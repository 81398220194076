import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { useNavigate, useParams } from 'react-router';
import { createStyles, withStyles } from '@mui/styles';
import AutoEditor from '../../components/auto/AutoEditor';
import UserList from '../../components/identity/UserList';
import { useQueryParams } from 'src/abstracts/NavigationHelpers';
import LoadingScreen from '../../components/common/LoadingScreen';
import DefaultAutoGrid from '../../components/auto/DefaultAutoGrid';
import { Typography, Paper, AppBar, Tabs, Tab, Theme } from '@mui/material';
import { IConnectedProps, mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import WorkerPhoto from 'src/components/identity/WorkerPhoto';

const styles = (theme: Theme) => createStyles({
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '16px'
  },
  contentWrapperTitled: {
    margin: '0 24px 16px 24px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  }
});

type TabValues = 'logins' | 'contacts' | 'addresses' | 'staff' | 'settings';

interface ICompanySettingsPageQueryParams {
  companyId?: number,
  tab: TabValues
}

interface IComapnySettingsPageState {
  loadedTabs: TabValues[];
  canEdit: boolean
}

function SettingsPage(props: IConnectedProps) {
  const { classes, profile } = props;
  const { companyId } = useParams();
  const navigate = useNavigate();

  if (companyId == null) {
    navigate('/');
  }

  const [{ tab }, setParams] = useQueryParams<ICompanySettingsPageQueryParams>({
    tab: {
      type: 'string',
      defaultValue: 'logins',
      allowedValues: ['logins', 'contacts', 'addresses', 'staff', 'settings']
    }
  });

  const handleUserSelected = () => {};
  const handleUserAddNew = () => {};

  const displayCompany = (): boolean => profile.isTrainingFacility || (companyId != null && (props.profile.companies?.filter((x) => x.companyId === +companyId).length ?? 0) > 0);

  const canEditCompany = (): boolean => {
    if (profile.isAdmin && profile.isTrainingFacility) return true;

    if (companyId == null) return false;

    return profile.companies.find((x) => x.companyId === +companyId)?.isAdmin ?? false;
  };

  const [editState, setEditState] = React.useState<IComapnySettingsPageState>({ loadedTabs: [], canEdit: canEditCompany() });
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: TabValues) => {
    setEditState((old) => ({
      ...old,
      loadedTabs: [newValue].concat(old.loadedTabs)
    }));

    setParams({ tab:newValue });
  };

  React.useEffect(() => {
    setEditState({ loadedTabs: [tab], canEdit: canEditCompany() });
  }, [companyId, profile.isTrainingFacility, profile.isAdmin]);

  return profile.userId < 0 || companyId == null ? (
    <LoadingScreen />
  ) : (
    <>
      <Paper className={classes.paper}>
        <div className={classes.contentWrapper}>
          {displayCompany() ? (
            <AutoEditor baseTable="identity.company" editId={+companyId} onSave={()=>{}} mode="inline" readonly={!profile.isAdmin || !profile.isTrainingFacility} />
          ) : (
            <Typography>Unable to open company</Typography>
          )}
        </div>
      </Paper>
      {!displayCompany() || companyId == null ? undefined : (
        <Paper className={classes.paper}>
          <div className={classes.contentWrapperTitled}>
            <AppBar position="relative">
              <Tabs value={tab} onChange={handleTabChange} variant="fullWidth" aria-label="Company details options">
                <Tab label="Logins" value="logins" />
                <Tab label="Contacts" value="contacts" />
                <Tab label="Addresses" value="addresses" />
                <Tab label="Associated Workers" value="staff" />
                {/* <Tab label="Training History" value="Custom" /> */}
              </Tabs>
            </AppBar>
            {editState.loadedTabs.indexOf('logins') < 0 ? undefined : (
              <UserList hidden={tab !== 'logins'} companyId={+companyId} onUserSelected={handleUserSelected} onAddNew={handleUserAddNew} readonly={!editState.canEdit} />
            )}
            {editState.loadedTabs.indexOf('contacts') < 0 ? undefined : (
              <div hidden={tab !== 'contacts'}>
                <DefaultAutoGrid
                  tableName="identity.companyContact"
                  defaultColumnFilters={[{ column: 'company', filter: +companyId, hideFromTable: true, defaultInEditor: 'readonly' }]}
                  autoEditor={editState.canEdit}
                  autoDelete={editState.canEdit}
                  company={+companyId}
                />
              </div>
            )}
            {editState.loadedTabs.indexOf('addresses') < 0 ? undefined : (
              <div hidden={tab !== 'addresses'}>
                <DefaultAutoGrid
                  tableName="identity.companyAddress"
                  defaultColumnFilters={[{ column: 'company', filter: +companyId, hideFromTable: true, defaultInEditor: 'readonly' }]}
                  autoEditor={editState.canEdit}
                  autoDelete={editState.canEdit}
                  company={+companyId}
                />
              </div>
            )}
            {editState.loadedTabs.indexOf('staff') < 0 ? undefined : (
              <div hidden={tab !== 'staff'}>
                <DefaultAutoGrid
                  tableName="identity.personCompany"
                  defaultColumnFilters={[{ column: 'company', filter: +companyId, hideFromTable: true, defaultInEditor: 'readonly' }]}
                  autoEditor={editState.canEdit}
                  autoDelete={editState.canEdit}
                  columns={[{
                    name: 'person.pictureTakenUTC',
                    readonly: true,
                    displayControl: (r) => <WorkerPhoto personId={r['person.iecNumber']} photoTakenUTC={r['person.pictureTakenUTC']} diameter={80}/>
                  }, 'person.iecNumber', 'person.firstLegal', 'person.firstPreferred', 'person.last', 'start', 'end', 'workerEmail']}
                  pagination
                  bulkDelete
                  // bulkEdit
                  buttonTitle="Add Worker to Company"
                  editorTitle="Worker Association"
                  company={+companyId}
                />
              </div>
            )}
          </div>
        </Paper>
      )}
    </>
  );
}
/**
 * {
      key: 'person.pictureTakenUTC',
      displayControl: (r) => <WorkerPhoto personId={r['person.iecNumber']} photoTakenUTC={r['person.pictureTakenUTC']} diameter={80}/>
    }
 */
function mapStateToProps(state: IAppState) {
  return {
    ui: state.ui,
    profile: state.profile
  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapConfigFetchToProps))(SettingsPage);

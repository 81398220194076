import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import settings from '../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import Reserves from '../../components/booking/Reserves';
import { quickGrid } from 'src/abstracts/DataroweHelpers';
import { fetchConfigRequest } from '../../stores/database/actions';
import { mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import { Grid, Typography, Paper, Theme, TextField } from '@mui/material';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  },
  verticalCenter: {
    alignItems: 'center'
  }
});

interface IConnectedProps {
  classes: any;
  db: IDatabaseState;
  fetchConfigRequest: typeof fetchConfigRequest;
}

interface IReservesPageState {
  start: moment.Moment;
  end: moment.Moment;
}

const ReservesPage = (props: IConnectedProps) => {
  const { classes } = props;

  const [viewState, setViewState] = React.useState<IReservesPageState>({
    start: moment(),
    end: moment().add(7, 'days')
  });

  const handleStartDateChange = (date: string) => {
    const startDate = date ? moment(date) : undefined;

    if (typeof startDate !== 'undefined') {
      setViewState(old => ({
        ...old,
        start: startDate
      }));
    }
  };

  const handleEndDateChange = (date: string) => {
    const endDate = date ? moment(date) : undefined;

    if (typeof endDate !== 'undefined') {
      setViewState(old => ({
        ...old,
        end: endDate
      }));
    }
  };

  const controlGridSizes = quickGrid(12, 6, 4, 2, 2);
  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Grid container spacing={3} className={classes.verticalCenter}>
          <Grid item {...controlGridSizes}>
            <TextField
              fullWidth
              id="mui-pickers-start"
              InputLabelProps={{ shrink: true }}
              label="Start Date"
              onChange={event => handleStartDateChange(event.currentTarget.value)}
              type="date"
              value={viewState.start.format(settings.apiDateFormatMoment)}
            />
          </Grid>
          <Grid item {...controlGridSizes}>
            <TextField
              fullWidth
              id="mui-pickers-end"
              InputLabelProps={{ shrink: true }}
              label="End Date"
              onChange={event => handleEndDateChange(event.currentTarget.value)}
              type="date"
              value={viewState.end.format(settings.apiDateFormatMoment)}
            />
          </Grid>
          <Grid item {...quickGrid(12, 6, 4, 3, 3)}>
            <Typography>Review reserved seats here. To create new reservations, find the class under Course Schedule.</Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentWrapper}>
        <Reserves dateRange={viewState} maxHeight="calc(100vh - 260px)" />
      </div>
    </Paper>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(ReservesPage);

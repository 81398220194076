import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { IAppState } from '../../stores';
import { Delete } from '@mui/icons-material';
import axios, { AxiosResponse } from 'axios';
import settings from '../../abstracts/settings';
import CourseSearchGrid from './CourseSearchGrid';
import CompanyList from '../identity/CompanyList';
import WorkerSearch from '../identity/WorkerSearch';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../../stores/profile/types';
import { IApiResult } from '../../stores/database/interfaces';
import { ICourse } from '../../stores/database/training/courses';
import AutoGrid, { mapConfigFetchToProps } from '../auto/AutoGrid';
import { fetchConfigRequest } from '../../stores/database/actions';
import { Fab, FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import { IUploadRow, IUploadState } from '../../stores/training/upload/types';
import { ICustomDisplayDefinition, AutoLoadKeyDisplay } from '../auto/AutoLoad';
import { uploadClear, uploadAdd, uploadDelete } from '../../stores/training/upload/actions';
import { arrayGroupByRemap, nameof, objectMapArray, quickGrid } from '../../abstracts/DataroweHelpers';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  },
  fabClear: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(28)
  },
  fabSubmit: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(14)
  },
  verticalCenter: {
    alignItems: 'center'
  }
});

interface IConnectedProps {
  classes: any;
  profile: IProfileState;
  upload: IUploadState;
  uploadClear: typeof uploadClear;
  uploadAdd: typeof uploadAdd;
  uploadDelete: typeof uploadDelete;
  fetchConfigRequest: typeof fetchConfigRequest;
}

interface ILocalState {
  date: string;
  allowedCourseIds: number[];
  companyCourseIds: Dictionary<number[]>;
  selectedCompanyId?: number;
  selectedUserCompanyId?: number;
  selectedCompany: string;
  selectedCourseId?: number;
  selectedCourseCode: string;
  selectedCourseTitle: string;
}

const Upload = (props: IConnectedProps) => {
  const { classes } = props;

  const [editState, setEditState] = React.useState<ILocalState>({
    date: moment().format(settings.apiDateFormatMoment),
    allowedCourseIds: [],
    companyCourseIds: {},
    selectedCompany: 'Select Company',
    selectedCourseCode: 'Select Course',
    selectedCourseTitle: ''
  });

  const handleDateChange = (dateValue: string) => {
    const date = dateValue ? moment(dateValue) : undefined;

    if (date != null) {
      setEditState((old) => ({
        ...old,
        date: date.format(settings.apiDateFormatMoment)
      }));
    }
  };

  const handleCompanySelected = (companyId: number | undefined, name: string, userCompanyId?: number) => {
    setEditState((old) => ({
      ...old,
      selectedCompanyId: companyId,
      selectedCompany: name,
      selectedUserCompanyId: userCompanyId
    }));
  };

  const handleCourseSelected = (courses: ICourse[]) => {
    setEditState((old) => ({
      ...old,
      selectedCourseId: courses[0].courseId,
      selectedCourseCode: courses[0].code,
      selectedCourseTitle: courses[0].title
    }));
  };

  const handleWorkerAdd = (workers: AutoLoadKeyDisplay) =>
    props.uploadAdd(
      objectMapArray(workers, (k, v) => ({
        personId: +k,
        workerName: v.display,
        userCompanyId: editState.selectedUserCompanyId!,
        companyName: editState.selectedCompany,
        courseId: editState.selectedCourseId!,
        courseCode: editState.selectedCourseCode,
        courseTitle: editState.selectedCourseTitle,
        trainingDate: editState.date
      }))
    );

  React.useEffect(() => {
    axios.get(`${API_ENDPOINT}/db/query/training/courseUpload`).then((res: AxiosResponse<IApiResult<{ course: number; company?: number }[]>>) => {
      const companyCourseIds = arrayGroupByRemap(
        res.data.results.filter((x) => x.company != null),
        (r) => `${r.company}`,
        (r) => [r.course],
        (g, r) => g.push(r.course)
      );

      setEditState((old) => ({
        ...old,
        companyCourseIds,
        allowedCourseIds: res.data.results.filter((x) => x.company == null).map((x) => x.course)
      }));
    });
  }, []);

  const getIncludedCourseIds = (): number[] => {
    const res: number[] = [];

    if (editState.selectedCompanyId == null) return res.concat(editState.allowedCourseIds);

    return res.concat(editState.allowedCourseIds).concat(editState.companyCourseIds[editState.selectedCompanyId]);
  };

  const uploadListColumns: ICustomDisplayDefinition<IUploadRow>[] = [
    {
      columnAlias: nameof<IUploadRow>('trainingDate'),
      columnTitle: 'Training Date'
    },
    {
      columnAlias: nameof<IUploadRow>('courseCode'),
      columnTitle: 'Course Code'
    },
    {
      columnAlias: nameof<IUploadRow>('courseTitle'),
      columnTitle: 'Course Title'
    },
    {
      columnAlias: nameof<IUploadRow>('workerName'),
      columnTitle: 'Worker'
    },
    {
      columnAlias: nameof<IUploadRow>('personId'),
      columnTitle: 'IEC Number'
    },
    {
      columnAlias: nameof<IUploadRow>('companyName'),
      columnTitle: 'Company'
    }
  ];

  const workerSearchDisabled = (): boolean => editState.selectedCourseId == null;

  const workerSearchTip = (): string | undefined => {
    if (editState.selectedUserCompanyId == null) return 'Select Company before selecting worker';

    if (editState.selectedCourseId == null) return 'Select Course before selecting worker';

    return undefined;
  };

  const handleSubmitClick = () => {
    axios.post(`${API_ENDPOINT}/training/upload`, objectMapArray(props.upload.rowData, (_key, value) => {
      return {
        trainingDate: value.trainingDate,
        userCompanyId: value.userCompanyId,
        courseId: value.courseId,
        personId: value.personId
      };
    })).then((_res) => {
      props.uploadClear();
    });
  };

  const handleClearClick = () => props.uploadClear();

  const controlGridSizes = quickGrid(12, 6, 4, 3, 2);
  return (
    <>
      <Grid container spacing={3} className={classes.verticalCenter}>
        <Grid item {...controlGridSizes}>
          <TextField
            fullWidth
            id="mui-pickers-date"
            InputLabelProps={{ shrink: true }}
            label="Training Date"
            onChange={event => handleDateChange(event.currentTarget.value)}
            required
            type="date"
            value={editState.date}
          />
        </Grid>
        <Grid item {...controlGridSizes}>
          <CompanyList mode="button-select" fullWidth={true} buttonTitle={editState.selectedCompany} companyId={editState.selectedCompanyId} selectTitle="Select Company" selectUnderLength={10} onCompanySelected={handleCompanySelected} />
        </Grid>
        <Grid item {...controlGridSizes}>
          <CourseSearchGrid fullWidth mode="button" title={editState.selectedCourseCode} onCoursesSelected={handleCourseSelected} includeOnlyCourseIds={getIncludedCourseIds()} />
        </Grid>
        <Grid item {...controlGridSizes}>
          <FormControl fullWidth>
            <WorkerSearch mode="button" fullWidth={true} buttonTitle="Select Worker(s)" disabled={workerSearchDisabled()} onWorkersSelected={handleWorkerAdd}/>
            <FormHelperText>{workerSearchTip()}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AutoGrid
            rowData={props.upload.rowData}
            exportTitle="Upload Training Records"
            displayColumns={uploadListColumns}
            rowDataLastUpdate={props.upload.lastUpdated}
            postActions={{
              icon: <Delete />,
              title: '',
              onClick: (r: IUploadRow) => props.uploadDelete(r)
            }}
            keyField={undefined}
            displayField={undefined}
            emptyMessage="Select Date, Company and Course, then select Workers to add to list"
          />
        </Grid>
      </Grid>
      <Fab className={classes.fabSubmit} variant="extended" onClick={handleSubmitClick} disabled={Object.keys(props.upload.rowData).length === 0} color="primary">
        Submit
      </Fab>
      <Fab className={classes.fabClear} variant="extended" onClick={handleClearClick} disabled={Object.keys(props.upload.rowData).length === 0}>
        Clear
      </Fab>
    </>
  );
};

const mapFromAppState = ({ upload, profile }: IAppState) => ({ profile, upload });

const mapConfig = {
  uploadClear,
  uploadAdd,
  uploadDelete,
  ...mapConfigFetchToProps
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapFromAppState, mapConfig))(Upload);

import moment from 'moment';
import { User } from 'oidc-client';
import { Dictionary } from 'lodash';
import { action } from 'typesafe-actions';
import { HubConnection } from '@microsoft/signalr';
import { ICourse } from '../../database/training/courses';
import { IFilterDefinition } from '../../database/interfaces';
import { AutoRow, IAutoLoadCacheConnected } from '../../../components/auto/AutoLoad';
import { CheckinActionTypes, IFilterConfig, CheckinGridProps, ICourseScheduleConnect, PageType } from './types';

export const onPersonUpdateWHMIS = (personId: number, whmis: boolean) => action(CheckinActionTypes.CHECKIN_TOGGLEWHMIS_DONE, { personId, whmis });
export const onPersonUpdateVerified = (personId: number, verified: boolean) => action(CheckinActionTypes.CHECKIN_TOGGLEVERIFIED_DONE, { personId, verified });
export const onScheduleUpdateArrival = (workerScheduleId: number, arrival?: moment.Moment) => action(CheckinActionTypes.CHECKIN_COURSE_DONE, { workerScheduleId, arrival });
export const onScheduleUpdateStatus = (workerScheduleId: number, statusKey: string) => action(CheckinActionTypes.CHECKIN_START_DONE, { workerScheduleId, statusKey });
export const persistConnection = (connection: HubConnection) => action(CheckinActionTypes.HUB_CONNECT, connection);

export interface ICheckinHub {
  onPersonUpdateWHMIS: typeof onPersonUpdateWHMIS;
  onPersonUpdateVerified: typeof onPersonUpdateVerified;
  onScheduleUpdateArrival: typeof onScheduleUpdateArrival;
  onScheduleUpdateStatus: typeof onScheduleUpdateStatus;
  user: User;
}

export const mapCheckinHub = {
  onPersonUpdateWHMIS,
  onPersonUpdateVerified,
  onScheduleUpdateArrival,
  onScheduleUpdateStatus
};

// export const signalrHubCheckinConnect = (checkinHub: ICheckinHub, date: string) => action(CheckinActionTypes.CHECKIN_CONNECT_START, { checkinHub, date });

export const signalrHubCheckinWorker = (workerScheduleId: number, newValue: boolean) => action(CheckinActionTypes.CHECKIN_COURSE, { workerScheduleId, newValue });
export const signalrHubUpdateWHMIS = (personId: number, newValue: boolean) => action(CheckinActionTypes.CHECKIN_TOGGLEWHMIS, { personId, newValue });
export const signalrHubUpdateVerified = (personId: number, newValue: boolean) => action(CheckinActionTypes.CHECKIN_TOGGLEVERIFIED, { personId, newValue });
export const signalrHubCourseStart = (workerScheduleId: number, newValue: string) => action(CheckinActionTypes.CHECKIN_TOGGLEVERIFIED, { workerScheduleId, newValue });

export const hubClear = () => action(CheckinActionTypes.HUB_CLEAR);

export const loadCheckinConfig = (checkinHub: ICheckinHub, connected: IAutoLoadCacheConnected, type: PageType, gridParams: CheckinGridProps, filterProps: IFilterConfig, date?: string) => action(CheckinActionTypes.LOAD_CONFIG, { checkinHub, connected, type, gridParams, filterProps, date });
export const updateCheckinDate = (checkinHub: ICheckinHub, connected: IAutoLoadCacheConnected, type: PageType, date: string) => action(CheckinActionTypes.LOAD_FILTER, { checkinHub, connected, type, date });
export const updateCheckinTableData = (rowData: Dictionary<AutoRow>, type: PageType, filter?: IFilterDefinition, courses?: Dictionary<ICourse>, courseSchedules?: ICourseScheduleConnect[]) => action(CheckinActionTypes.LOAD_DATA, { rowData, type, filter, courses, courseSchedules });

export const loadQuickAddWorkerSchedules = (connected: IAutoLoadCacheConnected, workerScheduleIds: number[]) => action(CheckinActionTypes.LOAD_QUICK_ADD_WORKER_SCHEDULES, { connected, workerScheduleIds });
export const mergeQuickAddWorkerSchedules = (rowData: Dictionary<AutoRow>) => action(CheckinActionTypes.MERGE_QUICK_ADD_WORKER_SCHEDULES, { rowData });

import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { IProfileState, ProfileActionTypes } from './types';
import { validateApi } from '../ui/actions';
const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

function* handleLoadRequest(action: { type: ProfileActionTypes, payload?: boolean }) {
  try {
    const response: AxiosResponse<IProfileState> = (yield call(axios.get, `${API_ENDPOINT}/account/profile${action.payload ? '?force=true' : ''}`));
    console.log(ProfileActionTypes.LOAD_ME, response);
    yield put({ type: ProfileActionTypes.LOADED, payload: response.data });
    //TODO: Create a call that gets messages from the API and displays it
    // if (!response.data.isPartner) {
    //   yield put(createAlert({
    //     autoDisplay: true,
    //     page: 'Welcome',
    //     title: 'Price Change',
    //     description: '*Notice: Recent update for Non-Member course costs effective April 1, 2023*',
    //     created: moment().format(settings.dateTimeFormatMoment),
    //     viewed: false,
    //     severity: 'info',
    //     autoDismiss: false
    //   }));
    // }
  } catch {
    yield put(validateApi(false));
  }
}

function* watchLoadRequest() {
  yield takeLeading(ProfileActionTypes.LOAD_ME, handleLoadRequest);
}

export function* profileSaga() {
  yield all([fork(watchLoadRequest)]);
}

import React from 'react';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { fetchProfile } from '../../stores/profile/actions';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

interface IConnectedProps {
  fetchProfile: typeof fetchProfile;
}

interface ILinkPersonIdProps extends IConnectedProps {
  classes: any;
}

interface ILinkPersonIdState {
  open: boolean;
  personId: number;
  lookupKey: string
}

const LinkPersonId = (props: ILinkPersonIdProps) => {
  React.useEffect(() => {
    props.fetchProfile();
  }, []);

  const [editState, setEditState] = React.useState<ILinkPersonIdState>({
    open: false,
    personId: -1,
    lookupKey: ''
  });

  const handleOpen = () => setEditState(() => ({
    personId: -1,
    lookupKey: '',
    open: true
  }));

  const handleClose = () => setEditState(() => ({
    personId: -1,
    lookupKey: '',
    open: false
  }));

  const handleLink = () => {
    axios.post(`${API_ENDPOINT}/account/mergePerson/${editState.personId}/${editState.lookupKey}`).then((_res) => {
      handleClose();
      props.fetchProfile();
    });
  };

  const setValue = (field: string, value: string) => setEditState((old) => ({
    ...old,
    ...{
      [field]: value
    }
  }));

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>
        Set My IEC Number
      </Button>
      <Dialog open={editState.open} onClose={handleClose}>
        <DialogTitle>Enter Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>The BASES card (see example image) can be used to link IEC numbers to a profile. Enter the IEC number and security key as directed.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth required id="personId" label="IEC Number" type="number" value={editState.personId < 1 ? '' : editState.personId} onChange={(e) => setValue('personId', e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth required id="lookupKey" label="Security Key" value={editState.lookupKey} onChange={(e) => setValue('lookupKey', e.target.value)} onKeyDown={(event) => { if (event.keyCode === 13) handleLink(); }}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img src="https://iecstorage.blob.core.windows.net/images/1B0DF062-3D2F-4637-9ECE-4146DA7AA15E/ProfileSampleCardArrow.png?1" title="Sample image to show where to find IEC number and security key on an BASES badge" height={350} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLink} color="primary">
            Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapConfig = {
  fetchProfile
};

export default compose(withStyles(createStyles({})), connect(undefined, mapConfig))(LinkPersonId);

import { AutoRow } from '../AutoLoad';
import { createStyles, withStyles } from '@mui/styles';
import { mapMenuItems } from './helpers/SelectHelpers';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { isNumber } from '../../../abstracts/DataroweHelpers';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Checkbox, ListItemText, SelectChangeEvent, Theme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  matchHeight: {
    marginTop: theme.spacing(1)
  }
});

interface ISelectEditorProps extends IAutoEditorImplementation<number[] | undefined> {
  classes: any;
}

const selectEditor = (props: ISelectEditorProps) => {
  const { classes, field, editValue, error, warning, onValueUpdated } = props;

  const handleRenderValue = (selectedOptions: number[]) => {
    if (props.valueCache == null || field.referenceTableId == null || props.valueCache[field.referenceTableId] == null || props.valueCache[field.referenceTableId].rows == null) return 'Loading...';

    const items = props.valueCache[field.referenceTableId];

    return selectedOptions.map(id => items.rows?.[id]?.[items.displayAlias] ?? 'Loading...').sort().join(', ');
  };

  const handleFieldUpdate = (event: SelectChangeEvent<number[]>) => {
    onValueUpdated(event.target.value as number[], field, handleRenderValue(event.target.value as number[]));
  };

  const getMenuItemDisplay = (item: AutoRow, depth?: number) => (
    <MenuItem key={item.id} value={item.id} style={{ paddingLeft: 15 * (depth ?? 0) + 5, fontWeight: depth === 0 ? 'bold' : undefined }} disabled={!item.isSelectable}>
      {item.isSelectable ? <Checkbox checked={isNumber(item.id) && (editValue ?? []).indexOf(item.id) >= 0} /> : undefined}
      <ListItemText primary={item.display} />
    </MenuItem>
  );

  const getMenuItems = () => (props.valueCache == null || field.referenceTableId == null || props.valueCache[field.referenceTableId] == null ? undefined : mapMenuItems(props.valueCache[field.referenceTableId], getMenuItemDisplay));

  return (
    <FormControl error={!!error} fullWidth required={field.required} className={classes.matchHeight}>
      <InputLabel id={`selectEditor_${field.columnId}_label`}>{field.title}</InputLabel>
      <Select label={field.title} labelId={`selectEditor_${field.columnId}_label`} id={`selectEditor_${field.columnId}`} multiple value={editValue ?? []} onChange={handleFieldUpdate} disabled={props.disabled} renderValue={handleRenderValue}>
        {getMenuItems()}
      </Select>
      <FormHelperText>{error || warning || field.description}</FormHelperText>
    </FormControl>
  );
};

export default withStyles(styles)(selectEditor);

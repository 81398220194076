import React from 'react';
import axios from 'axios';
import moment from 'moment';
import QRLink from '../common/QRLink';
import { useTheme } from '@mui/styles';
import { MdAddToQueue } from 'react-icons/md';
import settings from '../../abstracts/settings';
import CourseSearchGrid from './CourseSearchGrid';
import { ICourse } from '../../stores/database/training/courses';
import { Box, Theme, Button, ButtonGroup, Dialog, DialogTitle, DialogContent, Typography, DialogActions, List, ListItem, ListItemSecondaryAction, IconButton, ListItemText } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const generateSxClasses = (theme: Theme) => {
  const border = `1px solid ${ theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white}`;

  return {
    complete: {
      // backgroundColor: theme.palette.primary.main,
      border,
      '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
        border: border
      }
    },
    incomplete: {
      backgroundColor: theme.palette.secondary.main,
      border,
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        border: border
      }
    }
  };
};

export interface IWorkerSchedulePrereqAssignment {
  workerWebAssignId: number;
  linkToken: string;
  startUTC?: Date;
  completeUTC?: Date;
  success: boolean;
}

export interface IWorkerSchedulePrereqStatus {
  courseId: number;
  code: string;
  title: string;
  completed: boolean;
  expiryDate?: string;
  assignments?: IWorkerSchedulePrereqAssignment[];
}

interface IPrereqStatusDisplayProps {
  statuses?: IWorkerSchedulePrereqStatus[];
  personId: number;
  personDisplayName: string;
  courseDisplayName: string;
  workerScheduleId?: number;
}

export const prereqStatusDisplay = (status: IWorkerSchedulePrereqStatus): string => `${status.completed ? 'C' : 'Inc'}omplete: ${status.title}`;

export const prereqStatusListDisplay = (statuses?: IWorkerSchedulePrereqStatus[]): string[] => (statuses ?? []).map(prereqStatusDisplay);

const PrereqStatus = (props: IPrereqStatusDisplayProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const { statuses } = props;

  const [viewStatus, setViewStatus] = React.useState<IWorkerSchedulePrereqStatus | undefined>(undefined);
  const [quickAddWeb, setQuickAddWeb] = React.useState<boolean | undefined>(undefined);

  const handlePrereqClick = (event: React.MouseEvent, status: IWorkerSchedulePrereqStatus) => {
    event.stopPropagation();
    setViewStatus(status);
  };

  const createAssignmentItem = (a: IWorkerSchedulePrereqAssignment) => <ListItem key={`${props.personId}_${a.workerWebAssignId}_${a.linkToken.substring(10)}`}>
    <ListItemText>
      {a.success ? 'Success' : a.completeUTC == null ? 'Incomplete' : 'Failed'}: {a.startUTC == null ? 'Not Started' : `Started ${moment.utc(a.startUTC).local().format(settings.dateTimeFormatMoment)}`}{
        a.completeUTC == null ? '' : `, Completed ${moment.utc(a.completeUTC).local().format(settings.dateTimeFormatMoment)}`
      }
    </ListItemText>
    {
      a.completeUTC == null ?
        <ListItemSecondaryAction>
          <QRLink title={`${props.personDisplayName} - ${viewStatus?.title}`} link={`https://reg.lambtonbases.ca/runcourse?key=${a.linkToken}&id=${a.workerWebAssignId}`} />
        </ListItemSecondaryAction>
        : undefined
    }
  </ListItem>;

  const handleCourseSelected = (courses: ICourse[]) => {
    if (props.workerScheduleId != null) {
      axios.post(`${API_ENDPOINT}/Training/Schedule/AssignWebTraining?workerScheduleId=${props.workerScheduleId}&courseId=${courses[0].courseId}`).then((_res) => {
        setQuickAddWeb(false);
      });
    }
  };

  const handleAddWebCourseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setQuickAddWeb(true);
  };

  return <>{(statuses ?? []).length === 0 ? props.workerScheduleId == null ? undefined :
    <IconButton
      size="medium"
      key="add-web-course"
      onClick={handleAddWebCourseClick}
    >
      <MdAddToQueue/>
    </IconButton>
    : <Box key="worker-prereq-buttons">
      <ButtonGroup orientation="vertical">
        {statuses!.map(status =>
          <Button
            color={status.expiryDate == null ? 'secondary' : 'inherit'}
            key={status.courseId}
            onClick={event => handlePrereqClick(event, status)}
            size="small"
            sx={{ ...sxClasses[status.expiryDate ? 'complete' : 'incomplete'] }}
            variant="outlined"
          >
            {prereqStatusDisplay(status)}
          </Button>
        )}
        {props.workerScheduleId == null ? undefined : <Button
          color="inherit"
          key="add-web-course"
          onClick={handleAddWebCourseClick}
          variant="outlined"
        >
          <MdAddToQueue fontSize="large"/>
        </Button>}
      </ButtonGroup>
      <Dialog
        open={viewStatus != null}
        onClose={() => setViewStatus(undefined)}
        onClick={e => e.stopPropagation()}
      >
        <DialogTitle>{props.personDisplayName} - {viewStatus?.title}</DialogTitle>
        <DialogContent>
          <Typography>{props.courseDisplayName}</Typography>
          <List>
            {(viewStatus?.assignments ?? []).map(a => createAssignmentItem(a))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewStatus(undefined)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>}
    {quickAddWeb === undefined || props.workerScheduleId == null ? undefined :
      <CourseSearchGrid
        mode="direct"
        open={quickAddWeb}
        title={`${props.courseDisplayName} - ${props.personDisplayName} - Quick Assign New Web Training`}
        onCoursesSelected={handleCourseSelected}
        limitSelectCount={1}
        onCancel={() => setQuickAddWeb(false)}
        dialogTitle={props.courseDisplayName}
        onlineCourses
      />}
  </>;
};

export default PrereqStatus;

import clsx from 'clsx';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { Dictionary, pull } from 'lodash';
import { AutoRow } from '../auto/AutoLoad';
import AutoSizer from 'react-virtualized-auto-sizer';
import { createStyles, withStyles } from '@mui/styles';
import { ICompanyAlias } from '../../stores/database/interfaces';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { IConnectedProps, mapConfigFetchToProps } from '../auto/AutoGrid';
import { loadCompaniesCache } from '../../stores/database/identity/companies';
import { arrayMapObject, updateArray, objectSortMap, regexFilterString } from '../../abstracts/DataroweHelpers';
import { Theme, Button, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, ListItem, Grid, List, TextField } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  trainingAlreadySched: {
    color: theme.palette.grey[500],
    fontStyle: 'italic'
  },
  trainingNotScheduled: {
    color: theme.palette.primary.dark
  },
  heightLimitGridCell: {
    overflow: 'auto',
    fontSize: '90%'
  },
  noPadding: {
    padding: 0
  },
  fullWithList: {
    width: '100%'
  },
  priceDisplay: {
    textAlign: 'right'
  },
  scheduleDateHeader: {
    backgroundColor: theme.palette.primary.contrastText,
    width: '100%'
  },
  scheduleTooltipInline: {
    paddingLeft: theme.spacing(2)
  },
  legendIcon: {
    textAlign: 'center'
  },
  legendText: {
    fontSize: '0.85em'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  companyList: {
    minHeight: 'calc(100vh - 300px)'
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main
  },
  marginAuto: {
    margin: 'auto'
  }
});

interface ICompanySelectListProps {
  mode: 'button' | 'inline' | 'direct';
  open?: boolean;
  singleSelect?: boolean;
  dialogTitle?: string;
  buttonTitle?: string;
  classes: any;
  companies: Dictionary<AutoRow>;
  maxHeight?: string | number;
}

interface ICompanySelectListActions {
  onCompaniesChange: (companies: Dictionary<AutoRow>, aliases: ICompanyAlias) => void;
  onCompaniesLoaded: (companies: Dictionary<AutoRow>, aliases: ICompanyAlias) => void;
  onCancel?: () => void;
}

const CompanySelectList = (props: ICompanySelectListProps & ICompanySelectListActions & IConnectedProps) => {
  const { classes, companies } = props;

  interface ListStateInterface {
    activeCompany?: number;
    activeCompanySide: 'none' | 'left' | 'right';
    unselectedCompaniesUnfiltered: number[];
    unselectedCompanies: number[];
    selectedCompanies: number[];
    allCompanies: Dictionary<AutoRow>;
    companyAliases?: ICompanyAlias;
    allCompaniesLoading: boolean;
    listOpen: boolean;
    listFilter: string;
  }

  const [listState, setListState] = React.useState<ListStateInterface>({
    unselectedCompaniesUnfiltered: [],
    unselectedCompanies: [],
    selectedCompanies: objectSortMap(companies || {}, 'name', (x) => x.item.companyId),
    allCompanies: companies,
    allCompaniesLoading: false,
    listOpen: false,
    activeCompanySide: 'none',
    listFilter: ''
  });

  const filteredUnselectedCompanies = (filter: string, unselected: number[]): number[] => {
    if (filter.length === 0) return unselected;
    const reg = regexFilterString(filter);
    return unselected.filter((x) => x === listState.activeCompany || reg.test(listState.allCompanies[x].name));
  };

  const loadCompanyCache = () => {
    if (!listState.allCompaniesLoading) {
      setListState((old) => ({ ...old, allCompaniesLoading: true }));
      loadCompaniesCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk).then((res) => {
        if (props.onCompaniesLoaded) props.onCompaniesLoaded(res.autoGridProps.rowData, res.aliases);

        const unselectedCompaniesUnfiltered = pull(
          objectSortMap(res.autoGridProps.rowData, res.aliases.displayName, (x) => parseInt(x.key, 10)),
          ...listState.selectedCompanies
        );

        setListState((old) => ({
          ...old,
          unselectedCompaniesUnfiltered,
          unselectedCompanies: filteredUnselectedCompanies(listState.listFilter, unselectedCompaniesUnfiltered),
          allCompanies: res.autoGridProps.rowData,
          companyAliases: res.aliases,
          allCompaniesLoading: false
        }));
      });
    }
  };

  React.useEffect(() => {
    loadCompanyCache();
  }, []);

  React.useEffect(() => {
    setListState((old) => ({
      ...old,
      selectedCompanies: objectSortMap(companies, old.companyAliases?.displayName, (x) => x.item.companyId)
    }));

    loadCompanyCache();

    return () => { };
  }, [companies]);

  const handleCopyRight = () => {
    if (listState.activeCompany != null && listState.selectedCompanies.indexOf(listState.activeCompany) < 0) {
      const selectedCompanies = updateArray<number>(listState.selectedCompanies, listState.activeCompany!, true)
        .map((x) => listState.allCompanies[x])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((x) => x.companyId);

      const unselectedCompaniesUnfiltered = updateArray<number>(listState.unselectedCompaniesUnfiltered, listState.activeCompany!, false);
      const unselectedCompanies = updateArray<number>(listState.unselectedCompanies, listState.activeCompany!, false);

      setListState((old) => ({
        ...old,
        selectedCompanies,
        unselectedCompaniesUnfiltered,
        unselectedCompanies,
        activeCompany: undefined,
        activeCompanySide: 'none'
      }));
    }
  };

  const handleCopyLeft = () => {
    console.log('Copy Left');
    if (listState.activeCompany != null && listState.unselectedCompanies.indexOf(listState.activeCompany) < 0) {
      const selectedCompanies = updateArray<number>(listState.selectedCompanies, listState.activeCompany!, false);
      const unselectedCompaniesUnfiltered = updateArray<number>(listState.unselectedCompaniesUnfiltered, listState.activeCompany!, true)
        .map((x) => listState.allCompanies[x])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((x) => x.companyId);

      setListState((old) => ({
        ...old,
        selectedCompanies,
        unselectedCompaniesUnfiltered,
        unselectedCompanies: filteredUnselectedCompanies(listState.listFilter, unselectedCompaniesUnfiltered),
        activeCompany: undefined,
        activeCompanySide: 'none'
      }));
    }
  };

  const handleCompanyClick = (companyId: number, side: 'none' | 'left' | 'right') => {
    if (listState.activeCompany === companyId) {
      if (side === 'left') {
        handleCopyRight();
      } else if (side === 'right') {
        handleCopyLeft();
      } else {
        setListState((old) => ({
          ...old,
          activeCompany: undefined,
          activeCompanySide: 'none'
        }));
      }
    } else {
      setListState((old) => ({
        ...old,
        activeCompany: companyId,
        activeCompanySide: side
      }));
    }
  };

  const handleOpen = () => {
    if (props.mode === 'button') {
      setListState((old) => ({
        ...old,
        listOpen: true
      }));
    }
  };

  const handleUpdate = () => {
    if (props.mode === 'button') {
      setListState((old) => ({
        ...old,
        listOpen: false
      }));
    }

    props.onCompaniesChange(arrayMapObject(listState.selectedCompanies, (i, x) => [x, listState.allCompanies[x]]), listState.companyAliases!);
  };

  const handleCancel = () => {
    setListState((old) => ({
      ...old,
      selectedCompanies: objectSortMap(companies, old.companyAliases?.displayName, (x) => x.item.companyId),
      listOpen: false
    }));

    if (props.onCancel) props.onCancel();
  };

  const handleSelectClear = () => {
    const unselectedCompaniesUnfiltered = objectSortMap(listState.allCompanies, listState.companyAliases!.displayName, ({ item }) => item.companyId);

    setListState((old) => ({
      ...old,
      unselectedCompaniesUnfiltered,
      selectedCompanies: [],
      unselectedCompanies: filteredUnselectedCompanies(listState.listFilter, unselectedCompaniesUnfiltered),
      activeCompany: undefined,
      activeCompanySide: 'none'
    }));
  };

  const renderUnselectedRow = (localProps: ListChildComponentProps) => {
    const { index, style } = localProps;
    const company = listState.allCompanies[listState.unselectedCompanies[index]];

    return (
      <ListItem button style={style} key={company.companyId} onClick={() => handleCompanyClick(company.companyId, 'left')} selected={company.companyId === listState.activeCompany}>
        <ListItemText primary={company.name} />
      </ListItem>
    );
  };

  const handleFilterChange = (text: string) => {
    setListState((old) => ({
      ...old,
      listFilter: text,
      unselectedCompanies: filteredUnselectedCompanies(text, listState.unselectedCompaniesUnfiltered)
    }));
  };

  const listAllCompany = () => (
    <>
      <TextField id="quick-filter" label="Filter by Name" margin="dense" defaultValue={listState.listFilter} onChange={(event) => handleFilterChange(event.currentTarget.value)} />
      <AutoSizer>{({ height, width }) => <FixedSizeList height={height - 46} width={width} itemCount={listState.unselectedCompanies.length} itemSize={46}>{renderUnselectedRow}</FixedSizeList>}</AutoSizer>
    </>
  );

  const listSelectedCompany = () => (
    <List dense role="list">
      {listState.selectedCompanies.map((id) => (
        <ListItem button key={id} onClick={() => handleCompanyClick(id, 'right')} selected={id === listState.activeCompany}>
          <ListItemText primary={listState.allCompanies[id].name} />
        </ListItem>
      ))}
    </List>
  );

  const renderSelector = () => {
    if (props.singleSelect) return <div style={{ minHeight: 'calc(100vh - 300px)' }}>{listAllCompany()};</div>;

    return (
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={5} className={classes.companyList}>
          {listAllCompany()}
        </Grid>
        <Grid item md={2}>
          <Grid container direction="column" alignItems="center">
            <Button variant="outlined" size="small" className={clsx(classes.button, listState.activeCompanySide !== 'left' ? undefined : classes.activeButton)} onClick={handleCopyRight} disabled={listState.activeCompanySide !== 'left'}>
              &gt;
            </Button>
            <Button variant="outlined" size="small" className={clsx(classes.button, listState.activeCompanySide !== 'right' ? undefined : classes.activeButton)} onClick={handleCopyLeft} disabled={listState.activeCompanySide !== 'right'}>
              &lt;
            </Button>
            <Button variant="outlined" size="small" className={clsx(classes.button, listState.selectedCompanies.length === 0 ? undefined : classes.activeButton)} onClick={handleSelectClear} disabled={listState.selectedCompanies.length === 0}>
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item md={5} className={classes.companyList}>
          {listSelectedCompany()}
        </Grid>
      </Grid>
    );
  };

  const companySelectDialog = () => (
    <Dialog open={props.open || listState.listOpen} onClose={handleCancel} fullWidth maxWidth="lg">
      <DialogTitle>{props.dialogTitle ?? 'Select Companies'}</DialogTitle>
      <DialogContent>{renderSelector()}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" className={classes.marginAuto}>
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" className={classes.marginAuto}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );

  const display = () => {
    switch (props.mode) {
      case 'button':
        return (
          <>
            <Button variant="contained" size="small" fullWidth className={clsx(classes.button, classes.formControl)} onClick={handleOpen}>
              {props.buttonTitle ?? 'Select Companies'}
            </Button>
            {companySelectDialog()}
          </>
        );
      case 'direct':
        return companySelectDialog();
      case 'inline':
        return renderSelector();
    }
  };

  return display();
};

function mapStateToProps(state: IAppState) {
  return {
    db: state.db,
    profile: state.profile
  };
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapConfigFetchToProps))(CompanySelectList);

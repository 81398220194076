import { Dictionary, flattenDeep } from 'lodash';
import { ReactElement } from 'react';
import { nameof, objectEach, objectSortMap } from '../../../../abstracts/DataroweHelpers';
import { IAutoEditorCacheConfig } from '../../autoInterfaces';
import { AutoRow } from '../../AutoLoad';

const getItemWithChildren = (itemMap: (item: AutoRow, depth?: number) => ReactElement, items: INestedSelectItem[], depth: number, parentId?: number | string): any => {
  return items.filter(x => x.parent === parentId || (x.parent == null && parentId == null)).map(item => [
    itemMap(item, depth),
    ...getItemWithChildren(itemMap, items, depth + 1, item.id)
  ]);
};

export const mapMenuItems = (items: IAutoEditorCacheConfig & { rows: Dictionary<AutoRow>; }, itemMap: (item: AutoRow, depth?: number) => ReactElement): ReactElement[] | ReactElement | undefined => {
  // if straight list of items, return mapped and default sorted by display name
  if (items.parentAlias == null) {
    return objectSortMap(items.rows, items.displayAlias, v => itemMap({ id: v.item[items.keyAlias], display: v.item[items.displayAlias], isSelectable: true }));
  }

  // if parent alias is a list, then each row will have it's parent values attached; must be iterated, uniqued, and non-selectable
  if (items.parentAlias != null && Array.isArray(items.parentAlias)) {
    const parentValues: Dictionary<INestedSelectItem> = {};

    objectEach(items.rows, (k, v) => (items.parentAlias as string[]).forEach((alias, depth, all) => {
      const parent = depth === 0 ? undefined : `${depth - 1}_${v[all[depth - 1]]}`;
      const id = `${depth}_${v[alias]}`;
      parentValues[`${parent}|${id}`] = { parent, id, display: v[alias], isSelectable: false };
    }));
    const lastIndex = items.parentAlias.length - 1;
    const lastAlias = lastIndex < 0 ? '' : items.parentAlias[lastIndex];
    return flattenDeep(
      getItemWithChildren(
        itemMap,
        [
          ...objectSortMap(parentValues, nameof<INestedSelectItem>('display'), v => v.item),
          ...objectSortMap(items.rows, items.displayAlias, v => (
            { parent: lastIndex < 0 ? undefined : `${lastIndex}_${v.item[lastAlias]}`, id: v.item[items.keyAlias], display: v.item[items.displayAlias], isSelectable: true }
          ))
        ],
        0
      )
    );
  }

  // if non-null and not an array then a recursive parent; any parent can be selected
  return flattenDeep(
    getItemWithChildren(
      itemMap,
      objectSortMap(
        items.rows,
        items.displayAlias,
        (v): INestedSelectItem => ({ id: v.item[items.keyAlias], display: v.item[items.displayAlias], parent: v.item[items.parentAlias as string], isSelectable: true })
      ),
      0
    ));
};

export interface INestedSelectItem {
  id: number | string;
  display: string;
  parent?: number | string;
  isSelectable: boolean;
}

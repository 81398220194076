import React from 'react';
import { Edit, Visibility } from '@mui/icons-material';
import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { Box, IconButton, Theme, Typography, useTheme } from '@mui/material';
import MDEditor, { ICommand, bold, divider, italic, link, orderedListCommand, quote, title1, title2, title3, title4, title5, title6, unorderedListCommand } from '@uiw/react-md-editor';

const generateSxClasses = (theme: Theme) => {
  return {
    markdownPreview: {
      backgroundColor: theme.palette.background.paper,
      borderColor:  theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: '16.5px 14px'
    },
    markdownPreviewControls: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  };
};

interface IMarkdownEditorProps extends IAutoEditorImplementation<string | undefined> {
  classes: any;
}

type IMarkdownEditorMode = 'edit' | 'preview';

interface IMarkdownEditorState {
  mode: IMarkdownEditorMode;
  value?: string;
}

const MarkdownEditor = (props: IMarkdownEditorProps) => {
  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);

  const commands: ICommand[] = [
    title1,
    title2,
    title3,
    title4,
    title5,
    title6,
    divider,
    bold,
    italic,
    divider,
    link,
    quote,
    unorderedListCommand,
    orderedListCommand
  ];

  // const extraCommands: ICommand[] = [
  //   fullscreen
  // ];

  const { field, editValue, onValueUpdated } = props;
  const [state, setState] = React.useState<IMarkdownEditorState>({
    mode: 'edit',
    value: editValue
  });

  const setValue = (value?: string) => {
    setState((old) => ({
      ...old,
      value
    }));
  };

  const setMode = (mode: IMarkdownEditorMode) => {
    setState((old) => ({
      ...old,
      mode
    }));
  };

  React.useEffect(() => {
    setValue(editValue);
  }, []);

  React.useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  const handleFieldUpdate = (v: string) => setValue(v.length === 0 && !field.required ? undefined : v);

  const handleFieldBlur = () => onValueUpdated(state.value, field);

  return (
    <React.Fragment key={`markdownEditor_${field.columnId}`}>
      <Box data-color-mode={theme.palette.mode}>
        <Box hidden={state.mode === 'preview'}>
          <Box sx={{ ...sxClasses.markdownPreviewControls }}>
            <Typography variant="h6">{`${field.title} (Markdown Editor)`}</Typography>
            <IconButton onClick={() => setMode('preview')}><Visibility /></IconButton>
          </Box>
          <MDEditor
            commands={commands}
            extraCommands={[]}
            onBlur={handleFieldBlur}
            onChange={(updateValue) => handleFieldUpdate(updateValue ?? '')}
            preview="edit"
            value={state.value}
          />
        </Box>
        <Box hidden={state.mode === 'edit'}>
          <Box sx={{ ...sxClasses.markdownPreviewControls }}>
            <Typography variant="h6">{`${field.title} (Markdown Preview)`}</Typography>
            <IconButton onClick={() => setMode('edit')}><Edit /></IconButton>
          </Box>
          <Box sx={{ ...sxClasses.markdownPreview }}>
            <MDEditor.Markdown
              source={state.mode === 'edit' ? '' : state.value}
              style={{ backgroundColor: 'transparent', whiteSpace: 'pre-wrap' }}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default withStyles(createStyles({}))(MarkdownEditor);

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { xor, Dictionary } from 'lodash';
import settings from '../../abstracts/settings';
import { Grid, Theme, Paper, } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { quickGrid } from 'src/abstracts/DataroweHelpers';
import { IProfileState } from '../../stores/profile/types';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ICourse } from '../../stores/database/training/courses';
import { ICompanyAlias } from '../../stores/database/interfaces';
import { fetchConfigRequest } from '../../stores/database/actions';
import WorkerHistory from '../../components/training/WorkerHistory';
import { mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import WorkerSelectList from '../../components/identity/WorkerSelectList';
import CourseSelectList from '../../components/training/CourseSelectList';
import CompanySelectList from '../../components/identity/CompanySelectList';
import { AutoLoadKeyDisplay, AutoRow } from '../../components/auto/AutoLoad';
import { objectMapArray, objectSortMap } from '../../abstracts/DataroweHelpers';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';
import DateRangeEdit, { datesToString, IDateRange } from '../../components/common/DateRangeEdit';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  },
  verticalCenter: {
    alignItems: 'center'
  }
});

interface IHistoryProps {
  classes: any;
}

interface IConnectedProps {
  db: IDatabaseState;
  profile: IProfileState;
  fetchConfigRequest: typeof fetchConfigRequest;
}

const WorkerHistoryPage = (props: IHistoryProps & IConnectedProps) => {
  const { classes } = props;

  // const [snackMessage, setSnackMessages] = React.useState<ChangeMessage[]>([]);
  // const handleSnackClose = () => setSnackMessages([]);

  interface WorkerHistoryState {
    trainingDate?: IDateRange;
    expiryDate?: IDateRange;
    companies: Dictionary<AutoRow>;
    companyIds?: number[];
    companyAliases?: ICompanyAlias;
    workers: AutoLoadKeyDisplay;
    workerIds?: number[];
    courses: ICourse[];
    courseIds?: number[];
    hideReplacedTraining?: boolean;
  }

  const [viewState, setViewState] = React.useState<WorkerHistoryState>({
    expiryDate: {
      startDate: moment().format(settings.apiDateFormatMoment),
      endDate: moment().add(21, 'days').format(settings.apiDateFormatMoment),
      includeNull: false
    },
    companies: {},
    workers: {},
    workerIds: undefined,
    courses: [],
    courseIds: undefined
  });

  const handleTrainDateRange = (save?: IDateRange) => setViewState((old) => ({ ...old, trainingDate: save }));
  const handleExpiryDateRange = (save?: IDateRange) => {
    setViewState((old) => ({
      ...old,
      expiryDate: save
    }));
  };

  const handleWorkersChange = (workers: AutoLoadKeyDisplay) => {
    const ids = objectMapArray(workers, (key) => +key);

    if (!(ids.length === 0 && (viewState.workerIds?.length ?? 0) === 0) && xor(viewState.workerIds, ids).length > 0) {
      setViewState((old) => ({
        ...old,
        workers,
        workerIds: ids.length === 0 ? undefined : ids
      }));
    }
  };

  const handleCompaniesChange = (companies: Dictionary<AutoRow>, aliases: ICompanyAlias) => {
    if (companies == null) {
      setViewState((old) => ({
        ...old,
        companies,
        companyAliases: aliases,
        companyIds: undefined
      }));
    } else {
      // objectMapArray automatically discards null/undefined
      const ids = objectMapArray(companies, (k, v) => v[aliases.companyId]);

      if (!(ids.length === 0 && (viewState.companyIds?.length ?? 0) === 0) && xor(viewState.companyIds, ids).length > 0) {
        setViewState((old) => ({
          ...old,
          companies,
          companyAliases: aliases,
          companyIds: ids.length === 0 ? undefined : ids
        }));
      }
    }
  };

  const handleCoursesChange = (courses: ICourse[]) => {
    const ids = courses.map((c) => c.courseId);

    if (!(ids.length === 0 && (viewState.courseIds?.length ?? 0) === 0) && xor(viewState.courseIds, ids).length > 0) {
      setViewState((old) => ({
        ...old,
        courses,
        courseIds: ids.length === 0 ? undefined : ids
      }));
    }
  };

  const workerButtonTitle = () => {
    if ((viewState.workerIds?.length ?? 0) === 0) return 'Filter by Worker(s)';

    const list = objectSortMap(viewState.workers, 'display', ({ item }) => item.display);

    if (list.length > 3) return `Workers: ${list.slice(0, 3).join(', ')} (+${list.length - 3})`;

    return `Workers: ${list.join(', ')}`;
  };

  const companyButtonTitle = () => {
    if (viewState.companyIds == null || viewState.companyIds.length === 0) return 'Filter by Company(ies)';

    if (viewState.companyIds.length > 3) return `Companies: ${viewState.companyIds.slice(0, 3).map((c) => viewState.companies[c][viewState.companyAliases!.displayName]).join(', ')} (+${viewState.companyIds!.length - 3})`;

    return `Companies: ${viewState.companyIds.map((c) => viewState.companies[c][viewState.companyAliases!.displayName])}`;
  };

  const courseButtonTitle = () => {
    if ((viewState.courseIds?.length ?? 0) === 0) return 'Filter by Course(s)';

    if (viewState.courses.length > 3) return `Courses: ${viewState.courses.slice(0, 3).map((c) => c.title).join(', ')} (+${viewState.courses.length - 3})`;

    return `Courses: ${viewState.courses.map((c) => c.title).join(', ')}`;
  };

  // React.useEffect(() => { props.setUiTitle({ title: 'Reserves', subtitle: props.match.params.date }); }, []);
  const controlGridSizes = quickGrid(12, 6, 4, 2, 2);

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Grid container spacing={3} className={classes.verticalCenter}>
          <Grid className={classes.root} item {...controlGridSizes}>
            <DateRangeEdit
              fullWidth
              mode="button"
              optional
              nullableStart
              nullableEnd
              dateRange={viewState.trainingDate}
              onSave={handleTrainDateRange}
              buttonTitle={`Training Date\n${datesToString(viewState.trainingDate, { empty: 'Not Filtered' })}`}
              titleOverrides={{ dialogHeader: 'Filter by Training Date' }}
            />
          </Grid>
          <Grid className={classes.root} item {...controlGridSizes}>
            <DateRangeEdit
              fullWidth
              mode="button"
              optional
              nullableStart
              nullableEnd
              nullableDate
              dateRange={viewState.expiryDate}
              onSave={handleExpiryDateRange}
              buttonTitle={`Expiry Date\n${datesToString(viewState.expiryDate, { empty: 'Not Filtered' })}`}
              titleOverrides={{ nullableOption: 'Include History with No Expiry', dialogHeader: 'Filter by Expiry Date' }}
            />
          </Grid>
          <Grid className={classes.root} item {...controlGridSizes}>
            <WorkerSelectList mode="button" buttonTitle={workerButtonTitle()} workers={viewState.workers} onWorkersChange={handleWorkersChange} />
          </Grid>
          {!props.profile.isTrainingFacility ? undefined : (
            <Grid className={classes.root} item {...controlGridSizes}>
              <CompanySelectList mode="button" buttonTitle={companyButtonTitle()} companies={viewState.companies} onCompaniesChange={handleCompaniesChange} />
            </Grid>
          )}
          <Grid className={classes.root} item {...controlGridSizes}>
            <CourseSelectList mode="button" buttonTitle={courseButtonTitle()} courses={viewState.courses} onCoursesChange={handleCoursesChange} />
          </Grid>
          <Grid className={classes.root} item {...controlGridSizes}>
            <FormControlLabel
              control={<Checkbox checked={!!viewState.hideReplacedTraining} onChange={() => setViewState((old) => ({ ...old, hideReplacedTraining: !old.hideReplacedTraining }))} color="primary" />}
              label="Hide training that has been renewed"
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentWrapper}>
        <WorkerHistory
          maxHeight="calc(100vh - 260px)"
          trainingDate={viewState.trainingDate}
          expiryDate={viewState.expiryDate}
          personIds={viewState.workerIds}
          companyIds={viewState.companyIds}
          courseIds={viewState.courseIds}
          hideReplacedTraining={viewState.hideReplacedTraining}
        />
      </div>
    </Paper>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(WorkerHistoryPage);

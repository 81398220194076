import { compose } from 'redux';
import { connect } from 'react-redux';
import { Paper, Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import AutoEditor from '../../components/auto/AutoEditor';
import { fetchConfigRequest } from '../../stores/database/actions';
import { mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  }
});

interface IConnectedProps {
  classes: any;
  db: IDatabaseState;
  fetchConfigRequest: typeof fetchConfigRequest;
}

interface ICourseViewPageProps {
  courseId?: number;
  onCourseSave?: (newId: number) => void;
}

const CourseViewPage = (props: ICourseViewPageProps & IConnectedProps) => {
  const { courseId, classes, onCourseSave } = props;

  const handleOnSave = (newId: number) => {
    if (onCourseSave) onCourseSave(newId);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <AutoEditor baseTable="training.course" editId={courseId} onSave={handleOnSave} mode="inline"/>
      </div>
    </Paper>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(CourseViewPage);

import moment from 'moment';
import * as React from 'react';
import { compose } from 'redux';
import { User } from 'oidc-client';
import { IAppState } from '../stores';
import { connect } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { createStyles, withStyles } from '@mui/styles';
import LoadingScreen from '../components/common/LoadingScreen';
import { fetchConfigRequest } from '../stores/database/actions';
import { getParameterByName } from '../abstracts/DataroweHelpers';
import { preloadBooking } from '../stores/training/booking/actions';
import { ITrainingBookState } from '../stores/training/booking/types';
import { Box, Button, Theme, Typography, useTheme } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const generateSxClasses = (_theme: Theme) => {
  return {
    fullHeight: {
      height: '100vh'
    },
    fullSize: {
      width: '100%',
      height: '100%',
      textAlign: 'center'
    },
    halfSize: {
      width: '100%',
      height: 'calc(50% - 64px)'
    },
    marginBottom: {
      marginBottom: '2vh'
    }
  };
};

interface IProps {
  action: string;
  book: ITrainingBookState;
  user: User;
  classes: any;
  preloadBooking: typeof preloadBooking;
  fetchConfigRequest: typeof fetchConfigRequest;
}

interface IActionsMap {
  key: string;
  apiUrl: string;
  urlParams?: string[];
}

interface IWaitlistActionProps {
  bookSessionId: number;
  startDate: string;
  endDate: string;
}

interface IActionResponse {
  url: string;
  message: string;
  isExternal: boolean;
  additional?: IWaitlistActionProps;
}

interface IActionPageState {
  error: boolean;
  message: string;
}

const knownActions: IActionsMap[] = [
  {
    key: 'waitlist',
    apiUrl: 'Action/Waitlist/:linkToken',
    urlParams: ['linkToken']
  }
];

function ActionPage(props: IProps) {
  const sxClasses = generateSxClasses(useTheme());
  const { action } = props;

  const [loadState, setLoadState] = React.useState<IActionPageState>({
    error: false,
    message: 'Processing...'
  });

  // Action -> State Callbacks (If required)
  const handleWaitlistAction = (additionalProps: IWaitlistActionProps) => {
    props.preloadBooking({
      sessionId: additionalProps.bookSessionId,
      startDate: moment(additionalProps.startDate),
      endDate: moment(additionalProps.endDate),
      trainingBookStep: 'ConfirmTraining'
    });
  };

  // Action -> State `switch()` for triggering above callback(s)
  const preloadState = (additional: IWaitlistActionProps) => {
    switch (action) {
      case 'waitlist':
        handleWaitlistAction(additional);
    }
  };

  // Action Verify and Handler
  const performAction = () => {
    if (action == null) {
      return setLoadState({
        error: true,
        message: 'Invalid URL. The URL is missing the "action" parameter.'
      });
    }

    const selectedAction = knownActions.find(knownAction => knownAction.key == action);
    if (!selectedAction) {
      return setLoadState({
        error: true,
        message: `Invalid Action. The selected Action "${action}" is not recognized.`
      });
    }

    let apiUrl = `${API_ENDPOINT}/${selectedAction.apiUrl}`;
    selectedAction.urlParams?.forEach(urlParam => apiUrl = apiUrl.replace(`:${urlParam}`, getParameterByName(urlParam, window.location.href, false) ?? 'undefined'));

    if (apiUrl.includes('undefined')) {
      return setLoadState({
        error: true,
        message: `Invalid URL. The URL is missing one or more of the following required paramaters: ${selectedAction.urlParams?.join(', ')}`
      });
    }

    axios.get(apiUrl).then((res: AxiosResponse<IActionResponse>) => {
      const iActionResponse = res.data;

      setLoadState({
        error: false,
        message: iActionResponse.message
      });

      if (typeof iActionResponse.additional !== 'undefined') {
        preloadState(iActionResponse.additional);
      }

      return setTimeout(() => {
        window.location.replace(iActionResponse.url);
      }, 3000);
    }).catch((error) => {
      let errorMessage = 'An error occurred. If this persists on reload, please contact BASES with the URL you are attempting to load.';

      if (error.response && typeof error.response.data?.message !== 'undefined') {
        errorMessage = `Unhandled Action. ${error.response.data.message}`;
      }

      setLoadState({
        error: true,
        message: errorMessage
      });
    });
  };

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'preloadDatabaseForAction',
      action: () => performAction()
    });
    return () => {};
  }, [props.action]);

  if (loadState.error) {
    return (
      <Box sx={{ ...sxClasses.fullHeight }}>
        <Box sx={{ ...sxClasses.fullSize }}>
          <Box sx={{ ...sxClasses.halfSize }}/>
          <Typography sx={{ ...sxClasses.marginBottom }}>{loadState.message}</Typography>
          <Button variant="contained" color="primary" onClick={() => window.location.replace('/')}>Back to Safety</Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ ...sxClasses.fullHeight }}>
      <LoadingScreen helpText={loadState.message}/>
    </Box>
  );
}

const mapUserToProps = ({ book, oidc }: IAppState) => {
  return {
    book,
    user: oidc.user
  };
};

const mapScheduleActions = {
  preloadBooking,
  fetchConfigRequest
};

export default compose(withStyles(createStyles({})), connect(mapUserToProps, mapScheduleActions))(ActionPage);

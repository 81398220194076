import React from 'react';
import { TextField } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { nameof } from '../../../abstracts/DataroweHelpers';
import { IProfileState } from '../../../stores/profile/types';
import { ICourse } from '../../../stores/database/training/courses';
import CourseSearchGrid from '../../../components/training/CourseSearchGrid';
import { IAutoEditorImplementation, IAutoEditorStateExtension } from '../autoInterfaces';

const styles = () => createStyles({});

interface ISelectCourseEditorProps extends IAutoEditorImplementation<number | undefined> {
  classes: any;
  isUserCompanyId?: boolean;
  profile: IProfileState;
}

interface ISelectCourseEditorState {
  courseId?: number;
  courseDisplay: string;
  open: boolean;
  dialogTitle: string;
  courses: ICourse[];
}

const SelectCourseEditor = (props: ISelectCourseEditorProps) => {
  const { field, error, warning, onValueUpdated } = props;

  const [editState, setEditState] = React.useState<ISelectCourseEditorState>({ courseDisplay: '', open: false, dialogTitle: '', courses: [] });

  const handleInteract = () => {
    if (!props.disabled && !props.item[props.field.columnId].readonly) {
      setEditState(old => ({
        ...old,
        open: true,
        dialogTitle: editState.courseDisplay.length > 0 ? `Replace ${editState.courseDisplay}` : 'Select Course'
      }));
    }
  };

  React.useEffect(() => {
    if (props.editValue == null) {
      setEditState(old => ({
        ...old,
        courseId: undefined,
        courseDisplay: ''
      }));
    } else if (props.editValue !== editState.courseId || (props.editValue != null && editState.courses.length > 0)) {
      setEditState(old => ({
        ...old,
        courseId: props.editValue,
        courseDisplay: props.editValue == null ? '' : old.courses.find(x => x.courseId === props.editValue)?.code ?? 'Invalid Company'
      }));
    }
    if (props.editValue != null && props.localState?.selectedCourse == null && editState.courses != null) {
      const c = editState.courses.find(x => x.courseId === props.editValue);

      props.onValueUpdated(props.editValue, field, props.editValue ? props.editValue.toString() : '', undefined, undefined, { [nameof<IAutoEditorStateExtension>('selectedCourse')]: c });
    }
  }, [props.editValue, editState.courses]);

  const handleCancel = () => setEditState(old => ({
    ...old,
    open: false,
    dialogTitle: ''
  }));

  const handleCoursesSelected = (courses: ICourse[]) => {
    setEditState(old => ({
      ...old,
      courseId: courses[0].courseId,
      courseDisplay: courses[0].code
    }));

    onValueUpdated(courses[0].courseId, field, courses[0].code, undefined, undefined, { [nameof<IAutoEditorStateExtension>('selectedCourse')]: courses[0] });
    handleCancel();
  };

  const handleCoursesLoaded = (courses: ICourse[]) => setEditState(old => ({ ...old, courses }));
  return (
    <>
      <TextField
        id={`companyEditor_${field.columnId}_display`}
        label={field.title}
        margin="dense"
        fullWidth
        required={field.required}
        value={editState.courseDisplay}
        error={!!error}
        helperText={error || warning || field.description}
        disabled={props.disabled}
        onClick={handleInteract}
        onKeyDown={handleInteract}
      />
      <CourseSearchGrid
        mode="direct"
        open={editState.open}
        title={editState.courseDisplay}
        onCoursesSelected={handleCoursesSelected}
        onCoursesLoaded={handleCoursesLoaded}
        limitSelectCount={1}
        onCancel={handleCancel}
        dialogTitle={editState.dialogTitle}
        {...(props.stateExtension?.courseSearchFilters ?? {})}
      />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(SelectCourseEditor);

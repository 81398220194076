import { Dictionary } from 'lodash';
import { AlertColor } from '@mui/lab/Alert';
import { ICardDisplay } from 'src/components/identity/WorkerCardDisplay';

export type IUiStateThemeModes = 'light' | 'dark' | 'automatic';

export const enum UIActions {
  SET_TITLE = '@@ui/UI_SET_TITLE',
  SET_SUBTITLE = '@@ui/UI_SET_SUBTITLE',
  SET_TITLE_SUBTITLE = '@@ui/UI_SET_TITLE_SUBTITLE',

  SET_API_CONNECTED = '@@ui/SET_API_CONNECTED',

  ALERT_CREATE = '@@ui/ALERT_CREATE',
  ALERT_CREATE_BULK = '@@ui/ALERT_CREATE_BULK',
  ALERT_NEXT = '@@ui/ALERT_NEXT',
  ALERT_REMOVE = '@@ui/ALERT_REMOVE',
  ALERT_REMOVE_ALL = '@@ui/ALERT_REMOVE_ALL',
  ALERT_MARK_ALL_READ = '@@ui/ALERT_MARK_ALL_READ',
  QR_SHOW_LINK = '@@ui/QR_SHOW_LINK',
  QR_CLEAR_LINK = '@@ui/QR_CLEAR_LINK',
  QUEUE_WORKER_LOOKUP = '@@ui/QUEUE_WORKER_LOOKUP',
  COMPLETE_WORKER_LOOKUP = '@@ui/COMPLETE_WORKER_LOOKUP',
  QUEUE_WORKER_ERROR = '@@ui/QUEUE_WORKER_ERROR',
  CLEAR_LOOKUP = '@@ui/CLEAR_LOOKUP',
  TOGGLE_THEME_MODE = '@@ui/TOGGLE_THEME_MODE'
}

export interface IWorkerLookup {
  timestamp: number;
  personId: number;
}

export interface IAlertDetails {
  created: string;
  page: string;
  title: string;
  description: string;
  viewed: boolean;
  autoDisplay: boolean;
  severity: AlertColor;
  autoDismiss?: false | number;
}

export interface IQRDetails {
  link: string;
  title: string;
}

export interface IUiState {
  apiAccessible: boolean;
  apiValidated?: number;
  helpGuid: string;
  title: string;
  subtitle: string;
  alerts: Dictionary<IAlertDetails>;
  alertOrder: number[];
  nextAlertId: number;
  displayAlert?: number;
  unseenAlerts: number;
  showQRLink?: IQRDetails;
  themeMode: IUiStateThemeModes;
  workerLookups: IWorkerLookup[];
  workerLookupCache: Dictionary<ICardDisplay | string>;
}

import axios from 'axios';
import reduxLogger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import UserManager from '../abstracts/UserManager';
import createSagaMiddleware from '@redux-saga/core';
import { createRootReducer, rootSaga } from '../stores';
import createOidcManager, { loadUser } from 'redux-oidc';
import { IDatabaseState } from '../stores/database/types';
import { ProfileActionTypes } from '../stores/profile/types';
import { persistStore, persistReducer } from 'redux-persist';
import { IUploadState } from '../stores/training/upload/types';
import { ICheckinState } from '../stores/training/checkin/types';
import { ITrainingBookState } from '../stores/training/booking/types';
import { applyMiddleware, createStore, AnyAction, Action, Dispatch } from 'redux';

export const versionNumber = +(process.env.REACT_APP_VERSION_NUMBER ?? -1);

export const checkVersionNumber = (stateVersion: number, payloadVersion: number, initialState: IUploadState | ICheckinState | ITrainingBookState | IDatabaseState, callback: Function): any => {
  if (stateVersion != payloadVersion) {
    return {
      ...initialState,
      version: payloadVersion
    };
  }

  return callback();
};

export const configureStore = () => {
  UserManager.events.addSilentRenewError(error => {
    console.error('error while renewing the access token', error);
  });

  const oidcMiddleware = createOidcManager(UserManager);
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    persistReducer(
      {
        storage,
        key: 'root',
        blacklist: ['router', 'db', 'oidc', 'profile'],
        version: versionNumber
      },
      createRootReducer()
    ),
    applyMiddleware(oidcMiddleware, sagaMiddleware, reduxLogger)
  );

  UserManager.events.addUserLoaded(u => {
    axios.defaults.headers.common.Authorization = `Bearer ${u.access_token}`;
    store.dispatch({ type: ProfileActionTypes.LOAD_ME });
  });

  loadUser(store, UserManager);

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return {
    store,
    persistor
  };
};

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

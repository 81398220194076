import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Reserves from '../booking/Reserves';
import AutoEditor from '../auto/AutoEditor';
import { Refresh } from '@mui/icons-material';
import settings from '../../abstracts/settings';
import DisplayField from '../common/DisplayField';
import { createStyles, withStyles } from '@mui/styles';
import { updateRosterStatus } from '../auto/AutoChange';
import { createAlertBulk } from 'src/stores/ui/actions';
import { fetchConfigRequest } from '../../stores/database/actions';
import { RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { objectMapArray, objectSortMap } from '../../abstracts/DataroweHelpers';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';
import { mergeCourseScheduleDate } from '../../stores/database/training/courseSchedule';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, AutoGridRowClickProps } from '../auto/AutoGrid';
import { Theme, Grid, Typography, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { ICustomColumnDefinition, getAllFields, AutoRow, autoLoad, quickLoadSingle, AutoLoadKeyDisplay, quickLoad } from '../auto/AutoLoad';
import { IFilterDefinition, FilterGroupType, IFilterCondition, IColumnMap, FilterConditionType, ISelectColumnMap, ISelectDisplay } from '../../stores/database/interfaces';

const styles = (theme: Theme) => createStyles({
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  }
});

interface ICheckinProps {
  classes: any;
  dates?: Date | Date[];
  courseScheduleId: number;
  minHeight?: number | string;
  maxHeight?: number | string;
}

interface IConnectedProps {
  db: IDatabaseState;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

interface IInternalProps {
  baseTableId: number;
  loadedConfig: boolean;
  courseScheduleField?: IColumnMap;
  extraColumns?: ISelectColumnMap[];
  setChangesProcessing: boolean;
}

interface IRosterState {
  loading: boolean;
  date?: ISelectDisplay;
  time?: ISelectDisplay;
  courseCode?: ISelectDisplay;
  courseTitle?: ISelectDisplay;
  instructors?: ISelectDisplay;
  minSize?: ISelectDisplay;
  location?: ISelectDisplay;
  room?: ISelectDisplay;
  statusType: { name: string; id: number }[];
  selectedStatus?: number;
}

const Roster = (props: ICheckinProps & IConnectedProps) => {
  const personId = 'person.worker.person.iecNumber';
  const workerScheduleId = 'workerScheduleId';
  const companyId = 'userCompany.company';
  const company = 'userCompany.company.name';
  const personFirstLegal = 'person.worker.person.firstLegal';
  const personFirstPreferred = 'person.worker.person.firstPreferred';
  const personLast = 'person.worker.person.last';
  const personPictureTakenUTC = 'person.worker.person.pictureTakenUTC';
  const scheduleSelfpay = 'selfPay';
  const scheduleStatus = 'workerScheduleStatus.statusName';

  const displayField: RowFormatter<string> = (r): string => `${r[personFirstPreferred] ?? r[personFirstLegal]} ${r[personLast]} (${r[personId]})`;

  const workerRosterParams: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps = {
    displayField,
    exportTitle: 'Roster',
    baseTable: 'training.workerSchedule',
    baseTableId: -1,
    displayColumns: [],
    additionalColumns: undefined,
    keyField: undefined,
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    selectedRows: {},
    loadedConfig: false,
    courseScheduleField: undefined,
    setChangesProcessing: false
  };

  const [gridParams, setGridParams] = React.useState(workerRosterParams);
  const [rosterDetails, setRosterDetails] = React.useState<IRosterState>({
    loading: false,
    statusType: []
  });

  const defaultColumns: ICustomColumnDefinition[] = [
    {
      key: scheduleStatus
    },
    {
      key: personId,
      displayControl: 'IECNumber',
      cellAlign: 'left'
    },
    {
      key: personLast
    },
    {
      key: personFirstLegal,
      title: 'First Name',
      display: (r) => (r[personFirstPreferred] == null ? r[personFirstLegal] : r[personFirstPreferred]),
      sort: (a, b) => {
        const aText = a[personFirstPreferred] == null ? a[personFirstLegal] : a[personFirstPreferred];
        const bText = b[personFirstPreferred] == null ? b[personFirstLegal] : b[personFirstPreferred];
        return aText.localeCompare(bText);
      }
    },
    {
      title: 'Company',
      key: company
    },
    {
      key: scheduleSelfpay,
      title: 'Self Pay',
      display: (r) => (r[scheduleSelfpay] ? 'Y' : 'N')
    },
    {
      key: workerScheduleId,
      title: 'Signature',
      displayControl: () => <div style={{ minWidth: 125, minHeight: 20, borderBottom: 'thin solid black' }} />
    }
  ];

  const extraColumns = [companyId, personPictureTakenUTC];

  const loadData = (gridProps: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps) => {
    const filters: IFilterCondition[] = [];

    filters.push({
      columnId: gridProps.courseScheduleField!.columnId,
      lookupPath: gridProps.courseScheduleField!.lookupPath || [],
      type: FilterConditionType.Equal,
      firstParameter: props.courseScheduleId
    });

    const search: IFilterDefinition = {
      baseTableId: gridProps.baseTableId,
      name: '',
      baseFilter: {
        type: FilterGroupType.And,
        children: filters
      }
    };

    setGridParams((old) => ({
      ...old,
      rowDataLoading: true
    }));

    autoLoad({
      search,
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk,
      baseTableId: gridProps.baseTableId,
      keyField: gridProps.keyField!,
      displayField: gridProps.displayField!,
      allFields: getAllFields(gridProps.displayColumns, gridProps.additionalColumns)
    }).then((res) => {
      setGridParams((old) => ({
        ...old,
        rowData: res || {},
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    });
  };

  React.useEffect(() => {
    if (!gridParams.rowDataLoading) {
      props.fetchConfigRequest(undefined, {
        key: 'trainingRoster',
        action: (db) => {
          if (gridParams.baseTable == null) return;
          const baseTableId = typeof gridParams.baseTable === 'string' ? db.getTableByCombinedName(gridParams.baseTable.toString()).tableId : +gridParams.baseTable;

          const [keyField, courseScheduleField] = db.mapSelectColumnsByNamesWithId(baseTableId, [workerScheduleId, 'courseSchedule']);
          const displayColumns = db.mapDisplayColumnsByNamesWithId(baseTableId, defaultColumns);
          const additionalColumns = db.mapSelectColumnsByNamesWithId(baseTableId, extraColumns);

          setGridParams((old) => ({
            ...old,
            baseTableId,
            keyField,
            courseScheduleField,
            displayColumns,
            additionalColumns,
            defaultSortColumn: 2,
            loadedConfig: true
          }));

          loadData({
            ...gridParams,
            baseTableId,
            keyField,
            courseScheduleField,
            displayColumns,
            additionalColumns,
            defaultSortColumn: 2,
            loadedConfig: true
          });

          quickLoadSingle({
            db,
            schema: 'training',
            table: 'courseSchedule',
            columns: {
              date: 'trainingDate',
              time: 'trainingTime',
              courseCode: 'course.code',
              courseTitle: 'course.title',
              instructors: 'seatDetails.instructors',
              minSize: 'seatMinimum',
              location: 'room.location.name',
              room: 'room.name'
            },
            filters: {
              courseScheduleId: props.courseScheduleId
            }
          }).then((v) => {
            setRosterDetails((old) => ({
              ...old,
              loading: false,
              ...v
            }));
          });

          quickLoad<{ id:number, name: string }>({
            db,
            schema: 'training',
            table: 'workerScheduleStatus',
            keyfield: 'workerScheduleStatusId',
            columns: {
              name: 'statusName',
              id: 'workerScheduleStatusId'
            },
            filters: {
              rosterOption: true
            }
          }).then((st) => {
            setRosterDetails((old) => ({
              ...old,
              statusType: objectSortMap(st, 'name', ({ item }) => item)
            }));
          });
        }
      });
    }
    return () => {};
  }, []);

  const handleSetStatusChange = () => {
    if (gridParams.selectedRows != null && rosterDetails.selectedStatus != null) {
      updateRosterStatus(
        rosterDetails.selectedStatus,
        objectMapArray(gridParams.selectedRows, (key) => +key)
      ).then(() => {
        setGridParams((old) => ({
          ...old,
          selectedRows: {}
        }));

        setRosterDetails((old) => ({
          ...old,
          selectedStatus: undefined
        }));

        loadData(gridParams);
      });
    }
  };

  const handleRowClick = (rowClickProps: AutoGridRowClickProps) => {
    setGridParams((old) => ({
      ...old,
      selectedRows: rowClickProps.updatedSelectedRows
    }));
  };

  const handleSelectAllClick = (selectAllProps: { event: React.ChangeEvent<HTMLInputElement>; checked: boolean; updatedSelectedRows: AutoLoadKeyDisplay }) => {
    setGridParams((old) => ({
      ...old,
      selectedRows: selectAllProps.updatedSelectedRows
    }));
  };

  const handleRosterRefresh = () => {
    loadData(gridParams);
  };

  const handleStatusChange = (event: SelectChangeEvent<number>) => setRosterDetails((old) => ({
    ...old,
    selectedStatus: event.target.value as number
  }));

  const displayProps = {
    fontScale: 0.9
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Typography variant="h4" component="span">
            Industrial Educational Co-Operative
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" component="span">
            Class Roster Listing
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <DisplayField
            {...displayProps}
            label={rosterDetails.date?.columnTitle ?? 'loading...'}
            value={rosterDetails.date?.display == null ? '' : mergeCourseScheduleDate(rosterDetails.date.display, rosterDetails.time?.display, settings.dateWeekdayFormatMoment)}
          />
        </Grid>
        <Grid item xs={3}>
          <DisplayField {...displayProps} label="Course Code" value={rosterDetails.courseCode?.display ?? ''} />
        </Grid>
        <Grid item xs={3}>
          <DisplayField {...displayProps} label="Location" value={rosterDetails.location?.display ?? ''} />
        </Grid>
        <Grid item xs={3}>
          <DisplayField {...displayProps} label="Room" value={rosterDetails.room?.display ?? ''} />
        </Grid>
        <Grid item xs={5}>
          <DisplayField {...displayProps} label="Course Title" value={rosterDetails.courseTitle?.display ?? ''} />
        </Grid>
        <Grid item xs={5}>
          <DisplayField {...displayProps} label={rosterDetails.instructors?.columnTitle ?? 'loading...'} value={rosterDetails.instructors?.display ?? ''} />
        </Grid>
        <Grid item xs={2}>
          <DisplayField {...displayProps} label={rosterDetails.minSize?.columnTitle ?? 'loading...'} value={rosterDetails.minSize?.display ?? ''} />
        </Grid>
      </Grid>
      <Reserves dense hideCourse hideCreate courseSchedule={props.courseScheduleId} />
      <Grid className="no-print" container spacing={1}>
        <Grid item xs>
          <FormControl fullWidth>
            <InputLabel id="selectStatus_label">Select Status to Use</InputLabel>
            <Select labelId="selectStatus_label" id="selectStatus" value={rosterDetails.selectedStatus || ''} onChange={handleStatusChange} disabled={Object.keys(gridParams.selectedRows ?? {}).length === 0}>
              {rosterDetails.statusType.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <Button onClick={handleSetStatusChange} variant="contained" color="primary" disabled={Object.keys(gridParams.selectedRows ?? {}).length === 0 || rosterDetails.selectedStatus == null}>
            Set Selected to Status
          </Button>
        </Grid>
        <Grid item xs>
          {gridParams.loadedConfig ? (
            <AutoEditor
              baseTable="training.workerSchedule"
              mode="button"
              onSave={handleRosterRefresh}
              buttonTitle="Quick Add Worker"
              customEditor="quickadd"
              unmappedAdditionalChanges={{
                CourseSchedule: props.courseScheduleId
              }}
            />
          ) : undefined}
        </Grid>
        <Grid item xs>
          <Button onClick={handleRosterRefresh} variant="contained" color="primary">
            <Refresh />
          </Button>
        </Grid>
      </Grid>
      <AutoGrid dense rowNumbers minHeight={props.minHeight} maxHeight={props.maxHeight} {...gridParams} onRowClick={handleRowClick} onSelectAllClick={handleSelectAllClick} />
    </div>
  );
};

export default compose(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(Roster);

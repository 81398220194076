import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { ReactNode } from 'react';
import DisplayField from '../common/DisplayField';
import { createStyles, withStyles } from '@mui/styles';
import { autoChange, ChangeMessage, IChangeContext } from './AutoChange';
import { ISelectColumnMap } from '../../stores/database/interfaces';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { RowFormatter } from '../../stores/database/gridExtension/interfaces';
import { AutoRow, ICustomSelectColumnMap, ICustomDisplayDefinition } from './AutoLoad';
import { mapConfigFetchToProps, getDisplayField, getCellDisplay, IConnectedProps } from './AutoGrid';
import { Divider, Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography, Grid, TextField, FormControl, FormHelperText } from '@mui/material';

interface IAutoDeleteProps {
  baseTable: string | number;
  deleteId: number;
  title?: string;
  header?: string;
  rowData?: AutoRow;
  key: string | number;
  displayField?: ISelectColumnMap | RowFormatter<string>;
  displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition)[];
  promptEmail?: boolean;
  changeContextDescription?: string;
  onDelete: (success: boolean, messages: ChangeMessage[]) => void;
}

export interface IAutoDeleteButtonProps extends IAutoDeleteProps {
  mode: 'button';
  open: boolean;
  buttonTitle: string;
  onCancel?: () => void;
}

export interface IAutoDeleteDirectProps extends IAutoDeleteProps {
  mode: 'direct';
  open: boolean;
  onCancel: () => void;
}

const AutoDeletePrompt = (props: (IAutoDeleteButtonProps | IAutoDeleteDirectProps) & IConnectedProps) => {
  const [state, setState] = React.useState({
    open: false,
    comment: '',
    email: ''
  });

  const handleOpen = () => {
    if (props.mode === 'button') {
      setState({
        open: true,
        comment: '',
        email: ''
      });
    }
  };

  const handleClose = () => {
    if (props.onCancel != null) props.onCancel();

    if (props.mode === 'button') {
      setState({
        open: true,
        comment: '',
        email: ''
      });
    }
  };

  const handleDelete = () => {
    const table = typeof props.baseTable === 'string' ? props.db.database.getTableByCombinedName(props.baseTable) : props.db.database.getTableById(props.baseTable);

    const emailMessage = state.email?.trim();

    const changeContext: IChangeContext = {
      description: props.changeContextDescription ?? 'GenericAutoDeletePrompt',
      triggerEmail: props.promptEmail === true && emailMessage !== '',
      emailMessage: emailMessage === '' ? undefined : emailMessage
    };

    autoChange({
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk,
      page: props.ui.title,
      title: `Delete from ${table.title}`,
      description: getDisplayField(props.rowData!, props.key, props.displayField),
      changes: {
        action: 'Delete',
        description: state.comment,
        changeSets: [
          {
            tableId: table.tableId,
            type: 'Delete',
            action: 'AutoDeletePrompt',
            key: props.deleteId,
            changes: [],
            changeContext
          }
        ]
      }
    }).then((res) => {
      props.onDelete(res.success, res.messages);

      if (props.mode === 'button') {
        setState({
          open: false,
          comment: '',
          email: ''
        });
      }
    });
  };

  const rowTitleDisplay = (): ReactNode => (props.displayField == null || props.rowData == null ? <Typography>Loading...</Typography> : <Typography variant="h6">{getDisplayField(props.rowData, props.key, props.displayField)}</Typography>);

  const rowColumnsDisplay = (): (ReactNode | undefined)[] =>
    props.displayColumns.map((col) => {
      if (props.rowData == null) return undefined;

      const display = getCellDisplay({ row: props.rowData, key: props.key, column:  col, forceText: true, linkNewPage: true });

      if (display === '') return undefined;

      return (
        <Grid key={`${col.columnAlias}_title`} item xs={12} sm={6} md={4}>
          <DisplayField label={typeof col.columnTitle === 'string' ? col.columnTitle : col.columnTitle.export} value={display}/>
        </Grid>
      );
    });

  const handleCommentChange = (comment: string) => setState((old) => ({
    ...old,
    comment
  }));

  const handleEmailChange = (email: string) => setState((old) => ({
    ...old,
    email
  }));

  return (
    <>
      {props.mode === 'button' ? (
        <Button variant="contained" size="small" onClick={handleOpen}>
          {props.buttonTitle}
        </Button>
      ) : undefined}
      <Dialog open={state.open || (props.mode === 'direct' && props.open)} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>{props.title ?? 'Confirm Delete Item'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {props.displayField == null ? undefined : rowTitleDisplay()}
            </Grid>
            {props.header == null ? undefined : (
              <Grid item xs={12}>
                <Typography variant="h6">{props.header}</Typography>
              </Grid>
            )}
            {rowColumnsDisplay()}
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Comment on deletion" rows={4} value={state.comment} fullWidth onChange={(event) => handleCommentChange(event.currentTarget.value)}/>
            </Grid>
            {!props.promptEmail ? undefined : (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField label="Message for email" rows={4} value={state.email} fullWidth onChange={(event) => handleEmailChange(event.currentTarget.value)}/>
                  <FormHelperText>Deleting this item will result in an email being sent to relevant parties.</FormHelperText>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default compose(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(AutoDeletePrompt);

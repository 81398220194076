import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { useNavigate } from 'react-router';
import { createAlertBulk } from '../../stores/ui/actions';
import { IProfileState } from '../../stores/profile/types';
import { fetchProfile } from '../../stores/profile/actions';
import CompanyEdit from '../../components/identity/CompanyEdit';
import CompanyList from '../../components/identity/CompanyList';
import { IConnectedProps } from '../../components/auto/AutoGrid';
import { Paper, Button, Theme, useTheme, Box } from '@mui/material';
import { loadCompaniesCache } from '../../stores/database/identity/companies';
import { customNavigate, useQueryParams } from '../../abstracts/NavigationHelpers';
import { fetchConfigRequest, refreshCacheData } from '../../stores/database/actions';

const generateSxClasses = (theme: Theme) => {
  return {
    buttonMargin: {
      marginBottom: theme.spacing(2)
    },
    contentWrapper: {
      mx: theme.spacing(2),
      my: theme.spacing(1.25)
    },
    paper: {
      margin: 'auto',
      overflow: 'hidden',
      marginBottom: theme.spacing(1.25)
    }
  };
};

interface ILocalConnectedProps extends Omit<IConnectedProps, 'classes'> {
  profile: IProfileState;
  fetchProfile: typeof fetchProfile;
}

interface ICompaniesPageProps {}

interface ICompaniesPageQueryParams {
  companyId?: number;
  filter?: string;
}

function CompaniesPage(props: ICompaniesPageProps & ILocalConnectedProps) {
  const sxClasses = generateSxClasses(useTheme());
  const navigate = useNavigate();

  const [{ companyId, filter }, setParams] = useQueryParams<ICompaniesPageQueryParams>({
    companyId: {
      type: 'number'
    },
    filter: {
      type: 'string'
    }
  });

  const handleQuickFilter = (quickFilter: string) => {
    setParams({
      companyId,
      filter: quickFilter === '' ? undefined : quickFilter
    });
  };

  const handleCompanySave = (newId: number) => {
    if (companyId !== newId) {
      loadCompaniesCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk, true).then(() => {
        props.fetchProfile(true);
        navigate(customNavigate.toCompany(newId));
      });
    }
  };

  const handleBackButton = () => {
    setParams({
      filter,
      companyId: undefined
    });
  };

  const handleCompanySelected = (selectCompanyId: number) => setParams({
    filter,
    companyId: selectCompanyId
  });

  const handleAddNew = () => setParams({
    filter,
    companyId: 0
  });

  return (
    <Paper sx={{ ...sxClasses.paper }}>
      <Box sx={{ ...sxClasses.contentWrapper }}>
        {companyId == null
          ? <CompanyList onCompanySelected={handleCompanySelected} onAddNew={handleAddNew} onQuickFilter={handleQuickFilter} quickFilter={filter} mode="inline" maxHeight="calc(100vh - 240px)"/>
          : <>
              <Button sx={{ ...sxClasses.buttonMargin }} variant="contained" onClick={handleBackButton} color="primary">
                Return to Company List
              </Button>
              <CompanyEdit companyId={companyId === 0 ? undefined : companyId} onCompanySave={handleCompanySave}/>
            </>}
      </Box>
    </Paper>
  );
}

function mapStateToProps(state: IAppState) {
  return {
    ui: state.ui,
    profile: state.profile,
    db: state.db
  };
}

const mapLocalConfigFetchToProps = {
  fetchConfigRequest,
  refreshCacheData,
  createAlertBulk,
  fetchProfile
};

export default compose(connect(mapStateToProps, mapLocalConfigFetchToProps))(CompaniesPage);

import moment from 'moment';
import * as React from 'react';
import { compose } from 'redux';
import { User } from 'oidc-client';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import BasesIcon from 'src/layout/BasesIcon';
import { useNavigate } from 'react-router';
import Timer from '../../components/booking/Timer';
import { createStyles, withStyles } from '@mui/styles';
import { HubConnectionState } from '@microsoft/signalr';
import { connectHub } from '../../stores/training/booking/reducer';
import ScheduleSubmit from 'src/components/booking/ScheduleSubmit';
import ScheduleReview from '../../components/booking/ScheduleReview';
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { ITrainingBookState, TrainingBookStep } from '../../stores/training/booking/types';
import WorkerTrainingLiveSchedule from '../../components/booking/WorkerTrainingLiveSchedule';
import { IBookTrainingConnectedProps, mapBookTrainingPage, scheduleGetClasses, signalrHubVerifySession } from '../../stores/training/booking/actions';
import { Paper, Stepper, Step, StepButton, Snackbar, IconButton, Typography, Fab, Button, DialogTitle, DialogContent, Dialog, DialogActions, Backdrop, Theme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  pageLayout: {
    flex: 1,
    flexDirection: 'column'
  },
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  dateValid: {
    color: 'ForestGreen'
  },
  dateExpiring: {
    color: 'Goldenrod'
  },
  dateExpired: {
    color: 'FireBrick'
  },
  selectableDate: {
    cursor: 'pointer'
  },
  selectableCell: {
    cursor: 'pointer'
  },
  selectedDate: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    cursor: 'pointer'
  },
  fabTimerWrapper: {
    alignItems: 'center',
    bottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      right: '15px',
      width: '200px'
    },
    [theme.breakpoints.down('xs')]: {
      left: '25%',
      width: '50%'
    },
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100% - 65px)'
    }
  },
  navButtons: {
    backgroundColor: theme.palette.secondary.light,
  },
  unselectedButton: {
    backgroundColor: theme.palette.common.white,
    border: `thin solid ${theme.palette.primary.light}`
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
});

export interface INewBookingProps {
  classes: any;
  book: ITrainingBookState;
  user: User;
  scheduleGetClasses: typeof scheduleGetClasses;
}

const BookTraining = (props: INewBookingProps & IBookTrainingConnectedProps) => {
  const { classes, book, bookSetStep, bookActionClearMessage, hubConnecting, hubDisconnect, ...bookHub } = props;

  function handleNoticeClose() {
    bookActionClearMessage();
  }

  const navigate = useNavigate();

  const steps: TrainingBookStep[] = ['ScheduleTrainingLive', 'ConfirmTraining', 'PayForTraining'];

  React.useEffect(() => {
    return () => {
      hubDisconnect();
    };
  }, []);

  React.useEffect(() => {
    if (book.hubConnection == null && !book.hubConnectionLoading) {
      hubConnecting();

      connectHub(
        bookHub,
        (url) => navigate(url),
        book.bookSessionId == null || book.loadedBookDates !== 'Loaded' ? undefined : (hub) => signalrHubVerifySession(
          hub,
          book.bookSessionId,
          book.courseSchedule.map((cs) => cs.courseScheduleId)
        )
      );
    } else if (!book.hubConnectionLoading && book.hubConnection != null && book.bookSessionId != null && book.bookSessionId < 0) {
      signalrHubVerifySession(
        book.hubConnection,
        book.bookSessionId,
        book.courseSchedule.map((cs) => cs.courseScheduleId)
      );
    }

    if (book.hubConnection && book.loadedBookDates === 'AutoLoadOnConnect') {
      props.scheduleGetClasses({
        sessionId: book.bookSessionId!,
        startDate: book.bookDateStart,
        endDate: book.bookDateEnd,
        courseIds: [], // uniq(getTrainingListCourseIds(book.trainingList)),
        fetchConfigRequest: props.fetchConfigRequest,
        refreshCacheData: props.refreshCacheData,
        createAlertBulk: props.createAlertBulk
      });
    }
  });

  const stepTitle = (step: TrainingBookStep) => {
    switch (step) {
      case 'ScheduleTrainingLive':
        return 'Add Workers to Schedule';
      case 'ConfirmTraining':
        return 'Review Scheduled Training';
      case 'PayForTraining':
        return 'Submit Scheduled Training';
    }
  };

  const [timerExpired, setTimerExpired] = React.useState(false);

  const stepControl = () => {
    switch (book.trainingBookStep) {
      case 'ScheduleTrainingLive':
        return <WorkerTrainingLiveSchedule timerExpired={timerExpired} />;
      case 'ConfirmTraining':
        return <ScheduleReview />;
      case 'PayForTraining':
        return <ScheduleSubmit />;
    }
  };

  const handleTimerZero = () => {
    console.log(`Handle Timer 0 reached - ${book.reserveEndTime == null ? 'Reserve is null' : book.reserveEndTime.isBefore(moment()) ? 'reserve is expired' : 'reserve is active'}`, book);
    if (book.loadedBookDates === 'Loaded') {
      setTimerExpired(true);
    }
  };

  const handleReloadClick = () => {
    if (book.hubConnection != null && book.hubConnection.state === HubConnectionState.Disconnected) {
      book.hubConnection.start().then(() =>
        signalrHubVerifySession(
          book.hubConnection!,
          book.bookSessionId,
          book.courseSchedule.map((cs) => cs.courseScheduleId)
        )
      );
    } else if (book.hubConnection != null) {
      signalrHubVerifySession(
        book.hubConnection,
        book.bookSessionId,
        book.courseSchedule.map((cs) => cs.courseScheduleId)
      );
    }
    setTimerExpired(false);
  };

  const handleResetClick = () => {
    bookHub.bookRestart();
    setTimerExpired(false);
  };

  return (
    <div className={classes.pageLayout}>
      <Paper className={classes.paper}>
        <div className={classes.contentWrapper}>
          <Stepper nonLinear activeStep={steps.indexOf(book.trainingBookStep)}>
            {steps.map((step, idx) => (
              <Step key={step}>
                <StepButton onClick={() => bookSetStep(steps[idx])}>{stepTitle(step)}</StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        {book.userMessage !== undefined && book.userMessage.type === 'Error' ? (
          <div className={classes.contentWrapper}>
            <Typography>{book.userMessage.message}</Typography>
          </div>
        ) : undefined}
      </Paper>
      <br />
      {stepControl()}
      {book.reserveEndTime === undefined || book.loadedBookDates !== 'Loaded' ? undefined : (
        <Paper elevation={3} className={classes.fabTimerWrapper}>
          <IconButton className={classes.navButtons} disabled={book.trainingBookStep === 'ScheduleTrainingLive'} onClick={() => bookSetStep(steps[steps.indexOf(book.trainingBookStep) - 1])}>
            <KeyboardArrowLeft />
          </IconButton>
          <Fab className={classes.fabTimer}>
            <Timer endTimeUTC={book.reserveEndTime} maxInterval={300000} onComplete={handleTimerZero} />
          </Fab>
          <IconButton className={classes.navButtons} disabled={book.trainingBookStep === 'PayForTraining'} onClick={() => bookSetStep(steps[steps.indexOf(book.trainingBookStep) + 1])}>
            <KeyboardArrowRight />
          </IconButton>
        </Paper>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={book.userMessage !== undefined && book.userMessage.type === 'Alert'}
        autoHideDuration={4500}
        onClose={handleNoticeClose}
        ContentProps={{ 'aria-describedby': 'message-id' }}
        message={<span id="message-id">{book.userMessage !== undefined && book.userMessage.type === 'Alert' ? book.userMessage.message : '...'}</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" className={classes.close} onClick={handleNoticeClose}>
            <Close />
          </IconButton>
        ]}
      />
      <Dialog open={timerExpired}>
        <DialogTitle>Session has timed out</DialogTitle>
        <DialogContent>Timer has expired. Please click here to reload. Seats are no longer reserved, but you can attempt to reserve them again - classes with available seats will be reconnected.</DialogContent>
        <DialogActions>
          <Button onClick={handleReloadClick}>Attempt to Reserve</Button>
          <Button onClick={handleResetClick}>Start Over</Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={book.loadedBookDates === 'LoadingTrainingSchedule' || book.trainingSubmitting}>
        <BasesIcon animated lg />
      </Backdrop>
    </div>
  );
};

const mapBookFromAppState = ({ book, oidc }: IAppState) => {
  return {
    book,
    user: oidc.user
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapBookFromAppState, mapBookTrainingPage))(BookTraining);

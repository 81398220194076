import moment from 'moment';
import { compose } from 'redux';
import { flatten } from 'lodash';
import { User } from 'oidc-client';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { Delete } from '@mui/icons-material';
import settings from '../../abstracts/settings';
import InlineTextbox from '../common/InlineTextbox';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../../stores/profile/types';
import AutoGrid, { getBoolCheckDisplay } from '../auto/AutoGrid';
import { fetchConfigRequest } from '../../stores/database/actions';
import { AutoRow, ICustomDisplayDefinition } from '../auto/AutoLoad';
import { RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { Theme, Paper, Checkbox, Toolbar, Typography } from '@mui/material';
import { formatFitTestSchedule } from '../../stores/database/training/courses';
import { arrayMapObject, objectMapArray } from '../../abstracts/DataroweHelpers';
import DateRangeEdit, { datesToString, IDateRange } from '../common/DateRangeEdit';
import { ITrainingBookState, IPersonTraining, ISessionReserveWorker, ISessionWebTraining } from '../../stores/training/booking/types';
import { signalrHubCancelWebAssign, signalrHubRemoveReservation, signalrHubUpdatePurchaseOrderClassroom, signalrHubUpdatePurchaseOrderWebBased, signalrHubUpdateSelfPay, signalrHubUpdateWaitlistDates } from '../../stores/training/booking/actions';

const styles = (theme: Theme) => createStyles({
  centerSmall: {
    fontSize: 'small',
    textAlign: 'center',
    opacity: '50%'
  },
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  fabSubmit: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(14)
  }
});

interface IScheduleReviewProps {
  classes: any;
  book: ITrainingBookState;
  user: User;
  profile: IProfileState;
}

interface IConnectedProps {
  fetchConfigRequest: typeof fetchConfigRequest;
}

const ScheduleReview = (props: IScheduleReviewProps & IConnectedProps) => {
  const { classes, book, profile } = props;

  interface IScheduledPersonTrainingDisplay extends ISessionReserveWorker {
    courseScheduleId: number;
    personId: number;
    personName: string;
    courseCode: string;
    courseTitle: string;
    company: string;
    trainingDateDisplay: string;
  }

  interface IAssignWebTrainingDisplay extends ISessionWebTraining {
    courseId: number;
    personId: number;
    personName: string;
    courseCode: string;
    courseTitle: string;
    company: string;
    trainingDateDisplay: string;
  }

  const handleWorkerDeleteReserve = (personId: number, courseScheduleId: number) => {
    signalrHubRemoveReservation(book.hubConnection!, book.bookSessionId!, personId, courseScheduleId);
  };

  const handleWorkerCancelAssign = (personId: number, courseId: number) => {
    signalrHubCancelWebAssign(book.hubConnection!, book.bookSessionId!, personId, courseId);
  };

  const priceColumn: ICustomDisplayDefinition<IScheduledPersonTrainingDisplay> = {
    columnAlias: 'price',
    columnTitle: 'Price',
    display: (row) => Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(row[profile.pricingField])
  };

  const selfPayColumn: ICustomDisplayDefinition<IScheduledPersonTrainingDisplay> = {
    columnAlias: 'selfPay',
    columnTitle: 'Self Pay',
    hidden: (p) => p.companies.filter(c => c.isPartner).length === 0,
    display: (r) => getBoolCheckDisplay(r.selfPay),
    displayControl: (r) => (
      <Checkbox
        key="selfPay"
        disabled={!profile.companies.find(c=>c.userCompanyId === r.userCompanyId)?.isPartner}
        checked={r.selfPay}
        onClick={(event) => {
          event.stopPropagation();
          signalrHubUpdateSelfPay(book.hubConnection!, book.bookSessionId!, [{ personId: r.personId, reserveId: r.bookSessionReserveId, update: !r.selfPay }]);
          // handleSelfPayChange(r.personId)
        }}
      />
    )
  };

  const purchaseOrderColumnClassroom: ICustomDisplayDefinition<IScheduledPersonTrainingDisplay> = {
    columnAlias: 'purchaseOrder',
    columnTitle: 'Purchase Order',
    display: (r) => r.purchaseOrder ?? '',
    displayControl: (r) => (
      <InlineTextbox
        uniqueId="purchaseOrder"
        defaultValue={r.purchaseOrder}
        onValueUpdated={(text) => signalrHubUpdatePurchaseOrderClassroom(book.hubConnection!, book.bookSessionId!, [{ personId: r.personId, reserveId: r.bookSessionReserveId, update: text }])}
      />
    )
  };

  const purchaseOrderColumnWebBased: ICustomDisplayDefinition<IAssignWebTrainingDisplay> = {
    columnAlias: 'purchaseOrder',
    columnTitle: 'Purchase Order',
    display: (r) => r.purchaseOrder ?? '',
    displayControl: (r) => <InlineTextbox uniqueId="purchaseOrder" defaultValue={r.purchaseOrder} onValueUpdated={(text) => signalrHubUpdatePurchaseOrderWebBased(book.hubConnection!, book.bookSessionId!, [{ personId: r.personId, reserveId: r.bookSessionWebTrainingId, update: text }])} />
  };

  const onWaitlistUpdated = (personId: number, reserveId?: number) => (date?: IDateRange) => {
    if (reserveId != null) {
      signalrHubUpdateWaitlistDates(book.hubConnection!, book.bookSessionId!, [{ personId, reserveId, update: date }]);
    }
  };

  const waitlistDatesToRange = (start?: string, end?: string): IDateRange => {
    if (start == null || end == null) return {};

    return { startDate: start, endDate: end };
  };

  const waitlistRowToRange = (r: IScheduledPersonTrainingDisplay): IDateRange => waitlistDatesToRange(r.waitlistStart, r.waitlistEnd);

  const waitlistColumn: ICustomDisplayDefinition<IScheduledPersonTrainingDisplay> = {
    columnAlias: '',
    columnTitle: 'Add to Waitlist',
    display: (r) => datesToString(waitlistRowToRange(r), { empty: 'Not Waitlisted' }),
    hidden: (pr) => (pr.exclusions ?? []).indexOf('Waitlists') >= 0,
    displayControl: (r) => {
      if (r.course.scheduleTypeKey !== 'classroom') return <Typography className={classes.centerSmall}>N/A</Typography>;

      const cantWaitlist = moment().add(1, 'day').isSame(moment(r.trainingDate), 'day');
      const isWaitlisted = r.waitlistEnd && r.waitlistStart;

      let buttonTitle = 'Waitlist';
      if (isWaitlisted) {
        buttonTitle = r.waitlistStart == r.waitlistEnd ? moment(r.waitlistStart).format(settings.dateFormatMoment) : `${moment(r.waitlistStart).format(settings.dateFormatMoment)} - ${moment(r.waitlistEnd).format(settings.dateFormatMoment)}`;
      }

      return <DateRangeEdit
        dateRange={waitlistRowToRange(r)}
        mode="button"
        buttonTitle={buttonTitle}
        buttonDisabled={cantWaitlist}
        onSave={onWaitlistUpdated(r.personId, r.bookSessionReserveId)}
        minDate={moment().add(1, 'day').format(settings.dateFormatMoment)}
        maxDate={moment(r.trainingDate).subtract(1, 'day').format(settings.apiDateFormatMoment)}
        titleOverrides={{
          dialogHeader: `Add worker to ${r.courseTitle} waitlist`,
          description: 'Waitlist this worker for this course. Date range must be from tomorrow to one day before the date of the training to be booked.<br/><br/><small><em>Note: worker is not on the waitlist until the training is booked.</em></small>',
          saveButton: 'Set waitlist dates',
          resetFilterButton: 'Remove from waitlist'
        }}
      />;
    }
  };

  const displayField: RowFormatter<string, undefined> = (r) => `${r.displayName} - ${r.courseCode}`;

  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography>Scheduled Classroom Training</Typography>
        </Toolbar>
        <div className={classes.contentWrapper}>
          <AutoGrid
            exportTitle="Review Schedule"
            key="reserveId"
            keyField="reserveId"
            displayField={displayField}
            emptyMessage="No scheduled training to show"
            rowDataLastUpdate={book.lastRender ?? -1}
            rowData={arrayMapObject(
              flatten(
                objectMapArray(book.personTraining, (personId, person: IPersonTraining) => {
                  return objectMapArray(person?.reservedClassTraining ?? {}, (reserveId, sched: ISessionReserveWorker): IScheduledPersonTrainingDisplay | undefined => {
                    return sched.bookSessionReserveId < 0 ? undefined : {
                      ...sched,
                      courseScheduleId: +reserveId,
                      personId: +personId,
                      personName: person.displayName,
                      courseCode: sched.course.code,
                      courseTitle: sched.timeslotData?.map((s) => formatFitTestSchedule(s, book.courses)).join('/') ?? sched.course.title,
                      company: profile.companies.find((x) => x.userCompanyId === sched.userCompanyId)?.name ?? 'No Company Specified',
                      trainingDateDisplay:
                        sched.timeslotData == null
                          ? moment(sched.trainingDate).format(settings.dateWeekdayTimeFormatMoment)
                          : `${moment(sched.trainingDate).format(settings.dateFormatMoment)} ${moment(sched.timeslotData[0].scheduledTime, settings.apiTimeFormatMoment).format(settings.timeFormatMoment)}`
                    };
                  });
                })
              ),
              (idx, val) => [val.bookSessionReserveId!, val]
            )}
            // rowDataLastUpdate={moment().valueOf()}
            displayColumns={[
              {
                columnAlias: 'personId',
                columnTitle: 'IEC Number'
              },
              {
                columnAlias: 'personName',
                columnTitle: 'Worker'
              },
              {
                columnAlias: 'courseCode',
                columnTitle: 'Course Code'
              },
              {
                columnAlias: 'courseTitle',
                columnTitle: 'Course Name'
              },
              {
                columnAlias: 'company',
                columnTitle: 'Company Name'
              },
              {
                columnAlias: 'trainingDateDisplay',
                columnTitle: 'Date/Time'
              },
              selfPayColumn,
              priceColumn,
              purchaseOrderColumnClassroom,
              waitlistColumn
            ]}
            preActions={{
              icon: <Delete />,
              title: 'Delete Schedule',
              onClick: (r: AutoRow) => handleWorkerDeleteReserve(r.personId, r.courseScheduleId)
            }}
          />
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography>Assigned Web-Based Training</Typography>
        </Toolbar>
        <div className={classes.contentWrapper}>
          <AutoGrid
            exportTitle="Review Assigned"
            key="reserveId"
            keyField="reserveId"
            displayField={displayField}
            emptyMessage="No assigned web courses to show"
            rowDataLastUpdate={book.lastRender ?? -1}
            rowData={arrayMapObject(
              flatten(
                objectMapArray(book.personTraining, (personId, person: IPersonTraining) => {
                  return objectMapArray(person.assignedWebTraining, (courseId, assign: ISessionWebTraining): IAssignWebTrainingDisplay | undefined => {
                    return assign.bookSessionWebTrainingId == null ? undefined : {
                      ...assign,
                      personId: +personId,
                      courseId: +courseId,
                      personName: person.displayName,
                      courseCode: assign.course.code,
                      courseTitle: assign.course.title,
                      company: profile.companies.find((x) => x.userCompanyId === assign.userCompanyId)?.name ?? 'No Company Specified',
                      trainingDateDisplay: ''
                    };
                  });
                })
              ), (idx, val) => [val.bookSessionWebTrainingId!, val]
            )}
            displayColumns={[
              {
                columnAlias: 'personId',
                columnTitle: 'IEC Number'
              },
              {
                columnAlias: 'personName',
                columnTitle: 'Worker'
              },
              {
                columnAlias: 'courseCode',
                columnTitle: 'Course Code'
              },
              {
                columnAlias: 'courseTitle',
                columnTitle: 'Course Name'
              },
              {
                columnAlias: 'company',
                columnTitle: 'Company Name'
              },
              {
                columnAlias: 'trainingDate',
                columnTitle: 'Date/Time'
              },
              priceColumn,
              purchaseOrderColumnWebBased
            ]}
            preActions={{
              icon: <Delete />,
              title: 'Delete Schedule',
              onClick: (r: AutoRow) => handleWorkerCancelAssign(r.personId, r.courseId)
            }}
          />
        </div>
      </Paper>
    </>
  );
};

const mapFromAppState = ({ book, oidc, profile }: IAppState) => {
  return {
    book,
    profile,
    user:
      oidc.user
  };
};

const mapScheduleActions = {
  fetchConfigRequest
};

export default compose(withStyles(styles), connect(mapFromAppState, mapScheduleActions))(ScheduleReview);

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { createStyles, withStyles } from '@mui/styles';
import { formatSeatsOrTime } from '../../components/training/CourseSchedule';
import { courseScheduleConfig } from '../../stores/database/training/courseSchedule';
import { arrayMapObject, objectLeftJoin, objectSortMap } from '../../abstracts/DataroweHelpers';
import AutoGrid, { IAutoGridProps, IConnectedProps, mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import DateRangeEdit, { dateRangeToFilter, datesToString, IDateRange } from '../../components/common/DateRangeEdit';
import { FilterConditionType, FilterGroupType, IFilterCondition, IFilterGroup, ISelectColumnMap } from '../../stores/database/interfaces';
import { FormControl, InputLabel, Paper, Select, Typography, Grid, MenuItem, Button, Checkbox, FormControlLabel, Theme, SelectChangeEvent } from '@mui/material';
import { autoLoad, AutoRow, getAllFields, ICustomColumnDefinition, ICustomDisplayDefinition, ICustomSelectColumnMap } from '../../components/auto/AutoLoad';

const styles = (theme: Theme) => createStyles({
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '10px 16px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  }
});

interface IDashboardProps {
  classes: any;
}

const ReportsPage = (props: IDashboardProps & IConnectedProps) => {
  const { classes } = props;

  interface IFilterState {
    date?: IDateRange;
  }

  const [filterState, setFilterState] = React.useState<Dictionary<any>>({});

  interface ParamConfig extends IAutoGridProps<AutoRow, ISelectColumnMap | undefined> {
    loadingConfig: boolean;
    loadedConfig: boolean;
    ddlKey: string;
    filteredFields: ISelectColumnMap[];
  }

  const workerRosterParams: ParamConfig = {
    ddlKey: '',
    exportTitle: '',
    baseTable: '',
    displayColumns: [],
    additionalColumns: undefined,
    keyField: undefined,
    displayField: undefined,
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    loadingConfig: false,
    loadedConfig: false,
    filteredFields: [],
    emptyMessage: 'Please apply filters and click "Run"'
  };

  const [gridParams, setGridParams] = React.useState(workerRosterParams);

  interface IReportConfig {
    ddlKey: string;
    exportTitle: string;
    baseTable: string;
    displayColumnNames: ICustomColumnDefinition[];
    additionalColumns: string[];
    userFilters: string[];
    defaultFilters?: Dictionary<any>;
  }

  const reports: Dictionary<IReportConfig> = {
    'course-schedules': {
      ddlKey: 'course-schedules',
      exportTitle: 'Course Schedule Seats',
      baseTable: courseScheduleConfig._table,
      displayColumnNames: [
        {
          key: courseScheduleConfig.trainingDate
        },
        {
          key: courseScheduleConfig.trainingTime
        },
        {
          key: courseScheduleConfig.recordsUpload
        },
        {
          key: courseScheduleConfig.seatMin,
          display: formatSeatsOrTime
        },
        {
          key: courseScheduleConfig.seatMaxDisplay,
          display: formatSeatsOrTime
        },
        {
          key: courseScheduleConfig.seatsUsed,
          display: formatSeatsOrTime
        },
        {
          key: courseScheduleConfig.seatEnrolment,
          display: formatSeatsOrTime
        },
        {
          key: courseScheduleConfig.seatsReserved,
          display: formatSeatsOrTime
        },
        {
          key: courseScheduleConfig.courseCode,
          display: (r) => `${r[courseScheduleConfig.courseCode]}${r[courseScheduleConfig.flagOffsite] ? 'F' : ''}${r[courseScheduleConfig.flagPilot] ? 'P' : ''}${r[courseScheduleConfig.flagSpecial] ? 'S' : ''}${r[courseScheduleConfig.flagUpload] ? 'U' : ''}`
        },
        {
          key: courseScheduleConfig.courseTitle
        },
        {
          key: courseScheduleConfig.scheduleRoom
        },
        {
          key: courseScheduleConfig.displayPricePartner
        },
        {
          key: courseScheduleConfig.displayPriceNonPartner
        }
      ],
      additionalColumns: [courseScheduleConfig.flagOffsite, courseScheduleConfig.flagPilot, courseScheduleConfig.flagSpecial, courseScheduleConfig.flagUpload, courseScheduleConfig.seatType],
      userFilters: [courseScheduleConfig.trainingDate],
      defaultFilters: { [courseScheduleConfig.recordsUpload]: false }
    },
    'finance-daily': {
      ddlKey: 'finance-daily',
      exportTitle: 'Finance Attendance Report',
      baseTable: 'training.workerSchedule',
      displayColumnNames: [
        {
          key: 'person.worker'
        },
        {
          key: 'person.worker.person'
        },
        {
          key: 'workerScheduleStatus.statusName'
        },
        {
          key: 'courseSchedule.trainingDate'
        },
        {
          key: 'checkinUTC'
        },
        {
          key: 'scheduledTime'
        },
        {
          key: ['training.workerHistory.workerSchedule', 'training.workerHistory.course', 'training.course.title'],
          display: (r, k, c) => r[c!.columnAlias] ?? r['courseSchedule.course.title']
        }
      ],
      additionalColumns: ['courseSchedule.course.title'],
      userFilters: ['courseSchedule.trainingDate'],
      defaultFilters: { 'courseSchedule.recordsUpload': false }
    }
  };

  const loadData = (config: ParamConfig, loadFilterState: IFilterState) => {
    if (config.baseTable == null) return;

    const filters: (IFilterCondition | IFilterGroup)[] = [];

    config.filteredFields.forEach((f) => {
      if (loadFilterState[f.columnAlias] === null || loadFilterState[f.columnAlias] === undefined) return;

      switch (f.columnType) {
        case 'Date':
        case 'DateTime':
          filters.push(dateRangeToFilter({ range: loadFilterState[f.columnAlias], column: f, isUTC: f.columnAlias.endsWith('UTC'), includeTime: f.columnType === 'DateTime' }));
          break;
        case 'Bool':
          filters.push({
            columnId: f.columnId,
            lookupPath: f.lookupPath ?? [],
            type: FilterConditionType.Equal,
            firstParameter: loadFilterState[f.columnAlias]
          });
          break;
      }
    });

    setGridParams((old) => ({ ...old, rowDataLoading: true }));

    autoLoad({
      search: {
        baseTableId: +config.baseTable,
        name: '',
        baseFilter:
          filters.length === 1
            ? filters[0]
            : {
              type: FilterGroupType.And,
              children: filters
            }
      },
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk,
      baseTableId: +config.baseTable,
      keyField: config.keyField!,
      displayField: config.displayField!,
      allFields: getAllFields(config.displayColumns, config.additionalColumns)
    }).then((res) => {
      setGridParams((old) => ({
        ...old,
        rowData: res || {},
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    });
  };

  const handleReportChange = (event: SelectChangeEvent) => {
    const ddlKey = event.target.value;

    setGridParams((old) => ({
      ...old,
      ddlKey,
      loadingConfig: true,
      loadedConfig: false
    }));

    props.fetchConfigRequest(undefined, {
      key: 'LoadReportConfig',
      action: (db) => {
        const baseTable = typeof reports[ddlKey].baseTable === 'string' ? db.getTableByCombinedName(reports[ddlKey].baseTable) : db.getTableById(+reports[ddlKey].baseTable);

        const keyField = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.primaryKeyId!]);
        const displayField = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.displayKeyId!]);

        const filteredFields: ISelectColumnMap[] = db.mapSelectColumnsByNamesWithId(baseTable.tableId, reports[ddlKey].userFilters);

        const defaultFilterState: Dictionary<any> = {
          ...arrayMapObject(filteredFields, (idx, map) => {
            switch (map.columnType) {
              case 'Bool':
                return [map.columnAlias, false];
              default:
                return [map.columnAlias, null];
            }
          }),
          ...(reports[ddlKey].defaultFilters ?? {})
        };

        const displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition)[] = db.mapDisplayColumnsByNamesWithId(baseTable.tableId, reports[ddlKey].displayColumnNames);
        const additionalColumns = db.mapSelectColumnsByNamesWithId(baseTable.tableId, reports[ddlKey].additionalColumns);

        setGridParams((old) => ({
          ...old,
          keyField,
          displayField,
          filteredFields,
          displayColumns,
          additionalColumns,
          baseTable: baseTable.tableId,
          loadedConfig: true,
          loadingConfig: false
        }));

        setFilterState((old) => objectLeftJoin(defaultFilterState, old));

        loadData({
          ...gridParams,
          keyField,
          displayField,
          filteredFields,
          displayColumns,
          additionalColumns,
          baseTable: baseTable.tableId
        }, defaultFilterState);
      }
    });
  };

  const getFilterControl = (map: ISelectColumnMap) => {
    switch (map.columnType) {
      case 'Date':
      case 'DateTime':
        return (
          <DateRangeEdit
            mode="button"
            optional
            nullableStart
            nullableEnd
            dateRange={filterState[map.columnAlias]}
            onSave={(date) => setFilterState((old) => ({ ...old, [map.columnAlias]: date }))}
            buttonTitle={`${map.columnTitle}\n${filterState[map.columnAlias] == null ? 'Not Filtered' : datesToString(filterState[map.columnAlias])}`}
            titleOverrides={{ dialogHeader: `Filter by ${map.columnTitle}` }}
          />
        );
      case 'Bool':
        return (
          <FormControlLabel
            control={<Checkbox checked={!!filterState[map.columnAlias]} onChange={() => setFilterState((old) => ({ ...old, [map.columnAlias]: !filterState[map.columnAlias] }))} value={map.columnAlias} color="primary" />}
            label={map.columnTitle}
          />
        );
      default:
        return (
          <Typography>
            Unsupported Filter Type - {map.columnType} ({map.columnTitle})
          </Typography>
        );
    }
  };

  const getFilter = (map: ISelectColumnMap) => (
    <Grid item xs={12} sm={6} md={3}>
      {getFilterControl(map)}
    </Grid>
  );

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.contentWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="report-label">Select Report</InputLabel>
                <Select label="Select Report" labelId="report-label" id="report" value={gridParams.ddlKey} onChange={handleReportChange}>
                  {objectSortMap(reports, 'exportTitle', (x) => (
                    <MenuItem key={x.item.ddlKey} value={x.item.ddlKey}>
                      {x.item.exportTitle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {gridParams.filteredFields.map((m) => getFilter(m))}
            <Grid item xs={12} sm={6} md={3}>
              <Button variant="contained" size="small" onClick={() => loadData(gridParams, filterState)} disabled={gridParams.ddlKey === ''}>
                Run Report
              </Button>
            </Grid>
            <Grid item xs={12}>
              {gridParams.loadedConfig ? <AutoGrid {...gridParams} pagination maxHeight="calc(100vh - 200px)" /> : <Typography>Please select a report</Typography>}
            </Grid>
          </Grid>
        </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    profile: state.profile
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapConfigFetchToProps))(ReportsPage);

import React from 'react';
import { Dictionary } from 'lodash';
import { AutoRow } from '../AutoLoad';
import { TextField } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { IProfileState } from '../../../stores/profile/types';
import { objectEach } from '../../../abstracts/DataroweHelpers';
import CompanyList from '../../../components/identity/CompanyList';
import { ICompanyAlias } from '../../../stores/database/interfaces';

const styles = () => createStyles({});

interface ISelectCompanyEditorProps extends IAutoEditorImplementation<number | undefined> {
  classes: any;
  isUserCompanyId?: boolean;
  profile: IProfileState;
}

interface ISelectCompanyEditorState {
  companyId?: number;
  userCompanyId?: number;
  companyDisplay: string;
  open: boolean;
  dialogTitle: string;
  aliases?: ICompanyAlias;
  companies: Dictionary<AutoRow>;
}

const SelectCompanyEditor = (props: ISelectCompanyEditorProps) => {
  const { field, error, warning, onValueUpdated } = props;

  const [editState, setEditState] = React.useState<ISelectCompanyEditorState>({
    companyDisplay: '',
    open: false,
    dialogTitle: '',
    companies: {}
  });

  const handleCompanySelected = (companyId: number, companyDisplay: string, userCompanyId?: number) => {
    setEditState(old => ({
      ...old,
      companyId,
      userCompanyId,
      companyDisplay,
      open: false,
      dialogTitle: ''
    }));

    onValueUpdated(props.isUserCompanyId ? userCompanyId : companyId, field, companyDisplay, undefined, undefined, { companyId, userCompanyId });
  };

  const handleCompanyCancel = () => setEditState(old => ({
    ...old,
    open: false,
    dialogTitle: ''
  }));

  const handleCompaniesLoaded = (aliases: ICompanyAlias, companies: Dictionary<AutoRow>) => {
    setEditState(old => ({
      ...old,
      aliases,
      companies,
      open: false,
      dialogTitle: ''
    }));
  };

  const handleInteract = () => {
    if (!props.disabled && !props.item[props.field.columnId].readonly) {
      setEditState(old => ({
        ...old,
        open: true,
        dialogTitle: editState.companyDisplay.length > 0 ? `Replace ${editState.companyDisplay}` : 'Select Company'
      }));
    }
  };

  React.useEffect(() => {
    if (props.editValue == null) {
      setEditState(old => ({
        ...old,
        companyId: undefined,
        userCompanyId: undefined,
        companyDisplay: ''
      }));
    } else if (editState.aliases != null && props.editValue !== (props.isUserCompanyId ? editState.userCompanyId : editState.companyId)) {
      let companyId: number | undefined = -1;
      let userCompanyId: number | undefined = -1;
      if (props.isUserCompanyId) {
        companyId = props.profile.companies.find(x => x.userCompanyId === props.editValue)?.companyId;
        if (companyId == null && props.profile.companySpoofIds != null) {
          objectEach(props.profile.companySpoofIds, (cId, uId) => {
            if (uId.indexOf(props.editValue!) >= 0) {
              companyId = parseInt(cId, 10);
              return false;
            }
            return true;
          });
        }
        if (companyId == null) {
          // TODO: error
          userCompanyId = undefined;
        } else {
          userCompanyId = props.editValue;
        }
      } else {
        userCompanyId = props.profile.companies.find(x => x.companyId === props.editValue)?.userCompanyId;
        if (userCompanyId == null && props.profile.companySpoofIds != null) {
          objectEach(props.profile.companySpoofIds, (cId, uId) => {
            if (parseInt(cId, 10) === props.editValue) {
              userCompanyId = uId[0];
              return false;
            }
            return true;
          });
        }
        if (userCompanyId == null) {
          // TODO: error
          companyId = undefined;
        } else {
          companyId = props.editValue;
        }
      }

      setEditState(old => ({
        ...old,
        companyId,
        userCompanyId,
        companyDisplay: companyId == null ? '' : old.companies[companyId]?.[old.aliases!.displayName] ?? 'Invalid Company'
      }));
    }
  }, [props.editValue, editState.aliases]);

  return (
    <>
      <TextField
        id={`companyEditor_${field.columnId}_display`}
        label={field.title}
        margin="dense"
        fullWidth
        required={field.required}
        value={editState.companyDisplay}
        error={!!error}
        helperText={error || warning || field.description}
        InputProps={{ endAdornment: props.endAdornment }}
        disabled={props.disabled}
        onClick={handleInteract}
        onKeyDown={handleInteract}
      />
      <CompanyList mode="direct" onCompanySelected={handleCompanySelected} onCancel={handleCompanyCancel} onCompaniesLoaded={handleCompaniesLoaded} allowNoSelect={!field.required} open={editState.open} dialogTitle={editState.dialogTitle} />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(SelectCompanyEditor);

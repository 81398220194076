import { refreshCacheData, fetchConfigRequest } from '../actions';
import { autoLoad, ICustomColumnDefinition, getAllFields, AutoRow } from '../../../components/auto/AutoLoad';
import { ICacheResult, ICompanyAlias, ISelectColumnMap } from '../interfaces';
import { objectMapArray, nameof } from '../../../abstracts/DataroweHelpers';
import { IAutoGridProps } from '../../../components/auto/AutoGrid';
import moment from 'moment';
import { createAlertBulk } from 'src/stores/ui/actions';

export const loadCompaniesCache = (refresh: typeof refreshCacheData, fetch: typeof fetchConfigRequest, alert: typeof createAlertBulk, forceRefresh?: boolean): ICacheResult<ICompanyAlias> => {
  return new Promise((outerResolve) => {
    fetch(undefined, {
      key: 'loadCompaniesCache', action: (db) => {
        db.getCacheData(
          refresh,
          'companies',
          [{ from: 'access', unit: 'hours', amount: 1 }, { from: 'load', unit: 'hours', amount: 3 }],
          () => new Promise((resolve) => {
            const companyTable = 'identity.company';
            const companyTableId = db.getTableByCombinedName(companyTable).tableId;
            const companyKey = 'companyId';

            const aliases: ICompanyAlias = {
              companyId: companyKey,
              displayName: 'name',
              memberId: 'memberId',
              reserveLimit: 'reserveLimit',
              roleName: 'partnerType.name',
              isTrainingFacility: 'partnerType.isTrainingFacility',
              isPartner: 'partnerType.isPartner',
              isUnion: 'partnerType.isUnion'
            };

            const autoProps: IAutoGridProps<AutoRow, ISelectColumnMap> = {
              baseTable: companyTableId,
              exportTitle: 'Companies',
              rowDataLastUpdate: moment().valueOf(),
              rowDataLoading: false,
              rowData: {},
              keyField: db.mapSelectColumnsByNamesWithId(companyTableId, companyKey)[0],
              displayField: db.mapSelectColumnsByNamesWithId(companyTableId, aliases.displayName)[0],
              displayColumns: db.mapDisplayColumnsByNamesWithId(companyTableId, objectMapArray(aliases, (k, v): ICustomColumnDefinition => ({ key: v, title: k === nameof<ICompanyAlias>('roleName') ? 'Partner Type' : undefined }))),
              pagination: true,
              defaultSortColumn: 1
            };

            autoLoad({
              baseTableId: companyTableId,
              keyField: autoProps.keyField!,
              displayField: autoProps.displayField!,
              allFields: getAllFields(autoProps.displayColumns, autoProps.additionalColumns),
              fetchConfigRequest: fetch,
              createAlertBulk: alert
            }).then((rows) => {
              resolve({ aliases, autoGridProps: { ...autoProps, rowData: rows } });
            });
          }),
          forceRefresh
        ).then(outerResolve);
      }
    });
  });
};

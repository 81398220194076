import React from 'react';
import moment from 'moment';
import MDEditor from '@uiw/react-md-editor';
import axios, { AxiosResponse } from 'axios';
import Carousel from 'react-material-ui-carousel';
import { createStyles, withStyles } from '@mui/styles';
import { Box, Paper, Theme, useTheme } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';
const noticesUrl = `${API_ENDPOINT}/SystemConfig/Notices`;

const generateSxClasses = (theme: Theme) => {
  return {
    markdownContainer: {
      backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'white',
      borderRadius: '8px',
      padding: '16.5px 22px'
    },
    marginBottom: {
      marginBottom: theme.spacing(1.25)
    },
    paper: {
      margin: 'auto',
      overflow: 'hidden',
      marginBottom: theme.spacing(1.25)
    }
  };
};

interface INotice {
  noticeId: number;
  title: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  priority: number;
  message: string;
}

interface INoticesProps {
  classes: any;
}

interface INoticesState {
  loaded: boolean;
  notices: INotice[];
}

const Notices = (_props: INoticesProps) => {
  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);

  const [state, setState] = React.useState<INoticesState>({
    loaded: false,
    notices: []
  });

  React.useEffect(() => {
    axios.get(noticesUrl).then((res: AxiosResponse<INotice[]>) => {
      setState(() => ({
        loaded: true,
        notices: res.data
      }));
    }).catch((_err) => setState(() => ({
      loaded: true,
      notices: []
    })));

    return () => { };
  }, []);

  const renderNotice = (notice: INotice) => {
    return (
      <Paper sx={{ ...sxClasses.paper }}>
        <Box sx={{ ...sxClasses.markdownContainer }} data-color-mode={theme.palette.mode}>
          <MDEditor.Markdown
            source={notice.message}
            style={{ backgroundColor: 'transparent', whiteSpace: 'pre-wrap' }}
          />
        </Box>
      </Paper>
    );
  };

  if (!state.loaded || (state.loaded && state.notices.length === 0)) return null;

  return (
    <Carousel
      animation="fade"
      autoPlay={false}
      duration={600}
      swipe={false}
      sx={{ ...sxClasses.marginBottom }}
    >
        {state.notices.map((notice) => renderNotice(notice))}
    </Carousel>
  );
};

export default withStyles(createStyles({}))(Notices);

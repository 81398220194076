import { UIActions } from './types';
import axios, { AxiosResponse } from 'axios';
import { completeLookup, failLookup } from './actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ICardDisplay } from 'src/components/identity/WorkerCardDisplay';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

function* handleLookupRequest(action: { type: UIActions, payload: number }) {
  try {
    const response: AxiosResponse<ICardDisplay | undefined> = yield call(axios.get, `${API_ENDPOINT}/Training/Card/${action.payload}`);
    yield put(completeLookup(response.data));
  } catch (error) {
    yield put(failLookup(action.payload, 'Unable to find IEC Number'));
  }
}

function* watchLookupRequest() {
  yield takeEvery(UIActions.QUEUE_WORKER_LOOKUP, handleLookupRequest);
}

export function* uiSaga() {
  yield all([fork(watchLookupRequest)]);
}

import moment from 'moment';
import { Reducer } from 'redux';
import { IBookHub } from './actions';
import * as signalR from '@microsoft/signalr';
import { cloneDeep, Dictionary } from 'lodash';
import { GlobalActions } from '../../../stores';
import { REHYDRATE } from 'redux-persist/es/constants';
import { IEmailAddress } from '../../../abstracts/Interfaces';
import { objectEach, updateArray } from '../../../abstracts/DataroweHelpers';
import { checkVersionNumber, versionNumber } from '../../../abstracts/ConfigureStore';
import { ITrainingBookState, TrainingBookActionTypes, IHubUpdateSeats, IAssignToClass, IBookingSubmit, IPaymentConfig, IAssignToWebCourse } from './types';

const HUB_ENDPOINT = process.env.REACT_APP_REG_HUB_URL || '';

export const initialState: ITrainingBookState = {
  trainingBookStep: 'ScheduleTrainingLive',
  personTraining: {},
  userMessage: undefined,
  bookDateStart: moment().add(1, 'd'),
  bookDateEnd: moment().add(15, 'd'),
  reserveEndTime: undefined,
  loadedBookDates: 'WaitingOnUserLoad',
  courseSchedule: [],
  hubConnection: undefined,
  hubConnectionLoading: false,
  bookSessionId: undefined,
  courses: {},
  submit: undefined,
  trainingSubmitting: false,
  otherEmails: {},
  lastRender: undefined,
  version: versionNumber
};

export const connectHub = async (props: IBookHub, onRedirect: (url: string) => void, onStart?: (c: signalR.HubConnection) => void): Promise<signalR.HubConnection> => {
  const {
    bookHubUpdateSeats: updateSeats,
    bookHubUpdateSeatsBatch: updateSeatsBatch,
    bookHubUpdateExpiry: updateExpiry,
    bookHubVerifySession: verifySession,
    bookHubUpdateAddErrors: addErrors,
    bookHubPaymentConfig: paymentConfig,
    bookRestart: restart,
    bookHubFailed: bookingFailed,
    persistConnection,
    user
  }: IBookHub = props;

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${HUB_ENDPOINT}/training?access_token=${user.access_token}`, {})
    .configureLogging(signalR.LogLevel.Debug)
    .build();

  connection.on('VerifySession', verifySession);
  connection.on('UpdateClassSeats', updateSeats);
  connection.on('UpdateClassSeatsBatch', updateSeatsBatch);
  connection.on('UpdateExpiry', updateExpiry);
  connection.on('SessionErrors', addErrors);
  connection.on('CompletedBooking', (bookSessionId: number) => { onRedirect(`/training/booked?bookingId=${bookSessionId}`); restart(); });
  connection.on('BookingFailed', bookingFailed);

  connection.on('UpdatePaymentConfig', (result: (IBookingSubmit & { paymentMethods: Dictionary<IPaymentConfig>; registrationEmails: { [companyId: number]: IEmailAddress[] } })) => {
    const { paymentMethods, registrationEmails, ...config } = result;
    paymentConfig(config, paymentMethods, registrationEmails);
  });

  if (onStart === undefined) {
    await connection.start().catch(e => console.log(e));
  } else {
    await connection.start().catch(e => console.log(e)).then(() => onStart(connection));
  }

  persistConnection(connection);

  return connection;
};

const currentUnix = () => {
  return moment().valueOf();
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: Reducer<ITrainingBookState> = (state: ITrainingBookState = initialState, action: { type: TrainingBookActionTypes | string, payload: any, key?: string }) => {
  switch (action.type) {
    case GlobalActions.LOGOUT_CLEAR: {
      return initialState;
    }

    case REHYDRATE: {
      return checkVersionNumber(state.version, action.payload?._persist?.version, initialState, () => {
        if (action.key === 'root') {
          if (action.payload === undefined || action.payload.book === undefined) return state;

          const newStart: moment.Moment = action.payload.book.loadedBookDates !== 'Loaded' || action.payload.book.bookDateStart === undefined ? initialState.bookDateStart! : moment(action.payload.book.bookDateStart);
          const newEnd: moment.Moment = action.payload.book.loadedBookDates !== 'Loaded' || action.payload.book.bookDateEnd === undefined ? initialState.bookDateEnd! : moment(action.payload.book.bookDateEnd);

          return {
            ...initialState,
            ...state,
            ...action.payload.book,
            bookDateStart: newStart.isBefore(initialState.bookDateStart, 'day') ? initialState.bookDateStart : newStart,
            bookDateEnd: newEnd.isBefore(initialState.bookDateStart, 'day') ? initialState.bookDateStart : newEnd,
            hubConnection: undefined,
            hubConnectionLoading: false,
            reserveEndTime: undefined,
            trainingSubmitting: false,
            lastRender: currentUnix()
          };
        }

        return state;
      });
    }

    case TrainingBookActionTypes.BOOK_PRELOAD: {
      return {
        ...state,
        bookSessionId: action.payload.sessionId,
        bookDateStart: action.payload.startDate,
        bookDateEnd: action.payload.endDate,
        loadedBookDates: 'AutoLoadOnConnect',
        trainingBookStep: action.payload.trainingBookStep,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_CLEAR: {
      return {
        ...state,
        hubConnection: undefined,
        hubConnectionLoading: false,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_CONNECTING: {
      return {
        ...state,
        hubConnectionLoading: true,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_RESTART: {
      if (state.hubConnection != null) state.hubConnection.stop();
      return {
        ...initialState,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_CONNECT: {
      return {
        ...state,
        bookSessionId: action.payload.sessionId || state.bookSessionId || -1,
        hubConnection: action.payload.connection,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_VERIFYSESSION_START: {
      const { bookSessionId, reserveEndTime } = action.payload;
      return {
        ...state,
        bookSessionId,
        reserveEndTime,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_VERIFYSESSION_END: {
      const { schedules, workers, submit, waitlists } = action.payload;
      return {
        ...state,
        submit,
        courseSchedule: schedules,
        personTraining: workers,
        registrationEmails: undefined,
        workerWaitlists: waitlists,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_UPDATESEATS: {
      const schedule = cloneDeep(state.courseSchedule);
      const { courseScheduleId, scheduleData, reservedCompanyId, ...seatDetails }: IHubUpdateSeats = action.payload;
      const cs = schedule.find(x => x.courseScheduleId === courseScheduleId);
      if (cs != null) {
        // seats will come either with or without reserves/previous registrations; if comes without, just override total info
        const scData = typeof scheduleData === 'string' ? JSON.parse(scheduleData) : scheduleData;
        cs.seats = {
          ...cs.seats,
          ...seatDetails,
          scheduleData: scData
        };
      }

      return {
        ...state,
        courseSchedule: schedule,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_UPDATESEATSBATCH: {
      const schedule = cloneDeep(state.courseSchedule);

      (action.payload as IHubUpdateSeats[]).forEach((v) => {
        const { courseScheduleId, scheduleData, reservedCompanyId, ...seatDetails } = v;
        const cs = schedule.find(x => x.courseScheduleId === courseScheduleId);

        if (cs != null) {
          // seats will come either with or without reserves/previous registrations; if comes without, just override total info
          const scData = typeof scheduleData === 'string' ? JSON.parse(scheduleData) : scheduleData;
          cs.seats = {
            ...cs.seats,
            ...seatDetails,
            scheduleData: scData
          };
        }
      });

      return {
        ...state,
        courseSchedule: schedule,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_UPDATEEXPIRY: {
      const { bookSessionId, expiry }: { bookSessionId: number, expiry: number } = action.payload;
      return state.bookSessionId === bookSessionId ? {
        ...state,
        reserveEndTime: moment().add(expiry, 'seconds'),
        lastRender: currentUnix()
      } : state;
    }

    case TrainingBookActionTypes.BOOK_SET_STEP: {
      return {
        ...state,
        trainingBookStep: typeof action.payload === 'string' ? action.payload : 'SelectWorkerTrainingMatrix',
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_ACTION_MESSAGE: {
      const { message, otherProps } = action.payload;
      return {
        ...state,
        ...otherProps,
        userMessage: message,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_SET_DATE_END: {
      return {
        ...state,
        bookDateStart: action.payload.start,
        bookDateEnd: action.payload.end,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_ADD_PERSONS: {
      const personTraining = cloneDeep(state.personTraining);

      objectEach(action.payload, (key, { display, rowData }) => {
        const personId = parseInt(key, 10);
        if (personTraining[personId] == null) {
          personTraining[personId] = {
            displayName: display,
            photoDate: rowData['person.pictureTakenUTC'] == null ? undefined : moment(rowData['person.pictureTakenUTC']),
            courses: {},
            standards: {},
            dynamicStandards: {},
            reservedClassTraining: {},
            assignedWebTraining: {}
          };
        }
      });

      return {
        ...state,
        personTraining,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_DELETE_PERSON: {
      const { sessionId, personId } = action.payload;
      const personTraining = cloneDeep(state.personTraining);
      delete personTraining[personId];

      state.hubConnection?.invoke('RemovePerson', sessionId, personId);

      return {
        ...state,
        personTraining,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_FAILED: {
      return {
        ...state,
        trainingSubmitting: false,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_GET_CLASSES_START: {
      // will go through all the days and courses; if have people from the current session already attached, will keep. Removes
      // everything else. End result that the object still has all the courses that are in use to merge into the returned object
      const alreadyScheduled = state.loadedBookDates === 'Loaded' ? [] : cloneDeep(state.courseSchedule.filter(schedule => schedule.attachedPersons.length > 0));

      return {
        ...state,
        loadedBookDates: 'LoadingTrainingSchedule',
        courseSchedule: alreadyScheduled,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_GET_CLASSES_END: {
      return {
        ...state,
        loadedBookDates: 'Loaded',
        courseSchedule: action.payload.schedules,
        courses: action.payload.courses,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_ASSIGN_TOCLASS_START: {
      const assignments: IAssignToClass[] = action.payload;

      const workers = cloneDeep(state.personTraining);

      assignments.forEach((assign, idx) => {
        if (workers[assign.personId] == null) {
          workers[assign.personId] = {
            displayName: assign.workerDisplay,
            photoDate: assign.photoDate,
            courses: {},
            standards: {},
            dynamicStandards: {},
            reservedClassTraining: {},
            assignedWebTraining: {}
          };
        }

        if (workers[assign.personId].reservedClassTraining[assign.courseSchedule.courseScheduleId] == null) {
          workers[assign.personId].reservedClassTraining[assign.courseSchedule.courseScheduleId] = {
            bookSessionReserveId: idx * -1,
            course: assign.courseSchedule.course,
            trainingDate: assign.courseSchedule.trainingDate,
            courseScheduleId: assign.courseSchedule.courseScheduleId,
            userCompanyId: assign.userCompanyId!,
            usesCompanyReserve: false,
            pricePartner: assign.courseSchedule.seats?.pricePartner ?? assign.courseSchedule.course.pricePartner,
            priceNonPartner: assign.courseSchedule.seats?.priceNonPartner ?? assign.courseSchedule.course.priceNonPartner,
            selfPay: false,
            creditCard: false,
            status: 'loading'
          };
        }
      });

      return {
        ...state,
        personTraining: workers,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_ASSIGN_TOWEB_START: {
      const assignments: IAssignToWebCourse[] = action.payload;

      const workers = cloneDeep(state.personTraining);

      assignments.forEach((assign, idx) => {
        if (workers[assign.personId] == null) {
          workers[assign.personId] = {
            displayName: assign.workerDisplay,
            photoDate: assign.photoDate,
            courses: {},
            standards: {},
            dynamicStandards: {},
            reservedClassTraining: {},
            assignedWebTraining: {}
          };
        }

        if (workers[assign.personId].assignedWebTraining[assign.course.courseId] == null) {
          workers[assign.personId].assignedWebTraining[assign.course.courseId] = {
            bookSessionWebTrainingId: idx * -1,
            course: assign.course,
            userCompanyId: assign.userCompanyId,
            pricePartner: assign.course.pricePartner,
            priceNonPartner: assign.course.priceNonPartner,
            creditCard: false,
            status: 'loading'
          };
        }
      });

      return {
        ...state,
        personTraining: workers,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.BOOK_HUB_PAYMENTCONFIG: {
      const { paymentMethods, submitState, registrationEmails } = action.payload;

      return {
        ...state,
        paymentMethods,
        submitState,
        registrationEmails,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SCHEDULE_WAITLIST_UPDATE: {
      return {
        ...state,
        workerWaitlists: action.payload,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SUBMIT_ADD_EMAIL: {
      const { companyId, email } = action.payload;

      const updOtherEmails = cloneDeep(state.otherEmails);
      if (updOtherEmails[companyId] == null) {
        updOtherEmails[companyId] = [email];
      } else {
        updOtherEmails[companyId] = updateArray(updOtherEmails[companyId], email, true);
      }

      return {
        ...state,
        otherEmails: updOtherEmails,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SUBMIT_DELETE_EMAIL: {
      const { companyId, email } = action.payload;

      const updOtherEmails = cloneDeep(state.otherEmails);
      if (updOtherEmails[companyId] == null) {
        return state;
      }

      updOtherEmails[companyId] = updateArray(updOtherEmails[companyId], email, false);

      return {
        ...state,
        otherEmails: updOtherEmails,
        lastRender: currentUnix()
      };
    }

    case TrainingBookActionTypes.SUBMIT_MARK_COMPLETING: {
      return {
        ...state,
        trainingSubmitting: true,
        lastRender: currentUnix()
      };
    }

    default: {
      if (action.type.startsWith(TrainingBookActionTypes.__ENUM_ROOT)) {
        // console.log(`unhandled type ${action.type} for ITrainingBookState reducer`);
      }
      return state;
    }
  }
};

export { reducer as trainingBookReducer };

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTheme } from '@mui/styles';
import { IAppState } from '../../stores';
import { Box, Button, Theme, Paper } from '@mui/material';
import { IProfileState } from '../../stores/profile/types';
import { fetchProfile } from '../../stores/profile/actions';
import WorkerList from '../../components/identity/WorkerList';
import WorkerView from '../../components/identity/WorkerView';
import { useQueryParams } from '../../abstracts/NavigationHelpers';
import { AutoLoadKeyDisplay, quickLoadSingle } from '../../components/auto/AutoLoad';
import { IConnectedProps, mapConfigFetchToProps } from '../../components/auto/AutoGrid';

const generateSxClasses = (theme: Theme) => {
  return {
    buttonMargin: {
      marginBottom: theme.spacing(2)
    },
    contentWrapper: {
      mx: theme.spacing(2),
      my: theme.spacing(1.25)
    },
    paper: {
      margin: 'auto',
      overflow: 'hidden',
      marginBottom: theme.spacing(1.25)
    }
  };
};

interface ILocalConnectedProps extends Omit<IConnectedProps, 'classes'> {
  profile: IProfileState;
  fetchProfile: typeof fetchProfile;
}

interface IWorkersPageProps {}

interface IWorkersPageQueryParams {
  workerId?: number;
  filter?: string;
}

const WorkersPage = (props: IWorkersPageProps & ILocalConnectedProps) => {
  const { profile } = props;
  const sxClasses = generateSxClasses(useTheme());

  const [{ workerId, filter }, setParams] = useQueryParams<IWorkersPageQueryParams>({
    workerId: { type: 'number' },
    filter: { type: 'string' }
  });

  const handleQuickFilter = (quickFilter: string | undefined) => {
    setParams({
      workerId,
      filter: quickFilter
    });
  };

  React.useEffect(() => {
    if (typeof workerId != 'undefined' && (!isNaN(workerId) && workerId !== 0)) {
      props.fetchConfigRequest(undefined, {
        key: `getWorkerId_${workerId}`,
        action: (db) => {
          quickLoadSingle({
            db,
            schema: 'identity',
            table: 'person',
            columns: {
              id: 'worker.person'
            },
            filters: {
              iecNumber: workerId
            }
          }).then((v) => {
            if ((v.id.display as number) !== workerId) {
              setParams({
                workerId: v.id.display as number
              });
            }
          }).catch(()=> {
            setParams({
              workerId: undefined,
              filter: workerId.toString()
            });
          });
        }
      });
    }

    return () => { };
  });

  const handleBackButton = () => setParams({ filter });

  const handleWorkerSelected = (workers?: AutoLoadKeyDisplay, filterOverride?: string) => {
    if (typeof workers === 'undefined') {
      return setParams({
        filter: filterOverride === '' ? undefined : filterOverride,
        workerId: undefined
      });
    }

    setParams({
      filter,
      workerId: typeof workers === 'undefined' ? undefined : +Object.keys(workers)[0]
    });
  };

  const handleAddNew = () => setParams({
    filter,
    workerId: 0
  });

  const renderWorkerElement = () => {
    if (typeof workerId === 'undefined' || isNaN(workerId) || (workerId === 0 && (!profile.isAdmin || !profile.isTrainingFacility))) {
      return <WorkerList onWorkersSelected={handleWorkerSelected} onAddNew={handleAddNew} onQuickFilter={handleQuickFilter} quickFilter={filter} mode="inline" maxHeight="calc(100vh - 240px)" limitSelectCount={1}/>;
    }

    return (
      <>
        <Button sx={{ ...sxClasses.buttonMargin }} variant="contained" onClick={handleBackButton} color="primary">
          Return to Worker List
        </Button>
        <WorkerView personId={workerId === 0 ? undefined : workerId} />
      </>
    );
  };

  return (
    <Paper sx={{ ...sxClasses.paper }}>
      <Box sx={{ ...sxClasses.contentWrapper }}>
        {renderWorkerElement()}
      </Box>
    </Paper>
  );
};

function mapStateToProps(state: IAppState) {
  return {
    db: state.db,
    ui: state.ui,
    profile: state.profile
  };
}

export default compose(connect(mapStateToProps, mapConfigFetchToProps))(WorkersPage);

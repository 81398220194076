import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { fetchConfigSuccess, fetchConfigError } from './actions';
import { DatabaseActionTypes, IDatabaseState } from './types';
import { IAppState } from '..';
import { Database } from './class';
import { validateApi } from '../ui/actions';
const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

function* handleConfigFetch(action: { type: any; payload: { lastUpdated?: number, onLoad?: { key: string; action: (db: Database) => void; } } }) {
  const { lastUpdated, onLoad } = action.payload;
  try {
    const db: IDatabaseState = yield select((state: IAppState) => state.db);

    if (db.database.lastUpdated == null || (lastUpdated != null && db.database.lastUpdated !== lastUpdated)) {
      if (db.loading) {
        if (onLoad) yield put({ type: DatabaseActionTypes.FETCH_CONFIG_ACTION, payload: onLoad });
      } else {
        yield put({ type: DatabaseActionTypes.FETCH_CONFIG_START, payload: onLoad });
        const response: AxiosResponse<any> = (yield call(axios.get, `${API_ENDPOINT}/db/.config`));
        const newDb = new Database(response.data);
        const actions: { [key: string]: ((db: Database) => void)[] } = yield select(
          (state: IAppState) => {
            return state.db.onLoadActions;
          }
        );
        yield put(fetchConfigSuccess(newDb));
        console.log('Config fetched', Object.keys(actions));
        Object.keys(actions).forEach(k => actions[k].forEach((r) => {
          if (typeof r === 'function') r(newDb);
        })
        );
      }
    } else if (onLoad) onLoad.action(db.database);
  } catch (err) {
    if (err?.message === 'Network Error') {
      yield put(validateApi(false));
    } else {
      yield put(fetchConfigError(err));
    }
  }
}

function* watchConfigFetchRequest() {
  yield takeEvery(DatabaseActionTypes.FETCH_CONFIG_REQUEST, handleConfigFetch);
}

export function* databaseSaga() {
  yield all([fork(watchConfigFetchRequest)]);
}

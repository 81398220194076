import moment from 'moment';
import { Reducer } from 'redux';
import { GlobalActions } from '..';
import { cloneDeep, Dictionary } from 'lodash';
import { IAlertDetails, IUiState, UIActions } from './types';
import { updateArray, objectMap } from 'src/abstracts/DataroweHelpers';

export const initialState: IUiState = {
  apiAccessible: false,
  helpGuid: '21EFFD23-2A42-4CFD-9B1A-0FE14A000195',
  title: 'Welcome',
  subtitle: '',
  alerts: {},
  alertOrder: [],
  nextAlertId: 1,
  unseenAlerts: 0,
  themeMode: 'automatic',
  workerLookups: [],
  workerLookupCache: {}
};

const unseenCount = (alerts: Dictionary<IAlertDetails>) => Object.keys(alerts).filter(x => !alerts[x].viewed).length;
// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: Reducer<IUiState> = (state: IUiState = initialState, action: { type: UIActions | GlobalActions; payload: any, key?: string }) => {
  switch (action.type) {
    case GlobalActions.LOGOUT_CLEAR: {
      return initialState;
    }

    case UIActions.TOGGLE_THEME_MODE: {
      return {
        ...state,
        themeMode: action.payload
      };
    }

    case UIActions.SET_TITLE: {
      if (document.title !== `BASES: ${action.payload.title}`) {
        document.title = `BASES: ${action.payload.title}`;

        return {
          ...state,
          title: action.payload.title,
          helpGuid: action.payload.helpGuid,
          subtitle: ''
        };
      }

      return state;
    }

    case UIActions.SET_SUBTITLE: {
      if (document.title !== `BASES: ${state.title} - ${action.payload}`) {
        document.title = `BASES: ${state.title} - ${action.payload}`;

        return {
          ...state,
          subtitle: action.payload
        };
      }

      return state;
    }

    case UIActions.SET_TITLE_SUBTITLE: {
      if (document.title !== `BASES: ${action.payload.title} - ${action.payload.subtitle}`) {
        document.title = `BASES: ${action.payload.title} - ${action.payload.subtitle}`;

        return {
          ...state,
          title: action.payload.title,
          helpGuid: action.payload.helpGuid,
          subtitle: action.payload.subtitle
        };
      }

      return state;
    }

    case UIActions.ALERT_CREATE: {
      const alert: IAlertDetails = action.payload;

      return {
        ...state,
        alerts: {
          ...state.alerts,
          [state.nextAlertId]: alert
        },
        alertOrder: (state.alertOrder ?? []).concat([state.nextAlertId]),
        displayAlert: state.displayAlert ?? (alert.autoDisplay ? state.nextAlertId : undefined),
        nextAlertId: state.nextAlertId + 1,
        unseenAlerts: unseenCount({
          ...state.alerts,
          [state.nextAlertId]: alert
        })
      };
    }

    case UIActions.ALERT_CREATE_BULK: {
      const newAlerts: IAlertDetails[] = action.payload;
      let id = state.nextAlertId;
      let defaultDisplay: number | undefined = undefined;
      const alertOrder = cloneDeep(state.alertOrder);
      const alerts = cloneDeep(state.alerts);

      newAlerts.forEach(a => {
        if (defaultDisplay == null && a.autoDisplay) defaultDisplay = id;
        alerts[id] = a;
        alertOrder.push(id++);
      });

      return {
        ...state,
        alerts,
        alertOrder,
        displayAlert: state.displayAlert ?? defaultDisplay,
        nextAlertId: id,
        unseenAlerts: unseenCount(alerts)
      };
    }

    case UIActions.ALERT_NEXT: {
      const alerts = cloneDeep(state.alerts);

      if (state.displayAlert != null) {
        alerts[state.displayAlert].viewed = true;
      }

      return {
        ...state,
        alerts,
        displayAlert: state.alertOrder.find(x => !state.alerts[x].viewed && state.displayAlert !== x),
        unseenAlerts: unseenCount(alerts)
      };
    }

    case UIActions.ALERT_REMOVE: {
      const alerts = cloneDeep(state.alerts);
      const alertOrder = updateArray(state.alertOrder, action.payload, false);

      delete alerts[action.payload];

      return {
        ...state,
        alerts,
        alertOrder,
        displayAlert: alertOrder.find(x => state.displayAlert !== x && !state.alerts[x].viewed),
        unseenAlerts: unseenCount(alerts)
      };
    }

    case UIActions.ALERT_REMOVE_ALL: {
      return {
        ...state,
        alerts: {},
        alertOrder: [],
        nextAlertId: 1,
        unseenAlerts: 0,
        displayAlert: undefined
      };
    }

    case UIActions.ALERT_MARK_ALL_READ: {
      return {
        ...state,
        alerts: objectMap(state.alerts, (_k, x) => ({
          ...x,
          viewed: true
        })),
        displayAlert: undefined
      };
    }

    case UIActions.QR_SHOW_LINK: {
      return {
        ...state,
        showQRLink: action.payload
      };
    }

    case UIActions.QR_CLEAR_LINK: {
      return {
        ...state,
        showQRLink: undefined
      };
    }

    case UIActions.SET_API_CONNECTED: {
      return {
        ...state,
        apiAccessible: action.payload,
        apiValidated: moment().valueOf()
      };
    }

    case UIActions.QUEUE_WORKER_LOOKUP: {
      return {
        ...state,
        workerLookups: [{
          personId: action.payload,
          timestamp: moment().valueOf()
        },
        ...state.workerLookups ?? []],
        workerLookupCache: state.workerLookupCache ?? {}
      };
    }

    case UIActions.COMPLETE_WORKER_LOOKUP: {
      return {
        ...state,
        workerLookupCache: {
          ...state.workerLookupCache,
          [action.payload.iecNumber]: action.payload
        }
      };
    }

    case UIActions.QUEUE_WORKER_ERROR: {
      return {
        ...state,
        workerLookupCache: {
          ...state.workerLookupCache,
          [action.payload.personId]: action.payload.message
        }
      };
    }

    default: return state;
  }
};

export { reducer as uiReducer };

import { Reducer } from 'redux';
import { Database } from './class';
import { GlobalActions } from '..';
import { REHYDRATE } from 'redux-persist';
import { IDatabaseState, DatabaseActionTypes } from './types';
import { objectAddOrCreateArrayChild } from '../../abstracts/DataroweHelpers';
import { checkVersionNumber, versionNumber } from '../../abstracts/ConfigureStore';

export const initialState: IDatabaseState = {
  database: new Database({
    schemas: undefined,
    schemaNames: undefined,
    tableIdToSchemaId: undefined,
    columnIdToTableId: undefined
  }),
  errors: undefined,
  loading: false,
  onLoadActions: {},
  version: versionNumber
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: Reducer<IDatabaseState> = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActions.LOGOUT_CLEAR: {
      return initialState;
    }

    case REHYDRATE: {
      return checkVersionNumber(state.version, action.payload?._persist?.version, initialState, () => {

        if (action.key === 'root') {
          // if never initialized, return initialState. Otherwise, return initial state overridden by the payload, with
          // special attention to reinflate the database class instead of a simple JSON object
          if (action.payload === undefined || action.payload.db === undefined) return initialState;

          return {
            ...state,
            ...action.payload.db,
            loading: false,
            database: new Database(action.payload.db === undefined ? {} : action.payload.db.database, true),
            errors: undefined,
            onLoadActions: {}
          };
        }

        return state;
      });
    }

    case DatabaseActionTypes.FETCH_CONFIG_START: {
      if (action.payload != null) {
        return {
          ...state,
          loading: true,
          onLoadActions: objectAddOrCreateArrayChild(state.onLoadActions, action.payload.key, action.payload.action)
        };
      }

      return {
        ...state,
        loading: true
      };
    }

    case DatabaseActionTypes.FETCH_CONFIG_ACTION: {
      return {
        ...state,
        onLoadActions: objectAddOrCreateArrayChild(state.onLoadActions, action.payload.key, action.payload.action)
      };
    }

    case DatabaseActionTypes.FETCH_CONFIG_SUCCESS: {
      if (action.payload == null) {
        return {
          ...state,
          loading: false,
          onLoadActions: {}
        };
      }

      return {
        ...state,
        loading: false,
        database: action.payload
      };
    }

    case DatabaseActionTypes.FETCH_CONFIG_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    case DatabaseActionTypes.CACHE_REFRESH_DATA: {
      const { key, cacheData } = action.payload;

      return {
        ...state,
        database: state.database.updateCacheData(key, cacheData)
      };

    }

    default: {
      return state;
    }
  }
};

export { reducer as databaseReducer };

import React from 'react';
import { compose } from 'redux';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { FormControl, InputAdornment, TextField } from '@mui/material';

export interface ICurrencyFieldProps {
  min?: number;
  max?: number;
}

interface ICurrencyEditorProps extends IAutoEditorImplementation<string | undefined>, ICurrencyFieldProps {}

const CurrencyEditor = (props: ICurrencyEditorProps) => {
  const defaultValue = 0;
  const { editValue, error, field, onValueUpdated, warning } = props;
  const [value, setValue] = React.useState(props.editValue);

  React.useEffect(() => {
    setValue(editValue);
  }, []);

  React.useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  const handleFieldBlur = () => {
    let stateValue = field.required ? defaultValue.toFixed(2) : undefined;

    if (typeof value !== 'undefined' && value !== '') {
      stateValue = parseFloat(value).toFixed(2);
    }

    setValue(stateValue);

    return onValueUpdated(stateValue, field, typeof stateValue !== 'undefined' ? new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(+stateValue) : '');
  };

  return <FormControl fullWidth error={!!error}>
    <TextField
      disabled={props.disabled}
      error={!!error}
      helperText={error || warning || field.description}
      id={`currencyEditor_${field.columnId}`}
      inputProps={{ min: props.min, max: props.max, step: '0.01' }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        endAdornment: props.endAdornment
      }}
      label={`${field.title} ${field.required ? '*' : ''}`}
      margin="dense"
      onBlur={handleFieldBlur}
      onChange={event => setValue(event.currentTarget.value)}
      onKeyDown={(event) => { if (event.keyCode === 27) setValue(editValue); }}
      required={field.required}
      type="number"
      value={value ?? (field.required ? defaultValue.toFixed(2) : '')}
    />
  </FormControl>;
};

export default compose()(CurrencyEditor);

import * as React from 'react';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import { IAppState } from '../stores';
import HelpDoc from '../layout/HelpDoc';
import { compose, Dispatch } from 'redux';
import { closeAlert } from '../stores/ui/actions';
import { IProfileState } from '../stores/profile/types';
import { ucFirst } from 'src/abstracts/DataroweHelpers';
import { IUiState, UIActions } from '../stores/ui/types';
import { ICommonHelpItem } from '../abstracts/NavigationLinks';
import { ExpandMore, Help, Menu as MenuIcon, Notifications, Brightness4, Brightness7 } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, AppBar, Badge, Box, Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, Hidden, IconButton, Paper, Snackbar, SnackbarCloseReason, Theme, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';

const SITE_VERSION = process.env.REACT_APP_VERSION_NAME;

const generateSxClasses = (theme: Theme) => {
  return {
    accordionSummary: {
      backgroundColor: theme.palette.secondary.main
    },
    alertBox: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 700]
      }
    },
    alertPaper: {
      minWidth: '20vw',
      height: '98vh',
      margin: theme.spacing(2),
      overflowX: 'hidden',
      [theme.breakpoints.down('md')]: {
        maxWidth: '85vw'
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '30vw'
      }
    },
    helpPaper: {
      minWidth: '20vw',
      height: '98vh',
      margin: theme.spacing(2),
      overflowX: 'scroll',
      [theme.breakpoints.down('md')]: {
        maxWidth: '85vw'
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '55vw'
      }
    },
    pageHeader: {
      zIndex: 1101,
      maxWidth: '100vw'
    },
    topButtons: {
      padding: theme.spacing(2),
    }
  };
};

type PopoutOption = 'help' | 'alerts' | undefined;

interface IHeaderProps {
  drawerWidth: number;
  commonHelpItems: ICommonHelpItem[];
  dispatch: Dispatch;
  ui: IUiState;
  profile: IProfileState;
  onDrawerToggle: () => void;
  prefersDarkTheme: boolean;
}

interface IBreakpoint {
  label: Breakpoint;
  range: string;
}

const Header = (props: IHeaderProps) => {
  const { drawerWidth, commonHelpItems, dispatch, ui, onDrawerToggle, prefersDarkTheme } = props;

  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);

  const nextTheme = ui.themeMode === 'automatic' ? (prefersDarkTheme ? 'light' : 'dark') : ui.themeMode === 'light' ? 'dark' : 'light';

  const [popout, setPopout] = React.useState<PopoutOption>(undefined);
  const [expanded, setExpanded] = React.useState<string>('PageHelp');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => setExpanded(isExpanded ? panel : 'PageHelp');

  const handleSnackClose = (_event: React.SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;

    dispatch(closeAlert());
  };

  const renderBreakpoints = (hideBreakpoints: boolean = false) => {
    if (hideBreakpoints || SITE_VERSION !== 'Local Dev') return undefined;

    const allowedBreakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];
    const breakpoints: IBreakpoint[] = [
      {
        label: 'xs',
        range: '0px - 599px'
      },
      {
        label: 'sm',
        range: '600px - 960px'
      },
      {
        label: 'md',
        range: '961px - 1199px'
      },
      {
        label: 'lg',
        range: '1200px - 1536px'
      },
      {
        label: 'xl',
        range: '>= 1536px'
      }
    ];

    return (
      <Grid item>
        {breakpoints.map(breakpoint => <Hidden key={`breakpoint_${breakpoint.label}`} only={allowedBreakpoints.filter(bp => bp !== breakpoint.label)}>
          <Tooltip sx={{ cursor: 'default' }} title={`Current Breakpoint • ${breakpoint.label.toUpperCase()} (${breakpoint.range})`}>
            <Typography variant="h5" component="h1">
              {breakpoint.label.toUpperCase()}
            </Typography>
          </Tooltip>
        </Hidden>)}
      </Grid>
    );

  };

  const handleClearAlerts = () => dispatch({ type: UIActions.ALERT_REMOVE_ALL });
  const handleReadAllAlerts = () => dispatch({ type: UIActions.ALERT_MARK_ALL_READ });
  const handleManualNavigation = () => window.location.replace('/manual');
  const handleCloseManual = () => setPopout(undefined);

  const autoHideDuration = (): number | null => {
    const uiDisplayAlertIndex = ui.displayAlert;

    if (typeof uiDisplayAlertIndex === 'undefined' || typeof ui.alerts[uiDisplayAlertIndex] === 'undefined') return null;

    if (typeof ui.alerts[uiDisplayAlertIndex].autoDismiss === 'undefined') return 4000;

    if (ui.alerts[uiDisplayAlertIndex].autoDismiss === false) return null;

    return ui.alerts[uiDisplayAlertIndex].autoDismiss as number;
  };

  return (
    <>
      <AppBar color="primary" position="fixed" elevation={0} sx={{ ...sxClasses.pageHeader }}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }} item>
              <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerToggle} edge="start">
                <MenuIcon/>
              </IconButton>
            </Grid>
            <Hidden mdDown>
              <Grid item sx={{ width: drawerWidth }}/>
            </Hidden>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {ui.title + ((ui.subtitle || '').length > 0 ? ' - ' : '') + ui.subtitle}
              </Typography>
            </Grid>
            {renderBreakpoints()}
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit" onClick={() => setPopout('help')}>
                  <Help/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={`Alerts • ${ui.unseenAlerts ?? 'No'} alert${ui.unseenAlerts === 1 ? '' : 's'}`} onClick={() => setPopout('alerts')}>
                <IconButton color="inherit">
                  <Badge badgeContent={ui.unseenAlerts} color="secondary">
                    <Notifications/>
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={`Toggle Theme • Current: ${ui.themeMode === 'automatic' ? (ucFirst(theme.palette.mode) + ' (' + ucFirst(ui.themeMode) + ')') : ucFirst(theme.palette.mode)}`}>
                <IconButton color="inherit" onClick={() => dispatch({ type: UIActions.TOGGLE_THEME_MODE, payload: nextTheme })}>
                  {theme.palette.mode === 'light' ? <Brightness4/> : <Brightness7/>}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={popout === 'alerts'} onClose={() => setPopout(undefined)}>
        <Paper sx={{ ...sxClasses.alertPaper }}>
          <Grid sx={{ ...sxClasses.topButtons }} container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button onClick={handleClearAlerts} variant="contained" fullWidth color="primary">
                Delete All
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button onClick={handleReadAllAlerts} variant="contained" fullWidth color="primary">
                Mark All Read
              </Button>
            </Grid>
          </Grid>
          {ui.alertOrder.map((x) => {
            const alert = ui.alerts[x];
            return alert == null ? undefined : (
              <Box key={`alert_${x}`} sx={{ ...sxClasses.alertBox }}>
                <Alert severity={alert.severity} onClose={() => dispatch({ type: UIActions.ALERT_REMOVE, payload: x })}>
                  <AlertTitle>{alert.title}</AlertTitle>
                  {alert.description}
                </Alert>
              </Box>
            );
          })}
        </Paper>
      </Drawer>
      <Drawer anchor="right" open={popout === 'help'} onClose={() => setPopout(undefined)}>
        <Paper sx={{ ...sxClasses.helpPaper }}>
          <Grid sx={{ ...sxClasses.topButtons }} container spacing={3}>
            <Grid item xs={12} sm={5} md={4}>
              <Button onClick={handleManualNavigation} variant="contained" fullWidth color="primary">
                Open Full User Manual
              </Button>
            </Grid>
            <Hidden smUp>
              <Grid item xs={12}>
                <Button onClick={handleCloseManual} variant="contained" fullWidth color="primary">
                  Close User Manual
                </Button>
              </Grid>
            </Hidden>
          </Grid>
          <Accordion expanded={expanded === 'PageHelp'} onChange={handleChange('PageHelp')}>
            <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="pageHelp-content" id="pageHelp-header" sx={{ ...sxClasses.accordionSummary }}>
              <Typography>Page Help</Typography>
            </AccordionSummary>
            <AccordionDetails id="pageHelp-content">
              <HelpDoc markdownKey={ui.helpGuid} title={ui.title}/>
            </AccordionDetails>
          </Accordion>
          {commonHelpItems.map(({ title, helpKey }) => (
            <Accordion key={helpKey} expanded={expanded === helpKey} onChange={handleChange(helpKey)}>
              <AccordionSummary expandIcon={<ExpandMore/>} aria-controls={`${helpKey}_content`} id={`${helpKey}_header`} sx={{ ...sxClasses.accordionSummary }}>
                <Typography>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails id={`${helpKey}_content`}>
                <HelpDoc markdownKey={helpKey} title={title}/>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      </Drawer>
      <Snackbar open={ui.displayAlert != null} key={ui.displayAlert ?? -1} autoHideDuration={autoHideDuration()} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity={ui.displayAlert != null ? ui.alerts[ui.displayAlert].severity : undefined} onClose={handleSnackClose}>
          {ui.displayAlert != null ? ui.alerts[ui.displayAlert].description ?? ui.alerts[ui.displayAlert].title : ''}
        </Alert>
      </Snackbar>
      <Hidden mdDown>
        {ui.showQRLink == null ? (
          <></>
        ) : (
          <Dialog maxWidth="lg" open onClose={() => dispatch({ type: UIActions.QR_CLEAR_LINK })} onClick={(e) => { e.stopPropagation(); dispatch({ type: UIActions.QR_CLEAR_LINK }); }}>
            <DialogTitle>{ui.showQRLink?.title ?? ''}</DialogTitle>
            <DialogContent>
              <Box key="box" sx={{ p: theme.palette.mode === 'light' ? 0 : theme.spacing(3), backgroundColor: 'white !important;' }}>
                <QRCode value={ui.showQRLink?.link ?? ''} size={Math.min(window.innerHeight / 2, window.innerWidth / 2)}/>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => { e.stopPropagation(); navigator.clipboard.writeText(ui.showQRLink?.link ?? ''); }} color="primary">
                Copy Link
              </Button>
              <Button href={ui.showQRLink?.link ?? ''} target="_blank" color="primary">
                Open in New Tab
              </Button>
              <Button onClick={() => dispatch({ type: UIActions.QR_CLEAR_LINK })} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Hidden>
      <Hidden lgUp>
        {ui.showQRLink == null ? (
          <></>
        ) : (
          <Dialog fullScreen open onClose={() => dispatch({ type: UIActions.QR_CLEAR_LINK })} onClick={(e) => { e.stopPropagation(); dispatch({ type: UIActions.QR_CLEAR_LINK }); }}>
            <DialogTitle>{ui.showQRLink?.title ?? ''}</DialogTitle>
            <DialogContent>
              <Box key="box" sx={{ p: theme.palette.mode === 'light' ? 0 : theme.spacing(3), backgroundColor: 'white !important;' }}>
                <QRCode value={ui.showQRLink?.link ?? ''} size={Math.min(window.innerHeight / 2, window.innerWidth / 2)}/>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch({ type: UIActions.QR_CLEAR_LINK })} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Hidden>
    </>
  );
};

function mapStateToProps(state: IAppState) {
  return {
    user: state.oidc.user,
    ui: state.ui,
    profile: state.profile
  };
}

export default compose(connect(mapStateToProps))(Header);

// -TODO-: move to Database store
import React from 'react';
import moment from 'moment';
import { Dictionary } from 'lodash';
import { keyFieldString } from './AutoGrid';
import axios, { AxiosResponse } from 'axios';
import settings from 'src/abstracts/settings';
import { Database } from '../../stores/database/class';
import { createAlertBulk } from 'src/stores/ui/actions';
import { fetchConfigRequest, refreshCacheData } from '../../stores/database/actions';
import { nameof, isInterface, arrayMapObject, objectEach, objectMap, objectMapArray, objectRemap } from '../../abstracts/DataroweHelpers';
import { AutoSearchTypes, CellDisplayFormatter, CustomColumnSort, HiddenFormatter, IAutoGridConfig, IAutoGridFormatProperties, RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { IFilterDefinition, ISelectColumnMap, IOrderMap, IColumnMap, FilterConditionType, isSelectColumnMap, selectToColumnMap, IApiResult, IFilterCondition, FilterGroupType, ISelectDisplay, SpecialReferenceType } from '../../stores/database/interfaces';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

export interface ICustomColumnDefinition extends IAutoGridFormatProperties {
  key: string | string[];
  title?: string;
  customState?: any;
  hidden?: boolean | HiddenFormatter;
  // nullCoalesce?: string[]; TODO: Add null coalesce options that will carry to the SQL generation to allow alternate values when one is null
  display?: RowFormatter<string, ISelectColumnMap>;
  displayControl?: CellDisplayFormatter | SpecialReferenceType;
  sort?: CustomColumnSort<Dictionary<any>>;
}

export interface ICustomDisplayDefinition<T = Dictionary<any>> extends IAutoGridFormatProperties {
  columnAlias: string;
  columnTitle: string | { display: React.ReactNode, export: string };
  customState?: any;
  hidden?: HiddenFormatter;
  display?: RowFormatter<string, string, T>;
  displayControl?: RowFormatter<React.ReactNode | string | undefined, string, T> | SpecialReferenceType;
  sort?: CustomColumnSort<T>;
}

export interface ICustomSelectColumnMap extends ISelectColumnMap, Omit<ICustomColumnDefinition, 'key'>, IAutoGridFormatProperties { }
export const isICustomSelectColumnMap = (obj: ICustomSelectColumnMap | ICustomDisplayDefinition): obj is ICustomSelectColumnMap => (<ICustomSelectColumnMap>obj).columnId != null;

export interface IAutoLoadConnected {
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

export interface IAutoLoadCacheConnected extends IAutoLoadConnected {
  refreshCacheData: typeof refreshCacheData;
}

export interface IAutoGridLoadProps extends IAutoGridConfig {
  offset?: number;
  count?: number;
}

export interface ApiPost {
  columns: IColumnMap[];
  orders?: IOrderMap[];
  filter?: IFilterDefinition;
  context?: IContext;
}

export interface IContext {
  companyIds?: number[];
}

export interface IAutoLoadProps {
  baseTableId: number;
  keyField: ISelectColumnMap | ISelectColumnMap[];
  columns: IColumnMap[];
  orders?: IOrderMap[];
  search?: AutoSearchTypes;
  offset?: number;
  count?: number;
  options?: ILoadOptions;
}

export type AutoRow = { [x: string]: any };

export type AutoLoadKeyDisplay<T = {}> = { [key: string]: { display: string; rowData: T } };

export type IAutoProps = IAutoGridLoadProps | IAutoLoadProps;

export type IAutoMultiProps = IAutoProps & { resultKey?: string; };

export function autoLoadConfig(props: IAutoProps, context?: IContext): ApiPost {
  const config: ApiPost = { context, columns: [], orders: props.orders, filter: typeof props.search !== 'string' ? props.search : undefined };
  if (isInterface<IAutoGridLoadProps>(props, 'allFields')) {
    config.columns = selectToColumnMap((props.allFields ?? []).slice(0).concat(props.keyField));
    if (props.displayField != null && isSelectColumnMap(props.displayField)) config.columns.push(props.displayField);
  } else config.columns = props.columns;
  return config;
}

export function autoLoad<T = AutoRow>(props: IAutoProps & IAutoLoadConnected, context?: IContext): Promise<Dictionary<T>> {
  return new Promise((resolve, reject) => {
    props.fetchConfigRequest(undefined, {
      key: `autoLoad_${props.baseTableId}`,
      action: (db) => {
        const postParams = autoLoadConfig(props, context);
        const { schema, table } = db.getApiConfigByTable(props.baseTableId);
        const parameters: string[] = [];
        if (props.count != null) {
          parameters.push(`count=${props.count}`);
          parameters.push(`offset=${props.offset || 0}`);
        }

        if (props.search != null && typeof props.search === 'string') {
          parameters.push(`search=${props.search}`);
        }

        const api = `${API_ENDPOINT}/db/query/${schema}/${table}${parameters.length > 0 ? '?' : ''}${parameters.join('&')}`;

        console.log(api, postParams);
        axios.post(api, postParams).then((res: AxiosResponse<IApiResult>) => {
          if (!Object.prototype.hasOwnProperty.call(res.data, nameof<IApiResult>('lastUpdated'))) {
            reject(`Unable to load data with autoLoad. Response was ${res.status}: ${res.statusText}`);
          }
          // props.fetchConfigRequest(res.data.lastUpdated);
          const results = arrayMapObject(res.data.results, (i, v: T) => {
            return [keyFieldString(v as AutoRow, props.keyField) ?? i.toString(), v];
          });
          console.log(results);
          resolve(results);
        }).catch(err => reject(err));
      }
    });
  });
}

export function autoLoadMulti<T = AutoRow>(props: { multi: IAutoMultiProps[] } & IAutoLoadConnected, context?: IContext): Promise<Dictionary<Dictionary<T>>> {
  return new Promise((resolve, reject) => {
    props.fetchConfigRequest(undefined, {
      key: `autoLoad_${props.multi.map(x => x.baseTableId).join('_')}`,
      action: (db) => {
        if (props.multi.length === 0) {
          resolve({});
        } else {
          const api = `${API_ENDPOINT}/db/query/.multi`;
          const postParams = props.multi.map(p => ({
            ...autoLoadConfig(p, context),
            query: {
              ...db.getApiConfigByTable(p.baseTableId),
              resultKey: p.resultKey,
              count: p.offset == null ? undefined : p.count,
              offset: p.count == null ? undefined : p.offset
            }
          }));
          console.log(api, postParams);
          axios.post(api, postParams).then((res: AxiosResponse<IApiResult<Dictionary<any>>>) => {
            if (!Object.prototype.hasOwnProperty.call(res.data, nameof<IApiResult>('lastUpdated'))) {
              reject(`Unable to load data with autoLoadMulti. Response was ${res.status}: ${res.statusText}`);
            } else {
              // props.fetchConfigRequest(res.data.lastUpdated);
              const results = arrayMapObject(props.multi, (idx, t) => {
                const key = t.resultKey ?? db.getCombinedTableNameById(t.baseTableId);
                return [key, arrayMapObject(res.data.results[key], (i, v: T) => {
                  return [keyFieldString(v as AutoRow, t.keyField) ?? i.toString(), v];
                })];
              });
              console.log(results);
              resolve(results);
            }
          }).catch(err => reject(err));
        }
      }
    });
  });
}

export function loadWorkerDetails(props: { personId: number, fields: IColumnMap[] } & IAutoLoadConnected): Promise<any> {
  return new Promise((resolve, reject) => {
    props.fetchConfigRequest(undefined, {
      key: 'autoLoad_LoadWorkerDetails',
      action: (db) => {
        const api = `${API_ENDPOINT}/db/query/Identity/Worker`;
        const baseTable = db.getTableByNames('identity', 'worker');
        const [idColumn] = db.mapSelectColumnsByNamesWithId(baseTable.tableId, [['identity.person.worker', 'identity.person.iecnumber']]);
        const params = {
          filter: {
            baseFilter: {
              columnId: idColumn.columnId,
              lookupPath: idColumn.lookupPath || [],
              type: FilterConditionType.Equal,
              firstParameter: props.personId
            },
            baseTableId: baseTable.tableId,
            name: ''
          },
          columns: props.fields
        };
        console.log(api, params);
        axios.post(api, params).then((res: AxiosResponse<IApiResult>) => {
          if (!Object.prototype.hasOwnProperty.call(res.data, nameof<IApiResult>('lastUpdated'))) {
            reject(`Unable to load data with autoLoadMulti. Response was ${res.status}: ${res.statusText}`);
          } else {
            // props.fetchConfigRequest(res.data.lastUpdated);
            console.log(res.data.results);
            resolve(res.data.results);
          }
        }).catch(err => reject(err));
      }
    });
  });
}

export function getAllFields(displayFields: (ICustomSelectColumnMap | ICustomDisplayDefinition)[], additionalFields?: ISelectColumnMap[]): ISelectColumnMap[] {
  const res: ISelectColumnMap[] = displayFields
    .map((x) => { if (!isICustomSelectColumnMap(x)) return undefined; const { display, sort, ...other } = x; return other; })
    .filter((x): x is ISelectColumnMap => x != null);
  if (additionalFields != null) {
    return res.concat(additionalFields);
  }
  return res;
}

export function postGenerateExcel(props: { fileName: string, headers: string[], values: any[][] }) {
  const api = `${API_ENDPOINT}/file`;

  console.log(api, props);
  axios.post<string>(`${api}/GenerateExcel`, { ...props }).then((res) => {
    const a = document.createElement('a');
    a.href = `${api}/Excel/${res.data}`;
    a.download = props.fileName;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    console.log(res);
  }).catch(err => console.log(err));

}

export function postGenerateCustomReport() {
  const api = `${API_ENDPOINT}/file/report`;
  axios.post<string>(api).then((res) => {
    const a = document.createElement('a');
    a.href = `${api}/${res.data}`;
    a.download = `${moment().format(settings.apiDateFormatMoment)} Worker History.xlsx`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  });
}

export interface ILoadOptions {
  resultArray?: boolean;
}

export interface IQuickLoad {
  schema: string;
  table: string;
  keyfield: string;
  columns: { [returnname: string]: string; };
  filters?: { [column: string]: any };
  options?: ILoadOptions;
}

const mapQuickLoad = (db: Database, configs: Dictionary<IQuickLoad>): [Dictionary<IAutoLoadProps>, Dictionary<Dictionary<string>>] => {
  const resConfig: Dictionary<IAutoLoadProps> = {};
  const resMap: Dictionary<Dictionary<string>> = {};
  objectEach(configs, (key, config) => {
    const t = db.getTableByNames(config.schema, config.table);
    const remap: Dictionary<string> = {};
    const columns: ISelectColumnMap[] = [];
    objectEach(config.columns, (k, c) => {
      const map = db.mapSelectColumnsByNamesWithId(t.tableId, c)[0];
      columns.push(map);
      remap[map.columnAlias] = k;
    });
    const filters: IFilterCondition[] = objectMapArray(config.filters ?? {}, (col, val): IFilterCondition => {
      const map = db.mapSelectColumnsByNamesWithId(t.tableId, col)[0];
      return {
        columnId: map.columnId,
        lookupPath: map.lookupPath ?? [],
        type: Array.isArray(val) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: val
      };
    });
    resConfig[key] = {
      columns,
      keyField: db.mapSelectColumnsByNamesWithId(t.tableId, config.keyfield),
      baseTableId: t.tableId,
      search: filters.length === 0 ? undefined : {
        baseFilter: {
          type: FilterGroupType.And,
          children: filters
        },
        baseTableId: t.tableId,
        name: ''
      },
      options: config.options
    };
    resMap[key] = remap;
  });

  return [resConfig, resMap];
};

export function quickLoad<T = Dictionary<any>>(props: { db: Database; } & IQuickLoad, context?: IContext): Promise<Dictionary<T>> {
  return new Promise((resolve, reject) => {
    const t = props.db.getTableByNames(props.schema, props.table);

    const remap: Dictionary<string> = {};
    const columns: ISelectColumnMap[] = [];
    objectEach(props.columns, (k, c) => {
      const map = props.db.mapSelectColumnsByNamesWithId(t.tableId, c)[0];
      columns.push(map);
      remap[map.columnAlias] = k;
    });

    const filters: IFilterCondition[] = objectMapArray(props.filters ?? {}, (col, val): IFilterCondition => {
      const map = props.db.mapSelectColumnsByNamesWithId(t.tableId, col)[0];
      return {
        columnId: map.columnId,
        lookupPath: map.lookupPath ?? [],
        type: Array.isArray(val) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: val
      };
    });

    axios.post(`${API_ENDPOINT}/db/query/${props.schema}/${props.table}`, {
      columns,
      context,
      filter: filters.length === 0 ? undefined : {
        BaseFilter: {
          type: FilterGroupType.And,
          children: filters
        },
        baseTableId: t.tableId,
        name: ''
      }
    }).then((res) => {
      resolve(objectMap(res?.data?.results ?? [], (key, row: any) =>
        objectRemap(row, (k, v) => [remap[k], v]) as unknown as T
      ));
    }).catch(err => reject(err));
  });
}

export function quickLoadSingle(props: { db: Database; } & Omit<IQuickLoad, 'keyfield'>, context?: IContext): Promise<Dictionary<ISelectDisplay>> {
  return new Promise((resolve, reject) => {
    const t = props.db.getTableByNames(props.schema, props.table);

    const remap: Dictionary<ISelectDisplay & { resultKey: string; }> = {};
    const columns: ISelectColumnMap[] = [];
    objectEach(props.columns, (k, c) => {
      const map = props.db.mapSelectColumnsByNamesWithId(t.tableId, c)[0];
      columns.push(map);
      remap[map.columnAlias] = {
        resultKey: k,
        columnId: map.columnId,
        columnTitle: map.columnTitle,
        columnType: map.columnType,
        lookupPath: map.lookupPath
      };
    });

    const filters: IFilterCondition[] = objectMapArray(props.filters ?? {}, (col, val): IFilterCondition => {
      const map = props.db.mapSelectColumnsByNamesWithId(t.tableId, col)[0];
      return {
        columnId: map.columnId,
        lookupPath: map.lookupPath ?? [],
        type: Array.isArray(val) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: val
      };
    });
    console.log('', {
      columns,
      filter: {
        baseFilter: {
          type: FilterGroupType.And,
          children: filters
        },
        baseTableId: t.tableId,
        name: ''
      }
    });
    axios.post(`${API_ENDPOINT}/db/query/${props.schema}/${props.table}`, {
      columns,
      context,
      filter: filters.length === 0 ? undefined : {
        BaseFilter: {
          type: FilterGroupType.And,
          children: filters
        },
        baseTableId: t.tableId,
        name: ''
      }
    }).then((res) => {
      resolve(objectRemap(res?.data?.results[0], (k, v): [string, ISelectDisplay] => {
        const { resultKey, ...result } = remap[k];
        return [resultKey, { ...result, display: v }];
      }));
    }).catch(err => reject(err));
  });
}

export function quickLoadMulti(props: { db: Database; multi: Dictionary<IQuickLoad> }, context?: IContext): Promise<Dictionary<Dictionary<any> | any[]>> {
  return new Promise((resolve, reject) => {
    const [multi, remaps] = mapQuickLoad(props.db, props.multi);
    if (Object.keys(multi).length === 0) {
      resolve({});
    } else {
      const api = `${API_ENDPOINT}/db/query/.multi`;
      const postParams = objectMapArray(multi, (key, p) => ({
        ...autoLoadConfig(p, context),
        query: {
          ...props.db.getApiConfigByTable(p.baseTableId),
          resultKey: key,
          count: p.offset == null ? undefined : p.count,
          offset: p.count == null ? undefined : p.offset
        }
      }));
      console.log(api, postParams);
      axios.post(api, postParams).then((res: AxiosResponse<IApiResult<Dictionary<any>>>) => {
        if (!Object.prototype.hasOwnProperty.call(res.data, nameof<IApiResult>('lastUpdated'))) {
          reject(`Unable to load data with quickLoadMulti. Response was ${res.status}: ${res.statusText}`);
        } else {
          const results = objectMap(multi, (tKey, tbl) => {
            if (tbl.options?.resultArray) {
              return res.data.results[tKey].map((row: AutoRow) => objectRemap(row, (k, v) => [remaps[tKey][k], v]));
            }
            return arrayMapObject(res.data.results[tKey], (col, row: AutoRow) => {
              return [keyFieldString(row, tbl.keyField) ?? col.toString(), objectRemap(row, (k, v) => [remaps[tKey][k], v])];
            });
          });
          console.log(results);
          resolve(results);
        }
      }).catch(err => reject(err));
    }
  });
}

export function loadDirectEditTables(): Promise<{ schema: string; value: string; title: string; }[]> {
  return new Promise((resolve, reject) => {
    const api = `${API_ENDPOINT}/db/directedit`;
    axios.get(api).then((res: AxiosResponse<{ schema: string; value: string; title: string; }[]>) => {
      resolve(res.data);
    }).catch(err => reject(err));
  });
}

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Delete, Edit } from '@mui/icons-material';
import settings from '../../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import AutoEditor from '../../../components/auto/AutoEditor';
import { useQueryParams } from 'src/abstracts/NavigationHelpers';
import CompanyList from '../../../components/identity/CompanyList';
import WorkerSearch from '../../../components/identity/WorkerSearch';
import { ICheckinState } from '../../../stores/training/checkin/types';
import AutoDeletePrompt from '../../../components/auto/AutoDeletePrompt';
import FitTestSelector from '../../../components/booking/FitTestSelector';
import { IFitTestConfig } from '../../../stores/database/training/courses';
import AutoGrid, { AutoGridActions } from '../../../components/auto/AutoGrid';
import { objectMapArray, quickGrid } from '../../../abstracts/DataroweHelpers';
import { quickAddFitSchedule } from '../../../stores/database/training/courseSchedule';
import { ISelectColumnMap, FilterConditionType } from '../../../stores/database/interfaces';
import { hubClear, loadQuickAddWorkerSchedules } from '../../../stores/training/checkin/actions';
import { Paper, Theme, Grid, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { ICustomSelectColumnMap, AutoRow, autoLoad, AutoLoadKeyDisplay, IAutoLoadCacheConnected } from '../../../components/auto/AutoLoad';
import FitTestList, { IFittestConnectedProps, mapCheckinActions, mapFromAppState } from '../../../components/training/checkin/FitTestList';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  },
  verticalCenter: {
    alignItems: 'center'
  }
});

interface IFitTestProps {
  classes: any;
  checkin: ICheckinState;
  hubClear: typeof hubClear;
  history: History;
  loadQuickAddWorkerSchedules: typeof loadQuickAddWorkerSchedules;
}

interface IFitTestPageQueryParams {
  date: string;
  courseScheduleId?: number;
}

interface IFitTestPageEditState {
  workerScheduleId: number;
  personId: number;
  workerHistoryId?: number;
}

interface IFitTestPageDeleteState {
  id: number;
  rowData: AutoRow;
}

interface IFitTestPageQuickAddState {
  userCompanyId: number;
  companyDisplay: string;
  fitTests: IFitTestConfig[];
}

const FitTestPage = (props: IFitTestProps & IFittestConnectedProps) => {
  const { classes, checkin, fetchConfigRequest } = props;

  const [params, setParams] = useQueryParams<IFitTestPageQueryParams>({
    date: {
      type: 'string',
      defaultValue: moment().format(settings.apiDateFormatMoment),
      useDateValidation: true
    },
    courseScheduleId: {
      type: 'number'
    }
  });

  let momentDate = params.date == null ? moment() : moment(params.date, settings.apiDateFormatMoment);
  if (!momentDate.isValid()) momentDate = moment();

  const [editConfig, setEditConfig] = React.useState<IFitTestPageEditState | undefined>(undefined);

  const cacheConnected: IAutoLoadCacheConnected = {
    fetchConfigRequest: props.fetchConfigRequest,
    createAlertBulk: props.createAlertBulk,
    refreshCacheData: props.refreshCacheData
  };

  const handleReturnToFitTest = () => {
    setEditConfig(undefined);
  };

  const handleDateChange = (dateValue: string) => {
    const date = dateValue ? moment(dateValue) : undefined;

    if (typeof date !== 'undefined'){
      setParams({
        date: date.format(settings.apiDateFormatMoment),
        courseScheduleId: undefined
      });
    }

    handleReturnToFitTest();
  };

  interface IFitTestHistory {
    baseTableId?: number;
    keyField: ISelectColumnMap | undefined;
    displayField: ISelectColumnMap | undefined;
    workerScheduleField?: ISelectColumnMap;
    personField?: ISelectColumnMap;
    displayColumns: ICustomSelectColumnMap[];
    rowData: AutoRow;
    rowDataLastUpdate: number;
  }

  const [fitTestGrid, setFitTestGrid] = React.useState<IFitTestHistory>({
    displayColumns: [],
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    keyField: undefined,
    displayField: undefined
  });

  const loadData = (dataParams: IFitTestHistory, workerScheduleId: number) => {
    autoLoad({
      fetchConfigRequest,
      createAlertBulk: props.createAlertBulk,
      search: {
        baseTableId: dataParams.baseTableId!,
        name: '',
        baseFilter: {
          columnId: dataParams.workerScheduleField!.columnId,
          lookupPath: dataParams.workerScheduleField!.lookupPath ?? [],
          type: FilterConditionType.Equal,
          firstParameter: workerScheduleId
        }
      },
      baseTableId: dataParams.baseTableId!,
      keyField: dataParams.keyField!,
      displayField: dataParams.displayField!,
      allFields: dataParams.displayColumns.concat([dataParams.personField!])
    }).then((res) => {
      setFitTestGrid((old) => ({
        ...old,
        rowData: res,
        rowDataLastUpdate: moment().valueOf()
      }));
    });
  };

  React.useEffect(() => {
    const dailyFittestSchedule = checkin.connectedCourseSchedules.find(cs => cs.scheduleType === 'fittest');

    if (typeof dailyFittestSchedule !== 'undefined') {
      setParams({ date: params.date, courseScheduleId: dailyFittestSchedule.id });
    }
  }, [checkin.connectedCourseSchedules]);

  React.useEffect( () => {
    if (editConfig != null){
      if (fitTestGrid.keyField == null) {
        fetchConfigRequest(undefined, {
          key: `FitTestHistory_${editConfig.workerScheduleId}`,
          action: (db) => {
            const baseTable = db.getTableByCombinedName('training.workerHistory');
            const keyField = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.primaryKeyId!]);
            const displayField = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.displayKeyId!]);
            const [workerScheduleField, personField, ...displayFields] = db.mapDisplayColumnsByNamesWithId(baseTable.tableId, [
              {
                key: 'workerSchedule'
              },
              {
                key: 'workerSchedule.person.worker.person'
              },
              {
                key: 'course.code'
              },
              {
                key: 'course.title'
              },
              {
                key: 'expiryDate'
              },
              {
                key: 'respiratorSize'
              },
              {
                key: 'respiratorFactor'
              }
            ]);

            setFitTestGrid((old) => ({
              ...old,
              keyField,
              displayField,
              workerScheduleField,
              personField,
              displayColumns: displayFields,
              baseTableId: baseTable.tableId
            }));

            loadData({
              ...fitTestGrid,
              keyField,
              displayField,
              workerScheduleField,
              personField,
              displayColumns: displayFields,
              baseTableId: baseTable.tableId
            }, editConfig.workerScheduleId);
          }
        });
      } else {
        loadData(fitTestGrid, editConfig.workerScheduleId);
      }

    }
  }, [editConfig?.workerScheduleId]);

  const handleWorkerSelected = (workerScheduleId: number, personId: number) => {
    setEditConfig({
      workerScheduleId,
      personId
    });
  };

  const handleMaskSave = () => {
    loadData(fitTestGrid, editConfig!.workerScheduleId);
    // setEditConfig(undefined);
  };

  const [deletePrompt, setDeletePrompt] = React.useState<IFitTestPageDeleteState | undefined>();

  const handleDeleteCancel = () => setDeletePrompt(undefined);

  const handleDeleteSuccess = () => {
    setDeletePrompt(undefined);
    loadData(fitTestGrid, editConfig!.workerScheduleId);
  };

  const preActions: AutoGridActions = [
    {
      icon: <Delete />,
      title: 'Delete',
      onClick: (row) => setDeletePrompt({
        id: row.workerHistoryId,
        rowData: row
      })
    },
    {
      icon: <Edit />,
      title: 'Edit',
      onClick: ({ workerHistoryId }) => setEditConfig(old => ({
        ...old!,
        workerHistoryId
      }))
    }
  ];

  const [quickAdd, setQuickAdd] = React.useState<IFitTestPageQuickAddState | undefined>();

  const handleQuickAddWorker = () => setQuickAdd({
    userCompanyId: 0,
    companyDisplay: '',
    fitTests: []
  });

  const handleCompanySelected = (_companyId: number, companyDisplay: string, userCompanyId: number) => setQuickAdd({
    userCompanyId,
    companyDisplay,
    fitTests: []
  });

  const handleQuickAddCancel = () => setQuickAdd(undefined);

  const handleSelectCourseSchedule = (event: SelectChangeEvent) => {
    setParams({
      date: params.date,
      courseScheduleId: +(event.target.value)
    });
  };

  const handleQuickAddWorkersSelected = (workers: AutoLoadKeyDisplay) => {
    quickAddFitSchedule({
      fitDate: params.date,
      userCompanyId: quickAdd?.userCompanyId ?? 0,
      personIds: objectMapArray(workers, (key) => +key), fitTests: quickAdd?.fitTests ?? [],
      courseScheduleId: params.courseScheduleId
    }).then((res) => {
      props.loadQuickAddWorkerSchedules(cacheConnected, objectMapArray(res.referenceKeys, (_key, value) => value.id));

      // -FUTURE- If adding a single Worker, auto-set `editConfig()` and trigger `loadData()`

      handleQuickAddCancel();

      props.createAlertBulk(
        res.messages.map((m) => ({
          autoDisplay: true,
          created: moment().format(settings.dateTimeFormatMoment),
          severity: 'error',
          title: 'Fit Test Quick Add Worker',
          description: m.message,
          page: 'Daily Fit Test',
          viewed: false
        }))
      );
    });
  };

  const controlGridSizes = quickGrid(12, 6, 3, 2, 2);
  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Grid container spacing={3} className={classes.verticalCenter}>
          <Grid item {...controlGridSizes}>
            <TextField
              fullWidth
              id="mui-pickers-date"
              InputLabelProps={{ shrink: true }}
              label="Date"
              onChange={event => handleDateChange(event.currentTarget.value)}
              type="date"
              value={momentDate.format(settings.apiDateFormatMoment)}
            />
          </Grid>
          <Grid item {...quickGrid(12, 6, 6, 6, 6)}>
            <FormControl fullWidth required>
              <InputLabel id="selectFitTestClassLabel">Select Fit Test Class</InputLabel>
              <Select label="Select Fit Test Class" labelId="selectFitTestClassLabel" id="selectFitTestClass" value={checkin.connectedCourseSchedules.length ? params.courseScheduleId?.toString() ?? '' : ''} onChange={handleSelectCourseSchedule}>
                {checkin.connectedCourseSchedules.map(connectedCourseSchedule =>
                  <MenuItem key={connectedCourseSchedule.id} value={connectedCourseSchedule.id}>
                    {connectedCourseSchedule.name}
                  </MenuItem>)
                }
              </Select>
          </FormControl>
          </Grid>
          <Grid item {...controlGridSizes}>
            <Button variant="contained" disabled={params.courseScheduleId == null} onClick={() => (editConfig == null ? handleQuickAddWorker() : handleReturnToFitTest())} color="primary" className={classes.button}>
              {editConfig == null ? 'Quick Add Worker(s)' : 'Return to Fit Tests'}
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 0 }} container spacing={3}>
          {momentDate == null ? undefined : (
            <Grid item xs={12}>
              <FitTestList
                key="dailyFittest"
                checkinDate={params.date}
                maxHeight="calc(100vh - 260px)"
                onRowSelected={handleWorkerSelected}
                courseScheduleId={params.courseScheduleId}
                personId={editConfig?.personId}
              />
            </Grid>
          )}
          {editConfig == null || fitTestGrid.keyField == null ? undefined : (
            <Grid item xs={12}>
              <AutoGrid
                key="gridList"
                exportTitle="Daily Fit Test"
                preActions={preActions}
                {...fitTestGrid}
              />
              {deletePrompt == null ? undefined : (
                <AutoDeletePrompt
                  key="promptDelete"
                  baseTable="training.workerHistory"
                  deleteId={deletePrompt.id}
                  rowData={deletePrompt.rowData}
                  mode="direct"
                  open
                  displayColumns={fitTestGrid.displayColumns}
                  keyField={fitTestGrid.keyField}
                  displayField={fitTestGrid.displayField}
                  onCancel={handleDeleteCancel}
                  onDelete={handleDeleteSuccess}
                />
              )}
            </Grid>
          )}
          {editConfig == null ? undefined : (
            <Grid item xs={12}>
              <AutoEditor
                baseTable="training.workerHistory"
                editId={editConfig?.workerHistoryId}
                customEditor="fittest"
                stateExtension={{
                  courseSearchFilters: {
                    scheduleTypeKeys: ['fittest', 'fittest-theory']
                  }
                }}
                additionalChanges={[{
                  columnId: fitTestGrid.workerScheduleField?.columnId ?? 0,
                  newValue: editConfig.workerScheduleId
                }]}
                onSave={handleMaskSave}
                mode="inline"
              />
            </Grid>
          )}
        </Grid>
        <CompanyList
          dialogTitle={`Select Company for fit testing on ${params.date}`}
          mode="direct"
          onCancel={handleQuickAddCancel}
          onCompanySelected={handleCompanySelected}
          open={quickAdd?.userCompanyId === 0}
        />
        <FitTestSelector
          mode="direct"
          onCancel={() => setQuickAdd(undefined)}
          onFitTestsSelected={(fitTests: IFitTestConfig[]) => setQuickAdd((old) => ({ ...old!, fitTests }))}
          open={false}
          timeSelect
          title={`Select Fit Tests for ${quickAdd?.companyDisplay} on ${params.date}`}
        />
        <WorkerSearch
          dialogTitle={`Schedule Fit Tests for ${quickAdd?.companyDisplay} on ${params.date}`}
          mode="direct"
          onSelectCancel={handleQuickAddCancel}
          onWorkersSelected={handleQuickAddWorkersSelected}
          open={(quickAdd?.userCompanyId ?? 0) > 0}
        />
      </div>
    </Paper>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapFromAppState, mapCheckinActions))(FitTestPage);

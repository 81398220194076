import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import settings from '../../abstracts/settings';
import { cloneDeep, Dictionary } from 'lodash';
import { createStyles, withStyles } from '@mui/styles';
import { ISetSearchConfig } from '../../stores/database/interfaces';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { mapConfigFetchToProps, IConnectedProps } from '../../components/auto/AutoGrid';
import { arrayMapObject, nameof, objectMapArray, objectSortMap, quickGrid } from '../../abstracts/DataroweHelpers';
import { ICourse, ICourseFilters, IFitTestConfig, loadCourseCache } from '../../stores/database/training/courses';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, DialogActions, FormControlLabel, Checkbox, FormControl, Select, MenuItem, FormHelperText, Typography, SelectChangeEvent, Theme, useTheme } from '@mui/material';

const generateSxClasses = (theme: Theme) => {
  return {
    nestedGrid: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(3)
    }
  };
};

export interface IFitTestSelectorProps extends ISetSearchConfig, ICourseFilters {
  classes: any;
  title: string;
  mode: 'button' | 'inline' | 'direct';
  open?: boolean;
  timeSelect?: boolean;
  fitTestConfig?: IFitTestConfig[];
}

interface IFitTestSelectActions {
  onFitTestsSelected: (fitTests: IFitTestConfig[]) => void;
  onCancel?: () => void;
}

export const enum FitTestTypes {
  AirPure = 'rft-apr',
  FreshScott = 'rft-sctt',
  FreshDraeger = 'rft-drgr',
  N95 = 'rft-n95'
}

interface IFitTestSelectorState {
  text: string;
  error: string;
  showDeleted: boolean;
  open: boolean;
  selectedFitTests: Dictionary<IFitTestConfig>;
  courses?: Dictionary<ICourse>;
  courseOptions: Dictionary<number[]>;
  allCoursesLoading: boolean;
}

interface ITimeslotMenuItem {
  key: string;
  label: string;
}

const FitTestSelector = (props: IFitTestSelectorProps & IFitTestSelectActions & IConnectedProps) => {
  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);

  const fitTestPropToObj = () => arrayMapObject(props.fitTestConfig ?? [], (_idx, f) => [f.scheduleType, f]);

  const [state, setState] = React.useState<IFitTestSelectorState>({
    text: '',
    error: '',
    showDeleted: false,
    open: false,
    selectedFitTests: fitTestPropToObj(),
    allCoursesLoading: false,
    courseOptions: {
      [FitTestTypes.AirPure]: [],
      [FitTestTypes.FreshScott]: [],
      [FitTestTypes.FreshDraeger]: [],
      [FitTestTypes.N95]: []
    }
  });

  const handleVerifySelection = (fitTests: Dictionary<IFitTestConfig>): string => {
    const res: string[] = [];
    if (fitTests[FitTestTypes.AirPure] != null && fitTests[FitTestTypes.AirPure].courseIds.length === 0) {
      res.push('Please select the masks for Air Purifying, or uncheck it as an option.');
    }

    if (fitTests[FitTestTypes.N95] != null && fitTests[FitTestTypes.N95].courseIds.length === 0) {
      res.push('Please select the masks for N95, or uncheck it as an option.');
    }

    if (fitTests[FitTestTypes.FreshScott] != null) {
      if (fitTests[FitTestTypes.FreshScott].courseIds.length === 0) {
        res.push('Please select the mask for Scott Fresh Air, or uncheck it as an option.');
      }

      if (!fitTests[FitTestTypes.FreshScott].isSelfContained && !fitTests[FitTestTypes.FreshScott].isSuppliedAir) {
        res.push('Please select self contained or supplied air for Scott mask.');
      }
    }

    if (fitTests[FitTestTypes.FreshDraeger] != null) {
      if (fitTests[FitTestTypes.FreshDraeger].courseIds.length === 0) {
        res.push('Please select the mask for Draeger Fresh Air, or uncheck it as an option.');
      }

      if (!fitTests[FitTestTypes.FreshDraeger].isSelfContained && !fitTests[FitTestTypes.FreshDraeger].isSuppliedAir) {
        res.push('Please select self contained or supplied air for Draeger mask.');
      }
    }

    return res.join('\n');
  };

  React.useEffect(() => setState((old) => ({
    ...old,
    selectedFitTests: fitTestPropToObj()
  })), [props.fitTestConfig]);

  function handleOpen() {
    setState((old) => ({
      ...old,
      open: true
    }));
  }

  function handleClose() {
    setState((old) => ({
      ...old,
      open: false
    }));

    if (props.onCancel !== undefined) props.onCancel();
  }

  function handleSubmit() {
    setState((old) => ({ ...old, open: false }));
    props.onFitTestsSelected(objectMapArray(state.selectedFitTests, (_key, fitTest) => fitTest));
  }

  const handleAprToggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      if (selectedFitTests[FitTestTypes.AirPure] == null) {
        selectedFitTests[FitTestTypes.AirPure] = props.fitTestConfig?.find((fitTest) => fitTest.scheduleType === FitTestTypes.AirPure) ?? {
          courseIds: [],
          scheduleType: FitTestTypes.AirPure
        };
      } else {
        delete selectedFitTests[FitTestTypes.AirPure];
      }

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleN95Toggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      if (selectedFitTests[FitTestTypes.N95] == null) {
        selectedFitTests[FitTestTypes.N95] = props.fitTestConfig?.find((fitTest) => fitTest.scheduleType === FitTestTypes.N95) ?? {
          courseIds: [],
          scheduleType: FitTestTypes.N95
        };
      } else {
        delete selectedFitTests[FitTestTypes.N95];
      }

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleScottToggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      if (selectedFitTests[FitTestTypes.FreshScott] == null) {
        selectedFitTests[FitTestTypes.FreshScott] = props.fitTestConfig?.find((fitTest) => fitTest.scheduleType === FitTestTypes.FreshScott) ?? {
          courseIds: [],
          scheduleType: FitTestTypes.FreshScott,
          isSelfContained: false,
          isSuppliedAir: false
        };
      } else {
        delete selectedFitTests[FitTestTypes.FreshScott];
      }

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleDraegerToggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      if (selectedFitTests[FitTestTypes.FreshDraeger] == null) {
        selectedFitTests[FitTestTypes.FreshDraeger] = props.fitTestConfig?.find((fitTest) => fitTest.scheduleType === FitTestTypes.FreshDraeger) ?? {
          courseIds: [],
          scheduleType: FitTestTypes.FreshDraeger,
          isSelfContained: false,
          isSuppliedAir: false
        };
      } else {
        delete selectedFitTests[FitTestTypes.FreshDraeger];
      }

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const loadCoursesCache = () => {
    if (!state.allCoursesLoading) {
      setState((old) => ({
        ...old,
        allCoursesLoading: true
      }));

      loadCourseCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk).then((res) => {
        const courses = arrayMapObject(res.courseCache.courses, (_idx, courseCache) => [courseCache.courseId, courseCache]);
        const courseOptions = {
          [FitTestTypes.AirPure]: objectSortMap(courses, nameof<ICourse>('title'), (course) => (!course.item.active || course.item.courseTypeKey !== FitTestTypes.AirPure ? undefined : course.item.courseId)),
          [FitTestTypes.N95]: objectSortMap(courses, nameof<ICourse>('title'), (course) => (!course.item.active || course.item.courseTypeKey !== FitTestTypes.N95 ? undefined : course.item.courseId)),
          [FitTestTypes.FreshScott]: objectSortMap(courses, nameof<ICourse>('title'), (course) => (!course.item.active || course.item.courseTypeKey !== FitTestTypes.FreshScott ? undefined : course.item.courseId)),
          [FitTestTypes.FreshDraeger]: objectSortMap(courses, nameof<ICourse>('title'), (course) => (!course.item.active || course.item.courseTypeKey !== FitTestTypes.FreshDraeger ? undefined : course.item.courseId))
        };

        setState((old) => ({
          ...old,
          courses,
          courseOptions
        }));
      });
    }
  };

  React.useEffect(() => {
    loadCoursesCache();
  }, []);

  const handleAirPureChange = (event: SelectChangeEvent<number[]>) => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.AirPure].courseIds = event.target.value as number[];

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleN95Change = (event: SelectChangeEvent<number[]>) => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.N95].courseIds = event.target.value as number[];

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleFreshScottChange = (event: SelectChangeEvent<number>) => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.FreshScott].courseIds = [event.target.value as number];

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleFreshScottSelfContainedToggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.FreshScott].isSelfContained = !selectedFitTests[FitTestTypes.FreshScott].isSelfContained;

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleFreshScottSuppliedAirToggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.FreshScott].isSuppliedAir = !selectedFitTests[FitTestTypes.FreshScott].isSuppliedAir;

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleFreshDraegerChange = (event: SelectChangeEvent<number>) => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.FreshDraeger].courseIds = [event.target.value as number];

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleFreshDraegerSelfContainedToggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.FreshDraeger].isSelfContained = !selectedFitTests[FitTestTypes.FreshDraeger].isSelfContained;

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleFreshDraegerSuppliedAirToggle = () => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[FitTestTypes.FreshDraeger].isSuppliedAir = !selectedFitTests[FitTestTypes.FreshDraeger].isSuppliedAir;

      return {
        ...old,
        selectedFitTests,
        error: handleVerifySelection(selectedFitTests)
      };
    });
  };

  const handleSelectedTimeChange = (event: SelectChangeEvent, type: FitTestTypes): void => {
    setState((old) => {
      const selectedFitTests = cloneDeep(old.selectedFitTests);
      selectedFitTests[type].scheduledTime = event.target.value;

      return {
        ...old,
        selectedFitTests
      };
    });
  };

  const generateTimeslotMenuItems = (): ITimeslotMenuItem[]  => {
    const menuItems: ITimeslotMenuItem[] = [];

    let iterationDate = moment('1900-01-01 00:00:00');
    const endOfDay = moment('1900-01-01 23:59:59');

    while (iterationDate.isBefore(endOfDay)) {
      menuItems.push({
        key: iterationDate.format(settings.apiTimeFormatMoment),
        label: iterationDate.format(settings.timeFormatMoment)
      });

      iterationDate.add(30, 'minutes');
    }

    return menuItems;
  };

  const displaySelectedTime = (type: FitTestTypes) => {
    if (!props.timeSelect && state.selectedFitTests[type]?.scheduledTime == null) return undefined;

    const controlGridSizes = quickGrid(5, 5, 2, 2, 2);
    return (
      <Grid item {...controlGridSizes}>
        <FormControl fullWidth disabled={!props.timeSelect || state.selectedFitTests[type] == null}>
          <Select fullWidth id={`${type}_time`} value={state.selectedFitTests[type]?.scheduledTime ?? ''} onChange={(event: SelectChangeEvent) => handleSelectedTimeChange(event, type)}>
            {generateTimeslotMenuItems().map(timeslot => <MenuItem key={`fitTestMenuItem${timeslot.key}`} value={timeslot.key}>{timeslot.label}</MenuItem>)}
          </Select>
          <FormHelperText>Time</FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  const maskControlGridSizes = (type: FitTestTypes) => {
    if (!props.timeSelect && state.selectedFitTests[type]?.scheduledTime == null) return quickGrid(11, 11, 11, 11, 11);

    return quickGrid(5, 5, 9, 9, 9);
  };

  const displayGrid = () => (
    <>
      <Grid sx={{ marginBottom: theme.spacing(4) }} container spacing={3}>
        <Grid item xs={1}>
          <FormControlLabel label="" control={<Checkbox checked={state.selectedFitTests[FitTestTypes.AirPure] != null} onChange={handleAprToggle} value={FitTestTypes.AirPure} color="primary"/>}/>
        </Grid>
        {displaySelectedTime(FitTestTypes.AirPure)}
        <Grid item {...maskControlGridSizes(FitTestTypes.AirPure)}>
          <FormControl fullWidth disabled={state.selectedFitTests[FitTestTypes.AirPure] == null}>
            <Select
              labelId={`${FitTestTypes.AirPure}_label`}
              id={`${FitTestTypes.AirPure}_chip`}
              multiple
              value={state.selectedFitTests?.[FitTestTypes.AirPure]?.courseIds ?? []}
              onChange={handleAirPureChange}
            >
              {state.courseOptions[FitTestTypes.AirPure].map((id) => (
                <MenuItem key={id} value={id}>{state.courses?.[id]?.code} - ${state.courses?.[id]?.title}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Schedule Air Purifying Respirator/Powered Air Purifying Respirator (North, 3M, etc.)</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Divider/>
      <Grid sx={{ ...sxClasses.nestedGrid }} container spacing={3}>
        <Grid item xs={1}>
          <FormControlLabel label="" control={<Checkbox checked={state.selectedFitTests[FitTestTypes.FreshScott] != null} onChange={handleScottToggle} value={FitTestTypes.FreshScott} color="primary"/>}/>
        </Grid>
        {displaySelectedTime(FitTestTypes.FreshScott)}
        <Grid item {...maskControlGridSizes(FitTestTypes.FreshScott)}>
          <FormControl fullWidth disabled={state.selectedFitTests[FitTestTypes.FreshScott] == null}>
            <Select
              labelId={`${FitTestTypes.FreshScott}_label`}
              id={`${FitTestTypes.FreshScott}_chip`}
              value={state.selectedFitTests?.[FitTestTypes.FreshScott]?.courseIds[0] ?? ''}
              onChange={handleFreshScottChange}
            >
              {state.courseOptions[FitTestTypes.FreshScott].map((id) => (
                <MenuItem key={id} value={id}>{state.courses?.[id]?.code} - {state.courses?.[id]?.title}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Schedule Fresh Air with Scott Mask</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={5}>
          <FormControlLabel
            disabled={state.selectedFitTests[FitTestTypes.FreshScott] == null}
            control={<Checkbox checked={state.selectedFitTests[FitTestTypes.FreshScott]?.isSelfContained ?? false} onChange={handleFreshScottSelfContainedToggle} value={`${FitTestTypes.FreshScott}_scba`} color="primary"/>}
            label="Self Contained Breathing Apparatus"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            disabled={state.selectedFitTests[FitTestTypes.FreshScott] == null}
            control={<Checkbox checked={state.selectedFitTests[FitTestTypes.FreshScott]?.isSuppliedAir ?? false} onChange={handleFreshScottSuppliedAirToggle} value={`${FitTestTypes.FreshScott}_saba`} color="primary"/>}
            label="Supplied Air Breathing Apparatus w/Bottle Att"
          />
        </Grid>
      </Grid>
      <Divider/>
      <Grid sx={{ ...sxClasses.nestedGrid }} container spacing={3}>
        <Grid item xs={1}>
          <FormControlLabel label="" control={<Checkbox checked={state.selectedFitTests[FitTestTypes.FreshDraeger] != null} onChange={handleDraegerToggle} value={FitTestTypes.FreshDraeger} color="primary"/>}/>
        </Grid>
        {displaySelectedTime(FitTestTypes.FreshDraeger)}
        <Grid item {...maskControlGridSizes(FitTestTypes.FreshDraeger)}>
          <FormControl fullWidth disabled={state.selectedFitTests[FitTestTypes.FreshDraeger] == null}>
            <Select
              labelId={`${FitTestTypes.FreshDraeger}_label`}
              id={`${FitTestTypes.FreshDraeger}_chip`}
              value={state.selectedFitTests?.[FitTestTypes.FreshDraeger]?.courseIds[0] ?? ''}
              onChange={handleFreshDraegerChange}
            >
              {state.courseOptions[FitTestTypes.FreshDraeger].map((id) => (
                <MenuItem key={id} value={id}>{state.courses?.[id]?.code} - {state.courses?.[id]?.title}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Schedule Fresh Air with Draeger Mask</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={5}>
          <FormControlLabel
            disabled={state.selectedFitTests[FitTestTypes.FreshDraeger] == null}
            control={<Checkbox checked={state.selectedFitTests[FitTestTypes.FreshDraeger]?.isSelfContained ?? false} onChange={handleFreshDraegerSelfContainedToggle} value={`${FitTestTypes.FreshDraeger}_scba`} color="primary"/>}
            label="Self Contained Breathing Apparatus"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            disabled={state.selectedFitTests[FitTestTypes.FreshDraeger] == null}
            control={<Checkbox checked={state.selectedFitTests[FitTestTypes.FreshDraeger]?.isSuppliedAir ?? false} onChange={handleFreshDraegerSuppliedAirToggle} value={`${FitTestTypes.FreshDraeger}_saba`} color="primary"/>}
            label="Supplied Air Breathing Apparatus w/Bottle Att"
          />
        </Grid>
      </Grid>
      <Divider/>
      <Grid sx={{ ...sxClasses.nestedGrid }} container spacing={3}>
        <Grid item xs={1}>
          <FormControlLabel label="" control={<Checkbox checked={state.selectedFitTests[FitTestTypes.N95] != null} onChange={handleN95Toggle} value={FitTestTypes.N95} color="primary"/>}/>
        </Grid>
        {displaySelectedTime(FitTestTypes.N95)}
        <Grid item {...maskControlGridSizes(FitTestTypes.N95)}>
          <FormControl fullWidth disabled={state.selectedFitTests[FitTestTypes.N95] == null}>
            <Select
              labelId={`${FitTestTypes.N95}_label`}
              id={`${FitTestTypes.N95}_chip`}
              multiple
              value={state.selectedFitTests?.[FitTestTypes.N95]?.courseIds ?? []}
              onChange={handleN95Change}
            >
              {state.courseOptions[FitTestTypes.N95].map((id) => (
                <MenuItem key={id} value={id}>
                  {state.courses?.[id]?.code} - {state.courses?.[id]?.title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Schedule N95</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: theme.spacing(3) }} container spacing={3}>
        <Grid item xs={12}>
          <Typography>{state.error}</Typography>
        </Grid>
      </Grid>
    </>
  );

  return props.mode === 'inline' ? (
    displayGrid()
  ) : (
    <React.Fragment>
      {props.mode === 'direct' ? undefined : (
        <Button variant="contained" fullWidth size="small" onClick={handleOpen}>
          {props.title}
        </Button>
      )}
      <Dialog open={state.open || (props.mode === 'direct' && !!props.open)} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>{displayGrid()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={state.error !== ''}>
            Set Fit Test Choice
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default compose(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(FitTestSelector);

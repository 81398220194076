import { Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as errors from '../abstracts/ErrorConstants';
import { createStyles, withStyles } from '@mui/styles';

const styles = (theme: Theme) => createStyles({
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '10px 16px'
  },
  paper: {
    margin: 'auto',
    maxWidth: 936,
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  }
});

interface IErrorPageProps {
  classes: any;
  error?: Error;
  errorType?: string
}

function content(props: IErrorPageProps) {
  const { classes, errorType, error } = props;

  const message = () => {
    switch (errorType) {
      case errors.ERROR_LOGIN_CONNECTION:
        return 'unable to connect to login service.';
      default:
        return error?.stack || 'unknown error';
    }
  };

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="primary" elevation={0}>
        <Toolbar>
          <Typography>
            An Error Has Occurred
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <Typography color="textSecondary" align="center">
          {message}
        </Typography>
      </div>
    </Paper>
  );
}

export default withStyles(styles)(content);

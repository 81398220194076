import axios from 'axios';
import moment from 'moment';
import settings from '../../../abstracts/settings';
import { AutoRow } from '../../../components/auto/AutoLoad';
import { IFitTestConfig, IFitTestSchedule } from './courses';
import { ISaveResult } from '../../../components/auto/AutoChange';
import { isInterface, objectMapArray } from '../../../abstracts/DataroweHelpers';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

export interface IFitTestScheduleSlot extends IFitTestSchedule {
  usedSeats: number;
}

export interface IFitTestType {
  scheduleType: string;
  duration: number;
  maxSeats: number;
  message?: string;
  errorMessage?: string;
}

export interface ITimeslotData {
  start: string;
  end: string;
  fitTestTypes: IFitTestType[];
  errorMessage?: string;
}

export interface IScheduleData extends ITimeslotData {
  slots: IFitTestScheduleSlot[];
}

export const getFitTestType = (timeslot: ITimeslotData, scheduleType: string): IFitTestType => timeslot.fitTestTypes?.find(f => f.scheduleType === scheduleType) ?? { scheduleType, duration: 999, maxSeats: 0, message: `schedule type ${scheduleType} is not available` };

export interface ICourseSchedule {
  courseScheduleId: number;
  courseId?: number;
  course?: {
    code: string;
    title: string;
    pricePartner?: number;
    priceNonPartner?: number;
  };
  section: number;
  roomId?: number;
  trainingDate: moment.Moment;
  pricePartner?: number;
  priceNonPartner?: number;
  trainingTime?: moment.Moment;
  seatMinimum: number;
  seatMaximum: number;
  instructors: string;
  offsite: boolean;
  pilot: boolean;
  special: boolean;
  deleted: boolean;
  scheduleData: IScheduleData[] | any;
}

export const courseScheduleConfig = {
  _table: 'training.courseSchedule',
  _key: 'courseScheduleId',
  pricePartner: 'pricePartner',
  priceNonPartner: 'priceNonPartner',
  displayPricePartner: 'displayPricePartner',
  displayPriceNonPartner: 'displayPriceNonPartner',
  scheduleRoomId: 'room',
  scheduleRoom: 'room.name',
  recordsUpload: 'recordsUpload',
  courseId: 'course',
  seatType: 'course.courseCategory.courseType.scheduleType.key',
  defaultPricePartner: 'course.pricePartner',
  defaultPriceNonPartner: 'course.priceNonPartner',
  timeslotData: 'timeslotData',
  courseCode: 'course.code',
  flagOffsite: 'offsite',
  flagPilot: 'pilot',
  flagSpecial: 'special',
  flagUpload: 'recordsUpload',
  section: 'sectionNumber',
  seatMin: 'seatMinimum',
  seatMax: 'seats',
  seatMaxDisplay: 'seatDetails.seats',
  courseTitle: 'course.title',
  trainingDate: 'trainingDate',
  trainingTime: 'trainingTime',
  seatEnrolment: 'seatDetails.enrolment',
  seatsReserved: 'seatDetails.reservedSeats',
  seatsUsed: 'seatDetails.usedSeats',
  seatsAvailable: 'seatDetails.availableSeats',
  instructors: 'seatDetails.instructors',
  reservedCompany: 'reservedCompany.name'
};

export const courseScheduleConfigForRaw = (): string[] => objectMapArray(courseScheduleConfig, (key, val) => key === '_table' ? undefined : val);

export const mapRawToCourseSchedule = (row: AutoRow): ICourseSchedule => {
  const id = row[courseScheduleConfig._key];
  return {
    courseScheduleId: id,
    courseId: row[courseScheduleConfig.courseId],
    course: {
      code: row[courseScheduleConfig.courseCode],
      title: row[courseScheduleConfig.courseTitle],
      pricePartner: row[courseScheduleConfig.defaultPricePartner],
      priceNonPartner: row[courseScheduleConfig.defaultPriceNonPartner]
    },
    section: row[courseScheduleConfig.section],
    pricePartner: row[courseScheduleConfig.pricePartner],
    priceNonPartner: row[courseScheduleConfig.priceNonPartner],
    roomId: row[courseScheduleConfig.scheduleRoomId],
    trainingDate: moment(row[courseScheduleConfig.trainingDate]),
    trainingTime: row[courseScheduleConfig.trainingTime],
    seatMinimum: row[courseScheduleConfig.seatMin],
    seatMaximum: row[courseScheduleConfig.seatMax],
    offsite: row[courseScheduleConfig.flagOffsite],
    pilot: row[courseScheduleConfig.flagPilot],
    special: row[courseScheduleConfig.flagSpecial],
    deleted: false,
    scheduleData: row[courseScheduleConfig.timeslotData] == null ? undefined : JSON.parse(row[courseScheduleConfig.timeslotData]),
    instructors: row[courseScheduleConfig.instructors]
  };
};

interface ICompanyReserveLimit { courseScheduleId: number; companyId: number; }
interface IExistingReserveLimit { courseScheduleId: number; courseScheduleReserveId: number; }

export const getReserveLimit = (props: ICompanyReserveLimit | IExistingReserveLimit): Promise<number> =>
  new Promise((resolve) => {
    let api = `${API_ENDPOINT}/Training/Reserves/MaxSeats?courseScheduleId=${props.courseScheduleId}`;
    if (isInterface<ICompanyReserveLimit>(props, 'companyId')) {
      api += `&companyId=${props.companyId}`;
    } else {
      api += `&courseScheduleReserveId=${props.courseScheduleReserveId}`;
    }
    axios.get<number>(api).then((res) => {
      console.log('Max seats fetched', { api, seats: res.data });
      resolve(res.data);
    });
  });

export const quickAddFitSchedule = (props: { fitDate: string; userCompanyId: number; personIds: number[]; fitTests: IFitTestConfig[]; courseScheduleId?: number }): Promise<ISaveResult> =>
  new Promise((resolve) => {
    const api = `${API_ENDPOINT}/Training/Schedule/QuickAddFitTest/${props.fitDate}?userCompanyId=${props.userCompanyId}&personIds=${props.personIds.join(',')}&courseScheduleId=${props.courseScheduleId}`;
    axios.post<ISaveResult>(api).then(res => resolve(res.data));
  });

export const mergeCourseScheduleDate = (date: string, time: string | undefined, dateFormat: string): string =>
  moment(date).format(dateFormat) + (time == null ? '' : ` ${moment(`1900-01-01 ${time}`).format(settings.timeFormatMoment)}`);

export const formatTime = (time: string) => moment(`1900-01-01 ${time}`).format(settings.timeFormatMoment);

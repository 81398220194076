import React from 'react';
import { TextField } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';

const styles = () => createStyles({});

export interface INumberFieldProps {
  min?: number;
  max?: number;
}

interface INumberEditorProps extends IAutoEditorImplementation<number | undefined>, INumberFieldProps {
  classes: any;
}

const NumberEditor = (props: INumberEditorProps) => {
  const { field, editValue, error, warning, onValueUpdated } = props;
  const [value, setValue] = React.useState(props.editValue);

  React.useEffect(() => {
    setValue(editValue);
  }, []);

  React.useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  const handleFieldUpdate = (v: string) => {
    if (v === '') {
      setValue(field.required ? 0 : undefined);
    } else {
      const newVal = parseInt(v, 10);
      if (!isNaN(newVal)) {
        setValue(newVal);
      }
    }
  };

  const handleFieldBlur = () => onValueUpdated(value ?? undefined, field, value == null ? '' : value.toString());

  return <TextField
    InputProps={{ inputProps: { min: props.min, max: props.max }, endAdornment: props.endAdornment }}
    id={`numberEditor_${field.columnId}`}
    label={field.title}
    margin="dense"
    type="number"
    fullWidth
    required={field.required}
    value={value ?? (field.required ? 0 : '')}
    error={!!error}
    helperText={error || warning || field.description}
    disabled={props.disabled}
    onChange={event => handleFieldUpdate(event.currentTarget.value)}
    // when focus leaves, pass the value up
    onBlur={handleFieldBlur}
    // if user presses escape, cancel update to the value and reinstate the prop value
    onKeyDown={(event) => { if (event.keyCode === 27) setValue(editValue); }}
  />;
};

export default withStyles(styles)(NumberEditor);

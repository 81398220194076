import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import settings from '../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import Reserves from '../../components/booking/Reserves';
import { IProfileState } from '../../stores/profile/types';
import Notices from '../../components/systemConfig/Notices';
import { Theme, Toolbar, Typography, Paper } from '@mui/material';
import DefaultAutoGrid from '../../components/auto/DefaultAutoGrid';
import WorkerSchedule from '../../components/training/WorkerSchedule';
import { workerWebAssignLink } from '../../components/training/WorkerWebSchedule';

const styles = (theme: Theme) => createStyles({
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '10px 16px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  offsetCaption: {
    marginLeft: theme.spacing(4)
  }
});

interface IDashboardProps {
  classes: any;
}

interface IConnectedProps {
  profile: IProfileState;
}

const DashboardPage = (props: IDashboardProps & IConnectedProps) => {
  const { classes } = props;

  return (
    <>
      <Notices />
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography>Scheduled training for the next 7 days</Typography>
        </Toolbar>
        <div className={classes.contentWrapper}>
          <WorkerSchedule dateRange={{ start: moment().format(settings.apiDateFormatMoment), end: moment().add(7, 'days').format(settings.apiDateFormatMoment) }} maxHeight="calc(100vh - 260px)" />
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography>Reserved seats for the next 7 days</Typography>
        </Toolbar>
        <div className={classes.contentWrapper}>
          <Reserves dateRange={{ start: moment(), end: moment().add(7, 'days') }} maxHeight="calc(100vh - 260px)" />
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography>
            Web-Based Training to complete for the next 7 days
          </Typography>
          <Typography variant="caption" className={classes.offsetCaption}>
            Related web-based training is cancelled when scheduled training is cancelled.
          </Typography>
        </Toolbar>
        <div className={classes.contentWrapper}>
          <DefaultAutoGrid
            tableName="training.workerWebAssign"
            columns={[
              'person.worker.person.iecNumber',
              'person.worker.displayName',
              'course.title',
              'userCompany.company.name',
              'userCompany.user.displayName',
              'userCompany.user.phoneNumber',
              'connectedWorkerSchedule.courseSchedule.trainingDate',
              workerWebAssignLink
            ]}
            quickFilter={[{
              path: 'completeDateUTC'
            }, {
              path: 'connectedWorkerSchedule.courseSchedule.trainingDate',
              value: { startDate: moment().format(settings.apiDateFormatMoment), endDate: moment().add(7, 'days').format(settings.apiDateFormatMoment) }
            }]}
            pagination
          />
        </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    profile: state.profile
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, undefined))(DashboardPage);

import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { useParams } from 'react-router';
import React, { ComponentType } from 'react';
import { IProfileState } from '../../stores/profile/types';
import { INavigationLink } from '../../abstracts/NavigationLinks';
import { setTitleSubtitle, setTitle } from '../../stores/ui/actions';
import { Box, Button, Paper, Theme, Typography, useTheme } from '@mui/material';
import { fetchConfigRequest } from 'src/stores/database/actions';
import LoadingScreen from 'src/components/common/LoadingScreen';

const generateSxClasses = (theme: Theme) => {
  return {
    fullSize: {
      width: '100%',
      textAlign: 'center'
    },
    marginBottom: {
      marginBottom: '2vh'
    },
    paper: {
      margin: 'auto',
      overflow: 'hidden',
      padding: theme.spacing(2)
    }
  };
};

interface IConnectedProps {
  classes: any;
  setTitleSubtitle: typeof setTitleSubtitle;
  setTitle: typeof setTitle;
  fetchConfigRequest: typeof fetchConfigRequest;
  profile: IProfileState;
  dbLoaded: boolean;
  dbLoading: boolean;
}

export interface IRootPageProps {
  title: string;
  subtitle?: string;
  helpGuid: string;
  page?: React.ReactNode;
  linkItem?: INavigationLink;
  isCompanyLink?: boolean;
}

interface HttpStatusCodes {
  statusCode: number;
  title: string;
  message: string;
}

const fallbackStatus: HttpStatusCodes = {
  statusCode: 500,
  title: 'Server Error',
  message: 'An error occurred. If this persists on reload, please contact BASES with the URL you are attempting to load.'
};

const httpStatuses: HttpStatusCodes[] = [
  {
    statusCode: 401,
    title: 'Unauthorized',
    message: 'You do not have permission to access this page.'
  },
  {
    statusCode: 404,
    title: 'Not Found',
    message: 'The requested page was not found.'
  },
  fallbackStatus
];

const RootPageHelper = (props: IRootPageProps & IConnectedProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const { profile, title, helpGuid, subtitle, linkItem, isCompanyLink } = props;

  const { companyId } = useParams();
  const company = isCompanyLink && typeof companyId !== 'undefined' ? profile.companies.find((c) => c.companyId === +companyId) : undefined;

  const renderHttp = (httpCode: number) => {
    if (httpCode === 200) {
      if (subtitle == null) {
        props.setTitle(title, helpGuid);
      } else {
        props.setTitleSubtitle(title, helpGuid, subtitle);
      }

      return <>{props.page}</>;
    }

    const httpStatus = httpStatuses.find((status) => status.statusCode === httpCode) ?? fallbackStatus;

    props.setTitleSubtitle(httpStatus.title, '', `HTTP ${httpStatus.statusCode}`);

    return (
      <Paper sx={{ ...sxClasses.paper }}>
        <Box sx={{ ...sxClasses.fullSize }}>
          <Typography sx={{ ...sxClasses.marginBottom }}>{httpStatus!.message}</Typography>
          <Button variant="contained" color="primary" onClick={() => window.location.replace('/')}>Back to Safety</Button>
        </Box>
      </Paper>
    );
  };

  if (!props.dbLoaded) {
    if (!props.dbLoading) {
      props.fetchConfigRequest();
    }
    return <LoadingScreen />;
  }

  if (props.page == null) return renderHttp(404);

  if (typeof linkItem !== 'undefined') {
    if (isCompanyLink && typeof company === 'undefined') return renderHttp(404);

    if (typeof linkItem.display !== 'undefined' && !linkItem.display(profile, company)) return renderHttp(401);
  }

  return renderHttp(200);
};

const mapUiActions = {
  setTitleSubtitle,
  setTitle,
  fetchConfigRequest
};

const mapStateToProps = (state: IAppState) => ({ profile: state.profile, dbLoading: state.db.loading, dbLoaded: state.db.database.lastUpdated != null });

export default compose<ComponentType<IRootPageProps>>(connect(mapStateToProps, mapUiActions))(RootPageHelper);

import { createStyles, withStyles } from '@mui/styles';

const fadeIn1 = 'fade-in-one';
const fadeIn2 = 'fade-in-two';

const styles = () => createStyles({
  [`@keyframes ${fadeIn1}`]: {
    ['0%']: {
      opacity: 0
    },
    ['30%']: {
      opacity: 1
    },
    ['60%']: {
      opacity: 1
    },
    ['90%']: {
      opacity: 0
    },
    ['100%']: {
      opacity: 0
    }
  },
  animated1: {
    animationName: `$${fadeIn1}`,
    animationIterationCount: 'infinite'
  },
  [`@keyframes ${fadeIn2}`]: {
    ['0%']: {
      opacity: 0
    },
    ['30%']: {
      opacity: 0
    },
    ['60%']: {
      opacity: 1
    },
    ['90%']: {
      opacity: 0
    },
    ['100%']: {
      opacity: 0
    }
  },
  animated2: {
    animationName: `$${fadeIn2}`,
    animationIterationCount: 'infinite'
  }
});

export type IconSize = 'xs' | 'sm' | 'md' | 'lg';

interface WidthHeight { width: number | string; height: number | string; }

export interface IBasesIcon {
  size?: IconSize | WidthHeight;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  duration?: number;
  animated?: boolean;
  classes: any;
}

const basesIcon = (props: IBasesIcon) => {
  const sizes = (): WidthHeight => {
    if (props.xs) return { width: 16, height: 16 };
    if (props.sm) return { width: 32, height: 32 };
    if (props.md) return { width: 64, height: 64 };
    if (props.lg) return { width: 128, height: 128 };

    switch (props.size) {
      case 'xs': return { width: 16, height: 16 };
      case 'sm': return { width: 32, height: 32 };
      case 'md': return { width: 64, height: 64 };
      case 'lg': return { width: 128, height: 128 };
      default: return props.size ?? { width: 64, height: 64 };
    }
  };

  const { classes } = props;

  const duration = props.duration || 2100;

  return (
    <svg
      id="loading-spinner"
      viewBox="0 0 48 48"
      {...sizes()}
    >
      <g id="left">
        <path
          className={props.animated ? classes.animated1 : undefined}
          d="M15.214 7.2c-.5 0-1 0-1.5.1-2 .4-3.8 1.5-4.9 3.2-3.1 4.6-3 12.5-2.4 17.3 1.6-.4 3.1-.9 4.6-1.5 5-1.9 8.7-4.4 10.6-7.2 2.4-3.5 1.5-8.3-2-10.7-1.3-.7-2.9-1.2-4.4-1.2zM1.514 35l-.6-2.8c-.1-.7-3.3-15.9 2.9-25 4.3-6.3 12.8-7.9 19.1-3.6 6.3 4.3 7.9 12.8 3.6 19.1-6.2 9.1-21.4 11.7-22.1 11.8l-2.9.5z"
          id="Fill-1"
          fill="#004987"
          style={{ animationDuration: `${duration}ms` }}
        />
      </g>
      <g id="right">
        <path
          className={props.animated ? classes.animated2 : undefined}
          d="M37.214 7.2c-4.3-6.3-12.8-7.9-19.1-3.6-6.3 4.3-7.9 12.8-3.6 19.1.4.6.8 1.1 1.2 1.6 1.8-1 3.4-2.2 4.8-3.7-.4-.4-.7-.8-1-1.3-2.4-3.5-1.5-8.3 2.1-10.7 1.3-.9 2.8-1.3 4.3-1.3.5 0 1 0 1.5.1 2 .4 3.8 1.5 4.9 3.2 1.9 2.8 2.8 7.1 2.8 12.5 0 1.6-.1 3.2-.3 4.8-3.3-.8-6.4-2.1-9.4-3.7-1.4 1.6-3 3-4.8 4.1 6.9 4.6 15.7 6.1 16.1 6.2l2.8.5.6-2.8c.1-.6 3.3-15.9-2.9-25"
          id="Fill-3"
          fill="#00A98F"
          style={{ animationDuration: `${duration}ms` }}
        />
      </g>
    </svg>
  );
};

export default withStyles(styles)(basesIcon);

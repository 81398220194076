export default {
  dateFormatMoment: 'MMM DD, Y',
  dateWeekdayFormatMoment: 'ddd MMM DD, Y',
  dateTimeFormatMoment: 'MMM DD, Y h:mm A',
  dateWeekdayTimeFormatMoment: 'ddd MMM DD, Y h:mm A',
  timeFormatMoment: 'h:mm A',
  dateFormatUnicode: 'yyyy/MM/dd',
  apiDateFormatMoment: 'Y-MM-DD',
  apiTimeFormatMoment: 'HH:mm:ss',
  apiDateTimeFormatMoment: 'Y-MM-DD HH:mm:ss',
  birthdayWithYear: 'MMMM DD, YYYY',
  birthdayWithoutYear: 'MMMM DD',
  scheduleTimeFormat: 'HH:mm:ss',
  momentTimestamp: 'YYYY-MM-DD HH:mm:ss',
  inputs: {
    date: 'YYYY-MM-DD',
    dateTime: 'YYYY-MM-DD HH:mm',
    dateTimeSeconds: 'YYYY-MM-DD HH:mm:ss',
    time: 'HH:mm',
    timeSeconds: 'HH:mm:ss'
  }
};

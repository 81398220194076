import moment from 'moment';
import { Add } from '@mui/icons-material';
import { clone, cloneDeep } from 'lodash';
import settings from '../../../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import { Grid, Button, Typography } from '@mui/material';
import Validation from '../../../../abstracts/Validation';
import FitTestTimeslotEditor from './FitTestTimeslotEditor';
import { IAutoEditorImplementation } from '../../autoInterfaces';
import { IFitTestType, ITimeslotData } from '../../../../stores/database/training/courseSchedule';

const styles = () => createStyles({
  addTimeslot: {
    textAlign: 'right'
  },
  odd: {
    backgroundColor: '#f3f3f3'
  },
  even: {
    backgroundColor: 'white'
  }
});

interface IFitTestScheduleProps extends IAutoEditorImplementation<ITimeslotData[] | undefined> {
  classes: any;
}

const FitTestScheduleEditor = (props: IFitTestScheduleProps) => {
  const { classes, field, editValue, onValueUpdated } = props;

  const getErrorMessage = (newEditValue: ITimeslotData[]) => {
    for (let i = 0; i < newEditValue.length; i++) {
      if (newEditValue[i].errorMessage) {
        return newEditValue[i].errorMessage;
      }

      for (let j = 0; j < newEditValue[i].fitTestTypes.length; j++) {
        if (newEditValue[i].fitTestTypes[j].errorMessage) {
          return newEditValue[i].fitTestTypes[j].errorMessage;
        }
      }
    }

    return undefined;
  };

  const handleAddFitTestTimeslot = () => {
    const newEditValue = cloneDeep(editValue) ?? [];
    newEditValue.push({
      start: '',
      end: '',
      fitTestTypes: [{ scheduleType: '', duration: 0, maxSeats: 0, errorMessage: Validation.messages.scheduleType.required }],
      errorMessage: Validation.messages.startDate.required
    });

    onValueUpdated(newEditValue, field, JSON.stringify(newEditValue), getErrorMessage(newEditValue));
  };

  const handleRemoveFitTestTimeslot = (index: number) => () => {
    const newEditValue = cloneDeep(editValue) ?? [];
    newEditValue.splice(index, 1);

    onValueUpdated(newEditValue, field, JSON.stringify(newEditValue), getErrorMessage(newEditValue));
  };

  const handleCreateDefault = () => {
    const initialEditValue = [
      {
        start: '2000-01-01 07:30:00',
        end: '2000-01-01 11:30:00',
        fitTestTypes: [
          { scheduleType: 'rft-apr', duration: 30, maxSeats: 4 },
          { scheduleType: 'rft-sctt', duration: 60, maxSeats: 2 },
          { scheduleType: 'rft-drgr', duration: 60, maxSeats: 1 },
          { scheduleType: 'rft-n95', duration: 60, maxSeats: 2 }
        ]
      },
      {
        start: '2000-01-01 12:30:00',
        end: '2000-01-01 15:30:00',
        fitTestTypes: [
          { scheduleType: 'rft-apr', duration: 30, maxSeats: 4 },
          { scheduleType: 'rft-sctt', duration: 60, maxSeats: 2 },
          { scheduleType: 'rft-drgr', duration: 60, maxSeats: 1 },
          { scheduleType: 'rft-n95', duration: 60, maxSeats: 2 }
        ]
      }
    ];

    onValueUpdated(initialEditValue, field, JSON.stringify(initialEditValue), getErrorMessage(initialEditValue));
  };

  const validateStartAndEndDates = (index: number, start: string, end: string) => {
    const newEditValue = cloneDeep(editValue) ?? [];
    const newStart = clone(start);
    const newEnd = clone(end);

    let startDate = (start.length === '00:00'.length ? moment(`2000-01-01 ${start}`) : moment(start)).second(0);
    let endDate = (end.length === '00:00'.length ? moment(`2000-01-01 ${end}`) : moment(end)).second(0);

    if (editValue && startDate.isValid() && endDate.isValid()) {
      if (editValue[index]?.start !== newStart) {
        if (startDate.isAfter(endDate)) {
          endDate = startDate.clone().add(30, 'minutes');
          if (endDate.day() !== startDate.day()) {
            endDate = startDate.clone().endOf('day').second(0);
          }
        }
      } else if (editValue[index]?.end !== newEnd) {
        if (endDate.isBefore(startDate)) {
          startDate = endDate.clone().subtract(30, 'minutes');
          if (startDate.day() !== endDate.day()) {
            startDate = endDate.clone().startOf('day').second(0);
          }
        }
      }

      if (editValue[index]?.start !== newStart || editValue[index]?.end !== newEnd) {
        const intersects = newEditValue.filter((timeslotValue, timeslotIndex: number) => {
          if (timeslotIndex !== index) {
            const timeslotStartDate = moment(timeslotValue?.start).second(0);
            const timeslotEndDate = moment(timeslotValue?.end).second(0);

            if (timeslotStartDate.isValid() && timeslotEndDate.isValid() && timeslotStartDate.isBefore(timeslotEndDate)) {
              return startDate.isBetween(timeslotStartDate, timeslotEndDate) || endDate.isBetween(timeslotStartDate, timeslotEndDate) || timeslotStartDate.isBetween(startDate, endDate) || timeslotEndDate.isBetween(startDate, endDate);
            }
          }

          return false;
        });

        if (intersects.length) {
          alert(`This Timeslot overlaps ${intersects.length} other Timeslot${intersects.length === 1 ? '' : 's'}`);
        }
      }
    }

    let errorMessage: string | undefined = undefined;
    if (!startDate.isValid()) {
      errorMessage = Validation.messages.startDate.required;
    } else if (!endDate.isValid()) {
      errorMessage = Validation.messages.endDate.required;
    }

    return {
      newEditValue,
      errorMessage,
      startDate: startDate.isValid() ? startDate.format(settings.momentTimestamp) : '',
      endDate: endDate.isValid() ? endDate.format(settings.momentTimestamp) : ''
    };
  };

  const handleValueUpdated = (index: number) => (start: string, end: string, fitTestTypes: IFitTestType[]) => {
    const { newEditValue, startDate, endDate, errorMessage } = validateStartAndEndDates(index, start, end);

    newEditValue[index] = {
      fitTestTypes,
      errorMessage,
      start: startDate,
      end: endDate
    };

    onValueUpdated(newEditValue, field, JSON.stringify(newEditValue), getErrorMessage(newEditValue));
  };

  return (editValue ?? []).length === 0 || (editValue != null && !Array.isArray(editValue)) ? (
    <Button onClick={handleCreateDefault} color="primary" className={classes.root}>
      Create Default Schedule
    </Button>
  ) : (
    <Grid container spacing={3}>
      {field.title === null ? undefined : (
        <Grid item xs={6}>
          <Typography>{field.title}</Typography>
        </Grid>
      )}
      <Grid item xs={field.description === null ? 12 : 6} className={classes.addTimeslot}>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} onClick={handleAddFitTestTimeslot}>
          Add Timeslot
        </Button>
      </Grid>
      {field.description === null ? undefined : (
        <Grid item xs={12}>
          <Typography>{field.description}</Typography>
        </Grid>
      )}
      {(editValue ?? []).map((fitTestTimeSlot: ITimeslotData, index) => (
        <FitTestTimeslotEditor key={index} index={index} {...fitTestTimeSlot} parityClass={index % 2 === 0 ? 'even' : 'odd'} onValueUpdated={handleValueUpdated(index)} onRemoveTimeslot={handleRemoveFitTestTimeslot(index)} />
      ))}
    </Grid>
  );
};

export default withStyles(styles)(FitTestScheduleEditor);

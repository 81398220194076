import { Dictionary } from 'lodash';

export const enum UploadActionTypes {
  __ENUM_ROOT = '@@Upload',
  ADD_ROW = '@@Upload/ADD_ROW',
  DELETE_ROW = '@@Upload/DELETE_ROW',
  CLEAR = '@@Upload/CLEAR'
}

export interface IUploadRow {
  trainingDate: string;
  userCompanyId: number;
  companyName: string;
  courseId: number;
  courseCode: string;
  courseTitle: string;
  personId: number;
  workerName: string;
}

export interface IUploadState {
  rowData: Dictionary<IUploadRow>;
  lastUpdated: number;
  version: number;
}

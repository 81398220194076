import React from 'react';
import { GridSize } from '@mui/material/Grid';
import { Dictionary, difference } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

/**
 * equivalent to the C# 'nameof', gives the name of the provided property as a string to be used in keys
 * @param name object whose name will be returned
 */
export const nameof = <T>(name: keyof T) => name;

export type TypePropertyExtension<T, R> = { [K in keyof T]: R };
/**
 * Provides type safe check for if statements when switching between interface types
 * @param obj object to be checked; returned as type for Typescript purposes
 * @param requiredField name of the field that correctly identifies the provided type
 */
export const isInterface = <T>(obj: T | any, requiredField: keyof T): obj is T => obj != null && (<T>obj)[requiredField] != null;
export const isInterfaceArray = <T>(obj: T[] | any, requiredField: keyof T): obj is T[] => (<T>obj)[0]?.[requiredField] != null;
export const isArray = (value: any): value is any[] => Array.isArray(value);
export const isNumber = (value: any): value is number => typeof value === 'number';
export const isStringArray = (value: any): value is string[] => Array.isArray(value) && (value.length === 0 || typeof value[0] === 'string');
export const isNumberArray = (value: any): value is number[] => Array.isArray(value) && (value.length === 0 || typeof value[0] === 'number');

export const arrayRemoveAt = <T>(array: T[], idx: number): T[] => {
  if (idx < 0) return array;
  const res: T[] = [];
  if (idx === 0) return res.concat(array.slice(1));
  if (idx === array.length - 1) return res.concat(array.slice(0, - 1));
  return res.concat(
    array.slice(0, idx),
    array.slice(idx + 1)
  );
};

/**
 * Internally uses lodash difference to just generate 2 arrays
 * @param array1
 * @param array2
 */
export const arrayOuterDifferences = <T>(array1: T[], array2: T[]): [T[], T[]] => {
  const res1 = difference(array1, array2);
  const res2 = difference(array2, array1);
  return [res1, res2];
};

// function isStringArray(value: any): value is string[] {
//   if (value instanceof Array) {
//     value.forEach(function (item) { // maybe only check first value?
//       if (typeof item !== 'string') {
//         return false
//       }
//     })
//     return true
//   }
//   return false
// }

/**
 * returns true if the passed variable is a function
 * @param obj variable to be tested
 */
export const isFunction = (obj: any) => !!(obj && obj.constructor && obj.call && obj.apply);

/**
 * return a copy of an array with either the value toggled, the value moved to the forced index, or the array unchanged
 * @param array array to be modifed
 * @param value value to be added or removed. If a value occurs more than once, only the first value will be removed.
 * @param force number that describes new index, or true to ensure value exists, or false to ensure value does not exist. If
 * force is a boolean that matches the current status, existing array is returned unchanged
 */
export const updateArray = <T = string | number>(array: T[], value: T | T[], force?: number | boolean): T[] => {
  if (Array.isArray(value)) {
    let res = array;
    value.forEach(v => res = updateArray(res, v, force));
    return res;
  }
  const selectedIndex = array.indexOf(value);
  const res: T[] = [];
  if (force === undefined) {
    if (selectedIndex === -1) return res.concat(array, value);
    return arrayRemoveAt(array, selectedIndex);
  }
  if (force === true) {
    return selectedIndex === -1
      ? updateArray(array, value)
      : array;
  }
  if (force === false) {
    return selectedIndex >= 0
      ? updateArray(array, value)
      : array;
  }
  if (selectedIndex === force) return res.concat(array);
  if (selectedIndex === -1) {
    if (force === 0) return res.concat(value, array);
    if (force >= array.length) return res.concat(array, value);
    return res.concat(
      array.slice(0, force),
      value,
      array.slice(force)
    );
  }

  if (selectedIndex < force) {
    return res.concat(
      array.slice(0, selectedIndex),
      array.slice(selectedIndex + 1, force),
      value,
      array.slice(force)
    );
  }

  return res.concat(
    array.slice(0, force),
    value,
    array.slice(force, selectedIndex),
    array.slice(selectedIndex + 1)
  );
};

/**
 * return a new object with each key/value updated with the provided function, with returned null/undefined excluded
 * @param object object whose keys will be iterated over
 * @param map function that will update the value for each key. if null or undefined are returned, that item will be excluded
 */
export const objectMap = <T, R>(
  object: Dictionary<T>,
  map: (key: string, value: T, object: Dictionary<T>, runningTotal: number) => R | null | undefined
): Dictionary<R> => {
  const result: Dictionary<R> = {};
  let runningTotal = 0;
  Object.keys(object).forEach((key: string) => {
    const r = map(key, object[key], object, runningTotal);
    if (r !== undefined && r !== null) {
      result[key] = r;
      runningTotal++;
    }
  });
  return result;
};
/**
 * return a new object with each key/value updated with the provided function, with returned null/undefined excluded
 * @param object object whose keys will be iterated over
 * @param map function that will update the value for each key. if null or undefined are returned, that item will be excluded
 */
export const objectRemap = <T, R>(
  object: Dictionary<T>,
  map: (key: string, value: T, object: Dictionary<T>) => [string | number, R | null | undefined]
): Dictionary<R> => {
  const result: Dictionary<R> = {};
  Object.keys(object).forEach((key: string) => {
    const [k, r] = map(key, object[key], object);
    if (r != null) {
      result[k] = r;
    }
  });
  return result;
};

/**
 * return a new object with each key/value updated with the provided function.
 * @param object object whose keys will be iterated over
 * @param map function that will update the value for each key. if null or undefined are returned, that item will be excluded
 */
export const objectMapIncludeNull = <T, R>(
  object: Dictionary<T>,
  map: (key: string, value: T, object: Dictionary<T>) => R | null | undefined
): Dictionary<R | null | undefined> => {
  const result: Dictionary<R | null | undefined> = {};
  Object.keys(object).forEach((key: string) => {
    result[key] = map(key, object[key], object);
  });
  return result;
};

/**
 * return a new array with each key/value updated with the provided function
 * @param object object whose keys will be iterated over
 * @param map function that will update the value for each key
 */
export const objectMapArray = <T, R>(
  object: Dictionary<T>,
  map: (key: string, value: T, object: Dictionary<T>, totalIndex: number) => R | null | undefined
): R[] => {
  const result: R[] = [];
  Object.keys(object).forEach((key: string) => {
    const r = map(key, object[key], object, result.length);
    if (r != null) result.push(r);
  });
  return result;
};

/**
 * convert an array to an object with a mapping procedure
 * @param array array whose items will be iterated through
 * @param map function that will return undefined if the item is not to be included in the new object,
 * or an array with the first index being the key and the second index being the value to be attached to the new object
 */
export const arrayMapObject = <T, R>(
  array: T[],
  map: (index: number, value: T, array: T[]) => [string | number, R] | undefined
): Dictionary<R> => {
  const result: Dictionary<R> = {};
  array.forEach((v, i) => {
    const m = map(i, v, array);
    if (m != null) {
      result[m[0]] = m[1];
    }
  });
  return result;
};

/**
 * return a new array with each key/value updated with the provided function, with returned null/undefined excluded
 * @param object object whose keys will be iterated over
 * @param map function that will update the value for each key
 */
export const objectMapArrayIncludeNull = <T, R>(
  object: Dictionary<T>,
  map: (key: string, value: T, object: Dictionary<T>) => R | null | undefined
): (R | null | undefined)[] => {
  const result: (R | null | undefined)[] = [];
  Object.keys(object).forEach((key: string) => {
    result.push(map(key, object[key], object));
  });
  return result;
};

export const objectArrayMap = <T, R>(
  array: T[],
  map: (index: number, value: T, array: T[]) => { key: string, value: R | null | undefined },
  ignoreUndefined: boolean = true
): Dictionary<(R | null | undefined)> => {
  const result: Dictionary<(R | null | undefined)> = {};

  for (let idx = 0; idx < array.length; idx++) {
    const { key, value } = map(idx, array[idx], array);
    if (!ignoreUndefined || value != null) {
      result[key] = value;
    }
  }

  return result;
};

/**
 * Create a new array with objects grouped and results remapped
 * @param array array to be mapped
 * @param select select the unique key that defines the uniqueness
 * @param mapFirst function that creates the first instance of the result array's object for each group key
 * @param mapDuplicate function that updates the instance of the result array's object if it's not the first instance of that key
 */
export const arrayGroupByMap = <T, R>(
  array: T[],
  select: (value: T) => string,
  mapFirst: (value: T) => R,
  mapDuplicate: (group: R, value: T) => void
): R[] => {
  const result: { [select: string]: R } = {};

  array.forEach((t) => {
    const key = select(t);
    if (Object.prototype.hasOwnProperty.call(result, key)) mapDuplicate(result[key], t);
    else result[key] = mapFirst(t);
  });

  return objectMapArray(result, (k, v) => v);
};

/**
 * Iterate through every child produced from Object.keys, unless false is returned from each
 * @param object Object whose keys will be iterated through; if order is required, use objectSortEach
 * @param each function that will be called on every item. returning false will end the each immediately and return false
 * @returns {boolean} returns true if all of the keys are iterated through. If not, returns false
 */
export const objectEach = <T>(object: Dictionary<T>, each: (key: string, value: T, object: Dictionary<T>) => boolean | void): boolean => {
  for (const key of Object.keys(object)) {
    if (each(key, object[key], object) === false) return false;
  }
  return true;
};

/**
 * Create a new object with the newItem added to the array of the key, or a new array containing just the newItem added for the key if not on the object
 * @param object currently existing dictionary of arrays
 * @param key key of the object to add the new item to
 * @param newItem new item to be added
 * @returns updated object
 */
export const objectAddOrCreateArrayChild = <T>(object: Dictionary<T[]>, key: string, newItem: T): Dictionary<T[]> => {
  const res = { ...object };
  if (Array.isArray(res[key])) {
    res[key].push(newItem);
  } else {
    res[key] = [newItem];
  }
  return res;
};

/**
 * Create a new array with objects grouped and results remapped
 * @param array array to be mapped
 * @param select select the unique key that defines the uniqueness
 * @param mapFirst function that creates the first instance of the result array's object for each group key
 * @param mapDuplicate function that updates the instance of the result array's object if it's not the first instance of that key
 */
export const objectGroupByMap = <T, R>(
  obj: Dictionary<T>,
  select: (key: string, value: T) => string | undefined,
  mapFirst: (key: string, value: T) => R,
  mapDuplicate: (group: R, key: string, value: T) => void
): R[] => {
  const result: { [select: string]: R } = {};
  objectEach(obj, (k, v) => {
    const key = select(k, v);
    if (key == null) return;
    if (Object.prototype.hasOwnProperty.call(result, key)) mapDuplicate(result[key], k, v);
    else result[key] = mapFirst(k, v);
  });

  return objectMapArray(result, (k, v) => v);
};

/**
 * Create a new array with objects grouped and results remapped
 * @param array array to be mapped
 * @param select select the unique key that defines the uniqueness
 * @param mapFirst function that creates the first instance of the result array's object for each group key
 * @param mapDuplicate function that updates the instance of the result array's object if it's not the first instance of that key
 */
export const objectObjectGroupByMap = <S, T, R>(
  obj: Dictionary<S>,
  objSelect: (key: string, value: S) => { key: string; value: T }[],
  select: (key1: string, key2: string, value: T) => string | undefined,
  mapFirst: (key1: string, key2: string, parentValue: S, value: T) => R,
  mapDuplicate: (group: R, key1: string, key2: string, parentValue: S, value: T) => void
): R[] => {
  const result: { [select: string]: R } = {};
  objectEach(obj, (k, v) => {
    objSelect(k, v).forEach(({ key, value }) => {
      const selectKey = select(k, key, value);
      if (selectKey == null) return;
      if (Object.prototype.hasOwnProperty.call(result, key)) mapDuplicate(result[selectKey], k, key, v, value);
      else result[key] = mapFirst(k, key, v, value);
    });
  });

  return objectMapArray(result, (k, v) => v);
};

/**
 * Create a new object from an array grouped and results remapped
 * @param array array to be mapped
 * @param select select the unique key that defines the uniqueness
 * @param mapFirst function that creates the first instance of the result array's object for each group key
 * @param mapDuplicate function that updates the instance of the result array's object if it's not the first instance of that key
 */
export const arrayGroupByRemap = <T, R>(
  array: T[],
  select: (value: T) => string,
  mapFirst: (value: T) => R,
  mapDuplicate: (group: R, value: T) => void
): Dictionary<R> => {
  const result: { [select: string]: R } = {};

  array.forEach((t) => {
    const key = select(t);
    if (Object.prototype.hasOwnProperty.call(result, key)) mapDuplicate(result[key], t);
    else result[key] = mapFirst(t);
  });

  return result;
};

/**
 * Create a new 2-level object from an array grouped and results remapped
 * @param array array to be mapped
 * @param selectFirst select the unique key that defines the uniqueness at the first level
 * @param selectSecond select the unique key that defines the uniqueness at the second level
 * @param mapFirst function that creates the first instance of the result array's object for each group key
 * @param mapDuplicate function that updates the instance of the result array's object if it's not the first instance of that key
 */
export const arrayGroupByRemap2 = <T, R>(
  array: T[],
  selectFirst: (value: T) => string | number,
  selectSecond: (value: T) => string | number,
  mapFirst: (value: T) => R,
  mapDuplicate: (group: R, value: T) => void
): Dictionary<Dictionary<R>> => {
  const result: { [select1: string]: { [select2: string]: R } } = {};

  array.forEach((t) => {
    const key1 = selectFirst(t);
    const key2 = selectSecond(t);
    if (!Object.prototype.hasOwnProperty.call(result, key1)) result[key1] = {};
    if (Object.prototype.hasOwnProperty.call(result[key1], key2)) mapDuplicate(result[key1][key2], t);
    else result[key1][key2] = mapFirst(t);
  });

  return result;
};

/**
 * Create a new 3-level object from an array grouped and results remapped
 * @param array array to be mapped
 * @param selectFirst select the unique key that defines the uniqueness at the first level
 * @param selectSecond select the unique key that defines the uniqueness at the second level
 * @param selectThird select the unique key that defines the uniqueness at the second level
 * @param mapFirst function that creates the first instance of the result array's object for each group key
 * @param mapDuplicate function that updates the instance of the result array's object if it's not the first instance of that key
 */
export const arrayGroupByRemap3 = <T, R>(
  array: T[],
  selectFirst: (value: T) => string | number,
  selectSecond: (value: T) => string | number,
  selectThird: (value: T) => string | number,
  mapFirst: (value: T) => R,
  mapDuplicate: (group: R, value: T) => void
): Dictionary<Dictionary<Dictionary<R>>> => {
  const result: { [select1: string]: { [select2: string]: { [select3: string]: R } } } = {};

  array.forEach((t) => {
    const key1 = selectFirst(t);
    const key2 = selectSecond(t);
    const key3 = selectThird(t);
    if (!Object.prototype.hasOwnProperty.call(result, key1)) result[key1] = {};
    if (!Object.prototype.hasOwnProperty.call(result[key1], key2)) result[key1][key2] = {};
    if (Object.prototype.hasOwnProperty.call(result[key1][key2], key3)) mapDuplicate(result[key1][key2][key3], t);
    else result[key1][key2][key3] = mapFirst(t);
  });

  return result;
};

export interface IRecursiveMapAction<T, R> {
  /** Get the group by key for an item
   * @param value the value to generate the key from
   * @param parentKeys array of parent keys above this nested group. Last item will be immediate parent
   */onSelect: (value: T, parentKeys: (string | number)[]) => string | number | undefined;
  /** Create the initial group item; this will setup the type safety for the rest of this usage
   * @param value the value of the first item in this group
   * @param key the key of the current item as returned by onSelect
   * @param parentKeys array of parent keys above this nested group. Last item will be immediate parent
   * @param mapState object that will be passed to every mapping within a group. Allows limited
   * @returns initial instance of the group result, and initial instance of mapState passed to each instance of map and the final afterAllMap
   */onFirstMap: (value: T, key: string | number, parentKeys: (string | number)[]) => [R, Dictionary<any>],
  /** action to run when adding an item to a group
   * @param group the group object representing this key
   * @param value the value of the item being mapped to this group
   * @param parentKeys array of parent keys above this nested group. Last item will be immediate parent
   * @param mapState state object always passed with this group for state that is not to be returned
   */onMap?: (group: R, value: T, key: string | number, parentKeys: (string | number)[], mapState: Dictionary<any>) => void;
  /** inject behaviour when completed grouping items before moving into the next map action in the array. If
   *  omitted, will always run recursive map action found next in the array
   * @param group the group object representing this key
   * @param values all the values included in this group
   * @param parentKeys array of parent keys above this nested group. Last item will be immediate parent
   * @param mapState state object always passed with this group for state that is not to be returned
   * @returns object stating whether this action will be called again, and whether to add another action before continuing with
   * items found in the array. A use case is to have an extra breakdown/group in a specific scenario
   */recursiveCall?: (group: R, values: T[], parentKeys: (string | number)[], mapState: Dictionary<any>) => { reuse: boolean, inject?: IRecursiveMapAction<T, R>[] };
  /** Define how to finalize the group. Can be either a string, which is the object key of where child records are attached to the group, or a function call
   *  to manually configure that rollup.
   * @param group the group object representing this key
   * @param values all the values included in this group
   * @param children the child items created from the next map action, if available
   * @param parentKeys array of parent keys above this nested group. Last item will be immediate parent
   * @param mapState state object always passed with this group for state that is not to be returned
   */afterAllMap: string | ((group: R, values: T[], children: R[] | undefined, parentKeys: (string | number)[], mapState: Dictionary<any>) => void);
}

/**
 * Return a list of items grouped with a recursive definition
 * @param values Items to recursively group
 * @param mapActions definition of each level of group
 * @param parentKeys keys of parent groups; passed along with
 * @returns list of result type
 */
export const arrayGroupByRecursiveMap = <T, R>(values: T[], mapActions: IRecursiveMapAction<T, R>[], parentKeys: (string | number)[]): R[] => {
  if (mapActions.length === 0) throw new Error('No mapping actions to complete');
  if (parentKeys == null) parentKeys = [];
  const results: Dictionary<{ result: R, values: T[], state: {} }> = {};

  values.forEach(v => {
    const key = mapActions[0].onSelect(v, parentKeys);
    if (key == null) return;
    if (!Object.prototype.hasOwnProperty.call(results, key)) {
      const [result, state] = mapActions[0].onFirstMap(v, key, parentKeys);
      results[key] = { state, result, values: [v] };
    } else {
      results[key].values.push(v);
      if (mapActions[0].onMap) mapActions[0].onMap(results[key].result, v, key, parentKeys, results[key].state);
    }
  });

  objectEach(results, (key, res) => {
    let children: R[] | undefined = undefined;
    const nextMapActions = [...mapActions];
    const recurse = mapActions[0].recursiveCall
      ? mapActions[0].recursiveCall(res.result, res.values, [...parentKeys, key], res.state)
      : mapActions.length > 1 ? { reuse: false } : undefined;


    if (recurse?.inject != null) {
      children = arrayGroupByRecursiveMap(res.values, [...recurse.inject, ...mapActions.slice(recurse.reuse ? undefined : 1)], [...parentKeys, key]);
    } else if (recurse != null) {
      children = arrayGroupByRecursiveMap(res.values, mapActions.slice(recurse.reuse ? undefined : 1), [...parentKeys, key]);
    }

    if (nextMapActions.length > 0) {
      children = arrayGroupByRecursiveMap(res.values, nextMapActions, [...parentKeys, key]);
    }

    if (typeof mapActions[0].afterAllMap === 'string') {
      if (children != null) res.result[mapActions[0].afterAllMap] = children;
    } else {
      mapActions[0].afterAllMap(res.result, res.values, children, [...parentKeys, key], res.state);
    }
  });

  return objectMapArray(results, (k, { result }) => result);
};

export const arrayGroupByNest = <T>(values: T[], id: keyof T, parent: keyof T, children: keyof T, parentId?: any): T[] =>
  values.filter(v => v[parent] === parentId).map(v => ({ ...v, [children]: arrayGroupByNest(values, id, parent, children, v[id]) }));


/**
 * create a nested group-by; for every item in the array, run through each remap and add the results from that key/map as a child of the parent remap.
 * @param array source array
 * @param remap array of select/map functions. Select returns the key at that level, or null to not add at that remap. map includes the existing object, or undefined
 * if the object has not been created yet. must return the full object.
 */
// export const arrayGroupByRecursiveMap = <T>(
//   array: T[],
//   maps: <S>{ select: (value: T) => string | number | undefined, mapFirst: (value: T) => S, mapDuplicates:  }[]
// ): Dictionary<any> => {
//   let result = {};
//   array.forEach((t) => {
//     result = recursiveMultiRemap(result, t, remap);
//   });
//   return result;
// };

/**
 * Create a new object with objects grouped and results remapped
 * @param array array to be mapped
 * @param select select the unique key that defines the uniqueness; will be the key in the result dictionary
 * @param mapFirst function that creates the first instance of the result array's object for each group key
 * @param mapDuplicate function that updates the instance of the result array's object if it's not the first instance of that key
 */
export const objectGroupByRemap = <T, R>(
  obj: Dictionary<T>,
  select: (key: string, value: T) => string,
  mapFirst: (key: string, value: T) => R,
  mapDuplicate: (group: R, key: string, value: T) => void
): Dictionary<R> => {
  const result: { [select: string]: R } = {};
  objectEach(obj, (k, v) => {
    const key = select(k, v);
    if (Object.prototype.hasOwnProperty.call(result, key)) mapDuplicate(result[key], k, v);
    else result[key] = mapFirst(k, v);
  });
  return result;
};

/**
 * While a condition is true, loop through a process to map a result
 * @param objStart The initial value to test the whileExpression
 * @param whileExpression what is tested to verify if continuing to run map again
 * @param map function called every loop that returns an array of 2 items; first item being next parameter for whileExpression, second
 * item being what's added to the result
 * @param minOnce if true, map is called before the first whileExpression is evaluated
 * @returns array of items generated by map
 */
export const mapWhile = <T, R>(
  objStart: T,
  whileExpression: (value: T) => boolean,
  map: (value: T) => [T, R],
  minOnce?: boolean
): R[] => {
  const res: R[] = [];
  if (minOnce) map(objStart);
  let loop = objStart;
  while (whileExpression(loop)) {
    const m = map(loop);
    res.push(m[1]);
    loop = m[0];
  }
  return res;
};

/**
 * Iterate through every child produced from Object.keys until the first true is returned. Please note - order is not guaranteed with objects
 * @param object Object whose keys will be iterated through; if order is required, use objectSortEach
 * @param each function that will be called on every item. returning true will return the current object
 * @returns {T} returns item of dictionary
 */
export const objectFind = <T>(object: Dictionary<T>, each: (key: string, value: T, object: Dictionary<T>) => boolean): T | undefined => {
  for (const key of Object.keys(object)) {
    if (each(key, object[key], object)) return object[key];
  }
  return undefined;
};

/**
 * Pass in a string, and all characters that aren't xml-safe will be replaced with the xml coded character sets
 * @param {string} s - string to have xml-special characters replaced with xml-safe characters
 * @returns {string} - converted string
 */
export const encodeXml = (s: any): string => {
  const xmlSpecialMap = { '&': '&amp;', '"': '&quot;', '<': '&lt;', '>': '&gt;', '\n': '&#xA;' };
  return s.toString().replace(/\\r\\n/, '\n').replace(/([&"<>\n])/g, (str: string, item: string) => xmlSpecialMap[item]);
};

/**
 * Pass in a string that has xml-safe characters, and replace with the standard character set
 * @param {string} s - string to have xml-safe characters replaced with standard character set
 * @returns {string} - converted string
 */
export const decodeXml = (s: string): string => {
  const xmlSpecialMap = { '&amp;': '&', '&quot;': '"', '&lt;': '<', '&gt;': '>', '&#xA;': '\n' };
  return s.toString().replace(/(&amp;|&quot;|&lt;|&gt;|&#xA;)/g, (str, item) => xmlSpecialMap[item]);
};

export const parseIntList = (s?: string): number[] | undefined => {
  if (s == null) return undefined;
  const ids: (number | string)[] = s.split(',');
  for (let i = 0; i < ids.length; i++) {
    if (isNaN(parseInt(ids[i] as string, 10))) {
      return undefined;
    }
    ids[i] = parseInt(ids[i] as string, 10);
  }
  return ids as number[];
};

/**
 * Simple convert JSON object to XML; used mainly for building object in js, then passing the object in XML to SQL. This conversion
 * does not do properties in the xml - simple conversion that tries to meet all xml standards
 * @param {string} name - the name of the immediate node to be processed
 * @param {(Object|Array|string)} val - item to be parsed into xml
 * @param {boolean} [options.removeUndefined=false] - if the underlying value is undefined, remove if true, or return self-closed tag if false
 * @param {boolean} [options.removeEmptyStrings=false] - if the underlying value is a zero-length string, remove if true
 * @param {string[]} [options.arrayItemsInParent=[]] - relative path for arrays that should not be grouped under a common name, but rather should be listed
 * in the same parent as other potential references. Be very careful when using this - available because of previous bad design decisions in the receiving
 * node for xml, should be corrected at some point.
 * @param {string} [path=] - the current path of the recursive item. Used for array options, should be undefined when first called
 * @returns {string} - string of xml data representing the original object
*/
export const jsonToXml = (
  name: string,
  val: Dictionary<any> | any[] | string | boolean | number | undefined,
  removeUndefined: boolean = false,
  removeEmptyStrings: boolean = false,
  arrayItemsInParent: string[] = [],
  path: string = ''
): string => {
  if (removeEmptyStrings && (val === '' || val == null)) return '';
  // xml tag names cannot start with "XML", and must start with a letter or underscore. If starts with "xml" or not a letter, prepend with underscore
  let nodeName = name;
  if (/^[xX][mM][lL].* /.test(name) || !/^[a-zA-Z_].* /.test(name)) nodeName = `_${name}`;
  // only letters, digits, hyphens, underscores and periods are allowed in a tag name; remove all characters that do not match that template
  nodeName = nodeName.replace(/([^0-9a-zA-Z-_.])/g, '');

  if (val == null) {
    if (removeUndefined) return '';
    return `<${nodeName} />`;
  }

  if (Array.isArray(val)) {
    // If array, create a parent node of the name with an "s" on the end, then a child node for each item in the array. If the name already has an "s" on the end, remove it to
    // force (in most cases) to have a clear plural and singular parent/children. If the option is included to have array items in immediate
    // parent, then the array grouping doesn't occur.
    if (nodeName.slice(-1) === 's') {
      nodeName = nodeName.slice(0, -1);
    }
    const isGrouped = arrayItemsInParent.indexOf(`${path}.${name}`) < 0;
    return (isGrouped ? `<${nodeName}s>` : '')
      + val.map(v =>
        jsonToXml(nodeName, v, removeUndefined, removeEmptyStrings, arrayItemsInParent, !isGrouped ? path : path === '' ? nodeName : `${path}.${nodeName}`)
      ).join('')
      + (isGrouped ? `</${nodeName}s>` : '');
  }
  if (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') {
    return `<${nodeName}>${encodeXml(val)}</${name}>`;
  }

  // tslint:disable-next-line: prefer-template
  return `<${nodeName}>`
    + objectMap(val, (key, value) => jsonToXml(key, value, removeUndefined, removeEmptyStrings, arrayItemsInParent, path === '' ? nodeName : `${path}.${nodeName}`))
    + `</${nodeName}>`;

};

/**
 * Return the value of an item using an array of selectors, to be able to auto-select into child items. Design is to return string
 * values, so when an item doesn't contain a property with the provided name, a zero-length string is returned instead of undefined.
 * @param {string|string[]} select - name or ordered array of names for selectors to return desired value.
 * @param {object} item - item that select will be applied against
 * @returns {string} - the value from the selector, or an empty string if undefined is ever encountered
*/
export const recursiveSelector = (select: string | string[], item: any): any => {
  if (typeof select === 'string') return recursiveSelector([select], item);
  if (!Object.prototype.hasOwnProperty.call(item, select[0])) return '';
  if (select.length === 1) return item[select[0]];

  return recursiveSelector(select.slice(1), item[select[0]]);
};

/**
 * Compare (sort) items a/b through selectors; e.g. [ 'person.address.city', [ 'have.','periods.','in.name' ] ] would only compare the 2nd depth
 * if the first depth returned equal. Multi-step sort, essentially.
 * @param {Object} a - first item to be compared
 * @param {Object} b - secibd item to be compared
 * @param {(Array<string>|Array<string[]>)} select - array that contains either strings or string arrays that denote the accessor within the object
 * @returns {number} - number noting the order, where < 0 is a before b, > 0 is a after b, and 0 is they are equal
 */
export const recursiveCompare = (a: any, b: any, select: string[] | string[][], order?: boolean[]): number => {
  const reverse = order?.[0] ?? false ? -1 : 1;
  const aVal = recursiveSelector(select[0], a);
  const bVal = recursiveSelector(select[0], b);

  const res = typeof aVal === 'boolean' ? (aVal ? 1 : 0) - (bVal ? 1 : 0)
    : typeof aVal === 'number' ? aVal - bVal
      : aVal.toString().localeCompare(bVal.toString());
  // If not equal to 0, then sort can be made on this single value
  if (res !== 0) return res * reverse;
  // If select length is 0, no child elements to break equality - return 0, as in a/b are equal
  if (select.length === 1) return 0;
  // If not equal and still select items to compare, return result of that comparison
  return recursiveCompare(a, b, select.slice(1), order == null || order.length === 0 ? [false] : order.slice(1));
};
/**
 * Comparing & sorting 2 key-value pairs
 * @function CustomSort
 * @param {string} aKey - key of the first item to be compared
 * @param {*} aItem - first item to be compared
 * @param {string} bKey - key of the second item to be compared
 * @param {*} bItem - second item to be compared
 * @returns {number} - number representing sort, being <0, 0 or >0
 */
/**
 * Parameter order for map is consistent with jQuery map callback functions
 * @function CustomMap
 * @param {string} id - the key portion of key-value pairs
 * @param {Object} item - the value portion of key-value pairs
 * @returns {Object}
 */

export interface IKeyValueFlat<T> { key: string; item: T; }
// export interface IKeyValueFlat { key: string; item: Dictionary<any>; }
export type CustomSort<T> = (a: IKeyValueFlat<T>, b: IKeyValueFlat<T>) => number;
export type CustomIter<T, R = any> = (value: IKeyValueFlat<T>, index: number, runningTotal: number) => R | undefined;

/**
 * Takes an object, applies the sort, and returns with the provided map function
 * @param {Object} object - the object to be sorted/mapped over
 * @param {(boolean|CustomSort|string[]|Array<string[]>|string)} sort - define the sorting to be done; false sorts by the accessor of the object, function defines the sort function, array
 * defines multiple strings to be sorted, string defines the accessor of how it is sorted. If the string array contains a string array, it will be used for accessing
 * child items (e.g. ['item','data','value'] would call object.item.data.value for comparison). This can only be used with string[]. If only sorting by a single field with multiple accessors,
 * it would still need to be a 1-length array for the sort parameter.
 * @param {CustomIter} map - define the mapping to be done
 * @returns {Object[]} - array of objects defined in Map ordered by Sort
 */
export const objectSortMap = <T, R>(object: Dictionary<T>, sort: undefined | CustomSort<T> | string | string[] | string[][], map: CustomIter<T, R>): R[] => {
  if (typeof sort === 'string') return objectSortMap(object, [sort], map);
  // If sort undefined, sort by the key/id of the object. Object.keys returns keys in order of declaration, not alphabetically, which this solves
  const result: R[] = [];
  if (sort === undefined) {
    objectMapArray(object, (key, item): IKeyValueFlat<T> => { return { key, item }; })
      .sort((a: IKeyValueFlat<T>, b: IKeyValueFlat<T>) => a.key.localeCompare(b.key))
      .forEach((o, i) => {
        const r = map(o, i, result.length);
        if (r != null) result.push(r);
      });
  } else if (Array.isArray(sort)) {
    objectMapArray(object, (key, item): IKeyValueFlat<T> => { return { key, item }; })
      .sort((a: IKeyValueFlat<T>, b: IKeyValueFlat<T>) => recursiveCompare(a.item, b.item, sort))
      .forEach((o, i) => {
        const r = map(o, i, result.length);
        if (r != null) result.push(r);
      });
  } else {
    objectMapArray(object, (key, item): IKeyValueFlat<T> => { return { key, item }; })
      .sort((a: IKeyValueFlat<T>, b: IKeyValueFlat<T>) => (sort as CustomSort<T>)(a, b))
      .forEach((o, i) => {
        const r = map(o, i, result.length);
        if (r != null) result.push(r);
      });
  }
  return result;
  // throw `DataroweHelpers.ObjectSortMap - unsupported sort type ${typeof sort}; data: ${JSON.stringify(sort)}`;
};

export const arrayMapKeySort = <T, R>(values: T[], map: (value: T, index: number) => [string, R] | undefined): R[] => {
  const items = {};
  values.forEach((v, i) => {
    const item = map(v, i);
    if (item != null) items[item[0]] = item[1];
  });
  return Object.keys(items).sort().map(k => items[k]);
};
export const objectMapKeySort = <T, R>(values: Dictionary<T>, map: CustomIter<T, [string, R]>): R[] => {
  const items = {};
  Object.keys(values).forEach((v, i) => {
    const item = map({ key: v, item: values[v] }, i, Object.keys(items).length);
    if (item != null) items[item[0]] = item[1];
  });
  return Object.keys(items).sort().map(k => items[k]);
};

export const arraySortMap = <T, R>(values: T[], sort: ((a: T, b: T) => number) | keyof T | (keyof T)[] | string[][], map: (value: T, index: number) => R): R[] => {
  if (values.length === 0) return [];
  if (typeof sort === 'string' || typeof sort === 'number') return arraySortMap(values, [sort], map);
  const result: R[] = [];
  if (Array.isArray(sort)) {
    values
      .sort((a: T, b: T) => recursiveCompare(a, b, sort as string[]))
      .forEach((o, i) => {
        const r = map(o, i);
        if (r != null) result.push(r);
      });
  } else {
    values
      .sort((a: T, b: T) => (sort as (a: T, b: T) => number)(a, b))
      .forEach((o, i) => {
        const r = map(o, i);
        if (r != null) result.push(r);
      });
  }
  return result;
};

export const arraySortEach = <T, R>(values: T[], sort: ((a: T, b: T) => number) | keyof T | (keyof T)[] | string[][], each: (value: T, index: number) => R): void => {
  if (values.length === 0) return;
  if (typeof sort === 'string' || typeof sort === 'number') {
    arraySortEach(values, [sort], each);
  } else if (Array.isArray(sort)) {
    values
      .sort((a: T, b: T) => recursiveCompare(a, b, sort as string[]))
      .forEach((o, i) => each(o, i));
  } else {
    values
      .sort((a: T, b: T) => (sort as (a: T, b: T) => number)(a, b))
      .forEach((o, i) => each(o, i));
  }
};

export const arrayRefSortMap = <T, R>(values: (string | number)[], dictionary: Dictionary<T>, sort: ((a: T, b: T) => number) | keyof T | (keyof T)[] | string[][], map: (value: T, index: number) => R): R[] =>
  arraySortMap(values.map(key => dictionary[key]), sort, map);

/**
 * Takes an object, and remaps all the keys with the map function to a return object
 * @param object - the object to be remapped
 * @param map - define the mapping to be done
 * @returns - object of parameters that have been remapped
 */
export const objectObjectMap = <T>(object: any, map: CustomIter<T>, ignoreUndefined: boolean = true) => {
  const result = {};
  let runningTotal = 0;
  Object.keys(object).forEach((key, i) => {
    const r = map({ key, item: object[key] }, i, runningTotal);
    // tslint:disable-next-line: triple-equals
    if (!ignoreUndefined || r == undefined) {
      result[key] = r;
      runningTotal++;
    }
  });
  return result;
};

/**
 * Parameter order for each is consistent with jQuery each callback functions
 * @function CustomEach
 * @param {string} id - the key portion of key-value pairs
 * @param {Object} item - the value portion of key-value pairs
 * @return undefined
 */

/**
 * Takes an object, applies the sort, and calls the each function over the sorted array
 * @param {Object} object  - the object to be sorted & have each applied
 * @param {(boolean|CustomSort|Array<string>|Array<string[]>|string)} sort - define the sorting to be done; false sorts by the accessor of the object, function defines the sort function, array
 * defines multiple strings to be sorted, string defines the accessor of how it is sorted. If the string array contains a string array, it will be used for accessing
 * child items (e.g. ['item','data','value'] would call object.item.data.value for comparison). This can only be used with string[]. If only sorting by a single field with multiple accessors,
 * it would still need to be a 1-length array for the sort parameter
 * @param {CustomEach} each - callback for function that will be called on each object after it has been sorted
 */
export const objectSortEach = <T>(object: Dictionary<T>, sort: undefined | CustomSort<T> | string | string[] | string[][], each: CustomIter<T>): void => {
  if (typeof sort === 'string') {
    objectSortEach(object, [sort], each);
    // If sort undefined, sort by the key/id of the object. Object.keys returns keys in order of declaration, not alphabetically.
  } else if (sort === undefined) {
    objectMapArray(object, (key: string, item: any): IKeyValueFlat<T> => { return { key, item }; })
      .sort((a: any, b: any) => a.key.localeCompare(b.key))
      .forEach((v: IKeyValueFlat<T>, i) => each(v, i, i));
  } else if (Array.isArray(sort)) {
    objectMapArray(object, (key: string, item: any): IKeyValueFlat<T> => { return { key, item }; })
      .sort((a: any, b: any) => recursiveCompare(a.item, b.item, sort))
      .forEach((v: IKeyValueFlat<T>, i) => each(v, i, i));
  } else if (isFunction(sort)) {
    objectMapArray(object, (key: string, item: any): IKeyValueFlat<T> => { return { key, item }; })
      .sort((a: any, b: any) => sort(a, b))
      .forEach((v: IKeyValueFlat<T>, i) => each(v, i, i));
  } else {
    throw new Error(`DataroweHelpers.ObjectSortMap - unsupported sort type ${typeof sort}; data: ${JSON.stringify(sort)}`);
  }
};


export interface GenericCategory<K> {
  id: K;
  parent?: K;
  childCategories: K[];
}

/**
 * Given a list of objects that inherit from GenericCategory that is not nested, create a result dictionary with children properly mapped and sorted, a
 * list of root items sorted, and path of categories to any category through
 * @param values
 * @param sort
 * @returns
 */
export const mapRecursiveCategories = <T extends GenericCategory<K>, K = number | string, R extends GenericCategory<K> = T, S = any>(
  values: T[],
  sort: ((a: T, b: T) => number) | keyof T | (keyof T)[] | string[][],
  map?: CustomIter<T, R>,
  mapResultMap?: keyof R
): { categories: Dictionary<R>, categoryPath: Dictionary<K[]>, categoryRoot: K[], mapResultSet: S[] } => {
  const categories: Dictionary<R> = {};
  const categoryPath: Dictionary<K[]> = {};
  const categoryRoot: K[] = [];
  const mapResultSet: S[] = [];
  let count = 0;

  const recursiveMap = (item: T, path: K[]) => {
    item.childCategories = [];
    if (map == null) {
      categories[item.id as unknown as string] = item as unknown as R;
    } else {
      const res = map({ item, key: `${item.id}` }, count++, Object.keys(categories).length);
      if (res != null) {
        categories[item.id as unknown as string] = res;
        if (mapResultMap != null) mapResultSet.push(res[mapResultMap] as unknown as S);
      }
    }
    arraySortEach(values.filter(v => v.parent === item.id), sort, v => {
      item.childCategories.push(v.id);
      recursiveMap(v, [...path, item.id]);
    });
    categoryPath[item.id as unknown as string] = [...path, item.id];
  };

  arraySortEach(values.filter(v => v.parent == null), sort, v => {
    categoryRoot.push(v.id);
    recursiveMap(v, []);
  });

  return { categories, categoryPath, categoryRoot, mapResultSet };
};

/**
 * Takes two objects and merges them where only items with keys from the first object are included, but if they exist in the second object, that value is used for the key
 * @param {Object} obj1  - the object with every key to be included, and a default value
 * @param {Object} obj2  - the object whose values are to be kept if they exist
 */
export const objectLeftJoin = <T>(obj1: Dictionary<T>, obj2: Dictionary<T>): Dictionary<T> => objectMap(obj1, (k, v) => obj2[k] ?? v);

/**
 * In a case where dynamically creating regex (to search for words, etc.), this can be used to escape any RegExp characters so they don't alter the overall setup.
 * @param {string} s - character string to be made regex-safe
 * @returns {string} string with no special regex characters
 */
export const regexEscape = (s: string) => {
  return s.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
};

/**
 * Gets a parameter from a url by name
 * @param {string} name - the name of the parameter to be returned
 * @param {string=} url - the url to be parsed; default is window.location.href
 * @returns {string} - found parameter
 */
export const getParameterByName = (name: string, url?: string, allowBlank?: boolean): string | undefined => {
  const results = new RegExp(`[?&]${name.replace(/[[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`).exec(url ?? window.location.href);
  if (!results) return undefined;
  if (!results[2]) return allowBlank ? '' : undefined;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const objectFilterString = (object: any, search?: string) => {
  if (search == null) return true;
  return new RegExp(`^(?=.*${search.replace(/[ ]/g, ')(?=.*')})`, 'i').test(
    Object.getOwnPropertyNames(object).reduce((y, z) => `${y} ${object[z]}`, '')
  );
};

/**
 * Return a regular expression to test if a string matches the search term
 * @param search text to search by; each space-separated term is uniquely searched for
 */
export const regexFilterString = (search: string): RegExp => new RegExp(`^(?=.*${search.replace(/[ ]/g, ')(?=.*')})`, 'i');

/**
 * source from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * run a function on an interval that works properly with react hook events, that adjusts by delay with no other changes, or
 * pauses when delay becomes null.
 * @param callback function that is called every delay
 * @param delay delay between callbacks
 */
export const useInterval = (callback: () => void, delay?: number) => {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback]
  );

  // Set up the interval.
  React.useEffect(
    () => {
      function tick() {
        if (savedCallback.current) savedCallback.current();
      }
      if (delay != null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
      return () => { };
    },
    [delay]
  );
};
export interface ICustomDebounceOptions { min: number, max: number, onDispose: 'Cancel' | 'Callback' }
export interface ICustomSimpleDebounce<T, F> { initialState: T, callback: F, delay: number }
export interface ICustomComplexDebounce<T, F> extends Omit<ICustomSimpleDebounce<T, F>, 'delay'> { options: ICustomDebounceOptions }


export type CustomDebounce<T> = ICustomSimpleDebounce<T, (value: T) => void> | ICustomComplexDebounce<T, (value: T) => void>;
export type CustomDebouncePromise<T> = { promise: true } & ICustomSimpleDebounce<T, (value: T) => Promise<T>> | ({ promise: true } & ICustomComplexDebounce<T, (value: T) => Promise<T>>);
export type CustomDebounceCallback<T> = (newValue: T, option?: 'force' | 'callbackComplete') => void;
/**
 * Passing an initial value, a callback, and debounce options, value/boolean/setter like a state that will periodically push changes to the
 * callback. During callback, boolean at index 1 will become true. if callback is a promise (and promise is true), upon completion value set
 * to promise result, and boolean becomes false. If not a promise, calling set with
 * @returns
 */
export const useStateDebounceEffect = <T>(
  props: CustomDebounce<T> | CustomDebouncePromise<T>
): [T, boolean, CustomDebounceCallback<T>] => {

  const savedCallback = React.useRef(props.callback);
  React.useEffect(() => { savedCallback.current = props.callback; }, [props.callback]);

  const unmountAction = React.useRef(isInterface<ICustomSimpleDebounce<T, any>>(props, 'delay') ? 'Cancel' : props.options.onDispose);
  React.useEffect(
    () => { unmountAction.current = isInterface<ICustomSimpleDebounce<T, any>>(props, 'delay') ? 'Cancel' : props.options.onDispose; },
    [isInterface<ICustomSimpleDebounce<T, any>>(props, 'delay') ? 'Cancel' : props.options.onDispose]
  );

  const [updating, setUpdating] = React.useState(false);
  const [value, setValue] = React.useState(props.initialState);
  const debounced = useDebouncedCallback(
    (v: T) => {
      setUpdating(true);
      if (isInterface<CustomDebouncePromise<T>>(props, 'promise')) {
        (savedCallback.current(v) as Promise<T>).then((update: T) => {
          setValue(update);
          setUpdating(false);
        });
      } else {
        savedCallback.current(v);
      }
    },
    isInterface<ICustomSimpleDebounce<T, any>>(props, 'delay') ? props.delay : props.options.min,
    isInterface<ICustomSimpleDebounce<T, any>>(props, 'delay') ? ({}) : ({ maxWait: props.options.max })
  );

  React.useEffect(
    () => () => {
      if (unmountAction.current === 'Callback') {
        debounced.flush();
        // savedCallback.current(value);
      }
    },
    []
  );

  let setValueManaged = React.useCallback<CustomDebounceCallback<T>>(
    (newValue, option) => {
      setValue(newValue);
      if (option === 'callbackComplete') {
        setUpdating(false);
      } else {
        debounced(newValue);
      }
      if (option === 'force') {
        debounced.flush();
      }
    },
    [setValue, debounced]
  );


  return [value, updating, setValueManaged];
};

/**
 * Returns true on the first time isMatch is satisfied; if no matches are found, returns false.
 * @param variable Array or Dictionary to be searched
 * @param isMatch Function that takes item of the searched variable, returning true or false. First time returning true, operation exits and stops executing
 */
export const hasAnyMatch = <T>(variable: T[] | Dictionary<T> | undefined, isMatch: (item: T, index: string | number) => boolean): boolean => {
  if (variable == null) return false;
  if (Array.isArray(variable)) {
    for (let i = 0; i < variable.length; i++) {
      if (isMatch(variable[i], i)) return true;
    }
  } else {
    const keys = Object.keys(variable);
    for (let i = 0; i < keys.length; i++) {
      if (isMatch(variable[keys[i]], keys[i])) return true;
    }
  }
  return false;
};

/**
 * Find all indexes within an array that match
 * @param array array of objects
 * @param isMatch if the index should be returned
 */
export const findIndexesOf = <T>(array: T[], isMatch: (item: T, index: number, array: T[]) => boolean): number[] => {
  const result: number[] = [];
  for (let i = 0; i < array.length; i++) {
    if (isMatch(array[i], i, array)) result.push(i);
  }
  return result;
};

export const isEqualOrInArray = <T>(a: T | T[], b: T): boolean =>
  Array.isArray(a) ? a.indexOf(b) >= 0 : a === b;

export const stringToIdList = (list: string): number[] => list.split(/[^0-9]+/g).map(x => +x).filter(num => isNumber(num));
export const minutesToHours = (minutes: number): string => `${(minutes / 60).toFixed(0)}:${(`00${minutes % 60}`).slice(-2)}`;

export const formatTime = (seconds: number): string => {
  const s = seconds % 60;
  const m = Math.floor(seconds / 60) % 60;
  const h = Math.floor(seconds / 3600) % 60;

  return `${h > 0 ? `${h}:` : ''}${h > 0 && m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
};

/** Quick syntax helper for creating `Grid` element properties. */
export const quickGrid = (xs: GridSize = 'auto', sm: GridSize = 'auto', md: GridSize = 'auto', lg: GridSize = 'auto', xl: GridSize = 'auto'): { xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize, xl: GridSize } => {
  return { xs, sm, md, lg, xl };
};

// JS implementation of PHP's `ucfirst()` function. Converts first character of provided string to Uppercase.
export const ucFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Extremely basic "Plualize" helper, only automatically handles words that append an `s` as their plural form
// For all other cases, use as `pluralize(count, 'Moose', 'Moose');`, `pluralize(count, 'Status', 'Statuses');` (or similar)
export const pluralize = (count: number, singular: string, plural?: string) => {
  return count === 1 ? singular : (plural ? plural : `${singular}s`);
};

export const orMin = (value: number, min?: number): number => min == null ? orMin(value, 0) : value < min ? min : value;
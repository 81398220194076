import { compose } from 'redux';
import { connect } from 'react-redux';
import { AutoRow } from '../AutoLoad';
import React, { ComponentType } from 'react';
import { mapConfigFetchToProps } from '../AutoGrid';
import { createStyles, withStyles } from '@mui/styles';
import { createAlertBulk } from 'src/stores/ui/actions';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { mapProfileDbFromAppState } from '../../../stores/database/types';
import { loadCompaniesCache } from '../../../stores/database/identity/companies';
import { IKeyValueFlat, objectSortMap } from '../../../abstracts/DataroweHelpers';
import { fetchConfigRequest, refreshCacheData } from '../../../stores/database/actions';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectChangeEvent } from '@mui/material';

const styles = () => createStyles({
  matchHeight: {
    marginTop: '5px;'
  }
});

interface IConnectedProps {
  classes: any;
  fetchConfigRequest: typeof fetchConfigRequest;
  refreshCacheData: typeof refreshCacheData;
  createAlertBulk: typeof createAlertBulk;
}

const UnionSelectEditor = (props: IAutoEditorImplementation<string | undefined> & IConnectedProps) => {
  const { classes, field, editValue, error, warning } = props;

  const [unions, setUnions] = React.useState<{ key: number; display: string }[]>([]);

  const handleFieldUpdate = (event: SelectChangeEvent<string>) => {
    const displayUnion = unions.find((union) => union.key === +event.target.value);

    return props.onValueUpdated(event.target.value, field, displayUnion?.display ?? '');
  };

  React.useEffect(() => {
    loadCompaniesCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk).then(res => {
      const u = objectSortMap(res.autoGridProps.rowData, res.aliases.displayName, (r: IKeyValueFlat<AutoRow>) => (r.item[res.aliases.isUnion] ? { key: r.item[res.aliases.companyId], display: r.item[res.aliases.displayName] } : undefined));
      setUnions(u);
    });
  }, []);

  const getMenuItems = () => unions.map(({ key, display }) => (
    <MenuItem key={key} value={key}>
      {display}
    </MenuItem>
  ));

  return (
    <FormControl error={!!error} fullWidth required={field.required} className={classes.matchHeight}>
      <InputLabel id={`selectEditor_${field.columnId}_label`}>{field.title}</InputLabel>
      <Select label={field.title} labelId={`selectEditor_${field.columnId}_label`} id={`selectEditor_${field.columnId}`} value={editValue ?? ''} onChange={handleFieldUpdate} disabled={props.disabled}>
        {field.required ? undefined : <MenuItem value="">&nbsp;</MenuItem>}
        {getMenuItems()}
      </Select>
      <FormHelperText>{error || warning || field.description}</FormHelperText>
    </FormControl>
  );
};

export default compose<ComponentType<IAutoEditorImplementation<string | undefined>>>(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(UnionSelectEditor);

import React from 'react';
import { compose } from 'redux';
import { cloneDeep } from 'lodash';
import WorkerPhoto from './WorkerPhoto';
import WorkerSearch from './WorkerSearch';
import { Delete } from '@mui/icons-material';
import { AutoLoadKeyDisplay } from '../auto/AutoLoad';
import { createStyles, withStyles } from '@mui/styles';
import { objectSortMap } from '../../abstracts/DataroweHelpers';
import { Theme, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@mui/material';

const generateSxClasses = (_theme: Theme) => {
  return {
    heightLimitGridCell: {
      overflow: 'auto',
      fontSize: '90%'
    }
  };
};

interface IWorkerSelectListProps {
  mode: 'button' | 'inline' | 'direct';
  open?: boolean;
  dialogTitle?: string;
  buttonTitle?: string;
  classes: any;
  workers: AutoLoadKeyDisplay;
  maxHeight?: string | number;
}

interface IWorkerSelectListActions {
  onWorkersChange: (workers: AutoLoadKeyDisplay) => void;
  onCancel?: () => void;
}

interface IWorkerSelectListState {
  rows: AutoLoadKeyDisplay;
  listOpen: boolean;
}

const WorkerSelectList = (props: IWorkerSelectListProps & IWorkerSelectListActions) => {
  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);
  const { workers } = props;

  const [listState, setListState] = React.useState<IWorkerSelectListState>({
    rows: workers,
    listOpen: false
  });

  const [searchOpen, setSearchOpen] = React.useState(false);

  React.useEffect(() => {
    setListState((old) => ({
      ...old,
      rows: workers
    }));

    return () => { };
  }, [workers]);

  const handleSearchClose = () => setSearchOpen(false);

  const handleWorkerClear = () => setListState((old) => ({
    ...old,
    rows: {}
  }));

  const handlePersonsAdd = (persons: AutoLoadKeyDisplay) => {
    setListState((old) => ({
      ...old,
      rows: {
        ...old.rows,
        ...persons
      }
    }));

    handleSearchClose();
  };

  const handlePersonDelete = (personId: number) => {
    const updatedList: AutoLoadKeyDisplay = cloneDeep(listState.rows);
    delete updatedList[personId];

    setListState((old) => ({
      ...old,
      rows: updatedList
    }));
  };

  const handleOpen = () => {
    if (props.mode === 'button') setListState((old) => ({
      ...old,
      listOpen: true
    }));
  };

  const handleRequestAdd = () => setSearchOpen(true);

  const handleUpdate = () => {
    if (props.mode === 'button') setListState((old) => ({
      ...old,
      listOpen: false
    }));

    props.onWorkersChange(listState.rows);
  };

  const handleCancel = () => {
    if (props.mode === 'button') setListState((old) => ({
      ...old,
      rows: workers,
      listOpen: false
    }));

    if (props.onCancel) props.onCancel();
  };

  const workerList = () => (
    <List sx={{ maxHeight: props.maxHeight || 400, ...sxClasses.heightLimitGridCell }} key="person-list">
      {Object.keys(listState.rows).length === 0 ? (
        <ListItem>
          <ListItemText primary="No Workers" secondary="Select 'Add Workers' to add to this list"/>
        </ListItem>
      ) : (
        objectSortMap(listState.rows, 'display', ({ key, item }: { key: string; item: any }) => {
          return (
            <ListItem button key={`person-${key}`}>
              <ListItemAvatar key={`person-avatar-${key}`}>
                <WorkerPhoto personId={+key} photoTakenUTC={item.rowData['person.pictureTakenUTC']} diameter={48}/>
              </ListItemAvatar>
              <ListItemText sx={{ marginLeft: theme.spacing(1) }} key={`person-text-${key}`} primary={item.display}/>
              <ListItemSecondaryAction>
                <IconButton onClick={() => handlePersonDelete(+key)}>
                  <Delete/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      )}
    </List>
  );

  const workerListDialog = () => (
    <Dialog open={props.open || listState.listOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>{props.dialogTitle ?? 'Select Workers'}</DialogTitle>
      <DialogContent>{workerList()}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
        <Button onClick={handleWorkerClear} color="primary" disabled={Object.keys(listState.rows).length === 0}>
          Clear Selection
        </Button>
        <Button onClick={handleRequestAdd} color="primary">
          Add Workers
        </Button>
      </DialogActions>
    </Dialog>
  );

  const display = () => {
    switch (props.mode) {
      case 'button':
        return (
          <>
            <Button variant="contained" size="small" fullWidth onClick={handleOpen}>
              {props.buttonTitle ?? 'Select Worker(s)'}
            </Button>
            {workerListDialog()}
          </>
        );
      case 'direct':
        return workerListDialog();
      case 'inline':
        return workerList();
    }
  };

  return (
    <>
      {display()}
      <WorkerSearch mode="direct" open={searchOpen} onSelectCancel={handleSearchClose} onWorkersSelected={handlePersonsAdd} dialogTitle="Add worker(s) to list"/>
    </>
  );
};

export default compose(withStyles(createStyles({})))(WorkerSelectList);

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import settings from '../../abstracts/settings';
import { AutoLoadKeyDisplay } from '../auto/AutoLoad';
import { createStyles, withStyles } from '@mui/styles';
import { ISetSearchConfig } from '../../stores/database/interfaces';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import DefaultAutoGrid, { IQuickFilterPath } from '../auto/DefaultAutoGrid';
import { AutoSearchTypes } from 'src/stores/database/gridExtension/interfaces';
import { mapConfigFetchToProps, IConnectedProps, AutoGridRowClickEvent } from '../auto/AutoGrid';
import { Button, Dialog, DialogContent, DialogTitle, Grid, DialogActions, Typography } from '@mui/material';

export interface ICourseScheduleSelectProps extends ISetSearchConfig {
  classes: any;
  minHeight?: number | string;
  maxHeight?: number | string;
  dialogTitle: string;
  limitSelectCount?: number;
  disabled?: boolean;
  excludedIds?: number | number[];
  defaultCourseFilter?: number | number[];
  ignorePrimaryFilter?: number | number[];
  onCourseSchedulesSelected: (courseSchedules?: AutoLoadKeyDisplay) => void;
  onSelectCancel?: () => void;
}

export interface ICourseScheduleButtonSelectProps extends ICourseScheduleSelectProps {
  buttonTitle: string;
  buttonTip?: string;
  mode: 'button';
}

export interface ICourseScheduleDirectSelectProps extends ICourseScheduleSelectProps {
  open: boolean;
  mode: 'direct';
}

interface ICourseScheduleSelectState {
  open: boolean;
  search?: AutoSearchTypes;
}

const CourseScheduleSelect = (props: (ICourseScheduleButtonSelectProps | ICourseScheduleDirectSelectProps) & IConnectedProps) => {
  const [localState, setLocalState] = React.useState<ICourseScheduleSelectState>({ open: false });

  function handleOpen() {
    setLocalState((old) => ({
      ...old,
      open: true
    }));
  }

  function handleClose() {
    setLocalState((old) => ({
      ...old,
      open: false
    }));

    if (props.onSelectCancel !== undefined) props.onSelectCancel();
  }

  const handleSubmit: AutoGridRowClickEvent = (e) => {
    setLocalState((old) => ({
      ...old,
      open: false
    }));

    props.onCourseSchedulesSelected(e.updatedSelectedRows!);
  };

  const constructFilters = (): IQuickFilterPath[] => {
    const defaultFilters: IQuickFilterPath[] = [{
      path: 'trainingDate',
      value: {
        startDate: moment().format(settings.apiDateFormatMoment),
        includeNull: false
      }
    }];

    if (typeof props.defaultCourseFilter !== 'undefined') {
      const courseIdFilter: IQuickFilterPath = {
        path: 'course',
        value: props.defaultCourseFilter
      };

      defaultFilters.push(courseIdFilter);
    }

    if (typeof props.ignorePrimaryFilter !== 'undefined') {
      const courseScheduleIdFilter: IQuickFilterPath = {
        path: 'courseScheduleId',
        value: props.ignorePrimaryFilter,
        not: true
      };

      defaultFilters.push(courseScheduleIdFilter);
    }

    return defaultFilters;
  };

  return (
    <>
      {props.mode === 'button' ? (
        <>
          <Button variant="contained" size="small" disabled={props.disabled} onClick={handleOpen} title={props.buttonTip}>
            {props.buttonTitle}
          </Button>
          {props.buttonTip?.length ?? 0 > 0 ? <Typography>{props.buttonTip}</Typography> : undefined}
        </>
      ) : undefined}

      <Dialog open={localState.open || (props.mode === 'direct' && props.open)} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid sx={{ minHeight: '100px' }} container spacing={3}>
            <Grid item xs={12}>
              <DefaultAutoGrid
                columns={[
                  'trainingDate',
                  'trainingTime',
                  'seatDetails.availableSeats',
                  'course.code',
                  'course.title',
                  'course.pricePartner',
                  'course.priceNonPartner',
                  'room.location.name',
                  'reservedCompany.name'
                ]}
                // -TODO- Investigate why this isn't working as expected
                // defaultColumnFilters={[{ column: 'coursescheduleid', filter: props.excludedIds ?? 0, hideFromTable: true }]}
                maxHeight={props.maxHeight}
                minHeight={props.minHeight}
                onRowClick={handleSubmit}
                pagination
                quickFilter={constructFilters()}
                tableName="training.courseSchedule"
                defaultSortColumn={0}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default compose(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(CourseScheduleSelect);

import {
  TrainingBookActionTypes, TrainingBookStep, IQueryCourseScheduleProps, IHubUpdateSeats, IAssignToClass, IUpdateScheduleDateProps, IHubScheduleResult, IReserveRequest,
  IPaymentConfig, IBookingSubmit, IAssignToWebCourse, IWebCourseRequest, IHubReserveError, IPreloadBookingProps
} from './types';
import { User } from 'oidc-client';
import { Dictionary } from 'lodash';
import { action } from 'typesafe-actions';
import { HubConnection } from '@microsoft/signalr';
import { createAlertBulk } from 'src/stores/ui/actions';
import { IDateRange } from 'src/components/common/DateRangeEdit';
import { mapConfigFetchToProps } from 'src/components/auto/AutoGrid';
import { AutoLoadKeyDisplay } from '../../../components/auto/AutoLoad';
import { IUIMessage, IEmailAddress } from '../../../abstracts/Interfaces';
import { fetchConfigRequest, refreshCacheData } from 'src/stores/database/actions';

export const bookSetStep = (step: TrainingBookStep) => action(TrainingBookActionTypes.BOOK_SET_STEP, step);
export const bookRestart = () => action(TrainingBookActionTypes.BOOK_RESTART);

export const bookHubUpdateSeats = (update: IHubUpdateSeats) => action(TrainingBookActionTypes.BOOK_HUB_UPDATESEATS, update);
export const bookHubUpdateSeatsBatch = (update: IHubUpdateSeats[]) => action(TrainingBookActionTypes.BOOK_HUB_UPDATESEATSBATCH, update);
export const bookHubUpdateExpiry = (bookSessionId: number, expirySeconds?: number) => action(TrainingBookActionTypes.BOOK_HUB_UPDATEEXPIRY, { bookSessionId, expiry: expirySeconds ?? 0 });
export const bookHubVerifySession = (result: IHubScheduleResult) => action(TrainingBookActionTypes.BOOK_HUB_VERIFYSESSION, result);
export const bookHubUpdateAddErrors = (errors: IHubReserveError[]) => action(TrainingBookActionTypes.BOOK_HUB_ADDERRORS, errors);
export const bookHubFailed = (message: string) => action(TrainingBookActionTypes.BOOK_HUB_FAILED, message);
export const bookHubPaymentConfig = (submitState: IBookingSubmit, paymentMethods: Dictionary<IPaymentConfig>, registrationEmails: { [companyId: number]: IEmailAddress[] }) => action(TrainingBookActionTypes.BOOK_HUB_PAYMENTCONFIG, { submitState, paymentMethods, registrationEmails });
export const persistConnection = (connection: HubConnection, sessionId?: number) => action(TrainingBookActionTypes.BOOK_HUB_CONNECT, { connection, sessionId });

export interface IBookHub {
  bookHubUpdateSeats: typeof bookHubUpdateSeats;
  bookHubUpdateSeatsBatch: typeof bookHubUpdateSeatsBatch;
  bookHubUpdateExpiry: typeof bookHubUpdateExpiry;
  bookHubVerifySession: typeof bookHubVerifySession;
  bookHubUpdateAddErrors: typeof bookHubUpdateAddErrors;
  bookRestart: typeof bookRestart;
  bookHubFailed: typeof bookHubFailed;
  bookHubPaymentConfig: typeof bookHubPaymentConfig;
  persistConnection: typeof persistConnection;
  user: User;
}

export interface IBookTrainingConnectedProps extends IBookHub {
  bookSetStep: typeof bookSetStep;
  bookActionClearMessage: typeof bookActionClearMessage;
  hubConnecting: typeof hubConnecting;
  hubDisconnect: typeof hubDisconnect;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
  refreshCacheData: typeof refreshCacheData;
}

export const mapBookHub = {
  bookHubUpdateSeats,
  bookHubUpdateSeatsBatch,
  bookHubVerifySession,
  bookHubUpdateAddErrors,
  persistConnection,
  bookHubPaymentConfig,
  bookRestart,
  bookHubFailed
};

export const preloadBooking = (props: IPreloadBookingProps) => action(TrainingBookActionTypes.BOOK_PRELOAD, props);
export const bookActionSetMessage = (message: IUIMessage, otherProps?: { [x: string]: any }) => action(TrainingBookActionTypes.BOOK_ACTION_MESSAGE, { message, otherProps: otherProps || {} });
export const bookActionClearMessage = () => action(TrainingBookActionTypes.BOOK_ACTION_MESSAGE, { message: undefined, otherProps: {} });
export const scheduleSetDate = (props: IUpdateScheduleDateProps) => action(TrainingBookActionTypes.SCHEDULE_SET_DATE_START, props);
export const scheduleGetClasses = (props: IQueryCourseScheduleProps) => action(TrainingBookActionTypes.SCHEDULE_GET_CLASSES, props);
export const scheduleAddPersons = (personIds: AutoLoadKeyDisplay) => action(TrainingBookActionTypes.SCHEDULE_ADD_PERSONS, personIds);
export const scheduleDeletePerson = (sessionId: number, personId: number) => action(TrainingBookActionTypes.SCHEDULE_DELETE_PERSON, { sessionId, personId });
export const scheduleAssignToClass = (sessionId: number, assignments: IAssignToClass[]) => action(TrainingBookActionTypes.SCHEDULE_ASSIGN_TOCLASS, { sessionId, assignments });
export const scheduleRemoveFromClass = (sessionId: number, personId: number, courseScheduleId: number) => action(TrainingBookActionTypes.SCHEDULE_REMOVE_FROMCLASS, { sessionId, personId, courseScheduleId });
export const scheduleAssignWebCourse = (sessionId: number, assignments: IAssignToWebCourse[]) => action(TrainingBookActionTypes.SCHEDULE_ASSIGN_TOWEB, { sessionId, assignments });
export const scheduleRemoveWebCourse = (sessionId: number, personId: number, courseId: number) => action(TrainingBookActionTypes.SCHEDULE_REMOVE_FROMWEB, { sessionId, personId, courseId });
export const submitAddEmail = (companyId: number, email: string) => action(TrainingBookActionTypes.SUBMIT_ADD_EMAIL, { companyId, email });
export const submitRemoveEmail = (companyId: number, email: string) => action(TrainingBookActionTypes.SUBMIT_DELETE_EMAIL, { companyId, email });
export const submitMarkCompleting = () => action(TrainingBookActionTypes.SUBMIT_MARK_COMPLETING);

export const hubDisconnect = () => action(TrainingBookActionTypes.BOOK_HUB_DISCONNECT);
export const hubClear = () => action(TrainingBookActionTypes.BOOK_HUB_CLEAR);
export const hubConnecting = () => action(TrainingBookActionTypes.BOOK_HUB_CONNECTING);

// "actions" that are for signalR checkins
// export const signalrHubVerifyCheckin = (connection: HubConnection, date?: moment.Moment) => {
//   connection.invoke('ConnectCheckin', date == null ? undefined : date.format(settings.apiDateFormatMoment)).catch(e => console.log(e));
// };

// "actions" that are for signalR sessions
export const signalrHubVerifySession = (connection: HubConnection, bookSessionId?: number, courseScheduleIds?: number[], abandonedCourseScheduleIds?: number[]) => {
  connection.invoke('VerifySession', bookSessionId, courseScheduleIds ?? [], abandonedCourseScheduleIds).catch(e => console.log(e));
};

export const signalrHubReserveClassSeats = (connection: HubConnection, sessionId: number, reserveSeatRequests: IReserveRequest[]) => {
  console.log({ sessionId, reserveSeatRequests });
  connection.invoke('ReserveClassSeats', sessionId, reserveSeatRequests).catch(e => console.log(e));
};

export const signalrHubAssignWebCourse = (connection: HubConnection, sessionId: number, webCourseRequests: IWebCourseRequest[]) => {
  console.log({ sessionId, webCourses: webCourseRequests });
  connection.invoke('AssignWebCourse', sessionId, webCourseRequests).catch(e => console.log(e));
};

export const signalrHubRemoveReservation = (connection: HubConnection, sessionId: number, personId: number, courseScheduleId: number) => {
  console.log({ sessionId, personId, courseScheduleId });
  connection.invoke('RemoveReservation', sessionId, personId, courseScheduleId).catch(e => console.log(e));
};

export const signalrHubCancelWebAssign = (connection: HubConnection, sessionId: number, personId: number, courseId: number) => {
  console.log({ sessionId, personId, courseId });
  connection.invoke('CancelWebAssign', sessionId, personId, courseId).catch(e => console.log(e));
};

export const signalrHubUpdateSelfPay = (connection: HubConnection, sessionId: number, updates: { personId: number, reserveId: number; update: boolean; }[]) => {
  console.log('signalrHubUpdateSelfPay', { sessionId, updates });
  connection.invoke('UpdateSelfPay', sessionId, updates).catch(e => console.log(e));
};

export const signalrHubUpdateWaitlistDates = (connection: HubConnection, sessionId: number, updates: { personId: number, reserveId: number; update?: IDateRange }[]) => {
  console.log('signalrHubUpdateWaitlistDates', { sessionId, updates });
  connection.invoke('UpdateWaitlistDates', sessionId, updates).catch(e => console.log(e));
};

export const signalrHubUpdatePurchaseOrderClassroom = (connection: HubConnection, sessionId: number, updates: { personId: number, reserveId: number; update?: string; }[]) => {
  console.log('UpdatePurchaseOrderClassroom', { sessionId, updates });
  connection.invoke('UpdatePurchaseOrderClassroom', sessionId, updates).catch(e => console.log(e));
};

export const signalrHubUpdatePurchaseOrderWebBased = (connection: HubConnection, sessionId: number, updates: { personId: number, reserveId: number; update?: string; }[]) => {
  console.log('UpdatePurchaseOrderClassroomWebBased', { sessionId, updates });
  connection.invoke('UpdatePurchaseOrderClassroomWebBased', sessionId, updates).catch(e => console.log(e));
};

export const signalrHubUpdateCreditCard = (connection: HubConnection, sessionId: number, updates: { personId: number, userCompanyId: number; purchaseOrder: string; creditCard: boolean; }[]) => {
  console.log('signalrHubUpdateCreditCard', { sessionId, updates });
  connection.invoke('UpdateCreditCard', sessionId, updates).catch(e => console.log(e));
};

export const signalrHubCompleteBooking = (connection: HubConnection, sessionId: number, otherEmails: { companyId: number; address: string; }[]) => {
  connection.invoke('CompleteBooking', sessionId, otherEmails).catch(e => console.log(e));
};

export const mapBookTrainingPage = {
  scheduleGetClasses,
  bookActionClearMessage,
  bookSetStep,
  hubConnecting,
  hubDisconnect,
  ...mapBookHub,
  ...mapConfigFetchToProps
};

import React from 'react';
import moment from 'moment';
import settings from '../../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, Grid, SelectChangeEvent, Theme } from '@mui/material';
import { ICourseExpiry, ICourseExpiryFixed, ICourseExpiryRelative, IExpiryPeriods, IExpiryTypes } from '../../../stores/database/training/courses';

const styles = (theme: Theme) => createStyles({
  matchHeight: {
    marginTop: theme.spacing(1)
  },
  nestedMatchHeight: {
    marginTop: theme.spacing(2)
  }
});

interface IExpiryEditorProps extends IAutoEditorImplementation<string | undefined> {
  classes: any;
}

interface IExpiryOption {
  value: IExpiryTypes;
  label: string;
}

interface IPeriodOption {
  value: IExpiryPeriods;
}

const ExpiryEditor = (props: IExpiryEditorProps) => {
  const { classes, field, error, warning, onValueUpdated } = props;

  const defaultValue = (val?: string): ICourseExpiry => (typeof val === 'string' ? JSON.parse(val) : undefined);

  const [expiry, setExpiry] = React.useState(defaultValue(props.editValue));

  const handleChange = (newExpiry: ICourseExpiry) => {
    setExpiry(newExpiry);
    onValueUpdated(JSON.stringify(newExpiry), field);
  };

  const handleTypeUpdate = (event: SelectChangeEvent<IExpiryTypes>) => {
    switch (event.target.value) {
      case 'NoRenew':
        handleChange({ type: 'NoRenew' });
        break;
      case 'Fixed':
        handleChange({ type: 'Fixed', date: moment().add(1, 'year').format(settings.apiDateFormatMoment) });
        break;
      case 'Relative':
        handleChange({ type: 'Relative', period: 'Years', frequency: 2 });
        break;
    }
  };

  const handleValueDisplay = (): string => {
    if (expiry == null) return '';

    switch (expiry.type) {
      case 'NoRenew':
        return 'No Renewal';
      case 'Fixed':
        return `Fixed Date (${moment((expiry as ICourseExpiryFixed).date).format(settings.dateFormatMoment)}`;
      case 'Relative':
        return `Relative to Training (${(expiry as ICourseExpiryRelative).frequency} ${(expiry as ICourseExpiryRelative).period})`;
    }
  };

  const handleFixedDateChange = (date: string) => handleChange({ date, type: 'Fixed' });

  const handleRelativeFrequencyChange = (frequency: number) => handleChange({ frequency, type: 'Relative', period: (expiry as ICourseExpiryRelative).period });

  const handleRelativePeriodChange = (event: SelectChangeEvent<IExpiryPeriods>) => {
    return handleChange({ period: (event?.target?.value ?? 'Years') as IExpiryPeriods, type: 'Relative', frequency: (expiry as ICourseExpiryRelative).frequency });
  };

  const handleFieldBlur = () => onValueUpdated(JSON.stringify(expiry), field, handleValueDisplay());

  const expiryOptions: IExpiryOption[] = [
    { value: 'NoRenew', label: 'No Renewal' },
    { value: 'Fixed', label: 'Fixed Date' },
    { value: 'Relative', label: 'Relative to Training' }
  ];

  const periodOptions: IPeriodOption[] = [
    { value: 'Years' },
    { value: 'Months' },
    { value: 'Weeks' },
    { value: 'Days' }
  ];

  return (
    <Grid container spacing={0} className={classes.matchHeight}>
      <Grid item xs={12}>
        <FormControl id={`expiryType_${field.columnId}_group`} fullWidth error={!!error} required={field.required}>
          <InputLabel id={`expiryType_${field.columnId}_label`}>{field.title} Type</InputLabel>
          <Select label={`${field.title} Type`} labelId={`expiryType_${field.columnId}_label`} id={`expiryType_${field.columnId}`} value={expiry?.type ?? ''} onChange={handleTypeUpdate} disabled={props.disabled} onBlur={handleFieldBlur}>
            {field.required ? undefined : <MenuItem value="">&nbsp;</MenuItem>}
            {expiryOptions.map((expiryOption, index) => <MenuItem key={`expiryType_${field.columnId}_expiryOption_${index}`} value={expiryOption.value}>{expiryOption.label}</MenuItem>)}
          </Select>
          <FormHelperText>{error || warning || field.description}</FormHelperText>
        </FormControl>
        {expiry?.type === 'Fixed' ? (
          <TextField
            className={classes.nestedMatchHeight}
            disabled={props.disabled}
            error={!!error}
            fullWidth
            id={`expiryFixedDate_${field.columnId}`}
            label={`${field.title} Date`}
            margin="dense"
            onChange={event => handleFixedDateChange(event.currentTarget.value)}
            required={field.required}
            type="date"
            value={expiry.date}
          />
        ) : undefined}
        {expiry?.type === 'Relative' ? (
          <>
            <TextField
              className={classes.nestedMatchHeight}
              disabled={props.disabled}
              error={!!error}
              fullWidth
              id={`relativeFrequency_${field.columnId}`}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: 0 }}
              label="Frequency"
              margin="dense"
              onChange={event => handleRelativeFrequencyChange(+event.currentTarget.value)}
              required={field.required}
              type="number"
              value={expiry?.frequency}
            />
            <FormControl id={`relativeType_${field.columnId}_group`} fullWidth error={!!error} required={field.required} className={classes.nestedMatchHeight}>
              <InputLabel id={`relativeType_${field.columnId}_label`}>Period</InputLabel>
              <Select id={`relativeType_${field.columnId}`} label="Period" value={expiry?.period ?? ''} onChange={handleRelativePeriodChange} disabled={props.disabled} margin="dense">
                {periodOptions.map((periodOption, index) => <MenuItem key={`expiryType_${field.columnId}_periodOption_${index}`} value={periodOption.value}>{periodOption.value}</MenuItem>)}
              </Select>
              <FormHelperText>{error || warning || field.description}</FormHelperText>
            </FormControl>
          </>
        ) : undefined}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ExpiryEditor);

export default {
  messages: {
    duration: {
      min: 'Duration must be at least 30 Minutes.',
      required: 'Duration is Required.'
    }, endDate: {
      required: 'End Date is Required.'
    }, maxSeats: {
      min: 'Max Seats must be as least 1.',
      required: 'Max Seats is Required.'
    }, scheduleType: {
      required: 'Schedule Type is Required.'
    }, startDate: {
      required: 'Start Date is Required.'
    }
  }
};

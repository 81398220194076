import BasesIcon from '../../layout/BasesIcon';
import { createStyles, withStyles } from '@mui/styles';
import { Box, Theme, Typography, useTheme } from '@mui/material';

const generateSxClasses = (theme: Theme) => {
  return {
    fullSize: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 600 : 800],
      opacity: 0.75
    },
    fullScreen: {
      width: '100vw',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 600 : 800],
      opacity: 0.75,
      position: 'absolute',
      left: 0,
      top: 0
    },
    halfSize: {
      width: '100%',
      height: 'calc(50% - 64px)'
    }
  };
};

interface ILoadingScreenProps {
  classes: any,
  small?: boolean,
  helpText?: string,
  hideIcon?: boolean,
  fullScreen?: boolean
}

const LoadingScreen = (props: ILoadingScreenProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const { small } = props;

  return (
    <Box sx={{ ...(props.fullScreen ? sxClasses.fullScreen : sxClasses.fullSize) }}>
      <Box sx={{ ...sxClasses.halfSize }}/>
      {props.hideIcon ? undefined : <BasesIcon size={small ? 'sm' : 'lg'} animated/>}
      {(props.helpText || '').length > 0 ? (<Typography>{props.helpText}</Typography>) : undefined}
    </Box>
  );
};

export default withStyles(createStyles({}))(LoadingScreen);

import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { ComponentType } from 'react';
import { mapConfigFetchToProps } from '../AutoGrid';
import { createStyles, withStyles } from '@mui/styles';
import { createAlertBulk } from 'src/stores/ui/actions';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { mapProfileDbFromAppState } from '../../../stores/database/types';
import { loadCourseCache, ICourse } from 'src/stores/database/training/courses';
import { fetchConfigRequest, refreshCacheData } from '../../../stores/database/actions';
import { IKeyValueFlat, isNumber, nameof, objectSortMap } from '../../../abstracts/DataroweHelpers';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Checkbox, ListItemText, SelectChangeEvent } from '@mui/material';

const styles = () => createStyles({});

interface IConnectedProps {
  classes: any;
  fetchConfigRequest: typeof fetchConfigRequest;
  refreshCacheData: typeof refreshCacheData;
  createAlertBulk: typeof createAlertBulk;
}

interface IWaitlistCourseSelectEditorCourses {
  key: number;
  display: string
}

const SelectWaitlistCourseEditor = (props: IAutoEditorImplementation<number[] | undefined> & IConnectedProps) => {
  const { field, editValue, error, warning, onValueUpdated } = props;

  const handleFieldUpdate = (event: SelectChangeEvent<number[]>) => {
    onValueUpdated(event.target.value as number[], field);
  };

  const [courses, setCourses] = React.useState<IWaitlistCourseSelectEditorCourses[]>([]);

  React.useEffect(() => {
    loadCourseCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk).then((res) => {
      setCourses(objectSortMap(res.courseRows, nameof<ICourse>('title'), (c: IKeyValueFlat<ICourse>) => (c.item.isWaitlistable ? { key: c.item.courseId, display: c.item.title } : undefined)));
    });
  }, []);

  const handleRenderValue = (selectedOptions: number[]) => selectedOptions.map(id => courses.find(course => course.key === id)?.display ?? 'Loading...').sort().join(', ');

  const getMenuItems = () => courses.map(({ key, display }) => (
    <MenuItem key={key} value={key}>
      <Checkbox checked={isNumber(key) && (editValue ?? []).indexOf(key) >= 0} />
      <ListItemText primary={display} />
    </MenuItem>
  ));

  return (
    <FormControl error={!!error} fullWidth required={field.required}>
      <InputLabel id={`selectEditor_${field.columnId}_label`}>{field.title}</InputLabel>
      <Select labelId={`selectEditor_${field.columnId}_label`} id={`selectEditor_${field.columnId}`} multiple value={editValue ?? []} onChange={handleFieldUpdate} disabled={props.disabled} renderValue={handleRenderValue}>
        {field.required ? undefined : <MenuItem value="">&nbsp;</MenuItem>}
        {getMenuItems()}
      </Select>
      <FormHelperText>{error || warning || field.description}</FormHelperText>
    </FormControl>
  );
};

export default compose<ComponentType<IAutoEditorImplementation<string | undefined>>>(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(SelectWaitlistCourseEditor);

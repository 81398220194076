import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import React, { ComponentType } from 'react';
import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { IProfileState } from '../../../stores/profile/types';
import { quickLoadSingle, AutoLoadKeyDisplay } from '../AutoLoad';
import WorkerSearch from '../../../components/identity/WorkerSearch';
import { mapProfileDbFromAppState, IDatabaseState } from '../../../stores/database/types';

interface IConnectedProps {
  classes: any;
  profile: IProfileState;
  db: IDatabaseState;
}

interface IWorkerSelectEditorState {
  workerId?: number;
  workerDisplay: string;
  open: boolean;
  dialogTitle: string;
  displayCache: Dictionary<string>;
}

const WorkerSelectEditor = (props: IAutoEditorImplementation<number | undefined> & IConnectedProps) => {
  const { field, error, warning, onValueUpdated, db } = props;

  const [editState, setEditState] = React.useState<IWorkerSelectEditorState>({
    workerDisplay: '',
    open: false,
    dialogTitle: '',
    displayCache: {}
  });

  const handleWorkerSelected = (workers: AutoLoadKeyDisplay) => {
    const workerId = +Object.keys(workers)[0];
    const workerDisplay = workerId == null ? '' : workers[workerId].display;

    setEditState(old => ({
      ...old,
      workerId,
      workerDisplay,
      open: false,
      dialogTitle: '',
      displayCache: {
        ...old.displayCache,
        [workerId]: workers[workerId].display
      }
    }));

    onValueUpdated(workerId, field, workerDisplay);
  };

  const handleWorkerCancel = () => setEditState(old => ({
    ...old,
    open: false,
    dialogTitle: ''
  }));

  const handleInteract = () => {
    if (!props.disabled && !props.item[props.field.columnId].readonly) {
      setEditState(old => ({
        ...old,
        open: true,
        dialogTitle: editState.workerDisplay.length > 0 ? `Replace ${editState.workerDisplay}` : 'Select Worker'
      }));
    }
  };

  React.useEffect(() => {
    if (props.editValue == null) {
      setEditState(old => ({
        ...old,
        workerId: undefined,
        workerDisplay: ''
      }));
    } else {
      if (!Object.prototype.hasOwnProperty.call(editState.displayCache, props.editValue)) {
        quickLoadSingle({
          db: db.database,
          schema: 'identity',
          table: 'person',
          columns: {
            name: 'workerName'
          },
          filters: {
            iecNumber: props.editValue
          }
        }).then(res => setEditState(old => ({
          ...old,
          workerId: props.editValue,
          workerDisplay: res.name.display
        })));
      }
    }
  }, [props.editValue]);

  return (
    <>
      <TextField
        id={`companyEditor_${field.columnId}_display`}
        InputProps={{ endAdornment: props.endAdornment }}
        label={field.title}
        margin="dense"
        fullWidth
        required={field.required}
        value={editState.workerDisplay}
        error={!!error}
        helperText={error || warning || field.description}
        disabled={props.disabled}
        onClick={handleInteract}
        onKeyDown={handleInteract}
      />
      <WorkerSearch mode="direct" limitSelectCount={1} open={editState.open} onSelectCancel={handleWorkerCancel} onWorkersSelected={handleWorkerSelected} dialogTitle={editState.dialogTitle}/>
    </>
  );
};

export default compose<ComponentType<IAutoEditorImplementation<number | undefined>>>(withStyles(createStyles({})), connect(mapProfileDbFromAppState))(WorkerSelectEditor);

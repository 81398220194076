import { Reducer } from 'redux';
import { IProfileState, ProfileActionTypes } from './types';

export const initialState: IProfileState = {
  userId: -1,
  userNoCompanyId: -1,
  active: false,
  displayName: 'Loading...',
  exclusions: [],
  isPartner: false,
  isAdmin: false,
  isEditor: false,
  isTrainingFacility: false,
  isUnion: false,
  // isContactManager: false,
  // isEquipmentManager: false,
  companies: [],
  pricingField: 'priceNonPartner',
  subtotalField: 'subtotalNonPartner'
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: Reducer<IProfileState> = (state = initialState, action) => {
  switch (action.type) {
    case ProfileActionTypes.LOADED: {
      const { isPartner }: IProfileState = action.payload;

      return {
        ...action.payload,
        pricingField: isPartner ? 'pricePartner' : 'priceNonPartner',
        subtotalField: isPartner ? 'subtotalPartner' : 'subtotalNonPartner'
      };
    }

    default: return state;
  }
};

export { reducer as profileReducer };

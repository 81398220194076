import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import UserAdd from './UserAdd';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import AutoEditor from '../auto/AutoEditor';
import { createStyles, withStyles } from '@mui/styles';
import AutoDeletePrompt from '../auto/AutoDeletePrompt';
import { createAlertBulk } from 'src/stores/ui/actions';
import { IProfileState } from '../../stores/profile/types';
import { Search, Delete, Edit } from '@mui/icons-material';
import { Grid, TextField, Icon, Theme, InputAdornment } from '@mui/material';
import { arrayRemoveAt } from '../../abstracts/DataroweHelpers';
import { fetchConfigRequest } from '../../stores/database/actions';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, AutoGridRowClickProps, AutoGridActions } from '../auto/AutoGrid';
import { ISelectColumnMap, IFilterCondition, IColumnMap, IFilterGroup, FilterConditionType } from '../../stores/database/interfaces';
import { ICustomColumnDefinition, autoLoadMulti, getAllFields, ICustomDisplayDefinition, ICustomSelectColumnMap, AutoRow } from '../auto/AutoLoad';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  }
});

interface IUserListProps {
  [x: string]: any;
  classes: any;
  hidden?: boolean;
  readonly?: boolean;
  companyId?: number;
  onUserSelected: (userId: number, name: string) => void;
  onAddNew: () => void;
}

interface IConnectedProps {
  db: IDatabaseState;
  profile: IProfileState;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

interface IUserListState {
  editId?: number;
  editRow?: AutoRow;
  updateRow?: AutoRow;
  deleteId?: number;
  deleteRow?: AutoRow;
  editOpen: boolean;
}

const UserList = (props: IUserListProps & IConnectedProps) => {
  const { classes } = props;

  const userCompanyTable = 'identity.userCompany';

  let defaultUserCompanyColumns: ICustomColumnDefinition[] = [
    { key: 'user.displayName' },
    { key: 'user.primaryEmail' },
    { key: 'receiveRegistrationEmails' },
    { key: 'company.name' },
    { key: 'user.deleteDateUTC' },
    { key: 'legacyUsername' },
    { key: 'active' },
    { key: 'iecNote' },
    { key: 'isAdmin' },
    { key: 'isEditor' },
    { key: 'user.modernAccountCreated' }
  ];

  if (!props.profile.isTrainingFacility) {
    defaultUserCompanyColumns = arrayRemoveAt(
      defaultUserCompanyColumns,
      defaultUserCompanyColumns.findIndex((x) => x.key === 'user.deleteDateUTC')
    );
    defaultUserCompanyColumns = arrayRemoveAt(
      defaultUserCompanyColumns,
      defaultUserCompanyColumns.findIndex((x) => x.key === 'iecNote')
    );
  }

  if (props.companyId != null) {
    defaultUserCompanyColumns = arrayRemoveAt(
      defaultUserCompanyColumns,
      defaultUserCompanyColumns.findIndex((x) => x.key === 'company.name')
    );
  }

  const [userKey, userDisplay, userEmail, companyKey] = ['userCompanyId', 'user.displayName', 'user.primaryEmail', 'company'];

  interface OtherState {
    loadedConfig: boolean;
    userCompanyTable?: number;
    companyColumn?: IColumnMap;
    emailColumn?: IColumnMap;
  }

  const defaultUserListState: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & OtherState = {
    exportTitle: props.companyId == null ? 'Company Users' : 'All Users',
    baseTable: userCompanyTable,
    displayColumns: [],
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    loadedConfig: false,
    keyField: undefined,
    displayField: undefined
  };

  const [gridParams, setGridParams] = React.useState(defaultUserListState);

  const [state, setState] = React.useState<IUserListState>({
    editOpen: false
  });

  const loadData = (params: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & OtherState) => {
    let userCompanyFilter: IFilterGroup | IFilterCondition | undefined = {
      columnId: params.companyColumn!.columnId,
      lookupPath: params.companyColumn!.lookupPath || [],
      type: FilterConditionType.Equal,
      firstParameter: props.companyId
    };

    autoLoadMulti({
      multi: [
        {
          search: {
            baseTableId: params.userCompanyTable!,
            name: '',
            baseFilter: userCompanyFilter
          },
          baseTableId: params.userCompanyTable!,
          keyField: params.keyField!,
          displayField: params.displayField!,
          allFields: getAllFields(params.displayColumns, params.additionalColumns)
        }
      ],
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk
    }).then((res) => {
      setGridParams((old) => ({
        ...old,
        rowData: res[userCompanyTable],
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    });
  };

  React.useEffect(() => {
    if (gridParams.loadedConfig) {
      loadData(gridParams);
    }
    return () => {};
  }, [props.companyId]);

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'identityUserList',
      action: (db) => {
        if (gridParams.baseTable == null) return;

        const companyTable = typeof gridParams.baseTable === 'string' ? db.getTableByCombinedName(gridParams.baseTable.toString()).tableId : +gridParams.baseTable;
        const [keyField, displayField, emailColumn, companyColumn] = db.mapSelectColumnsByNamesWithId(companyTable, [userKey, userDisplay, userEmail, companyKey]);

        // -TODO- Investigate why this can't just be `let displayColumns = db.mapDisplayColumnsByNamesWithId(...).concat(...)`
        const displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition)[] = db.mapDisplayColumnsByNamesWithId(companyTable, defaultUserCompanyColumns);

        setGridParams((old) => ({
          ...old,
          keyField,
          displayField,
          emailColumn,
          companyColumn,
          displayColumns,
          userCompanyTable: companyTable,
          loadedConfig: true
        }));

        if (props.companyId != null) {
          loadData({
            ...gridParams,
            keyField,
            displayField,
            emailColumn,
            companyColumn,
            displayColumns,
            userCompanyTable: companyTable,
            loadedConfig: true
          });
        }
      }
    });
    return () => { };
  }, []);

  const preActions: AutoGridActions = [
    {
      icon: <Delete/>,
      title: 'Delete',
      hidden: () => !!props.readonly,
      onClick: (r) =>
        setState((old) => ({
          ...old,
          deleteId: r[userKey],
          deleteRow: r,
          editId: undefined,
          editRow: undefined
        }))
    },
    {
      icon: <Edit/>,
      title: 'Edit',
      hidden: () => !!props.readonly,
      onClick: (r) =>
        setState((old) => ({
          ...old,
          editId: r[userKey],
          editRow: r,
          deleteId: undefined,
          deleteRow: undefined
        }))
    }
  ];

  const handleSearchChange = (quickSearch: string) => setGridParams((old) => ({
    ...old,
    quickTextFilter: quickSearch.length === 0 ? undefined : quickSearch
  }));

  const handleUserAdd = (newId?: number) => {
    console.log('Login onSave', { newId });
    if (newId != null) {
      loadData(gridParams);
      setState((old) => ({ ...old, editId: newId }));
    }
  };

  const handleUserClose = () => setState((old) => ({
    ...old,
    editId: undefined
  }));

  const handleUserUpdate = (newId: number, result: AutoRow) => {
    console.log('Login Update', { newId, result });

    const newRows = cloneDeep(gridParams.rowData);
    newRows[newId] = {
      ...newRows[newId],
      ...result
    };

    setGridParams((old) => ({
      ...old,
      rowData: newRows,
      rowDataLastUpdate: moment().valueOf()
    }));

    handleUserClose();
  };

  const handleDeleteClose = () => setState((old) => ({
    ...old,
    deleteId: undefined,
    deleteRow: undefined
  }));

  const handleDeleteConfirm = (success: boolean) => {
    if (success) {
      const rows = cloneDeep(gridParams.rowData);
      delete rows[state.deleteId!];
      setGridParams((old) => ({
        ...old,
        rowData: rows,
        rowDataLastUpdate: moment().valueOf()
      }));
    }
    handleDeleteClose();
  };

  return (
    <>
      <div hidden={props.hidden}>
        <Grid container spacing={3}>
          <Grid className={clsx(classes.root, classes.searchRoot)} item xs={12} md={6} lg={4}>
            <TextField
              id="quick-search"
              label="Filter by Name"
              margin="dense"
              fullWidth
              value={gridParams.quickTextFilter ?? ''}
              onChange={(event) => handleSearchChange(event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.keyCode === 27) {
                  // esc pressed
                  handleSearchChange('');
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Icon aria-label="search">
                    <Search/>
                  </Icon>
                </InputAdornment>
              }}
            />
          </Grid>
          {props.companyId == null || !!props.readonly ? undefined : (
            <Grid className={clsx(classes.root, classes.searchRoot)} item xs={12} md={6} lg={4}>
              <UserAdd onSave={handleUserAdd} companyId={props.companyId}/>
            </Grid>
          )}
          <Grid item className={classes.autoGrid} xs={12}>
            <AutoGrid preActions={preActions} onRowClick={(e: AutoGridRowClickProps) => props.onUserSelected(parseInt(e.id, 10), e.name)} maxHeight="calc(100vh - 160px)" {...gridParams}/>
          </Grid>
        </Grid>
      </div>
      <AutoDeletePrompt
        baseTable={userCompanyTable}
        deleteId={state.deleteId}
        mode="direct"
        open={state.deleteId != null}
        rowData={state.deleteRow}
        key="userCompanyId"
        displayColumns={gridParams.displayColumns ?? []}
        keyField={gridParams.keyField}
        displayField={gridParams.displayField}
        onDelete={handleDeleteConfirm}
        onCancel={handleDeleteClose}
      />
      <AutoEditor
        mode="direct"
        open={state.editId != null}
        keyField={gridParams.keyField}
        baseTable={userCompanyTable}
        editId={state.editId}
        onSave={handleUserUpdate}
        onCancel={handleUserClose}
        // displayContext={{
        //   IsContactManager: { display: (p, c)=> c?.featureAccess?.includes('ContactGroups') ?? false },
        //   IsEquipmentManager: { display: (p, c)=> c?.featureAccess?.includes('SharedEquipment') ?? false }
        // }}
        company={props.companyId}
      />
    </>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(UserList);

import React from 'react';
import RosterPage from '../pages/training/RosterPage';
import UploadPage from '../pages/training/UploadPage';
import ReportsPage from '../pages/common/ReportsPage';
import WorkersPage from '../pages/workers/WorkersPage';
import SchedulePage from '../pages/training/SchedulePage';
import ReservesPage from '../pages/training/ReservesPage';
import CompaniesPage from '../pages/identity/CompaniesPage';
import CourseListPage from '../pages/courses/CourseListPage';
import AppSettingsPage from '../pages/common/AppSettingsPage';
import QuickLookupPage from '../pages/training/QuickLookupPage';
import BookTrainingPage from '../pages/training/BookTrainingPage';
import { ICompany, IProfileState } from '../stores/profile/types';
import WorkerHistoryPage from '../pages/training/WorkerHistoryPage';
import DailyListsPage from '../pages/training/checkin/DailyListsPage';
import CompanySettingsPage from '../pages/identity/CompanySettingsPage';
import WaitlistWorkersPage from '../pages/training/WaitlistWorkersPage';
import CompletedBookingPage from '../pages/training/CompletedBookingPage';
import ScheduledTrainingPage from '../pages/training/ScheduledTrainingPage';
import DataroweCustomReportPage from '../pages/special/DataroweCustomReportPage';
import ScheduledWebTrainingPage from '../pages/training/ScheduledWebTrainingPage';
import { AccountBalanceWallet, AddToQueue, Assessment, AssignmentInd, AssignmentLate, AssignmentTurnedIn, Ballot, Business, EventNote, ImportantDevices, LocalActivity, MeetingRoom, Person, Publish, RecordVoiceOver, Settings, SettingsApplications, Style } from '@mui/icons-material';

export interface INavigationOutlet {
  key: string;
  path: string;
  children: (INavigationLink | INavigationOutlet)[];
  display?: (profile: IProfileState, company?: ICompany) => boolean;
}

export interface INavigationLink extends Omit<INavigationOutlet, 'children'> {
  title: string;
  helpKey: string;
  element: React.ReactNode;
  children?: (INavigationLink | INavigationOutlet)[];
  forceManual?: boolean;
  icon?: React.ReactNode;
  index?: boolean;
}

export interface ICommonHelpItem {
  title: string;
  helpKey: string;
  icon: React.ReactNode;
}

export const links: (INavigationLink | INavigationOutlet)[] = [
  {
    key: 'workers',
    title: 'Workers',
    helpKey: 'F55DBC11-F50B-4F26-A273-87FB316142B2',
    icon: <Person/>,
    path: 'workers',
    element: <WorkersPage/>,
    children: [
      {
        key: 'scheduled-workers',
        title: 'Scheduled Workers',
        helpKey: 'CFB550E1-E00C-4887-B95B-BC3F9A3619FC',
        icon: <AssignmentInd/>,
        path: 'scheduled',
        element: <ScheduledTrainingPage/>
      },
      {
        key: 'scheduled-web-workers',
        title: 'Assigned Web Training',
        helpKey: '',
        icon: <ImportantDevices/>,
        path: 'web',
        element: <ScheduledWebTrainingPage/>
      },
      {
        key: 'waitlist-workers',
        title: 'Worker Waitlist',
        helpKey: '',
        icon: <LocalActivity/>,
        path: 'waitlist',
        display: (profile) => (profile.exclusions ?? []).indexOf('Waitlists') < 0,
        element: <WaitlistWorkersPage/>
      }
    ]
  },
  {
    key: 'courses',
    title: 'Courses',
    helpKey: '9070EDE4-B078-4ED3-B949-845308FD617B',
    icon: <Style/>,
    path: 'courses',
    display: (profile) => profile.isTrainingFacility,
    element: <CourseListPage/>,
    children: [
      {
        key: 'course-schedule',
        title: 'Course Schedule',
        helpKey: 'F5366FD7-D142-4980-8702-81AC469E560A',
        icon: <EventNote/>,
        path: 'schedule',
        element: <SchedulePage/>
      }
    ]
  },
  // {
  //   key: 'audits',
  //   title: 'Audit Schedule',
  //   helpKey: '',
  //   icon: <WorkHistory/>,
  //   path: 'audits',
  //   element: <AuditsPage/>,
  //   display: (profile) => profile.isTrainingFacility,
  // },
  {
    key: 'training-outlet',
    path: 'training',
    children: [
      {
        key: 'schedule-roster',
        title: 'Roster',
        helpKey: '799496D2-0AA8-4F64-807E-725B9452422B',
        forceManual: true,
        path: 'roster/:courseScheduleId',
        element: <RosterPage/>,
        display: (profile) => profile.isTrainingFacility
      },
      {
        key: 'checkin',
        title: 'Daily Check-In',
        helpKey: '84FEE8DA-5CD4-41C7-81A5-F4FDFC16FC10',
        icon: <AssignmentTurnedIn/>,
        path: 'checkin',
        element: <DailyListsPage pageType="checkin"/>,
        display: (profile) => profile.isTrainingFacility
      },
      {
        key: 'fittest',
        title: 'Daily Fit Tests',
        helpKey: '7B96BCAD-6F39-49EB-8E33-EB90E5E98F8E',
        icon: <RecordVoiceOver/>,
        path: 'fit-test',
        element: <DailyListsPage pageType="fittest"/>,
        display: (profile) => profile.isTrainingFacility
      },
      {
        key: 'book-training',
        title: 'Book Training',
        helpKey: '6882D761-2FDE-4079-B64C-5E16A099C69E',
        icon: <MeetingRoom/>,
        path: 'book',
        display: (profile) => profile.isEditor,
        element: <BookTrainingPage/>
      },
      {
        key: 'completed-booking',
        title: 'Completed Bookings',
        helpKey: '0BE2F14B-C31A-4AD2-9076-E802F0431AFD',
        icon: <AccountBalanceWallet/>,
        path: 'booked',
        element: <CompletedBookingPage/>
      },
      {
        key: 'reserved-seats',
        title: 'Reserved Seats',
        helpKey: 'F5697993-D018-42BF-9C34-B21F3D3924A2',
        icon: <AssignmentLate/>,
        path: 'reserved-seats',
        display: (profile) => profile.isPartner,
        element: <ReservesPage/>
      },
      {
        key: 'training-upload',
        title: 'Upload Training Records',
        helpKey: '5362F360-7407-4453-9178-DF4067BA8E4D',
        icon: <Publish/>,
        path: 'upload',
        display: (profile) => profile.companies.length > 0 && profile.isEditor,
        element: <UploadPage/>
      },
      {
        key: 'training-history',
        title: 'Training History',
        helpKey: 'D0E051F1-E748-4344-9FCA-805DAF8F9C3B',
        icon: <Ballot/>,
        path: 'history',
        element: <WorkerHistoryPage/>
      },
      {
        key: 'quick-lookup',
        title: 'Quick Card Lookup',
        helpKey: '599abd23-87f5-4d37-bbb3-0785c2b5eac0',
        icon: <AddToQueue/>,
        path: 'card-lookup',
        display: (profile) => profile.companies.filter(company => company.companyId === 1578 || company.companyId === 575).length > 0,
        element: <QuickLookupPage/>
      }
    ]
  },
  {
    key: 'companies',
    title: 'Companies',
    helpKey: 'BCBC4122-C152-4AC9-BD4C-C3F1A553B849',
    icon: <Business/>,
    path: 'companies',
    display: (profile) => profile.isTrainingFacility,
    element: <CompaniesPage/>
  },
  {
    key: 'custom-reports',
    title: 'Reports',
    helpKey: '1C667A32-A286-4160-88E2-D597255DB807',
    icon: <Assessment/>,
    path: 'reports',
    display: (profile) => profile.isTrainingFacility,
    element: <ReportsPage/>
  },
  {
    key: 'app-settings',
    title: 'Shared App Settings',
    helpKey: '6CB659BA-F934-40CA-94AC-CFD7EC63AA10',
    icon: <SettingsApplications/>,
    path: 'app-settings',
    display: (profile) => [49921, 1428, 50766, 50583, 420].includes(profile.userId),
    element: <AppSettingsPage/>
  }
];

export const companyLinks: INavigationLink[] = [
  {
    key: 'settings',
    title: 'Company Settings',
    helpKey: 'A6CBE5AC-5FAF-4B87-A1BC-4B65DC7834BA',
    icon: <Settings/>,
    path: 'company/:companyId/settings',
    element: <CompanySettingsPage/>
  },
  {
    key: 'datarowe-reports',
    title: 'Company Reports',
    helpKey: '',
    icon: <Assessment/>,
    display: (_profile, company) => [17753].includes(company?.companyId ?? -1),
    path: 'company/:companyId/reports',
    element: <DataroweCustomReportPage/>
  }
];

import { TextField } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

interface IDisplayFieldProps {
  classes: any;
  label: string;
  value?: any;
  fontScale?: number;
}

const displayField = (props: IDisplayFieldProps) => {
  return <TextField fullWidth label={props.label} value={props.value ?? ''} disabled/>;
};

export default withStyles(createStyles({}))(displayField);

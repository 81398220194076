import React from 'react';
import { TextField } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';

const styles = () => createStyles({});

interface ITextEditorProps extends IAutoEditorImplementation<string | undefined> {
  classes: any;
}

const TextEditor = (props: ITextEditorProps) => {
  const { field, editValue, error, warning, onValueUpdated } = props;
  const [value, setValue] = React.useState(props.editValue);

  React.useEffect(() => {
    setValue(editValue);
  }, []);

  React.useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  const handleFieldUpdate = (v: string) => setValue(v.length === 0 && !field.required ? undefined : v);

  const handleFieldBlur = () => onValueUpdated(value, field, value);

  return <TextField
    id={`singleLineTextEditor_${field.columnId}`}
    label={field.title}
    margin="dense"
    fullWidth
    multiline
    maxRows={4}
    required={field.required}
    value={value || ''}
    error={!!error}
    helperText={error || warning || field.description}
    disabled={props.disabled}
    onChange={event => handleFieldUpdate(event.currentTarget.value)}
    // when focus leaves, pass the value up
    onBlur={handleFieldBlur}
    // if user presses escape, cancel update to the value and reinstate the prop value
    onKeyDown={(event) => { if (event.keyCode === 27) setValue(editValue); }}
    InputProps={{ endAdornment: props.endAdornment }}
  />;
};

export default withStyles(styles)(TextEditor);

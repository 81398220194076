import moment from 'moment';
import { IAppState } from '../../../stores';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep, Dictionary } from 'lodash';
import settings from '../../../abstracts/settings';
import { AutoRow } from '../../../components/auto/AutoLoad';
import { IProfileState } from '../../../stores/profile/types';
import { stateDatabase } from '../../../stores/database/class';
import { IAlertDetails, UIActions } from 'src/stores/ui/types';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { courseSchedule, hubConnection, persons, profile } from './selectors';
import { IDatabasePost, IDatabaseState } from '../../../stores/database/types';
import { put, takeEvery, all, fork, select, call, takeLatest } from 'redux-saga/effects';
import { arrayMapObject, objectMap, objectMapArray } from '../../../abstracts/DataroweHelpers';
import { FilterGroupType, FilterConditionType, ISelectColumnMap, IApiResult } from '../../../stores/database/interfaces';
import { loadCourseCache, ICourseCache, formatFitTestSchedule, ICourse, formatTrainingTime } from '../../../stores/database/training/courses';
import { bookActionSetMessage, scheduleGetClasses, signalrHubVerifySession, signalrHubReserveClassSeats, hubClear, signalrHubAssignWebCourse } from './actions';
import { IAssignToClass, IAssignToWebCourse, ISessionWebTraining, IBookingSubmit, IHubReserveError, IHubScheduleResult, IPersonTraining, IQueryCourseClassResult, IQueryCourseScheduleProps, IReserveRequest, ISessionReserveWorker, ISubmitCompanyPay, ISubmitLineItem, ITrainingClassSchedule, IUpdateScheduleDateProps, IWebCourseRequest, TrainingBookActionTypes } from './types';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

function* handleScheduleSetDateRange(action: { type: TrainingBookActionTypes, payload: IUpdateScheduleDateProps }) {
  const { sessionId, newStart, newEnd, start, end, courseIds, fetchConfigRequest, refreshCacheData, createAlertBulk } = action.payload;

  if ((newStart != null && !newStart.isSame(start, 'day')) || (newEnd != null && !newEnd.isSame(end, 'day'))) {
    let [usedStart, usedEnd] = [newStart || start, newEnd || end];

    // If `newStart` is `null`, `newEnd` cannot be `null` (from above `if`)
    if (newStart == null && newEnd!.isBefore(start, 'day')) {
      const maxDays = newEnd!.diff(moment(), 'days');
      usedStart = moment(newEnd!).add(maxDays < 7 ? maxDays : 7, 'days');
    } else if (newEnd == null && newStart!.isAfter(end, 'day')) {
      usedEnd = moment(newStart!).add(7, 'days');
    }

    yield put({
      type: TrainingBookActionTypes.SCHEDULE_SET_DATE_END,
      payload: {
        sessionId,
        start: usedStart,
        end: usedEnd,
        oldStart: start,
        oldEnd: end
      }
    });

    if (sessionId > 0) {
      yield put(scheduleGetClasses({
        fetchConfigRequest,
        refreshCacheData,
        createAlertBulk,
        sessionId,
        courseIds: courseIds || [],
        startDate: usedStart,
        endDate: usedEnd
      }));
    }
  }
}

function* handleScheduleGetClasses(action: { type: TrainingBookActionTypes, payload: IQueryCourseScheduleProps }) {
  const { startDate, endDate, count, sessionId, fetchConfigRequest, refreshCacheData, createAlertBulk } = action.payload;

  if (startDate.isAfter(endDate)) {
    yield put(bookActionSetMessage({ type: 'Alert', message: `Unable to query course schedule; start (${startDate.format('YYYY-MM-DD')}) is after (${startDate.format('YYYY-MM-DD')})` }));
  } else {
    yield put({ type: TrainingBookActionTypes.SCHEDULE_GET_CLASSES_START });

    try {
      let db: IDatabaseState = yield select(stateDatabase);

      if (!db.loading && db.database.lastUpdated == null) {
        yield call(fetchConfigRequest);
        db = yield select(stateDatabase);
      }

      const baseTableName = ['training', 'CourseSchedule'];
      const baseTable = db.database.getTableByNames(baseTableName[0], baseTableName[1]);

      const courses: { courseCache: ICourseCache, courseRows: Dictionary<AutoRow> } = yield call(loadCourseCache, refreshCacheData, fetchConfigRequest, createAlertBulk);

      const [recordsUpload, courseId, trainingDateCol, trainingTimeCol, scheduleTypeKey, ...defaultColumns] = db.database.mapSelectColumnsByNamesWithId(
        baseTable.tableId,
        [
          'recordsUpload',
          'course.courseId',
          'trainingDate',
          'trainingTime',
          'course.courseCategory.courseType.scheduleType.key',
          'reservedCompany',
          'reservedCompany.name',
          'course.scheduleOnly',
          'course.code',
          'courseScheduleId',
          'room',
          'timeslotData',
          'room.location.name',
          'room.location.mapURL'
        ]);

      const post: IDatabasePost = {
        filter: {
          baseTableId: baseTable.tableId,
          name: '',
          baseFilter: {
            type: FilterGroupType.And,
            children: [
              {
                columnId: recordsUpload.columnId,
                lookupPath: recordsUpload.lookupPath || [],
                firstParameter: false,
                type: FilterConditionType.Equal
              },
              {
                columnId: trainingDateCol.columnId,
                lookupPath: trainingDateCol.lookupPath || [],
                firstParameter: startDate.format('YYYY-MM-DD'),
                secondParameter: endDate.format('YYYY-MM-DD'),
                type: FilterConditionType.Between
              },
              {
                columnId: scheduleTypeKey.columnId,
                lookupPath: scheduleTypeKey.lookupPath ?? [],
                type: FilterConditionType.IsOneOf,
                firstParameter: ['classroom', 'fittest', 'sma']
              },
              {
                columnId: trainingTimeCol.columnId,
                lookupPath: trainingTimeCol.lookupPath ?? [],
                type: FilterConditionType.IsNotNull
              }
            ]
          }
        },
        columns: Array<ISelectColumnMap>().concat(courseId, trainingDateCol, trainingTimeCol, defaultColumns)
      };

      console.log(`${API_ENDPOINT}/db/query/${baseTableName[0]}/${baseTableName[1]}?count=${count || 1000}`, post);

      const result: AxiosResponse<IApiResult<Dictionary<any>>> = (yield call(axios.post, `${API_ENDPOINT}/db/query/${baseTableName[0]}/${baseTableName[1]}`, post));

      const scheduleGet: ITrainingClassSchedule[] = yield select(courseSchedule);
      const schedules: ITrainingClassSchedule[] = [];

      const newSchedules: number[] = [];
      const removedSchedules: number[] = [];

      result.data.results.forEach((c: IQueryCourseClassResult) => {
        const idx = schedules.findIndex(x => x.courseScheduleId === c.courseScheduleId);
        const { trainingDate, trainingTime, ...info } = c;
        const dt = trainingTime == null ? moment(trainingDate) : moment(`${moment(c.trainingDate).format(settings.apiDateFormatMoment)} ${c.trainingTime}`);
        // const tm = moment(`1900-01-01 ${trainingTime}`);
        if (idx < 0) {
          newSchedules.push(c.courseScheduleId);
          schedules.push({
            ...info,
            course: courses.courseCache.courses.find(x => x.courseId === info['course.courseId'])!,
            location: info['room.location.name'],
            locationMap: info['room.location.mapURL'],
            trainingDate: dt,
            seats: undefined,
            attachedPersons: [],
            timeslotData: JSON.parse(c.timeslotData as unknown as string)
          });
        } else {
          schedules[idx] = {
            ...scheduleGet[idx],
            ...info,
            trainingDate: dt
          };
        }
      });

      scheduleGet.forEach((cs) => {
        if (schedules.findIndex(x => x.courseScheduleId = cs.courseScheduleId) < 0) {
          if (cs.attachedPersons.length === 0) {
            removedSchedules.push(cs.courseScheduleId);
          } else {
            schedules.push(cs);
          }
        }
      });

      yield put({ type: TrainingBookActionTypes.SCHEDULE_GET_CLASSES_END, payload: { schedules, courses: arrayMapObject(courses.courseCache.courses, (i, c) => [c.courseId, c]) } });
      const hub: HubConnection = yield select(hubConnection);

      signalrHubVerifySession(hub, sessionId, newSchedules, removedSchedules);
    } catch (error) {
      yield put(bookActionSetMessage({ type: 'Alert', message: error.toString() }));
    }
  }
}

function* handleScheduleAssignToClass(action: { type: TrainingBookActionTypes, payload: { sessionId: number; assignments: IAssignToClass[] } }) {
  const changes: IReserveRequest[] = action.payload.assignments.map(assignment => ({
    courseScheduleId: assignment.courseSchedule.courseScheduleId,
    personId: assignment.personId,
    userCompanyId: assignment.userCompanyId!,
    timeslotData: assignment.timeslotData
  }));

  yield put({ type: TrainingBookActionTypes.SCHEDULE_ASSIGN_TOCLASS_START, payload: action.payload.assignments });

  signalrHubReserveClassSeats(yield select(hubConnection), action.payload.sessionId, changes);
}

function* handleScheduleAssignWebCourse(action: { type: TrainingBookActionTypes, payload: { sessionId: number, assignments: IAssignToWebCourse[] } }) {
  const changes: IWebCourseRequest[] = action.payload.assignments.map(assignment => ({
    courseId: assignment.course.courseId,
    personId: assignment.personId,
    userCompanyId: assignment.userCompanyId
  }));

  yield put({ type: TrainingBookActionTypes.SCHEDULE_ASSIGN_TOWEB_START, payload: action.payload.assignments });
  signalrHubAssignWebCourse(yield select(hubConnection), action.payload.sessionId, changes);
}

function* handleHubVerifySession(action: { type: TrainingBookActionTypes, payload: IHubScheduleResult }) {
  yield put({
    type: TrainingBookActionTypes.BOOK_HUB_VERIFYSESSION_START, payload: {
      bookSessionId: action.payload.bookSessionId,
      reserveEndTime: moment().add(action.payload.expirySeconds, 'seconds')
    }
  });

  const schedules = cloneDeep((yield select(courseSchedule)) as ITrainingClassSchedule[]).map<ITrainingClassSchedule>(x => ({ ...x, attachedPersons: [] }));
  const workers = objectMap((yield select(persons)) as { [personId: number]: IPersonTraining }, (k, v: IPersonTraining): IPersonTraining => {
    return { ...cloneDeep(v), reservedClassTraining: {}, assignedWebTraining: {} };
  });

  const newWorkerIds = action.payload.sessionReserves.filter(x => workers[x.personId] == null).map(x => x.personId);
  action.payload.sessionWebTrainings.forEach((w) => {
    if (workers[w.personId] == null) {
      newWorkerIds.push(w.personId);
    }
  });

  if (newWorkerIds.length > 0) {
    const db: IDatabaseState = yield select(stateDatabase);
    const baseTableName = ['identity', 'worker'];
    const baseTable = db.database.getTableByNames(baseTableName[0], baseTableName[1]);
    const [iecNumber, displayName, photoUTC] = db.database.mapSelectColumnsByNamesWithId(
      baseTable.tableId,
      ['person', 'displayName', 'person.pictureTakenUTC']
    );

    const post: IDatabasePost = {
      filter: {
        baseTableId: baseTable.tableId,
        name: '',
        baseFilter: {
          columnId: iecNumber.columnId,
          lookupPath: iecNumber.lookupPath || [],
          firstParameter: newWorkerIds,
          type: FilterConditionType.IsOneOf
        }
      },
      columns: Array<ISelectColumnMap>().concat(iecNumber, displayName, photoUTC)
    };

    const result: AxiosResponse<IApiResult<Dictionary<any>[]>> = (yield call(axios.post, `${API_ENDPOINT}/db/query/${baseTableName[0]}/${baseTableName[1]}`, post));
    result.data.results.forEach((x) => {
      const iecNum: number = x[iecNumber.columnAlias];
      if (workers[iecNum] == null) {
        workers[iecNum] = {
          displayName: x[displayName.columnAlias],
          photoDate: x[photoUTC.columnAlias] == null ? undefined : moment(x[photoUTC.columnAlias]),
          courses: {},
          standards: {},
          dynamicStandards: {},
          reservedClassTraining: {},
          assignedWebTraining: {}
        };
      }
    });
  }

  const selfPay: ISubmitLineItem[] = [];
  const companyPay: Dictionary<ISubmitCompanyPay> = {};

  const courses: Dictionary<ICourse> = yield select((state: IAppState) => state.book.courses);
  const profileState: IProfileState = yield select(profile);

  const reserveIdStart: Dictionary<string> = {};

  action.payload.sessionReserves.forEach((sessionReserve) => {
    const pricePartner: number = sessionReserve.pricePartner ?? sessionReserve.price;
    const priceNonPartner: number = sessionReserve.priceNonPartner ?? sessionReserve.price;

    const courseSched = schedules.find(x => x.courseScheduleId === sessionReserve.courseScheduleId)!;
    const sched: ISessionReserveWorker = {
      pricePartner,
      priceNonPartner,
      bookSessionReserveId: sessionReserve.bookSessionReserveId,
      course: courseSched.course,
      trainingDate: courseSched.trainingDate,
      courseScheduleId: courseSched.courseScheduleId,
      userCompanyId: sessionReserve.userCompanyId,
      usesCompanyReserve: sessionReserve.usesCompanyReserve,
      selfPay: sessionReserve.selfPay,
      purchaseOrder: sessionReserve.purchaseOrder,
      waitlistStart: sessionReserve.waitlistStart,
      waitlistEnd: sessionReserve.waitlistEnd,
      status: 'complete',
      creditCard: sessionReserve.creditCard,
      timeslotData: typeof sessionReserve.timeslotData === 'string' ? JSON.parse(sessionReserve.timeslotData) : sessionReserve.timeslotData
    };

    workers[sessionReserve.personId].reservedClassTraining[`${sessionReserve.courseScheduleId}${sessionReserve.timeslotData == null ? '' : `_${sessionReserve.bookSessionReserveId}`}`] = sched;
    if (courseSched.attachedPersons.find(p => p.id === sessionReserve.personId) == null) {
      courseSched.attachedPersons.push({ id: sessionReserve.personId, display: workers[sessionReserve.personId].displayName });
    }

    reserveIdStart[sessionReserve.bookSessionReserveId] = formatTrainingTime(sched.trainingDate, undefined, sched.timeslotData);

    const lineItem: ISubmitLineItem = {
      pricePartner,
      priceNonPartner,
      key: `class_${sessionReserve.bookSessionReserveId}`,
      name: workers[sessionReserve.personId].displayName,
      title: sched.course.scheduleTypeKey === 'fittest' ? `${moment(sched.trainingDate).format(settings.dateFormatMoment)} ${sched.timeslotData?.map(s => formatFitTestSchedule(s, courses)).join('/')}` : `${formatTrainingTime(sched.trainingDate)}: ${sched.course.code}`,
    };

    if (sessionReserve.selfPay) {
      selfPay.push(lineItem);
    } else {
      const companyPayKey = `${sessionReserve.userCompanyId}_${sessionReserve.purchaseOrder ?? ''}`;
      if (companyPay[companyPayKey] == null) {
        companyPay[companyPayKey] = {
          key: companyPayKey,
          userCompanyId: sessionReserve.userCompanyId,
          company: profileState.companies.find(c => c.userCompanyId === sessionReserve.userCompanyId)?.name ?? 'No Company Specified',
          purchaseOrder: sessionReserve.purchaseOrder ?? '',
          creditCard: sessionReserve.creditCard,
          subtotalPartner: 0,
          subtotalNonPartner: 0,
          items: []
        };
      }

      companyPay[companyPayKey].items.push(lineItem);
      companyPay[companyPayKey].subtotalPartner += pricePartner;
      companyPay[companyPayKey].subtotalNonPartner += priceNonPartner;
    }
  });

  action.payload.sessionWebTrainings.forEach((webSession) => {
    const pricePartner: number = webSession.pricePartner ?? webSession.price;
    const priceNonPartner: number = webSession.priceNonPartner ?? webSession.price;
    const course = courses[webSession.courseId];

    const assign: ISessionWebTraining = {
      pricePartner,
      priceNonPartner,
      course,
      bookSessionWebTrainingId: webSession.bookSessionWebTrainingId,
      userCompanyId: webSession.userCompanyId,
      creditCard: webSession.creditCard,
      purchaseOrder: webSession.purchaseOrder,
      status: 'complete',
      connectedBookSessionReserveId: webSession.connectedBookSessionReserveId,
      connectedTrainingDate: webSession.connectedBookSessionReserveId == null ? undefined : reserveIdStart[webSession.connectedBookSessionReserveId]
    };

    workers[webSession.personId].assignedWebTraining[webSession.courseId] = assign;

    const payKey = `${webSession.userCompanyId}_${webSession.purchaseOrder ?? ''}`;

    if (companyPay[payKey] == null) {
      companyPay[payKey] = {
        key: payKey,
        userCompanyId: webSession.userCompanyId,
        company: profileState.companies.find(c => c.userCompanyId === webSession.userCompanyId)?.name ?? 'No Company Specified',
        purchaseOrder: webSession.purchaseOrder ?? '',
        creditCard: webSession.creditCard,
        subtotalPartner: 0,
        subtotalNonPartner: 0,
        items: []
      };
    }

    companyPay[payKey].items.push({
      pricePartner,
      priceNonPartner,
      key: `web_${webSession.bookSessionWebTrainingId}`,
      name: workers[webSession.personId].displayName,
      title: `Web Based Training - ${course.code}`,
    });

    companyPay[payKey].subtotalPartner += pricePartner;
    companyPay[payKey].subtotalNonPartner += priceNonPartner;
  });

  const submit: IBookingSubmit = {
    selfPay,
    payment: action.payload.payment,
    companyPay: objectMapArray(companyPay, (k, v) => v)
  };

  yield put({
    type: TrainingBookActionTypes.BOOK_HUB_VERIFYSESSION_END, payload: {
      schedules,
      workers,
      submit
    }
  });

  const alerts: IAlertDetails[] = [];
  (action.payload.sessionErrors ?? []).forEach(({ request, message }) => {
    const workerDisplay = request.personId < 0 ? 'Making Change' : `Booking ${workers[request.personId].displayName}`;
    let courseDisplay = '';
    if (request.courseId != null) {
      courseDisplay = ` into ${courses[request.courseId].title}`;
    } else if (request.courseScheduleId != null) {
      courseDisplay = ` into ${schedules.find(x => x.courseScheduleId === request.courseScheduleId)!.course.title}`;
    }
    alerts.push({
      autoDisplay: true,
      page: 'Book Training',
      title: 'Error Booking Training',
      description: `Error ${workerDisplay}${courseDisplay} - ${message}`,
      created: moment().format(settings.dateTimeFormatMoment),
      viewed: false,
      severity: 'error'
    });
  });
  (action.payload.sessionWarnings ?? []).forEach(({ message }) => alerts.push({
    autoDisplay: true,
    page: 'Book Training',
    title: 'Booking Training Warning',
    description: message,
    created: moment().format(settings.dateTimeFormatMoment),
    viewed: false,
    severity: 'warning'
  }));
  if (alerts.length > 0) {
    yield put({ type: UIActions.ALERT_CREATE_BULK, payload: alerts });
  }
}

function* handleHubBookingFailed(action: { type: TrainingBookActionTypes, payload: string }) {
  const alert: IAlertDetails = {
    autoDisplay: true,
    page: 'Book Training',
    title: 'Error Booking Training',
    description: `Unable to complete booking - ${action.payload}`,
    created: moment().format(settings.dateTimeFormatMoment),
    viewed: false,
    severity: 'error'
  };

  yield put({ type: UIActions.ALERT_CREATE_BULK, payload: [alert] });

}

function* handleHubDisconnect() {
  const hub: HubConnection | undefined = yield select(hubConnection);
  if (hub != null && hub.state === HubConnectionState.Connected) {
    yield hub.stop();
    yield put(hubClear());
    console.log('Successfully disconnected from training hub');
  }
}

function* handleAddErrors(action: { type: TrainingBookActionTypes, payload: IHubReserveError[] }) {
  yield put({
    type: UIActions.ALERT_CREATE_BULK,
    payload: action.payload.map(({ message }) => ({
      autoDisplay: true,
      page: 'Book Training',
      title: 'Error Booking Training',
      description: message,
      created: moment().format(settings.dateTimeFormatMoment),
      viewed: false,
      severity: 'error'
    }))
  });
}

function* watchScheduleSetDateRange() {
  yield takeLatest(TrainingBookActionTypes.SCHEDULE_SET_DATE_START, handleScheduleSetDateRange);
}

function* watchScheduleGetClasses() {
  yield takeEvery(TrainingBookActionTypes.SCHEDULE_GET_CLASSES, handleScheduleGetClasses);
}

function* watchScheduleAssignToClass() {
  yield takeEvery(TrainingBookActionTypes.SCHEDULE_ASSIGN_TOCLASS, handleScheduleAssignToClass);
}

function* watchScheduleAssignWebCourse() {
  yield takeEvery(TrainingBookActionTypes.SCHEDULE_ASSIGN_TOWEB, handleScheduleAssignWebCourse);
}

function* watchHubVerifySession() {
  yield takeLatest(TrainingBookActionTypes.BOOK_HUB_VERIFYSESSION, handleHubVerifySession);
}

function* watchHubBookingFailed() {
  yield takeLatest(TrainingBookActionTypes.BOOK_HUB_FAILED, handleHubBookingFailed);
}

function* watchHubDisconnect() {
  yield takeLatest(TrainingBookActionTypes.BOOK_HUB_DISCONNECT, handleHubDisconnect);
}

function* watchHubAddErrors() {
  yield takeEvery(TrainingBookActionTypes.BOOK_HUB_ADDERRORS, handleAddErrors);
}

export function* trainingBookingSaga() {
  yield all([
    fork(watchScheduleSetDateRange),
    fork(watchScheduleGetClasses),
    fork(watchScheduleAssignToClass),
    fork(watchScheduleAssignWebCourse),
    fork(watchHubVerifySession),
    fork(watchHubBookingFailed),
    fork(watchHubDisconnect),
    fork(watchHubAddErrors)
  ]);
}

import { createTheme, PaletteOptions, Theme } from '@mui/material';

//palette generated with https://codyhouse.co/ds/globals/colors
const lightModePalette: PaletteOptions = {
  background: {
    default: '#FAFAFA',
    paper: '#FFF'
  },
  mode: 'light',
  primary: {
    // BASES Blue: #004987
    contrastText: '#FFF',
    dark: '#003666', // 'hsl(208, 100%, 20%)',
    light: '#0057a3', // 'hsl(208, 100%, 32%)',
    main: '#004785' // 'hsl(208, 100%, 26%)'
  },
  secondary: {
    // based on IEC light blue, #6796CE
    // BASES Green: #00A98F
    contrastText: '#FFF',
    dark: '#008a75', // 'hsl(171, 100%, 27%)',
    light: '#00c7a9', // 'hsl(171, 100%, 39%)',
    main: '#00a88f' // 'hsl(171, 100%, 33%)'
  }
};

const darkModePalette: PaletteOptions = {
  background: {
    default: '#121212',
    paper: '#1E1E1E'
  },
  mode: 'dark',
  primary: {
    contrastText: '#FFF',
    dark: '#193675',
    light: '#5F7FCB',
    main: '#1C589F'
  },
  secondary: {
    contrastText: '#FFF',
    dark: '#33689D',
    light: '#99C6FF',
    main: '#6796CE'
  },
  text: {
    primary: '#FFF',
    secondary: 'rgba(255, 255, 255, 0.9)',
  }
};

export const generatePaperbaseTheme = (darkThemePreference: boolean): Theme => {
  let theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 961,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: darkThemePreference ? darkModePalette : lightModePalette,
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5
      },
      fontSize: 13
    },
    shape: {
      borderRadius: 8
    },
    mixins: {
      toolbar: {
        minHeight: 48
      }
    }
  });

  theme = {
    ...theme,
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'unset'
          }
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#4FC3F7'
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            '&:active': {
              boxShadow: 'none'
            },
            boxShadow: 'none'
          },
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: 'none'
          }
        }
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: 'rgba(255, 255, 255, 0.5)',
            margin: `0 ${theme.spacing(2)}`,
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up('lg')]: {
              minWidth: 0,
              padding: 0
            },
            textTransform: 'none',
            '&.Mui-selected': {
              color: theme.palette.primary.contrastText
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.common.white,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            height: 3
          },
          root: {
            marginLeft: theme.spacing(1),
            color: theme.palette.primary.contrastText
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1)
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
            fontSize: 12
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: '#404854',
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: theme.typography.fontWeightMedium // 500
          },
          secondary: {
            color: darkThemePreference ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)'
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            '& svg': {
              fontSize: 20
            },
            color: 'inherit',
            marginRight: 0
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 32,
            height: 32
          }
        }
      }
    }
  };

  if (darkThemePreference && typeof theme.components !== 'undefined') {
    theme.components.MuiTextField = {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#FFF'
          }
        }
      }
    };

    theme.components.MuiFormLabel = {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.6)',
          '&.Mui-focused ': {
            color: '#FFF'
          }
        }
      }
    };
  }

  return theme;
};

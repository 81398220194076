import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { ComponentType } from 'react';
import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { IColumn } from '../../../stores/database/interfaces';
import { IProfileState } from '../../../stores/profile/types';
import NumberEditor, { INumberFieldProps } from './NumberEditor';
import { getReserveLimit } from '../../../stores/database/training/courseSchedule';
import { mapProfileDbFromAppState, IDatabaseState } from '../../../stores/database/types';

const styles = () => createStyles({});

interface IConnectedProps {
  classes: any;
  db: IDatabaseState;
  profile: IProfileState;
}

const ReserveNumberEditor = (props: IAutoEditorImplementation<number | undefined> & IConnectedProps) => {
  const { db, profile, onValueUpdated, ...otherProps } = props;

  const [numberProps, setNumberProps] = React.useState<INumberFieldProps & { loaded: boolean }>({ min: 0, max: 0, loaded: false });

  const loadConfig = () => {
    const companyId = props.localState?.companyId;
    if (companyId != null) {
      getReserveLimit({ companyId, courseScheduleId: props.stateExtension?.reserveCourseScheduleId ?? -1 }).then(max => setNumberProps(old => ({ ...old, max, loaded: true })));
    } else if (props.editId != null) {
      getReserveLimit({ courseScheduleReserveId: props.editId, courseScheduleId: props.stateExtension?.reserveCourseScheduleId ?? -1 }).then(max => setNumberProps(old => ({ ...old, max, loaded: true })));
    } else {
      setNumberProps(old => ({ ...old, max: undefined, loaded: true }));
    }
  };

  React.useEffect(() => loadConfig(), [props.item]);

  const handleValueUpdated = (value: number | undefined, column: IColumn) => {
    if (value != null && value > (numberProps.max ?? 0)) {
      onValueUpdated(numberProps.max ?? 0, column, numberProps.max ? numberProps.max.toString() : '', undefined, `Max reserves available is ${numberProps.max ?? 0}`);
    } else {
      onValueUpdated(value, column, value ? value.toString() : '');
    }
  };

  return <NumberEditor
    {...otherProps}
    onValueUpdated={handleValueUpdated}
    min={numberProps.min}
    max={numberProps.max}
    warning={!numberProps.loaded ? 'Loading max seats...' : `Maximum ${numberProps.max} seats available`}
  />;
};

export default compose<ComponentType<IAutoEditorImplementation<number | undefined>>>(withStyles(styles), connect(mapProfileDbFromAppState))(ReserveNumberEditor);

import React from 'react';
import ContentMenu from './ContentMenu';
import axios, { AxiosResponse } from 'axios';
import { IWorkerWebView } from './WebCourseTypes';
import LoadingScreen from '../../common/LoadingScreen';
import { createStyles, withStyles } from '@mui/styles';
import WorkerCourseDisplay from './WorkerCourseDisplay';
import { Box, Theme, Typography, useTheme } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const generateSxClasses = (_theme: Theme) => {
  return {
    fullHeight: {
      height: '100vh'
    },
    fullHeightOverflow: {
      height: '100vh',
      overflow: 'hidden'
    },
    fullSize: {
      width: '100%',
      height: '100%',
      textAlign: 'center'
    },
    halfSize: {
      width: '100%',
      height: 'calc(50% - 64px)'
    }
  };
};

interface IProps {
  linkKey?: string;
  id?: string;
}

interface IConnectedProps {
  classes: any;
}

interface IWebCourseLaunchState {
  loaded: boolean;
  url?: string;
  error?: string;
  workerWebView?: IWorkerWebView;
  accepted: boolean;
  launched: boolean;
}

const WebCourseLaunch = (props: IProps & IConnectedProps) => {
  const sxClasses = generateSxClasses(useTheme());

  const [loadState, setLoadState] = React.useState<IWebCourseLaunchState>({
    loaded: false,
    accepted: false,
    launched: false
  });

  const loadWorkerWebView = () => {
    if (props.linkKey == null || props.id == null) {
      setLoadState({
        loaded: true,
        accepted: false,
        launched: false,
        url: undefined,
        error: 'Invalid URL. The URL is missing the "key" or "id".'
      });
    } else {
      axios.get(`${API_ENDPOINT}/training/CloudCourses/WorkerAssignments/${props.id}/${props.linkKey}`).then((res: AxiosResponse<IWorkerWebView>) => {
        const workerWebView = res.data;

        workerWebView.assignment.courseContents = workerWebView.assignment.courseContents.sort((a, b) => a.order - b.order);

        setLoadState({
          workerWebView,
          loaded: true,
          accepted: false,
          launched: false,
          url: undefined,
          error: undefined
        });
      }).catch((_err) => {
        setLoadState({
          loaded: true,
          accepted: false,
          launched: false,
          url: undefined,
          error: 'An error occurred. If this persists on reload, please contact BASES with the URL you are attempting to load.'
        });
      });
    }
  };

  const handleRunCourse = () => setLoadState(old => ({
    ...old,
    launched: true
  }));

  React.useEffect(() => {
    loadWorkerWebView();
    return () => {};
  }, [props.linkKey, props.id]);

  if (loadState.loaded) {
    if (loadState.launched && loadState.workerWebView != null) {
      return (
        <Box sx={{ ...sxClasses[loadState.url ? 'fullHeight' : 'fullHeightOverflow'] }}>
          <ContentMenu
            workerWebView={loadState.workerWebView}
            id={+props.id!}
            linkKey={props.linkKey!}
            onContentUpdate={(a, ret) => setLoadState(old => ({
              ...old,
              workerWebView: {
                ...old.workerWebView!,
                assignment: a
              },
              launched: !ret
            }))}
          />
        </Box>
      );
    }

    if (loadState.workerWebView == null) {
      return (
        <Box sx={{ ...sxClasses.fullHeight }}>
          <Box sx={{ ...sxClasses.fullSize }}>
            <Box sx={{ ...sxClasses.halfSize }}/>
            <Typography>{loadState.error ?? 'An error occurred.'}</Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ ...sxClasses.fullHeight }}>
        <WorkerCourseDisplay workerWebView={loadState.workerWebView} onRunCourse={handleRunCourse}/>
      </Box>
    );
  }

  return (
    <Box sx={{ ...sxClasses.fullHeight }}>
      <LoadingScreen helpText="Authorizing Course - please wait"/>
    </Box>
  );
};

export default withStyles(createStyles({}))(WebCourseLaunch);

import React from 'react';
import settings from './settings';
import { Dictionary } from 'lodash';
import moment, { Moment } from 'moment';
import { IDateRange } from '../components/common/DateRangeEdit';
import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { objectEach, TypePropertyExtension } from './DataroweHelpers';

const urlDateFormat = settings.apiDateFormatMoment;

export type SupportedAutoParamTypes = undefined | null | string | number | number[] | IDateRange | Moment;
export type AutoParamTypeName = 'string' | 'number' | 'numberArray' | 'IDateRange' | 'date';
export type URLLiteralTypes = string | null | undefined;
export type CustomValidator = (value: URLLiteralTypes, currentResults: any) => boolean;

export interface IParamConfiguration {
  type: AutoParamTypeName;
  defaultValue?: SupportedAutoParamTypes;
  allowedValues?: URLLiteralTypes[];
  validation?: CustomValidator;
  useDateValidation?: boolean;
}

const validators = {
  date: (value: URLLiteralTypes): boolean => moment(value).isValid()
};

const formatIDateRange = (dateRange: IDateRange): string => {
  const result: string[] = [];

  result.push(dateRange.includeNull ? 'Z' : '');

  //if equal dates, then single date passed; if both null then blank
  if (dateRange.startDate === dateRange.endDate && dateRange.startDate != null) {
    result.push(dateRange.startDate);
  } else {
    result.push(dateRange.startDate ?? '');
    result.push('.');
    result.push(dateRange.endDate ?? '');
  }

  return result.join('');
};

const readIDateRange = (range: string, defaultValue?: IDateRange): IDateRange => {
  let rangeRemainder = range;
  const result: IDateRange = {};

  while (rangeRemainder.length > 0) {
    if (rangeRemainder[0] === 'Z') {
      result.includeNull = true;
      rangeRemainder = rangeRemainder.substring(1);
    } else if (rangeRemainder[0] === '.') {
      result.endDate = rangeRemainder.substring(1);
      rangeRemainder = '';
    } else if (rangeRemainder[rangeRemainder.length - 1] === '.') {
      result.startDate = rangeRemainder.substring(0, rangeRemainder.length - 1);
      rangeRemainder = '';
    } else {
      const splitDates = rangeRemainder.split('.');
      result.startDate = splitDates[0];
      result.endDate = splitDates[1] ?? splitDates[0];
      rangeRemainder = '';
    }
  }

  if ((result.startDate != null && !moment(result.startDate, urlDateFormat).isValid()) || (result.endDate != null && !moment(result.endDate, urlDateFormat).isValid())) return defaultValue ?? {};

  return result;
};

export const setAutoParam = <T = Dictionary<SupportedAutoParamTypes>>(starterParams: URLSearchParams, paramObject: T, types: TypePropertyExtension<T, IParamConfiguration>, rootKey?: string): URLSearchParams => {
  objectEach(types, (key, { type }) => {
    const refKey = rootKey == null ? key : `${rootKey}_${key}`;

    if (paramObject[key] == null) {
      starterParams.delete(refKey);
    } else {
      switch (type as AutoParamTypeName) {
        case 'IDateRange':
          starterParams.set(refKey, formatIDateRange(paramObject[key] as IDateRange));
          break;
        case 'string':
        case 'number':
          starterParams.set(refKey, paramObject[key] as string);
          break;
        case 'numberArray': {
          const arrayConst = (paramObject[key] as number[]);
          if (arrayConst.length === 0) {
            starterParams.delete(refKey);
          } else {
            starterParams.set(refKey, arrayConst.join('.'));
          }
          break;
        }
        case 'date':
          starterParams.set(refKey, (paramObject[key] as Moment).format(urlDateFormat));
          break;
      }
    }
  });

  return starterParams;
};

export const formatAutoParam = <T = Dictionary<SupportedAutoParamTypes>>(params: URLSearchParams, types: TypePropertyExtension<T, IParamConfiguration>, rootKey?: string): [T, boolean] => {
  const result: T = {} as T;
  let forceUpdate = false;

  objectEach(types, (key, { type, defaultValue, allowedValues, ...options }: IParamConfiguration) => {
    const refKey = rootKey == null ? key : `${rootKey}_${key}`;
    const param: URLLiteralTypes = params.get(refKey);
    if (param == null) {
      if (defaultValue != null) {
        result[key] = defaultValue;
        if (!Array.isArray(defaultValue) || defaultValue.length > 0) {
          forceUpdate = true;
        }
      }
    } else if (allowedValues != null && allowedValues.length > 0 && allowedValues.indexOf(param) < 0) {
      result[key] = defaultValue;
      forceUpdate = true;
    } else if (
      (options.validation != null && !options.validation(param, result))
      || (options.useDateValidation && !validators.date(param))
    ) {
      result[key] = defaultValue;
      forceUpdate = true;
    } else {
      switch (type as AutoParamTypeName) {
        case 'IDateRange':
          result[key] = param == null ? defaultValue : readIDateRange(param);
          break;
        case 'string':
          result[key] = param ?? defaultValue;
          break;
        case 'number':
          result[key] = param == null ? defaultValue : parseInt(param, 10);
          break;
        case 'numberArray':
          result[key] = param?.split('.')?.map(i => parseInt(i)) ?? defaultValue;
          break;
        case 'date': {
          const dateParse = moment(param);
          result[key] = dateParse.isValid() ? dateParse : defaultValue;
          break;
        }
      }
    }
  });
  return [result, forceUpdate];
};

// based on https://reactrouter.com/docs/en/v6/examples/custom-query-parsing
// based on https://stackblitz.com/github/remix-run/react-router/tree/main/examples/custom-query-parsing?file=src/App.tsx
export const useQueryParams = <T = Dictionary<SupportedAutoParamTypes>>(types: TypePropertyExtension<T, IParamConfiguration>, rootKey?: string): [T, (newQuery: T, options?: NavigateOptions) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [valueMap, forceUpdate] = formatAutoParam<T>(searchParams, types, rootKey);

  if (forceUpdate) {
    setSearchParams(setAutoParam(new URLSearchParams(searchParams), valueMap, types, rootKey), { replace: true });
  }

  const value = React.useMemo(() => valueMap, [valueMap]);

  let setValue = React.useCallback((newValue: T, options?: NavigateOptions) => {
    setSearchParams(setAutoParam(new URLSearchParams(searchParams), newValue, types, rootKey), options);
  }, [searchParams, setSearchParams, types, rootKey]);

  return [value, setValue];
};

export interface ICustomNavigate {
  toWorker: (workerId: '' | number) => string;
  toWorkerFilter: (filter: string) => string;
  toCompany: (companyId: number) => string;
  toCourse: (courseId: number) => string;
  toBooking: (bookingId: number) => string;
  toRoster: (courseScheduleId: number) => string;
}

export const customNavigate: ICustomNavigate = {
  toWorker: (id) => `/workers?workerId=${id}`,
  toWorkerFilter: (filter) => `/workers?filter=${filter}`,
  toCompany: (id) => `/companies?companyId=${id}`,
  toCourse: (id) => `/courses?courseId=${id}`,
  toBooking: (id) => `/companies?companyId=${id}`,
  toRoster: (id) => `/companies?companyId=${id}`
};

import { Moment } from 'moment';
import { Dictionary } from 'lodash';
import { IAutoGridProps } from '../../components/auto/AutoGrid';

export interface ISchema {
  schemaId: number;
  name: string;
  title: string;
  deleted: boolean;
  properties: any;
  tables: { [x: number]: ITable };
  tableNames: { [x: string]: number };
}

interface IEditorSection {
  key: string;
  title: string;
  customView?: string;
  onlyTrainingFacility?: boolean;
  onlyPartner?: boolean;
}

export interface ITableEditorSection extends IEditorSection {
  fieldOrder: number[];
}

export interface IMultiLookupSection extends IEditorSection {
  lookupReferenceColumn: number;
  lookupDisplayColumn?: number;
  secureEdit?: boolean;
}

export type ISectionOrders = ITableEditorSection | IMultiLookupSection;

export interface ITableEditor {
  title: string;
  description: string;
  key: string;
  sectionOrder: ISectionOrders[];
}

export interface IColumnRefTableProperties {
  referenceAlternateEditor?: string;
  useReferenceAlternateReadonly?: boolean;
}

export interface ITableProperties extends IColumnRefTableProperties {
  editors?: ITableEditor[];
}

export const usedAlternateEditor = (props: IColumnProperties, readonly?: boolean) =>
  readonly && !props?.useAlternateReadonly && !props?.tableProperties?.useReferenceAlternateReadonly ? undefined
    : props?.alternateEditor ?? props?.tableProperties?.referenceAlternateEditor;


export interface ITable {
  tableId: number;
  schemaId: number;
  name: string;
  title: string;
  readonly: boolean;
  hidden: boolean;
  deleted: boolean;
  commentOnUpdate: boolean;
  commentOnDelete: boolean;
  properties: ITableProperties;
  columns: { [x: number]: IColumn };
  columnNames: { [x: string]: number };
  additionalColumns?: IColumnMap[];
  primaryKeyId?: number;
  displayKeyId: number;
  companyKeyId?: number;
  sharedKeyId?: number;
  deleteKeyId?: number;
}

export type SpecialReferenceType = 'IECNumber';
export type TableCellAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';
export interface IColumnProperties {
  alternateEditor?: string;
  useAlternateReadonly?: boolean;
  listEditorParentColumns?: IColumnMap[];
  displayMap?: IColumnMap;
  specialReferenceType?: SpecialReferenceType;
  defaultCellAlign?: TableCellAlign;
  tableProperties: IColumnRefTableProperties;
}

// export type PrimaryColumnType = 'Int' | 'SmallInt' | 'BigInt' | 'Decimal' | 'Money' | 'String' | 'Bool' | 'Date' | 'Time' | 'DateTime' | 'Lookup' | 'Json' | 'Unknown';
// export type VirtualColumnType = 'VirtualString' | 'VirtualInt' | 'VirtualBool' | 'VirtualDate' | 'VirtualTime' | 'VirtualDateTime';
export type ColumnType = 'Int' | 'SmallInt' | 'BigInt' | 'Decimal' | 'Money' | 'String' | 'Bool' | 'Date' | 'Time' | 'DateTime' | 'Lookup' | 'NestedLookup' | 'Json' | 'MultiLookup' | 'Markdown' | 'Unknown';
// type CustomEditorType = 'UnionSelect' | 'CompanySelect' | 'CourseInstructors';
export interface IColumn {
  columnId: number;
  columnType: ColumnType;
  // customEditorType?: CustomEditorType;
  tableId: number;
  referenceTableId?: number;
  name: string;
  title: string;
  description: string;
  order: number;
  readonly: boolean;
  required: boolean;
  viewSecurable: boolean;
  editSecurable: boolean;
  bulkSecurable?: boolean;
  // deleted: boolean;
  commentOnUpdate: boolean;
  commentOnDelete: boolean;
  properties: IColumnProperties;
}

export interface IColumnMap {
  columnId: number;
  lookupPath?: number[];
}

export interface ISelectColumnMap extends IColumnMap {
  columnAlias: string;
  columnTitle: string;
  columnType: ColumnType;
  cellAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  specialReferenceType?: SpecialReferenceType;
}

export const IColumnPropertyToSelectMap = (c: IColumn): Pick<ISelectColumnMap, 'specialReferenceType' | 'cellAlign' | 'columnType' | 'columnId'> => ({
  columnType: c.columnType,
  columnId: c.columnId,
  cellAlign: c.properties.defaultCellAlign ?? (() => {
    switch (c.columnType) {
      case 'Decimal':
      case 'SmallInt':
      case 'BigInt':
      case 'Int':
      case 'Money':
        return 'right';
      case 'Bool':
        return 'center';
      default:
        return 'left';
    }
  })(),
  specialReferenceType: c.properties.specialReferenceType

});

export interface ISelectDisplay extends IColumnMap {
  display?: any;
  columnType: ColumnType;
  columnTitle: string;
}

export const isSelectColumnMap = (obj: any): obj is ISelectColumnMap => <ISelectColumnMap>obj.columnType != null;
export const selectToColumnMap = (columns: ISelectColumnMap[]): IColumnMap[] => columns.map(col => col.lookupPath == null ? { columnId: col.columnId } : { columnId: col.columnId, lookupPath: col.lookupPath });

export type ColumnOrderType = 'Ascending' | 'Descending';
export interface IOrderMap extends IColumnMap {
  columnOrder: ColumnOrderType;
}

/**
 * potential properties for doing api lookups to the database with a hardcoded base table passed down
 */
export interface ISetSearchConfig {
  selectedColumns: IColumnMap[];
  orderColumns?: IOrderMap[];
}

/**
 * potential properties for doing api lookups to the database with a dynamic base table passed down
 */
export interface ISearchConfig extends ISetSearchConfig {
  baseTable?: number | string;
}

export interface IFilterDefinition {
  baseTableId: number;
  name: string;
  baseFilter: IFilterGroup | IFilterCondition;
}

// export type FilterGroupType = 'And' | 'Or' | 'Nand' | 'Nor';
export enum FilterGroupType {
  And = 'And',
  Or = 'Or',
  Nand = 'Nand',
  Nor = 'Nor'
}
export interface IFilterGroup {
  type: FilterGroupType;
  children: (IFilterGroup | IFilterCondition)[];
}

// export enum FilterConditionTypeNumeric

export enum FilterConditionType {
  Equal = 'Equal',
  // EqualDateIgnoreTime = 'EqualDateIgnoreTime',
  NotEqual = 'NotEqual',
  Between = 'Between',
  NotBetween = 'NotBetween',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  StartWith = 'StartWith',
  NotStartWith = 'NotStartWith',
  EndWith = 'EndWith',
  NotEndWith = 'NotEndWith',
  Contain = 'Contain',
  NotContain = 'NotContain',
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull',
  IsOneOf = 'IsOneOf',
  IsNotOneOf = 'IsNotOneOf',
  IsBlank = 'IsBlank',
  IsNotBlank = 'IsNotBlank',
  IsBlankOrNull = 'IsBlankOrNull',
  IsNotBlankOrNull = 'IsNotBlankOrNull'
}

export type FilterNullableType = FilterConditionType.IsNull | FilterConditionType.IsNotNull;
export type FilterNullableStringType = FilterConditionType.IsBlankOrNull | FilterConditionType.IsNotBlankOrNull;

export type FilterNumericType =
  FilterConditionType.Equal |
  FilterConditionType.NotEqual |
  FilterConditionType.Between |
  FilterConditionType.NotBetween |
  FilterConditionType.LessThan |
  FilterConditionType.LessThanOrEqual |
  FilterConditionType.GreaterThan |
  FilterConditionType.GreaterThanOrEqual;

export type FilterStringType =
  FilterConditionType.Equal |
  FilterConditionType.NotEqual |
  FilterConditionType.StartWith |
  FilterConditionType.NotStartWith |
  FilterConditionType.EndWith |
  FilterConditionType.NotEndWith |
  FilterConditionType.Contain |
  FilterConditionType.NotContain |
  FilterConditionType.IsBlank |
  FilterConditionType.IsNotBlank;

export type SupportedFilterTypes = string | Date | Moment | number | boolean;

export interface IFilterCondition {
  columnId: number;
  lookupPath: number[];
  type: FilterConditionType;
  forcedType?: ColumnType;
  firstParameter?: SupportedFilterTypes | SupportedFilterTypes[];
  secondParameter?: SupportedFilterTypes;
}

export interface IApiResult<T = any> {
  lastUpdated: number;
  results: T;
}

export type ICacheResult<T = any> = Promise<{
  // rows: AutoRow[];
  autoGridProps: IAutoGridProps;
  aliases: T;
}>;

export interface ICompanyAlias extends Dictionary<string> {
  companyId: string;
  displayName: string;
  memberId: string;
  roleName: string;
  reserveLimit: string;
  isTrainingFacility: string;
  isPartner: string;
  isUnion: string;
}

import { IAppState } from '../../../stores';
import { ITrainingClassSchedule } from './types';

export const persons = (state: IAppState) => state.book.personTraining;

export const courseSchedule = (state: IAppState): ITrainingClassSchedule[] => state.book.courseSchedule;

export const hubConnection = (state: IAppState) => state.book.hubConnection;

export const profile = (state: IAppState) => state.profile;

import React from 'react';
import moment from 'moment';
import Menu from '@mui/icons-material/Menu';
import axios, { AxiosResponse } from 'axios';
import * as signalR from '@microsoft/signalr';
import { ArrowForwardIos } from '@mui/icons-material';
import { createStyles, withStyles } from '@mui/styles';
import { useInterval } from '../../../abstracts/DataroweHelpers';
import WorkerPhoto from '../../../components/identity/WorkerPhoto';
import LoadingScreen from '../../../components/common/LoadingScreen';
import { IWorkerWebView, ICourseContent, IWorkerWebAssignment } from './WebCourseTypes';
import { Theme, Drawer, List, ListItem, AppBar, Toolbar, Grid, IconButton, Typography, ListItemText, ListItemSecondaryAction, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';
const HUB_ENDPOINT = process.env.REACT_APP_REG_HUB_URL || '';

const styles = (theme: Theme) => createStyles({
  pageHeader: {
    zIndex: 1101,
    maxWidth: '100vw'
  },
  courseTitle: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark
  }
});

interface IProps {
  workerWebView: IWorkerWebView;
  id: number;
  linkKey: string;
  onContentUpdate: (assignment: IWorkerWebAssignment, returnToOverview: boolean) => void;
}

interface IConnectedProps {
  classes: any;
}

interface IBuildLinkResponse {
  success: boolean;
  error?: string;
  url?: string;
}

const ContentMenu = (props: IProps & IConnectedProps) => {
  interface ILocalState {
    viewWelcome: boolean;
    visible: boolean;
    url?: string;
    errorMessage?: string;
    courseContentId?: number;
    connection?: signalR.HubConnection;
    connectionRefresh?: number;
    lastLoaded?: number;
  }

  const { classes, workerWebView } = props;

  const [state, setState] = React.useState<ILocalState>({
    visible: false,
    viewWelcome: workerWebView.assignment.courseId === 1612 && workerWebView.assignment.startDateUTC == null
  });

  // eslint-disable-next-line no-undef
  const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  const getCourseContentStatus = (c: ICourseContent) => {
    if (c.completeDateUTC != null) return `${(c.success ? 'Passed' : 'Failed')}${c.score == null ? '' : (` - ${c.score.toFixed(2)}%`)}`;

    if (c.startDateUTC != null) return 'In Progress';

    return 'Not Started';
  };

  const delayLoadContent = (callback: () => void) => {
    if (timerRef.current != null) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => callback, 500);
  };

  const loadCourseContent = (courseContentId: number) => {
    if (state.courseContentId === courseContentId || (state.lastLoaded != null && moment().unix() - state.lastLoaded < 2)) return;

    setState(old => ({
      ...old,
      courseContentId: courseContentId,
      url: undefined,
      errorMessage: undefined
    }));

    axios.post(`${API_ENDPOINT}/training/CloudCourses/BuildLink`, { id: props.id, key: props.linkKey, content: courseContentId })
      .then((res: AxiosResponse<IBuildLinkResponse>) => {
        if (res.data.error === 'An error occurred') {
          delayLoadContent(() => loadCourseContent(courseContentId));
        } else {
          setState((old) => {
            if (old.courseContentId === courseContentId) {
              return {
                ...old,
                url: res.data.url,
                errorMessage: res.data.error,
                lastLoaded: moment().unix(),
                visible: false
              };
            }

            return old;
          });
        }
      }).catch((err) => {
        console.log(err);

        setState(old => ({
          ...old,
          url: undefined,
          errorMessage: 'An error occurred. If this persists on reload, please contact BASES with the URL you are attempting to load.'
        }));
      });
  };

  const handleContentUpdate = (update: IWorkerWebAssignment, completedContentId?: number) => {
    // compare to active, see if changes to success, if yes progress to next
    if (workerWebView.assignment.completeDateUTC == null && update.completeDateUTC != null) {
      // return to main view since overall course changed to completed
      props.onContentUpdate(update, true);
    } else {
      if (completedContentId != null) {
        const currentIndex = update.courseContents.findIndex(c => c.courseContentId === completedContentId);
        if (currentIndex + 1 < update.courseContents.length) {
          loadCourseContent(update.courseContents[currentIndex + 1].courseContentId);
        }
      }
      props.onContentUpdate(update, false);
    }
  };

  const createHubConnection = async () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${HUB_ENDPOINT}/webcontent?webAssignId=${props.id}&linkKey=${props.linkKey}`, {})
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on('ContentUpdate', handleContentUpdate);

    try {
      await connection.start();
    } catch (error) {
      console.log(error);
    }

    setState(old => ({
      ...old,
      connection,
      connectionRefresh: 5000
    }));
  };

  React.useEffect(() => {
    const firstIncomplete = workerWebView.assignment.courseContents.find(a => a.completeDateUTC == null);
    if (firstIncomplete != null) {
      loadCourseContent(firstIncomplete.courseContentId);
    } else if (workerWebView.assignment.courseContents.length > 0) {
      loadCourseContent(workerWebView.assignment.courseContents[0].courseContentId);
    }
    createHubConnection();

    return () => {
      if (state.connection != null) {
        state.connection.stop();
      }
      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useInterval(() => {
    if (state.connection?.state !== signalR.HubConnectionState.Connected) {
      setState(old => ({
        ...old,
        connectionRefresh: undefined
      }));
      createHubConnection();
    }
  }, state.connectionRefresh);

  const handleFrameLoad = (frame: EventTarget & HTMLIFrameElement) => {
    try {
      if (frame.contentWindow?.location.href.endsWith('/runcoursecomplete')) {

        if (state.connection?.state === signalR.HubConnectionState.Connected) {
          // Complete postback to begin a manual verify of completion status
          state.connection.invoke('VerifyRegistration', props.workerWebView.assignment.workerWebAssignId, state.courseContentId);
        }

        console.log('Frame set to course complete', { workerWebAssignId: props.workerWebView.assignment.workerWebAssignId, courseContentId: state.courseContentId });
        const currentIter = workerWebView.assignment.courseContents.findIndex(c => c.courseContentId === state.courseContentId);
        if (currentIter >= 0 && currentIter + 1 < workerWebView.assignment.courseContents.length) {
          // delayLoadContent(() => loadCourseContent(workerWebView.assignment.courseContents[currentIter + 1].courseContentId));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const bodyContent = () => {
    if (state.courseContentId != null && state.url == null) {
      return <LoadingScreen/>;
    }

    if (state.url != null) {
      return <iframe title="Web Training Content" style={{ width: '99%', height: 'calc(100vh - 70px)', marginTop: 64, overflowY: 'hidden', overflowX: 'hidden' }} src={state.url} onLoad={frame => handleFrameLoad(frame.currentTarget)} />;
    }

    return <div style={{ width: '99%', height: 'calc(100vh - 70px)', marginTop: 64, overflowY: 'hidden', overflowX: 'hidden' }}>
      <Typography>Please select content from the left menu.</Typography>
    </div>;
  };

  const closeWelcomeMessage = () => setState(old => ({
    ...old,
    viewWelcome: false
  }));

  return (
    <>
      <Dialog open={state.viewWelcome} onClose={closeWelcomeMessage} maxWidth="md">
        <DialogTitle>
          Welcome
        </DialogTitle>
        <DialogContent>
          <p>Welcome!</p>
          <p>The online course you are about to begin is the first of a two-part regional safety orientation program. It was developed with the help of local Construction and Building Trades, Regional Contractors, and Member Companies.</p>
          <p>This online course provides you, as an adult learner, with increased flexibility to study at your own pace. You are encouraged to take microbreaks as you advance through the material.</p>
          <p>If you leave the program open and unattended for <i>more than an hour</i>, please &ldquo;refresh&rdquo; the page to ensure progress is properly saved.</p>
          <p>We hope you find the online course to be informative and look forward to welcoming  you to our BASES training facility in Sarnia for the in-class portion.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWelcomeMessage} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar position="fixed" className={classes.pageHeader}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => setState(old => ({ ...old, visible: !old.visible }))}
              >
                <Menu/>
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography color="inherit" variant="h6" component="h1">
                {workerWebView.displayName} ({workerWebView.iecNumber}) - {workerWebView.assignment.courseDisplay}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        onClose={() => setState(old => ({
          ...old,
          visible: !old.visible
        }))}
        open={state.visible}
      >
        <List disablePadding sx={{ marginBottom: 40, minWidth: 40 }}>
          <ListItem key="photo">
            <WorkerPhoto personId={workerWebView.iecNumber} readonly diameter={325} />
          </ListItem>
          <ListItem key="course_title" className={classes.courseTitle}>
            <ListItemText
              primary={workerWebView.assignment.courseDisplay}
              secondary={workerWebView.assignment.isPassed ? 'Complete' : 'In Progress'}
            />
          </ListItem>
          {workerWebView.assignment.courseContents.length > 1 ? workerWebView.assignment.courseContents.map((cc, idx, all) =>
            <ListItem
              button
              disabled={cc.completeDateUTC == null && idx > 0 && all[idx - 1].completeDateUTC == null}
              key={`content_${cc.courseContentId}`}
              onClick={() => loadCourseContent(cc.courseContentId)}
            >
              <ListItemText
                primary={`${idx + 1}. ${cc.title}`}
                secondary={getCourseContentStatus(cc)}
              />
              {(cc.courseContentId !== state.courseContentId ? undefined : <ListItemSecondaryAction><ArrowForwardIos/></ListItemSecondaryAction>)}
            </ListItem>
          ) : undefined}
        </List>
      </Drawer>
      {bodyContent()}
    </>
  );
};
export default withStyles(styles)(ContentMenu);

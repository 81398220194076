import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom';
import Roster from '../../components/training/Roster';
import { createStyles, withStyles } from '@mui/styles';
import { Paper, Theme, Typography } from '@mui/material';
import { fetchConfigRequest } from '../../stores/database/actions';
import { mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  printPaper: {
    margin: 'auto',
    width: '9.4in'
  },
  printButton: {
    position: 'relative',
    left: -60
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  }
});

interface IConnectedProps {
  classes: any;
  db: IDatabaseState;
  fetchConfigRequest: typeof fetchConfigRequest;
}

class ComponentToPrint extends React.Component<{ scheduleId: number }, { /*inPrint: boolean*/ }> {
  // constructor(props: any) {
  //   super(props);
  //   this.state = { inPrint: false };
  // }

  // setAsPrint(inPrint: boolean) {
  //   this.setState({ inPrint });
  // }

  render() {
    return <Roster courseScheduleId={this.props.scheduleId} maxHeight={'auto'} />;
    // return <Roster courseScheduleIds={this.props.scheduleIds} maxHeight="calc(100vh - 100px)" />;
  }
}

const RosterPage = (props: IConnectedProps) => {
  const { classes } = props;
  const { courseScheduleId } = useParams();

  const componentRef = React.useRef<ComponentToPrint>();
  return (
    <Paper className={classes.printPaper}>
      <div className={classes.contentWrapper}>
        {courseScheduleId == null || isNaN(parseInt(courseScheduleId)) ? <Typography>Unable to open roster; invalid id</Typography> : <>
        <ReactToPrint
          trigger={() => <button className={classes.printButton}>Print</button>}
          content={() => componentRef.current!}
          pageStyle="margin: 0.25in 0.1in 0.1in 0.1in; font-size: 30% !important;"
          // onBeforeGetContent={() => componentRef.current!.setAsPrint(true)}
          // onAfterPrint={() => componentRef.current!.setAsPrint(false)}
        />
        <ComponentToPrint scheduleId={+courseScheduleId} ref={el => (componentRef.current = el as ComponentToPrint)} /></>}
      </div>
    </Paper>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(RosterPage);

import { IAppState } from '..';
import moment, { unitOfTime } from 'moment';
import { refreshCacheData } from './actions';
import { IDatabaseCache, IDatabaseState } from './types';
import { objectEach } from '../../abstracts/DataroweHelpers';
import { IFilterDefinition, ISelectColumnMap } from '../../stores/database/interfaces';
import { ISchema, ITable, IColumn, IColumnMap, IColumnPropertyToSelectMap } from './interfaces';
import { ICustomColumnDefinition, ICustomSelectColumnMap } from '../../components/auto/AutoLoad';

export const stateDatabase = (state: IAppState): IDatabaseState => state.db;

export class Database {// extends ApiResponse {
  public constructor(json: any, resetCache?: boolean) {
    // build the schema and other pieces with the raw json; this should be stored in-memory for easy use in all components
    this.schemas = json.schemas;
    this.schemaNames = json.schemaNames;
    this.tableIdToSchemaId = json.tableIdToSchemaId;
    this.columnIdToTableId = json.columnIdToTableId;
    this.lastUpdated = resetCache ? undefined : json.lastUpdated;
    this.cacheData = resetCache ? {} : json.cacheData ?? {};
  }

  private schemas: { [x: number]: ISchema };

  private schemaNames: { [x: string]: number };

  tableIdToSchemaId: { [x: number]: number };

  columnIdToTableId: { [x: number]: number };

  readonly lastUpdated: number;

  private cacheData: { [x: string]: IDatabaseCache; };

  getSchemaById(id: number): ISchema {
    return this.schemas[id];
  }

  getTableById(id: number): ITable {
    return this.schemas[this.tableIdToSchemaId[id]].tables[id];
  }

  getColumnById(id: number): IColumn {
    return this.getTableById(this.columnIdToTableId[id]).columns[id];
  }

  getSchemaByName(schema: string): ISchema {
    return this.schemas[this.schemaNames[schema.toLowerCase()]];
  }

  getTableByNames(schema: string, table: string): ITable {
    const s = this.getSchemaByName(schema);
    return s.tables[s.tableNames[table.toLowerCase()]];
  }

  getTableByCombinedName(name: string) {
    const [schema, table] = name.split('.');
    return this.getTableByNames(schema, table);
  }

  tableExistsByCombinedName(name: string): boolean {
    const [schema, table] = name.split('.');
    const schemaId = this.schemaNames[schema.toLowerCase()];
    if (schemaId == null) return false;
    return this.schemas[schemaId].tableNames[table.toLowerCase()] != null;
  }

  getCombinedTableNameById(tableId: number): string {
    const t = this.getTableById(tableId);
    const s = this.getSchemaById(t.schemaId);
    return this.createMapName([s.name, t.name]);
  }

  getCombinedColumnNameById(columnId: number): string {
    const c = this.getColumnById(columnId);
    const t = this.getTableById(c.tableId);
    const s = this.getSchemaById(t.schemaId);
    return this.createMapName([s.name, t.name, c.name]);
  }

  getColumnByNames(schema: string, table: string, column: string): IColumn {
    const t = this.getTableByNames(schema, table);
    return t.columns[t.columnNames[column.toLowerCase()]];
  }

  getColumnByNameTableId(table: number, column: string): IColumn {
    const t = this.getTableById(table);
    return t.columns[t.columnNames[column.toLowerCase()]];
  }

  generateFilterFromJson(json: any): IFilterDefinition {
    // if ever a need to alter when loading a definition, is now setup for a single point of entry
    return json;
  }

  mapSelectColumnObject(tableId: number, columns: { [name: string]: string | string[] }): { names: { [name: string]: string; }; array: ISelectColumnMap[], object: { [name: string]: ISelectColumnMap; }; } {
    const res = {
      names: {},
      array: <ISelectColumnMap[]>[],
      object: {}
    };
    objectEach(columns, (key, column) => {
      const map = this.mapSelectColumnsByNamesWithId(tableId, [column])[0];
      res.array.push(map);
      res.object[key] = map;
      res.names[key] = map.columnAlias;
    });

    return res;
  }

  private createMapName = (lookups: string[], column?: string) =>
    column === undefined ? (lookups ?? []).slice(0).map(x => `${x[0].toLowerCase()}${x.slice(1)}`).join('.')
      : (lookups ?? []).slice(0).concat(column).map(x => `${x[0].toLowerCase()}${x.slice(1)}`).join('.');

  mapSelectLookupColumn(column: IColumn): ISelectColumnMap {
    if (column.properties.displayMap == null) {
      const t = this.getTableById(column.referenceTableId!);
      const c = t.columns[t.displayKeyId!];

      return {
        ...IColumnPropertyToSelectMap(column),
        lookupPath: [column.columnId],
        columnId: c.columnId,
        columnAlias: this.createMapName([column.name], c.name),
        columnTitle: `${column.title}`
      };
    }
    return this.mapSelectColumnMap(column.tableId, column.properties.displayMap);
  }

  mapSelectColumnMap(tableId: number, map: IColumnMap): ISelectColumnMap {
    const t = this.getTableById(tableId);
    const lookupName: string[] = [];
    const lookupTitle: string[] = [];
    let lastTable = t;
    for (let index = 0; index < (map.lookupPath?.length ?? 0); index++) {
      const col = this.getColumnById(Math.abs(map.lookupPath![index]));
      if (col.tableId !== lastTable.tableId && col.referenceTableId !== lastTable.tableId) {
        throw new Error(`Unable to map by Column Map, table id mismatch - ${map.lookupPath![index]} expected table id ${lastTable.tableId}, found ${col.tableId}${col.referenceTableId == null ? '' : ` and ${col.referenceTableId}`}`);
      }

      if (col.referenceTableId === lastTable.tableId) { // if referencing to the last table, is a 'negative' map.
        lastTable = this.getTableById(col.tableId);
        lookupName.push(lastTable.name);
        lookupTitle.push(lastTable.title);
      } else {
        lastTable = this.getTableById(col.referenceTableId!);
        lookupName.push(col.name);
        lookupTitle.push(col.title);
      }
    }
    const c = lastTable.columns[map.columnId];
    // lookupTitle.push(c.title);
    return {
      ...IColumnPropertyToSelectMap(c),
      ...map,
      columnAlias: this.createMapName(lookupName, c.name),
      columnTitle: lookupTitle.join(' ')
    };
  }

  mapSelectColumnsByNamesWithId(tableId: number, columns: (string[] | string)[] | string): ISelectColumnMap[] {
    const tbl = this.getTableById(tableId);
    return (typeof columns === 'string' ? [columns] : columns).map((columnMapName): ISelectColumnMap => {
      try {
        if (Array.isArray(columnMapName)) {
          const lookupPath: number[] = [];
          const lookupName: string[] = [];
          const lookupTitle: string[] = [];
          let col: IColumn;
          let lastTable = tbl;
          for (let index = 0; index < columnMapName.length; index++) {
            const absoluteColumnName = columnMapName[index];
            // }
            // columnMapName.forEach((absoluteColumnName, idx) => {
            const [s, t, c] = absoluteColumnName.split('.');
            col = this.getColumnByNames(s, t, c);
            if (col.tableId !== lastTable.tableId && col.referenceTableId !== lastTable.tableId) {
              throw new Error(`Unable to map by absolute names, table id mismatch - ${index === 0 ? 'Base Table' : absoluteColumnName[index - 1]} > ${absoluteColumnName} expected table id ${lastTable.tableId}, found ${col.tableId}${col.referenceTableId == null ? '' : ` and ${col.referenceTableId}`}`);
            }
            // if the last item, then it's the actual column to be displayed
            if (index < columnMapName.length - 1) {
              if (col.referenceTableId === lastTable.tableId) { // if referencing to the last table, is a 'negative' map.
                lastTable = this.getTableById(col.tableId);
                lookupName.push(lastTable.name);
                lookupPath.push(col.columnId * -1);
                lookupTitle.push(lastTable.title);
              } else {
                lastTable = this.getTableById(col.referenceTableId!);
                lookupName.push(col.name);
                lookupPath.push(col.columnId);
                lookupTitle.push(col.title);
              }
            }

          } // );
          lookupTitle.push(col!.title);
          return {
            ...IColumnPropertyToSelectMap(col!),
            lookupPath,
            columnAlias: this.createMapName(lookupName, col!.name),
            columnTitle: lookupTitle.join(' ')
          };
        }
        const colSplit: string[] = columnMapName.toLowerCase().split('.');
        if (colSplit.length === 1) {
          const foundCol = tbl.columns[tbl.columnNames[colSplit[0]]];

          return {
            ...IColumnPropertyToSelectMap(foundCol),
            columnAlias: foundCol.name[0].toLowerCase() + foundCol.name.slice(1),
            columnTitle: foundCol.title
          };
        }
        let lookupTable = tbl;
        const lookupPath: number[] = [];
        const lookupName: string[] = [];
        for (let index = 0; index < colSplit.length - 1; index++) {
          try {
            const colId = lookupTable.columnNames[colSplit[index]];
            lookupPath.push(colId);
            lookupName.push(lookupTable.columns[colId].name);
            lookupTable = this.getTableById(lookupTable.columns[colId].referenceTableId!);
          } catch (error) {
            console.log(`Error mapping column - ${lookupName.join('.')}/${colSplit.join('.')} failed with the lookup of ${colSplit[index]} on ${lookupTable.name}`);
            throw error;
          }
        }
        const finalColId = lookupTable.columnNames[colSplit[colSplit.length - 1]];
        return {
          ...IColumnPropertyToSelectMap(lookupTable.columns[finalColId]),
          lookupPath,
          columnAlias: this.createMapName(lookupName, lookupTable.columns[finalColId].name),
          columnTitle: lookupTable.columns[finalColId].title
        };

      } catch (error) {
        console.log(`Unable to map columns for table id ${tableId}: ${JSON.stringify(columnMapName)}`, { columnMapName, error, table: tbl });
        throw error;
      }
    });
  }

  mapSelectColumnsByNamesWithNames(schema: string, table: string, columns: string[] | string): ISelectColumnMap[] {
    return this.mapSelectColumnsByNamesWithId(this.getTableByNames(schema, table).tableId, columns);
  }

  mapColumnToSelectColumnMap(column: IColumn): ISelectColumnMap {
    return {
      ...IColumnPropertyToSelectMap(column),
      lookupPath: [],
      columnAlias: this.createMapName([column.name]),
      columnTitle: column.title
    };
  }

  mapKeySelectColumnByIds(columnIds: number | number[]): ISelectColumnMap | ISelectColumnMap[] {
    if (Array.isArray(columnIds)) return columnIds.map(c => this.mapColumnToSelectColumnMap(this.getColumnById(c)));
    return this.mapColumnToSelectColumnMap(this.getColumnById(columnIds));
  }

  mapDisplayColumnsByNamesWithId(tableId: number, columns: ICustomColumnDefinition[] | ICustomColumnDefinition): ICustomSelectColumnMap[] {
    // const t = this.getTableById(tableId);
    return (Array.isArray(columns) ? columns : [columns]).map((col): ICustomSelectColumnMap => {
      const foundCol = this.mapSelectColumnsByNamesWithId(tableId, [col.key])[0];
      return {
        ...col,
        ...foundCol,
        cellAlign: col.cellAlign ?? foundCol.cellAlign
      };
    });
  }

  mapDisplayColumnsByNamesWithNames(schema: string, table: string, columns: ICustomColumnDefinition[] | ICustomColumnDefinition): ISelectColumnMap[] {
    return this.mapDisplayColumnsByNamesWithId(this.getTableByNames(schema, table).tableId, columns);
  }

  getColumnsByTableId(table: number, filterColumnIds?: (string | number)[]): IColumnMap[] {
    return this.getColumnsByTable(this.getTableById(table), filterColumnIds);
  }

  private getMapIdsString(column: IColumnMap): string {
    return column.lookupPath == null ? `${column.columnId}` : `${column.lookupPath.join('.')}.${column.columnId}`;
  }

  private getColumnsByTable(table: ITable, filterColumnIds?: (string | number)[]): IColumnMap[] {
    const columns: IColumnMap[] = [];
    Object.keys(table.columns).forEach((colId) => {
      if (filterColumnIds != null && filterColumnIds.filter(x => x.toString() === colId).length === 0) return;
      const c: IColumn = table.columns[colId];
      columns.push({
        columnId: c.columnId,
        lookupPath: []
      });
    });
    if (table.additionalColumns) {
      Object.keys(table.additionalColumns).forEach((colId) => {
        const idString = this.getMapIdsString(table.additionalColumns![colId]);
        if (filterColumnIds != null && filterColumnIds.filter(x => x.toString() === idString).length !== 0) return;
        columns.push(table.additionalColumns![colId]);
      });
    }
    return columns;
  }

  getApiConfigByTable(tableId: number): { schema: string, table: string } {
    return {
      schema: this.schemas[this.tableIdToSchemaId[tableId]].name,
      table: this.getTableById(tableId).name
    };
  }

  getCacheData<T>(refresh: typeof refreshCacheData, key: string, getLimit: { amount: number; unit: unitOfTime.DurationConstructor, from: 'access' | 'load' }[], loadData: () => Promise<T>, forceRefresh?: boolean): Promise<T> {
    return new Promise((resolve, reject) => {
      const cache = Object.prototype.hasOwnProperty.call(this.cacheData, key) ? this.cacheData[key] : undefined;
      // if the cache doesn't exist, or it fails any of the time limits, reload
      if (forceRefresh || cache == null || getLimit.reduce(
        (a, b) => {
          if (b.from == 'access') {
            return a || moment.unix(cache.lastAccessed).add(b.amount, b.unit).isBefore(moment());
          }
          return a || moment.unix(cache.lastLoaded).add(b.amount, b.unit).isBefore(moment());
        },
        false)) {
        loadData().then((res) => {
          // this.cacheData[key] = {
          //   data: res,
          //   lastAccessed: moment().valueOf(),
          //   lastLoaded: moment().valueOf()
          // };
          refresh(key, res);
          resolve(res);
        }).catch(reject);
      } else {
        // this.cacheData[key].lastAccessed = moment().valueOf();
        refresh(key);
        resolve(this.cacheData[key].data);
      }
    });
  }

  updateCacheData(key: string, cacheData?: any): Database {
    if (cacheData == null) {
      this.cacheData[key].lastAccessed = moment().valueOf();
    } else {
      this.cacheData[key] = { data: cacheData, lastAccessed: moment().valueOf(), lastLoaded: moment().valueOf() };
    }
    return this;
  }

}

import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import QRCode from 'qrcode.react';
import settings from '../../abstracts/settings';
import { green, red } from '@mui/material/colors';
import { Check, Close } from '@mui/icons-material';
import { createStyles, withStyles } from '@mui/styles';
import WorkerPhoto from '../../components/identity/WorkerPhoto';
import { recursiveCompare } from '../../abstracts/DataroweHelpers';
import { Box, Typography, Grid, FormControlLabel, Switch, Theme, useTheme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  inactive: {
    color: theme.palette.grey[400]
  },
  rightAlign: {
    textAlign: 'right'
  },
  centerAlign: {
    textAlign: 'center'
  },
  companyName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  verticalDense: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  }
});

export interface ICardTraining {
  key: string;
  code: string;
  title: string;
  detailNote?: string;
  expiry?: string;
  delivery: string;
  instructors: string;
  respiratorSize?: string;
  respiratorFactor?: string;
  activeCourse: boolean;
  requirementKeys: string[];
}

export interface ICardDisplay {
  displayName: string;
  iecNumber: number;
  hasWHMIS: boolean;
  pictureTakenUTC: string;
  training: ICardTraining[];
}

export type CardDisplayViewMode = 'detail' | 'dense';

interface IRequirement {
  title: string,
  keys: string[]
}

export interface IWorkerCardDisplayProps {
  classes: any;
  card: ICardDisplay;
  QRUrl?: string;
  quickNotify?: {
    title: string,
    requirements: IRequirement[]
  };
  viewMode?: CardDisplayViewMode;
  onToggleViewMode?: (mode: CardDisplayViewMode) => void;
}

const WorkerCardDisplay = (props: IWorkerCardDisplayProps) => {
  const theme = useTheme();
  const { classes, card } = props;

  const trainingDenseDisplay = (training: ICardTraining) => <React.Fragment key={training.key}>
    <Grid item xs={8}>
      <Typography className={!training.activeCourse ? classes.inactive : undefined}>
        {training.title}{training.activeCourse ? '' : ' (inactive)'}
      </Typography>
    </Grid>
    <Grid item xs={4} className={classes.rightAlign}>
      <Typography className={!training.activeCourse || training.expiry == null ? classes.inactive : undefined}>
        {training.expiry == null ? 'No Expiry' : moment(training.expiry, settings.apiDateFormatMoment).format(settings.dateFormatMoment)}
      </Typography>
    </Grid>
  </React.Fragment>;

  const trainingDetailDisplay = (training: ICardTraining) => <React.Fragment key={training.key}>
    <Grid item xs={12} key="title" className={!training.activeCourse ? classes.inactive : undefined}>
      <Typography variant="h5">{training.title}{training.activeCourse ? '' : ' (inactive)'}</Typography>
    </Grid>
    {training.detailNote == null ? undefined : <Grid item xs={12} key="note"><Typography variant="body2" key="note">{training.detailNote}</Typography></Grid>}
    <Grid item xs={5} key="issue_label">
      <Typography variant="subtitle2">Issue Date</Typography>
    </Grid>
    <Grid item xs={7} key="issue_date">
      <Typography variant="subtitle1" className={classes.rightAlign}>{training.delivery ? moment(training.delivery, settings.apiDateFormatMoment).format(settings.dateFormatMoment) : 'N/A'}</Typography>
    </Grid>
    <Grid item xs={5} key="expiry_label">
      <Typography variant="subtitle2">Expiry Date</Typography>
    </Grid>
    <Grid item xs={7} key="expiry_date">
      <Typography variant="subtitle1" className={classes.rightAlign}>
        {training.expiry == null ? 'No Expiry' : `${moment(training.expiry, settings.apiDateFormatMoment).format(settings.dateFormatMoment)}`}
      </Typography>
    </Grid>
    <Grid item xs={5} key="instructor_label">
      <Typography variant="subtitle2">Instructor/Evaluator</Typography>
    </Grid>
    <Grid item xs={7} key="instructor_names">
      {(JSON.parse(training.instructors ?? '["N/A"]') as string[]).map(instructor => <Typography key={instructor} variant="subtitle1" className={classes.rightAlign}>{instructor}</Typography>)}
    </Grid>
    {training.respiratorSize == null ? undefined : <React.Fragment key="respirator_size">
      <Grid item xs={5}>
        <Typography variant="subtitle2">Respirator Size</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="subtitle1" className={classes.rightAlign}>{training.respiratorSize}</Typography>
      </Grid>
    </React.Fragment>}
    {training.respiratorFactor == null ? undefined : <React.Fragment key="respirator_factor">
      <Grid item xs={5}>
        <Typography variant="subtitle2">Respirator Factor</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="subtitle1" className={classes.rightAlign}>{training.respiratorFactor}</Typography>
      </Grid>
    </React.Fragment>}
    <Grid key="hr" item xs={12}>
      <hr />
    </Grid>
  </React.Fragment>;

  const handleViewModeToggle = () => props.onToggleViewMode!(props.viewMode === 'detail' ? 'dense' : 'detail');

  return <Grid key="displaycardcontent" container spacing={3}>
    {props.QRUrl == null ? undefined :
      <Grid key="org-title" className={clsx(classes.centerAlign, classes.verticalDense)} item xs={12}>
        <Typography variant="overline" className={classes.companyName}>Sarnia-Lambton Industrial Educational Co-operative</Typography>
      </Grid>
    }
    <Grid key="photo" className={classes.centerAlign} item xs={12}>
      <WorkerPhoto personId={card.iecNumber} photoTakenUTC={card.pictureTakenUTC} diameter={325} />
    </Grid>
    {props.quickNotify == null ? undefined :
      <React.Fragment key="quick-notify">
        <Grid key="quick-notify" item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3">{props.quickNotify.title}</Typography>
          </Grid>
          {props.quickNotify.requirements.map(r =>
            <Grid sx={{ py: theme.spacing(1) }} container spacing={3} key={r.title}>
              <Grid item xs={8}>
                <Typography>{r.title}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.rightAlign}>
                {r.keys.filter(k => card.training.filter(t => t.requirementKeys.indexOf(k) >= 0).length > 0).length > 0 ? <Check key="checked" style={{ color: green[500] }} /> : <Close key="unchecked" style={{ color: red[500] }} />}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid key="qn_hr" item xs={12}>
          <hr/>
        </Grid>
      </React.Fragment>
    }
    <Grid key="name" item xs={12}>
      <Typography variant="h5">{card.displayName}</Typography>
    </Grid>
    <Grid key="number" className={classes.verticalDense} item xs={4}>
      <Typography variant="h6">{card.iecNumber}</Typography>
    </Grid>
    <Grid key="whmis" className={clsx(classes.verticalDense, classes.rightAlign)} item xs={8}>
      <Typography variant="h6">Proof of WHMIS: {card.hasWHMIS ? 'Y' : 'N'}</Typography>
    </Grid>
    {props.onToggleViewMode == null ? undefined :
      <Grid key="mode" className={classes.verticalDense} item xs={12}>
        <FormControlLabel label="Show as detail cards" control={<Switch checked={props.viewMode === 'detail'} onChange={handleViewModeToggle} name="checkedB" color="primary" />} />
      </Grid>}
    <Grid key="hr" item xs={12}>
      <hr/>
    </Grid>
    {(card.training ?? []).sort((a, b) => recursiveCompare(b, a, ['activeCourse', 'expiry', 'delivery', 'title'], [false, false, false, true])).map(t =>
      props.viewMode === 'detail' ? trainingDetailDisplay(t) : trainingDenseDisplay(t)
    )}
    {props.QRUrl == null ? undefined : <React.Fragment key="solo_display">
      <Grid key="bases_logo" className={classes.centerAlign} item xs={12}>
        <img src="/images/BASES_Safety_Partnership.png" width={325} title="BASES Logo" />
      </Grid>
      <Grid key="qrcode" className={classes.centerAlign} item xs={12}>
        {/* Must render a white background around QR Code for scanning to work while in Dark Mode */}
        <Box key="box" sx={{ py: theme.spacing(5), backgroundColor: 'white !important;' }}>
          <QRCode value={props.QRUrl} size={325} />
        </Box>
      </Grid>
    </React.Fragment>
    }
  </Grid>;
};

export default withStyles(styles, { withTheme: true })(WorkerCardDisplay);

import clsx from 'clsx';
import { Delete } from '@mui/icons-material';
import { createStyles, withStyles } from '@mui/styles';
import Validation from '../../../../abstracts/Validation';
import { FitTestTypes } from '../../../../components/booking/FitTestSelector';
import { IFitTestType } from '../../../../stores/database/training/courseSchedule';
import { Grid, FormControl, Select, MenuItem, TextField, Button, SelectChangeEvent, FormHelperText, InputLabel, Theme } from '@mui/material';

const borderStyle: string = '1px solid rgba(0, 0, 0, 0.4)';
const styles = (theme: Theme) => createStyles({
  removeType: {
    textAlign: 'right'
  },
  odd: {
    backgroundColor: theme.palette.background.paper
  },
  even: {
    backgroundColor: theme.palette.background.default
  },
  bottom: {
    borderBottom: borderStyle,
    paddingBottom: theme.spacing(3)
  },
  left: {
    borderLeft: borderStyle
  },
  leftCorner: {
    borderLeft: borderStyle,
    borderBottom: borderStyle
  },
  right: {
    borderRight: borderStyle,
    paddingRight: theme.spacing(3)
  },
  rightCorner: {
    borderRight: borderStyle,
    borderBottom: borderStyle,
    paddingRight: theme.spacing(3)
  },
  fluidRow: {
    marginTop: '-2px'
  }
});

interface IFitTestTypeProps extends IFitTestType {
  classes?: any;
  count: number;
  index: number;
  maxIndex: number;
  parityClass: string;
  onValueUpdated: (scheduleType: string, duration: number, maxSeats: number, message?: string, errorMessage?: string) => void;
  onRemoveType: () => void;
}

interface IScheduleTypeOption {
  value: number | string;
  label: string;
}

const fitTestTypeEditor = (props: IFitTestTypeProps) => {
  const { classes, count, scheduleType, duration, maxSeats, message, onRemoveType } = props;

  const applyClasses = (additionalClasses: string[]) => {
    const classList = props.index === 0 ? [classes.fluidRow, classes[props.parityClass]] : [classes[props.parityClass]];
    additionalClasses.forEach(element => {
      classList.push(classes[element]);
    });

    return clsx(...classList);
  };

  const getErrorMessage = (label: string, value: number | string) => {
    if (label === 'Schedule Type' && !value) {
      return Validation.messages.scheduleType.required;
    }

    if (label === 'Duration') {
      if (!value) {
        return Validation.messages.duration.required;
      }

      if (value <= 0) {
        return Validation.messages.duration.min;
      }
    }

    if (label === 'Max Seats') {
      if (!value) {
        return Validation.messages.maxSeats.required;
      }

      if (value <= 0) {
        return Validation.messages.maxSeats.min;
      }
    }

    return undefined;
  };

  const onValueUpdated = (scheduleTypeUpdate: string, durationUpdate: number, maxSeatsUpdate: number, messageUpdate?: string) => {
    const errorMessage = getErrorMessage('Schedule Type', scheduleTypeUpdate) || getErrorMessage('Duration', durationUpdate) || getErrorMessage('Max Seats', maxSeatsUpdate);

    props.onValueUpdated(scheduleTypeUpdate, durationUpdate, maxSeatsUpdate, messageUpdate, errorMessage);
  };

  const handleSelectPickerUpdate = (label: string, event: SelectChangeEvent) => {
    const updatedValue = event.target.value;

    if (label === 'Schedule Type') {
      onValueUpdated(updatedValue as string, duration, maxSeats, message ?? '');
    } else if (label === 'Duration') {
      onValueUpdated(scheduleType, +(updatedValue as string), maxSeats, message ?? '');
    }
  };

  const handleNumberUpdate = (value: string) => {
    const updatedValue = !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : 0;

    onValueUpdated(scheduleType, duration, updatedValue, message ?? '');
  };

  const handleTextUpdate = (value: string) => {
    onValueUpdated(scheduleType, duration, maxSeats, value ?? '');
  };

  const renderSelectPicker = (label: string, options: IScheduleTypeOption[], selected: number | string) => {
    const errorMessage = getErrorMessage(label, selected);
    const hasError = typeof errorMessage !== 'undefined';

    return (
      <FormControl fullWidth error={hasError} required={true}>
        <InputLabel id={`selectEditor_${label}_label`}>{label}</InputLabel>
        <Select
          id={`selectEditor_${label}`}
          label={label}
          labelId={`selectEditor_${label}_label`}
          value={selected || ''}
          onChange={(event: SelectChangeEvent) => handleSelectPickerUpdate(label, event)}
          disabled={false}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {hasError ? <FormHelperText>{errorMessage}</FormHelperText> : undefined}
      </FormControl>
    );
  };

  const renderNumberEditor = (min: number, label: string, value: number) => {
    const errorMessage = getErrorMessage(label, value);
    const hasError = typeof errorMessage !== 'undefined';

    return (
      <TextField
        error={hasError}
        InputProps={{ inputProps: { min } }}
        label={label}
        type="number"
        fullWidth
        required={true}
        value={value ?? ''}
        onChange={event => handleNumberUpdate(event.currentTarget.value)}
        helperText={errorMessage}
      />
    );
  };

  const renderTextEditor = (label: string, value: string) => {
    return <TextField label={label} fullWidth multiline maxRows={4} value={value || ''} onChange={event => handleTextUpdate(event.currentTarget.value)} />;
  };

  const maskOptions = () => {
    return [
      {
        value: FitTestTypes.AirPure,
        label: 'Air Pure'
      },
      {
        value: FitTestTypes.FreshScott,
        label: 'Fresh Scott'
      },
      {
        value: FitTestTypes.FreshDraeger,
        label: 'Fresh Draeger'
      },
      {
        value: FitTestTypes.N95,
        label: 'N95'
      }
    ];
  };

  const durationOptions = () => {
    const options: IScheduleTypeOption[] = [];

    for (let minutes = 30; minutes <= 120; minutes += 30) {
      options.push({
        value: minutes,
        label: `${minutes} Minutes`
      });
    }

    return options;
  };

  return (
    <>
      <Grid item xs={6} md={3} className={applyClasses(props.index === props.maxIndex ? ['leftCorner'] : ['left'])}>
        {renderSelectPicker('Schedule Type', maskOptions(), scheduleType)}
      </Grid>
      <Grid item xs={6} md={3} className={applyClasses(props.index === props.maxIndex ? ['bottom'] : [])}>
        {renderSelectPicker('Duration', durationOptions(), duration)}
      </Grid>
      <Grid item xs={6} md={3} className={applyClasses(props.index === props.maxIndex ? ['bottom'] : [])}>
        {renderNumberEditor(1, 'Max Seats', maxSeats)}
      </Grid>
      <Grid item xs={5} md={2} className={applyClasses(props.index === props.maxIndex ? ['bottom'] : [])}>
        {renderTextEditor('Message', message ?? '')}
      </Grid>
      <Grid item xs={1} className={applyClasses(['removeType', props.index === props.maxIndex ? 'rightCorner' : 'right'])}>
        <Button variant="contained" color="primary" className={classes.button} onClick={onRemoveType} disabled={count === 1}>
          <Delete />
        </Button>
      </Grid>
    </>
  );
};

export default withStyles(styles)(fitTestTypeEditor);

import moment from 'moment';
import { Dictionary } from 'lodash';
import signalR from '@microsoft/signalr';
import { createAlertBulk } from 'src/stores/ui/actions';
import { IUIMessage, IEmailAddress } from '../../../abstracts/Interfaces';
import { refreshCacheData, fetchConfigRequest } from '../../../stores/database/actions';
import { IFitTestScheduleSlot, IScheduleData } from '../../../stores/database/training/courseSchedule';
import { ICourse, IStandard, IDynamicStandard, CourseGroupType, IFitTestSchedule } from '../../../stores/database/training/courses';
import { IWorkerHistoryCourseDates, IWorkerHistoryStandardDates, IWorkerHistoryDynamicStandardDates } from '../../../stores/database/training/workerHistory';

export const enum TrainingBookActionTypes {
  __ENUM_ROOT = '@@TrainingBook',

  BOOK_PRELOAD = '@@TrainingBook/PRELOAD',

  BOOK_RESTART = '@@TrainingBook/BOOK_RESTART',
  BOOK_SET_STEP = '@@TrainingBook/BOOK_SET_STEP',

  BOOK_HUB_DISCONNECT = '@@TrainingBook/BOOK_HUB_DISCONNECT',
  BOOK_HUB_CLEAR = '@@TrainingBook/BOOK_HUB_CLEAR',

  BOOK_HUB_CONNECT = '@@TrainingBook/BOOK_HUB_CONNECT',
  BOOK_HUB_CONNECTING = '@@TrainingBook/BOOK_HUB_CONNECTING',

  BOOK_HUB_UPDATESEATS = '@@TrainingBook/BOOK_HUB_UPDATESEATS',
  BOOK_HUB_UPDATESEATSBATCH = '@@TrainingBook/BOOK_HUB_UPDATESEATSBATCH',
  BOOK_HUB_UPDATEEXPIRY = '@@TrainingBook/BOOK_HUB_UPDATEEXPIRY',

  BOOK_HUB_VERIFYSESSION = '@@TrainingBook/BOOK_HUB_VERIFYSESSION',
  BOOK_HUB_VERIFYSESSION_START = '@@TrainingBook/BOOK_HUB_VERIFYSESSION_START',
  BOOK_HUB_VERIFYSESSION_END = '@@TrainingBook/BOOK_HUB_VERIFYSESSION_END',

  BOOK_HUB_UPDATE_WAITLISTSTART = '@@TrainingBook/BOOK_HUB_UPDATE_WAITLISTSTART',
  BOOK_HUB_UPDATE_WAITLISTEND = '@@TrainingBook/BOOK_HUB_UPDATE_WAITLISTEND',
  BOOK_HUB_ADDERRORS = '@@TrainingBook/BOOK_HUB_ADDERRORS',

  BOOK_HUB_FAILED = '@@TrainingBook/BOOK_HUB_COMPLETEFAILED',
  BOOK_HUB_PAYMENTCONFIG = '@@TrainingBook/BOOK_HUB_PAYMENTCONFIG',

  BOOK_ACTION_MESSAGE = '@@TrainingBook/ACTION_MESSAGE',

  SCHEDULE_ADD_PERSONS = '@@TrainingBook/SCHEDULE_ADD_PERSONS',
  SCHEDULE_DELETE_PERSON = '@@TrainingBook/SCHEDULE_DELETE_PERSON',

  SCHEDULE_SET_DATE_START = '@@TrainingBook/SCHEDULE_SET_DATE_START',
  SCHEDULE_SET_DATE_END = '@@TrainingBook/SCHEDULE_SET_DATE_END',

  SCHEDULE_GET_CLASSES = '@@TrainingBook/SCHEDULE_GET_CLASSES',
  SCHEDULE_GET_CLASSES_START = '@@TrainingBook/SCHEDULE_GET_CLASSES_START',
  SCHEDULE_GET_CLASSES_END = '@@TrainingBook/SCHEDULE_GET_CLASSES_END',

  SCHEDULE_ASSIGN_TOCLASS = '@@TrainingBook/SCHEDULE_ASSIGN_TOCLASS',
  SCHEDULE_ASSIGN_TOCLASS_START = '@@TrainingBook/SCHEDULE_ASSIGN_TOCLASS_START',
  SCHEDULE_REMOVE_FROMCLASS = '@@TrainingBook/SCHEDULE_ASSIGN_TOCLASS',
  SCHEDULE_ASSIGN_TOWEB = '@@TrainingBook/SCHEDULE_ASSIGN_TOWEB',
  SCHEDULE_ASSIGN_TOWEB_START = '@@TrainingBook/SCHEDULE_ASSIGN_TOWEB_START',
  SCHEDULE_REMOVE_FROMWEB = '@@TrainingBook/SCHEDULE_REMOVE_FROMWEB',

  SCHEDULE_WAITLIST_UPDATE = '@@TrainingBook/SCHEDULE_WAITLIST_UPDATE',

  SUBMIT_ADD_EMAIL = '@@TrainingBook/SUBMIT_ADD_EMAIL',
  SUBMIT_DELETE_EMAIL = '@@TrainingBook/SUBMIT_DELETE_EMAIL',
  SUBMIT_MARK_COMPLETING = '@@TrainingBook/SUBMIT_MARK_COMPLETING'
}

export type ReserveFieldStatus = 'loading' | 'complete' | 'error';

export interface IReserveFields {
  userCompanyId: number;
  purchaseOrder?: string;
  price?: number;
  pricePartner: number;
  priceNonPartner: number;
  creditCard: boolean;
  course: ICourse;
  status: ReserveFieldStatus;
}

export interface IReserveClassFields extends IReserveFields {
  courseScheduleId: number;
  bookSessionReserveId: number;
  usesCompanyReserve: boolean;
  timeslotData?: IFitTestSchedule[];
  selfPay: boolean;
  waitlistStart?: string;
  waitlistEnd?: string;
}

export interface ISessionReserveWorkerResult extends Omit<IReserveClassFields, 'course'> {
  personId: number;
}

export interface ISessionReserveWorker extends IReserveClassFields {
  trainingDate: moment.Moment;
}

export interface ISessionWebTrainingResult extends Omit<IReserveFields, 'course'> {
  bookSessionWebTrainingId: number;
  personId: number;
  courseId: number;
  connectedBookSessionReserveId?: number;
}

export interface ISessionWebTraining extends IReserveFields {
  bookSessionWebTrainingId: number;
  connectedBookSessionReserveId?: number;
  connectedTrainingDate?: string;
}

export interface IPersonTraining {
  displayName: string;
  photoDate?: moment.Moment;
  courses: { [id: string]: IWorkerHistoryCourseDates };
  standards: { [id: string]: IWorkerHistoryStandardDates };
  dynamicStandards: { [id: string]: IWorkerHistoryDynamicStandardDates };
  reservedClassTraining: {
    [courseScheduleId: number]: ISessionReserveWorker;
  };
  assignedWebTraining: {
    [courseId: number]: ISessionWebTraining;
  };
}

export interface IClassReserve {
  available: number;
  reserve: number;
  companyId: number;
  companyName: number;
}

export interface IPreviouslyScheduledWorker {
  workerScheduleId: number;
  personId: number;
  personDisplayName: string;
  trainingDate: Date;
  scheduler: string;
  timeslotData?: IFitTestSchedule;
}

export interface IClassSeats {
  total: number;
  available: number;
  reserves?: IClassReserve[];
  inUse?: IPreviouslyScheduledWorker[];
  scheduleData?: IScheduleData[];
  pricePartner?: number;
  priceNonPartner?: number;
  scheduleTypeKey: string;
}

export interface IAssignToClass {
  personId: number;
  workerDisplay: string;
  photoDate?: moment.Moment;
  courseSchedule: ITrainingClassSchedule;
  userCompanyId: number;
  timeslotData?: IFitTestSchedule[];
}

export interface IAssignToWebCourse {
  personId: number;
  workerDisplay: string;
  photoDate?: moment.Moment;
  course: ICourse;
  userCompanyId: number;
}

export interface IReserveRequest {
  courseScheduleId: number;
  personId: number;
  userCompanyId: number;
  timeslotData?: IFitTestSchedule[];
}

export interface IWebCourseRequest {
  courseId: number;
  courseOverrideId?: number;
  personId: number;
  userCompanyId: number;
}

export interface ITrainingClassSchedule extends Omit<IQueryCourseClassResult, 'trainingDate' | 'trainingTime' | 'room.location.name'> {
  trainingDate: moment.Moment;
  course: ICourse;
  location: string;
  locationMap?: string;
  seats?: IClassSeats;
  attachedPersons: { id: number; display: string; }[];
}

export interface ITrainingReference {
  id: number | string;
  type: CourseGroupType;
}

export interface ITrainingList {
  courses: { [courseId: number]: ICourse & { selectedWorkers: number[] } };
  standards: { [standardId: number]: IStandard & { selectedWorkers: number[] } };
  dynamicStandards: { [key: string]: IDynamicStandard & { selectedWorkers: number[] } };
}

export type TrainingBookStep = 'ScheduleTrainingLive' | 'ConfirmTraining' | 'PayForTraining';
export type PaymentType = 'Invoice' | 'CreditCard';

export interface IPaymentConfig {
  type: PaymentType;
  paymentId?: number;
}

export interface IPaymentAdjustment {
  creditCard: boolean;
  userCompanyPO: string;
  price: number;
  lineItem: string;
}

export interface IStripePayment {
  subtotal: number;
  taxes: number;
  total: number;
  clientSecret: string;
  priceAdjustments: IPaymentAdjustment[];
}

// export interface IWorkerCoursePrice {
//   personId: number;
//   courseScheduleId: string;
//   price: number;
// }

// export interface ICompanyPay {
//   userCompanyId: number;
//   purchaseOrder: string;
//   key: string;
//   subtotal: number;
//   alternateCreditCard?: IStripePayment;
//   items: IWorkerCoursePrice[];
// }

export interface ISubmitLineItem {
  key: string;
  name: string;
  title: string;
  pricePartner: number;
  priceNonPartner: number;
}

export interface ISubmitCompanyPay {
  key: string;
  company: string;
  userCompanyId: number;
  purchaseOrder: string;
  creditCard: boolean;
  subtotalPartner: number;
  subtotalNonPartner: number;
  items: ISubmitLineItem[];
}

export interface IBookingSubmit {
  payment?: IStripePayment;
  selfPay: ISubmitLineItem[];
  companyPay: ISubmitCompanyPay[];
}

export interface ITrainingBookState {
  trainingBookStep: TrainingBookStep;
  userMessage?: IUIMessage;
  personTraining: { [personId: number]: IPersonTraining };
  bookDateStart: moment.Moment;
  bookDateEnd: moment.Moment;
  reserveEndTime?: moment.Moment;
  loadedBookDates: 'WaitingOnUserLoad' | 'LoadingTrainingSchedule' | 'Loaded' | 'AutoLoadOnConnect';
  courseSchedule: ITrainingClassSchedule[];
  hubConnection?: signalR.HubConnection;
  hubConnectionLoading: boolean;
  bookSessionId?: number;
  courses: Dictionary<ICourse>;
  trainingSubmitting: boolean;
  submit?: IBookingSubmit;
  registrationEmails?: { [companyId: number]: IEmailAddress[] };
  otherEmails: { [companyId: number]: string[] };
  lastRender?: number;
  version: number;
}

export interface IPreloadBookingProps {
  sessionId: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  trainingBookStep: TrainingBookStep;
}

export interface IQueryCourseScheduleProps {
  sessionId: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  courseIds: number[];
  count?: number;
  refreshCacheData: typeof refreshCacheData;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

export interface IUpdateScheduleDateProps {
  sessionId: number;
  start: moment.Moment;
  end: moment.Moment;
  newStart?: moment.Moment;
  newEnd?: moment.Moment;
  courseIds?: number[];
  refreshCacheData: typeof refreshCacheData;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

export interface IQueryCourseClassResult {
  courseScheduleId: number;
  'course.courseId': number;
  reservedCompany?: number;
  'reservedCompany.name'?: string;
  trainingDate: string;
  trainingTime: string;
  room?: number;
  'room.location.name': string;
  timeslotData?: IFitTestScheduleSlot[];
}

export interface IQueryCourseScheduleResult {
  classes: IQueryCourseClassResult[];
}

export interface IHubUpdateSeats extends IClassSeats {
  courseScheduleId: number;
  reservedCompanyId?: number;
}

export interface IHubReserveWarning {
  message: string;
  key: string;
}

export interface IHubReserveError extends IHubReserveWarning {
  request: {
    personId: number;
    courseId?: number;
    courseScheduleId?: number;
  }
}

export interface IHubScheduleResult {
  bookSessionId: number;
  sessionReserves: ISessionReserveWorkerResult[];
  sessionWebTrainings: ISessionWebTrainingResult[];
  sessionErrors: IHubReserveError[];
  sessionWarnings: IHubReserveWarning[];
  expirySeconds: number;
  payment?: IStripePayment;
}

export interface IRegistrationResultRow {
  workerName: string;
  iecNumber: number;
  trainingDate: string;
  classTime: string;
  companyName: string;
  course: string;
  location: string;
  mapURL: string;
  purchaseOrder: string;
  companyId: number;
  price: string;
  payment: string;
}

export interface IRegistrationPaymentRow {
  lineItem: string;
  price: string;
}

export interface IRegistrationPaymentData {
  receiptUrl: string;
  subTotal: string;
  taxes: string;
  paymentAmount: string;
  lineItems: IRegistrationPaymentRow[];
}

export interface IRegistrationConfirmationDetails {
  scheduleDisplay: string;
  scheduleEmail: string;
  scheduleDateTimeUTC: string;
  scheduler: string;
  registrationRows: Dictionary<IRegistrationResultRow>;
  payment: IRegistrationPaymentData;
  priceAdjustments: IPaymentAdjustment[];
}

import { User } from 'oidc-client';
import { IAppState } from '../stores';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import ErrorPage from '../pages/ErrorPage';
import UserManager from '../abstracts/UserManager';
import LoadingScreen from '../components/common/LoadingScreen';

interface ILoginProps {
  [x: string]: any;
  user: User;
  dispatch: Dispatch
}

function Login(props: ILoginProps) {
  const { user } = props;

  if (!user) {
    try {
      UserManager.signinRedirect({
        data: location.pathname
      });
    } catch (error) {
      return <ErrorPage/>;
    }
  }

  return (
    <div style={{ height: '100vh' }}>
      <LoadingScreen helpText="Login page is loading - please wait" />
    </div>
  );
}

function mapStateToProps(state: IAppState) {
  return {
    user: state.oidc.user
  };
}

export default compose(connect(mapStateToProps))(Login);

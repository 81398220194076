import clsx from 'clsx';
import React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  categoryHeader: {
    color: theme.palette.primary.contrastText,
    maxWidth: 240
  },
  item: {
    color: theme.palette.primary.contrastText,
    paddingBottom: 4,
    paddingTop: 4,
    maxWidth: 240
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  itemActiveItem: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark
  },
  itemPrimary: {
    '&$textDense': {
      fontSize: theme.typography.fontSize
    },
    color: 'inherit',
    fontSize: theme.typography.fontSize
  },
  textDense: {}
});

// from: https://dragoshmocrii.com/using-react-router-navlink-with-a-mui-listitembutton-typescript/
interface IListItemNavLink {
  classes?: any;
  isHeader?: boolean;
  key: string;
  to: string;
  text: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const ListItemNavLink = (props: IListItemNavLink) => {
  type MyNavLinkProps = Omit<NavLinkProps, 'to'>;

  // eslint-disable-next-line react/display-name
  const MyNavLink = React.useMemo(() => React.forwardRef<HTMLAnchorElement, MyNavLinkProps>((navLinkProps, ref) => {
    const { className: previousClasses, ...rest } = navLinkProps;
    const elementClasses = previousClasses?.toString() ?? '';

    const classes = props.isHeader ? [props.classes.categoryHeader, elementClasses] : [props.classes.item, props.classes.itemActionable, elementClasses];

    return (
      <NavLink
        {...rest}
        ref={ref}
        to={props.to}
        end
        className={({ isActive }) => clsx(...classes, (isActive && props.classes.itemActiveItem))}
      />
    );
  }), [props.to]);

  return (
    <ListItem
      button
      key={`navLink_${props.key}`}
      dense={!props.isHeader}
      component={MyNavLink}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        classes={{
          dense: props.classes.textDense,
          primary: props.classes.itemPrimary
        }}
      >
        {props.text}
      </ListItemText>
    </ListItem>
  );
};

export default withStyles(styles)(ListItemNavLink);

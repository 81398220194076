import { AutoRow } from '../AutoLoad';
import { createStyles, withStyles } from '@mui/styles';
import { mapMenuItems } from './helpers/SelectHelpers';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { TextField, MenuItem, FormControl, InputLabel, Select, FormHelperText, Theme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  readonlyField: {
    cursor: 'not-allowed'
  },
  matchHeight: {
    marginTop: theme.spacing(1)
  },
});

interface INumberEditorProps extends IAutoEditorImplementation<string | undefined> {
  classes: any;
}

const numberEditor = (props: INumberEditorProps) => {
  const { classes, field, editValue, error, warning } = props;

  const getMenuItemDisplay = (item: AutoRow, depth?: number) => (
    <MenuItem style={{ paddingLeft: 15 * (depth ?? 0) + 5, fontWeight: depth === 0 ? 'bold' : undefined }} key={item.id} value={item.id} disabled={!item.isSelectable}>
      {item.display}
    </MenuItem>
  );

  const getMenuItems = () => (props.valueCache == null || field.referenceTableId == null || props.valueCache[field.referenceTableId] == null ? undefined : mapMenuItems(props.valueCache[field.referenceTableId], getMenuItemDisplay));

  if (field.columnType === 'Lookup' || field.columnType === 'NestedLookup') {
    return (
      <FormControl error={!!error} fullWidth required={field.required} className={classes.matchHeight}>
        <InputLabel id={`selectEditor_${field.columnId}_label`}>{field.title}</InputLabel>
        <Select label={field.title} labelId={`selectEditor_${field.columnId}_label`} id={`selectEditor_${field.columnId}`} value={editValue ?? ''} disabled>
          {getMenuItems()}
        </Select>
        <FormHelperText>{error || warning || field.description}</FormHelperText>
      </FormControl>
    );
  }

  return (
    <TextField
      id={`readonlyEditor_${field.columnId}`}
      label={field.title}
      className={props.classes.readonlyField}
      margin="dense"
      fullWidth
      multiline
      maxRows={4}
      required={field.required}
      value={editValue ?? ''}
      error={!!error}
      helperText={error || warning || field.description}
      disabled={props.disabled}
    />
  );
};

export default withStyles(styles)(numberEditor);

import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cropper from 'react-easy-crop';
import Camera from 'react-html5-camera-photo';
import settings from '../../abstracts/settings';
import { VisibilityOff } from '@mui/icons-material';
import 'react-html5-camera-photo/build/css/index.css';
import { createStyles, withStyles } from '@mui/styles';
import { getCroppedImg } from '../../abstracts/CropImage';
import { IProfileState } from '../../stores/profile/types';
import { mapFromAppState } from '../training/checkin/CheckinList';
import { Avatar, IconButton, Theme, Dialog, DialogTitle, DialogContent, Grid, FormControlLabel, Checkbox, DialogActions, Button, Slider } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const styles = (theme: Theme) => createStyles({
  cropperArea: {
    position: 'relative',
    width: 768,
    height: 576
  },
  photoRequired: {
    backgroundColor: theme.palette.primary.main
  }
});

export interface IWorkerPhotoProps {
  classes: any;
  personId: number;
  photoTakenUTC?: string | moment.Moment;
  workerName: string;
  readonly?: boolean;
  diameter?: number | string;
  onPhotoUpdate?: (personId: number, photoTakenUTC: string) => void;
}

interface IConnectedProps {
  profile: IProfileState;
}

const cropSettings = {
  maxZoom: 6,
  zoomSpeed: 0.2
};

interface IWorkerPhotoState {
  showBlowup: boolean;
  open: boolean;
  takePhoto: boolean;
  photoData?: any;
  crop: { x: number; y: number };
  pixelCrop?: { width: number; height: number; x: number; y: number };
  zoom: number;
  queueCard: boolean;
}

const WorkerPhoto = (props: IWorkerPhotoProps & IConnectedProps) => {
  const { personId, photoTakenUTC } = props;
  const diameter = props.diameter ?? 80;

  const defaultPhotoState: IWorkerPhotoState = {
    showBlowup: false,
    open: false,
    takePhoto: false,
    crop: {
      x: 0,
      y: 0
    },
    zoom: 1,
    queueCard: true
  };

  const [camera, setCamera] = React.useState<IWorkerPhotoState>(defaultPhotoState);
  const [photoDate, setPhotoDate] = React.useState<string | moment.Moment | undefined>();

  React.useEffect(() => setPhotoDate(photoTakenUTC), [photoTakenUTC]);

  const handleAvatarClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCamera((old) => ({
      ...old,
      showBlowup: true
    }));
  };

  const handlePhotoPrompt = () => setCamera((old) => ({
    ...old,
    open: true,
    takePhoto: true,
    showBlowup: false
  }));

  const handleCameraClose = () => setCamera(defaultPhotoState);

  const handleCardPrint = () => {
    axios.post(`${API_ENDPOINT}/Account/QueueCard/${props.personId}`).then(() => {
      handleCameraClose();
      setPhotoDate(moment().utc());
    }).catch((err) => console.log(err));
  };

  const handleTakePhoto = (dataUri: any) => setCamera((old) => ({
    ...old,
    photoData: dataUri,
    takePhoto: false
  }));

  const handleCameraUpload = () => {
    axios.post(`${API_ENDPOINT}/File/UploadPhoto`, { data: getCroppedImg(camera.photoData, camera.pixelCrop!), personId: props.personId, print: camera.queueCard }).then(() => {
      if (props.onPhotoUpdate) props.onPhotoUpdate(props.personId, moment().format(settings.apiDateTimeFormatMoment));
      handleCameraClose();
      setPhotoDate(moment().utc());
    }).catch((err) => console.log(err));
  };

  const handleCameraReset = () => setCamera((old) => ({
    ...old,
    photoData: undefined,
    takePhoto: true
  }));

  const handleLogEvent = (type: string) => (obj: any) => console.log(type, obj);

  const handleCropChange = (crop: { x: number; y: number }) => setCamera((old) => ({
    ...old,
    crop
  }));

  const handleCropComplete = (croppedArea: any, cropppedAreaPixels: any) => setCamera((old) => ({
    ...old,
    pixelCrop: cropppedAreaPixels
  }));

  const handleZoomChange = (zoom: number) => setCamera((old) => ({
    ...old,
    zoom
  }));

  const loadAvatar = (altDiameter?: string) => (
    <Avatar
      src={`https://iecpartnership.blob.core.windows.net/pictures/p${personId}${photoDate == null ? '.jpg' : `.jpg?${moment(photoDate).unix()}`}`}
      style={{ width: altDiameter ?? diameter, height: altDiameter ?? diameter, marginLeft: 'auto', marginRight: 'auto' }}
    >
      <VisibilityOff/>
    </Avatar>
  );

  const displayAvatar = () => props.readonly ? (
    loadAvatar()
  ) : (
    <IconButton className={photoDate == null ? props.classes.photoRequired : undefined} onClick={handleAvatarClick}>
      {loadAvatar()}
    </IconButton>
  );

  const displayBlowup = () => (
    <Dialog open={camera.showBlowup} fullWidth maxWidth="xl" onClick={(e) => e.stopPropagation()}>
      <DialogContent>{loadAvatar('min(calc(100vh - 200px), calc(100vw - 100px))')}</DialogContent>
      <DialogActions>
        {!props.profile.isTrainingFacility ? undefined : (
          <>
            <Button key="button_print" onClick={handleCardPrint} color="primary">
              Reprint Card
            </Button>
            <Button key="button_photo" onClick={handlePhotoPrompt} color="primary">
              Take New Photo
            </Button>
          </>
        )}
        <Button onClick={handleCameraClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const displayCamera = () => (
    <Dialog onClick={(e) => e.stopPropagation()} open={camera.open} onClose={handleCameraClose} maxWidth="xl">
      <DialogTitle>Take new photo for {props.workerName}</DialogTitle>
      <DialogContent>
        {camera.takePhoto ? (
          <Camera
            onTakePhoto={handleTakePhoto}
            onCameraStart={handleLogEvent('onCameraStart')}
            onCameraStop={() => console.log('onCameraStop')}
            onCameraError={handleLogEvent('onCameraError')}
            imageType="jpg"
            idealFacingMode="environment"
            isMaxResolution
          />
        ) : (
          <>
            <div className={props.classes.cropperArea} key="cropper">
              <Cropper
                image={camera.photoData}
                crop={camera.crop}
                aspect={4 / 3}
                zoom={camera.zoom}
                maxZoom={cropSettings.maxZoom}
                zoomSpeed={cropSettings.zoomSpeed}
                onCropChange={handleCropChange}
                onCropComplete={handleCropComplete}
                onZoomChange={handleZoomChange}
              />
            </div>
            <Grid key="options" container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Slider value={camera.zoom} min={1} max={cropSettings.maxZoom} step={0.1} onChange={(e, zoom: number) => setCamera((old) => ({ ...old, zoom }))}/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel control={<Checkbox checked={camera.queueCard} onChange={() => setCamera((old) => ({ ...old, queueCard: !old.queueCard }))} color="primary"/>} label="Print Card on Upload"/>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {camera.photoData == null ? undefined : (
          <>
            <Button key="button_upload" onClick={handleCameraUpload} color="primary">
              Upload
            </Button>
            <Button key="button_take" onClick={handleCameraReset} color="primary">
              Take Again
            </Button>
          </>
        )}
        <Button onClick={handleCameraClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {displayAvatar()}
      {displayBlowup()}
      {displayCamera()}
    </>
  );
};

export default compose(withStyles(styles), connect(mapFromAppState))(WorkerPhoto);

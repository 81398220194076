import { Paper } from '@mui/material';
import Upload from '../../components/training/Upload';
import { createStyles, withStyles } from '@mui/styles';

const styles = () => createStyles({
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  }
});

export interface ITrainingUploadProps {
  classes: any;
}

const UploadPage = (props: ITrainingUploadProps) => {
  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Upload />
      </div>
    </Paper>
  );
};

export default withStyles(styles, { withTheme: true })(UploadPage);

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import { IAppState } from '../../stores';
import { createStyles, withStyles } from '@mui/styles';
import { IDatabaseState } from '../../stores/database/types';
import { arraySortMap } from '../../abstracts/DataroweHelpers';
import LoadingScreen from '../../components/common/LoadingScreen';
import DefaultAutoGrid from '../../components/auto/DefaultAutoGrid';
import { loadDirectEditTables } from '../../components/auto/AutoLoad';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Theme, SelectChangeEvent, useTheme } from '@mui/material';

const generateSxClasses = (_theme: Theme) => {
  return {
    contentWrapper: {
      margin: '10px 16px'
    },
    paper: {
      margin: 'auto',
      overflow: 'hidden',
      marginBottom: 10
    }
  };
};

interface IAppSettingsPageProps {
  classes: any;
}

interface IAppSettingsPageState {
  value: string;
  title: string;
}

interface IConnectedProps { dbState: IDatabaseState }

const AppSettingsPage = (_props: IAppSettingsPageProps & IConnectedProps) => {
  const sxClasses = generateSxClasses(useTheme());

  const [selectedTable, setSelectedTable] = React.useState('');

  const [tables, setTables] = React.useState<IAppSettingsPageState[] | undefined>(undefined);

  React.useEffect(() => {
    loadDirectEditTables().then(setTables);
  }, []);

  const handleTableChange = (event: SelectChangeEvent) => setSelectedTable(event.target.value);

  return (
    <Paper sx={{ ...sxClasses.paper }}>
      <Box sx={{ ...sxClasses.contentWrapper }}>
        {tables == null ? <LoadingScreen/> :
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="table-label">Select Table</InputLabel>
              <Select label="Select Table" labelId="table-label" id="table" value={selectedTable} onChange={handleTableChange}>
                {arraySortMap(tables, 'title', ({ value, title })=><MenuItem key={value} value={value}>{title}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {selectedTable === '' ? <Typography>Please select a table</Typography> : <DefaultAutoGrid tableName={selectedTable} autoEditor autoDelete maxHeight="calc(100vh - 150px)" />}
          </Grid>
        </Grid>}
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    helpGuid: state.ui.helpGuid,
    db: state.db
  };
};

export default compose(withStyles(createStyles({})), connect(mapStateToProps, undefined))(AppSettingsPage);

import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { TypographyProps } from '@mui/material/Typography';
import { formatTime, useInterval } from '../../abstracts/DataroweHelpers';

// theming based on https://spin.atomicobject.com/2018/11/08/countdown-timer-react-typescript/
const dashes = 188.19;
const styles = (theme: Theme) => createStyles({
  countdownTimer: {
    position: 'absolute',
    width: 72,
    height: 72
  },
  countdownTimerCircle: {
    strokeDasharray: dashes,
    strokeDashoffset: dashes,
    strokeLinecap: 'butt',
    strokeWidth: 8,
    stroke: theme.palette.secondary.main,
    fill: theme.palette.primary.main
    // animationName: '$countdown-animation',
    // animationTimingFunction: 'linear'
  },
  countdownTimerSVG: {
    width: 72,
    height: 72,
    transform: 'rotateZ(-90deg);'
  },
  countdownTimerText: {
    position: 'absolute',
    top: 23,
    width: 72,
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    textAlign: 'center'
  },
  countdownDone: {
    animationName: '$fade-in-out',
    animationDuration: '4s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite'
  },
  // ['@keyframes countdown-animation']: {
  //   from: { strokeDashoffset: 0 },
  //   to: { strokeDashoffset: dashes }
  // },
  ['@keyframes fade-in-out']: {
    ['0%,100%']: { opacity: 0 },
    ['50%']: { opacity: 1 }
  }
});

export interface ITimerProps {
  classes: any;
  endTimeUTC: moment.Moment;
  interval?: number;
  maxInterval: number;
  format?: (ticks: number) => string;
  onTick?: (ticks: number) => undefined | number;
  onComplete?: () => void;
}

interface ITimerState {
  remainder: number;
  interval: number;
}

const Timer = (props: ITimerProps & Omit<TypographyProps, 'component'>) => {
  const stoppedTimer = 1000000000;
  const toPrecisionCustom = (number: number, msScale?: number): number => number < 0 ? 0 : number - (number % (msScale || 1000));

  const { classes, endTimeUTC, interval, maxInterval, format, onTick: tickCallback, onComplete: completeCallback } = props;
  const [timerState, setTimerState] = React.useState<ITimerState>({ remainder: toPrecisionCustom(endTimeUTC.diff(moment().utc(), 'milliseconds'), interval), interval: interval || 1000 });

  React.useEffect(
    () => {
      if (timerState.interval === stoppedTimer && endTimeUTC.isAfter(moment().utc())) {
        setTimerState(old => ({ ...old, interval: interval || 1000 }));
      }
    },
    [endTimeUTC]
  );

  useInterval(
    () => {
      const newRemainder = toPrecisionCustom(endTimeUTC.diff(moment().utc(), 'milliseconds'));
      if (newRemainder > 0 || timerState.remainder > 0) {
        setTimerState(old => ({ remainder: newRemainder, interval: newRemainder > 0 ? old.interval : stoppedTimer }));
        if (newRemainder > 0) {
          if (tickCallback) tickCallback(newRemainder);
        } else {
          if (completeCallback) completeCallback();
        }
      }
    },
    timerState.interval
  );

  return (
    <div className={classes.countdownTimer}>
      <div>
        <svg className={classes.countdownTimerSVG}>
          <circle
            r="30"
            cx="36"
            cy="36"
            className={classes.countdownTimerCircle}
            style={{
              strokeDashoffset: (maxInterval - timerState.remainder) / maxInterval * dashes
            }}
          />
        </svg>
      </div>
      <div className={clsx(classes.countdownTimerText, timerState.remainder === 0 ? classes.countdownDone : undefined)}>
        {format === undefined ? formatTime(Math.floor(timerState.remainder / 1000)) : format(timerState.remainder)}
      </div>
    </div>
  );
};

export default withStyles(styles)(Timer);

import React from 'react';
import { compose } from 'redux';
import remarkGfm from 'remark-gfm';
import { connect } from 'react-redux';
import { IAppState } from '../stores';
import ReactMarkdown from 'react-markdown';
import axios, { AxiosResponse } from 'axios';
import { IMarkdownDoc } from '../abstracts/Interfaces';
import { createStyles, withStyles } from '@mui/styles';
import { quickLoadSingle } from '../components/auto/AutoLoad';
import LoadingScreen from '../components/common/LoadingScreen';
import { IConnectedProps, mapConfigFetchToProps } from '../components/auto/AutoGrid';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const styles = () => createStyles({
  contentWrapper: {
    margin: '15px 10px'
  },
  floatingEditButton: {
    position: 'absolute',
    right: 10,
    zIndex: 1000
  },
  floatingSaveCloseButton: {
    position: 'absolute',
    right: 110,
    zIndex: 1000
  },
  floatingSaveButton: {
    position: 'absolute',
    right: 250,
    zIndex: 1000
  },
  noWrap: {
    '& pre': {
      whiteSpace: 'normal'
    }
  }
});

interface IHelpDocProps {
  classes: any;
  markdownKey: string;
  noAuth?: boolean;
  pageLink?: boolean;
  inEdit?: boolean;
  title: string;
  onEditClose: () => void;
}

interface IHelpDocState {
  currentMarkdown?: string;
  markdownVersion?: number;
  versionId?: number;
  markdownLoading: boolean;
  saving: boolean;
}

const HelpDoc = (props: IHelpDocProps & IConnectedProps) => {
  const { classes } = props;

  const [state, setState] = React.useState<IHelpDocState>({
    markdownLoading: true,
    saving: false
  });

  React.useEffect(() => {
    setState((old) => ({
      ...old,
      markdownLoading: true
    }));

    if (props.noAuth) {
      axios.get(`${API_ENDPOINT}/file/markdown/${props.markdownKey}`).then((res: AxiosResponse<IMarkdownDoc>) => setState((old) => ({
        ...old,
        markdownLoading: false,
        currentMarkdown: res.data.markdown
      })));
    } else {
      props.fetchConfigRequest(undefined, {
        key: `HelpDoc_${props.markdownKey}`,
        action: (db) => {
          quickLoadSingle({
            db,
            schema: 'datarowe',
            table: 'markdownDoc',
            columns: {
              markdown: 'markdown',
              version: 'versionNo',
              id: 'markdownDocId'
            },
            filters: {
              docKey: props.markdownKey,
              active: true
            }
          }).then((v) => {
            setState((old) => ({
              ...old,
              currentMarkdown: v.markdown.display,
              markdownVersion: +v.version.display,
              versionId: +v.id.display,
              markdownLoading: false
            }));
          });
        }
      });
    }
  }, [props.markdownKey]);

  return (
    <div className={classes.contentWrapper}>
      {state.markdownLoading ? (
        <LoadingScreen />
      ) : (
        <ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.noWrap}>{state.currentMarkdown ?? ''}</ReactMarkdown>
      )}
    </div>
  );
};

function mapStateToProps(state: IAppState) {
  return {
    user: state.oidc.user,
    ui: state.ui,
    profile: state.profile,
    db: state.db.database
  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapConfigFetchToProps))(HelpDoc);

import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { ComponentType } from 'react';
import { cloneDeep, Dictionary } from 'lodash';
import AutoDeletePrompt from './AutoDeletePrompt';
import { Theme, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { AutoEditorCache } from './autoInterfaces';
import { IDefaultValueParameter } from './AutoChange';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from 'src/stores/profile/types';
import AutoEditor, { IDisplayContext } from './AutoEditor';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { dateRangeToFilter, IDateRange } from '../common/DateRangeEdit';
import { CellDisplayFormatter, RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { ICustomSelectColumnMap, getAllFields, autoLoad, AutoRow, ICustomDisplayDefinition } from './AutoLoad';
import AutoGrid, { mapConfigFetchToProps, IAutoGridProps, IConnectedProps, AutoGridActions, AutoGridRowClickEvent } from './AutoGrid';
import { objectSortEach, nameof, objectEach, isInterface, objectMapArray, isNumberArray, hasAnyMatch } from '../../abstracts/DataroweHelpers';
import { ISelectColumnMap, IColumnMap, IColumn, FilterConditionType, IFilterCondition, FilterGroupType, IFilterGroup, SupportedFilterTypes, ColumnOrderType } from '../../stores/database/interfaces';
import BulkCreator from './bulk/BulkCreator';

const styles = (theme: Theme) => createStyles({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  tableCell: {
    flex: 1
  },
  noClick: {
    cursor: 'initial'
  },
  denseCell: {
    padding: '0px 3px 0px 3px'
  },
  tableWrapper: {
    width: '100%',
    overflow: 'auto'
  },
  emptyMessage: {
    textAlign: 'center',
    color: theme.palette.grey[500],
    fontStyle: 'italic'
  }
});

export interface IAutoColumn {
  name: string | string[];
  readonly?: boolean;
  excludeTable?: boolean;
  title?: string;
  display?: RowFormatter<string, ISelectColumnMap>;
  displayControl?: CellDisplayFormatter;
}

export interface IDefaultFilter {
  filter: number | number[] | IFilterCondition | IFilterGroup;
  hideFromTable?: boolean;
  defaultInEditor?: 'readonly' | 'editable';
}

export interface IDefaultColumnFilter<T = number | string> extends IDefaultFilter {
  column: T;
}

export interface IDefaultReferenceFilter<T = number | string> extends IDefaultFilter {
  referenceTable: T;
}

export interface IOptionalAuto {
  filters: Dictionary<string | number | string[] | number[] | undefined>;
  hideByProfile?: (p: IProfileState) => boolean;
}

export interface IQuickFilterField {
  field: ISelectColumnMap;
  value?: SupportedFilterTypes | SupportedFilterTypes[] | IDateRange;
  not?: boolean;
  condition?: FilterConditionType
}

export interface IQuickFilterPath extends Omit<IQuickFilterField, 'field'> {
  path: string | string[];
}

export interface IDefaultAutoGridProps {
  tableName: string;
  onRowClick?: AutoGridRowClickEvent;
  defaultColumnFilters?: IDefaultColumnFilter[];
  defaultReferenceFilters?: IDefaultReferenceFilter[];
  defaultSortColumn?: { column: ICustomSelectColumnMap | ICustomDisplayDefinition; sortOrder?: ColumnOrderType } | number;
  defaultSortOrder?: ColumnOrderType;
  quickFilter?: (IQuickFilterField | IQuickFilterPath)[];
  columns?: (string | string[] | IAutoColumn)[];
  company?: number;
  editorContext?: Dictionary<IDisplayContext>;
  autoEditor?: boolean | IOptionalAuto;
  onSaveCallback?: Function;
  autoDelete?: boolean | IOptionalAuto;
  onDeleteCallback?: Function;
  minHeight?: number | string;
  maxHeight?: number | string;
  pagination?: boolean | number[];
  buttonTitle?: string;
  bulkButtonTitle?: string;
  editorTitle?: string;
  forceUpdateStamp?: number;
  bulkDelete?: boolean;
  bulkEdit?: boolean;
  bulkCreate?: boolean;
}

const DefaultAutoGrid = (props: IDefaultAutoGridProps & IConnectedProps) => {
  interface AdditionalProps {
    validTable?: boolean;
    defaultFilters: Dictionary<IFilterCondition | IFilterGroup>;
    defaultValues: IDefaultValueParameter[];
    companyField?: IColumnMap;
    editId?: number;
    createTitle: string;
    bulkCreateTitle: string;
    deleteId?: number;
    deleteRow?: AutoRow;
    cache?: AutoEditorCache;
    quickFilter: IQuickFilterField[];
    valueMap: Dictionary<string>;
    loadingConfig: boolean;
  }

  const defaultGridState: IAutoGridProps = {
    baseTable: props.tableName,
    exportTitle: '',
    displayColumns: [],
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    keyField: undefined,
    displayField: undefined
  };

  const [gridState, setGridState] = React.useState(defaultGridState);

  const [state, setState] = React.useState<AdditionalProps>({
    createTitle: 'Add New',
    bulkCreateTitle: 'Bulk Add New',
    quickFilter: [],
    defaultFilters: {},
    defaultValues: [],
    loadingConfig: false,
    valueMap: {}
  });

  const filterFromDefault = (columnId: number, lookupPath: number[], filter: IFilterGroup | IFilterCondition | number | number[]): IFilterGroup | IFilterCondition =>
    isInterface<IFilterGroup | IFilterCondition>(filter, 'type') ? filter : { columnId, lookupPath, type: Array.isArray(filter) ? FilterConditionType.IsOneOf : FilterConditionType.Equal, firstParameter: filter };

  const filterFromConfig = (col: IColumn, filter?: IFilterCondition | IFilterGroup, propColFilter?: IDefaultFilter, propRefFilter?: IDefaultFilter, lookupPath?: number[]): IFilterCondition | IFilterGroup | undefined => {
    if (propColFilter != null || propRefFilter != null) {
      const result: IFilterGroup = { type: FilterGroupType.And, children: [] };

      if (isInterface<IFilterGroup>(filter, 'children')) {
        if (filter.type === FilterGroupType.And) {
          result.children = filter.children;
        } else {
          result.children.push(filter);
        }
      } else if (filter != null) {
        result.children.push(filter);
      }

      if (propColFilter) result.children.push(filterFromDefault(col.columnId, lookupPath ?? [], propColFilter.filter));

      if (propRefFilter) result.children.push(filterFromDefault(col.columnId, lookupPath ?? [], propRefFilter.filter));

      return result.children.length === 1 ? result.children[0] : result;
    }

    return undefined;
  };

  const isHiddenAddDefaultFilter = (col: IColumn, defaultFilters: Dictionary<IFilterCondition | IFilterGroup>, defaultValues: Dictionary<IDefaultValueParameter>, propColFilters: Dictionary<IDefaultColumnFilter>, propRefFilters: Dictionary<IDefaultReferenceFilter>, lookupPath?: number[]): boolean => {
    const filterCol = propColFilters[col.columnId];
    const filterRef = col.referenceTableId == null ? undefined : propRefFilters[col.referenceTableId];

    const f = filterFromConfig(col, defaultFilters[col.columnId], filterCol, filterRef, lookupPath);

    if (f != null) defaultFilters[col.columnId] = f;

    if (filterCol?.defaultInEditor && !isInterface<IFilterCondition | IFilterGroup>(filterCol?.filter, 'type')) {
      if (defaultValues[col.columnId] == null) defaultValues[col.columnId] = { columnId: col.columnId, newValue: filterCol.filter, readonly: filterCol.defaultInEditor === 'readonly' };
    } else if (filterRef?.defaultInEditor && !isInterface<IFilterCondition | IFilterGroup>(filterRef?.filter, 'type')) {
      if (defaultValues[col.columnId] == null) defaultValues[col.columnId] = { columnId: col.columnId, newValue: filterRef.filter, readonly: filterRef.defaultInEditor === 'readonly' };
    }

    return (filterCol?.hideFromTable ?? false) || (filterRef?.hideFromTable ?? false);
  };

  const loadData = (params: IAutoGridProps, additionalParams: AdditionalProps) => {
    if (params.baseTable == null) return;

    const conditions: (IFilterCondition | IFilterGroup)[] = objectMapArray(additionalParams.defaultFilters, (k, v) => v);

    additionalParams.quickFilter.forEach((f) => {
      if (isInterface<IDateRange>(f.value, 'startDate')) return conditions.push(dateRangeToFilter({ range: f.value, column: f.field, isUTC: f.field.columnAlias.endsWith('UTC'), includeTime: f.field.columnType === 'DateTime', not: f.not }));

      const fallback = Array.isArray(f.value) ? (f.not ? FilterConditionType.IsNotOneOf : FilterConditionType.IsOneOf) : (f.not ? FilterConditionType.NotEqual : FilterConditionType.Equal);

      return conditions.push({
        columnId: f.field.columnId,
        lookupPath: f.field.lookupPath ?? [],
        firstParameter: f.value,
        type: f.condition ?? (f.value == null ? FilterConditionType.IsNull : fallback)
      });
    });

    setGridState((old) => ({
      ...old,
      rowDataLoading: true
    }));

    autoLoad({
      search: conditions.length === 0 ? undefined : {
        baseTableId: +params.baseTable,
        name: 'Default Auto Grid Filters',
        baseFilter: conditions.length === 1 ? conditions[0] : {
          type: FilterGroupType.And,
          children: conditions
        }
      },
      baseTableId: +params.baseTable,
      keyField: params.keyField as ISelectColumnMap,
      displayField: params.displayField!,
      allFields: getAllFields(params.displayColumns, params.additionalColumns),
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk
    }).then((res) => {
      setGridState((old) => ({
        ...old,
        rowData: res,
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    });
  };

  React.useEffect(() => {
    if (!state.loadingConfig) {
      setState((old) => ({ ...old, loadingConfig: true }));
      props.fetchConfigRequest(undefined, {
        key: `defaultAutoGrid_${props.tableName}`,
        action: (db) => {
          if (db.tableExistsByCombinedName(props.tableName)) {
            const baseTable = db.getTableByCombinedName(props.tableName);

            const propColFilters: Dictionary<IDefaultColumnFilter> = {};
            (props.defaultColumnFilters ?? []).forEach((f) => (propColFilters[typeof f.column === 'number' ? f.column : baseTable.columnNames[f.column]] = f));

            const propRefFilters: Dictionary<IDefaultReferenceFilter> = {};
            (props.defaultReferenceFilters ?? []).forEach((f) => (propRefFilters[typeof f.referenceTable === 'number' ? f.referenceTable : db.getTableByCombinedName(f.referenceTable).tableId] = f));

            const displayColumns: ICustomSelectColumnMap[] = [];
            const additionalColumns: ISelectColumnMap[] = [];
            const defaultFilters: Dictionary<IFilterCondition | IFilterGroup> = {};
            const defaultValuesObj: Dictionary<IDefaultValueParameter> = {};
            const valueMap: Dictionary<string> = {};

            if (isInterface<IOptionalAuto>(props.autoEditor, 'filters')) {
              objectEach(props.autoEditor.filters, (filter) => {
                const eField = typeof filter === 'number' ? db.mapColumnToSelectColumnMap(baseTable.columns[filter]) : db.mapSelectColumnsByNamesWithId(baseTable.tableId, filter)[0];

                additionalColumns.push(eField);
                valueMap[filter] = eField.columnAlias;
              });
            }
            if (isInterface<IOptionalAuto>(props.autoDelete, 'filters')) {
              objectEach(props.autoDelete.filters, (filter) => {
                const dField = typeof filter === 'number' ? db.mapColumnToSelectColumnMap(baseTable.columns[filter]) : db.mapSelectColumnsByNamesWithId(baseTable.tableId, filter)[0];

                additionalColumns.push(dField);
                valueMap[filter] = dField.columnAlias;
              });
            }

            let keyField: ISelectColumnMap | undefined = undefined;
            let displayField: ISelectColumnMap | undefined = undefined;
            // let companyField: IColumnMap | undefined = undefined;

            const quickFilter: IQuickFilterField[] = [];
            if (props.quickFilter != null) {
              props.quickFilter.forEach((f) => {
                if (isInterface<IQuickFilterPath>(f, 'path')) {
                  const { path, ...obj } = f;

                  quickFilter.push({
                    ...obj,
                    field: db.mapSelectColumnsByNamesWithId(baseTable.tableId, [f.path])[0]
                  });
                } else {
                  quickFilter.push(f);
                }
              });
            }

            objectSortEach(baseTable.columns, nameof<IColumn>('order'), ({ item }) => {
              if (item.viewSecurable && !props.profile.isTrainingFacility) return;

              if (baseTable.primaryKeyId === item.columnId) {
                keyField = db.mapColumnToSelectColumnMap(item);
                additionalColumns.push(keyField!);

                return;
              }

              if (isHiddenAddDefaultFilter(item, defaultFilters, defaultValuesObj, propColFilters, propRefFilters) || props.columns != null) return;

              if (item.referenceTableId != null) {
                additionalColumns.push(db.mapColumnToSelectColumnMap(item));
                displayColumns.push(db.mapSelectLookupColumn(item));
              } else if (item.columnId === baseTable.displayKeyId) {
                displayField = db.mapColumnToSelectColumnMap(item);

                if (item.readonly) {
                  additionalColumns.push(displayField!);
                } else {
                  // if readonly display, then is a code or lookup column and shouldn't be displayed, as it should be composed of other fields already displayed
                  displayColumns.push(db.mapColumnToSelectColumnMap(item));
                }
              } else {
                displayColumns.push(db.mapColumnToSelectColumnMap(item));
              }
            });

            if (props.columns != null) {
              keyField = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.primaryKeyId!]);
              displayField = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.displayKeyId]);

              props.columns.forEach((c) => {
                if (isInterface<IAutoColumn>(c, 'name')) {
                  if (c.excludeTable) {
                    additionalColumns.push(db.mapSelectColumnsByNamesWithId(baseTable.tableId, [c.name])[0]);
                  } else {
                    const { display, displayControl } = c;

                    displayColumns.push({
                      ...db.mapSelectColumnsByNamesWithId(baseTable.tableId, [c.name])[0],
                      display,
                      displayControl
                    });
                  }
                } else {
                  displayColumns.push(db.mapSelectColumnsByNamesWithId(baseTable.tableId, [c])[0]);
                }
              });
            }

            const defaultValues = objectMapArray(defaultValuesObj, (_k, v) => v);

            const gridStateUpdate = {
              keyField,
              displayField,
              displayColumns,
              additionalColumns,
              exportTitle: baseTable.title,
              baseTable: baseTable.tableId
            };

            const stateUpdate = {
              defaultFilters,
              defaultValues,
              quickFilter,
              valueMap,
              createTitle: `Add New ${baseTable.title}`,
              validTable: true
            };

            loadData({
              ...gridState,
              ...gridStateUpdate
            }, {
              ...state,
              ...stateUpdate
            });

            setGridState((old) => ({
              ...old,
              ...gridStateUpdate
            }));

            setState((old) => ({
              ...old,
              ...stateUpdate,
              loadingConfig: false
            }));
          } else {
            setGridState({ ...defaultGridState });

            setState((old) => ({
              ...old,
              editId: undefined,
              deleteId: undefined,
              deleteRow: undefined,
              cache: undefined,
              defaultFilters: {},
              loadingConfig: false
            }));
          }
        }
      });
    }
  }, [props.tableName, JSON.stringify(props.quickFilter ?? '{}')]);

  const hideAction = (p: IProfileState, map: Dictionary<string>, r?: AutoRow, show?: boolean | IOptionalAuto): boolean => {
    if (show == null) return true;

    if (r == null) return true;

    if (typeof show === 'boolean') return !show;

    if (show.hideByProfile != null && show.hideByProfile(p)) return true;

    return hasAnyMatch(show.filters, (filterValue, filterKey) => {
      if (Array.isArray(filterValue)) {
        if (isNumberArray(filterValue)) return filterValue.indexOf(r[map[filterKey]] as number) < 0;

        return filterValue.indexOf(r[map[filterKey]] as string) < 0;
      }

      return filterValue === undefined ? r[map[filterKey]] != null : r[map[filterKey]] !== filterValue;
    });
  };

  const defaultPreActions = (): AutoGridActions => [{
    icon: <Delete />,
    hidden: (p, r) => hideAction(p, state.valueMap, r, props.autoDelete),
    title: 'Delete',
    onClick: (r) => setState((old) => ({
      ...old,
      deleteId: r[(gridState.keyField as ISelectColumnMap).columnAlias],
      deleteRow: r
    }))
  }, {
    icon: <Edit />,
    hidden: (p, r) => hideAction(p, state.valueMap, r, props.autoEditor),
    title: 'Edit',
    onClick: (r) => setState((old) => ({
      ...old,
      editId: r[(gridState.keyField as ISelectColumnMap).columnAlias],
      deleteId: undefined,
      deleteRow: undefined
    }))
  }];

  const [preActions, setPreActions] = React.useState(defaultPreActions());

  React.useEffect(() => setPreActions(defaultPreActions()), [props.autoEditor, props.autoDelete, gridState.keyField, props.profile]);

  const handleEditCancel = () => {
    if (state.editId) {
      setState((old) => ({
        ...old,
        editId: undefined
      }));
    }
  };

  const handleEditSave = (newId: number, result: AutoRow) => {
    if (state.editId != null) {
      const newRowData = cloneDeep(gridState.rowData);

      objectEach(result, (key, v) => (newRowData[newId][key] = v));

      setGridState((old) => ({
        ...old,
        rowData: newRowData
      }));
    } else {
      loadData(gridState, state);
    }

    handleEditCancel();

    if (props.onSaveCallback) {
      props.onSaveCallback();
    }
  };

  React.useEffect(() => {
    if (props.forceUpdateStamp != null) {
      loadData(gridState, state);
    }
  }, [props.forceUpdateStamp]);

  // const handleEditCache = (cache: AutoEditorCache) => setState(old => ({ ...old, cache }));

  const handleDeleteCancel = () => {
    setState((old) => ({
      ...old,
      deleteId: undefined,
      deleteRow: undefined
    }));
  };

  const handleBulkDeleteSuccess = (deleteIds: string[], success: boolean) => {
    if (success) {
      const newRowData = cloneDeep(gridState.rowData);
      deleteIds.forEach((deleteId) => delete newRowData[deleteId]);

      setGridState((old) => ({
        ...old,
        rowData: newRowData,
        rowDataLastUpdate: moment().valueOf()
      }));
    }
  };

  const handleDeleteSuccess = (success: boolean) => {
    if (success) {
      const newRowData = cloneDeep(gridState.rowData);
      delete newRowData[state.deleteId!];

      setGridState((old) => ({
        ...old,
        rowData: newRowData,
        rowDataLastUpdate: moment().valueOf()
      }));
    }

    handleDeleteCancel();

    if (props.onDeleteCallback) {
      props.onDeleteCallback();
    }
  };

  return state.validTable == null ? (
    <Typography>Loading configuration</Typography>
  ) : !state.validTable ? (
    <Typography>Unable to open table {props.tableName}</Typography>
  ) : (
    <>
      {!props.autoEditor || props.tableName === '' ? undefined : (
        <AutoEditor
          baseTable={props.tableName}
          editId={state.editId}
          open={state.editId != null}
          onSave={handleEditSave}
          onCancel={handleEditCancel}
          company={props.company}
          displayContext={props.editorContext}
          minHeight={props.minHeight}
          maxHeight={props.maxHeight ?? 'calc(100vh - 80px)'}
          mode={state.editId == null ? 'button' : 'direct'}
          buttonTitle={props.buttonTitle ?? state.createTitle}
          title={props.editorTitle}
          defaultValues={state.defaultValues}
        />
      )}
      {!props.autoEditor || !props.bulkCreate || props.tableName === '' ? undefined : (
        <BulkCreator
          baseTable={props.tableName}
          buttonTitle={props.bulkButtonTitle ?? state.bulkCreateTitle}
          mode="button"
        />
      )}
      <AutoGrid
        {...gridState}
        preActions={preActions}
        onRowClick={props.onRowClick}
        minHeight={props.minHeight}
        maxHeight={props.maxHeight}
        pagination={props.pagination}
        defaultSortColumn={props.defaultSortColumn}
        defaultSortOrder={props.defaultSortOrder}
        bulkDelete={props.bulkDelete}
        bulkEdit={props.bulkEdit}
        onBulkDelete={handleBulkDeleteSuccess}
      />
      {state.deleteId == null ? undefined :
        <AutoDeletePrompt
          baseTable={props.tableName}
          deleteId={state.deleteId}
          mode="direct"
          //UNUSED: What does this even do
          key={-1}
          open={state.deleteId != null}
          rowData={state.deleteRow}
          displayField={gridState.displayField}
          displayColumns={gridState.displayColumns}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteSuccess}
        />}
    </>
  );
};

export default compose<ComponentType<IDefaultAutoGridProps>>(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(DefaultAutoGrid);

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../../stores';
import settings from '../../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import { quickGrid } from '../../../abstracts/DataroweHelpers';
import { Paper, Grid, TextField, Theme, } from '@mui/material';
import { useQueryParams } from 'src/abstracts/NavigationHelpers';
import CheckinList from '../../../components/training/checkin/CheckinList';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  }
});

interface IConnectedProps {
  classes: any;
}

const CheckinPage = (props: IConnectedProps) => {
  const { classes } = props;
  const [params, setParams] = useQueryParams<{ date: string }>({
    date: {
      type: 'string',
      defaultValue: moment().format(settings.apiDateFormatMoment),
      useDateValidation: true
    }
  });

  let momentDate = params.date == null ? moment() : moment(params.date, settings.apiDateFormatMoment);
  if (!momentDate.isValid()) momentDate = moment();

  const handleDateChange = (dateValue: string) => {
    const date = dateValue ? moment(dateValue) : undefined;

    if (typeof date !== 'undefined'){
      setParams({ date: date.format(settings.apiDateFormatMoment) });
    }
  };

  const [quickText, setQuickText] = React.useState('');
  const handleTextUpdate = (value: string) => setQuickText(value);

  const controlGridSizes = quickGrid(12, 6, 4, 4, 4);
  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Grid container spacing={3}>
          <Grid item {...controlGridSizes}>
            <TextField
              fullWidth
              id="mui-pickers-date"
              InputLabelProps={{ shrink: true }}
              label="Date"
              onChange={event => handleDateChange(event.currentTarget.value)}
              type="date"
              value={momentDate.format(settings.apiDateFormatMoment)}
            />
          </Grid>
          <Grid item {...controlGridSizes}>
            <TextField label="Quick Filter" fullWidth value={quickText} onChange={(event) => handleTextUpdate(event.currentTarget.value)} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentWrapper}>{
        momentDate == null ? undefined : <CheckinList
          key="dailyCheckin"
          checkinDate={params.date}
          maxHeight="calc(100vh - 300px)"
          quickFilter={quickText} />
        }
      </div>
    </Paper>
  );
};

const mapFromAppState = ({ profile, oidc }: IAppState) => ({
  profile,
  user: oidc.user
});

export default compose(withStyles(styles, { withTheme: true }), connect(mapFromAppState))(CheckinPage);

import { compose } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import { IoQrCodeOutline } from 'react-icons/io5';
import { showQRLink } from 'src/stores/ui/actions';
import { createStyles, withStyles } from '@mui/styles';

const styles = () => createStyles({});

interface IQRLinkProps {
  classes: any;
  link?: string;
  title: string;
  showQRLink: typeof showQRLink
}

const QRLink = (props: IQRLinkProps) => {
  const { link, title } = props;
  return link == null ? null : (
    <IconButton onClick={() => props.showQRLink({ link, title })}>
      <IoQrCodeOutline />
    </IconButton>
  );
};

const mapActions = {
  showQRLink
};

export default compose(withStyles(styles), connect(undefined, mapActions))(QRLink);

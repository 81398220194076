import moment from 'moment';
import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import QRLink from '../common/QRLink';
import { Dictionary, xor } from 'lodash';
import { AutoRow } from '../auto/AutoLoad';
import settings from 'src/abstracts/settings';
import { Theme, Link, Grid } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import CompanySelectList from '../identity/CompanySelectList';
import { ICompanyAlias } from 'src/stores/database/interfaces';
import { mapProfileDbFromAppState } from 'src/stores/database/types';
import { mapConfigFetchToProps, IConnectedProps } from '../auto/AutoGrid';
import { objectMapArray, quickGrid } from 'src/abstracts/DataroweHelpers';
import DateRangeEdit, { datesToString, IDateRange } from '../common/DateRangeEdit';
import DefaultAutoGrid, { IAutoColumn, IQuickFilterPath } from '../auto/DefaultAutoGrid';

const styles = (theme: Theme) => createStyles({
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  },
  verticalCenter: {
    alignItems: 'center'
  }
});

interface IWorkerScheduleProps {
  classes: any;
  hidden?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
}

interface IWorkerWebScheduleState {
  trainingDate?: IDateRange;
  companies: Dictionary<AutoRow>;
  companyIds?: number[];
  companyAliases?: ICompanyAlias;
}

export const workerWebAssignLink: IAutoColumn = {
  name: 'trainingLink',
  display: (r, k) => r[k],
  displayControl: (r, k, c) => {
    const link = c?.columnAlias == null ? undefined : r[c.columnAlias];

    if (link == null) return undefined;

    return (
      <>
        <Link style={{ color: '#3391FF' }} href={link} target="_blank" rel="noreferrer">Open Training</Link>
        <QRLink link={link} />
      </>
    );
  }
};

const WorkerWebSchedule = (props: IWorkerScheduleProps & IConnectedProps) => {
  const { classes } = props;

  const [viewState, setViewState] = React.useState<IWorkerWebScheduleState>({
    companies: {},
    trainingDate: {
      startDate: moment().format(settings.apiDateFormatMoment),
      includeNull: true
    }
  });

  const handleTrainDateRange = (save?: IDateRange) => setViewState(old => ({
    ...old,
    trainingDate: save
  }));

  const createQuickFilter = (): IQuickFilterPath[] => {
    const filters: IQuickFilterPath[] = [{ path: 'completeDateUTC' }];

    if (viewState.trainingDate != null) {
      filters.push({ path: 'connectedWorkerSchedule.courseSchedule.trainingDate', value: viewState.trainingDate });
    }

    if (viewState.companyIds != null) {
      filters.push({ path: 'userCompany.company', value: viewState.companyIds.length === 0 ? undefined : viewState.companyIds });
    }

    return filters;
  };

  const handleCompaniesChange = (companies: Dictionary<AutoRow>, aliases: ICompanyAlias) => {
    if (companies == null) {
      setViewState(old => ({
        ...old,
        companies,
        companyAliases: aliases,
        companyIds: undefined
      }));
    } else {
      // objectMapArray automatically discards null/undefined
      const ids = objectMapArray(companies, (k, v) => v[aliases.companyId]);
      if (!(ids.length === 0 && (viewState.companyIds?.length ?? 0) === 0) && xor(viewState.companyIds, ids).length > 0) {
        setViewState(old => ({
          ...old,
          companies,
          companyAliases: aliases,
          companyIds: ids.length === 0 ? undefined : ids
        }));
      }
    }
  };

  const companyButtonTitle = () => {
    if (viewState.companyIds == null || viewState.companyIds.length === 0) return 'Filter by Company(ies)';

    if (viewState.companyIds.length > 3) return `Companies: ${viewState.companyIds.slice(0, 3).map(c => viewState.companies[c][viewState.companyAliases!.displayName]).join(', ')} (+${viewState.companyIds!.length - 3})`;

    return `Companies: ${viewState.companyIds.map(c => viewState.companies[c][viewState.companyAliases!.displayName])}`;
  };

  const controlGridSizes = quickGrid(12, 6, 4, 3, 2);
  return (
    <div style={{ maxWidth: '100vw' }} hidden={props.hidden}>
      <Grid container spacing={3} className={classes.verticalCenter}>
        <Grid item {...controlGridSizes}>
          <DateRangeEdit
            fullWidth
            mode="button"
            optional
            nullableStart
            nullableEnd
            nullableDate
            dateRange={viewState.trainingDate}
            onSave={handleTrainDateRange}
            buttonTitle={`Training Date\n${viewState.trainingDate == null ? 'Not Filtered' : datesToString(viewState.trainingDate)}`}
            titleOverrides={{
              dialogHeader: 'Filter by related Training Date',
              nullableOption: 'Include training not connected to in-class training'
            }}
          />
        </Grid>
        {!props.profile.isTrainingFacility ? undefined : <Grid item {...controlGridSizes}>
          <CompanySelectList
            mode="button"
            buttonTitle={companyButtonTitle()}
            companies={viewState.companies}
            onCompaniesChange={handleCompaniesChange}
          />
        </Grid>}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DefaultAutoGrid
            minHeight={props.minHeight}
            maxHeight={props.maxHeight}
            tableName="training.workerWebAssign"
            columns={[
              'person.worker.person.iecNumber',
              'person.worker.displayName',
              'course.title',
              'userCompany.company.name',
              'userCompany.user.displayName',
              'userCompany.user.phoneNumber',
              'connectedWorkerSchedule.courseSchedule.trainingDate',
              'webAssignStatus',
              // '(training.bookSessionWebTraining.workerWebAssign).bookSession.completedUTC',
              workerWebAssignLink
            ]}
            quickFilter={createQuickFilter()}
            pagination
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default compose(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(WorkerWebSchedule);

import 'date-fns';
import React from 'react';
import moment from 'moment';
import { Dictionary } from 'lodash';
import settings from '../../abstracts/settings';
import CourseListSeats from './CourseListSeats';
import FitTestSelector from './FitTestSelector';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../../stores/profile/types';
import { ITrainingClassSchedule } from '../../stores/training/booking/types';
import { ICourse, IFitTestConfig } from '../../stores/database/training/courses';
import { nameof, objectSortMap } from '../../abstracts/DataroweHelpers';
import { formatTime, getFitTestType, IFitTestScheduleSlot, IScheduleData, ITimeslotData } from '../../stores/database/training/courseSchedule';
import { List, Theme, ListItem, Grid, Typography, Tooltip, Menu, FormControlLabel, Switch, ButtonGroup, Button, Link, useTheme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  contentWrapper: {
    margin: `${theme.spacing(2)} ${theme.spacing(1.25)}`
  },
  heightLimitGridCell: {
    overflow: 'auto',
    fontSize: '90%'
  },
  priceDisplay: {
    textAlign: 'right'
  },
  scheduleDateHeader: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
    fontWeight: 'bold',
    width: '100%'
  },
  rowChanges: {
    color: theme.palette.grey[500],
    fontStyle: 'italic'
  },
  limitCompany: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  siteSpecificWeb: {
    fontStyle: 'italic',
    color: theme.palette.primary.main
  },
  tightVertical: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },
  offsetLeft: {
    marginLeft: theme.spacing(1)
  }
});

export type CourseActionType = (actionable: ITrainingClassSchedule | ICourse, menuParams?: any, fitTime?: string, fitConfig?: IFitTestConfig[], slotData?: ITimeslotData[]) => React.ReactElement;

interface ICourseListProps {
  classes: any;
  courseSchedule: ITrainingClassSchedule[];
  courses: Dictionary<ICourse>;
  menuParams?: any;
  maxHeight?: string | number;
  courseActions: CourseActionType[];
  profile: IProfileState;
}

interface ICourseListActions {
  onCourseActionCancel?: () => void;
}

const hasPrereq = (course: ICourse) => course.prereqTitle != null && course.prereqTitle.length > 0 && course.prereqDescription != null && course.prereqDescription.length > 0;

const CourseList = (props: ICourseListProps & ICourseListActions) => {
  type ListMode = 'date' | 'course' | 'fittest' | 'webtraining' | 'smas';
  type DisplayPrices = number | '$$$';

  const theme = useTheme();

  const { classes, profile } = props;

  interface IViewState {
    mode: ListMode;
    showFull: boolean;
    courseFilter: number[];
    schedule?: ITrainingClassSchedule;
    course?: ICourse;
    anchor?: HTMLElement;
    fitTestChoices: IFitTestConfig[];
    fitTestTitle: string;
    selectedTime?: string;
  }

  const [listState, setListState] = React.useState<IViewState>({
    mode: 'date',
    showFull: true,
    courseFilter: [],
    fitTestChoices: [],
    fitTestTitle: 'Select Fit Test(s) to be Completed'
  });

  const handleScheduleSelect = (event: React.MouseEvent<HTMLElement>, schedule: ITrainingClassSchedule, selectedTime?: string) => setListState(old => ({
    ...old,
    selectedTime,
    schedule,
    anchor: event.currentTarget,
    course: undefined
  }));

  const handleCourseSelect = (event: React.MouseEvent<HTMLElement>, course: ICourse) => {
    setListState(old => ({
      ...old,
      course,
      anchor: event.currentTarget,
      selectedTime: undefined,
      schedule: undefined
    }));
  };

  const handleModeSelect = (mode: ListMode) => setListState(old => ({
    ...old,
    mode
  }));

  const handleShowFullToggle = () => setListState(old => ({
    ...old,
    showFull: !old.showFull
  }));

  const handleMenuCancel = () => {
    setListState(old => ({
      ...old,
      anchor: undefined,
      seats: undefined,
      selectedTime: undefined
    }));

    if (props.onCourseActionCancel) props.onCourseActionCancel();
  };

  const handleFitTestChoicesChange = (fitTestChoices: IFitTestConfig[]) => {
    setListState(old => ({
      ...old, fitTestChoices, fitTestTitle: `Booking Fit Tests: ${fitTestChoices.map((c) => {
        switch (c.scheduleType) {
          case 'rft-apr': return 'Air Purifying Respirator';
          case 'rft-sctt': return 'Scott Fresh Air';
          case 'rft-n95': return 'N95';
          default: return 'Draeger Fresh Air';
        }
      }).join(', ')}`
    }));
  };

  const gridStyle = (persons: any[]): { paddingBottom?: number } => ({ paddingBottom: persons.length === 0 ? undefined : 0 });

  const makeLink = (location: string, locationMap: string) => <Link sx={{ color: '#3391FF' }} href={locationMap} target="_blank" rel="noreferrer">{location}</Link>;

  const locationDetail = (location: string, locationMap?: string, reservedCompany?: string) => {
    if (reservedCompany == null) return <Typography>Location: {locationMap == null ? location : makeLink(location, locationMap)}</Typography>;

    return (
      <Tooltip title={`Reserved for ${reservedCompany}`}>
        <Typography className={classes.limitCompany}>
          <sup>1</sup>Location: {locationMap == null ? location : makeLink(location, locationMap)}
        </Typography>
      </Tooltip>
    );
  };

  const displayDateRow = (cls: ITrainingClassSchedule, displayPrice: DisplayPrices) => {
    return (
      <ListItem button className={classes.selectableRow} key={cls.courseScheduleId} onClick={e => handleScheduleSelect(e, cls)}>
        <Grid container spacing={2}>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={6} sm={2}>
            {locationDetail(cls.location, cls.locationMap, cls['reservedCompany.name'])}
          </Grid>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={3} sm={2} md={1}>
            <Tooltip title={cls.courseScheduleId}>
              <Typography align="right">
                {moment(cls.trainingDate).format(settings.timeFormatMoment)}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={12} sm={3} md={4}>
            <Tooltip title={hasPrereq(cls.course) ? <>
              <Typography variant="h6">
                {cls.course.prereqTitle}
              </Typography>
              <Typography variant="body2">
                {cls.course.prereqDescription}
              </Typography>
            </> : ''}
            >
              <Typography>
                {cls.course.title}{hasPrereq(cls.course) ? '*' : ''} ({cls.course.code})
              </Typography>
            </Tooltip>
          </Grid>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={6} sm={3}>
            <CourseListSeats seats={cls.seats} />
          </Grid>
          <Grid className={classes.priceDisplay} item xs={6} sm={1} style={gridStyle(cls.attachedPersons)}>
            <Typography>{displayPrice === '$$$' ? displayPrice : new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(displayPrice)}</Typography>
          </Grid>
          {cls.attachedPersons.length === 0 ? undefined :
            <Grid item sm={12} style={{ paddingTop: 0, paddingLeft: '40px' }}>
              <Typography className={classes.rowChanges}>Adding: {cls.attachedPersons.map(x => x.display).join(', ')}</Typography>
            </Grid>}
        </Grid>
      </ListItem>
    );
  };

  const displayCourseRow = (cls: ITrainingClassSchedule, displayPrice: DisplayPrices) => {
    return (
      <ListItem button className={classes.selectableRow} key={cls.courseScheduleId} onClick={e => handleScheduleSelect(e, cls)}>
        <Grid container spacing={2}>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={4} sm={3}>
            {locationDetail(cls.location, cls.locationMap, cls['reservedCompany.name'])}
          </Grid>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={8} sm={4}>
            <Tooltip title={cls.courseScheduleId}>
              <Typography align="left">
                {moment(cls.trainingDate).format(settings.dateWeekdayTimeFormatMoment)}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={6} sm={2}>
            <CourseListSeats seats={cls.seats} />
          </Grid>
          <Grid className={classes.priceDisplay} item xs={6} sm={1} style={gridStyle(cls.attachedPersons)}>
            <Typography>{displayPrice === '$$$' ? displayPrice : new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(displayPrice)}</Typography>
          </Grid>
          {cls.attachedPersons.length === 0 ? undefined :
            <Grid item sm={12} style={{ paddingTop: 0, paddingLeft: '40px' }}>
              <Typography className={classes.rowChanges}>Adding: {cls.attachedPersons.map(x => x.display).join(', ')}</Typography>
            </Grid>}
        </Grid>
      </ListItem>
    );
  };

  const disableFitTestTime = (time: string, slotGroup: IScheduleData, idx: number) => {
    if (listState.fitTestChoices.length === 0) return true;

    if (moment(`2000-01-01 ${slotGroup.end}`).diff(moment(`2000-01-01 ${time}`), 'minutes') < listState.fitTestChoices.reduce((a, b) => a + getFitTestType(slotGroup, b.scheduleType).duration, 0)) return true;

    for (let i = 0; i < listState.fitTestChoices.length; i++) {
      if (listState.fitTestChoices[i].scheduleType == null) return true;

      if (slotGroup.slots.length <= i + idx) return true;

      if (slotGroup.slots[idx + i].scheduleType == null) {
        if (listState.fitTestChoices.slice(i).reduce((u, v) => u + getFitTestType(slotGroup, v.scheduleType).duration, 0) > getFitTestType(slotGroup, slotGroup.slots[idx + i].scheduleType).duration) return true;

        if (slotGroup.slots.length <= i + idx + 1) return false;
      } else {
        if (slotGroup.slots[idx + i].usedSeats >= (slotGroup.fitTestTypes.find(x => x.scheduleType === slotGroup.slots[idx + i].scheduleType)?.maxSeats ?? 0)) return true;

        if (slotGroup.slots[idx + i].scheduleType !== listState.fitTestChoices[i].scheduleType) return true;

        // if the slot has is self contained or is supplied air, then has to match at least one selection; one both and the other just one, or matching the same just one. Can't allow
        if ((slotGroup.slots[idx + i].isSelfContained === true || slotGroup.slots[idx + i].isSuppliedAir === true) && !(slotGroup.slots[idx + i].isSelfContained && listState.fitTestChoices[i].isSelfContained) && !(slotGroup.slots[idx + i].isSuppliedAir && listState.fitTestChoices[i].isSuppliedAir)) return true;
      }
    }

    return false;
  };

  const displayFitTestRow = (cls: ITrainingClassSchedule) => {
    return (
      <ListItem button className={classes.selectableRow} key={cls.courseScheduleId}>
        <Grid container spacing={2}>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={6} sm={2}>
            {locationDetail(cls.location, cls.locationMap, cls['reservedCompany.name'])}
          </Grid>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={6} sm={2}>
            <Tooltip title={cls.courseScheduleId}>
              <Typography align="left">
                {moment(cls.trainingDate).format(settings.dateWeekdayFormatMoment)}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid style={gridStyle(cls.attachedPersons)} item xs={12} sm={8}>
            {cls.seats?.scheduleData == null ? undefined : cls.seats.scheduleData.map(s =>
              <ButtonGroup key={`${cls.courseScheduleId}_${s.start}`}>
                {s.slots.map((t: IFitTestScheduleSlot, idx: number) =>
                  <Button
                    key={`${cls.courseScheduleId}_${t.scheduledTime}`}
                    onClick={e => handleScheduleSelect(e, cls, t.scheduledTime)}
                    disabled={disableFitTestTime(t.scheduledTime, s, idx)}
                  >
                    {formatTime(t.scheduledTime)}
                  </Button>
                )}
              </ButtonGroup>
            )}
          </Grid>
          {cls.attachedPersons.length === 0 ? undefined :
            <Grid item sm={12} style={{ paddingTop: 0, paddingLeft: '40px' }}>
              <Typography className={classes.rowChanges}>Adding: {cls.attachedPersons.map(x => x.display).join(', ')}</Typography>
            </Grid>}
        </Grid>
      </ListItem>
    );
  };

  const displaySmaCourseRow = (smaCourseSchedule: ITrainingClassSchedule): JSX.Element => {
    const displayPrice: DisplayPrices = (profile.pricingField == 'pricePartner' ? smaCourseSchedule.seats?.pricePartner : smaCourseSchedule.seats?.priceNonPartner) ?? '$$$';
    const reservedCompany = smaCourseSchedule['reservedCompany.name'];
    const courseListSeats = <CourseListSeats seats={smaCourseSchedule.seats} asBoolean />;

    const availability = reservedCompany ? (
      <Tooltip title={`Reserved for ${reservedCompany}`}>
        <span className={classes.limitCompany}>
          <sup>1</sup> {courseListSeats}
        </span>
      </Tooltip>
    ) : courseListSeats;

    return (
      <ListItem button className={classes.selectableRow} key={smaCourseSchedule.courseScheduleId} onClick={e => handleScheduleSelect(e, smaCourseSchedule)}>
        <Grid container spacing={2}>
          <Grid style={gridStyle(smaCourseSchedule.attachedPersons)} item xs={12} sm={5} md={4}>
            <Typography align="left">
              {moment(smaCourseSchedule.trainingDate).format(settings.dateWeekdayTimeFormatMoment)}
            </Typography>
          </Grid>
          <Grid style={gridStyle(smaCourseSchedule.attachedPersons)} item xs={6} sm={3}>
            {availability}
          </Grid>
          <Grid className={classes.priceDisplay} item xs={6} sm={1} style={gridStyle(smaCourseSchedule.attachedPersons)}>
            <Typography>{displayPrice === '$$$' ? displayPrice : new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(displayPrice)}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  const displayWebCourseRow = (course: ICourse) => {
    const tooltipTitle: JSX.Element | '' = !hasPrereq(course) ? '' : (
      <>
        <Typography variant="h6">
          {course.prereqTitle}
        </Typography>
        <Typography variant="body2">
          {course.prereqDescription}
        </Typography>
      </>
    );

    const coursePrice: string = course[profile.pricingField] == null ? '$$$' : new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(course[profile.pricingField]);

    return (
      <ListItem button className={classes.selectableRow} key={course.courseId} onClick={e => handleCourseSelect(e, course)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>{course.code}</Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip title={tooltipTitle}>
              <Typography>
                {course.title}{hasPrereq(course) ? '*' : ''} ({course.code})
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={3}>{coursePrice}</Grid>
        </Grid>
      </ListItem>
    );
  };

  const displayRow = (cls: ITrainingClassSchedule) => {
    if (cls.reservedCompany != null && !profile.isTrainingFacility && profile.companies.findIndex(x => x.companyId === cls.reservedCompany) < 0) {
      return undefined;
    }

    const displayPrice: DisplayPrices = (profile.pricingField == 'pricePartner' ? cls.seats?.pricePartner : cls.seats?.priceNonPartner) ?? '$$$';

    switch (listState.mode) {
      case 'date':
        return displayDateRow(cls, displayPrice);
      case 'course':
        return displayCourseRow(cls, displayPrice);
      case 'fittest':
        return displayFitTestRow(cls);
      case 'smas':
        return undefined;
      case 'webtraining':
        return undefined;
    }
  };

  const renderTab = (): JSX.Element | React.ReactNode[][] | React.ReactNode[] | undefined => {
    if (listState.mode === 'webtraining') {
      return (
        <>
          <ListItem key="webtraining-header" className={classes.scheduleDateHeader}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  BASES offers Web-Based Training that can be accessed via computer, tablet, or smart phone.
                  To minimize time spent by a worker in class, this training should be accessed remotely at the worker’s convenience.
                  If remote access is not available, please contact BASES for assistance.
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          {objectSortMap(props.courses ?? {}, nameof<ICourse>('title'), i => i.item.online && i.item.allowWebAssign ? displayWebCourseRow(i.item) : undefined)}
        </>
      );
    }

    if (listState.mode === 'smas') {
      const smaRows = (props.courseSchedule ?? []).filter((courseSchedule) => {
        return courseSchedule.course.scheduleTypeKey === 'sma' && (listState.showFull ? true : (courseSchedule.seats?.available || 0) + (courseSchedule.seats?.reserves?.reduce((a, b) => a + b.available, 0) || 0) > 0);
      }).sort((a, b) => {
        return moment(a.trainingDate).valueOf() - moment(b.trainingDate).valueOf();
      });

      const rows: React.ReactNode[] = [];
      if (smaRows.length) {
        const newKey = 'Safety Management Assessment (SMA)';

        rows.push(
          <ListItem key={newKey} className={classes.scheduleDateHeader}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={7}>
                <Typography variant="subtitle2">{newKey}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        );
      }

      smaRows.map((smaCourse) => {
        rows.push(displaySmaCourseRow(smaCourse));
      });

      return rows;
    }

    let recentKey = '';

    const displayRows = (props.courseSchedule ?? []).filter(x => {
      return x.course.scheduleTypeKey !== 'sma' && (x.course.scheduleTypeKey === 'fittest') === (listState.mode === 'fittest') && (listState.showFull || listState.mode === 'fittest' ? true : (x.seats?.available || 0) + (x.seats?.reserves?.reduce((a, b) => a + b.available, 0) || 0) > 0);
    }).sort((a, b) => {
      return listState.mode === 'date' ? moment(a.trainingDate).valueOf() - moment(b.trainingDate).valueOf() : a.course.title === b.course.title ? moment(a.trainingDate).valueOf() - moment(b.trainingDate).valueOf() : a.course.title.localeCompare(b.course.title);
    }).map((cls) => {
      const rows: React.ReactNode[] = [];
      const newKey = listState.mode === 'date' ? moment(cls.trainingDate).format(settings.dateWeekdayFormatMoment) : `${cls.course.title} (${cls.course.code})`;

      if (recentKey !== newKey) {
        recentKey = newKey;

        rows.push(
          <ListItem key={newKey} className={classes.scheduleDateHeader}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={7}>
                {listState.mode === 'date'
                  ? <Typography variant="subtitle2">{newKey}</Typography>
                  : <Tooltip
                    title={hasPrereq(cls.course) ? <>
                      <Typography variant="h6">
                        {cls.course.prereqTitle}
                      </Typography>
                      <Typography variant="body2">
                        {cls.course.prereqDescription}
                      </Typography>
                    </> : ''}
                  >
                    <Typography variant="subtitle2">
                      {newKey}{hasPrereq(cls.course) ? '*' : ''}
                    </Typography>
                  </Tooltip>}
              </Grid>
            </Grid>
          </ListItem>
        );

        if (cls.course.scheduleTypeKey === 'fittest' && listState.fitTestChoices.length === 0) {
          rows.push(
            <ListItem key={`${newKey}_warn`}>
              <Typography>Please select a fit test with the button above to see available times</Typography>
            </ListItem>
          );
        }
      }

      if (cls.course.scheduleTypeKey !== 'fittest' || listState.fitTestChoices.length > 0) {
        rows.push(displayRow(cls));
      }

      return rows;
    });

    return displayRows;
  };

  if (props.courseSchedule.length === 0) return (<Typography>Please load dates to see courses</Typography>);

  return (
    <>
      <div className={classes.contentWrapper}>
        <Grid sx={{ marginBottom: theme.spacing(5) }} container spacing={3}>
          <Grid item md={12} lg={10}>
            <ButtonGroup>
              <Button variant={listState.mode === 'date' ? 'contained' : 'outlined'} color="primary" onClick={() => handleModeSelect('date')}>
                Courses by Date
              </Button>
              <Button variant={listState.mode === 'course' ? 'contained' : 'outlined'} color="primary" onClick={() => handleModeSelect('course')}>
                Dates by Course
              </Button>
              <Button variant={listState.mode === 'fittest' ? 'contained' : 'outlined'} color="primary" onClick={() => handleModeSelect('fittest')}>
                Fit Test
              </Button>
              <Button variant={listState.mode === 'webtraining' ? 'contained' : 'outlined'} color="primary" onClick={() => handleModeSelect('webtraining')}>
                Web-Based Training
              </Button>
              <Button variant={listState.mode === 'smas' ? 'contained' : 'outlined'} color="primary" onClick={() => handleModeSelect('smas')}>
                SMAs
              </Button>
            </ButtonGroup>
            <FormControlLabel className={classes.offsetLeft} label="Show Full Classes" control={
              <Switch checked={listState.showFull} onChange={() => handleShowFullToggle()} value="show-full" color="primary" />
            } />
          </Grid>
          <Grid item md={12} lg={2}>
          </Grid>
        </Grid>
        <Grid sx={{ marginBottom: theme.spacing(3) }} container spacing={3}>
          <Grid item xs={12} className={classes.tightVertical}>
            <Typography className={classes.limitCompany}>
              <sup>*</sup> Course has prerequisites. Hover course for details.
            </Typography>
          </Grid>
          {(props.courseSchedule ?? []).some(s => s.reservedCompany != null && (profile?.isTrainingFacility || profile?.companies.some(c => c.companyId === s.reservedCompany))) ?
            <Grid item xs={12} className={classes.tightVertical}>
              <Typography className={classes.limitCompany}>
                <sup>1</sup> Entire class is reserved for a company you are associated with. Hover class for details.
              </Typography>
            </Grid> : undefined}
          {listState.mode === 'fittest'
            ? <Grid item xs={12}>
              <Typography className={classes.limitCompany}>
                <sup>***</sup> Note: As of January 1, 2024, BASES will no longer provide AV-2000 respirators for fit testing. If you are scheduling a fit test for an AV-2000 respirator, please ensure the scheduled trainee brings an AV-2000 respirator with them for the fit test. Trainees who do not bring a respirator with them will be required to reschedule later.
              </Typography>
              <FitTestSelector mode="button" title={listState.fitTestTitle} fitTestConfig={listState.fitTestChoices} onFitTestsSelected={handleFitTestChoicesChange} />
            </Grid>
            : undefined}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List key="course-list" style={{ maxHeight: props.maxHeight || 400 }} className={classes.heightLimitGridCell}>
              {renderTab()}
            </List>
          </Grid>
        </Grid>
      </div>

      {listState.anchor == null ? undefined : (
        <Menu
          id="course-schedule-menu"
          anchorEl={listState.anchor}
          keepMounted
          open={listState.anchor != null}
          onClose={handleMenuCancel}
          onClick={handleMenuCancel}
        >
          {props.courseActions.map(x => x(
            (listState.schedule ?? listState.course)!,
            props.menuParams,
            listState.selectedTime,
            listState.selectedTime == null ? undefined : listState.fitTestChoices,
            listState.selectedTime == null ? undefined : listState.schedule?.seats?.scheduleData
          ))}
        </Menu>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(CourseList);

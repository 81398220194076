import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ComponentType } from 'react';
import AutoEditorField from './AutoEditorField';
import DisplayField from '../common/DisplayField';
import LoadingScreen from '../common/LoadingScreen';
import { Database } from '../../stores/database/class';
import { createStyles, withStyles } from '@mui/styles';
import { ICompany, IProfileState } from 'src/stores/profile/types';
import { IConnectedProps, mapConfigFetchToProps } from './AutoGrid';
import { AutoRow, autoLoadMulti, IAutoMultiProps } from './AutoLoad';
import { cloneDeep, Dictionary, flatten, isEqual, xor } from 'lodash';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { objectEach, objectMapArray, objectRemap, hasAnyMatch, isInterface, objectMap, arrayMapObject } from '../../abstracts/DataroweHelpers';
import { autoEditorChange, IValueParameter, IReferenceParameter, getAliasName, IDefaultValueParameter, IChangeSet, IDefaultNamedParameter } from './AutoChange';
import { IAutoEditItem, AutoEditorCache, IAutoEditField, IDisplayField, IAutoEditorStateExtension, IDefaultStateExtension, IAutoChange } from './autoInterfaces';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Grid, List, ListItem, ListItemText, Divider, TextField, Typography, GridSize } from '@mui/material';
import { IColumnMap, ITable, IFilterDefinition, FilterConditionType, IColumn, ISelectColumnMap, ITableEditorSection, IMultiLookupSection } from '../../stores/database/interfaces';

export interface IDisplayContext {
  display: (profile: IProfileState, company?: ICompany) => boolean;
}

export interface IReferenceTableChange {
  table: string;
  reference: string;
  columns: string[];
  deletes: number[];
  adds: Dictionary<any>[];
  error?: string;
}

//TODO: implement min/max height
interface IAutoEditorProps {
  baseTable: string;
  keyField?: ISelectColumnMap;
  editId?: number;
  readonly?: boolean;
  minHeight?: string | number;
  maxHeight?: string | number;
  resultColumns?: IColumnMap[];
  customEditor?: string;
  title?: string;
  hideComment?: boolean;
  additionalDisplay?: (IDisplayField | ISelectColumnMap)[];
  defaultValues?: (IDefaultValueParameter | IDefaultNamedParameter)[];
  additionalChanges?: (IValueParameter | IReferenceParameter)[];
  unmappedAdditionalChanges?: Dictionary<any>;
  stateExtension?: IDefaultStateExtension;
  splitEditor?: React.ReactElement;
  company?: number;
  displayContext?: Dictionary<IDisplayContext>;
  referenceTableChanges?: Dictionary<IReferenceTableChange>;
  onSave: (newId: number, result: AutoRow) => void;
  onSaveOverride?: () => void;
  onLoad?: (display: string, result: AutoRow) => void;
  onEditIdNotFound?: (editId: number) => void;
  cache?: AutoEditorCache;
  saveCache?: (cache: AutoEditorCache) => void;
}

export interface IAutoEditorButtonProps extends IAutoEditorProps {
  buttonTitle: string;
  disabled?: boolean;
  fullWidth?: boolean;
  mode: 'button';
  onCancel?: () => void;
  open?: boolean;
}

export interface IAutoEditorDirectProps extends IAutoEditorProps {
  mode: 'direct';
  onCancel: () => void;
  open: boolean;
}

export interface IAutoEditorInlineProps extends IAutoEditorProps {
  deleteButton?: boolean;
  mode: 'inline';
  onCancel?: () => void;
}

export const getFieldOrder = (table: ITable, customEditor?: string, isTrainingFacility?: boolean, isPartner?: boolean): ITableEditorSection[] => {
  if ((table.properties?.editors?.filter((x) => x.key === (customEditor ?? 'default')).length ?? 0) > 0) {
    const res = table.properties.editors!.filter((x) => x.key === (customEditor ?? 'default'))[0].sectionOrder;
    return res.filter((x) => {
      if (x.onlyTrainingFacility && !isTrainingFacility) return false;
      if (x.onlyPartner && !isPartner) return false;
      return isInterface<ITableEditorSection>(x, 'fieldOrder');
    }) as ITableEditorSection[];
  }
  return [
    {
      key: 'auto',
      title: '',
      fieldOrder: objectMapArray(table.columns, (key, col) => {
        if (table.primaryKeyId === col.columnId) return undefined;
        if (table.deleteKeyId === col.columnId) return undefined;
        if (col.readonly) return undefined;
        if (col.viewSecurable && !isTrainingFacility) return undefined;
        return col.columnId;
      })
    }
  ];
};

export const getMultiLookupSections = (table: ITable, customEditor?: string, isTrainingFacility?: boolean, isPartner?: boolean): IMultiLookupSection[] => {
  if ((table.properties?.editors?.filter((x) => x.key === (customEditor ?? 'default')).length ?? 0) > 0) {
    const res = table.properties.editors!.filter((x) => x.key === (customEditor ?? 'default'))[0].sectionOrder;
    return res.filter((x) => {
      if (x.onlyTrainingFacility && !isTrainingFacility) return false;
      if (x.onlyPartner && !isPartner) return false;
      return isInterface<IMultiLookupSection>(x, 'lookupReferenceColumn');
    }) as IMultiLookupSection[];
  }
  return [];
};

export const lookupFilters = (db: Database, column: IColumn, defaultValue?: any): IFilterDefinition | undefined => {
  switch (db.getCombinedColumnNameById(column.columnId)) {
    case 'training.courseSchedule.personCompanyInstructorIds':
      return {
        baseTableId: column.referenceTableId!,
        name: '',
        baseFilter: {
          type: FilterConditionType.Equal,
          columnId: db.mapSelectColumnsByNamesWithId(column.referenceTableId!, 'isInstructor')[0].columnId,
          lookupPath: [],
          firstParameter: true
        }
      };
    default: {
      if (defaultValue != null) {
        return {
          baseTableId: column.referenceTableId!,
          name: '',
          baseFilter: {
            columnId: db.getTableById(column.referenceTableId!).primaryKeyId!,
            lookupPath: [],
            type: Array.isArray(defaultValue) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
            firstParameter: defaultValue
          }
        };
      }
      return undefined;
    }
  }
};

const AutoEditor = (props: (IAutoEditorButtonProps | IAutoEditorDirectProps | IAutoEditorInlineProps) & IConnectedProps) => {
  const { profile } = props;

  interface IAutoEditorState {
    open: boolean;
    loading: boolean;
    failedSave: boolean;
    displayName: string;
    table?: ITable;
    fieldOrder: ITableEditorSection[];
    localState: IAutoEditorStateExtension;
    editItem?: IAutoEditItem;
    cache?: AutoEditorCache;
    changeComment: string;
    editable: boolean;
    references: Dictionary<number>;
    referenceTables?: Dictionary<{
      baseTable: ITable;
      referenceColumn: number;
      columns: Dictionary<number>;
    }>;
  }

  const defaultEditState: IAutoEditorState  = {
    open: false,
    loading: false,
    failedSave: false,
    displayName: '',
    fieldOrder: [],
    changeComment: '',
    editable: false,
    references: {},
    localState: {
      selectedUserCompanyId: props.stateExtension?.userCompanyId
    }
  };

  const [editState, setEditState] = React.useState(defaultEditState);

  React.useEffect(() => setEditState((old) => ({
    ...old,
    localState: {
      ...old.localState,
      selectedUserCompanyId: props.stateExtension?.userCompanyId
    }
  })), [props.stateExtension?.userCompanyId]);

  const formatData = (column: IColumn, value: any) => {
    switch (column.columnType) {
      case 'MultiLookup':
        return JSON.parse(value ?? '[]');
      case 'Json':
        return JSON.parse(value ?? '{}');
      default:
        return value;
    }
  };

  const loadReferenceTables = (db: Database) => {
    if (props.referenceTableChanges == null) return undefined;

    return objectMap(props.referenceTableChanges, (_k, changes): {
      baseTable: ITable;
      referenceColumn: number;
      columns: Dictionary<number>;
    } => {
      const baseTable = db.getTableByCombinedName(changes.table);
      return {
        baseTable,
        referenceColumn: baseTable.columnNames[changes.reference.toLowerCase()],
        columns: arrayMapObject(changes.columns, (idx, col) => [col, baseTable.columnNames[col.toLowerCase()]])
      };
    });
  };

  const hasChanges = () => hasAnyMatch(editState.editItem ?? {}, v => v.isChanged) || hasAnyMatch(props.referenceTableChanges ?? {}, v => v.adds.length > 0 || v.deletes.length > 0);

  const loadData = () => {
    if (!editState.loading) {
      setEditState((old) => ({ ...old, loading: true }));
      props.fetchConfigRequest(undefined, {
        key: `autoEditor_${props.baseTable}_${props.editId ?? 'new'}`,
        action: (db) => {
          const baseTable = db.getTableByCombinedName(props.baseTable);

          if (baseTable.primaryKeyId == null) {
            throw new Error('Invalid table for autoEditor - no primary key defined');
          }
          const fieldOrder = getFieldOrder(baseTable, props.customEditor, props.profile.isTrainingFacility, props.profile.isPartner);
          const fieldIds = flatten((fieldOrder as ITableEditorSection[]).map((x) => x.fieldOrder));
          const references: Dictionary<number> = {};
          const selectFields = db.getColumnsByTableId(baseTable.tableId, fieldIds);
          const displayFields: { [columnId: string]: ISelectColumnMap } = {};
          fieldIds.forEach((x) => {
            const col = baseTable.columns[x];
            references[col.name] = col.columnId;
            // if(col.properties.alternateEditor === 'multiLookupInstructors')
            if (col.referenceTableId != null && db.getTableById(col.referenceTableId).displayKeyId != null) {
              if (col.properties.displayMap != null) {
                displayFields[x] = db.mapSelectColumnMap(baseTable.tableId, col.properties.displayMap);
              } else {
                displayFields[x] = db.mapSelectLookupColumn(col);
              }
            }
          });

          objectEach(props.unmappedAdditionalChanges ?? {}, (key) => {
            if (references[key] == null) {
              references[key] = baseTable.columnNames[key.toLowerCase()];
            }
          });

          objectEach(props.displayContext ?? {}, (key)=>{
            if (references[key] == null) {
              references[key] = baseTable.columnNames[key.toLowerCase()];
            }
          });

          (props.defaultValues ?? []).forEach((x)=>{
            if (isInterface<IDefaultNamedParameter>(x, 'columnName') && references[x.columnName] == null){
              references[x.columnName] = baseTable.columnNames[x.columnName.toLowerCase()];
            }
          });

          const displayCol = db.mapColumnToSelectColumnMap(baseTable.columns[baseTable.displayKeyId!]);
          if (displayFields[baseTable.displayKeyId!] == null) {
            displayFields[baseTable.displayKeyId] = displayCol;
          }

          if (props.additionalDisplay != null) {
            props.additionalDisplay.forEach((x) => {
              if (isInterface<ISelectColumnMap>(x, 'columnAlias')) selectFields.push(x);
            });
          }

          const selects: IAutoMultiProps[] = [];
          const cacheMap: AutoEditorCache = props.cache == null ? {} : cloneDeep(props.cache);
          const tableIds: Dictionary<{ refId: string; displayName: string; keyName: string; parentAlias?: string | string[] }> = {};

          // const multiLookupIds: Dictionary<{refKey: ISelectColumnMap; refDisplay: ISelectColumnMap; checkedKey: ISelectColumnMap; rootKey: ISelectColumnMap; }> = {};

          objectEach(baseTable.columns, (key, column) => {
            if (
              column.properties.alternateEditor != null ||
              selectFields.findIndex((x) => x.columnId === column.columnId) < 0 ||
              ['Lookup', 'NestedLookup', 'MultiLookup'].indexOf(column.columnType) < 0 ||
              column.referenceTableId == null ||
              cacheMap[column.referenceTableId] != null
            )
              return;
            const refTable = db.getTableById(column.referenceTableId);
            if (refTable.primaryKeyId == null || refTable.displayKeyId == null) {
              throw new Error(`refTable ${refTable.title} not configured properly; requires both primary key and display key.`);
            }

            const dictKey = `refTable_${db.getCombinedTableNameById(column.referenceTableId)}`;
            tableIds[dictKey] = {
              refId: column.referenceTableId.toString(),
              displayName: getAliasName(refTable.columns[refTable.displayKeyId].name),
              keyName: getAliasName(refTable.columns[refTable.primaryKeyId].name)
            };
            const selectColumns: IColumnMap[] = [
              { columnId: refTable.primaryKeyId, lookupPath: [] },
              { columnId: refTable.displayKeyId, lookupPath: [] }
            ];

            if (column.columnType === 'NestedLookup') {
              const parentIdField = Object.keys(refTable.columns).find((x) => refTable.columns[x].referenceTableId === refTable.tableId);
              selectColumns.push({ columnId: +parentIdField!, lookupPath: [] });
              tableIds[dictKey].parentAlias = getAliasName(refTable.columns[parentIdField!].name);
            } else if ((column.properties.listEditorParentColumns?.length ?? 0) > 0) {
              const parentFields: string[] = [];
              column.properties.listEditorParentColumns!.forEach((c) => {
                selectColumns.push(c);
                parentFields.push(db.mapSelectColumnMap(column.referenceTableId!, c).columnAlias);
              });
              tableIds[dictKey].parentAlias = parentFields;
            }

            selects.push({
              baseTableId: refTable.tableId,
              resultKey: dictKey,
              keyField: db.mapKeySelectColumnByIds(refTable.primaryKeyId),
              columns: selectColumns,
              search: lookupFilters(db, column, props.defaultValues?.find(x => (isInterface<IDefaultNamedParameter>(x, 'columnName') ? references[x.columnName] : x.columnId) === column.columnId))
            });
          });

          if (props.editId != null) {
            const search: IFilterDefinition = {
              baseTableId: baseTable.tableId,
              name: '',
              baseFilter: {
                type: FilterConditionType.Equal,
                columnId: baseTable.primaryKeyId,
                lookupPath: [],
                firstParameter: props.editId
              }
            };

            selects.push({
              search,
              baseTableId: baseTable.tableId,
              keyField: props.keyField!,
              columns: selectFields.concat(objectMapArray(displayFields, (k, v) => v as IColumnMap))
            });
          }
          if (selects.length > 0) {
            autoLoadMulti({ multi: selects, fetchConfigRequest: props.fetchConfigRequest, createAlertBulk: props.createAlertBulk }).then((res) => {
              // if (props.editId != null && Object.keys(res[props.baseTable]).length === 0) { }
              const editItem: IAutoEditItem = {};
              objectEach(res, (key, result) => {
                if (key === props.baseTable) return;
                cacheMap[tableIds[key].refId] = {
                  tableAlias: key,
                  displayAlias: tableIds[key].displayName,
                  keyAlias: tableIds[key].keyName,
                  parentAlias: tableIds[key].parentAlias,
                  rows: result
                };
              });

              selectFields.forEach(({ columnId }) => {
                const aliasName = getAliasName(baseTable.columns[columnId].name);
                const defaultValue = props.defaultValues?.find(x => (isInterface<IDefaultNamedParameter>(x, 'columnName') ? references[x.columnName] : x.columnId) === columnId);
                editItem[columnId] = {
                  editValue: formatData(baseTable.columns[columnId], res[props.baseTable]?.[0]?.[aliasName] ?? defaultValue?.newValue),
                  originalValue: formatData(baseTable.columns[columnId], res[props.baseTable]?.[0]?.[aliasName]),
                  isChanged: defaultValue != null,
                  error: '',
                  warning: '',
                  readonly: defaultValue?.readonly
                };
                // if (!!defaultValue && editItem[columnId].editValue !== editItem[columnId].originalValue) editItem[columnId].isChanged = true;
              });

              setEditState((old) => ({
                ...old,
                fieldOrder,
                editItem,
                references,
                loading: false,
                failedSave: false,
                referenceTables: loadReferenceTables(db),
                editable: fieldIds.some((x) => !baseTable.columns[x].readonly && (!baseTable.columns[x].editSecurable || profile.isTrainingFacility)),
                displayName: props.title ?? (baseTable.displayKeyId == null ? '' : res[props.baseTable]?.[0]?.[displayCol.columnAlias] ?? 'New item'),
                table: baseTable,
                cache: cacheMap
              }));
              if (props.saveCache) props.saveCache(cacheMap);
              if (props.onLoad) props.onLoad(editItem[baseTable.displayKeyId]?.editValue ?? res[props.baseTable]?.[0][displayCol.columnAlias], res[props.baseTable]?.[0]);
            });
          } else {
            const editItem: IAutoEditItem = {};
            selectFields.forEach(({ columnId }) => {
              const def = props.defaultValues?.find((x) => (isInterface<IDefaultNamedParameter>(x, 'columnName') ? references[x.columnName] : x.columnId) === columnId);
              editItem[columnId] = {
                editValue: def?.newValue,
                originalValue: null,
                isChanged: def != null,
                error: '',
                warning: '',
                readonly: def?.readonly
              };
            });
            setEditState((old) => ({
              ...old,
              fieldOrder,
              editItem,
              references,
              loading: false,
              failedSave: false,
              referenceTables: loadReferenceTables(db),
              editable: fieldIds.some((x) => !baseTable.columns[x].readonly && (!baseTable.columns[x].editSecurable || profile.isTrainingFacility)),
              displayName: props.title ?? 'Item',
              table: baseTable,
              cache: cacheMap
            }));
          }
        }
      });
    }
    return () => { };
  };

  React.useEffect(loadData, []);

  React.useEffect(loadData, [
    props.editId,
    props.baseTable,
    props.customEditor,
    props.additionalDisplay,
    JSON.stringify(props.stateExtension ?? {}),
    props.profile.isTrainingFacility,
    props.profile.isPartner
  ]);

  const handleOpen = () => {
    if (props.mode === 'button') setEditState((old) => ({
      ...old,
      open: true
    }));
  };

  const handleClose = () => {
    if (props.mode !== 'inline') {
      if (props.onCancel != null) props.onCancel();

      if (props.mode === 'button') setEditState((old) => ({
        ...old,
        open: false
      }));
    }
  };

  const handleSave = () => {
    if (hasAnyMatch(editState.editItem!, i => i.error.length > 0) || hasAnyMatch(props.referenceTableChanges ?? {}, i => i.error != null)) {
      setEditState((old) => ({ ...old, failedSave: true }));
    } else {
      const addtlChanges: (IValueParameter | IReferenceParameter)[] = props.additionalChanges ?? [];

      objectEach(props.unmappedAdditionalChanges ?? {}, (key, value) => {
        addtlChanges.push({ columnId: editState.references[key], newValue: value });
      });
      const changes: IAutoChange = {
        item: editState.editItem!,
        table: editState.table!,
        id: props.editId,
        resultKey: 'newId',
        additionalChanges: addtlChanges,

      };
      autoEditorChange({
        ...changes,
        page: props.ui.title,
        title: `${props.editId == null ? 'Create' : 'Update'} Record in ${editState.table!.title}`,
        description: editState.displayName,
        fetchConfigRequest: props.fetchConfigRequest,
        createAlertBulk: props.createAlertBulk,
        additionalTableChanges: flatten(objectMapArray(props.referenceTableChanges ?? {}, (key, tbl): IChangeSet[] =>{
          const refTable = editState.referenceTables![key];
          const partial = { tableId: refTable.baseTable.tableId, action: key, changes: [] };
          return [
            ...tbl.adds.map((a):IChangeSet=>({
              ...partial,
              type: 'Create',
              changes: [{ columnId: refTable.referenceColumn, referenceKey: changes.resultKey! },
                ...objectMapArray(a, (col, newValue):IValueParameter=>({ newValue, columnId: refTable.columns[col] }))
              ]
            })),
            ...tbl.deletes.map((d):IChangeSet=>({
              ...partial,
              type: 'Delete',
              key: d
            }))
          ];

        }))
      }).then((res) => {
        console.log(res);
        if (res.success) {
          // if (props.editId != null) {
          //   queueMessage(`Successfully updated ${editState.table?.title}!`, 'success');
          // }
          props.onSave(res.referenceKeys.newId?.id ?? props.editId, {
            ...objectRemap(editState.editItem!, (key, value) => [getAliasName(editState.table!.columns[key].name), !value.isChanged ? undefined : value.editValue]),
            ...(res.referenceRows?.newId ?? {})
          });
          if (props.mode !== 'inline') {
            setEditState(defaultEditState);
          }
          loadData();
        }
      });
    }
  };

  const validateValue = (value: any, column: IColumn, additionalError?: string, additionalWarning?: string): { error: string; warning: string } => {
    const defaultWarning = additionalWarning ?? '';
    if (additionalError != null) {
      return { error: additionalError, warning: defaultWarning };
    }
    if (column.properties.alternateEditor != null) {
      // TODO: any custom validation for alternate editors
    }
    if ((value === '' || value === null || value === undefined) && column.required) return { error: `${column.title} must not be blank`, warning: defaultWarning };
    switch (column.columnType) {
      case 'BigInt':
      case 'Int':
      case 'SmallInt':
        if (value === '' || value === null || (value === undefined && !column.required)) return { error: '', warning: defaultWarning };
        return { error: isNaN(parseInt(value, 10)) ? `${column.title} must be numeric` : '', warning: defaultWarning };
    }
    return { error: '', warning: defaultWarning };
  };

  const isValueChanged = (newValue: any, oldValue: any, column: IColumn): boolean => {
    if ((newValue === null || newValue === undefined) !== (oldValue === null || oldValue === undefined)) return true;
    if ((newValue === null || newValue === undefined) && (oldValue === null || oldValue === undefined)) return false;

    switch (column.columnType) {
      case 'String':
        return newValue.localeCompare(oldValue) !== 0;
      case 'Json':
        return !isEqual(newValue, oldValue);
      case 'MultiLookup':
        return xor(newValue, oldValue).length > 0;
      default:
        return newValue !== oldValue;
    }
  };

  const updateValue = (value: any, column: IColumn, current: IAutoEditField, additionalError?: string, additionalWarning?: string): IAutoEditField => ({
    ...validateValue(value, column, additionalError, additionalWarning),
    editValue: value,
    originalValue: current.originalValue,
    isChanged: isValueChanged(value, current.originalValue, column)
  });

  const handleValueUpdated = (value: any, column: IColumn, _displayValue?: string, additionalError?: string, additionalWarning?: string, localStateUpdate?: any) => {
    const editItem: IAutoEditItem = editState.editItem == null ? {} : cloneDeep(editState.editItem);

    editItem[column.columnId] = updateValue(value, column, editItem[column.columnId], additionalError, additionalWarning);

    setEditState((old) => {
      return {
        ...old,
        editItem,
        failedSave: hasAnyMatch(editItem, (item) => item.error.length > 0),
        localState: { ...old.localState, ...(localStateUpdate ?? {}) }
      };
    });
  };

  const displayTitle = (): string => {
    if (props.title != null) return `${props.editId == null ? 'Create' : 'Edit'} ${props.title}`;

    if (editState.table == null) return 'Loading...';

    if (props.editId == null) return `Create new ${editState.table.title}`;

    if (editState.editItem == null) return `Loading ${editState.table.title}...`;

    return `Edit ${editState.displayName}`;
  };

  const displayErrorsWarnings = () => {
    return flatten(editState.fieldOrder.map((x) => x.fieldOrder)).map((id) => {
      const field = editState.editItem![id];
      if (!field.error && !field.warning) return undefined;
      return (
        <ListItem key={`error-warning-${id}`}>
          <ListItemText primary={field.error || field.warning} secondary={!!field.error && !!field.warning ? field.warning : null} />
        </ListItem>
      );
    });
  };

  const handleCommentChange = (changeComment: string) => setEditState((old) => ({ ...old, changeComment }));

  const autoEditorField = (columnId: number) => (
    <AutoEditorField
      key={`autoEditorField_${columnId}`}
      {...editState.editItem![columnId]}
      editId={props.editId}
      doubleWidth={props.splitEditor != null}
      item={editState.editItem!}
      columnReferences={editState.references}
      field={editState.table!.columns[columnId]}
      valueCache={editState.cache}
      displayContext={props.displayContext}
      company={props.company}
      onValueUpdated={handleValueUpdated}
      disabled={editState.table!.columns[columnId].editSecurable && !profile.isTrainingFacility}
      readonly={props.readonly || editState.editItem![columnId].readonly}
      stateExtension={props.stateExtension}
      localState={editState.localState}
    />
  );

  const displaySection = (section: ITableEditorSection) => {
    switch (section.customView) {
      case 'firstItemColumnEdit': {
        return props.editId == null ? (
          <React.Fragment key={`firstItemColumnEdit_${section.key}_new`}>
            {section.title.length > 0 ? (
              <Grid key={`newitem_section_${section.key}`} item xs={12}>
                <Typography variant="h6">{section.title}</Typography>
              </Grid>
            ) : undefined}
            {section.fieldOrder.slice(1).map((id) => autoEditorField(id))}
          </React.Fragment>
        ) : (
          <React.Fragment key={`firstItemColumnEdit_${section.key}_${props.editId}`}>
            {section.title.length > 0 ? (
              <Grid key={`section_${section.key}`} item xs={12}>
                <Typography variant="h6">{section.title}</Typography>
              </Grid>
            ) : undefined}
            <Grid key={`firstItemColumn_${section.key}`} item xs={12}>
              <Grid container spacing={3}>
                <Grid key={`parent_autoEditorField_${section.fieldOrder[0]}`} item>
                  {autoEditorField(section.fieldOrder[0])}
                </Grid>
                <Grid key="parent_autoEditorField" item xs={12} sm>
                  <Grid container spacing={3}>
                    {section.fieldOrder.slice(1).map((id) => autoEditorField(id))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      }
      default: {
        return (
          <React.Fragment key={`displaySection_${section.key}`}>
            {section.title.length > 0 ? (
              <Grid key={`section_${section.key}`} item xs={12}>
                <Typography variant="h6">{section.title}</Typography>
              </Grid>
            ) : undefined}
            {section.fieldOrder.map((id) => autoEditorField(id))}
          </React.Fragment>
        );
      }
    }
  };

  const displayChangeDetails = () => <React.Fragment key="displayChangeDetails">
    {props.readonly || !editState.editable ? undefined : (
      <Grid key="divide-edit-comments" item xs={12}>
        <Divider/>
      </Grid>
    )}
    <Grid key="change-comment" item xs={12} md={8} hidden={editState.editItem == null || !editState.editable || !hasChanges()}>
      <TextField rows={4} fullWidth label={`Comment on ${props.editId == null ? 'Create' : 'Update'}`} value={editState.changeComment} onChange={(event) => handleCommentChange(event.currentTarget.value)} variant="outlined" />
    </Grid>
    {props.mode === 'inline' && !props.readonly && editState.editable ? (
      <Grid key="commit-change-button" item md={12}>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={editState.editItem == null || !hasChanges()}>
          {props.editId == null ? 'Create' : 'Update'}
        </Button>
      </Grid>
    ) : undefined}
    {editState.failedSave ? (
      <Grid key="list-errors-section" item md={12}>
        <List>{displayErrorsWarnings()}</List>
      </Grid>
    ) : undefined}
  </React.Fragment>;

  const displayEditorDefault = (sizes: { xs?: GridSize, sm?: GridSize, md?: GridSize, lg?: GridSize }) => {
    return (
      editState.loading ? (
        <LoadingScreen key="loading-config"/>
      ) : (
        <Grid key="root-editor-grid" container spacing={3}>
          {editState.editItem == null || editState.table == null ? (
            <LoadingScreen key="loading-table" />
          ) : (
            <React.Fragment key={`editorDefault_${editState.editItem ?? 'new'}`}>
              {props.additionalDisplay?.map((display) =>
                isInterface<ISelectColumnMap>(display, 'columnAlias') ? (
                  <Grid key={`display_${display.columnAlias}`} item {...sizes}>
                    <DisplayField label={display.columnTitle} value={editState.editItem![display.columnId].originalValue} />
                  </Grid>
                ) : (
                  <Grid key={`display_${display.label}`} item {...sizes}>
                    <DisplayField {...display} />
                  </Grid>
                )
              ) ?? undefined}
              {editState.fieldOrder.map((sec) => displaySection(sec))}
            </React.Fragment>
          )}
          {props.splitEditor == null && displayChangeDetails()}
        </Grid>
      )
    );
  };

  const displayEditor = () => {
    if (props.splitEditor == null) return displayEditorDefault({ xs: 12, sm: 6, md: 4 });

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>{displayEditorDefault({ xs: 12, lg: 6 })}</Grid>
        <Grid item xs={12} md={6}>{props.splitEditor}</Grid>
        {displayChangeDetails()}
      </Grid>
    );
  };

  return (
    <>
      {props.mode === 'button' ? (
        <Button fullWidth={props.fullWidth} key="button-mode-editor" variant="contained" disabled={props.disabled} size="small" onClick={handleOpen}>
          {props.buttonTitle}
        </Button>
      ) : undefined}
      {props.mode === 'inline' ? (
        displayEditor()
      ) : (
        <Dialog key="editor-dialog" open={editState.open || (props.mode === 'direct' && props.open)} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogTitle>{displayTitle()}</DialogTitle>
          <DialogContent>{displayEditor()}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" disabled={editState.editItem == null || !hasChanges()}>
              {props.editId == null ? 'Create' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default compose<ComponentType<(IAutoEditorButtonProps | IAutoEditorDirectProps | IAutoEditorInlineProps)>>(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(AutoEditor);


import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { intersection } from 'lodash';
import WorkerPhoto from './WorkerPhoto';
import AutoEditor from '../auto/AutoEditor';
import settings from '../../abstracts/settings';
import { Search, Forward } from '@mui/icons-material';
import { createStyles, withStyles } from '@mui/styles';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { AutoSearchTypes } from '../../stores/database/gridExtension/interfaces';
import { stringToIdList, objectMap, quickGrid } from '../../abstracts/DataroweHelpers';
import { autoLoad, AutoLoadKeyDisplay, getAllFields, ICustomColumnDefinition } from '../auto/AutoLoad';
import { IFilterDefinition, FilterConditionType, IColumnMap, ISelectColumnMap } from '../../stores/database/interfaces';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, AutoGridSelectAllEvent, AutoGridRowClickEvent, IConnectedProps } from '../auto/AutoGrid';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, IconButton, DialogActions, Typography, Tooltip, Theme, useTheme, InputAdornment } from '@mui/material';

const generateSxClasses = (_theme: Theme) => {
  return {
    buttonAlign: {
      alignContent: 'center',
      display: 'flex',
      flexWrap: 'wrap'
    },
    gridContainer: {
      alignItems: 'center'
    }
  };
};

export interface IWorkerSearchProps {
  dialogTitle: string;
  limitSelectCount?: number;
  disabled?: boolean;
  excludedIds?: number[];
  onWorkersSelected: (workers: AutoLoadKeyDisplay) => void;
  onSelectCancel?: () => void;
}

export interface IWorkerButtonSearchProps extends IWorkerSearchProps {
  buttonTitle: string;
  buttonTip?: string;
  fullWidth?: boolean;
  mode: 'button';
}

export interface IWorkerDirectSearchProps extends IWorkerSearchProps {
  open: boolean;
  mode: 'direct';
}

const WorkerSearch = (props: (IWorkerButtonSearchProps | IWorkerDirectSearchProps) & IConnectedProps) => {
  const sxClasses = generateSxClasses(useTheme());

  const iecNumberColumns = ['identity.person.worker', 'identity.person.iecnumber'];
  const defaultColumns: ICustomColumnDefinition[] = [
    {
      key: 'person.pictureTakenUTC',
      displayControl: (r) => <WorkerPhoto personId={r['person.iecNumber']} photoTakenUTC={r['person.pictureTakenUTC']} diameter={80} />
    },
    {
      key: 'person.iecNumber'
    },
    {
      key: 'person.firstLegal'
    },
    {
      key: 'person.firstPreferred'
    },
    {
      key: 'person.last'
    },
    {
      key: 'person.companyUnion.name',
      title: 'Union'
    },
    {
      key: 'activeCompanies'
    }
  ];

  const [key, display] = ['person.iecNumber', 'displayName'];

  const defaultGridParams: Omit<IAutoGridProps, 'displayField,keyField'> & { displayField?: ISelectColumnMap; keyField?: ISelectColumnMap } & { baseTableId: number; idSearchField?: IColumnMap } = {
    exportTitle: 'Searched Workers',
    baseTable: 'Identity.Worker',
    baseTableId: -1,
    keyField: undefined,
    displayField: undefined,
    idSearchField: undefined,
    displayColumns: [],
    additionalColumns: undefined,
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    selectedRows: props.limitSelectCount === 1 ? undefined : {}
  };

  const [gridParams, setGridParams] = React.useState(defaultGridParams);

  React.useEffect(() => {
    if ((gridParams.selectedRows == null) !== (props.limitSelectCount === 1)) {
      setGridParams((old) => ({
        ...old,
        selectedRows: props.limitSelectCount === 1 ? undefined : {}
      }));
    }
  }, [props.limitSelectCount]);

  const defaultWorkerSearchState: {
    open: boolean;
    search?: AutoSearchTypes;
  } = {
    open: false,
    search: undefined
  };

  const [myState, setMyState] = React.useState(defaultWorkerSearchState);

  const handleSelectRow: AutoGridRowClickEvent = (e) => {
    console.log(e);
    if (props.limitSelectCount === 1) {
      props.onWorkersSelected(e.updatedSelectedRows!);

      setMyState((old) => ({
        ...old,
        open: false
      }));
    } else {
      setGridParams((old) => ({
        ...old,
        selectedRows: e.updatedSelectedRows
      }));
    }
  };

  const handleSelectAll: AutoGridSelectAllEvent = (e) => {
    setGridParams((old) => ({
      ...old,
      selectedRows: e.updatedSelectedRows
    }));
  };

  const [quickSearch, setQuickSearch] = React.useState('');
  const [quickSearchIEC, setQuickSearchIEC] = React.useState('');

  const handleSearchRun = (search: AutoSearchTypes): void => {
    setMyState((old) => ({
      ...old,
      search
    }));

    setGridParams((old) => ({
      ...old,
      rowDataLoading: true
    }));

    autoLoad({
      search,
      baseTableId: gridParams.baseTableId,
      keyField: gridParams.keyField!,
      displayField: gridParams.displayField!,
      allFields: getAllFields(gridParams.displayColumns, gridParams.additionalColumns),
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk
    }).then((res) => {
      setGridParams((old) => ({
        ...old,
        rowData: objectMap(res, (k, v) => ((props.excludedIds ?? []).indexOf(+k) >= 0 ? undefined : v)),
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    });
  };

  const searchErrorString = (ids: number[], msgEnd: string) => (ids.length === 0 ? '' : `The IEC Number${ids.length > 1 ? 's' : ''} ${ids.join(', ')} ${ids.length > 1 ? 'were' : 'was'} ${msgEnd}. `);

  const handleGetByIdRun = (personId: number | number[]) => {
    if (Array.isArray(personId) && personId.length === 0) {
      handleSearchRun(quickSearchIEC);
    } else {
      const search: IFilterDefinition = {
        baseFilter: {
          columnId: gridParams.idSearchField!.columnId,
          lookupPath: gridParams.idSearchField!.lookupPath || [],
          type: Array.isArray(personId) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
          firstParameter: Array.isArray(personId) && props.limitSelectCount != null && personId.length > props.limitSelectCount ? personId.slice(0, props.limitSelectCount) : personId
        },
        baseTableId: gridParams.baseTableId,
        name: ''
      };

      autoLoad({
        search,
        count: 1000,
        baseTableId: gridParams.baseTableId,
        keyField: gridParams.keyField!,
        displayField: gridParams.displayField!,
        allFields: getAllFields(gridParams.displayColumns, gridParams.additionalColumns),
        fetchConfigRequest: props.fetchConfigRequest,
        createAlertBulk: props.createAlertBulk
      }).then((res) => {
        const selected: AutoLoadKeyDisplay = objectMap(res, (k, v) => ((props.excludedIds ?? []).indexOf(+k) >= 0 ? undefined : { display: v[gridParams.displayField!.columnAlias], rowData: v }));

        if (Object.keys(selected).length > 0) {
          setMyState((old) => ({ ...old, open: false }));
          props.onWorkersSelected(selected);
        }

        const excluded = intersection(props.excludedIds ?? [], Array.isArray(personId) ? personId : [personId]);
        const notFound = (Array.isArray(personId) ? personId : [personId]).filter((id) => selected[id] == null && excluded.indexOf(id) < 0);

        if (notFound.length > 0 || excluded.length > 0) {
          props.createAlertBulk([
            {
              autoDisplay: true,
              created: moment().format(settings.dateTimeFormatMoment),
              severity: 'warning',
              title: 'IEC Number(s) Not Found',
              description: `${searchErrorString(notFound, 'not found')}${searchErrorString(excluded, 'not allowed in the current context')}`,
              page: 'API',
              viewed: false
            }
          ]);
        }
      });
    }
  };

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'identityWorkerSearch',
      action: (db) => {
        if (gridParams.baseTable == null) return;
        const baseTableId = typeof gridParams.baseTable === 'string' ? db.getTableByCombinedName(gridParams.baseTable.toString()).tableId : +gridParams.baseTable;
        const [keyField, displayField] = db.mapSelectColumnsByNamesWithId(baseTableId, [key, display]);
        setGridParams((old) => ({
          ...old,
          baseTableId,
          keyField,
          displayField,
          idSearchField: db.mapSelectColumnsByNamesWithId(baseTableId, [iecNumberColumns])[0],
          displayColumns: db.mapDisplayColumnsByNamesWithId(baseTableId, defaultColumns)
        }));
      }
    });
    return () => { };
  }, []);

  function handleOpen() {
    setMyState((old) => ({
      ...old,
      open: true
    }));
  }

  function handleClose() {
    setMyState((old) => ({
      ...old,
      open: false
    }));

    if (props.onSelectCancel !== undefined) props.onSelectCancel();
  }

  function handleSubmit() {
    setMyState((old) => ({
      ...old,
      open: false
    }));

    props.onWorkersSelected(gridParams.selectedRows!);

    setGridParams((old) => ({
      ...old,
      selectedRows: {}
    }));
  }

  const controlGridSizes = quickGrid(12, 6, 4, 4, 4);

  const displayQuickSearch = () => (
    <TextField
      fullWidth
      id="id-search"
      label="Add by IEC Number"
      margin="dense"
      onChange={(event) => setQuickSearchIEC(event.currentTarget.value)}
      onKeyDown={(event) => {
        if (event.keyCode === 13) handleGetByIdRun(stringToIdList(quickSearchIEC));
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <IconButton aria-label="search" onClick={() => handleGetByIdRun(stringToIdList(quickSearchIEC))}>
            <Forward />
          </IconButton>
        </InputAdornment>
      }}
      helperText={props.limitSelectCount == null ? undefined : props.limitSelectCount === 1 ? 'Enter a single IEC Number' : `Enter up to ${props.limitSelectCount} IEC numbers`}
    />
  );


  const displayGrid = () => (
    <Grid sx={{ ...sxClasses.gridContainer }} container spacing={3}>
      <Grid item {...controlGridSizes}>
        {props.limitSelectCount !== 1
          ? <Tooltip title="Add multiple workers by entering or pasting multiple ids with any character(s) in between numbers">
            {displayQuickSearch()}
          </Tooltip>
          : displayQuickSearch()
        }
      </Grid>
      <Grid item {...controlGridSizes}>
        <TextField
          fullWidth
          id="quick-search"
          label="Search by Name"
          margin="dense"
          defaultValue={typeof myState.search === 'string' ? myState.search : undefined}
          onChange={(event) => setQuickSearch(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.keyCode === 13) handleSearchRun(quickSearch);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton aria-label="search" onClick={() => handleSearchRun(quickSearch)}>
                <Search />
              </IconButton>
            </InputAdornment>
          }}
          helperText={props.limitSelectCount == null ? '' : ' '}
        />
      </Grid>
      {!props.profile.isTrainingFacility || !props.profile.isAdmin ? undefined : (
        <Grid sx={{ ...sxClasses.buttonAlign }} item {...controlGridSizes}>
          <AutoEditor fullWidth={true} baseTable="identity.person" mode="button" minHeight={300} buttonTitle="Add New Worker" onSave={handleGetByIdRun} />
        </Grid>
      )}
      <Grid item xs={12}>
        <AutoGrid onRowClick={handleSelectRow} onSelectAllClick={handleSelectAll} maxHeight={395} {...gridParams} />
      </Grid>
    </Grid>
  );

  return (
    <>
      {props.mode !== 'button' ? undefined : (
        <>
          <Button fullWidth={props.fullWidth} variant="contained" size="small" disabled={props.disabled} onClick={handleOpen} title={props.buttonTip}>
            {props.buttonTitle}
          </Button>
          {props.buttonTip?.length ?? 0 > 0 ? <Typography>{props.buttonTip}</Typography> : undefined}
        </>
      )}
      <Dialog open={myState.open || (props.mode === 'direct' && props.open)} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>{displayGrid()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {props.limitSelectCount === 1 ? undefined : (
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={gridParams.selectedRows === undefined || Object.keys(gridParams.selectedRows).length === 0 || (props.limitSelectCount != null && Object.keys(gridParams?.selectedRows ?? {}).length > props.limitSelectCount)}
            >
              {`Add ${Object.keys(gridParams?.selectedRows ?? {}).length}${props.limitSelectCount != null ? `/${props.limitSelectCount}` : ''} Workers`}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default compose(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(WorkerSearch);

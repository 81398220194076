import clsx from 'clsx';
import React from 'react';
import HelpDoc from './HelpDoc';
import { Dictionary } from 'lodash';
import { IAppState } from '../stores';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose, Dispatch } from 'redux';
import ReactToPrint from 'react-to-print';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../stores/profile/types';
import { fetchProfile } from '../stores/profile/actions';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { isInterface } from 'src/abstracts/DataroweHelpers';
import { Edit, Cancel, Settings } from '@mui/icons-material';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { ICommonHelpItem, INavigationLink, INavigationOutlet } from '../abstracts/NavigationLinks';
import { Paper, AppBar, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, List } from '@mui/material';

const styles = () => createStyles({
  appBarGap: {
    marginTop: 120
  },
  printManual: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});

interface IHeaderProps {
  classes: any;
  links: INavigationLink[];
  commonHelpItems: ICommonHelpItem[];
  profile: IProfileState;
  dispatch: Dispatch;
}

class HelpManualPrint extends React.Component<IHeaderProps, Dictionary<boolean>> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  handleEditClick(key: string) {
    this.setState((old) => ({
      ...old,
      [key]: !old[key]
    }));
  }

  displayItem(markdownKey: string, icon: React.ReactNode, title: string, link?: string) {
    return (
      <React.Fragment key={`rf_${markdownKey}`}>
        <AppBar key={`appbar_${markdownKey}`} color="secondary" position="sticky" elevation={0}>
          <List>
            <ListItem className="force-header-print" button dense component={link == null ? 'div' : Link} to={link == null ? undefined : '/profile'} key={link == null ? undefined : title}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText className={clsx(this.props.classes.itemPrimary)}>{title}</ListItemText>
              <ListItemSecondaryAction className="no-print">
                <IconButton aria-label="Edit" onClick={() => this.handleEditClick(markdownKey)}>
                  {!this.state[markdownKey] ? <Edit /> : <Cancel />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </AppBar>
        <HelpDoc key={`${markdownKey}_doc`} title={title} inEdit={this.state[markdownKey]} markdownKey={markdownKey} onEditClose={() => this.handleEditClick(markdownKey)}/>
      </React.Fragment>
    );
  }

  recursiveFlatten(linkItems: (INavigationLink | INavigationOutlet)[], parentPath: string) : INavigationLink[] {
    const res = [] as INavigationLink[];
    linkItems.forEach(l => {
      if (isInterface<INavigationLink>(l, 'title') && (l.icon || l.forceManual)) {
        const linkItem = {
          ...l,
          path: parentPath + '/' + l.path
        };

        res.push(linkItem);

        if (l.children != null) {
          res.concat(this.recursiveFlatten(l.children, linkItem.path));
        }
      }
    });

    return res;
  }

  render() {
    return (
      <Paper className={this.props.classes.helpPaper}>
        {this.displayItem('21EFFD23-2A42-4CFD-9B1A-0FE14A000195', <FA icon={icons.faHome}/>, 'Page - Welcome', '/')}
        {this.displayItem('1B0DF062-3D2F-4637-9ECE-4146DA7AA15E', <Settings/>, 'Page - Profile', '/profile')}
        {this.recursiveFlatten(this.props.links, '/').filter((v) => v.display == null || v.display(this.props.profile)).map((v) => this.displayItem(v.helpKey, v.icon ?? <></>, v.title, v.path))}
        {this.props.commonHelpItems.map(({ title, helpKey, icon }) => this.displayItem(helpKey, icon ?? <></>, title))}
      </Paper>
    );
  }
}

const HelpManual = (props: IHeaderProps) => {
  const { dispatch } = props;

  React.useEffect(() => {
    dispatch(fetchProfile());

    return () => {};
  }, []);

  const componentRef = React.useRef<HelpManualPrint>();

  return (
    <>
      <ReactToPrint trigger={() => <button className={props.classes.printManual}>Print</button>} content={() => componentRef.current!} pageStyle="margin: 0.25in 0.1in 0.1in 0.1in; font-size: 30% !important;" bodyClass="print-manual-body"/>
      <HelpManualPrint {...props} ref={(el) => (componentRef.current = el as HelpManualPrint)}/>
    </>
  );
};

function mapStateToProps(state: IAppState) {
  return {
    user: state.oidc.user,
    ui: state.ui,
    profile: state.profile
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(HelpManual);

import clsx from 'clsx';
import React from 'react';
import { compose } from 'redux';
import { flatten } from 'lodash';
import { User } from 'oidc-client';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { Delete } from '@mui/icons-material';
import LoadingScreen from '../common/LoadingScreen';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../../stores/profile/types';
import { IEmailAddress } from '../../abstracts/Interfaces';
import { fetchConfigRequest } from '../../stores/database/actions';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { recursiveCompare, objectMapArray, nameof } from '../../abstracts/DataroweHelpers';
import { ISubmitCompanyPay, ISubmitLineItem, ITrainingBookState, PaymentType } from '../../stores/training/booking/types';
import { signalrHubCompleteBooking, submitAddEmail, submitRemoveEmail, signalrHubUpdateCreditCard, submitMarkCompleting } from '../../stores/training/booking/actions';
import { Box, Theme, Paper, Typography, Grid, Select, MenuItem, FormControl, InputLabel, Button, Hidden, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListSubheader, Dialog, DialogTitle, DialogContent, TextField, DialogActions, useTheme } from '@mui/material';

const generateSxClasses = (theme: Theme) => {
  return {
    emailContainer: {
      marginTop: theme.spacing(1.25),
      mx: theme.spacing(2)
    },
    listSubheader: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      my: theme.spacing(1),
      py: theme.spacing(1.5)
    },
    rightAlign: {
      textAlign: 'right'
    }
  };
};

const styles = (theme: Theme) => createStyles({
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  contentWrapper: {
    margin: `${theme.spacing(2)} ${theme.spacing(1.25)}`
  },
  divider: {
    borderBottomColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
    borderBottomWidth: 1,
    borderBottomStyle: 'dotted',
    padding: '0 !important'
  },
  greyedTip: {
    color: theme.palette.grey[500]
  },
  textRight: {
    textAlign: 'right'
  },
  displayPrice: {
    textAlign: 'right'
  },
  calcPrice: {
    borderTopStyle: 'dashed',
    borderTopWidth: 1
  },
  cardDisplay: {
    borderRadius: '16px',
    borderColor: theme.palette.text.primary,
    borderStyle: 'solid',
    padding: '1em',
    border: 1
  }
});

type CreditCardStatuses = 'incomplete' | 'complete' | 'processing' | 'paymentError' | 'paymentSuccess';

interface IScheduleReviewProps {
  classes: any;
  book: ITrainingBookState;
  user: User;
  profile: IProfileState;
}

interface IConnectedProps {
  fetchConfigRequest: typeof fetchConfigRequest;
  submitAddEmail: typeof submitAddEmail;
  submitRemoveEmail: typeof submitRemoveEmail;
  submitMarkCompleting: typeof submitMarkCompleting;
}

interface IScheduleSubmitEmail {
  address: string;
  companyId?: number
}

const ScheduleSubmit = (props: IScheduleReviewProps & IConnectedProps) => {
  const theme = useTheme();
  const sxClasses = generateSxClasses(theme);

  const { classes, book, profile } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [creditCardStatus, setCreditCardStatus] = React.useState<CreditCardStatuses>('incomplete');
  const [error, setError] = React.useState('');
  const [email, setEmail] = React.useState<IScheduleSubmitEmail>({ address: '' });

  const updateEmail = (address: string) => setEmail((old) => ({
    ...old,
    address
  }));

  const cancelEmail = () => setEmail({ address: '' });
  const saveEmail = () => {
    props.submitAddEmail(email.companyId!, email.address);
    cancelEmail();
  };

  const handleComplete = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (creditCardStatus === 'complete' && elements != null && book.submit?.payment != null) {
      setCreditCardStatus('processing');
      const result = await stripe!.confirmCardPayment(book.submit.payment.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: profile.displayName
          }
        }
      });

      if (result.error) {
        setError(result.error.message!);
        setCreditCardStatus('paymentError');

        return;
      }

      setCreditCardStatus('paymentSuccess');
    } else {
      props.submitMarkCompleting();
    }

    signalrHubCompleteBooking(book.hubConnection!, book.bookSessionId!, flatten(objectMapArray(book.otherEmails, (cId, addresses) => addresses.map((address) => ({ address, companyId: +cId })))));
  };

  const noPO = (<Typography className={classes.greyedTip} variant="subtitle2" component="span">No Po</Typography>);

  const handleCardChange = (event: any) => setCreditCardStatus(event.complete ? 'complete' : 'incomplete');

  const displayLineItem = (keySupplement: string, item: ISubmitLineItem): React.ReactNode => {
    const displayKey = `${keySupplement}${item.key}`;

    return (
      <React.Fragment key={`${displayKey}_fragment`}>
        <Grid key={`${displayKey}_divider`} className={classes.divider} item xs={12} />
        <Grid key={`${displayKey}_title`} item xs={12} md={4}>
          {item.name}
        </Grid>
        <Grid key={`${displayKey}_course`} item xs={12} md={6}>
          {item.title}
        </Grid>
        <Grid sx={{ ...sxClasses.rightAlign }} key={`${displayKey}_price`} item xs={12} md={2}>
          {new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(item[profile.pricingField])}
        </Grid>
      </React.Fragment>
    );
  };

  const displaySelfPay = (items: ISubmitLineItem[]) => items.length === 0 ? undefined : (
    <>
      <Paper key="1_selfPay" className={classes.paper}>
        <div className={classes.contentWrapper}>
          <Typography variant="h6">Self Pay Workers</Typography>
          <Typography variant="subtitle2">These workers have been marked Self-pay, and will have to pay before admittance to their class.</Typography>
          <Grid style={{ marginTop: 15 }} container spacing={3}>
            {items.sort((a, b) => recursiveCompare(a, b, [nameof<ISubmitLineItem>('name'), nameof<ISubmitLineItem>('title')])).map(item => displayLineItem('', item))}
          </Grid>
        </div>
      </Paper>
      <br key="1_selfPayBreak" />
    </>
  );

  const priceItemCell = (price: number) => <>&nbsp;&nbsp;{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(price)}&nbsp;&nbsp;</>;
  const priceItemTotal = (price: number) => <span className={clsx(classes.calcPrice, classes.displayPrice)}>{priceItemCell(price)}</span>;

  const isHidden = (userCompanyId: number) => {
    return !(profile.companies.find((x) => x.userCompanyId === userCompanyId)?.canInvoice ?? false);
  };

  const displayPurchase = (company: ISubmitCompanyPay) => {
    const displayKey = company.key;

    return (
      <React.Fragment key={`${displayKey}_fragment`}>
        <Paper key={`2_${displayKey}`} className={classes.paper}>
          <div className={classes.contentWrapper}>
            <Typography component="p" variant="h6">
              {company.company}
            </Typography>
            <Typography component="p" variant="subtitle2">
              Purchase Order:&nbsp;{(company.purchaseOrder ?? '') === '' ? noPO : company.purchaseOrder}
            </Typography>
            <Grid style={{ marginTop: theme.spacing(2) }} container spacing={3}>
              {company.items.sort((a, b) => recursiveCompare(a, b, [nameof<ISubmitLineItem>('name'), nameof<ISubmitLineItem>('title')])).map(item => displayLineItem(company.key, item))}
              <Grid key={`${displayKey}_pricetitle`} item xs={7} sm={10} className={clsx(classes.greyedTip, classes.textRight)}>
                Subtotal:
              </Grid>
              <Grid key={`${displayKey}_price`} item xs={5} sm={2} className={classes.displayPrice}>
                {priceItemTotal(company[profile.subtotalField])}
              </Grid>
              <Grid key={`${displayKey}_payment`} item xs={12} sm={6} md={6} lg={4} xl={3}>
                <FormControl fullWidth>
                  <InputLabel id={`paymentMethod_${displayKey}_label`}>Payment Method</InputLabel>
                  <Select
                    fullWidth
                    id={`paymentMethod_${displayKey}`}
                    label="Payment Method"
                    labelId={`paymentMethod_${displayKey}_label`}
                    value={company.creditCard ? 'CreditCard' : 'Invoice'}
                    onChange={(event) => {
                      signalrHubUpdateCreditCard(book.hubConnection!, book.bookSessionId!, [
                        {
                          personId: -1,
                          userCompanyId: company.userCompanyId,
                          purchaseOrder: company.purchaseOrder,
                          creditCard: (event.target.value as PaymentType) === 'CreditCard'
                        }
                      ]);
                    }}
                  >
                    {isHidden(company.userCompanyId) ? undefined : <MenuItem value="Invoice">Invoice Company</MenuItem>}
                    <MenuItem value="CreditCard">Credit Card</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Paper>
        <br />
      </React.Fragment>
    );
  };

  const emailText = (address: IEmailAddress) => (address.name == null ? address.email : `${address.name} <${address.email}>`);

  const displayEmails = () => (profile?.companies ?? []).map((company) =>
    !(book.submit?.companyPay?.some((companyPay) => companyPay.userCompanyId === company.userCompanyId) ?? false) ? undefined : (
      <React.Fragment key={`fragment_${company.companyId}`}>
        <Paper key={`emails_${company.companyId}`} className={classes.paper}>
          <Box sx={{ ...sxClasses.emailContainer }}>
            <Typography key="email-header">Confirmation Emails for {company.name}</Typography>
            <List
              dense
              subheader={
                <ListSubheader sx={{ ...sxClasses.listSubheader }} key="email-note" component="div" id="nested-list-subheader">
                  <Typography key="email-note" component="span" variant="subtitle2">
                    Include the following email address(es) as recipient(s):
                  </Typography>
                  <Button sx={{ alignSelf: 'flex-end' }} onClick={() => setEmail({ address: '', companyId: company.companyId })} color="primary" variant="contained" size="small">
                    Add Email Recipient
                  </Button>
                </ListSubheader>
              }
            >
              {(book.registrationEmails?.[company.companyId] ?? []).map((regEmail) => (
                <ListItem key={`auto_${regEmail.email}`}>
                  <ListItemText primary={emailText(regEmail)} />
                </ListItem>
              ))}
              {(book.otherEmails[company.companyId] ?? []).map((otherEmail) => (
                <ListItem key={`custom_${otherEmail}`}>
                  <ListItemText primary={otherEmail} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => props.submitRemoveEmail(company.companyId, otherEmail)} edge="end" aria-label="delete">
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
        <br />
      </React.Fragment>
    )
  );

  const displayCredCards = () => {
    const cardStyle = {
      style: {
        base: {
          color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
          '::placeholder': {
            color: theme.palette.secondary.dark
          },
          iconColor: theme.palette.primary.main
        }
      },
      disableLink: true
    };

    return book.submit?.payment == null ? undefined : (
      <>
        <Paper key="credit-card" className={classes.paper}>
          <Box className={classes.contentWrapper}>
            <Typography variant="h6">Primary Credit Card</Typography>
            <Grid style={{ marginTop: 15 }} container spacing={3}>
              {(book.submit?.companyPay ?? [])
                .filter((x) => x.creditCard)
                .sort((a, b) => a.key.localeCompare(b.key))
                .map((payLine, index) => (
                  <React.Fragment key={`payLineFragment_${index}`}>
                    <Grid key={`${payLine.key}_title`} item xs={12} sm={8}>
                      {payLine.company} - {(payLine.purchaseOrder ?? '') === '' ? noPO : payLine.purchaseOrder}
                    </Grid>
                    <Grid key={`${payLine.key}_price`} item xs={12} sm={4} className={classes.displayPrice}>
                      {priceItemCell(payLine[profile.subtotalField])}
                    </Grid>
                  </React.Fragment>
                ))}
              {(book.submit?.payment?.priceAdjustments ?? []).map((adj, idx) =>
                adj.creditCard ? (
                  <React.Fragment key={`priceAdjustmentFragment_${idx}`}>
                    <Grid item key={`${book.reserveEndTime?.unix() ?? 0}_${idx}_title`} xs={8}>
                      {adj.lineItem}
                    </Grid>
                    <Grid item key={`${book.reserveEndTime?.unix() ?? 0}_${idx}_price`} xs={4} className={classes.displayPrice}>
                      {priceItemCell(adj.price)}
                    </Grid>
                  </React.Fragment>
                ) : undefined
              )}
              <Hidden xsDown>
                <Grid className={classes.divider} item xs={12} />
              </Hidden>
              <Grid item xs={10} sm={2} className={clsx(classes.greyedTip, classes.textRight)}>
                Subtotal:
              </Grid>
              <Grid item xs={2} className={classes.displayPrice}>
                {priceItemTotal(book.submit?.payment.subtotal)}
              </Grid>
              <Grid item xs={10} sm={2} className={clsx(classes.greyedTip, classes.textRight)}>
                HST:
              </Grid>
              <Grid item xs={2} className={classes.displayPrice}>
                {priceItemCell(book.submit?.payment.taxes)}
              </Grid>
              <Grid item xs={10} sm={2} className={clsx(classes.greyedTip, classes.textRight)}>
                Total:
              </Grid>
              <Grid item xs={2} className={classes.displayPrice}>
                {priceItemTotal(book.submit?.payment.total)}
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.cardDisplay}>
                  <CardElement id="cc-primary" options={cardStyle} onChange={handleCardChange} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <br />
      </>
    );
  };

  return (
    <>
      {creditCardStatus === 'processing' || creditCardStatus === 'paymentSuccess' ? (
        <LoadingScreen helpText={creditCardStatus === 'processing' ? 'Processing credit card payment, please wait. Do not refresh your screen...' : 'Credit card processing complete. Loading results...'} />
      ) : undefined}
      {creditCardStatus === 'paymentError' ? (
        <Paper>
          <Typography>{error}</Typography>
        </Paper>
      ) : undefined}
      <div hidden={creditCardStatus !== 'complete' && creditCardStatus !== 'incomplete'}>
        <Grid style={{ marginBottom: 140 }} container spacing={3}>
          <Grid key="left-view" item xs={12} md={6}>
            {displaySelfPay(book.submit?.selfPay ?? [])}
            {(book.submit?.companyPay ?? []).sort((a, b) => recursiveCompare(a, b, [nameof<ISubmitCompanyPay>('company'), nameof<ISubmitCompanyPay>('purchaseOrder')])).map(displayPurchase)}
          </Grid>
          <Grid key="right-view" item xs={12} md={6}>
            {displayEmails()}
            {displayCredCards()}
            <Paper key="confirm-submission" className={classes.paper}>
              <div className={classes.contentWrapper}>
                <Typography variant="h6">Confirm Booking</Typography>
                <Typography>By clicking Complete Booking, you are agreeing to the terms &amp; conditions of BASES registration policy.</Typography>
                <Typography>If you have any questions or issues, please contact BASES at 519-383-1222</Typography>
                <Button
                  sx={{ mt: theme.spacing(2) }}
                  variant="contained"
                  size="small"
                  disabled={
                    book.submit == null ||
                    (book.submit.companyPay.length === 0 && book.submit.selfPay.length === 0) ||
                    ((!stripe || creditCardStatus === 'incomplete') && book.submit?.payment != null) ||
                    book.trainingSubmitting ||
                    book.reserveEndTime == null ||
                    book.reserveEndTime.isBefore()
                  }
                  className={clsx(classes.button, classes.formControl)}
                  onClick={handleComplete}
                >
                  Complete Booking
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog open={email.companyId != null} onClose={cancelEmail} fullWidth maxWidth="sm">
        <DialogTitle>Enter Email</DialogTitle>
        <DialogContent>
          <TextField fullWidth margin="dense" required id="email" label="Add Email" type="email" value={email.address} onChange={e => updateEmail(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEmail} color="primary">
            Cancel
          </Button>
          <Button onClick={saveEmail} color="primary">
            Add Email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapFromAppState = ({ book, oidc, profile }: IAppState) => {
  return {
    book,
    profile,
    user: oidc.user
  };
};

const mapScheduleActions = {
  fetchConfigRequest,
  submitAddEmail,
  submitRemoveEmail,
  submitMarkCompleting
};

export default compose(withStyles(styles), connect(mapFromAppState, mapScheduleActions))(ScheduleSubmit);

import * as React from 'react';
import { User } from 'oidc-client';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Box, Theme, useTheme } from '@mui/material';
import UserManager from '../../abstracts/UserManager';
import { GlobalActions, IAppState } from '../../stores';
import LoadingScreen from '../../components/common/LoadingScreen';

const generateSxClases = (_theme: Theme) => {
  return {
    fullHeight: {
      height: '100vh'
    }
  };
};

interface ILogoutPageProps {
  dispatch: Dispatch;
  user: User;
}

function Logout(props: ILogoutPageProps) {
  const sxClasses = generateSxClases(useTheme());
  const { dispatch, user } = props;

  React.useEffect(() => {
    dispatch({ type: GlobalActions.LOGOUT_CLEAR });

    UserManager.clearStaleState();
    UserManager.removeUser();
    UserManager.signoutRedirect({ id_token_hint: user.id_token }).catch((err) => console.log('Error signing out', err));
  }, []);

  return (
    <Box sx={{ ...sxClasses.fullHeight }}>
      <LoadingScreen helpText="Logging out..."/>
    </Box>
  );
}

function mapStateToProps(state: IAppState) {
  return {
    user: state.oidc.user
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dispatch
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Logout);

import * as React from 'react';
import settings from '../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import moment, { MomentFormatSpecification } from 'moment';
import { IQuickFilterPath } from '../auto/DefaultAutoGrid';
import { IFilterCondition, IFilterGroup, FilterConditionType, FilterGroupType, IColumnMap } from '../../stores/database/interfaces';
import { Box, Dialog, DialogTitle, DialogContent, Button, DialogActions, Grid, Checkbox, Typography, FormControlLabel, Theme, TextField, useTheme, ButtonGroup } from '@mui/material';

const generateSxClasses = (_theme: Theme) => {
  return {
    alignmentDivider: {
      flex: '1 0 0'
    },
    gridContainer: {
      alignItems: 'center'
    }
  };
};

export interface IDateRange {
  startDate?: string;
  endDate?: string;
  includeNull?: boolean;
}

const formatDateUTC = (date?: string, isUTC?: boolean, includeTime?: boolean): string | undefined => {
  if (date == null) return undefined;

  if (isUTC && includeTime) return moment(date).utcOffset(0).format(settings.apiDateTimeFormatMoment);

  return date;
};

export const getFormattedOrNull = (date: string | moment.Moment | undefined, format: string, inputFormat?: MomentFormatSpecification): string | undefined => {
  if (date == null) return undefined;

  const dateParse = moment(date, inputFormat, undefined);
  if (!dateParse.isValid()) {
    return undefined;
  }

  return dateParse.format(format);
};

export const dateRangeToFilter = (props: { range: IDateRange, column: IColumnMap, disallowNull?: boolean, isUTC?: boolean, includeTime?: boolean, not?: boolean }): IFilterCondition | IFilterGroup => {
  const dateFilter: IFilterCondition = {
    columnId: props.column.columnId,
    lookupPath: props.column.lookupPath ?? [],
    type: props.not ? FilterConditionType.LessThan : FilterConditionType.GreaterThanOrEqual,
    firstParameter: formatDateUTC(props.range.startDate, props.isUTC, props.includeTime)
  };

  if (props.range.startDate == null) {
    dateFilter.firstParameter = formatDateUTC(props.range.endDate, props.isUTC, props.includeTime);
    dateFilter.type = props.not ? FilterConditionType.GreaterThan : FilterConditionType.LessThanOrEqual;
  } else if (props.range.endDate != null) {
    dateFilter.secondParameter = formatDateUTC(props.range.endDate, props.isUTC, props.includeTime);
    dateFilter.type = props.not ? FilterConditionType.NotBetween : FilterConditionType.Between;
  }

  if (props.range.includeNull && !props.disallowNull) {
    return {
      type: FilterGroupType.Or,
      children: [dateFilter, {
        columnId: props.column.columnId,
        lookupPath: props.column.lookupPath ?? [],
        type: FilterConditionType.IsNull
      }]
    };
  }

  return dateFilter;
};

export const dateRangeToQuickFilterPath = (props: { range: IDateRange, path: string | string[], not?: boolean }): IQuickFilterPath => {
  const dateFilterPath: IQuickFilterPath = {
    path: props.path,
    not: props.not
  };

  if (props.range.startDate && !props.range.endDate) {
    dateFilterPath.value = props.range.startDate;
    dateFilterPath.condition = props.not ? FilterConditionType.LessThanOrEqual : FilterConditionType.GreaterThanOrEqual;
  } else if (!props.range.startDate && props.range.endDate) {
    dateFilterPath.value = props.range.endDate;
    dateFilterPath.condition = props.not ? FilterConditionType.GreaterThanOrEqual : FilterConditionType.LessThanOrEqual;
  } else {
    dateFilterPath.value = props.range;
  }

  return dateFilterPath;
};

export interface IDateRangeDisplayLanguage {
  empty: string;
  before: string;
  after: string;
  exactly: string;
  betweenStart: string;
  betweenJoin: string;
  onlyNull: string;
  orNull: string;
}

export interface IDateRangeFormLanguage {
  dialogHeader: string;
  description?: string;
  resetFilterButton: string;
  saveButton: string;
  nullableOption: string;
  startDate: string;
  endDate: string;
  quickSetToday: string;
  quickSetSeven: string;
  quickSetThirty: string;
}
export interface IDateRangeProps {
  classes: any;
  dateRange?: IDateRange;
  minDate?: string;
  maxDate?: string;
  optional?: boolean;
  nullableStart?: boolean;
  nullableEnd?: boolean;
  nullableDate?: boolean;
  titleOverrides?: Partial<IDateRangeFormLanguage>;
  onSave: (props?: IDateRange) => void;
}

export interface IAutoEditorButtonProps extends IDateRangeProps {
  buttonDisabled?: boolean;
  buttonTitle: string;
  fullWidth?: boolean;
  mode: 'button';
  onCancel?: () => void;
}

export interface IAutoEditorDirectProps extends IDateRangeProps {
  mode: 'direct';
  onCancel: () => void;
  open: boolean;
}

export interface IAutoEditorInlineProps extends IDateRangeProps {
  mode: 'inline';
}

export const datesToString = (range?: IDateRange, messages?: Partial<IDateRangeDisplayLanguage>) => {
  const msg: IDateRangeDisplayLanguage = {
    empty: '',
    before: 'before',
    after: 'after',
    exactly: 'is',
    betweenStart: 'between',
    betweenJoin: 'and',
    onlyNull: 'only blank',
    orNull: ', or blank',
    ...(messages ?? {})
  };

  if (range == null || (range.startDate == null && range.endDate == null)) return range?.includeNull ? msg.onlyNull : msg.empty;

  const nullableString = range.includeNull ? msg.orNull : '';

  if (range.startDate == null) return `${msg.before} ${moment(range.endDate).format(settings.dateFormatMoment)}${nullableString}`;

  if (range.endDate == null) return `${msg.after} ${moment(range.startDate).format(settings.dateFormatMoment)}${nullableString}`;

  if (range.startDate == range.endDate) return `${msg.exactly} ${moment(range.endDate).format(settings.dateFormatMoment)}${nullableString}`;

  return `${msg.betweenStart} ${moment(range.startDate).format(settings.dateFormatMoment)} ${msg.betweenJoin} ${moment(range.endDate).format(settings.dateFormatMoment)}${nullableString}`;
};

const DateRangeEdit = (props: IAutoEditorButtonProps | IAutoEditorDirectProps | IAutoEditorInlineProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const titles: IDateRangeFormLanguage = {
    dialogHeader: 'Set Date Range',
    startDate: 'Date On or After',
    endDate: 'Date On or Before',
    nullableOption: 'Include blank dates',
    saveButton: 'Set Date Range',
    resetFilterButton: 'Remove Date Range',
    quickSetToday: 'Today',
    quickSetSeven: 'Next 7 Days',
    quickSetThirty: 'Next 30 Days',
    ...(props.titleOverrides ?? {})
  };

  interface ILocalState {
    open: boolean; start?: string; startValue: boolean; end?: string; endValue: boolean; includeNull: boolean;
  }
  const [state, setState] = React.useState<ILocalState>({
    open: false, startValue: false, endValue: false, includeNull: false, start: props.minDate, end: props.maxDate
  });

  React.useEffect( () => {
    if (props.mode === 'inline' && (props.minDate != null || props.maxDate != null)) {
      props.onSave({
        startDate: props.minDate,
        endDate: props.maxDate,
        includeNull: false
      });
    }
  }, []);

  React.useEffect(() => {
    setState(old => ({
      ...old,
      start: props.dateRange?.startDate ?? getFormattedOrNull(props.minDate, settings.apiDateFormatMoment),
      end: props.dateRange?.endDate ?? getFormattedOrNull(props.maxDate, settings.apiDateFormatMoment),
      startValue: !props.nullableStart || props.dateRange?.startDate != null,
      endValue: !props.nullableEnd || props.dateRange?.endDate != null,
      includeNull: !!props.nullableDate && (props.dateRange?.includeNull ?? false)
    }));
  }, [
    props.dateRange?.startDate,
    props.dateRange?.endDate,
    props.dateRange?.includeNull,
    props.nullableStart,
    props.nullableEnd,
    props.nullableDate,
    props.minDate,
    props.maxDate
  ]);

  const handleOpen = () => {
    if (props.mode === 'button') {
      setState(old => ({
        ...old,
        open: true
      }));
    }
  };

  const handleClose = () => {
    if (props.mode !== 'inline') {
      if (props.mode === 'button') {
        setState(old => ({
          ...old,
          open: false
        }));
      }
    }
  };

  const handleCancel = () => {
    if (props.mode !== 'inline' && props.onCancel != null) props.onCancel();

    handleClose();
  };

  const handleReset = () => {
    props.onSave();

    handleClose();
  };

  const handleQuickSetToday = (_event: React.MouseEvent<HTMLElement>, trigger: boolean = false) => {
    const today = moment().format(settings.apiDateFormatMoment);

    setState((old) => ({
      ...old,
      start: today,
      startValue: true,
      end: today,
      endValue: true
    }));

    if (trigger) {
      props.onSave({
        startDate: today,
        endDate: today
      });

      handleClose();
    }
  };

  const handleQuickSetSeven = (_event: React.MouseEvent<HTMLElement>, trigger: boolean = false) => {
    const today = moment();
    const startDate = today.format(settings.apiDateFormatMoment);
    const endDate = today.add(7, 'days').format(settings.apiDateFormatMoment);

    setState((old) => ({
      ...old,
      start: startDate,
      startValue: true,
      end: endDate,
      endValue: true
    }));

    if (trigger) {
      props.onSave({
        startDate,
        endDate
      });

      handleClose();
    }
  };

  const handleQuickSetThirty = (_event: React.MouseEvent<HTMLElement>, trigger: boolean = false) => {
    const today = moment();
    const startDate = today.format(settings.apiDateFormatMoment);
    const endDate = today.add(30, 'days').format(settings.apiDateFormatMoment);

    setState((old) => ({
      ...old,
      start: startDate,
      startValue: true,
      end: endDate,
      endValue: true
    }));

    if (trigger) {
      props.onSave({
        startDate,
        endDate
      });

      handleClose();
    }
  };

  const handleSave = () => {
    props.onSave(!!props.optional && !state.startValue && !state.endValue && !state.includeNull ? undefined : {
      startDate: props.nullableStart && !state.startValue ? undefined : state.start,
      endDate: props.nullableEnd && !state.endValue ? undefined : state.end,
      includeNull: !!props.nullableDate && state.includeNull
    });

    handleClose();
  };

  const handleStartDateChange = (date: string) => {
    let startDate = date ? moment(date) : undefined;

    if (typeof startDate !== 'undefined') {
      const min = props.minDate == null ? moment(new Date(1900, 1, 1)) : moment(props.minDate);

      if (min.isAfter(startDate)) startDate = min;

      setState(old => ({
        ...old,
        start: startDate!.format(settings.apiDateFormatMoment),
        end: old.endValue && moment(old.end).isBefore(startDate) ? startDate!.format(settings.apiDateFormatMoment) : old.end
      }));

      if (props.mode === 'inline') handleSave();
    }
  };

  const handleEndDateChange = (date: string) => {
    let endDate = date ? moment(date) : undefined;

    if (typeof endDate !== 'undefined') {
      const max = props.maxDate == null ? moment(new Date(2100, 12, 31)) : moment(props.maxDate);

      if (max.isBefore(endDate)) endDate = max;

      setState(old => ({
        ...old,
        end: endDate!.format(settings.apiDateFormatMoment),
        start: old.startValue && moment(old.start).isAfter(endDate) ? endDate!.format(settings.apiDateFormatMoment) : old.start
      }));

      if (props.mode === 'inline') handleSave();
    }
  };

  const toggleStartNull = () => setState(old => ({
    ...old,
    startValue: !old.startValue
  }));

  const toggleEndNull = () => setState(old => ({
    ...old,
    endValue: !old.endValue
  }));

  const toggleIncludeNull = () => setState(old => ({
    ...old,
    includeNull: !old.includeNull
  }));

  const containerSizes = {
    start: props.nullableStart ? { xs: 11, md: 5 } : { xs: 12, md: 6 },
    end: props.nullableEnd ? { xs: 11, md: 5 } : { xs: 12, md: 6 }
  };

  const displayEditor = () => (
    <Grid sx={{ ...sxClasses.gridContainer }} container spacing={3}>
      {titles.description == null ? undefined : <Grid item xs={12}><Typography dangerouslySetInnerHTML={{ __html: titles.description }}></Typography></Grid>}
      {props.nullableStart ? <Grid item xs={1} md={1}><Checkbox checked={state.startValue} onChange={toggleStartNull} name="start-date-null"/></Grid> : undefined}
      <Grid item {...containerSizes.start}>
        <TextField
          fullWidth
          disabled={!state.startValue}
          id="startValue"
          InputLabelProps={{
            shrink: true,
            required: props.nullableStart
          }}
          inputProps={{
            min: props.minDate,
            max: props.maxDate
          }}
          label={titles.startDate}
          margin="dense"
          onChange={event => handleStartDateChange(event.currentTarget.value)}
          type="date"
          value={state.start ?? ''}
        />
      </Grid>
      {props.nullableEnd ? <Grid item xs={1} md={1}><Checkbox checked={state.endValue} onChange={toggleEndNull} name="start-date-null"/></Grid> : undefined}
      <Grid item {...containerSizes.end}>
        <TextField
          fullWidth
          disabled={!state.endValue}
          id="endValue"
          InputLabelProps={{
            shrink: true,
            required: props.nullableEnd
          }}
          inputProps={{
            min: props.minDate,
            max: props.maxDate
          }}
          label={titles.endDate}
          margin="dense"
          onChange={event => handleEndDateChange(event.currentTarget.value)}
          type="date"
          value={state.end ?? ''}
        />
      </Grid>
      {!props.nullableDate ? undefined :
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox checked={state.includeNull} onChange={toggleIncludeNull} name="allow-date-null" />
            }
            label={titles.nullableOption}
          />
        </Grid>
      }
    </Grid>
  );

  return (
    <>
      {props.mode === 'button' ? (
        <Button fullWidth={props.fullWidth} variant="contained" size="small" disabled={props.buttonDisabled} onClick={handleOpen}>
          {props.buttonTitle}
        </Button>
      ) : undefined}
      {props.mode === 'inline' ? (
        displayEditor()
      ) : (
        <Dialog open={state.open || (props.mode === 'direct' && props.open)} onClose={handleCancel} fullWidth maxWidth="md">
          <DialogTitle>{titles.dialogHeader}</DialogTitle>
          <DialogContent>{displayEditor()}</DialogContent>
          <DialogActions>
            <ButtonGroup variant="text">
              <Button onClick={(event) => handleQuickSetToday(event)} onDoubleClick={(event) => handleQuickSetToday(event, true)} color="primary">
                {titles.quickSetToday}
              </Button>
              <Button onClick={(event) => handleQuickSetSeven(event)} onDoubleClick={(event) => handleQuickSetSeven(event, true)} color="primary">
                {titles.quickSetSeven}
              </Button>
              <Button onClick={(event) => handleQuickSetThirty(event)} onDoubleClick={(event) => handleQuickSetThirty(event, true)} color="primary">
                {titles.quickSetThirty}
              </Button>
            </ButtonGroup>
            <Box sx={{ ...sxClasses.alignmentDivider }} />
            <Button onClick={handleCancel} variant="text" color="primary">
              Cancel
            </Button>
            {(titles.resetFilterButton ?? '') === '' ? undefined :
              <Button onClick={handleReset} variant="text" color="primary">
                {titles.resetFilterButton}
              </Button>
            }
            <Button onClick={handleSave} variant="text" color="primary">
              {titles.saveButton}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(createStyles({}))(DateRangeEdit);

import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { Dictionary } from 'lodash';
import { IAutoLoadConnected, AutoRow } from './AutoLoad';
import settings from '../../abstracts/settings';
import { IApiResult, IColumnMap, IColumn, ITable } from '../../stores/database/interfaces';
import { nameof, objectMapArray, objectEach, isInterface } from '../../abstracts/DataroweHelpers';
import { IAutoEditItem, IAutoEditField, IAutoChange } from './autoInterfaces';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

export interface ChangeMessage {
  key?: number;
  resultKey?: string;
  field: string;
  message: string;
}

export interface IReferenceResult {
  id: number;
  display: string;
}

export interface ISaveResult {
  success: boolean;
  referenceKeys: Dictionary<IReferenceResult>;
  messages: ChangeMessage[];
  referenceRows?: Dictionary<AutoRow>;
}

export interface IDefaultValueParameter extends IValueParameter {
  readonly: boolean;
}
export interface IDefaultNamedParameter {
  readonly: boolean;
  columnName: string;
  newValue: any;
}

export interface IValueParameter {
  columnId: number;
  newValue: any;
}

export interface IReferenceParameter {
  columnId: number;
  referenceKey: string;
}

export interface IChangeContext {
  description: string;
  triggerEmail: boolean;
  emailMessage?: string;
}

export interface IChangeSet {
  tableId: number;
  type: 'Create' | 'Update' | 'Delete';
  action: string;
  key?: number;
  companyId?: number;
  resultKey?: string;
  resultColumns?: IColumnMap[];
  changes: (IValueParameter | IReferenceParameter)[];
  changeContext?: IChangeContext;
}

export interface ITransaction {
  action: string;
  description?: string;
  changeSets: IChangeSet[];
}

type TransactionDetails = {
  title: string;
  description: string;
  page: string;
};


export const getAliasName = (names: string | string[]): string => !Array.isArray(names) ? getAliasName([names]) : names.slice(0).map(x => `${x[0].toLowerCase()}${x.slice(1)}`).join('.');

export function autoChange(props: TransactionDetails & { changes: ITransaction; } & IAutoLoadConnected): Promise<ISaveResult> {
  return new Promise((resolve, reject) => {
    const apiUrl = `${API_ENDPOINT}/db/change/.multi`;

    console.log(apiUrl, props.changes);

    axios.post(apiUrl, props.changes).then((res: AxiosResponse<IApiResult<ISaveResult>>) => {
      if (!Object.prototype.hasOwnProperty.call(res.data, nameof<IApiResult>('lastUpdated'))) {
        // fatal - connection error. Please contact BASES.
        props.createAlertBulk([{
          autoDisplay: true,
          created: moment().format(settings.dateTimeFormatMoment),
          severity: 'error',
          title: 'Invalid API configuration - AutoChange',
          description: 'A fatal error has occurred. Please reload the page. If this issue persists, please contact BASES',
          page: 'API',
          viewed: false
        }]);
        reject(`Unable to load data with autoLoadMulti. Response was ${res.status}: ${res.statusText}`);
      } else {
        props.fetchConfigRequest(res.data.lastUpdated);
        console.log(res.data.results);
        if (res.data.results.messages.length === 0) {
          props.createAlertBulk([{
            autoDisplay: true,
            created: moment().format(settings.dateTimeFormatMoment),
            severity: res.data.results.success ? 'success' : 'error',
            title: props.title,
            description: res.data.results.success ? `Record Updated Successfully - ${props.description}` : `An error occurred when updating the record - ${props.description}`,
            page: props.page,
            viewed: false
          }]);
        } else {
          props.createAlertBulk(res.data.results.messages.map(m => ({
            autoDisplay: true,
            created: moment().format(settings.dateTimeFormatMoment),
            severity: res.data.results.success ? 'success' : 'error',
            title: res.data.results.success ? 'Success' : 'An Error Occurred',
            description: m.message,
            page: props.page,
            viewed: false
          })));

        }
        resolve(res.data.results);
      }
    }).catch(err => reject(err));
  });
}

export const getValueParameterByColumn = (props: { column: IColumn, newObject?: any, oldObject?: any, newValue?: any, oldValue?: any, altKey?: string }): IValueParameter | undefined => {
  let n = props.newValue ?? props.newObject?.[props.altKey ?? getAliasName(props.column.name)] ?? undefined;
  let o = props.oldValue ?? props.oldObject?.[props.altKey ?? getAliasName(props.column.name)] ?? undefined;
  // type ColumnType = 'Int' | 'SmallInt' | 'BigInt' | 'Decimal' | 'String' | 'Boolean' | 'Date' | 'Time' | 'DateTime' | 'Lookup' | 'Json' | 'Unknown';
  const t = props.column.columnType;
  switch (t) {
    case 'Date':
    case 'DateTime':
      if (n != null) n = moment(n).format(t === 'Date' ? settings.apiDateFormatMoment : settings.apiDateTimeFormatMoment);
      if (o != null) o = moment(o).format(t === 'Date' ? settings.apiDateFormatMoment : settings.apiDateTimeFormatMoment);
      break;
    case 'Time':
      if (n != null) n = moment(`1900-01-01 ${n}`).format(settings.apiTimeFormatMoment);
      if (o != null) o = moment(`1900-01-01 ${o}`).format(settings.apiTimeFormatMoment);
      break;
  }
  if (n !== o) {
    return {
      columnId: props.column.columnId,
      newValue: n
    };
  }
  return undefined;
};

// export const columnToSelectColumnMap = (col: IColumn, lookupPath?: number[]): ISelectColumnMap => ({
//   lookupPath,
//   columnId: col.columnId,
//   columnAlias: getAliasName(col.name),
//   columnTitle: col.title,
//   columnType: col.columnType
// });

// export const getMultiLookupChanges = (db: Database, lookupKey: string, lookupTableId: number, column: IColumn, field: IAutoEditField<number[]>): IChangeSet[] => {
//   const results: IChangeSet[] = [];
//   const table = db.getTableById(column.referenceTableId!);
//   const lookupCol = objectFind(table.columns, (k, v) => v.referenceTableId === lookupTableId)!.columnId;
//   const refCol = objectFind(table.columns, (k, v) => v.columnId !== lookupCol && v.columnId !== table.primaryKeyId)!.columnId;
//   field.originalValue.forEach((v) => {
//     if (!field.editValue.some(x => x.primaryId === v.primaryId)) {
//       results.push({
//         action: 'Uncheck',
//         type: 'Delete',
//         tableId: table.tableId,
//         key: v.primaryId,
//         changes: []
//       });
//     }
//   });

//   field.editValue.forEach((v) => {
//     if (v.primaryId == null) {
//       results.push({
//         action: 'check',
//         type: 'Create',
//         tableId: table.tableId,
//         key: v.primaryId,
//         changes: [{
//           columnId: lookupCol,
//           referenceKey: lookupKey
//         }, {
//           columnId: refCol,
//           newValue: v.displayId
//         }]
//       });
//     }
//   });

//   return results;
// };

export const getAutoEditValueParameter = (column: IColumn, field: IAutoEditField): IValueParameter | undefined => {
  if (!field.isChanged) return undefined;
  switch (column.columnType) {
    case 'Date': return {
      columnId: column.columnId,
      newValue: moment(field.editValue).format(settings.apiDateFormatMoment)
    };
    case 'Time': return {
      columnId: column.columnId,
      newValue: moment(`1900-01-01 ${field.editValue}`).format(settings.apiTimeFormatMoment)
    };
    case 'DateTime': return {
      columnId: column.columnId,
      newValue: moment(field.editValue).format(settings.apiDateTimeFormatMoment)
    };
    case 'Json':
    case 'MultiLookup': return {
      columnId: column.columnId,
      newValue: JSON.stringify(field.editValue)
    };
    default: return {
      columnId: column.columnId,
      newValue: field.editValue
    };
  }
};

export const generateChanges = (item: IAutoEditItem, table: ITable): (IValueParameter | IReferenceParameter)[] =>
  objectMapArray(item, (columnId, field): IValueParameter | undefined => getAutoEditValueParameter(table.columns[columnId], field));

export const autoEditorChange = (props: IAutoChange & TransactionDetails & IAutoLoadConnected): Promise<ISaveResult> =>
  new Promise((resolve, reject) => {
    const { item, table, id, resultKey, resultColumns, ...conn } = props;
    const action: 'Create' | 'Update' = id == null ? 'Create' : 'Update';
    console.log(`${action} for table ${table.title}`, { item, table, id });

    conn.fetchConfigRequest(undefined, {
      key: `autoEditorSave_${table.tableId}_${id ?? 'new'}`,
      action: () => {
        const primary: IChangeSet = {
          action,
          resultColumns,
          resultKey: resultKey ?? 'newId',
          tableId: table.tableId,
          type: action,
          key: id,
          changes: props.additionalChanges ?? []
        };
        //, ...(props.additionalTableChanges ?? []);

        objectEach(item, (columnId, field) => {
          const col: IColumn = table.columns[columnId];
          const change = getAutoEditValueParameter(col, field);
          switch (col.columnType) {
            // case 'MultiLookup':
            //   sets = sets.concat(getMultiLookupChanges(db, resultKey ?? 'newId', table.tableId, col, field));
            //   break;
            default:
              if (change != null) primary.changes.push(change);
              break;
          }
        });

        const sets = primary.action === 'Create' || primary.changes.length > 0
          ? [primary, ...(props.additionalTableChanges ?? [])]
          : (props.additionalTableChanges ?? []).map(
            tc => ({
              ...tc, changes: tc.changes.map(
                c => isInterface<IReferenceParameter>(c, 'referenceKey') && props.resultKey != null && c.referenceKey === props.resultKey
                  ? { columnId: c.columnId, newValue: id! } : c
              )
            }));

        return autoChange({
          ...conn,
          changes: {
            action,
            changeSets: sets
          }
        }).then(resolve).catch(reject);
      }
    });
  });

export const updateRosterStatus = (workerScheduleStatusId: number, workerScheduleIds: number[]): Promise<ISaveResult> =>
  new Promise((resolve, reject) => {
    const api = `${API_ENDPOINT}/training/roster/set`;

    console.log(api, { workerScheduleStatusId, workerScheduleIds });

    axios.post(api, { workerScheduleStatusId, workerScheduleIds })
      .then((res: AxiosResponse<IApiResult<ISaveResult>>) => resolve(res.data.results))
      .catch(err => reject(err));
  });

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Dictionary, cloneDeep } from 'lodash';
import settings from '../../abstracts/settings';
import { autoChange } from '../auto/AutoChange';
import WorkerSearch from '../identity/WorkerSearch';
import { createStyles, withStyles } from '@mui/styles';
import AutoDeletePrompt from '../auto/AutoDeletePrompt';
import CourseScheduleSelect from './CourseScheduleSelect';
import { IProfileState } from '../../stores/profile/types';
import { FindReplace, Delete, EventNote } from '@mui/icons-material';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { mergeCourseScheduleDate } from '../../stores/database/training/courseSchedule';
import WorkerSchedulePrereqStatus, { prereqStatusListDisplay } from './WorkerSchedulePrereqStatus';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, IActionButton, IConnectedProps } from '../auto/AutoGrid';
import { Theme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { autoLoad, ICustomColumnDefinition, getAllFields, AutoLoadKeyDisplay, AutoRow, ICustomSelectColumnMap, ICustomDisplayDefinition } from '../auto/AutoLoad';
import { IFilterDefinition, FilterGroupType, IFilterCondition, IColumnMap, FilterConditionType, ISelectColumnMap, IFilterGroup } from '../../stores/database/interfaces';

const styles = (theme: Theme) => createStyles({
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  }
});

interface IWorkerScheduleProps {
  classes: any;
  hidden?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
  personIds?: number | number[];
  dateRange?: { start: string; end: string };
  companyIds?: number | number[];
}

interface IInternalProps {
  baseTableId: number;
  loadedConfig: boolean;
  dateField?: IColumnMap;
  personField?: IColumnMap;
  scheduleCompanyField?: IColumnMap;
  statusCompleteField?: IColumnMap;
  unionCompanyField?: IColumnMap;
  extraColumns?: ISelectColumnMap[];
}

interface ILocalAutoGridProps {
  baseTable: string | number;
  deleteId: string;
  title?: string;
  header?: string;
  mode: 'direct';
  open: boolean;
  rowData: AutoRow;
  displayField: RowFormatter<string>;
  displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition)[];
}

const WorkerSchedule = (props: IWorkerScheduleProps & IConnectedProps) => {
  const companyId = 'userCompany.company';
  const companyName = 'userCompany.company.name';
  const courseId = 'courseSchedule.course';
  const courseScheduleId = 'courseSchedule';
  const courseTypeKey = 'courseSchedule.course.courseCategory.courseType.key';
  const defaultPricePartner = 'courseSchedule.course.pricePartner';
  const defaultPriceNonPartner = 'courseSchedule.course.priceNonPartner';
  const displayIECNumber = 'person.worker.person.iecNumber';
  const displayName = 'person.worker.displayName';
  const scheduleCourseTitle = 'courseSchedule.course.title';
  const schedulePrice = 'displayPrice';
  const scheduleTrainingDate = 'courseSchedule.trainingDate';
  const scheduleTrainingTime = 'scheduledTime';
  const selfPay = 'selfPay';
  const timeslotData = 'timeslotData';
  const unionName = 'person.worker.person.companyUnion.name';
  const userCompanyId = 'userCompany';
  const userName = 'userCompany.user.displayName';
  const userPhone = 'userCompany.user.phoneNumber';
  const workerPrereqStatus = 'webPrerequisiteStatus';
  const workerScheduleStatus = 'workerScheduleStatus.complete';
  const workerScheduleStatusName = 'workerScheduleStatus.statusName';

  const formatScheduleDate = (row: Dictionary<any>) => mergeCourseScheduleDate(row[scheduleTrainingDate], row[scheduleTrainingTime], settings.dateWeekdayFormatMoment);

  const defaultColumns: ICustomColumnDefinition[] = [
    {
      key: workerScheduleStatusName
    },
    {
      key: displayIECNumber,
      displayControl: 'IECNumber'
    },
    {
      key: displayName,
      title: 'Worker Name'
    },
    {
      key: workerPrereqStatus,
      title: 'Prerequisites',
      cellAlign: 'center',
      display: row => row[workerPrereqStatus] == null ? '' : prereqStatusListDisplay(JSON.parse(row[workerPrereqStatus])).join('\n'),
      displayControl: row => <WorkerSchedulePrereqStatus
        personId={row[displayIECNumber]}
        personDisplayName={row[displayName]}
        courseDisplayName={row[scheduleCourseTitle]}
        statuses={JSON.parse(row[workerPrereqStatus])}
      />
    },
    {
      key: scheduleTrainingDate,
      display: formatScheduleDate
    },
    {
      key: scheduleCourseTitle
    },
    {
      key: selfPay,
      display: (r) => (r[selfPay] ? 'Y' : 'N')
    },
    {
      key: userName,
      title: 'Registered by User'
    },
    {
      key: userPhone,
      title: "Register's Phone Number"
    },
    {
      key: companyName,
      title: 'Registered for Company'
    },
    {
      key: unionName,
      title: 'Union'
    },
    {
      key: schedulePrice
    }
  ];

  const extraColumns = [scheduleTrainingTime, defaultPricePartner, defaultPriceNonPartner, timeslotData, companyId, userCompanyId, courseId, courseScheduleId, courseTypeKey];
  const workerSchedulePrimaryKey = 'workerScheduleId';

  const displayField: RowFormatter<string> = (data): string => `${data[displayName]} - ${data[scheduleCourseTitle]}`;

  const defaultGridParams: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps = {
    displayField,
    exportTitle: 'Worker Schedule',
    baseTable: 'training.workerSchedule',
    baseTableId: -1,
    displayColumns: [],
    additionalColumns: undefined,
    keyField: undefined,
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    selectedRows: undefined,
    loadedConfig: false,
    dateField: undefined,
    personField: undefined,
    scheduleCompanyField: undefined,
    unionCompanyField: undefined
  };

  const [gridParams, setGridParams] = React.useState<IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps>(defaultGridParams);

  const defaultLocalGridProps: ILocalAutoGridProps = {
    displayField,
    baseTable: defaultGridParams.baseTable ?? -1,
    deleteId: '',
    mode: 'direct',
    open: false,
    rowData: {},
    displayColumns: []
  };

  const [scheduleDelete, setScheduleDelete] = React.useState<ILocalAutoGridProps>(defaultLocalGridProps);

  const loadGridData = (params: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps) => {
    const filters: (IFilterGroup | IFilterCondition)[] = [];

    const companies: IFilterCondition[] = [];

    if (props.personIds != null) {
      filters.push({
        columnId: params.personField!.columnId,
        lookupPath: params.personField!.lookupPath || [],
        type: Array.isArray(props.personIds) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: props.personIds
      });

      filters.push({
        columnId: params.statusCompleteField!.columnId,
        lookupPath: params.statusCompleteField!.lookupPath || [],
        type: FilterConditionType.Equal,
        firstParameter: false
      });
    }

    if (props.companyIds != null && (!Array.isArray(props.companyIds) || props.companyIds.length > 0)) {
      companies.push({
        columnId: params.scheduleCompanyField!.columnId,
        lookupPath: params.scheduleCompanyField!.lookupPath || [],
        type: Array.isArray(props.companyIds) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: props.companyIds
      });
    }

    if (companies.length === 1) {
      filters.push(companies[0]);
    } else if (companies.length > 1) {
      filters.push({
        type: FilterGroupType.Or,
        children: companies
      });
    }

    if (props.dateRange != null) {
      filters.push({
        columnId: params.dateField!.columnId,
        lookupPath: params.dateField!.lookupPath || [],
        type: FilterConditionType.Between,
        firstParameter: moment(props.dateRange.start).format(settings.apiDateFormatMoment),
        secondParameter: moment(props.dateRange.end).format(settings.apiDateFormatMoment)
      });
    } else {
      filters.push({
        columnId: params.dateField!.columnId,
        lookupPath: params.dateField!.lookupPath || [],
        type: FilterConditionType.GreaterThan,
        firstParameter: '2020-10-27'
      });
    }

    const search: IFilterDefinition = {
      baseTableId: params.baseTableId,
      name: '',
      baseFilter: {
        type: FilterGroupType.And,
        children: filters
      }
    };

    setGridParams((old) => ({
      ...old,
      rowDataLoading: true
    }));

    autoLoad({
      search,
      baseTableId: params.baseTableId,
      keyField: params.keyField!,
      displayField: params.displayField!,
      allFields: getAllFields(params.displayColumns, params.additionalColumns),
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk
    }).then((res) => {
      setGridParams((old) => ({
        ...old,
        rowData: res,
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    });
  };

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'trainingWorkerSchedule',
      action: (db) => {
        if (gridParams.baseTable == null) return;
        const baseTableId = typeof gridParams.baseTable === 'string' ? db.getTableByCombinedName(gridParams.baseTable.toString()).tableId : +gridParams.baseTable;
        const [keyField, personField, dateField, scheduleCompanyField, unionCompanyField, statusCompleteField] = db.mapSelectColumnsByNamesWithId(baseTableId, [
          workerSchedulePrimaryKey,
          'person.worker.person',
          scheduleTrainingDate,
          'userCompany.company',
          'person.worker.person.companyUnion',
          workerScheduleStatus
        ]);
        const displayColumns = db.mapDisplayColumnsByNamesWithId(baseTableId, defaultColumns);
        const additionalColumns = db.mapSelectColumnsByNamesWithId(baseTableId, extraColumns);

        setGridParams((old) => ({ ...old, baseTableId, keyField, personField, dateField, statusCompleteField, scheduleCompanyField, unionCompanyField, displayColumns, additionalColumns, loadedConfig: true }));
        setScheduleDelete((old) => ({ ...old, displayColumns }));
        loadGridData({ ...gridParams, baseTableId, keyField, personField, dateField, statusCompleteField, scheduleCompanyField, unionCompanyField, displayColumns, additionalColumns });
      }
    });
    return () => {};
  }, []);

  React.useEffect(() => {
    if (gridParams.loadedConfig) loadGridData(gridParams);

    return () => {};
  }, [props.dateRange, props.companyIds, props.personIds]);

  interface ActionReplaceWorker {
    type: 'replace-worker' | 'reschedule-worker';
    workerScheduleId: number;
    workerScheduleCourseId?: number;
    workerScheduleCourseScheduleId?: number;
    newPersonId?: number;
    newPersonTitle: string;
    newCourseScheduleId?: number;
    newCourseScheduleTitle?: string;
    title: string;
  }

  const [actionState, setActionState] = React.useState<ActionReplaceWorker | undefined>(undefined);

  const handleActionClear = () => setActionState(undefined);

  const handleRescheduleWorker = (courses: AutoLoadKeyDisplay) => {
    const id = Object.keys(courses)[0];
    const rowData: any = courses[id].rowData;
    const courseTitle = rowData['course.title'];
    const trainingDate = moment(rowData.trainingDate);
    const trainingTime = moment(`1900-01-01 ${rowData.trainingTime}`);

    setActionState((old: ActionReplaceWorker) => ({
      ...old,
      newCourseScheduleId: +id,
      newCourseScheduleTitle: `${courseTitle} on ${trainingDate.format(settings.dateWeekdayFormatMoment)}, ${trainingTime.format(settings.timeFormatMoment)}`
    }));
  };

  const handleReplaceWorker = (workers: AutoLoadKeyDisplay) => {
    const id = Object.keys(workers)[0];
    const title = workers[id].display;

    setActionState((old: ActionReplaceWorker) => ({
      ...old,
      newPersonId: +id,
      newPersonTitle: title
    }));
  };

  const actionConfirmTitle = () => {
    if (!actionState || typeof actionState.type === 'undefined') return undefined;

    switch (actionState.type) {
      case 'replace-worker':
        return `Replace ${actionState.title} with ${actionState.newPersonTitle}?`;
      case 'reschedule-worker':
        return `Reschedule ${actionState.title} to ${actionState.newCourseScheduleTitle}?`;
    }
  };

  const handleActionConfirmCancel = () => {
    setActionState((old: ActionReplaceWorker) => ({
      ...old,
      newCourseScheduleId: undefined,
      newCourseScheduleTitle: '',
      newPersonId: undefined,
      newPersonTitle: ''
    }));
  };

  const handleActionConfirm = () => {
    if (actionState?.type === 'replace-worker') {
      autoChange({
        page: props.ui.title,
        title: 'Replace Scheduled Worker',
        description: `Replace ${actionState.title} with ${actionState.newPersonTitle}`,
        fetchConfigRequest: props.fetchConfigRequest,
        createAlertBulk: props.createAlertBulk,
        changes: {
          action: 'Replace Worker',
          changeSets: [
            {
              tableId: gridParams.baseTableId,
              key: actionState.workerScheduleId,
              type: 'Update',
              action: 'Replace Worker',
              changes: [
                {
                  columnId: props.db.database.getColumnByNameTableId(gridParams.baseTableId, 'person').columnId,
                  newValue: actionState.newPersonId
                }
              ]
            }
          ]
        }
      }).then(() => loadGridData(gridParams));
    } else if (actionState?.type === 'reschedule-worker') {
      autoChange({
        page: props.ui.title,
        title: 'Reschedule Scheduled Worker',
        description: `Reschedule ${actionState.title} to ${actionState.newCourseScheduleTitle}`,
        fetchConfigRequest: props.fetchConfigRequest,
        createAlertBulk: props.createAlertBulk,
        changes: {
          action: 'Reschedule Worker',
          changeSets: [
            {
              tableId: gridParams.baseTableId,
              key: actionState.workerScheduleId,
              type: 'Update',
              action: 'Reschedule Worker',
              changes: [
                {
                  columnId: props.db.database.getColumnByNameTableId(gridParams.baseTableId, 'courseSchedule').columnId,
                  newValue: actionState.newCourseScheduleId
                }
              ]
            }
          ]
        }
      }).then(() => loadGridData(gridParams));
    }

    handleActionClear();
  };

  const handleScheduleDeleteCancel = () => setScheduleDelete((old) => ({ ...old, open: false }));
  const handleScheduleDeleteSuccess = (success: boolean) => {
    if (success) {
      const rowData = cloneDeep(gridParams.rowData);
      delete rowData[scheduleDelete.deleteId];

      setGridParams((old) => ({
        ...old,
        rowData,
        rowDataLastUpdate: moment().valueOf()
      }));
    }

    handleScheduleDeleteCancel();
  };

  const isHidden = (timeOffsetHours: number) => (p: IProfileState, row?: AutoRow) => {
    if (row == null) return true;

    // if (props.profile.isTrainingFacility) return false;
    const company = p.companies.find((x) => x.companyId === row?.[companyId] || x.userCompanyId === row?.[userCompanyId]);

    if (company == null && !p.isTrainingFacility && p.userNoCompanyId !== row[userCompanyId]) return true;

    const courseTime = moment(`${moment(row[scheduleTrainingDate]).format(settings.apiDateFormatMoment)} ${row[scheduleTrainingTime]}`);
    const cancelTime = moment().add(p.isTrainingFacility || (company?.isPartner ?? false) ? 0 : timeOffsetHours, 'hours');
    const res = courseTime.isBefore(cancelTime);

    return res;
  };

  const postActions: IActionButton[][] = [
    [
      {
        icon: <EventNote />,
        title: 'Reschedule Scheduled Worker',
        onClick: (row) => {
          setActionState({
            type: 'reschedule-worker',
            workerScheduleId: row[workerSchedulePrimaryKey],
            workerScheduleCourseId: row[courseId],
            workerScheduleCourseScheduleId: row[courseScheduleId],
            newPersonTitle: '',
            title: `${row[displayName]} in ${row[scheduleCourseTitle]} on ${formatScheduleDate(row)}`
          });
        },
        hidden: (profile, row) => {
          return !profile.isTrainingFacility || (typeof row !== 'undefined' && row[courseTypeKey] !== 'classroom');
        }
      },
      {
        icon: <FindReplace />,
        title: 'Replace Scheduled Worker',
        onClick: (row) => {
          setActionState({
            type: 'replace-worker',
            workerScheduleId: row[workerSchedulePrimaryKey],
            newPersonTitle: '',
            title: `${row[displayName]} in ${row[scheduleCourseTitle]} on ${formatScheduleDate(row)}`
          });
        },
        hidden: isHidden(0)
      },
      {
        icon: <Delete />,
        title: 'Cancel Scheduled Worker',
        onClick: (row) => {
          setScheduleDelete((old) => ({
            ...old,
            rowData: row,
            deleteId: row[(gridParams.keyField as ISelectColumnMap).columnAlias],
            open: true
          }));
        },
        hidden: isHidden(48)
      }
    ]
  ];

  return (
    <div style={{ maxWidth: '100vw' }} hidden={props.hidden}>
      <AutoGrid
        emptyMessage="No scheduled training to show"
        minHeight={props.minHeight}
        maxHeight={props.maxHeight}
        postActions={postActions}
        pagination={[10, 25, 50, 100]}
        {...gridParams}
      />

      { actionState && actionState.type === 'replace-worker' ?
        <WorkerSearch
          mode="direct"
          limitSelectCount={1}
          open={actionState.newPersonId == null}
          onSelectCancel={handleActionClear}
          onWorkersSelected={handleReplaceWorker}
          dialogTitle={actionState.title}
        /> : undefined }

      { actionState && actionState.type === 'reschedule-worker' ?
        <CourseScheduleSelect
          mode="direct"
          limitSelectCount={1}
          open={actionState.newCourseScheduleId == null}
          onSelectCancel={handleActionClear}
          onCourseSchedulesSelected={handleRescheduleWorker}
          dialogTitle={actionState.title}
          defaultCourseFilter={actionState.workerScheduleCourseId}
          ignorePrimaryFilter={actionState.workerScheduleCourseScheduleId}
          excludedIds={actionState.workerScheduleCourseScheduleId}
        /> : undefined }

      { actionState && typeof actionState?.type !== 'undefined' ?
        <Dialog open={actionState.newPersonId != null || actionState.newCourseScheduleId != null} onClose={handleActionConfirmCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{actionState.type === 'replace-worker' ? 'Replace Worker' : 'Reschedule Worker'}?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {actionConfirmTitle()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleActionConfirmCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleActionConfirm} color="primary" autoFocus>
              Update
            </Button>
          </DialogActions>
        </Dialog> : undefined }

      <AutoDeletePrompt {...scheduleDelete} onDelete={handleScheduleDeleteSuccess} onCancel={handleScheduleDeleteCancel} />
    </div>
  );
};

export default compose(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(WorkerSchedule);

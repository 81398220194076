import { AutoRow } from '../AutoLoad';
import { createStyles, withStyles } from '@mui/styles';
import { mapMenuItems } from './helpers/SelectHelpers';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectChangeEvent, Theme } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  matchHeight: {
    marginTop: theme.spacing(1)
  }
});

interface ISelectEditorProps extends IAutoEditorImplementation<string | undefined> {
  classes: any;
}

const selectEditor = (props: ISelectEditorProps) => {
  const { classes, field, editValue, error, warning, onValueUpdated } = props;

  const handleFieldUpdate = (event: SelectChangeEvent) => {
    onValueUpdated(event.target.value, field, event.target.value);
  };

  const getMenuItemDisplay = (item: AutoRow, depth?: number) => (
    <MenuItem style={{ paddingLeft: 15 * (depth ?? 0) + 5, fontWeight: depth === 0 ? 'bold' : undefined }} key={item.id} value={item.id} disabled={!item.isSelectable}>
      {item.display}
    </MenuItem>
  );

  const getMenuItems = () => (props.valueCache == null || field.referenceTableId == null || props.valueCache[field.referenceTableId] == null ? undefined : mapMenuItems(props.valueCache[field.referenceTableId], getMenuItemDisplay));

  return (
    <FormControl error={!!error} fullWidth required={field.required} className={classes.matchHeight}>
      <InputLabel id={`selectEditor_${field.columnId}_label`}>{field.title}</InputLabel>
      <Select label={field.title} labelId={`selectEditor_${field.columnId}_label`} id={`selectEditor_${field.columnId}`} value={editValue ?? ''} onChange={handleFieldUpdate} disabled={props.disabled}>
        {field.required ? undefined : <MenuItem value="">&nbsp;</MenuItem>}
        {getMenuItems()}
      </Select>
      <FormHelperText>{error || warning || field.description}</FormHelperText>
    </FormControl>
  );
};

export default withStyles(styles)(selectEditor);

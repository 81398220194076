import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { Dictionary, pull } from 'lodash';
import settings from '../../abstracts/settings';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ICompany } from '../../stores/profile/types';
import { createStyles, withStyles } from '@mui/styles';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { IConnectedProps, mapConfigFetchToProps } from '../auto/AutoGrid';
import { arrayMapObject, updateArray, objectSortMap, nameof, regexFilterString } from '../../abstracts/DataroweHelpers';
import { ICourse, loadCourseCache, ICourseExpiry, ICourseFilters, isAllowedCourse } from '../../stores/database/training/courses';
import { Theme, Button, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, ListItem, Grid, List, TextField } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  trainingAlreadySched: {
    color: theme.palette.grey[500],
    fontStyle: 'italic'
  },
  trainingNotScheduled: {
    color: theme.palette.primary.dark
  },
  heightLimitGridCell: {
    overflow: 'auto',
    fontSize: '90%'
  },
  noPadding: {
    padding: 0
  },
  fullWithList: {
    width: '100%'
  },
  priceDisplay: {
    textAlign: 'right'
  },
  scheduleDateHeader: {
    backgroundColor: theme.palette.primary.contrastText,
    width: '100%'
  },
  scheduleTooltipInline: {
    paddingLeft: theme.spacing(2)
  },
  legendIcon: {
    textAlign: 'center'
  },
  legendText: {
    fontSize: '0.85em'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  companyList: {
    minHeight: 'calc(100vh - 300px)'
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main
  }
});

type Sides = 'none' | 'left' | 'right';

interface ICourseSelectListProps extends ICourseFilters {
  mode: 'button' | 'inline' | 'direct';
  open?: boolean;
  singleSelect?: boolean;
  dialogTitle?: string;
  buttonTitle?: string;
  showDisabled?: boolean;
  classes: any;
  courses: ICourse[];
  maxHeight?: string | number;
}

interface ICourseSelectListActions {
  onCoursesChange: (courses: ICourse[]) => void;
  onCoursesLoaded: (courses: ICourse[]) => void;
  onCancel?: () => void;
}

const CourseSelectList = (props: ICourseSelectListProps & ICourseSelectListActions & IConnectedProps) => {
  const { classes, courses, courseTypeKeys, scheduleTypeKeys, includeOnlyCourseIds, excludeCourseIds } = props;

  interface ListStateInterface {
    activeCourse?: number;
    activeCourseSide: 'none' | 'left' | 'right';
    unselectedCoursesUnfiltered: number[];
    unselectedCourses: number[];
    selectedCourses: number[];
    allCourses: Dictionary<ICourse>;
    allCoursesLoading: boolean;
    listOpen: boolean;
    listFilter: string;
  }

  const [listState, setListState] = React.useState<ListStateInterface>({
    unselectedCoursesUnfiltered: [],
    unselectedCourses: [],
    selectedCourses: courses.sort((a, b) => a.title.localeCompare(b.title)).map((x) => x.courseId),
    allCourses: arrayMapObject(courses, (i, c) => [c.courseId.toString(), c]),
    allCoursesLoading: false,
    listOpen: false,
    activeCourseSide: 'none',
    listFilter: ''
  });

  const filteredUnselectedCourses = (filter: string, unselected: number[]): number[] => {
    if (filter.length === 0) return unselected;

    const reg = regexFilterString(filter);

    return unselected.filter((x) => x === listState.activeCourse || reg.test(`${listState.allCourses[x].title} ${listState.allCourses[x].code}`));
  };

  const loadCoursesCache = () => {
    if (!listState.allCoursesLoading) {
      setListState((old) => ({ ...old, allCoursesLoading: true }));
      loadCourseCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk).then((res) => {
        if (props.onCoursesLoaded) props.onCoursesLoaded(res.courseCache.courses);

        const unselectedCoursesUnfiltered = pull(
          res.courseCache.courses
            .filter((x) => (props.showDisabled || x.active) && isAllowedCourse(x, props))
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((x) => x.courseId),
          ...listState.selectedCourses
        );

        setListState((old) => ({
          ...old,
          unselectedCoursesUnfiltered,
          unselectedCourses: filteredUnselectedCourses(listState.listFilter, unselectedCoursesUnfiltered),
          allCourses: arrayMapObject(res.courseCache.courses, (k, v) => [v.courseId.toString(), v]),
          allCoursesLoading: false
        }));
      });
    }
  };

  React.useEffect(() => {
    loadCoursesCache();
  }, []);

  React.useEffect(() => {
    setListState((old) => ({
      ...old,
      selectedCourses: courses.sort((a, b) => a.title.localeCompare(b.title)).map((x) => x.courseId)
    }));

    loadCoursesCache();

    return () => {};
  }, [courses, courseTypeKeys, scheduleTypeKeys, includeOnlyCourseIds, excludeCourseIds]);

  const handleUpdate = (courseIds?: number[]) => {
    if (props.mode === 'button') {
      setListState((old) => ({
        ...old,
        listOpen: false
      }));
    }

    props.onCoursesChange((courseIds ?? listState.selectedCourses).map((x) => listState.allCourses[x]));
  };

  const handleCopyRight = () => {
    if (listState.activeCourse != null && listState.selectedCourses.indexOf(listState.activeCourse) < 0) {
      const selectedCourses = updateArray<number>(listState.selectedCourses, listState.activeCourse!, true)
        .map((x) => listState.allCourses[x])
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((x) => x.courseId);

      const unselectedCoursesUnfiltered = updateArray<number>(listState.unselectedCoursesUnfiltered, listState.activeCourse!, false);
      const unselectedCourses = updateArray<number>(listState.unselectedCourses, listState.activeCourse!, false);

      setListState((old) => ({
        ...old,
        selectedCourses,
        unselectedCoursesUnfiltered,
        unselectedCourses,
        activeCourse: undefined,
        activeCourseSide: 'none'
      }));

      if (props.mode === 'inline') handleUpdate(selectedCourses);
    }
  };

  const handleCopyLeft = () => {
    if (listState.activeCourse != null && listState.unselectedCourses.indexOf(listState.activeCourse) < 0) {
      const selectedCourses = updateArray<number>(listState.selectedCourses, listState.activeCourse!, false);
      const unselectedCoursesUnfiltered = updateArray<number>(listState.unselectedCoursesUnfiltered, listState.activeCourse!, true)
        .map((x) => listState.allCourses[x])
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((x) => x.courseId);

      setListState((old) => ({
        ...old,
        selectedCourses,
        unselectedCoursesUnfiltered,
        unselectedCourses: filteredUnselectedCourses(listState.listFilter, unselectedCoursesUnfiltered),
        activeCourse: undefined,
        activeCourseSide: 'none'
      }));

      if (props.mode === 'inline') handleUpdate(selectedCourses);
    }
  };

  const handleCourseClick = (courseId: number, side: Sides) => {
    if (listState.activeCourse === courseId) {
      if (side === 'left') {
        handleCopyRight();
      } else if (side === 'right') {
        handleCopyLeft();
      } else {
        setListState((old) => ({
          ...old,
          activeCourse: undefined,
          activeCourseSide: 'none'
        }));
      }
    } else {
      setListState((old) => ({
        ...old,
        activeCourse: courseId,
        activeCourseSide: side
      }));
    }
  };

  const handleOpen = () => {
    if (props.mode === 'button') setListState((old) => ({ ...old, listOpen: true }));
  };

  const handleCancel = () => {
    const unselectedCoursesUnfiltered = pull(
      objectSortMap(listState.allCourses, nameof<ICourse>('title'), (x) => ((props.showDisabled || x.item.active) && isAllowedCourse(x.item, props) ? x.item.courseId : undefined)),
      ...courses.map((x) => x.courseId)
    );

    setListState((old) => ({
      ...old,
      unselectedCoursesUnfiltered,
      unselectedCourses: unselectedCoursesUnfiltered,
      selectedCourses: courses.sort((a, b) => a.title.localeCompare(b.title)).map((x) => x.courseId),
      listFilter: '',
      listOpen: false
    }));

    if (props.onCancel) props.onCancel();
  };

  const handleSelectClear = () => {
    const unselectedCoursesUnfiltered = objectSortMap(listState.allCourses, nameof<ICompany>('name'), ({ item }) => ((props.showDisabled || item.active) && isAllowedCourse(item, props) ? item.courseId : undefined));

    setListState((old) => ({
      ...old,
      unselectedCoursesUnfiltered,
      selectedCourses: [],
      unselectedCourses: filteredUnselectedCourses(listState.listFilter, unselectedCoursesUnfiltered),
      activeCourse: undefined,
      activeCourseSide: 'none'
    }));

    if (props.mode === 'inline') handleUpdate([]);
  };

  const renderExpiryDescription = (expiry?: ICourseExpiry) => {
    if (expiry == null) return 'Does not Expire';

    switch (expiry.type) {
      case 'Fixed':
        return moment(expiry.date).format(settings.dateFormatMoment);
      case 'NoRenew':
        return 'Does not Expire';
      case 'Relative':
        return `${expiry.frequency} ${expiry.frequency === 1 ? expiry.period.slice(0, -1) : expiry.period}`;
    }
  };

  const renderDurationDescription = (duration: number) => `${duration} hour${duration === 1 ? '' : 's'}`;

  const renderUnselectedRow = (renderProps: ListChildComponentProps) => {
    const { index, style } = renderProps;
    const course = listState.allCourses[listState.unselectedCourses[index]];

    return (
      <ListItem button style={style} key={course.courseId} onClick={() => handleCourseClick(course.courseId, 'left')} selected={course.courseId === listState.activeCourse}>
        <ListItemText primary={course.title} secondary={`${course.code}/${renderDurationDescription(course.duration)}/${renderExpiryDescription(course.expiry)}`} />
      </ListItem>
    );
  };

  const handleFilterChange = (text: string) => {
    setListState((old) => ({
      ...old,
      listFilter: text,
      unselectedCourses: filteredUnselectedCourses(text, listState.unselectedCoursesUnfiltered)
    }));
  };

  const listAllCourses = () => (
    <>
      <TextField fullWidth id="quick-filter" label="Filter by Name" margin="dense" defaultValue={listState.listFilter} onChange={(event) => handleFilterChange(event.currentTarget.value)} />
      <AutoSizer>{({ height, width }) => <FixedSizeList height={height - 50} width={width} itemCount={listState.unselectedCourses.length} itemSize={58}>{renderUnselectedRow}</FixedSizeList>}</AutoSizer>
    </>
  );

  const listSelectedCourse = () => (
    <List dense role="list">
      {listState.selectedCourses.map((id) => (
        <ListItem button key={id} onClick={() => handleCourseClick(id, 'right')} selected={id === listState.activeCourse}>
          <ListItemText primary={listState.allCourses[id].title} secondary={`${listState.allCourses[id].code}/${renderDurationDescription(listState.allCourses[id].duration)}/${renderExpiryDescription(listState.allCourses[id].expiry)}`} />
        </ListItem>
      ))}
    </List>
  );

  const renderSelector = () => {
    if (props.singleSelect) return <div style={{ minHeight: 'calc(100vh - 310px)' }}>{listAllCourses()};</div>;

    return (
      <Grid style={{ maxHeight: props.maxHeight ?? 'calc(100vh - 300px)' }} container spacing={2} className={classes.root}>
        <Grid item md={5} className={classes.companyList}>
          {listAllCourses()}
        </Grid>
        <Grid item md={2}>
          <Grid container direction="column" alignItems="center">
            <Button variant="outlined" size="small" className={clsx(listState.activeCourseSide !== 'left' ? undefined : classes.activeButton)} onClick={handleCopyRight} disabled={listState.activeCourseSide !== 'left'}>
              &gt;
            </Button>
            <Button variant="outlined" size="small" className={clsx(listState.activeCourseSide !== 'right' ? undefined : classes.activeButton)} onClick={handleCopyLeft} disabled={listState.activeCourseSide !== 'right'}>
              &lt;
            </Button>
            <Button variant="outlined" size="small" className={clsx(listState.selectedCourses.length === 0 ? undefined : classes.activeButton)} onClick={handleSelectClear} disabled={listState.selectedCourses.length === 0}>
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item md={5} className={classes.companyList}>
          {listSelectedCourse()}
        </Grid>
      </Grid>
    );
  };

  const companySelectDialog = () => (
    <Dialog open={props.open || listState.listOpen} onClose={handleCancel} fullWidth maxWidth="lg">
      <DialogTitle>{props.dialogTitle ?? 'Select Courses'}</DialogTitle>
      <DialogContent>{renderSelector()}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleUpdate()} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );

  const display = () => {
    switch (props.mode) {
      case 'button':
        return (
          <>
            <Button variant="contained" size="small" fullWidth onClick={handleOpen}>
              {props.buttonTitle ?? 'Select Companies'}
            </Button>
            {companySelectDialog()}
          </>
        );
      case 'direct':
        return companySelectDialog();
      case 'inline':
        return renderSelector();
    }
  };

  return display();
};

function mapStateToProps(state: IAppState) {
  return {
    db: state.db,
    profile: state.profile
  };
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapConfigFetchToProps))(CourseSelectList);

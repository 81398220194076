import moment from 'moment';
import { Reducer } from 'redux';
import { cloneDeep } from 'lodash';
import { GlobalActions } from '../../';
import { REHYDRATE } from 'redux-persist/es/constants';
import { IUploadState, UploadActionTypes, IUploadRow } from './types';
import { checkVersionNumber, versionNumber } from '../../../abstracts/ConfigureStore';

export const initialState: IUploadState = {
  rowData: {},
  lastUpdated: moment().valueOf(),
  version: versionNumber
};

const key = (item: IUploadRow) => `${item.personId}_${item.userCompanyId}_${item.courseId}`;

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: Reducer<IUploadState> = (state = initialState, action: { type: UploadActionTypes | string, payload: any, key?: string }) => {
  switch (action.type) {
    case GlobalActions.LOGOUT_CLEAR: {
      return initialState;
    }

    case REHYDRATE: {
      return checkVersionNumber(state.version, action.payload?._persist?.version, initialState, () => {
        if (action.key === 'root') {
          if (action.payload == null || action.payload.upload == null) return state;

          return {
            ...state,
            ...action.payload.upload
          };
        }

        return state;
      });
    }

    case UploadActionTypes.ADD_ROW: {
      const changes: IUploadRow[] = action.payload;
      const rowData = cloneDeep(state.rowData);

      if (changes.length === 0) return state;

      changes.forEach(x => rowData[key(x)] = x);

      return {
        ...state,
        rowData,
        lastUpdated: moment().valueOf()
      };
    }

    case UploadActionTypes.DELETE_ROW: {
      const rowData = cloneDeep(state.rowData);

      if (rowData[key(action.payload)] == null) return state;

      delete rowData[key(action.payload)];

      return {
        ...state,
        rowData,
        lastUpdated: moment().valueOf()
      };
    }

    case UploadActionTypes.CLEAR: {
      return initialState;
    }

    default: return state;
  }
};

export { reducer as uploadReducer };

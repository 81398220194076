import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../stores';
import { compose, Dispatch } from 'redux';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { isInterface } from '../abstracts/DataroweHelpers';
import LoadingScreen from '../components/common/LoadingScreen';
import { customNavigate } from '../abstracts/NavigationHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompany, IProfileState } from '../stores/profile/types';
import ListItemNavLink from '../components/navigation/ListItemNavLink';
import { INavigationLink, INavigationOutlet } from '../abstracts/NavigationLinks';
import { ChevronLeft, Info, LockOpenOutlined, Search, Settings } from '@mui/icons-material';
import { Box, Divider, Drawer, DrawerProps, Hidden, IconButton, InputBase, List, ListItem, ListItemIcon, ListItemText, Theme, Tooltip, Typography } from '@mui/material';

const SITE_VERSION = process.env.REACT_APP_VERSION_NAME;

const generateSxClasses = (theme: Theme) => {
  return {
    categoryHeader: {
      color: theme.palette.primary.contrastText,
      maxWidth: '241px'
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white
    },
    dataroweBox: {
      position: 'fixed',
      bottom: 0,
      width: '242px',
      backgroundColor: theme.palette.common.black
    },
    dataroweLabel: {
      textAlign: 'center',
      margin: '2px 0 2px 0',
      padding: '6px 0 6px 0',
      borderBottom: 'thin solid red',
      borderTop: 'thin solid red',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black
    },
    divider: {
      backgroundColor: theme.palette.primary.contrastText,
      marginTop: '10px',
      marginBottom: '10px'
    },
    inputDrawer: {
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.dark
    },
    item: {
      color: theme.palette.primary.contrastText,
      maxWidth: '241px',
      paddingBottom: '4px',
      paddingTop: '4px'
    },
    itemActionable: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    },
    itemCategory: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      padding: '16px'
    },
    toggleText: {
      fontWeight: 500,
      fontSize: theme.typography.fontSize,
      color: 'inherit',
    },
    siteVersion: {
      backgroundColor: 'yellow',
      color: 'black',
      justifyContent: 'center'
    },
    searchBar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main
    },
    searchIcon: {
      color: theme.palette.primary.contrastText
    }
  };
};

const recursiveFlatten = (linkItems: (INavigationLink | INavigationOutlet)[], parentPath: string, profile: IProfileState, company?: ICompany): INavigationLink[] => {
  let navigationLinks = [] as INavigationLink[];

  linkItems.forEach(linkItem => {
    if (isInterface<INavigationLink>(linkItem, 'title') && !!linkItem.icon && (linkItem.display == null || linkItem.display(profile, company))) {
      navigationLinks.push({
        ...linkItem,
        path: `${parentPath}/${linkItem.path}`
      });
    }

    if (linkItem.children) {
      navigationLinks = navigationLinks.concat(recursiveFlatten(linkItem.children, parentPath + '/' + linkItem.path, profile, company));
    }
  });

  return navigationLinks;
};

interface INavigatorProps {
  drawerVariant?: 'temporary' | 'permanent' | 'persistent' | undefined;
  dispatch: Dispatch;
  profile: IProfileState;
  loading: boolean;
  links: (INavigationLink | INavigationOutlet)[];
  companyLinks: INavigationLink[];
  onDrawerToggle?: () => void;
}

const Navigator = (props: INavigatorProps & DrawerProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const { drawerVariant, dispatch, profile, loading, links, companyLinks, onDrawerToggle, ...other } = props;

  const { toWorker, toWorkerFilter } = customNavigate;
  const navigate = useNavigate();

  const [searchNumber, setSearchNumber] = React.useState('');
  const handleSearchNumber = () => {
    if (isNaN(+searchNumber)) {
      navigate(toWorkerFilter(searchNumber.trim()));
    } else {
      if (searchNumber === '' || +searchNumber === 0) {
        navigate(toWorker(''));
      } else {
        navigate(toWorker(+searchNumber));
      }
    }

    setSearchNumber('');
  };

  return (
    <Drawer variant={drawerVariant} {...other}>
      <List key="menu-list" disablePadding style={{ marginBottom: 40 }}>
        <ListItem key="logo" sx={{ ...sxClasses.item, ...sxClasses.itemCategory }}>
          <img src="/images/BASES_Safety_Partnership.png" title="BASES Logo" width={200} />
        </ListItem>
        {SITE_VERSION == null ? undefined :
          <ListItem key="version-notice" sx={{ ...sxClasses.siteVersion }}>
            <Typography variant="h4">{SITE_VERSION}</Typography>
          </ListItem>
        }
        <Hidden mdUp>
          <ListItem key="toggle-sidebar" onClick={onDrawerToggle} sx={{ ...sxClasses.item, ...sxClasses.searchBar, ...sxClasses.itemActionable }}>
            <ListItemIcon>{<ChevronLeft />}</ListItemIcon>
            <ListItemText>
              <Typography sx={{ ...sxClasses.toggleText }}>
                Hide Menu
              </Typography>
            </ListItemText>
          </ListItem>
        </Hidden>
        <ListItemNavLink to="/" key="welcome" text="Welcome" isHeader icon={<FontAwesomeIcon icon={icons.faHome} />} />
        <ListItem key="workerSearch" sx={{ ...sxClasses.item, ...sxClasses.searchBar }}>
          <IconButton onClick={handleSearchNumber}>
            <Search sx={{ ...sxClasses.searchIcon }} />
          </IconButton>
          <Tooltip placement="right" title="Enter an IEC number then hit enter or click the magnifying glass to open that worker">
            <InputBase
              placeholder="Search IEC number"
              // color="inherit"
              sx={{ ...sxClasses.inputDrawer, ...sxClasses.item }}
              value={searchNumber}
              onChange={(event) => setSearchNumber(event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') handleSearchNumber();
              }}
            />
          </Tooltip>
        </ListItem>
        {loading ? (
          <LoadingScreen key="loadScreen" />
        ) : (
          recursiveFlatten(links, '', profile).map((v) => (
            <ListItemNavLink to={v.path} key={v.key} text={v.title} icon={v.icon!} />
          ))
        )}
        <Divider key="profileDivider" sx={{ ...sxClasses.divider }} />
        <ListItemNavLink to="/profile" key="profile" text={profile.displayName} icon={<Settings />} />
        <ListItemNavLink to="/logout" key="logout" text="Logout" icon={<LockOpenOutlined />} onClick={() => navigate('logout')} />
        {profile.companies.length === 0 ? undefined : (
          <>
            <Divider key="companiesDivider" sx={{ ...sxClasses.divider }} />
            {profile.companies.map((company) => (
              <React.Fragment key={`${company.userCompanyId}_title`}>
                <ListItem sx={{ ...sxClasses.categoryHeader }}>
                  <ListItemText sx={{ ...sxClasses.categoryHeaderPrimary }}>
                    {company.name} ({company.role})
                  </ListItemText>
                </ListItem>
                {recursiveFlatten(companyLinks, '', profile, company)
                  .map((l) => (
                    <ListItemNavLink
                      to={l.path.replace(':companyId', company.companyId.toString())}
                      key={`${company.userCompanyId}_${l.key}`}
                      text={l.title}
                      icon={l.icon!}
                    />
                  ))}
              </React.Fragment>
            ))}
          </>
        )}
        <Divider key="aboutDivider" sx={{ ...sxClasses.divider }} />
        {profile.isTrainingFacility ? <ListItemNavLink to="/about" key="about" text="About" icon={<Info />} /> : undefined}
      </List>
      <Box key="datarowe-box" sx={{ ...sxClasses.dataroweBox }}>
        <a href="https://Datarowe.ca" target="_blank" style={{ display: 'block', textDecoration: 'none' }} rel="noreferrer">
          <Box sx={{ ...sxClasses.dataroweLabel }}>
            powered by <span style={{ fontSize: '1.2em' }}>D</span>ATAROWE
          </Box>
        </a>
      </Box>
    </Drawer>
  );
};

function mapStateToProps(state: IAppState) {
  return {
    profile: state.profile
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dispatch
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Navigator);

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import settings from '../../abstracts/settings';
import { Grid, Theme, Paper } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { quickGrid } from 'src/abstracts/DataroweHelpers';
import { useQueryParams } from 'src/abstracts/NavigationHelpers';
import { ICourse } from '../../stores/database/training/courses';
import CourseSchedule from '../../components/training/CourseSchedule';
import { mapConfigFetchToProps } from '../../components/auto/AutoGrid';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import CourseSelectList from '../../components/training/CourseSelectList';
import DateRangeEdit, { datesToString, IDateRange } from 'src/components/common/DateRangeEdit';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  },
  verticalCenter: {
    alignItems: 'center'
  }
});

interface ISchedulePageQueryParams {
  date: IDateRange,
  courseIds: number[]
}

const SchedulePage = (props: { classes: any }) => {
  const { classes } = props;
  const [{ date, courseIds }, setParams] = useQueryParams<ISchedulePageQueryParams>({
    date: {
      type: 'IDateRange',
      defaultValue: {
        startDate: moment().format(settings.apiDateFormatMoment),
        endDate: moment().format(settings.apiDateFormatMoment)
      }
    },
    courseIds: {
      type: 'numberArray',
      defaultValue: []
    }
  });

  const [selectedCourses, setSelectedCourses] = React.useState<ICourse[]>([]);

  const handleCoursesChange = (courses: ICourse[]) => {
    setParams({
      date,
      courseIds: courses.map((c) => c.courseId)
    });

    setSelectedCourses(courses);
  };

  const handleCoursesLoaded = (courses: ICourse[]) => {
    // if start with filter (e.g. first load from URL), get courses for those ids and strip out invalid ones
    if (courseIds != null) {
      const c = courseIds.map((x) => courses.find((y) => y.courseId === x));
      const validC = c.filter((x) => x != null) as ICourse[];

      setSelectedCourses(validC);

      if (c.length !== validC.length) {
        // invalid courseIds were provided
        setParams({ date, courseIds: validC.map((x) => x.courseId) });
      }
    }
  };

  const courseButtonTitle = () => {
    if ((courseIds?.length ?? 0) === 0) return 'Filter by Course(s)';

    if (selectedCourses.length === 0) return `Filter by Course${courseIds!.length === 1 ? '' : 's'} (${courseIds!.length})`;

    if (courseIds.length > 3) return `Courses: ${selectedCourses.slice(0, 3).map((c) => c.title).join(', ')} (+${selectedCourses.length - 3})`;

    return `Courses: ${selectedCourses.map((c) => c.title).join(', ')}`;
  };

  const handleDateRangeSave = (range: IDateRange) => {
    setParams({
      courseIds,
      date: range
    });
  };

  const controlGridSizes = quickGrid(12, 6, 4, 2, 2);
  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Grid container spacing={3} className={classes.verticalCenter}>
          <Grid className={classes.root} item {...controlGridSizes}>
            <DateRangeEdit fullWidth mode="button" buttonTitle={`Course Date\n${datesToString(date)}`} dateRange={date} onSave={handleDateRangeSave} />
          </Grid>
          <Grid className={classes.root} item {...controlGridSizes}>
            <CourseSelectList
              mode="button"
              buttonTitle={courseButtonTitle()}
              courses={courseIds.map(id => selectedCourses?.[id] ).filter(c => c != null)}
              onCoursesChange={handleCoursesChange}
              onCoursesLoaded={handleCoursesLoaded}
              hideDisabled
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentWrapper}>
        <CourseSchedule startDate={date.startDate} endDate={date.endDate} courseIds={courseIds} maxHeight="calc(100vh - 260px)" />
      </div>
    </Paper>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(SchedulePage);

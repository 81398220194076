import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import AutoEditor from '../auto/AutoEditor';
import settings from '../../abstracts/settings';
import { Delete, Edit } from '@mui/icons-material';
import { Theme, Grid, Button } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import AutoDeletePrompt from '../auto/AutoDeletePrompt';
import { createAlertBulk } from 'src/stores/ui/actions';
import { IProfileState } from '../../stores/profile/types';
import { objectEach } from '../../abstracts/DataroweHelpers';
import { fetchConfigRequest } from '../../stores/database/actions';
import { ChangeMessage, IValueParameter } from '../auto/AutoChange';
import { RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';
import { mergeCourseScheduleDate } from '../../stores/database/training/courseSchedule';
import { AutoEditorCache, IDisplayField, IDefaultStateExtension } from '../auto/autoInterfaces';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, AutoGridActions, getDisplayFields } from '../auto/AutoGrid';
import { autoLoad, ICustomColumnDefinition, getAllFields, AutoRow, ICustomSelectColumnMap, ICustomDisplayDefinition } from '../auto/AutoLoad';
import { IFilterDefinition, FilterGroupType, IFilterCondition, IColumnMap, FilterConditionType, ISelectColumnMap } from '../../stores/database/interfaces';

const styles = (theme: Theme) => createStyles({
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  }
});

interface IReservesProps {
  classes: any;
  dateRange?: { start: moment.Moment; end: moment.Moment };
  courseSchedule?: number;
  minHeight?: number | string;
  maxHeight?: number | string;
  dense?: boolean;
  hideCourse?: boolean;
  hideCreate?: boolean;
  onReservesChanged?: () => void;
}

interface IConnectedProps {
  db: IDatabaseState;
  profile: IProfileState;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

interface IInternalProps {
  baseTableId: number;
  loadedConfig: boolean;
  courseScheduleField?: IColumnMap;
  dateField?: IColumnMap;
  extraColumns?: ISelectColumnMap[];
}

interface IReservesEditState {
  additionalDisplay?: IDisplayField[];
  resultColumns?: IColumnMap[];
  open: boolean;
  editId?: number;
  cache?: AutoEditorCache;
  customEditor: string;
  additionalChanges?: IValueParameter[];
  stateExtension?: IDefaultStateExtension;
}

interface IReservesDeleteState {
  baseTable?: string | number;
  deleteId: string;
  title?: string;
  header?: string;
  mode: 'direct';
  open: boolean;
  rowData: AutoRow;
  displayField?: ISelectColumnMap | RowFormatter<string>;
  displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition)[];
}

const Reserves = (props: IReservesProps & IConnectedProps) => {
  const { profile, classes } = props;

  const reserveId = 'courseScheduleReservesId';
  const companyId = 'userCompany.company';
  const userCompany = 'userCompany';
  const company = 'userCompany.company.name';
  const reserves = 'reservedSeats';
  const notes = 'notes';
  const courseScheduleId = 'courseSchedule';
  const trainingDate = 'courseSchedule.trainingDate';
  const trainingTime = 'courseSchedule.trainingTime';
  const courseCode = 'courseSchedule.course.code';
  const courseTitle = 'courseSchedule.course.title';
  const section = 'courseSchedule.sectionNumber';
  const flagOffsite = 'courseSchedule.offsite';
  const flagPilot = 'courseSchedule.pilot';
  const flagSpecial = 'courseSchedule.special';

  const displayCourse: RowFormatter<string, ISelectColumnMap> = (r): string => `${r[courseCode]}-${`0${r[section]}`.slice(-2)}${r[flagOffsite] ? 'F' : ''}${r[flagPilot] ? 'P' : ''}${r[flagSpecial] ? 'S' : ''}`;
  const displayField: RowFormatter<string> = (r, k): string => `${r[company]} - ${displayCourse(r, k)} - ${r[reserves]} reserved`;

  const defaultGridParams: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps = {
    displayField,
    exportTitle: 'Reserved Seats',
    baseTable: 'training.courseScheduleReserves',
    baseTableId: -1,
    displayColumns: [],
    additionalColumns: undefined,
    keyField: undefined,
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    selectedRows: undefined,
    loadedConfig: false,
    courseScheduleField: undefined,
    dateField: undefined
  };

  const defaultColumns = (): ICustomColumnDefinition[] => {
    const a: ICustomColumnDefinition[] = [
      {
        key: company,
        title: 'Company Name'
      }
    ];

    const b: ICustomColumnDefinition[] = [
      {
        key: trainingDate,
        display: (r) => mergeCourseScheduleDate(r[trainingDate], r[trainingTime], settings.dateWeekdayFormatMoment)
      },
      {
        key: courseCode,
        display: displayCourse,
        title: 'Course'
      },
      {
        key: courseTitle,
        title: 'Course Title'
      }
    ];

    const c: ICustomColumnDefinition[] = [
      {
        key: reserves
      },
      {
        key: notes
      }
    ];

    if (props.hideCourse) return a.concat(c);

    return a.concat(b).concat(c);
  };

  const [gridParams, setGridParams] = React.useState(defaultGridParams);

  const extraColumns = [companyId, trainingTime, courseScheduleId, section, flagOffsite, flagPilot, flagSpecial, userCompany];

  const loadData = (params: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps) => {
    const filters: IFilterCondition[] = [];

    if (props.courseSchedule != null) {
      filters.push({
        columnId: params.courseScheduleField!.columnId,
        lookupPath: params.courseScheduleField!.lookupPath || [],
        type: FilterConditionType.Equal,
        firstParameter: props.courseSchedule
      });
    }

    if (props.dateRange != null) {
      filters.push({
        columnId: params.dateField!.columnId,
        lookupPath: params.dateField!.lookupPath || [],
        type: FilterConditionType.Between,
        firstParameter: props.dateRange.start.format(settings.apiDateFormatMoment),
        secondParameter: props.dateRange.end.format(settings.apiDateFormatMoment)
      });
    }

    const search: IFilterDefinition = {
      baseTableId: params.baseTableId,
      name: '',
      baseFilter: {
        type: FilterGroupType.And,
        children: filters
      }
    };

    setGridParams((old) => ({
      ...old,
      rowDataLoading: true
    }));

    if (filters.length === 0) {
      setGridParams((old) => ({
        ...old,
        rowData: {},
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    } else {
      autoLoad({
        search,
        baseTableId: params.baseTableId,
        keyField: params.keyField!,
        displayField: params.displayField!,
        allFields: getAllFields(params.displayColumns, params.additionalColumns),
        fetchConfigRequest: props.fetchConfigRequest,
        createAlertBulk: props.createAlertBulk
      }).then((res) => {
        console.log('Reserve load', { oldLastUpdate: gridParams.rowDataLastUpdate, newLastUpdate: moment().valueOf() });

        setGridParams((old) => ({
          ...old,
          rowData: res,
          rowDataLastUpdate: moment().valueOf(),
          rowDataLoading: false
        }));
      });
    }
  };

  const [reserveEdit, setReserveEdit] = React.useState<IReservesEditState>({
    open: false,
    customEditor: 'quickEdit'
  });

  const [reserveDelete, setReserveDelete] = React.useState<IReservesDeleteState>({
    baseTable: defaultGridParams.baseTable,
    deleteId: '',
    mode: 'direct',
    open: false,
    rowData: {},
    displayColumns: []
  });

  const handleReserveDeleteSuccess = (success: boolean, messages: ChangeMessage[]) => {
    if (success) {
      const newRowData = cloneDeep(gridParams.rowData);
      delete newRowData[reserveDelete.deleteId];
      // const idx = gridParams.rowData.findIndex(x => x[(gridParams.keyField as ISelectColumnMap).columnAlias] === reserveDelete.deleteId);

      setGridParams((old) => ({
        ...old,
        rowData: newRowData,
        rowDataLastUpdate: moment().valueOf()
      }));

      if (props.onReservesChanged) props.onReservesChanged();
    } else {
      console.log('Unable to delete record', messages);
    }

    setReserveDelete((old) => ({ ...old, open: false }));
  };

  const handleReserveDeleteCancel = () => {
    setReserveDelete((old) => ({ ...old, open: false }));
  };

  const handleReserveCache = (cache: AutoEditorCache) => setReserveEdit((old) => ({
    ...old,
    cache
  }));

  const handleReserveCancel = () => {
    if (reserveEdit.open) setReserveEdit((old) => ({
      ...old,
      additionalDisplay: undefined,
      resultColumns: undefined,
      editId: undefined,
      open: false,
      additionalChanges: undefined,
      customProperties: undefined
    }));
  };

  const handleReserveSave = (newId: number, result: AutoRow) => {
    if (reserveEdit.editId != null) {
      const newRowData = cloneDeep(gridParams.rowData);
      objectEach(result, (key, v) => (newRowData[newId][key] = v));
      setGridParams((old) => ({
        ...old,
        rowData: newRowData
      }));
    } else {
      loadData(gridParams);
    }

    if (props.onReservesChanged) props.onReservesChanged();

    handleReserveCancel();
  };

  const handleCreateReserve = () => {
    setReserveEdit((old) => ({
      ...old,
      open: true,
      editId: undefined,
      customEditor: 'quickAdd',
      additionalChanges: [
        {
          columnId: gridParams.courseScheduleField!.columnId,
          newValue: props.courseSchedule
        }
      ],
      stateExtension: {
        reserveCourseScheduleId: props.courseSchedule
      }
      // additionalDisplay: getAllFields(params.displayColumns, params.additionalColumns)
    }));
  };

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'bookingReserves',
      action: (db) => {
        if (gridParams.baseTable == null) return;

        const baseTableId = typeof gridParams.baseTable === 'string' ? db.getTableByCombinedName(gridParams.baseTable.toString()).tableId : +gridParams.baseTable;
        const [keyField, courseScheduleField, dateField] = db.mapSelectColumnsByNamesWithId(baseTableId, [reserveId, courseScheduleId, trainingDate]);
        const displayColumns = db.mapDisplayColumnsByNamesWithId(baseTableId, defaultColumns());
        const additionalColumns = db.mapSelectColumnsByNamesWithId(baseTableId, extraColumns);

        setGridParams((old) => ({
          ...old,
          baseTableId,
          keyField,
          courseScheduleField,
          dateField,
          displayColumns,
          additionalColumns,
          loadedConfig: true
        }));

        loadData({
          ...gridParams,
          baseTableId,
          keyField,
          courseScheduleField,
          dateField,
          displayColumns,
          additionalColumns
        });
      }
    });
    return () => { };
  }, []);

  React.useEffect(() => {
    if (gridParams.loadedConfig) {
      loadData(gridParams);
    }

    return () => { };
  }, [props.dateRange]);

  const preActions: AutoGridActions = [
    {
      icon: <Delete />,
      hidden: (p, r) => !p.isTrainingFacility && r != null && (p.companies.find((x) => x.companyId === r[companyId])?.reserveLimit ?? 0) === 0,
      title: 'Delete',
      onClick: (r) =>
        setReserveDelete((old) => ({
          ...old,
          deleteId: r[(gridParams.keyField as ISelectColumnMap).columnAlias],
          open: true,
          rowData: r,
          displayField: gridParams.displayField,
          displayColumns: gridParams.displayColumns
        }))
    },
    {
      icon: <Edit />,
      hidden: (p, r) => !p.isTrainingFacility && r != null && (p.companies.find((x) => x.companyId === r[companyId])?.reserveLimit ?? 0) === 0,
      title: 'Edit',
      onClick: (r, k) =>
        setReserveEdit((old) => ({
          ...old,
          open: true,
          customEditor: 'quickEdit',
          editId: r[(gridParams.keyField as ISelectColumnMap).columnAlias],
          additionalDisplay: getDisplayFields(r, k, gridParams.displayColumns, [0, 1, 3]),
          additionalChanges: undefined,
          stateExtension: {
            reserveCourseScheduleId: r[courseScheduleId],
            userCompanyId: r[userCompany]
          }
        }))
    }
  ];

  return (
    <>
      <div style={{ maxWidth: '100vw' }}>
        {props.hideCreate || (!profile.isTrainingFacility && profile.companies.filter((x) => (x.reserveLimit ?? 0) > 0).length === 0) || props.courseSchedule == null ? undefined : (
          <Grid container spacing={3}>
            <Grid className={clsx(classes.root, classes.searchRoot)} item sm={12} md={4}>
              <Button variant="contained" size="small" className={clsx(classes.button, classes.formControl)} onClick={handleCreateReserve}>
                Create Reservation
              </Button>
            </Grid>
          </Grid>
        )}
        <AutoGrid dense={props.dense} minHeight={props.minHeight} maxHeight={props.maxHeight} emptyMessage="No reserves for selection" {...gridParams} preActions={preActions} />
      </div>
      <AutoEditor {...reserveEdit} baseTable="training.courseScheduleReserves" mode="direct" title="Reserved Seats" onSave={handleReserveSave} onCancel={handleReserveCancel} saveCache={handleReserveCache} />
      <AutoDeletePrompt {...reserveDelete} onDelete={handleReserveDeleteSuccess} onCancel={handleReserveDeleteCancel} />
    </>
  );
};

export default compose(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(Reserves);

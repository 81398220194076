import axios from 'axios';
import Login from './Login';
import Header from './Header';
import * as React from 'react';
import Content from './Content';
import { User } from 'oidc-client';
import Navigator from './Navigator';
import { connect } from 'react-redux';
import { IAppState } from '../stores';
import HelpDoc from '../layout/HelpDoc';
import { compose, Dispatch } from 'redux';
import ActionPage from '../pages/ActionPage';
import { processSilentRenew } from 'redux-oidc';
import { generatePaperbaseTheme } from './Theme';
import CallbackPage from '../pages/CallbackPage';
import LogoutPage from 'src/pages/common/LogoutPage';
import { IProfileState } from '../stores/profile/types';
import HelpManualPrint from '../layout/HelpManualPrint';
import { IUiStateThemeModes } from '../stores/ui/types';
import { fetchProfile } from '../stores/profile/actions';
import CardDisplayPage from '../pages/common/CardDisplayPage';
import LoadingScreen from '../components/common/LoadingScreen';
import { fetchConfigRequest } from '../stores/database/actions';
import { getParameterByName } from '../abstracts/DataroweHelpers';
import ConnectionsIssuePage from '../pages/common/ConnectionsIssuePage';
import WebCourseLaunch from '../components/course/WebDisplay/WebCourseLaunch';
import { MenuBook, Navigation, Person, TableChart } from '@mui/icons-material';
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { links, companyLinks, INavigationLink, INavigationOutlet, ICommonHelpItem } from '../abstracts/NavigationLinks';

const drawerWidth = 242;

const commonHelpTasks: ICommonHelpItem[] = [
  {
    title: 'Definitions',
    helpKey: 'D916C1C2-60BE-41E9-8F3F-E907DFCB706C',
    icon: <MenuBook/>
  },
  {
    title: 'Definition - Navigation',
    helpKey: 'ABAE8563-0BE2-4F15-ACC1-96F9FECE4D8D',
    icon: <Navigation/>
  },
  {
    title: 'Definition - Table',
    helpKey: '467BAA91-BB53-4B59-8B8A-8FA6B60A2DB4',
    icon: <TableChart/>
  },
  {
    title: 'Definition - Find Worker',
    helpKey: '98A42918-BA52-4212-8325-83F0EF6BC6AD',
    icon: <Person/>
  }
];


export interface IPaperbaseProps {
  dispatch: Dispatch;
  user: User;
  profile: IProfileState;
  themeMode: IUiStateThemeModes;
  isApiAccessible: boolean;
}

const Paperbase = (props: IPaperbaseProps) => {
  const { user, dispatch, profile, themeMode } = props;

  const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = generatePaperbaseTheme((themeMode ?? 'automatic') === 'automatic' ? prefersDarkTheme : themeMode === 'dark');
  const forcedLightTheme = generatePaperbaseTheme(false);

  const isValidUserPresent = user && !user.expired;
  const isCallbackRequested = location.pathname.toLowerCase() === '/callback';

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (location.pathname.toLowerCase() === '/callback') {
    return (
      <ThemeProvider theme={forcedLightTheme}>
        <CssBaseline/>
        <CallbackPage/>
      </ThemeProvider>
    );
  }

  if (location.pathname.toLowerCase() === '/silent') {
    processSilentRenew();
  }

  if (location.pathname.toLowerCase() === '/logout') {
    return (
      <ThemeProvider theme={forcedLightTheme}>
        <CssBaseline/>
        <LogoutPage/>
      </ThemeProvider>
    );
  }

  if (!props.isApiAccessible) {
    return (
      <ThemeProvider theme={forcedLightTheme}>
        <CssBaseline/>
        <ConnectionsIssuePage/>
      </ThemeProvider>
    );
  }

  if (location.pathname.toLowerCase() === '/caer/story') {
    return (
      <ThemeProvider theme={forcedLightTheme}>
        <CssBaseline/>
        <HelpDoc noAuth markdownKey="F6ACA1DF-9DBA-4463-BB34-9ACDAB2D632A" title="CAER Online Training"/>
      </ThemeProvider>
    );
  }

  if (isValidUserPresent) axios.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;

  if (location.pathname.toLowerCase().startsWith('/c/')) {
    const path = location.pathname.split('/');

    return (
      <ThemeProvider theme={forcedLightTheme}>
        <CssBaseline/>
        <CardDisplayPage iecNumber={path?.[3]} lookupKey={path?.[2]}/>
      </ThemeProvider>
    );
  }

  if (location.pathname.toLocaleLowerCase() === '/runcourse') {
    return (
      <ThemeProvider theme={forcedLightTheme}>
        <CssBaseline/>
        <WebCourseLaunch linkKey={getParameterByName('key')} id={getParameterByName('id')}/>
      </ThemeProvider>
    );
  }

  if (location.pathname.toLocaleLowerCase() === '/runcoursecomplete') {
    return (
      <ThemeProvider theme={forcedLightTheme}>
        <CssBaseline/>
        <LoadingScreen helpText="Verifying Content - Please refresh the page if no progress"/>
      </ThemeProvider>
    );
  }

  if (isValidUserPresent && location.pathname.toLowerCase() === '/document') {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <HelpDoc markdownKey={getParameterByName('key')} title={getParameterByName('title')}/>
      </ThemeProvider>
    );
  }

  if (isValidUserPresent && location.pathname.toLowerCase() === '/manual') {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <HelpManualPrint links={Array<INavigationLink | INavigationOutlet>().concat(links).concat(companyLinks)} commonHelpItems={commonHelpTasks}/>
      </ThemeProvider>
    );
  }

  if (isValidUserPresent && location.pathname.toLowerCase().startsWith('/action')) {
    const path = location.pathname.split('/');

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <ActionPage action={path?.[2]}/>
      </ThemeProvider>
    );
  }

  if (!isCallbackRequested && isValidUserPresent && profile.userId < 0) {
    dispatch(fetchConfigRequest());
    dispatch(fetchProfile());

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <LoadingScreen fullScreen helpText='Loading Profile'/>
      </ThemeProvider>
    );
  }

  if (isValidUserPresent || isCallbackRequested) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline/>
          <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
            {isMdUp ? null : (
              <Navigator PaperProps={{ style: { width: drawerWidth } }} drawerVariant="temporary" open={mobileOpen} onClose={handleDrawerToggle} loading={false} links={links} companyLinks={companyLinks} onDrawerToggle={handleDrawerToggle} />
            )}
            <Navigator PaperProps={{ style: { width: drawerWidth } }} drawerVariant="permanent" sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }} loading={false} links={links} companyLinks={companyLinks}/>
          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header drawerWidth={drawerWidth} onDrawerToggle={handleDrawerToggle} commonHelpItems={commonHelpTasks} prefersDarkTheme={prefersDarkTheme}/>
            <Box component="main" sx={{ flex: 1, py: 10, px: 4, bgcolor: (theme.palette.mode === 'light' ? '#EAEFF1' : '#121212') }}>
              <Content links={links} companyLinks={companyLinks} />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Login/>
    </ThemeProvider>
  );
};

function mapStateToProps(state: IAppState) {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
    isApiAccessible: state.ui.apiAccessible,
    profile: state.profile,
    themeMode: state.ui.themeMode
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dispatch
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Paperbase);

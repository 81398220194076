import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import settings from '../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import { quickGrid } from 'src/abstracts/DataroweHelpers';
import { IProfileState } from '../../stores/profile/types';
import { Button, Grid, Theme, Paper } from '@mui/material';
import { useQueryParams } from 'src/abstracts/NavigationHelpers';
import DefaultAutoGrid from '../../components/auto/DefaultAutoGrid';
import { AutoGridRowClickProps } from '../../components/auto/AutoGrid';
import CompletedBooking from '../../components/booking/CompletedBooking';
import DateRangeEdit, { datesToString, IDateRange } from 'src/components/common/DateRangeEdit';

const styles = (theme: Theme) => createStyles({
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '16px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  }
});

interface ICompletedBookingProps {
  classes: any;
}

interface IConnectedProps {
  profile: IProfileState;
}

interface ICompletedBookingQueryParams {
  date: IDateRange | undefined;
  companyIds: number[];
  bookingId?: number;
}

function CompletedBookingPage(props: ICompletedBookingProps & IConnectedProps) {
  const { classes } = props;

  const [{ date, companyIds, bookingId }, setParams] = useQueryParams<ICompletedBookingQueryParams>({
    date: {
      type: 'IDateRange',
      defaultValue: {
        startDate: moment().format(settings.apiDateFormatMoment),
        endDate: moment().format(settings.apiDateFormatMoment)
      }
    },
    companyIds: {
      type: 'numberArray',
      defaultValue: []
    },
    bookingId: {
      type: 'number'
    }
  });

  const handleBookingSelected = ({ id }: AutoGridRowClickProps) => setParams({
    date,
    companyIds,
    bookingId: +id
  });

  const handleDateRangeSave = (range: IDateRange | undefined) => {
    setParams({
      companyIds,
      bookingId,
      date: range
    });
  };

  const controlGridSizes = quickGrid(12, 6, 4, 2, 2);
  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <Grid hidden={bookingId != null} container spacing={3}>
          <Grid className={classes.root} item {...controlGridSizes}>
            <DateRangeEdit fullWidth mode="button" buttonTitle={`Date Booked ${datesToString(date, { empty: 'unfiltered' })}`} dateRange={date} onSave={handleDateRangeSave} />
          </Grid>
          <Grid className={classes.root} item xs={12}>
            <DefaultAutoGrid
              tableName="training.bookSession"
              columns={['bookingCompany', 'user.displayName', 'user.primaryEmail', 'completedUTC', 'scheduledWorkers', 'scheduledCount', 'webAssignedWorkers', 'webAssignedCount']}
              quickFilter={date == null ? [] : [{ path: 'completedUTC', value: { startDate: moment(date.startDate!).startOf('day').toString(), endDate: moment(date.endDate!).endOf('day').toString() } }]}
              pagination
              onRowClick={handleBookingSelected}
            />
          </Grid>
        </Grid>
        {bookingId == null ? null : (
          <>
            <Button variant="contained" onClick={() => setParams({ date, companyIds })} color="primary" className={classes.button}>
              Close Completed Booking
            </Button>
            <CompletedBooking booking={bookingId} />
          </>
        )}
      </div>
    </Paper>
  );
}

function mapStateToProps(state: IAppState) {
  return {
    ui: state.ui,
    profile: state.profile
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(CompletedBookingPage);

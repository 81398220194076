import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { createStyles, withStyles } from '@mui/styles';
import LoadingScreen from '../../components/common/LoadingScreen';
import { Box, Paper, Typography, Theme, useTheme } from '@mui/material';
import WorkerCardDisplay, { CardDisplayViewMode, ICardDisplay } from '../../components/identity/WorkerCardDisplay';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const generateSxClasses = (theme: Theme) => {
  return {
    contentWrapper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      mx: theme.spacing(1.25)
    },
    paper: {
      margin: 'auto',
      overflow: 'hidden'
    },
    cardDisplay: {
      mx: 'auto',
      [theme.breakpoints.only('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '450px'
      }
    }
  };
};

interface ICardDisplayProps {
  classes: any;
  iecNumber: string;
  lookupKey?: string;
}

interface ICardDisplayPageState {
  loading: boolean;
  display?: ICardDisplay;
  lookupKey?: string;
  errorMessage?: string;
  displayMode?: CardDisplayViewMode
}

const CardDisplayPage = (props: ICardDisplayProps) => {
  const sxClasses = generateSxClasses(useTheme());

  const [state, setState] = React.useState<ICardDisplayPageState>({ loading: false });

  React.useEffect(() => setState({
    loading: false,
    lookupKey: props.lookupKey
  }), [props.iecNumber, props.lookupKey]);

  const handleViewModeToggle = (displayMode: CardDisplayViewMode) => setState(old => ({
    ...old,
    displayMode
  }));

  React.useEffect(() => {
    if (state.lookupKey != null) {
      setState(old => ({
        ...old,
        loading: true
      }));

      axios.get(`${API_ENDPOINT}/Training/Card/${props.iecNumber}/${state.lookupKey}`).then((res: AxiosResponse<ICardDisplay | undefined>) => {
        if (res.data == null) {
          setState({
            loading: false,
            errorMessage: 'Invalid IEC Number or Security Key'
          });
        } else {
          setState(old => ({
            ...old,
            loading: false,
            display: res.data
          }));
        }
      });
    }
  }, [state.lookupKey]);

  const displayContent = () => {
    if (state.loading) return <LoadingScreen />;

    if (state.lookupKey == null) return (<Typography>prompt for key {JSON.stringify({ state, iecNumber: props.iecNumber, key: props.lookupKey })}</Typography>);

    if (state.display == null) return (<Typography>notify of not found</Typography>);

    return <WorkerCardDisplay card={state.display} QRUrl={window.location.href} onToggleViewMode={handleViewModeToggle} viewMode={state.displayMode}/>;
  };

  return (
    <Paper sx={{ ...sxClasses.cardDisplay, ...sxClasses.paper }}>
      <Box sx={{ ...sxClasses.contentWrapper }}>
        {displayContent()}
      </Box>
    </Paper>
  );
};

export default withStyles(createStyles({}))(CardDisplayPage);

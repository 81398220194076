import { compose } from 'redux';
import { connect } from 'react-redux';
import { Paper, Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from 'src/stores/profile/types';
import { fetchConfigRequest } from 'src/stores/database/actions';
import { mapConfigFetchToProps } from 'src/components/auto/AutoGrid';
import WorkerWebSchedule from 'src/components/training/WorkerWebSchedule';
import { mapProfileDbFromAppState, IDatabaseState } from 'src/stores/database/types';

const styles = (theme: Theme) => createStyles({
  addUser: {
    marginRight: theme.spacing(1)
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '15px 10px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  formControl: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 120
  },
  errorText: {
    color: 'red'
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 45px)'
  },
  iconButton: {
    marginTop: 20
  },
  autoGrid: {
    height: '100%',
    width: '100%'
  }
});

interface IScheduledTrainingProps {
  classes: any;
}

interface IConnectedProps {
  db: IDatabaseState;
  profile: IProfileState;
  fetchConfigRequest: typeof fetchConfigRequest;
}

const Schedule = (props: IScheduledTrainingProps & IConnectedProps) => {
  const { classes } = props;

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <WorkerWebSchedule />
      </div>
    </Paper>
  );
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(Schedule);

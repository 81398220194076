import { combineReducers, Dispatch, Action, AnyAction } from 'redux';
import { User } from 'oidc-client';
import { reducer as oidcReducer } from 'redux-oidc';
import { IUiState } from './ui/types';
import { IDatabaseState } from './database/types';
import { databaseReducer } from './database/reducer';
import { databaseSaga } from './database/sagas';
import { fork, all } from 'redux-saga/effects';
import { trainingBookReducer } from './training/booking/reducer';
import { trainingBookingSaga } from './training/booking/sagas';
import { ITrainingBookState } from './training/booking/types';
import { IProfileState } from './profile/types';
import { profileReducer } from './profile/reducer';
import { profileSaga } from './profile/sagas';
import { ICheckinState } from './training/checkin/types';
import { checkinReducer } from './training/checkin/reducer';
import { checkinSaga } from './training/checkin/sagas';
import { IUploadState } from './training/upload/types';
import { uploadReducer } from './training/upload/reducer';
import { uiReducer } from './ui/reducer';
import { uiSaga } from './ui/sagas';

export const enum GlobalActions {
  LOGOUT_CLEAR = '@@global/LOGOUT_CLEAR'

}

interface IOidcState {
  isLoadingUser: boolean;
  user: User;
}

export interface IAppState {
  oidc: IOidcState;
  ui: IUiState;
  db: IDatabaseState;
  book: ITrainingBookState;
  checkin: ICheckinState;
  profile: IProfileState;
  upload: IUploadState;
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

export const createRootReducer = () =>
  combineReducers({
    oidc: oidcReducer,
    ui: uiReducer,
    db: databaseReducer,
    book: trainingBookReducer,
    checkin: checkinReducer,
    profile: profileReducer,
    upload: uploadReducer
  });

export function* rootSaga() {
  yield all([
    fork(trainingBookingSaga),
    fork(databaseSaga),
    fork(profileSaga),
    fork(checkinSaga),
    fork(uiSaga)
  ]);
}

import { Typography, Tooltip, Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { IClassSeats } from '../../stores/training/booking/types';
import { orMin } from 'src/abstracts/DataroweHelpers';

const styles = (theme: Theme) => createStyles({
  noSeatsAvailable: {
    color: theme.palette.grey[500]
  }
});

interface ICourseListSeatsProps {
  classes: any;
  seats?: IClassSeats;
  asBoolean?: boolean;
}

const CourseListSeats = (props: ICourseListSeatsProps) => {
  const { asBoolean, seats, classes } = props;

  if (seats == null) {
    return <Typography>Seat info loading</Typography>;
  }

  if (asBoolean) {
    return orMin(seats.available) === 0
      ? <Typography component="span" className={classes.noSeatsAvailable}>Booked</Typography>
      : <Typography component="span">Available</Typography>;
  }

  const scheduled = seats.inUse?.length || 0;

  const reserves = seats.reserves == null || seats.reserves.length === 0 ? 0 : seats.reserves.reduce((a, b) => a + b.available, 0);

  return (
    <Tooltip title={
      <>
        <Typography>{`${orMin(seats.available)} seats available`}</Typography>
        {seats?.reserves == null || seats.reserves.length === 0
          ? <Typography>0 seats reserved</Typography>
          : seats.reserves.map(r =>
            <Typography key={r.companyId}>{`${r.available} seats reserved for ${r.companyName}`}</Typography>
          )}
        <Typography>{`${scheduled} seats already scheduled`}</Typography>
        {seats.inUse == null
          ? undefined
          : seats.inUse.sort((a, b) => a.personDisplayName.localeCompare(b.personDisplayName)).map(s =>
            <Typography key={s.workerScheduleId} className={classes.scheduleTooltipInline}>- {s.personDisplayName}</Typography>
          )}
      </>
    }>
      <Typography className={orMin(seats.available) === 0 && reserves === 0 ? classes.noSeatsAvailable : undefined} noWrap>
        {`${orMin(seats.available)}/${reserves}/${seats.inUse?.length} seats`}
      </Typography>
    </Tooltip>
  );
};

export default withStyles(styles, { withTheme: true })(CourseListSeats);

import moment from 'moment';
import { Dictionary } from 'lodash';
import { FilterStringType, FilterNullableType, FilterNullableStringType, FilterNumericType, ISelectColumnMap, FilterConditionType } from '../../stores/database/interfaces';

export interface ILocalTextFilter {
  type: 'textFilter';
  filter: FilterStringType | FilterNullableType | FilterNullableStringType;
  firstParameter?: string;
  secondParameter?: string;
}

export interface ILocalNumberFilter {
  type: 'numberFilter';
  filter: FilterNumericType | FilterNullableType;
  firstParameter?: number;
  secondParameter?: number;
}

export interface ILocalDateTimeFilter {
  type: 'dateFilter' | 'timeFilter' | 'dateTimeFilter';
  filter: FilterNumericType | FilterNullableType;
  firstParameter?: moment.Moment;
  secondParameter?: moment.Moment;
}

export interface ILocalBoolFilter {
  type: 'boolFilter';
  firstParameter: boolean;
}

export type LocalFilters = ILocalTextFilter | ILocalNumberFilter | ILocalDateTimeFilter | ILocalBoolFilter;
// export type LocalFilterTypes = undefined | string | number | moment.Moment;

export const filterColumn = (row: { [x: string]: any }, filters?: { [column: string]: LocalFilters }): boolean => {
  if (filters == null || row == null) return true;
  for (const c in filters) {
    const f: LocalFilters = filters[c];
    if (f.type === 'boolFilter') {
      if (row[c] !== f.firstParameter) return false;
    } else {
      switch (f.filter) {
        case FilterConditionType.Equal:
          if (row[c] !== f.firstParameter) return false;
          break;
        default:
          throw new Error(`Unimplemented filterColumn: ${JSON.stringify(filters)}`);
      }
    }
  }
  return true;
};

export const sortFunction = (a: any, b: any, c?: ISelectColumnMap, aG?: string[], bG?: string[], g?: Dictionary<{ title: string; otherColumns: string[] }>): number => {
  if (aG && bG && g) {
    for (let i = 0; i < aG.length && i < bG.length; i++) {
      if (aG[i] !== bG[i]){
        return g[aG[i]].title === g[bG[i]].title ? aG[i].localeCompare(bG[i]) : g[aG[i]].title.localeCompare(g[bG[i]].title);
      }
    }
    if (aG.length > bG.length) return 1;
    if (aG.length < bG.length) return -1;
  }
  if (a == null && b == null) return 0;
  if (a == null) return 1;
  if (b == null) return -1;
  if (c == null) {
    return `${a}`.localeCompare(`${b}`);
  }
  switch (c.columnType) {
    case 'Bool': return (a ? 1 : 0) - (b ? 1 : 0);
    case 'Date':
    case 'DateTime':
      return moment(a).diff(b);
    case 'Time':
      return moment(`1900-01-01 ${a}`).diff(`1900-01-01 ${b}`);
    case 'Decimal':
    case 'SmallInt':
    case 'BigInt':
    case 'Int':
    case 'Money':
      return a - b;
    default:
      return a.localeCompare(b);
  }
};

// export const headerFilterMenu = (column: ISelectColumnMap, filter?: LocalFilters) => {
//   return (<span></span>);
// };

import { UserManagerSettings } from 'oidc-client';
import { createUserManager } from 'redux-oidc';

const userManagerConfig: UserManagerSettings = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  authority: process.env.REACT_APP_AUTHORITY,
  automaticSilentRenew: true,
  filterProtocolClaims: true,

  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT,
  loadUserInfo: false,
  monitorSession: true,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent`,
  revokeAccessTokenOnSignout: true,
  response_type: 'token id_token',
  extraQueryParams: {
    resource: process.env.REACT_APP_RESOURCE_ID
  },
  //TODO: pull signing keys out into ENV
  signingKeys: [
    {
      kid: 'X5eXk4xyojNFum1kl2Ytv8dlNP4-c57dO6QGTVBwaNk',
      nbf: 1493763266,
      use: 'sig',
      kty: 'RSA',
      e: 'AQAB',
      n: 'tVKUtcx_n9rt5afY_2WFNvU6PlFMggCatsZ3l4RjKxH0jgdLq6CScb0P3ZGXYbPzXvmmLiWZizpb-h0qup5jznOvOr-Dhw9908584BSgC83YacjWNqEK3urxhyE2jWjwRm2N95WGgb5mzE5XmZIvkvyXnn7X8dvgFPF5QwIngGsDG8LyHuJWlaDhr_EPLMW4wHvH0zZCuRMARIJmmqiMy3VD4ftq4nS5s8vJL0pVSrkuNojtokp84AtkADCDU_BUhrc2sIgfnvZ03koCQRoZmWiHu86SuJZYkDFstVTVSR0hiXudFlfQ2rOhPlpObmku68lXw-7V-P7jwrQRFfQVXw'
    }
  ],
  scope: `openid profile ${process.env.REACT_APP_ID_URI}/user_impersonation`
  // training-platform https://authdatarowe.onmicrosoft.com/543f8d8b-cf28-4af0-b34b-5adf99030df1/543f8d8b-cf28-4af0-b34b-5adf99030df1
};

const userManager = createUserManager(userManagerConfig);

export default userManager;

import axios from 'axios';
const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

export const handleWorkerMerge = (props: { fromWorkerId: number; toWorkerId?: number }): Promise<boolean> =>
  new Promise((resolve) => {
    let api = `${API_ENDPOINT}/Account/Worker/Merge?from=${props.fromWorkerId}`;
    if (props.toWorkerId != null) api += `&to=${props.toWorkerId}`;
    console.log(api, props);
    axios.post<boolean>(api).then(res => resolve(res.data));
  });

import React from 'react';
import { compose } from 'redux';
import HelpDoc from './HelpDoc';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { IAppState } from '../stores';
import { Route, Routes } from 'react-router-dom';
import { initialState } from '../stores/ui/reducer';
import LogoutPage from '../pages/common/LogoutPage';
import { IProfileState } from '../stores/profile/types';
import DashboardPage from '../pages/common/DashboardPage';
import { isInterface } from '../abstracts/DataroweHelpers';
import RootPageHelper from '../pages/common/RootPageHelper';
import UserSettingsPage from '../pages/identity/UserSettingsPage';
import { INavigationLink, INavigationOutlet } from '../abstracts/NavigationLinks';

interface IContentProps {
  profile: IProfileState;
  links: (INavigationLink | INavigationOutlet)[];
  companyLinks: (INavigationLink | INavigationOutlet)[];
}

const recursiveGenerate = (linkItems: (INavigationLink | INavigationOutlet)[], profile: IProfileState, isCompanyLink: boolean): React.ReactNode => {
  return linkItems.map(linkItem => {
    return (
      <Route key={linkItem.key} path={linkItem.path} element={!isInterface<INavigationLink>(linkItem, 'title') || (linkItem.children?.length ?? 0) > 0 ? undefined : <RootPageHelper linkItem={linkItem} isCompanyLink={isCompanyLink} title={linkItem.title} helpGuid={linkItem.helpKey} page={linkItem.element} />}>
        {!isInterface<INavigationLink>(linkItem, 'title') || (linkItem.children?.length ?? 0) == 0 ? undefined : <Route key={`${linkItem.key}_index`} index element={<RootPageHelper linkItem={linkItem} isCompanyLink={isCompanyLink} title={linkItem.title} helpGuid={linkItem.helpKey} page={linkItem.element} />} />}
        {recursiveGenerate(linkItem.children ?? [], profile, isCompanyLink)}
      </Route>
    );
  });
};

const Content = (props: IContentProps) => {
  const { links, companyLinks, profile } = props;

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Routes>
        <Route path="/" element={
          <RootPageHelper
            title={initialState.title}
            helpGuid={initialState.helpGuid}
            page={<DashboardPage />}
          />
        } />
        <Route path="/profile" element={
          <RootPageHelper
            title='User Profile'
            helpGuid='1B0DF062-3D2F-4637-9ECE-4146DA7AA15E'
            subtitle={profile.displayName}
            page={<UserSettingsPage />}
          />}
        />
        <Route path="/about" element={
          <RootPageHelper
            title='About'
            helpGuid='1B0DF062-3D2F-4637-9ECE-4146DA7AA15E'
            page={<HelpDoc markdownKey="C48C0F56-08D2-4C93-A90B-7CE280909212" title="About the BASES App" />}
          />}
        />

        <Route path="/logout" element={<LogoutPage />} />
        {recursiveGenerate(links, profile, false)}
        {recursiveGenerate(companyLinks, profile, true)}
        <Route path="*" element={<RootPageHelper title="Not Found" helpGuid='' />} />
      </Routes>
    </Box>
  );
};


const mapStateToProps = (state: IAppState) => ({ profile: state.profile });

export default compose(connect(mapStateToProps))(Content);

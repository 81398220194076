import 'date-fns';
import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { User } from 'oidc-client';
import { connect } from 'react-redux';
import PersonList from './PersonList';
import { IAppState } from '../../stores';
import settings from '../../abstracts/settings';
import WorkerSearch from '../identity/WorkerSearch';
import { AutoLoadKeyDisplay } from '../auto/AutoLoad';
import { createStyles, withStyles } from '@mui/styles';
import { createAlertBulk } from '../../stores/ui/actions';
import { IProfileState } from '../../stores/profile/types';
import CourseList, { CourseActionType } from './CourseList';
import { refreshCacheData, fetchConfigRequest } from '../../stores/database/actions';
import { isInterface, objectMapArray, quickGrid } from '../../abstracts/DataroweHelpers';
import { getFitTestType, ITimeslotData } from '../../stores/database/training/courseSchedule';
import { ICourse, IFitTestConfig, IFitTestSchedule } from '../../stores/database/training/courses';
import { Box, Theme, Paper, Grid, Button, MenuItem, DialogTitle, DialogContent, Dialog, ListItem, ListItemText, DialogActions, List, Typography, TextField } from '@mui/material';
import { ITrainingBookState, ITrainingClassSchedule, IAssignToClass, IPersonTraining, ISessionReserveWorker, IAssignToWebCourse, ISessionWebTraining } from '../../stores/training/booking/types';
import { scheduleSetDate, scheduleGetClasses, bookActionSetMessage, scheduleAssignToClass, scheduleAddPersons, signalrHubRemoveReservation, scheduleDeletePerson, bookRestart, scheduleAssignWebCourse, signalrHubCancelWebAssign } from '../../stores/training/booking/actions';

const styles = (theme: Theme) => createStyles({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: '120px',
  },
  contentWrapper: {
    margin: `${theme.spacing(2)} ${theme.spacing(1.25)}`
  }
});

interface IWorkerTrainingLiveScheduleProps {
  classes: any;
  timerExpired: boolean;
  book: ITrainingBookState;
  profile: IProfileState;
  user: User;
}

interface IConnectedProps {
  bookActionSetMessage: typeof bookActionSetMessage;
  scheduleSetDate: typeof scheduleSetDate;
  scheduleAddPersons: typeof scheduleAddPersons;
  scheduleGetClasses: typeof scheduleGetClasses;
  scheduleAssignToClass: typeof scheduleAssignToClass;
  scheduleAssignWebCourse: typeof scheduleAssignWebCourse;
  refreshCacheData: typeof refreshCacheData;
  createAlertBulk: typeof createAlertBulk;
  fetchConfigRequest: typeof fetchConfigRequest;
  scheduleDeletePerson: typeof scheduleDeletePerson;
  bookRestart: typeof bookRestart;
}

const WorkerTrainingLiveSchedule = (props: IWorkerTrainingLiveScheduleProps & IConnectedProps) => {
  const { classes, book, profile, timerExpired } = props;

  const minStartDate = moment();
  const minEndDate = moment().add(1, 'days');

  interface IAddPerson {
    type: 'addPerson';
    courseSchedule?: ITrainingClassSchedule;
    title: string;
    fitRequests?: IFitTestSchedule[];
  }

  interface ISelectPerson {
    type: 'selectPerson';
    courseSchedule: ITrainingClassSchedule;
    anchor?: HTMLElement;
  }

  interface IPromptCompany {
    type: 'promptCompany';
    workers: IAssignToClass[];
    courseSchedule: ITrainingClassSchedule;
  }

  interface IAddPersonWeb {
    type: 'addPersonWeb';
    course: ICourse;
  }

  interface IPromptCompanyWeb {
    type: 'promptCompanyWeb';
    workers: IAssignToWebCourse[];
  }

  interface IRemovePerson {
    type: 'removePerson';
    courseSchedule: ITrainingClassSchedule;
  }

  const [actionState, setActionState] = React.useState<IAddPerson | IAddPersonWeb | ISelectPerson | IRemovePerson | IPromptCompany | IPromptCompanyWeb | { type: 'none' }>({ type: 'none' });
  const closeActionState = () => setActionState({ type: 'none' });

  React.useEffect(() => {
    if (timerExpired && actionState.type !== 'none') closeActionState();
  }, [timerExpired]);

  const getFitRequestOrder = (fitTime: string, fitRequest: IFitTestConfig[], slotData: ITimeslotData): IFitTestSchedule[] => {
    // -TODO- Get timeslot data and sort based on that selection
    let nextTime = fitTime;
    return fitRequest.map((request) => {
      const res = {
        ...request,
        scheduledTime: nextTime
      };

      nextTime = moment(`2000-01-01 ${nextTime}`).add(getFitTestType(slotData, request.scheduleType).duration, 'minutes').format(settings.apiTimeFormatMoment);

      return res;
    });
  };

  const handleLoadClassSchedule = () => {
    if (book.bookDateStart === undefined || book.bookDateEnd === undefined) {
      props.bookActionSetMessage({
        type: 'Alert',
        message: 'Start and End date required to load schedule'
      });
    } else {
      props.scheduleGetClasses({
        sessionId: book.bookSessionId!,
        startDate: book.bookDateStart,
        endDate: book.bookDateEnd,
        courseIds: [], // uniq(getTrainingListCourseIds(book.trainingList)),
        fetchConfigRequest: props.fetchConfigRequest,
        refreshCacheData: props.refreshCacheData,
        createAlertBulk: props.createAlertBulk
      });
    }
  };

  const handleStartDateChange = (date: string) => {
    let startDate = date ? moment(date) : undefined;

    if (startDate?.isBefore(minStartDate)) {
      startDate = minStartDate;
    }

    props.scheduleSetDate({
      sessionId: book.bookSessionId!,
      start: book.bookDateStart,
      end: book.bookDateEnd,
      newStart: startDate ?? undefined,
      courseIds: undefined, // book.loadedBookDates ? uniq(getTrainingListCourseIds(book.trainingList)) :
      fetchConfigRequest: props.fetchConfigRequest,
      refreshCacheData: props.refreshCacheData,
      createAlertBulk: props.createAlertBulk
    });
  };

  const handleEndDateChange = (date: string) => {
    let endDate = date ? moment(date) : undefined;

    if (endDate?.isBefore(minEndDate)) {
      endDate = minEndDate;
    }

    props.scheduleSetDate({
      sessionId: book.bookSessionId!,
      start: book.bookDateStart,
      end: book.bookDateEnd,
      newEnd: endDate ?? undefined,
      courseIds: undefined, // book.loadedBookDates ? uniq(getTrainingListCourseIds(book.trainingList)) :
      fetchConfigRequest: props.fetchConfigRequest,
      refreshCacheData: props.refreshCacheData,
      createAlertBulk: props.createAlertBulk
    });
  };

  const seatsToUse = (sched?: ITrainingClassSchedule) => (sched?.seats?.available || 0) + (sched?.seats?.reserves?.reduce((a, b) => a + b.available, 0) || 0);
  const defaultUserCompanyId = () => (profile.companies.length === 0 ? profile.userNoCompanyId : profile.companies[0].userCompanyId);

  const handleMenuWorkerSelect = (personId: number, workerDisplay: string, assignment: ITrainingClassSchedule | ICourse, photoDate?: moment.Moment, fitRequests?: IFitTestSchedule[]) => {
    if (isInterface<ICourse>(assignment, 'courseTypeKey')) {
      if (profile.companies.length > 1) {
        setActionState({
          type: 'promptCompanyWeb',
          workers: [
            {
              personId,
              workerDisplay,
              course: assignment,
              userCompanyId: defaultUserCompanyId()
            }
          ]
        });
      } else {
        props.scheduleAssignWebCourse(book.bookSessionId!, [
          {
            personId,
            workerDisplay,
            photoDate,
            course: assignment,
            userCompanyId: defaultUserCompanyId()
          }
        ]);
        closeActionState();
      }
    } else {
      if (profile.companies.length > 1) {
        setActionState({
          type: 'promptCompany',
          courseSchedule: assignment,
          workers: [
            {
              personId,
              workerDisplay,
              courseSchedule: assignment,
              userCompanyId: defaultUserCompanyId(),
              timeslotData: fitRequests
            }
          ]
        });
      } else {
        props.scheduleAssignToClass(book.bookSessionId!, [
          {
            personId,
            workerDisplay,
            photoDate,
            courseSchedule: assignment,
            userCompanyId: defaultUserCompanyId(),
            timeslotData: fitRequests
          }
        ]);
        closeActionState();
      }
    }
  };

  const menuItems: CourseActionType[] = [
    (schedule: ITrainingClassSchedule | ICourse, _p?: any, fitTime?: string, fitConfig?: IFitTestConfig[], slotData?: ITimeslotData[]) => (
      <MenuItem
        key="select-new"
        disabled={!isInterface<ICourse>(schedule, 'courseTypeKey') && fitTime == null && seatsToUse(schedule) < 1}
        onClick={() => {
          if (isInterface<ICourse>(schedule, 'courseTypeKey')) {
            setActionState({
              type: 'addPersonWeb',
              course: schedule
            });
          } else {
            setActionState({
              type: 'addPerson',
              courseSchedule: schedule,
              title: '',
              fitRequests: fitTime == null ? undefined : getFitRequestOrder(fitTime, fitConfig!, slotData!.find((fitSlot) => fitSlot.start <= fitTime && fitSlot.end >= fitTime)!)
            });
          }
        }}
      >
        Select New Worker(s)
      </MenuItem>
    )
  ].concat(
    // eslint-disable-next-line react/display-name
    objectMapArray(book.personTraining, (id, v) => (schedule: ITrainingClassSchedule | ICourse, p?: any, fitTime?: string, fitConfig?: IFitTestConfig[], slotData?: ITimeslotData[]) =>
      <MenuItem
        key={`${id}_${isInterface<ICourse>(schedule, 'courseTypeKey') ? 'course' : 'schedule'}`}
        onClick={() => handleMenuWorkerSelect(+id, v.displayName, schedule, undefined, fitTime == null ? undefined : getFitRequestOrder(fitTime, fitConfig!, slotData!.find((sDat) => sDat.start <= fitTime && sDat.end >= fitTime)!))}
        disabled={fitTime == null && !isInterface<ICourse>(schedule, 'courseTypeKey') && (schedule.attachedPersons.filter((x) => x.id === +id).length > 0 || seatsToUse(schedule) < 1)}
      >
        {v.displayName}
      </MenuItem>
    )
  );

  const controlGridSizes = quickGrid(12, 6, 6, 3, 3);

  const handleNewWorkerButton = () => {
    setActionState({
      type: 'addPerson',
      title: 'Add Worker to List'
    });
  };

  const handleCompanySelect = (userCompanyId: number) => {
    if (actionState.type === 'promptCompany') {
      const assigns = actionState.workers.map((x) => ({ ...x, userCompanyId }));
      props.scheduleAssignToClass(book.bookSessionId!, assigns);
    } else if (actionState.type === 'promptCompanyWeb') {
      const assigns = actionState.workers.map((x) => ({ ...x, userCompanyId }));
      props.scheduleAssignWebCourse(book.bookSessionId!, assigns);
    }

    closeActionState();
  };

  const handleWorkerAdd = (workers: AutoLoadKeyDisplay) => {
    if (actionState.type === 'addPersonWeb') {
      const assigns = objectMapArray(
        workers,
        (key, { display, rowData }): IAssignToWebCourse => ({
          personId: parseInt(key, 10),
          workerDisplay: display,
          photoDate: rowData['person.pictureTakenUTC'] == null ? undefined : moment(rowData['person.pictureTakenUTC']),
          course: actionState.course!,
          userCompanyId: defaultUserCompanyId()
        })
      );

      if (profile.companies.length > 1 && actionState.course != null) {
        setActionState({
          type: 'promptCompanyWeb',
          workers: assigns
        });
      } else {
        props.scheduleAssignWebCourse(book.bookSessionId!, assigns);
        setActionState({ type: 'none' });
      }
    } else if (actionState.type === 'addPerson') {
      if (actionState.courseSchedule != null) {
        const assigns = objectMapArray(workers, (key, { display, rowData }): IAssignToClass => ({
          personId: parseInt(key, 10),
          workerDisplay: display,
          photoDate: rowData['person.pictureTakenUTC'] == null ? undefined : moment(rowData['person.pictureTakenUTC']),
          courseSchedule: actionState.courseSchedule!,
          userCompanyId: defaultUserCompanyId(),
          timeslotData: actionState.fitRequests
        }));

        if (profile.companies.length > 1 && actionState.courseSchedule != null) {
          setActionState({
            type: 'promptCompany',
            courseSchedule: actionState.courseSchedule,
            workers: assigns
          });
        } else {
          props.scheduleAssignToClass(book.bookSessionId!, assigns);
          setActionState({ type: 'none' });
        }
      } else {
        props.scheduleAddPersons(workers);
        setActionState({ type: 'none' });
      }
      console.log(workers);
    }
  };

  const generateCompanyChoices = (): React.ReactElement[] => {
    if (actionState.type === 'promptCompany') {
      const seats = actionState.courseSchedule.seats!;

      return profile.companies.map((c) => {
        const reserves = seats.reserves?.find((x) => x.companyId === c.companyId)?.available ?? 0;
        return (
          <ListItem key={c.companyId} button onClick={() => handleCompanySelect(c.userCompanyId)} disabled={seats.scheduleData == null && seats.available < 1 && reserves === 0}>
            {seats.scheduleTypeKey === 'sma'
              ? <ListItemText primary={c.name} />
              : <ListItemText primary={c.name} secondary={seats.scheduleData != null ? undefined : `${reserves === 0 ? seats.available : `${reserves} reserved`} seat${seats.available === 1 ? '' : 's'} available`} />
            }
          </ListItem>
        );
      });
    }

    if (actionState.type === 'promptCompanyWeb') {
      return profile.companies.map((c) => (
        <ListItem key={c.companyId} button onClick={() => handleCompanySelect(c.userCompanyId)}>
          <ListItemText primary={c.name} />
        </ListItem>
      ));
    }
    return [];
  };

  const handleWorkerDeleteReserve = (personId: number, courseScheduleId: number) => {
    signalrHubRemoveReservation(book.hubConnection!, book.bookSessionId!, personId, courseScheduleId);
  };

  const handleWorkerCancelAssign = (personId: number, courseId: number) => {
    signalrHubCancelWebAssign(book.hubConnection!, book.bookSessionId!, personId, courseId);
  };

  const handleWorkerDelete = (personId: number) => {
    props.scheduleDeletePerson(book.bookSessionId!, personId);
  };

  const reduceCallback = (a: any, b: any) => a + b;
  const getSubtotal = (): number => {
    const classSubtotal = objectMapArray(book.personTraining, (_personId, person: IPersonTraining): number => objectMapArray(person.reservedClassTraining, (_scheduleId, schedule: ISessionReserveWorker): number => schedule[profile.pricingField])
      .reduce(reduceCallback, 0))
      .reduce(reduceCallback, 0);

    const webSubtotal = objectMapArray(book.personTraining, (_personId, person: IPersonTraining): number => objectMapArray(person.assignedWebTraining, (_assignedWebId, assignedWeb: ISessionWebTraining): number => assignedWeb[profile.pricingField])
      .reduce(reduceCallback, 0))
      .reduce(reduceCallback, 0);

    return classSubtotal + webSubtotal;
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Box className={classes.contentWrapper}>
          <Grid sx={{ alignItems: 'center' }} container spacing={3}>
            <Grid item {...controlGridSizes}>
              <TextField
                fullWidth
                id="mui-pickers-startdate"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: minStartDate.format(settings.apiDateFormatMoment) }}
                label="Schedule Start Date"
                margin="dense"
                onChange={(event) => handleStartDateChange(event.currentTarget.value)}
                required
                type="date"
                value={book.bookDateStart.format(settings.apiDateFormatMoment)}
              />
            </Grid>
            <Grid item {...controlGridSizes}>
              <TextField
                fullWidth
                id="mui-pickers-enddate"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: minEndDate.format(settings.apiDateFormatMoment) }}
                label="Schedule End Date"
                margin="dense"
                onChange={(event) => handleEndDateChange(event.currentTarget.value)}
                required
                type="date"
                value={book.bookDateEnd.format(settings.apiDateFormatMoment)}
              />
            </Grid>
            <Grid item {...quickGrid(12, 12, 12, 6, 6)}>
              {book.loadedBookDates === 'Loaded' ? (
                <Button fullWidth variant="contained" size="small" onClick={() => props.bookRestart()}>
                  Discard &amp; Restart
                </Button>
              ) : (
                <Button fullWidth variant="contained" size="small" onClick={handleLoadClassSchedule}>
                  Load
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.contentWrapper}>
          <Grid container spacing={3}>
            <Grid className={classes.root} item xs={12} sm={12} md={4}>
              <PersonList
                persons={book.personTraining}
                maxHeight="calc(100vh - 500px)"
                onAddWorkerButtonClick={handleNewWorkerButton}
                onWorkerDelete={handleWorkerDelete}
                onWorkerDeleteReserve={handleWorkerDeleteReserve}
                onWorkerCancelAssign={handleWorkerCancelAssign}
              />
              <Typography style={{ width: '100%', textAlign: 'right' }}>Subtotal: {Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(getSubtotal())}</Typography>
            </Grid>
            <Grid className={classes.root} item xs={12} sm={12} md={8}>
              <CourseList courseSchedule={book.courseSchedule} courses={book.courses} courseActions={menuItems} menuParams={Object.keys(book.personTraining).length > 0} profile={profile} maxHeight="calc(100vh - 440px)" />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <WorkerSearch
        mode="direct"
        open={actionState.type === 'addPerson' || actionState.type === 'addPersonWeb'}
        onWorkersSelected={handleWorkerAdd}
        onSelectCancel={closeActionState}
        dialogTitle={actionState.type === 'addPerson' && actionState.title != null ? actionState.title : 'Add Worker'}
        limitSelectCount={actionState.type === 'addPerson' && actionState.courseSchedule != null ? (actionState.fitRequests != null ? 1 : seatsToUse(actionState.courseSchedule)) : undefined}
      />
      <Dialog open={actionState.type === 'promptCompany' || actionState.type === 'promptCompanyWeb'} onClose={closeActionState}>
        <DialogTitle>{`Select which company to use for ${actionState.type === 'promptCompany' || actionState.type === 'promptCompanyWeb' ? actionState.workers.length : 0} worker(s) (required)`}</DialogTitle>
        <DialogContent>
          <List>{generateCompanyChoices()}</List>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeActionState} color="primary" className={classes.root}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapFromAppState = ({ book, oidc, profile }: IAppState) => {
  return { book, profile, user: oidc.user };
};

const mapScheduleActions = {
  bookActionSetMessage,
  scheduleSetDate,
  scheduleGetClasses,
  scheduleAddPersons,
  scheduleAssignToClass,
  scheduleAssignWebCourse,
  fetchConfigRequest,
  createAlertBulk,
  refreshCacheData,
  scheduleDeletePerson,
  bookRestart
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapFromAppState, mapScheduleActions))(WorkerTrainingLiveSchedule);

import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { validateApi } from 'src/stores/ui/actions';
import { createStyles, withStyles } from '@mui/styles';
import { Box, Paper, Typography, Theme, Toolbar, useTheme } from '@mui/material';
import LoadingScreen from '../../components/common/LoadingScreen';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const generateSxClasses = (theme: Theme) => {
  return {
    paper: {
      margin: 'auto',
      overflow: 'hidden',
      marginBottom: theme.spacing(1.25)
    },
    toolbar: {
      justifyContent: 'center'
    },
    wrapper: {
      m: theme.spacing(2)
    }
  };
};

interface IConnectionIssueProps {
  classes: any;
}

interface IConnectedProps {
  apiAccessible: boolean;
  apiValidated?: number;
}

interface IConnectedActions {
  validateApi: typeof validateApi;
}

const ConnectionIssue = (props: IConnectionIssueProps & IConnectedProps & IConnectedActions) => {
  const sxClasses = generateSxClasses(useTheme());

  const [check, setCheck] = React.useState(false);

  React.useEffect(() => {
    if (!check) {
      setCheck(true);

      const timeout = new Promise((_resolve, reject) => {
        setTimeout(reject, 3000, 'request timed out');
      });

      const request = fetch(`${API_ENDPOINT}/Account/alive`);

      Promise.race([timeout, request]).then(() => {
        props.validateApi(true);

        setCheck(false);
      }).catch(() => {
        props.validateApi(false);

        setCheck(false);
      });
    }
  });

  return props.apiValidated == null ? (
    <div style={{ height: '100vh' }}>
      <LoadingScreen helpText="Connecting to Site"/>
    </div>
  ) : (
    <Box sx={{ ...sxClasses.wrapper }}>
      <Paper sx={{ ...sxClasses.paper }}>
        <Toolbar sx={{ ...sxClasses.toolbar }}>
          <Typography>Unable to connect to api at {API_ENDPOINT}. This may be because your internet is down, or the site is being blocked by your IT. After troubleshooting, please refresh the page to check if you are connected.</Typography>
        </Toolbar>
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state: IAppState): IConnectedProps => {
  return {
    apiAccessible: state.ui.apiAccessible,
    apiValidated: state.ui.apiValidated
  };
};

const mapValidateAction = { validateApi };

export default compose(withStyles(createStyles({})), connect(mapStateToProps, mapValidateAction))(ConnectionIssue);

import clsx from 'clsx';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Paper, Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../../stores/profile/types';
import { postGenerateCustomReport } from 'src/components/auto/AutoLoad';

const styles = (theme: Theme) => createStyles({
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '10px 16px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  }
});

interface IDashboardProps {
  classes: any;
}

interface IConnectedProps {
  profile: IProfileState;
}

const dataroweCustomReportPage = (props: IDashboardProps & IConnectedProps) => {
  const { classes } = props;

  const handleReportClick = () => postGenerateCustomReport();

  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography>The following reports are available. Click a report to download it.</Typography>
        </Toolbar>
        <div className={classes.contentWrapper}>
          <Button onClick={handleReportClick} variant="contained" color="primary" className={clsx(classes.root, classes.formControl)}>
            Union Workers - All Training
          </Button>
          <Typography>**Note: report can take a few minutes to complete generation. Once started, please keep the tab open until the report is complete.</Typography>
        </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    profile: state.profile
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, undefined))(dataroweCustomReportPage);

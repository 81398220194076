import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import BasesIcon from '../../layout/BasesIcon';
import settings from '../../abstracts/settings';
import WorkerPhoto from '../identity/WorkerPhoto';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../../stores/profile/types';
import { intersection, difference, isEqual, sortBy, Dictionary } from 'lodash';
import { isInterface, nameof, objectSortMap } from '../../abstracts/DataroweHelpers';
import { formatFitTestSchedule, ICourse } from '../../stores/database/training/courses';
import { KeyboardArrowRight, Add, Done, ErrorOutline, Delete, Edit } from '@mui/icons-material';
import { ISessionWebTraining, IPersonTraining, ISessionReserveWorker, ITrainingBookState, IReserveFields, ReserveFieldStatus } from '../../stores/training/booking/types';
import { Theme, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Typography, Grid, Button, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, ListItemIcon } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  trainingAlreadySched: {
    color: theme.palette.grey[500],
    fontStyle: 'italic'
  },
  heightLimitGridCell: {
    overflow: 'auto',
    fontSize: '90%'
  },
  legendIcon: {
    textAlign: 'center'
  },
  nestedContentWrapper: {
    marginBottom: theme.spacing(1.25)
  },
});

interface IPersonListProps {
  classes: any;
  profile: IProfileState;
  persons: { [personId: number]: IPersonTraining };
  maxHeight?: string | number;
  selectedPersonId?: number;
}

interface IPersonConnectedProps {
  onWorkerSelect?: (id: number, display: string, photoDate?: moment.Moment) => void;
  onAddWorkerButtonClick?: () => void;
  onWorkerDelete?: (personId: number) => void;
  onWorkerDeleteReserve: (personId: number, courseScheduleId: number) => void;
  onWorkerCancelAssign: (personId: number, courseId: number) => void;
  book: ITrainingBookState;
}

const PersonList = (props: IPersonListProps & IPersonConnectedProps) => {
  const { classes, book, profile } = props;
  const [listState, setListState] = React.useState<{ order: number[]; editPersonId?: number }>({ order: objectSortMap(book.personTraining, nameof<IPersonTraining>('displayName'), (x) => +x.key) });

  React.useEffect(() => {
    const personIds = Object.keys(book.personTraining).map((x) => +x);

    if (!isEqual(sortBy(personIds), sortBy(listState.order))) {
      const newOrder = intersection(listState.order, personIds);
      newOrder.push(...difference(personIds, newOrder));
      setListState((old) => ({ ...old, order: newOrder }));
    }

    return () => {};
  }, [book.personTraining]);

  const scheduleDisplay = (schedule: (ISessionReserveWorker | ISessionWebTraining)) => {
    const displayPrice = Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(schedule.price ?? schedule[profile.pricingField]);

    if (isInterface<ISessionReserveWorker>(schedule, 'courseScheduleId')) {
      if (Object.keys(book.courses).length > 0 && schedule.timeslotData != null && schedule.course.scheduleTypeKey === 'fittest') {
        return (
          <Typography component="span">
            {moment(schedule.trainingDate).format(settings.dateFormatMoment)} {schedule.timeslotData?.map((s) => formatFitTestSchedule(s, book.courses)).join('/')}: {displayPrice}
          </Typography>
        );
      }

      return (
        <Typography component="span">
          {moment(schedule.trainingDate).format(settings.dateTimeFormatMoment)}: {schedule.course.code} - {displayPrice}
        </Typography>
      );
    }

    return (
      <Typography component="span">
        Web-Based: {schedule.course.code} - {displayPrice}
      </Typography>
    );
  };

  const handlePersonSelect = (personId: number) => {
    if (props.onWorkerSelect != null) props.onWorkerSelect(personId, book.personTraining[personId].displayName, book.personTraining[personId].photoDate);
  };

  const handlePersonDelete = (personId: number) => {
    if (props.onWorkerDelete) props.onWorkerDelete(personId);
  };

  const handlePersonEdit = (personId: number) => setListState((old) => ({ ...old, editPersonId: personId }));
  const handlePersonEditClose = () => setListState((old) => ({ ...old, editPersonId: undefined }));

  const reserveStatusIcon = (type: ReserveFieldStatus) => {
    switch (type) {
      case 'complete':
        return <Done color="primary" />;
      case 'loading':
        return <BasesIcon xs animated />;
      case 'error':
        return <ErrorOutline color="error" />;
    }
  };

  const displayCourseOnWorker = (items: Dictionary<ISessionReserveWorker | ISessionWebTraining>, personId: number, typeName: string,) =>
    objectSortMap(items, [[`${nameof<IReserveFields>('course')}`, `${nameof<ICourse>('code')}`]], ({ item, key }) => (
      <span key={`${typeName}-${personId}-${key}`}>{reserveStatusIcon(item.status)}{scheduleDisplay(item)}<br /></span>
    ));

  return (
    <>
      <div className={classes.nestedContentWrapper}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {props.onAddWorkerButtonClick == null ? undefined : (
              <Button variant="contained" color="primary" startIcon={<Add />} onClick={props.onAddWorkerButtonClick}>
                Add Worker
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
      <table>
        <tbody>
          <tr>
            <td className={props.classes.legendIcon}>
              <Done color="primary" />
            </td>
            <td>
              <Typography className={props.classes.legendText}>Seat successfully reserved</Typography>
            </td>
          </tr>
          <tr>
            <td className={props.classes.legendIcon}>
              <BasesIcon xs />
            </td>
            <td>
              <Typography className={props.classes.legendText}>Attempting to reserve</Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <List key="person-list" className={props.classes.heightLimitGridCell} style={{ maxHeight: props.maxHeight || 400 }}>
        {listState.order.map((personId) => {
          const p: IPersonTraining = book.personTraining[personId];

          if (p == null) return undefined;

          return (
            <ListItem button selected={props.selectedPersonId === personId} key={`person-${personId}`} onClick={() => handlePersonSelect(personId)}>
              <ListItemAvatar key={`person-avatar-${personId}`}>
                <WorkerPhoto personId={personId} photoTakenUTC={p.photoDate} workerName={p.displayName} diameter={48} />
              </ListItemAvatar>
              <ListItemText
                key={`person-text-${personId}`}
                primary={p.displayName}
                secondary={
                  <span className={props.classes.trainingAlreadySched}>
                    {displayCourseOnWorker(p.reservedClassTraining, personId, 'class')}
                    {displayCourseOnWorker(p.assignedWebTraining, personId, 'web')}
                  </span>
                }
              />

              <ListItemSecondaryAction>
                {props.selectedPersonId !== personId ? (
                  Object.keys(p.reservedClassTraining).length > 0 || Object.keys(p.assignedWebTraining).length > 0 ? (
                    props.onWorkerDeleteReserve == null ? undefined : (
                      <IconButton onClick={() => handlePersonEdit(personId)}>
                        <Edit />
                      </IconButton>
                    )
                  ) : props.onWorkerDelete == null ? undefined : (
                    <IconButton onClick={() => handlePersonDelete(personId)}>
                      <Delete />
                    </IconButton>
                  )
                ) : (
                  <Tooltip title="Select course on the right to add this worker to that course">
                    <KeyboardArrowRight />
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Dialog open={listState.editPersonId !== undefined && props.persons[listState.editPersonId] != null} onClose={handlePersonEditClose}>
        <DialogTitle>Edit {listState.editPersonId !== undefined && props.persons[listState.editPersonId] != null ? props.persons[listState.editPersonId].displayName : 'Worker'} Courses</DialogTitle>
        <DialogContent>
          <List>
            {listState.editPersonId === undefined || props.persons[listState.editPersonId] == null ? undefined : (
              <>
                {objectSortMap(props.persons[listState.editPersonId].reservedClassTraining, [[nameof<ISessionReserveWorker>('course'), nameof<ICourse>('title')]], (item) => {
                  const t: ISessionReserveWorker = props.persons[listState.editPersonId!].reservedClassTraining[item.key];

                  return (
                    <ListItem key={`sched_${t.bookSessionReserveId}`} button onClick={() => props.onWorkerDeleteReserve(listState.editPersonId!, t.courseScheduleId)}>
                      <ListItemIcon>
                        <Delete />
                      </ListItemIcon>
                      <ListItemText primary={scheduleDisplay(t)} />
                    </ListItem>
                  );
                })}
                {objectSortMap(props.persons[listState.editPersonId].assignedWebTraining, [[nameof<ISessionWebTraining>('course'), nameof<ICourse>('title')]], (wt) => (
                  <ListItem key={`web_${wt.item.bookSessionWebTrainingId}`} button onClick={() => props.onWorkerCancelAssign(listState.editPersonId!, wt.item.course.courseId)}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText primary={scheduleDisplay(wt.item)} />
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePersonEditClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapFromAppState = ({ book, profile }: IAppState) => {
  return {
    book,
    profile
  };
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapFromAppState))(PersonList);

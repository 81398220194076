import moment from 'moment';
import { Reducer } from 'redux';
import { GlobalActions } from '../../';
import { ICheckinHub } from './actions';
import { REHYDRATE } from 'redux-persist';
import * as signalR from '@microsoft/signalr';
import { cloneDeep, Dictionary } from 'lodash';
import { AutoRow } from '../../../components/auto/AutoLoad';
import { objectMap } from '../../../abstracts/DataroweHelpers';
import { ICheckinState, CheckinActionTypes, CheckinColumnPaths } from './types';
import { checkVersionNumber, versionNumber } from '../../../abstracts/ConfigureStore';

const HUB_ENDPOINT = process.env.REACT_APP_REG_HUB_URL || '';

export const initialState: ICheckinState = {
  courses: {},
  hubConnection: undefined,
  hubConnectionLoading: false,
  connectedCourseSchedules: [],
  checkinRowData: {},
  checkinCourses: {},
  checkinFilterProps: undefined,
  checkinGridParams: {
    baseTable: 'Training.WorkerSchedule',
    displayColumns: [],
    exportTitle: 'Checkin List',
    keyField: undefined,
    displayField: undefined
  },
  checkinRowDataLastUpdate: moment().valueOf(),
  checkinRowDataLoading: false,
  fittestRowData: {},
  fittestCourses: {},
  fittestFilterProps: undefined,
  fittestGridParams: {
    baseTable: 'Training.WorkerSchedule',
    displayColumns: [],
    exportTitle: 'Checkin List',
    keyField: undefined,
    displayField: undefined
  },
  fittestRowDataLastUpdate: moment().valueOf(),
  fittestRowDataLoading: false,
  version: versionNumber
};

const setArrivalField = (rows: Dictionary<AutoRow>, workerScheduleId: number, arrival?: moment.Moment | true): Dictionary<AutoRow> => {
  const newRow = cloneDeep(rows[workerScheduleId]);

  if (arrival !== true) {
    newRow[CheckinColumnPaths.checkinDate] = arrival == null ? undefined : moment(arrival);
    newRow[CheckinColumnPaths.scheduleStatus] = arrival == null ? 'TrainingScheduled' : 'TrainingStarted';
  } else {
    newRow[CheckinColumnPaths.checkinDate] = true;
  }

  return {
    ...rows,
    [workerScheduleId]: newRow
  };
};

const setPersonField = (rows: Dictionary<AutoRow>, personId: number, field: CheckinColumnPaths.hasWhmis | CheckinColumnPaths.identityVerified, newValue: boolean | null): Dictionary<AutoRow> =>
  objectMap(rows, (_id, row) => {
    const res = cloneDeep(row);

    if (res[CheckinColumnPaths.personId] === personId) res[field] = newValue;

    return res;
  });

export const connectHub = async (props: ICheckinHub): Promise<signalR.HubConnection> => {
  const { onPersonUpdateWHMIS, onPersonUpdateVerified, onScheduleUpdateArrival, onScheduleUpdateStatus, user }: ICheckinHub = props;
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${HUB_ENDPOINT}/training?access_token=${user.access_token}`, {
      // skipNegotiation: true,
      // transport: signalR.HttpTransportType.WebSockets
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.on('CheckinUpdateWHMIS', onPersonUpdateWHMIS);
  connection.on('CheckinUpdateVerified', onPersonUpdateVerified);
  connection.on('CheckinUpdateArrival', onScheduleUpdateArrival);
  connection.on('CheckinUpdateStatus', onScheduleUpdateStatus);

  await connection.start().catch(e => console.log(e));

  return connection;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: Reducer<ICheckinState> = (state: ICheckinState = initialState, action: { type: CheckinActionTypes | string, payload: any, key?: string }) => {
  switch (action.type) {
    case GlobalActions.LOGOUT_CLEAR: {
      return initialState;
    }

    case REHYDRATE: {
      return checkVersionNumber(state.version, action.payload?._persist?.version, initialState, () => {
        return {
          ...state,
          hubConnection: undefined,
          hubConnectionLoading: false
        };
      });
    }

    case CheckinActionTypes.HUB_CLEAR: {
      return {
        ...state,
        hubConnection: undefined,
        hubConnectionLoading: false,
        lastRender: moment().valueOf()
      };
    }

    case CheckinActionTypes.HUB_CONNECT_START: {
      return {
        ...state,
        hubConnectionLoading: true
      };
    }

    case CheckinActionTypes.HUB_CONNECT: {
      return {
        ...state,
        hubConnection: action.payload,
        hubConnectionLoading: false
      };
    }

    case CheckinActionTypes.CHECKIN_CONNECT_END: {
      return {
        ...state,
        connectedCourseSchedules: action.payload
      };
    }

    case CheckinActionTypes.LOAD_CONFIG: {
      const { gridParams, filterProps, type } = action.payload;

      return {
        ...state,
        [`${type}GridParams`]: gridParams,
        [`${type}FilterProps`]: filterProps,
        [`${type}RowDataLoading`]: true,
      };
    }

    case CheckinActionTypes.LOAD_FILTER: {
      const { type } = action.payload;

      return {
        ...state,
        [`${type}RowDataLoading`]: true
      };
    }

    case CheckinActionTypes.LOAD_DATA: {
      const { filter, rowData, courses, courseSchedules, type } = action.payload;

      return {
        ...state,
        connectedCourseSchedules: courseSchedules,
        courses: courses ?? state.courses,
        [`${type}Filter`]: filter,
        [`${type}RowData`]: rowData,
        [`${type}RowDataLoading`]: false,
        [`${type}RowDataLastUpdate`]: moment().valueOf()
      };
    }

    case CheckinActionTypes.MERGE_QUICK_ADD_WORKER_SCHEDULES: {
      const { rowData } = action.payload;

      return {
        ...state,
        fittestRowData: { ...state.fittestRowData, ...rowData },
        fittestRowDataLoading: false,
        fittestRowDataLastUpdate: moment().valueOf()
      };
    }

    case CheckinActionTypes.CHECKIN_COURSE: {
      return {
        ...state,
        checkinRowData: setArrivalField(state.checkinRowData, action.payload.workerScheduleId, true)
      };
    }

    case CheckinActionTypes.CHECKIN_COURSE_DONE: {
      const { workerScheduleId, arrival } = action.payload;
      const newRow = cloneDeep(state.checkinRowData[workerScheduleId]);
      newRow[CheckinColumnPaths.checkinDate] = arrival;

      return {
        ...state,
        checkinRowData: setArrivalField(state.checkinRowData, action.payload.workerScheduleId, arrival)
      };
    }

    case CheckinActionTypes.CHECKIN_TOGGLEWHMIS: {
      return {
        ...state,
        checkinRowData: setPersonField(state.checkinRowData, action.payload.personId, CheckinColumnPaths.hasWhmis, null)
      };
    }

    case CheckinActionTypes.CHECKIN_TOGGLEWHMIS_DONE: {
      return {
        ...state,
        checkinRowData: setPersonField(state.checkinRowData, action.payload.personId, CheckinColumnPaths.hasWhmis, action.payload.whmis)
      };
    }

    case CheckinActionTypes.CHECKIN_TOGGLEVERIFIED: {
      return {
        ...state,
        checkinRowData: setPersonField(state.checkinRowData, action.payload.personId, CheckinColumnPaths.identityVerified, null)
      };
    }

    case CheckinActionTypes.CHECKIN_TOGGLEVERIFIED_DONE: {
      return {
        ...state,
        checkinRowData: setPersonField(state.checkinRowData, action.payload.personId, CheckinColumnPaths.identityVerified, action.payload.verified)
      };
    }

    default: {
      if (action.type.startsWith(CheckinActionTypes.__ENUM_ROOT)) {
        console.log(`unhandled type ${action.type} for ICheckinState reducer`);
      }

      return state;
    }
  }
};

export { reducer as checkinReducer };

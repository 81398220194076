import { CallbackComponent } from 'redux-oidc';
import { useNavigate } from 'react-router-dom';
import UserManager from '../abstracts/UserManager';
import LoadingScreen from '../components/common/LoadingScreen';

const RESET_PASSWORD = process.env.REACT_APP_PASSWORD_RESET || '';

const Callback = () => {
  const navigate = useNavigate();

  return (
    <CallbackComponent
      userManager={UserManager}
      successCallback={t => navigate(t.state)}
      errorCallback={(error) => {
        if (error.message.startsWith('AADB2C90118')) {
          window.location.replace(RESET_PASSWORD);
        } else {
          navigate('/Error');
          console.error(error);
        }
      }}
    >
      <div style={{ height: '100vh' }}>
        <LoadingScreen helpText="Logging in, please wait..." />
      </div>
    </CallbackComponent>
  );
};

export default Callback;

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { AutoRow } from '../auto/AutoLoad';
import { Search } from '@mui/icons-material';
import { createStyles, withStyles } from '@mui/styles';
import { quickGrid } from '../../abstracts/DataroweHelpers';
import { ICompanyAlias } from '../../stores/database/interfaces';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { loadCompaniesCache } from '../../stores/database/identity/companies';
import { AutoSearchTypes } from '../../stores/database/gridExtension/interfaces';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, AutoGridRowClickProps, IConnectedProps } from '../auto/AutoGrid';
import { Grid, TextField, Icon, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, InputAdornment, Theme, useTheme } from '@mui/material';

const generateSxClasses = (_theme: Theme) => {
  return {
    gridContainer: {
      alignItems: 'center'
    }
  };
};

interface ICompanyListProps extends IConnectedProps {
  disabled?: boolean;
  maxHeight?: string | number;
  onAddNew: () => void;
  onCompaniesLoaded?: (aliases: ICompanyAlias, companies: Dictionary<AutoRow>) => void;
  onCompanySelected: (companyId: number | undefined, name: string, userCompanyId?: number) => void;
  onQuickFilter?: (filter:string) => void;
  quickFilter?: string;
}

export interface ICompanyListButtonProps extends ICompanyListProps {
  allowNoSelect?: boolean;
  buttonTitle: string;
  dialogTitle?: string;
  fullWidth?: boolean;
  mode: 'button';
  onCancel?: () => void;
  open: boolean;
}

export interface ICompanyListButtonSelectProps extends ICompanyListProps {
  allowNoSelect?: boolean;
  buttonTitle: string;
  companyId?: number;
  dialogTitle?: string;
  fullWidth?: boolean;
  mode: 'button-select';
  onCancel?: () => void;
  open: boolean;
  selectTitle: string;
  selectUnderLength?: number;
}

export interface ICompanyListDirectProps extends ICompanyListProps {
  mode: 'direct';
  open: boolean;
  allowNoSelect?: boolean;
  dialogTitle?: string;
  onCancel: () => void;
}

export interface ICompanyListInlineProps extends ICompanyListProps {
  mode: 'inline';
}

interface ICompanyListState {
  gridParams: IAutoGridProps;
  companyAliases?: ICompanyAlias;
  search?: AutoSearchTypes;
  allCompaniesLoading: boolean;
  open: boolean;
}

const CompanyList = (props: ICompanyListButtonProps | ICompanyListDirectProps | ICompanyListInlineProps | ICompanyListButtonSelectProps) => {
  const sxClasses = generateSxClasses(useTheme());
  const { profile } = props;

  const defaultCompanyListState: ICompanyListState = {
    allCompaniesLoading: false,
    gridParams: {
      exportTitle: '',
      baseTable: '',
      displayColumns: [],
      rowData: {},
      rowDataLastUpdate: moment().valueOf(),
      rowDataLoading: true,
      quickTextFilter: props.quickFilter,
      keyField: undefined,
      displayField: undefined
    },
    open: false
  };

  const [listState, setListState] = React.useState(defaultCompanyListState);

  React.useEffect(() => {
    setListState((old) => ({
      ...old,
      gridParams: {
        ...old.gridParams,
        quickTextFilter: old.gridParams.quickTextFilter != null ? old.gridParams.quickTextFilter : props.quickFilter == null || props.quickFilter.length === 0 ? undefined : props.quickFilter
      }
    }));
  }, [props.quickFilter]);

  const loadCompanyCache = () => {
    if (!listState.allCompaniesLoading) {
      setListState((old) => ({
        ...old,
        allCompaniesLoading: true
      }));

      loadCompaniesCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk).then((res) => {
        setListState((old) => ({
          ...old,
          gridParams: {
            ...res.autoGridProps,
            quickTextFilter: old.gridParams.quickTextFilter
          },
          companyAliases: res.aliases,
          allCompaniesLoading: false
        }));

        if (props.onCompaniesLoaded) props.onCompaniesLoaded(res.aliases, res.autoGridProps.rowData);
      });
    }
  };

  React.useEffect(() => {
    loadCompanyCache();
  }, []);

  // const [quickSearch, setQuickSearch] = React.useState('');
  const setQuickSearch = (quickSearch: string) => {
    setListState((old) => ({
      ...old, gridParams: {
        ...old.gridParams,
        quickTextFilter: quickSearch.length === 0 ? undefined : quickSearch
      }
    }));

    if (props.onQuickFilter) props.onQuickFilter(quickSearch);
  };

  const handleOpen = () => setListState((old) => ({
    ...old,
    open: true
  }));

  const handleClose = () => {
    setListState((old) => ({ ...old, open: false }));

    if ((props.mode === 'button' && props.onCancel) || props.mode === 'direct') props.onCancel!();
  };

  const filteredCompanies = () => profile.companies.filter((c) => c.isEditor);

  const handleGridClick = (companyId: number, name: string) => {
    props.onCompanySelected(companyId, name, profile.companies.find((x) => x.companyId === companyId)?.userCompanyId ?? profile.companySpoofIds?.[companyId]?.[0]);

    setListState((old) => ({
      ...old,
      open: false
    }));
  };

  const handleSelect = (companyId?: number) => {
    if (props.mode === 'button-select') {
      const company = profile.companies.find((x) => x.companyId === companyId);

      if (company == null) {
        if (props.onCancel != null) props.onCancel();
      } else {
        props.onCompanySelected(company.companyId, company.name, company.userCompanyId);
      }

      setListState((old) => ({
        ...old,
        open: false
      }));
    }
  };

  const controlGridSizes = quickGrid(12, 6, 4, 4, 4);
  const displayGrid = () => (
    <Grid sx={{ ...sxClasses.gridContainer }} container spacing={3}>
      <Grid item {...controlGridSizes}>
        <TextField
          id="quick-search"
          label="Filter by Name"
          margin="dense"
          fullWidth
          value={listState.gridParams.quickTextFilter ?? ''}
          onChange={(event) => setQuickSearch(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Escape') setQuickSearch('');
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <Icon aria-label="search">
                <Search />
              </Icon>
            </InputAdornment>
          }}
        />
      </Grid>
      {profile.isTrainingFacility && profile.isAdmin ? (
        <Grid item {...controlGridSizes}>
          <Button fullWidth variant="contained" size="small" onClick={props.onAddNew}>
            Add Company
          </Button>
        </Grid>
      ) : undefined}
      {props.mode !== 'inline' && props.allowNoSelect ? (
        <Grid item {...controlGridSizes}>
          <Button fullWidth variant="contained" size="small" onClick={() => props.onCompanySelected(undefined, '', undefined)}>
            Select No Company
          </Button>
        </Grid>
      ) : undefined}
      <Grid item xs={12}>
        <AutoGrid onRowClick={(e: AutoGridRowClickProps) => handleGridClick(parseInt(e.id, 10), e.name)} maxHeight={props.maxHeight ?? 'calc(100vh - 300px)'} {...listState.gridParams} />
      </Grid>
    </Grid>
  );

  if (props.mode === 'button-select' && props.selectUnderLength != null && profile.userId > 0 && !profile.isTrainingFacility && filteredCompanies().length < props.selectUnderLength) {
    return (
      <FormControl fullWidth={props.fullWidth}>
        <InputLabel id="company-list-label">{props.selectTitle}</InputLabel>
        <Select label={props.selectTitle} labelId="company-list-label" id="company-list" value={props.companyId ?? ''} disabled={props.disabled || (!profile.isTrainingFacility && filteredCompanies().length === 0)} onChange={(event) => handleSelect(event.target.value as number)}>
          {profile.userId < 0 ? (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          ) : (
            filteredCompanies()
              .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
              .map((x) => (
                <MenuItem key={x.companyId ?? -1} value={x.companyId}>
                  {x.name ?? <em>None</em>}
                </MenuItem>
              ))
          )}
        </Select>
      </FormControl>
    );
  }

  return (
    <>
      {props.mode !== 'button' && props.mode !== 'button-select' ? undefined : (
        <Button fullWidth={props.fullWidth} variant="contained" size="small" disabled={props.disabled} onClick={handleOpen}>
          {props.buttonTitle}
        </Button>
      )}
      {props.mode === 'inline' ? (
        displayGrid()
      ) : (
        <Dialog open={listState.open || (props.mode === 'direct' && props.open)} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogTitle>{props.dialogTitle ?? 'Select Company'}</DialogTitle>
          <DialogContent>{displayGrid()}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default compose(withStyles(createStyles({}), { withTheme: true }), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(CompanyList);

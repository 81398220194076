import React from 'react';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import TextEditor from './editors/TextEditor';
import { IDisplayContext } from './AutoEditor';
import NumberEditor from './editors/NumberEditor';
import SelectEditor from './editors/SelectEditor';
import WorkerPhoto from '../identity/WorkerPhoto';
import ExpiryEditor from './editors/ExpiryEditor';
import BooleanEditor from './editors/BooleanEditor';
import CurrencyEditor from './editors/CurrencyEditor';
import DateTextEditor from './editors/DateTextEditor';
import ReadonlyEditor from './editors/ReadonlyEditor';
import MarkdownEditor from './editors/MarkdownEditor';
import { createStyles, withStyles } from '@mui/styles';
import { Grid, Tooltip, GridSize } from '@mui/material';
import { IProfileState } from 'src/stores/profile/types';
import UnionSelectEditor from './editors/UnionSelectEditor';
import SelectMultiEditor from './editors/SelectMultiEditor';
import SelectCourseEditor from './editors/SelectCourseEditor';
import WorkerSelectEditor from './editors/WorkerSelectEditor';
import SelectCompanyEditor from './editors/SelectCompanyEditor';
import ReserveNumberEditor from './editors/ReserveNumberEditor';
import { mapProfileDbFromAppState } from 'src/stores/database/types';
import { IAutoEditorImplementation, IAutoEditItem } from './autoInterfaces';
import FitTestScheduleEditor from './editors/fitTest/FitTestScheduleEditor';
import { IColumn, usedAlternateEditor } from 'src/stores/database/interfaces';
import WaitlistCourseSelectEditor from './editors/WaitlistCourseSelectEditor';

const styles = () => createStyles({});

interface IAutoEditorGridSizes {
  xs: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

export interface IAutoEditorFieldProps extends IAutoEditorImplementation {
  classes: any;
  profile: IProfileState;
  readonly?: boolean;
  doubleWidth?: boolean;
  displayContext?: Dictionary<IDisplayContext>;
  company?: number;
  sizes?: IAutoEditorGridSizes;
}

export type AutoEditorRowFunction<T = string> = (item: IAutoEditItem, field: IColumn) => T;

const AutoEditorField = (props: IAutoEditorFieldProps) => {
  const { classes, field, profile, displayContext, company } = props;

  const loadField = (): React.ReactNode => {
    if (!field.readonly && !props.readonly && usedAlternateEditor(field.properties) == null) {
      switch (field.columnType) {
        case 'BigInt':
        case 'Int':
        case 'SmallInt':
        case 'Decimal':
          return <NumberEditor {...props} />;
        case 'Money':
          return <CurrencyEditor {...props} />;
        case 'String':
          return <TextEditor {...props} />;
        case 'Lookup':
        case 'NestedLookup':
          return <SelectEditor {...props} />;
        case 'MultiLookup':
          return <SelectMultiEditor {...props} />;
        // return <NestedSelectEditor {...props} />;
        case 'Bool':
          return <BooleanEditor {...props} />;
        case 'Date':
        case 'Time':
        case 'DateTime':
          return <DateTextEditor {...props} type={field.columnType} />;
        case 'Markdown':
          return <MarkdownEditor {...props} />;
      }
    }

    switch (usedAlternateEditor(field.properties, field.readonly || props.readonly)) {
      case 'CompanySelect':
        return <SelectCompanyEditor {...props} />;
      case 'UserCompanySelect':
        return <SelectCompanyEditor {...props} isUserCompanyId />;
      case 'UnionSelect':
        return <UnionSelectEditor {...props} />;
      case 'ReserveNumber':
        return <ReserveNumberEditor {...props} />;
      case 'WorkerSelect':
        return <WorkerSelectEditor {...props} />;
      case 'WorkerPhoto':
        return <WorkerPhoto personId={props.editId ?? 0} photoTakenUTC={props.originalValue} diameter={250} />;
      case 'Expiry':
        return <ExpiryEditor {...props} />;
      case 'CourseSelect':
        return <SelectCourseEditor {...props} />;
      case 'FitTestSchedule':
        return <FitTestScheduleEditor {...props} />;
      case 'WaitlistCourse':
        return <WaitlistCourseSelectEditor {...props} />;
      case 'SecuredEmail':
        return props.editId != null && props.editValue != null && props.editValue.length > 0 && props.editValue[0] === '['
          ? <ReadonlyEditor {...props} />
          : <TextEditor {...props} />;
      default: return <ReadonlyEditor {...props} />;
    }
  };

  const sizes = (alternateEditor?: string): IAutoEditorGridSizes => {
    if (props.sizes) return props.sizes;

    switch (alternateEditor) {
      case 'FitTestSchedule':
        return { xs: 12 };
      case 'UnionSelect':
      case 'multiLookup':
      case 'MultiLookupInstructors':
        return props.doubleWidth ? { xs: 12 } : { xs: 12, sm: 6, md: 8, lg: 6 };
      default:
        if (props.field.columnType === 'Markdown') return { xs: 12 };

        return props.doubleWidth ? { xs: 12, xl: 6 } : { xs: 12, sm: 6, md: 4, lg: 3 };
    }
  };

  const hideAuto = (): boolean => {
    if (props.columnReferences.TimeslotData === props.field.columnId) {
      if (props.localState?.selectedCourse != null) {
        if (props.localState.selectedCourse.courseTypeKey === 'schedule-fittest' && props.columnReferences.TimeslotData === props.field.columnId) return false;
      }

      return true;
    }

    if (displayContext?.[field.name] != null) {
      if (!displayContext[field.name].display(profile, company == null ? undefined : profile.companies.find(c => c.companyId === company))) return true;
    }

    return false;
  };

  const createAuto = (): React.ReactNode | null => {
    if (field.viewSecurable && !profile.isTrainingFacility) return null;

    if (hideAuto()) return null;

    if (usedAlternateEditor(field.properties) == null && field.columnType === 'DateTime') {
      return (field.description?.length || 0) === 0 ? (
        <>
          <Grid key={`${field.columnId}.1`} className={classes.root} item {...sizes()}></Grid>
          <Grid key={`${field.columnId}.2`} className={classes.root} item {...sizes()}></Grid>
        </>
      ) : (
        <>
          <Tooltip title={field.description}>
            <Grid key={`${field.columnId}.1`} className={classes.root} item {...sizes()}></Grid>
          </Tooltip>
          <Tooltip title={field.description}>
            <Grid key={`${field.columnId}.2`} className={classes.root} item {...sizes()}></Grid>
          </Tooltip>
        </>
      );
    }

    return (field.description?.length || 0) === 0 ? (
      <Grid key={field.columnId} className={classes.root} item {...sizes(usedAlternateEditor(field.properties, field.readonly || props.readonly))}>
        {loadField()}
      </Grid>
    ) : (
      <Tooltip key={`tooltip_${field.columnId}`} title={field.description}>
        <Grid key={field.columnId} className={classes.root} item {...sizes(usedAlternateEditor(field.properties, field.readonly || props.readonly))}>
          {loadField()}
        </Grid>
      </Tooltip>
    );
  };

  return createAuto();
};

export default compose(withStyles(styles), connect(mapProfileDbFromAppState))(AutoEditorField);

import { Dictionary } from 'lodash';
import signalR from '@microsoft/signalr';
import { ICourse } from '../../database/training/courses';
import { AutoRow } from '../../../components/auto/AutoLoad';
import { IAutoGridProps } from '../../../components/auto/AutoGrid';
import { IColumnMap, ISelectColumnMap, IFilterDefinition } from '../../database/interfaces';

export const enum CheckinActionTypes {
  __ENUM_ROOT = '@@Checkin',
  HUB_CONNECT = '@@Checkin/HUB_CONNECT',
  HUB_CONNECT_START = '@@Checkin/HUB_CONNECT_START',
  HUB_CLEAR = '@@Checkin/HUB_CLEAR',

  // CHECKIN_CONNECT_START = '@@Checkin/CHECKIN_CONNECT_START',
  CHECKIN_CONNECT_END = '@@Checkin/CHECKIN_CONNECT_END',

  CHECKIN_COURSE = '@@Checkin/CHECKIN_COURSE',
  CHECKIN_COURSE_DONE = '@@Checkin/CHECKIN_COURSE_DONE',

  CHECKIN_TOGGLEWHMIS = '@@Checkin/CHECKIN_TOGGLEWHMIS',
  CHECKIN_TOGGLEWHMIS_DONE = '@@Checkin/CHECKIN_TOGGLEWHMIS_DONE',

  CHECKIN_TOGGLEVERIFIED = '@@Checkin/CHECKIN_TOGGLEVERIFIED',
  CHECKIN_TOGGLEVERIFIED_DONE = '@@Checkin/CHECKIN_TOGGLEVERIFIED_DONE',

  // CHECKIN_START = '@@Checkin/CHECKIN_START',
  CHECKIN_START_DONE = '@@Checkin/CHECKIN_START_DONE',

  LOAD_CONFIG = '@@Checkin/LOAD_CONFIG',
  LOAD_FILTER = '@@Checkin/LOAD_FILTER',
  LOAD_DATA = '@@Checkin/LOAD_DATA',

  MERGE_QUICK_ADD_WORKER_SCHEDULES = '@@Checkin/MERGE_QUICK_ADD_WORKER_SCHEDULES',
  LOAD_QUICK_ADD_WORKER_SCHEDULES = '@@Checkin/LOAD_QUICK_ADD_WORKER_SCHEDULES'
}

export const enum CheckinColumnPaths {
  personId = 'person.worker.person.iecNumber',
  workerScheduleId = 'workerScheduleId',
  courseScheduleId = 'courseSchedule',
  courseId = 'courseSchedule.course',
  scheduleTypeKey = 'courseSchedule.course.courseCategory.courseType.scheduleType.key',
  scheduleDate = 'courseSchedule.trainingDate',
  scheduleCourseCode = 'courseSchedule.course.code',
  scheduleCourseTitle = 'courseSchedule.course.title',
  // -TODO- Investigate if this is the correct (or only) `displayPrice` column
  price = 'displayPrice',
  scheduleStatus = 'workerScheduleStatus.statusKey',
  scheduleTimeslot = 'timeslotData',
  trainingTime = 'scheduledTime',
  scheduleSelfpay = 'selfPay',
  hasWhmis = 'person.worker.person.hasWHMIS',
  identityVerified = 'person.worker.person.identityVerified',
  locationName = 'courseSchedule.room.locationRoom',
  companyId = 'userCompany.company',
  company = 'userCompany.company.name',
  personDisplayName = 'person.worker.displayName',
  personPictureTakenUTC = 'person.worker.person.pictureTakenUTC',
  checkinDate = 'checkinUTC',
  recordsUpload = 'courseSchedule.recordsUpload',
  prereqStatus = 'webPrerequisiteStatus',
  _generatedDisplay = '___DISPLAY'
}

export interface IFilterConfig {
  dateField: IColumnMap;
  uploadField: IColumnMap;
  scheduleTypeField: IColumnMap;
  workerScheduleField: IColumnMap;
  additionalColumns: ISelectColumnMap[];
}

export type CheckinGridProps = Omit<IAutoGridProps, 'rowData' | 'rowDataLoading' | 'rowDataLastUpdate'>;

export type PageType = 'checkin' | 'fittest';

export interface ICourseScheduleConnect {
  id: number;
  name: string;
  scheduleType: string;
}

export interface ICheckinState {
  courses: Dictionary<ICourse>;

  hubConnection?: signalR.HubConnection;
  hubConnectionLoading: boolean;

  connectedCourseSchedules: ICourseScheduleConnect[];

  checkinGridParams: CheckinGridProps;
  checkinRowData: Dictionary<AutoRow>;
  checkinCourses: Dictionary<ICourse>;
  checkinRowDataLastUpdate: number;
  checkinRowDataLoading: boolean;
  checkinFilterProps?: IFilterConfig;
  checkinFilter?: IFilterDefinition;

  fittestGridParams: CheckinGridProps;
  fittestRowData: Dictionary<AutoRow>;
  fittestCourses: Dictionary<ICourse>;
  fittestRowDataLastUpdate: number;
  fittestRowDataLoading: boolean;
  fittestFilterProps?: IFilterConfig;
  fittestFilter?: IFilterDefinition;

  version: number;
}

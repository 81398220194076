export const enum ProfileActionTypes {
  __ENUM_ROOT = '@@Profile',
  LOAD_ME = '@@Profile/LOAD_ME',
  LOADED = '@@Profile/LOADED'
}

export interface ICompany {
  userCompanyId: number;
  companyId: number;
  name: string;
  role: string;
  featureAccess: string[];
  reserveLimit?: number;
  isTrainingFacility: boolean;
  isPartner: boolean;
  isUnion: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  // isContactManager: boolean;
  // isEquipmentManager: boolean;
  canInvoice: boolean;
}

type PricingTypes = 'pricePartner' | 'priceNonPartner';
type SubtotalTypes = 'subtotalPartner' | 'subtotalNonPartner';

export interface IProfileState {
  userId: number;
  displayName: string;
  exclusions: string[];
  personId?: number;
  isTrainingFacility: boolean;
  isAdmin: boolean;
  isPartner: boolean;
  isUnion: boolean;
  isEditor: boolean;
  // isContactManager: boolean;
  // isEquipmentManager: boolean;
  userNoCompanyId: number;
  active: boolean;
  companies: ICompany[];
  companySpoofIds?: { [companyId: string]: number[] };
  pricingField: PricingTypes;
  subtotalField: SubtotalTypes;
}

import moment from 'moment';
import { Dictionary } from 'lodash';
import axios, { AxiosPromise } from 'axios';
import settings from '../../../abstracts/settings';
import { createAlertBulk } from '../../../stores/ui/actions';
import { autoLoadMulti } from '../../../components/auto/AutoLoad';
import { refreshCacheData, fetchConfigRequest } from '../actions';
import { isEqualOrInArray, objectEach, objectMapArray } from '../../../abstracts/DataroweHelpers';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

export type CourseGroupType = 'Course' | 'Standard' | 'DynamicStandard';
export const courseGroupTypeStrings: CourseGroupType[] = ['Course', 'Standard', 'DynamicStandard'];

export type IExpiryNoRenew = 'NoRenew';
export type IExpiryFixed = 'Fixed';
export type IExpiryRelative = 'Relative';
export type IExpiryTypes = IExpiryNoRenew | IExpiryFixed | IExpiryRelative;
export type IExpiryPeriods = 'Years' | 'Months' | 'Weeks' | 'Days';

export interface ICourseExpiryNoRenew {
  type: IExpiryNoRenew;
}

export interface ICourseExpiryFixed {
  type: IExpiryFixed;
  date: string;
}

export interface ICourseExpiryRelative {
  type: IExpiryRelative;
  period: IExpiryPeriods;
  frequency: number;
}

export type ICourseExpiry = (ICourseExpiryNoRenew | ICourseExpiryFixed | ICourseExpiryRelative) & { sortValue?: number };

const generateSortValue = (exp: ICourseExpiry): ICourseExpiry => {
  if (exp == null) {
    return {
      type: 'NoRenew',
      sortValue: 999999
    };
  }

  switch (exp.type) {
    case 'Fixed':
      exp.sortValue = +moment(exp.date).format('YYMMDD');
      break;
    case 'NoRenew':
      exp.sortValue = 999999;
      break;
    case 'Relative':
      switch (exp.period) {
        case 'Days':
          exp.sortValue = exp.frequency;
          break;
        case 'Weeks':
          exp.sortValue = exp.frequency * 7;
          break;
        case 'Months':
          exp.sortValue = exp.frequency * 30;
          break;
        case 'Years':
          exp.sortValue = exp.frequency * 365;
      }
      break;
  }

  return exp;
};

export interface ICourse {
  courseId: number;
  code: string;
  title: string;
  description: string;
  category: string;
  duration: number;
  active: boolean;
  online: boolean;
  allowWebAssign: boolean;
  scheduleOnly: boolean;
  pricePartner: number;
  priceNonPartner: number;
  expiry?: ICourseExpiry;
  courseTypeKey: string;
  scheduleTypeKey: string;
  prereqTitle?: string;
  prereqDescription?: string;
  isWaitlistable: boolean;
}

export interface IStandard {
  standardId: number;
  title: string;
  description: string;
  courses: ICourse[];
  activeCourses: number;
  active: boolean;
}

export interface IDynamicStandard {
  dynamicStandardKey: string;
  title: string;
  description: string;
  courses: ICourse[];
  standards: IStandard[];
}

export interface ICourseCategory {
  courseCategoryId: number;
  name: string;
  description: string;
  childItems: number;
  activeChildItems: number;
  childCategories?: ICourseCategory[];
  courses?: ICourse[];
  standards?: IStandard[];
  dynamicStandards?: IDynamicStandard[];
}

export interface ICourseGroupResult {
  type: CourseGroupType;
  training: ICourse | IStandard | IDynamicStandard;
}

export interface ICourseCache {
  courses: ICourse[];
  standards: IStandard[];
}

export interface IFitTest {
  courseId: number;
  alternateCourseIds?: number[];
}

export interface IFitTestConfig {
  scheduleType: 'rft-apr' | 'rft-n95' | 'rft-sctt' | 'rft-drgr';
  courseIds: number[];
  isSelfContained?: boolean;
  isSuppliedAir?: boolean;
  scheduledTime?: string;
}

export interface IFitTestSchedule extends Omit<IFitTestConfig, 'scheduledTime'> {
  scheduledTime: string;
}

export const formatTrainingTime = (d: string | moment.Moment, t?: string, ft?: IFitTestSchedule[], format?: string): string => (t == null && (ft == null || ft.length === 0))
  ? moment(d).format(format ?? settings.dateWeekdayTimeFormatMoment)
  : moment(`${moment(d).format(settings.apiDateFormatMoment)} ${ft?.[0]?.scheduledTime ?? t}`).format(format ?? settings.dateWeekdayTimeFormatMoment);

export const formatFitTestSchedule = (s: IFitTestSchedule, courses: Dictionary<ICourse>) => `${moment(`2000-01-01 ${s.scheduledTime}`).format(settings.timeFormatMoment)}: ${s.courseIds?.map(c => courses[c]?.code).join(', ') ?? 'missing course details'}${s.scheduleType === 'rft-apr' ? '' : `${s.isSelfContained ? ' SCBA' : ''}${s.isSuppliedAir ? ' SABA' : ''}`}`;
export const formatFitTestScheduleTitle = (s: IFitTestSchedule, courses: Dictionary<ICourse>) => `${moment(`2000-01-01 ${s.scheduledTime}`).format(settings.timeFormatMoment)}: ${s.courseIds?.map(c => courses[c]?.title).join(', ') ?? 'missing course details'}${s.scheduleType === 'rft-apr' ? '' : `${s.isSelfContained ? ' SCBA' : ''}${s.isSuppliedAir ? ' SABA' : ''}`}`;

export interface IFitTestScheduleCheck {
  scheduledTime: string;
  slots: IFitTestConfig[];
}

export interface ICourseFilters {
  courseTypeKeys?: string | string[];
  scheduleTypeKeys?: string | string[];
  includeOnlyCourseIds?: number[];
  excludeCourseIds?: number[];
  onlineCourses?: boolean;
  scheduleOnlyCourses?: boolean;
}

export const isAllowedCourse = (course: ICourse, filter: ICourseFilters): boolean => {
  if (filter.courseTypeKeys != null && !isEqualOrInArray(filter.courseTypeKeys, course.courseTypeKey)) return false;

  if (filter.scheduleTypeKeys != null && !isEqualOrInArray(filter.scheduleTypeKeys, course.scheduleTypeKey)) return false;

  if (filter.includeOnlyCourseIds != null && filter.includeOnlyCourseIds.indexOf(course.courseId) < 0) return false;

  if (filter.excludeCourseIds != null && filter.excludeCourseIds.indexOf(course.courseId) >= 0) return false;

  if (filter.onlineCourses != null && course.online !== filter.onlineCourses) return false;

  if (filter.scheduleOnlyCourses != null && course.scheduleOnly !== filter.scheduleOnlyCourses) return false;

  return true;
};

export const loadCourseCategories = (): AxiosPromise<ICourseCategory[]> => {
  console.log('called loadCourses');

  const api = `${API_ENDPOINT}/Training/Courses`;

  console.log('loadCourses', { api });

  return axios.get(api);
};

export interface ICourseCacheLoad {
  courseCache: ICourseCache;
  courseRows: Dictionary<ICourse>;
}

export const loadCourseCache = (refresh: typeof refreshCacheData, fetch: typeof fetchConfigRequest, alert: typeof createAlertBulk, forceRefresh?: boolean): Promise<ICourseCacheLoad> => {
  return new Promise(outerResolve => {
    fetch(undefined, {
      key: 'loadCourseCache',
      action: db => {
        db.getCacheData(
          refresh,
          'courses',
          [
            {
              from: 'access',
              unit: 'minutes',
              amount: 10
            },
            {
              from: 'load',
              unit: 'minutes',
              amount: 60
            }
          ],
          () => new Promise(resolve => {
            const coursesTable = 'training.course';
            const coursesTableId = db.getTableByCombinedName(coursesTable).tableId;
            const courseCategory = 'courseCategory.displayName';
            const coursesKey = 'courseId';
            const courseTypeKey = 'courseCategory.courseType.key';
            const scheduleTypeKey = 'courseCategory.courseType.scheduleType.key';
            const coursesColumns = [
              'code',
              'title',
              'description',
              'duration',
              'expiry',
              'pricePartner',
              'priceNonPartner',
              'active',
              'online',
              'allowWebAssign',
              'scheduleOnly',
              courseCategory,
              courseTypeKey,
              scheduleTypeKey,
              'prerequisiteTitle',
              'prerequisiteDescription',
              'isWaitlistable'
            ];

            const standardsTable = 'training.standardCourse';
            const standardTableId = db.getTableByCombinedName(standardsTable).tableId;
            const standardsColumns = [
              'course',
              'standard.standardId',
              // 'standard.code',
              'standard.title',
              'standard.description',
              'standard.active'
            ];

            autoLoadMulti({
              fetchConfigRequest: fetch,
              createAlertBulk: alert,
              multi: [
                {
                  baseTableId: coursesTableId,
                  keyField: db.mapSelectColumnsByNamesWithId(coursesTableId, coursesKey)[0],
                  displayField: () => '',
                  allFields: db.mapSelectColumnsByNamesWithId(coursesTableId, coursesColumns)
                },
                {
                  // load standards information to be used later
                  baseTableId: standardTableId,
                  keyField: db.mapSelectColumnsByNamesWithId(standardTableId, standardsColumns[0])[0],
                  displayField: () => '',
                  allFields: db.mapSelectColumnsByNamesWithId(standardTableId, standardsColumns.slice(1))
                }
              ]
            }).then(res => {
              const cacheData = res;
              const courses: ICourse[] = objectMapArray(
                cacheData[coursesTable],
                (_courseId, course: any): ICourse => ({
                  courseId: course[coursesKey],
                  code: course.code,
                  title: course.title,
                  description: course.description,
                  category: course[courseCategory],
                  duration: course.duration,
                  active: course.active,
                  online: course.online,
                  allowWebAssign: course.allowWebAssign,
                  scheduleOnly: course.scheduleOnly,
                  pricePartner: course.pricePartner,
                  priceNonPartner: course.priceNonPartner,
                  expiry: generateSortValue(JSON.parse(course.expiry)),
                  courseTypeKey: course[courseTypeKey],
                  scheduleTypeKey: course[scheduleTypeKey],
                  prereqTitle: course.prerequisiteTitle,
                  prereqDescription: course.prerequisiteDescription,
                  isWaitlistable: course.isWaitlistable
                })
              );

              const standards: IStandard[] = [];
              objectEach(cacheData[standardsTable], (sId, s: any) => {
                const standardId = s['standard.standardId'];
                const idx = standards.findIndex(i => i.standardId === standardId);
                const c = courses.find((crs: ICourse) => crs.courseId === s.course);
                if (c == null) return;
                if (idx >= 0) {
                  standards[idx].courses.push(c);
                  if (c.active) standards[idx].activeCourses++;
                } else {
                  standards.push({
                    standardId,
                    // code: s[standardsColumns[2]],
                    title: s[standardsColumns[2]],
                    description: s[standardsColumns[3]],
                    active: s[standardsColumns[4]],
                    courses: [c],
                    activeCourses: c.active ? 1 : 0
                  });
                }
              });

              resolve({
                courseCache: {
                  courses,
                  standards
                },
                courseRows: cacheData[coursesTable]
              });
            });
          }),
          forceRefresh
        ).then(outerResolve);
      }
    });
  });
};

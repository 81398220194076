import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from 'src/stores';
import CheckinPage from './CheckinPage';
import FitTestPage from './FitTestPage';
import * as signalR from '@microsoft/signalr';
import { createStyles, withStyles } from '@mui/styles';
import { useInterval } from '../../../abstracts/DataroweHelpers';
import { connectHub } from 'src/stores/training/checkin/reducer';
import { mapCheckinActions } from 'src/components/training/checkin/CheckinList';
import { ICheckinState, PageType } from '../../../stores/training/checkin/types';
import { hubClear, ICheckinHub, persistConnection } from 'src/stores/training/checkin/actions';

const styles = () => createStyles({});

interface IConnectedProps {
  checkin: ICheckinState;
  classes: any;
  hubClear: typeof hubClear;
  persistConnection: typeof persistConnection;
  pageType: PageType;
}

interface ILocalState {
  connectionRefresh?: number;
  lastLoaded?: number;
}

const DailyListsPage = (props: IConnectedProps & ICheckinHub) => {
  const { checkin, pageType, ...hubItems } = props;

  // eslint-disable-next-line no-undef
  const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  React.useEffect(() => {
    return () => {
      if (checkin.hubConnection != null && checkin.hubConnection.state === signalR.HubConnectionState.Connected) {
        checkin.hubConnection.stop().then(() => props.hubClear());
      }
    };
  }, []);

  const [state, setState] = React.useState<ILocalState>({});

  const createHubConnection = async () => {
    const hub = await connectHub(hubItems);
    props.persistConnection(hub);

    setState(old => ({
      ...old,
      connectionRefresh: 5000
    }));
  };

  React.useEffect(() => {
    createHubConnection();

    return () => {
      if (checkin.hubConnection != null) {
        checkin.hubConnection.stop();
      }

      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useInterval(() => {
    if (checkin.hubConnection?.state !== signalR.HubConnectionState.Connected) {
      setState(old => ({
        ...old,
        connectionRefresh: undefined
      }));

      createHubConnection();
    }
  }, state.connectionRefresh);

  if (pageType === 'checkin') {
    return <CheckinPage/>;
  }

  return <FitTestPage/>;
};

const mapFromAppState = ({ checkin, profile, oidc }: IAppState) => ({
  checkin,
  profile,
  user: oidc.user
});

export default compose(withStyles(styles, { withTheme: true }), connect(mapFromAppState, mapCheckinActions))(DailyListsPage);

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { Search } from '@mui/icons-material';
import settings from '../../abstracts/settings';
import { LocalFilters } from '../auto/AutoLocalFilter';
import { createStyles, withStyles } from '@mui/styles';
import { ICustomDisplayDefinition } from '../auto/AutoLoad';
import { ISetSearchConfig } from '../../stores/database/interfaces';
import { mapProfileDbFromAppState } from '../../stores/database/types';
import { objectMapArray, nameof, arrayMapObject, objectMap, quickGrid } from '../../abstracts/DataroweHelpers';
import { ICourse, ICourseFilters, isAllowedCourse, loadCourseCache } from '../../stores/database/training/courses';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, AutoGridSelectAllEvent, AutoGridRowClickEvent, getBoolCheckDisplay, IConnectedProps } from '../auto/AutoGrid';
import { Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Theme, DialogActions, TextField, IconButton, Checkbox, InputAdornment, useTheme } from '@mui/material';

const generateSxClasses = (_theme: Theme) => {
  return {
    gridContainer: {
      alignItems: 'center'
    }
  };
};

export interface ICoursesSearchProps extends ISetSearchConfig, ICourseFilters {
  classes: any;
  title: string;
  mode: 'button' | 'inline' | 'direct';
  open?: boolean;
  limitSelectCount?: number;
  showDisabled?: boolean;
  fullWidth?: boolean;
  quickFilter?: string;
  includeInactive?: 1;
}

interface ICourseSearchActions {
  onCoursesSelected: (courses: ICourse[]) => void;
  onCoursesLoaded: (courses: ICourse[]) => void;
  onCancel?: () => void;
  onAddNew?: () => void;
  onQuickFilter?: (filter: string) => void;
  onToggleInactive?: (inactive?: 1) => void;
}

const CourseSearchGrid = (props: ICoursesSearchProps & ICourseSearchActions & IConnectedProps) => {
  const sxClasses = generateSxClasses(useTheme());

  const courseDefaultColumns: ICustomDisplayDefinition<ICourse>[] = [
    {
      columnAlias: nameof<ICourse>('category'),
      columnTitle: 'Course Category'
    },
    {
      columnAlias: nameof<ICourse>('code'),
      columnTitle: 'Course Code'
    },
    {
      columnAlias: nameof<ICourse>('title'),
      columnTitle: 'Title'
    },
    {
      columnAlias: nameof<ICourse>('description'),
      columnTitle: 'Description'
    },
    {
      columnAlias: nameof<ICourse>('duration'),
      columnTitle: 'Duration',
      display: (d) => (d.duration == null ? '' : `${d.duration} hour${d.duration === 1 ? '' : 's'}`),
      sort: (a, b) => (a.item.duration ?? 0) - (b.item.duration ?? 0)
    },
    {
      columnAlias: nameof<ICourse>('expiry'),
      columnTitle: 'Expiry',
      display: ({ expiry }: ICourse) => {
        if (expiry == null) return '';
        switch (expiry.type) {
          case 'Fixed':
            return moment(expiry.date).format(settings.dateFormatMoment);
          case 'NoRenew':
            return 'Does not Expire';
          case 'Relative':
            return `${expiry.frequency} ${expiry.frequency === 1 ? expiry.period.slice(0, -1) : expiry.period}`;
        }
      },
      sort: (a: ICourse, b: ICourse) => {
        return (a.expiry?.sortValue ?? 0) - (b.expiry?.sortValue ?? 0);
      }
    },
    {
      columnAlias: nameof<ICourse>('active'),
      columnTitle: 'Active',
      display: ({ active }: ICourse) => getBoolCheckDisplay(active)
    },
    {
      columnAlias: nameof<ICourse>('pricePartner'),
      columnTitle: 'Price (Partner)',
      display: ({ pricePartner }: ICourse) => {
        if (pricePartner == null || typeof pricePartner === 'undefined') return '';

        return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(pricePartner);
      }
    },
    {
      columnAlias: nameof<ICourse>('priceNonPartner'),
      columnTitle: 'Price (Non-Partner)',
      display: ({ priceNonPartner }: ICourse) => {
        if (priceNonPartner == null || typeof priceNonPartner === 'undefined') return '';

        return new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(priceNonPartner);
      }
    }
  ];

  const defaultGridParams: IAutoGridProps<ICourse> & { courseCache: Dictionary<ICourse> } = {
    exportTitle: 'Course Search',
    baseTable: 'coursesTable',
    displayColumns: courseDefaultColumns,
    keyField: undefined,
    displayField: (rowData: ICourse) => {
      return `${rowData.title} (${rowData.code})`;
    },
    rowData: {},
    courseCache: {},
    columnFilter: {
      active: {
        type: 'boolFilter',
        firstParameter: true
      }
    },
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    selectedRows: props.limitSelectCount ?? 1 === 1 ? undefined : {}
  };

  const [gridState, setGridState] = React.useState(defaultGridParams);

  const [dialogState, setDialogState] = React.useState({
    text: props.quickFilter ?? '',
    showDeleted: false,
    open: false
  });

  React.useEffect(() => {
    setGridState((old) => ({
      ...old,
      quickTextFilter: props.quickFilter == null || props.quickFilter.length === 0 ? undefined : props.quickFilter
    }));
  }, [props.quickFilter]);

  React.useEffect(() => {
    const showDeleted = props.includeInactive === 1;

    const newFilter: { [name: string]: LocalFilters } = showDeleted ? {} : {
      active: {
        type: 'boolFilter',
        firstParameter: true
      }
    };

    setDialogState((old) => ({
      ...old,
      showDeleted
    }));

    setGridState((old) => ({
      ...old,
      columnFilter: newFilter
    }));
  }, [props.includeInactive]);

  const loadCoursesCache = () => {
    if (!gridState.rowDataLoading && Object.keys(gridState.courseCache).length === 0) {
      setGridState((old) => ({ ...old, rowDataLoading: true }));
      loadCourseCache(props.refreshCacheData, props.fetchConfigRequest, props.createAlertBulk).then((res) => {
        if (props.onCoursesLoaded) props.onCoursesLoaded(res.courseCache.courses);

        const rowData = arrayMapObject(res.courseCache.courses, (k, v) => (isAllowedCourse(v, props) ? [v.courseId.toString(), v] : undefined));

        setGridState((old) => ({
          ...old,
          rowData,
          rowDataLastUpdate: moment().valueOf(),
          rowDataLoading: false
        }));
      });
    } else if (!gridState.rowDataLoading) {
      const rowData = objectMap(gridState.courseCache, (k, v) => ((props.showDisabled || v.active) && isAllowedCourse(v, props) ? v : undefined));

      setGridState((old) => ({
        ...old,
        rowData,
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    }
  };

  React.useEffect(() => {
    loadCoursesCache();
    return () => {};
  }, []);

  const handleSelectRow: AutoGridRowClickEvent = (e) => {
    if ((props.limitSelectCount ?? 1) > 1) {
      setGridState((old) => ({
        ...old,
        selectedRows: e.updatedSelectedRows
      }));
    } else {
      props.onCoursesSelected(objectMapArray(e.updatedSelectedRows!, (_id, obj: { display: string; rowData: any }): ICourse => gridState.rowData[obj.rowData.courseId]));

      setDialogState((old) => ({
        ...old,
        open: false
      }));
    }
  };

  const handleSelectAll: AutoGridSelectAllEvent = (e) => {
    setGridState((old) => ({
      ...old,
      selectedRows: e.updatedSelectedRows
    }));
  };

  const handleSearchRun = (text?: string): void => {
    setGridState((old) => ({
      ...old,
      quickTextFilter: dialogState.text.length === 0 ? undefined : text ?? dialogState.text
    }));

    if (props.onQuickFilter) props.onQuickFilter(text ?? '');
  };

  const handleSearchText = (text: string) => {
    setDialogState((old) => ({
      ...old,
      text
    }));

    handleSearchRun(text);
  };

  const handleSearchDeleted = () => {
    const showDeleted = !dialogState.showDeleted;

    const newFilter: { [name: string]: LocalFilters } = showDeleted ? {} : {
      active: {
        type: 'boolFilter',
        firstParameter: true
      }
    };

    setDialogState((old) => ({
      ...old,
      showDeleted
    }));

    setGridState((old) => ({
      ...old,
      columnFilter: newFilter
    }));

    if (props.onToggleInactive) props.onToggleInactive(showDeleted ? 1 : undefined);
  };

  function handleOpen() {
    loadCoursesCache();

    setDialogState((old) => ({
      ...old,
      open: true
    }));
  }

  function handleClose() {
    setDialogState((old) => ({
      ...old,
      open: false
    }));

    if (props.onCancel !== undefined) props.onCancel();
  }

  function handleSubmit() {
    setDialogState((old) => ({
      ...old,
      open: false
    }));

    props.onCoursesSelected(objectMapArray(gridState.selectedRows!, (_id, obj: { display: string; rowData: any }): ICourse => gridState.rowData[obj.rowData.courseId]));
  }

  const controlGridSizes = quickGrid(12, 6, 4, 4, 4);
  const displayGrid = () => (
    <Grid sx={{ ...sxClasses.gridContainer }} container spacing={3}>
      <Grid item {...controlGridSizes}>
        <TextField
          fullWidth
          id="quick-search"
          label="Search by Name"
          margin="dense"
          defaultValue={dialogState.text}
          onChange={(event) => handleSearchText(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.keyCode === 13) handleSearchRun();
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton aria-label="search" onClick={() => handleSearchRun()}>
                <Search/>
              </IconButton>
            </InputAdornment>
          }}
      />
      </Grid>
      {props.mode === 'button' ? undefined : (
        <Grid item {...controlGridSizes}>
          <FormControlLabel control={<Checkbox checked={dialogState.showDeleted} onChange={handleSearchDeleted} value="showDeleted" color="primary"/>} label="Show Inactive Courses"/>
        </Grid>
      )}
      {props.profile.isTrainingFacility && props.profile.isAdmin && props.onAddNew ? (
        <Grid item {...controlGridSizes}>
          <Button fullWidth variant="contained" size="small" onClick={props.onAddNew}>
            Add Course
          </Button>
        </Grid>
      ) : undefined}
      <Grid item xs={12}>
        <AutoGrid onRowClick={handleSelectRow} onSelectAllClick={handleSelectAll} maxHeight={props.mode === 'inline' ? 'calc(100vh - 200px)' : 395} {...gridState} pagination/>
      </Grid>
    </Grid>
  );

  return props.mode === 'inline' ? (
    displayGrid()
  ) : (
    <>
      {props.mode === 'direct' ? undefined : (
        <Button fullWidth={props.fullWidth} variant="contained" size="small" onClick={handleOpen}>
          {props.title}
        </Button>
      )}
      <Dialog open={dialogState.open || (props.mode === 'direct' && !!props.open)} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>{displayGrid()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {props.limitSelectCount ?? 1 > 1 ? (
            <Button onClick={handleSubmit} color="primary" disabled={gridState.selectedRows === undefined || Object.keys(gridState.selectedRows).length === 0}>
              Add Selected Courses
            </Button>
          ) : undefined}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default compose(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(CourseSearchGrid);

// export type ApiResponse = Record<string, any>;
import { Database } from './class';
import { IAppState } from '..';
import { IColumnMap, IFilterDefinition, IOrderMap } from './interfaces';

export const enum DatabaseActionTypes {
  FETCH_CONFIG_REQUEST = '@@database/FETCH_CONFIG_REQUEST',
  FETCH_CONFIG_START = '@@database/FETCH_CONFIG_START',
  FETCH_CONFIG_ACTION = '@@database/FETCH_CONFIG_ACTION',
  FETCH_CONFIG_SUCCESS = '@@database/FETCH_CONFIG_SUCCESS',
  FETCH_CONFIG_ERROR = '@@database/FETCH_CONFIG_ERROR',

  CACHE_REFRESH_DATA = '@@database/CACHE_REFRESH_DATA'
  // FETCH_DATA_REQUEST = '@@database/FETCH_DATA_REQUEST',
  // FETCH_DATA_SUCCESS = '@@database/FETCH_DATA_SUCCESS',
  // FETCH_DATA_ERROR = '@@database/FETCH_DATA_ERROR'
}

export interface IDatabaseState {
  database: Database;
  loading: boolean;
  errors?: string;
  onLoadActions: {
    [key: string]: (() => void)[]
  };
  version: number;
}

export interface IDatabasePost {
  filter?: IFilterDefinition;
  columns?: IColumnMap[];
  orders?: IOrderMap[];
}

export interface IDatabaseCache {
  lastLoaded: number;
  lastAccessed: number;
  data: any;
}

export interface IQueryOptions {
  schema: string;
  table: string;
  offset: number;
  count: number;
}

export interface IDatabaseMultiPost extends Array<IDatabasePost & { query: IQueryOptions }> { }

export const mapProfileDbFromAppState = ({ db, ui, profile }: IAppState) => ({
  profile,
  ui,
  db: {
    ...db,
    database: new Database(db.database)
  }
});

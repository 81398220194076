import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import Paperbase from './layout/Paperbase';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter } from 'react-router-dom';
import UserManager from './abstracts/UserManager';
import { Elements } from '@stripe/react-stripe-js';
import { configureStore } from './abstracts/ConfigureStore';
import { StyledEngineProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';

// const history = createBrowserHistory();
const { store, persistor } = configureStore();
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`, process.env.REACT_APP_STRIPE_ACCOUNT == null ? undefined : { stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT });

export default function defaultApp() {
  return (
    <StyledEngineProvider injectFirst>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <OidcProvider store={store} userManager={UserManager}>
              <BrowserRouter>
                <Paperbase/>
              </BrowserRouter>
            </OidcProvider>
          </PersistGate>
        </Provider>
      </Elements>
    </StyledEngineProvider>
  );
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../../stores';
import { Grid, Paper, Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { IProfileState } from '../../stores/profile/types';
import WorkerView from '../../components/identity/WorkerView';
import LinkPersonId from '../../components/identity/LinkPersonId';
import AutoEditor from 'src/components/auto/AutoEditor';

const styles = (theme: Theme) => createStyles({
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '10px 16px'
  },
  paper: {
    margin: 'auto',
    overflow: 'hidden',
    marginBottom: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  }
});

interface ISettingsProps {
  classes: any;
  companyId: number;
}

interface IConnectedProps {
  profile: IProfileState;
}

const handleEditSave = () => {};
const handleEditCancel = () => {};

function settingsPage(props: ISettingsProps & IConnectedProps) {
  const { classes, profile } = props;

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.contentWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <AutoEditor
                baseTable="identity.user"
                editId={profile.userId}
                mode="inline"
                minHeight={300}
                onSave={handleEditSave}
                onCancel={handleEditCancel}
              />
            </Grid>
            {profile.personId == null ? (
              <Grid item xs={12} md={6} lg={3}>
                <LinkPersonId />
              </Grid>
            ) : undefined}
          </Grid>
        </div>
      </Paper>
      {profile.personId == null ? undefined : <WorkerView personId={profile.personId} />}
    </>
  );
}

function mapStateToProps(state: IAppState) {
  return {
    ui: state.ui,
    profile: state.profile
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(settingsPage);

import { createStyles, withStyles } from '@mui/styles';
import { IAutoEditorImplementation } from '../autoInterfaces';
import { FormControlLabel, Checkbox, FormControl, FormHelperText, Select, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';

const styles = () => createStyles({});

interface IBooleanEditorProps extends IAutoEditorImplementation<boolean | undefined> {
  classes: any;
}

const BooleanEditor = (props: IBooleanEditorProps) => {
  const { field, editValue, error, warning, onValueUpdated } = props;

  const selectedOption = () => editValue === true ? 'true' : (editValue === false ? 'false' : '');

  const handleFieldUpdate = (event: SelectChangeEvent) => {
    switch (event.target.value) {
      case 'true':
        onValueUpdated(true, field, '✓');
        break;
      case 'false':
        onValueUpdated(false, field, '');
        break;
      default:
        onValueUpdated(undefined, field, '');
        break;
    }
  };

  return field.required ? (
    <FormControlLabel control={<Checkbox disabled={props.disabled} checked={!!editValue} onChange={() => onValueUpdated(!editValue, field)} value={field.name} color="primary" />} label={field.title} />
  ) : (
    <FormControl error={!!error} fullWidth required={field.required}>
      <InputLabel id={`selectEditor_${field.columnId}_label`}>{field.title}</InputLabel>
      <Select label={field.title} labelId={`selectEditor_${field.columnId}_label`} id={`selectEditor_${field.columnId}`} value={selectedOption()} onChange={handleFieldUpdate} disabled={props.disabled}>
        <MenuItem value="">&nbsp;</MenuItem>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </Select>
      <FormHelperText>{error || warning || field.description}</FormHelperText>
    </FormControl>
  );
};

export default withStyles(styles)(BooleanEditor);

import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { IAppState } from 'src/stores';
import { Search } from '@mui/icons-material';
import settings from 'src/abstracts/settings';
import { ICompany } from 'src/stores/profile/types';
import { IWorkerLookup } from 'src/stores/ui/types';
import { queueLookup } from 'src/stores/ui/actions';
import { createStyles, withStyles } from '@mui/styles';
import LoadingScreen from 'src/components/common/LoadingScreen';
import WorkerCardDisplay, { ICardDisplay } from 'src/components/identity/WorkerCardDisplay';
import { Grid, IconButton, InputAdornment, List, ListItem, Paper, TextField, Theme, Typography } from '@mui/material';

const styles = (theme: Theme) => createStyles({
  paper: {
    width: '100%',
    overflow: 'hidden'
  },
  contentWrapper: {
    margin: `${theme.spacing(2)} ${theme.spacing(1.25)}`
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: `calc(100% - ${theme.spacing(3)})`
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px'
    }
  }
});

interface IQuickLookupProps {
  classes: any;
  workerLookups: IWorkerLookup[];
  workerLookupCache: Dictionary<ICardDisplay | string>;
  companies: ICompany;
  queueLookup: typeof queueLookup;
}

const QuickLookupPage = (props: IQuickLookupProps) => {
  const { classes } = props;

  const [quickSearch, setQuickSearch] = React.useState('');
  const [activeLookup, setActiveLookup] = React.useState(-1);

  const handleSearchRun = (search: string): void => {
    const portions = search.split('/');
    const iecNumber = parseInt(portions[portions.length - 1], 10);

    if (isNaN(iecNumber)) {
      console.log('Invalid number');
    } else {
      props.queueLookup(iecNumber);
      setActiveLookup(iecNumber);
      setQuickSearch('');
    }
  };

  const displayContent = () => {
    if (activeLookup < 0) return <Typography>Please enter an IEC number above</Typography>;

    if (props.workerLookupCache[activeLookup] == null) return <LoadingScreen />;

    if (typeof props.workerLookupCache[activeLookup] === 'string') return <Typography>{props.workerLookupCache[activeLookup]}</Typography>;

    return <WorkerCardDisplay
      card={props.workerLookupCache[activeLookup] as ICardDisplay}
      quickNotify={{
        title: 'Nova Chemicals',
        requirements: [
          {
            title: 'Basic Safety Orientation',
            keys: ['standard_1']
          },
          {
            title: 'Nova Site Specific',
            keys: ['course_306']
          },
          {
            title: 'Nova COTA22 Orientation',
            keys: ['course_1616', 'course_1618']
          }
        ]
      }}
    />;
  };

  const displayLabel = (l: IWorkerLookup) => {
    const item = props.workerLookupCache[l.personId];

    if (item == null) return <LoadingScreen/>;

    if (typeof item === 'string') return <Typography>{moment(l.timestamp).format(settings.apiDateTimeFormatMoment)}: {l.personId} - {item}</Typography>;

    return <Typography>{l.personId === activeLookup ? ' > ' : ''}{moment(l.timestamp).format(settings.apiDateTimeFormatMoment)}: {item.displayName} ({item.iecNumber})</Typography>;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid className={classes.root} item xs={12}>
          <Paper className={clsx(classes.paper)}>
            <div className={clsx(classes.contentWrapper, classes.searchRoot)}>
              <TextField
                fullWidth
                id="quick-search"
                inputRef={input => input && input.focus()}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton className={classes.iconButton} aria-label="search" onClick={() => handleSearchRun(quickSearch)}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }}
                label="Search by IEC Number (press enter to submit)"
                margin="dense"
                onChange={event => setQuickSearch(event.target.value)}
                onKeyDown={(event) => { if (event.keyCode === 13) handleSearchRun(quickSearch); }}
                value={quickSearch}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item sm={12} md={6}>
          <Paper className={clsx(classes.paper)}>
            <div className={classes.contentWrapper}>
              {displayContent()}
            </div>
          </Paper>
        </Grid>
        <Grid item sm={12} md={6}>
          <Paper className={clsx(classes.paper)}>
            <div className={classes.contentWrapper}>
              <List>
                <ListItem>
                  <Typography>Previous Lookups</Typography>
                </ListItem>
                {(props.workerLookups ?? []).map(l =>
                  <ListItem key={l.timestamp} button onClick={() => setActiveLookup(l.personId)}>
                    {displayLabel(l)}
                  </ListItem>
                )}
              </List>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const mapCardsFromAppState = ({ ui, profile }: IAppState) => {
  return {
    workerLookups: ui.workerLookups,
    workerLookupCache: ui.workerLookupCache,
    companies: profile.companies
  };
};

const mapLookupActions = {
  queueLookup
};

export default compose(withStyles(styles, { withTheme: true }), connect(mapCardsFromAppState, mapLookupActions))(QuickLookupPage);

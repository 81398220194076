import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import Reserves from '../booking/Reserves';
import AutoEditor from '../auto/AutoEditor';
// import BulkCreator from '../auto/bulk/BulkCreator';
import { createStyles, withStyles } from '@mui/styles';
import AutoDeletePrompt from '../auto/AutoDeletePrompt';
import { createAlertBulk } from 'src/stores/ui/actions';
import { IProfileState } from '../../stores/profile/types';
import { minutesToHours } from '../../abstracts/DataroweHelpers';
import { fetchConfigRequest } from '../../stores/database/actions';
import { Edit, SaveAlt, ListAlt, Cancel } from '@mui/icons-material';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';
import { HiddenFormatter, RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, IActionButton } from '../auto/AutoGrid';
import { Theme, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { ICourseSchedule, courseScheduleConfig, mapRawToCourseSchedule } from '../../stores/database/training/courseSchedule';
import { autoLoadMulti, ICustomColumnDefinition, getAllFields, AutoRow, ICustomDisplayDefinition, ICustomSelectColumnMap } from '../auto/AutoLoad';
import { IFilterDefinition, FilterGroupType, IFilterCondition, IColumnMap, FilterConditionType, ISelectColumnMap } from '../../stores/database/interfaces';

const styles = (theme: Theme) => createStyles({
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  }
});

interface ICourseScheduleProps {
  classes: any;
  hidden?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
  startDate: string;
  endDate?: string;
  courseIds?: number[];
  // companyIds?: number | number[];
}

interface IConnectedProps {
  db: IDatabaseState;
  profile: IProfileState;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

interface IInternalProps {
  baseTableId: number;
  loadedConfig: boolean;
  dateField?: IColumnMap;
  courseField?: IColumnMap;
  recordsUploadField?: IColumnMap;
  extraColumns?: ISelectColumnMap[];
}

export const formatSeatsOrTime: RowFormatter<string, ISelectColumnMap> = (r, _k, c) => {
  if (r[courseScheduleConfig.seatType] === 'fittest') return minutesToHours(r[c!.columnAlias]);

  return r[c!.columnAlias];
};

const CourseSchedule = (props: ICourseScheduleProps & IConnectedProps) => {
  const { classes, profile } = props;

  const displayField: RowFormatter<string> = (r): string => `${r[courseScheduleConfig.courseCode]}-${`0${r[courseScheduleConfig.section]}`.slice(-2)}${r[courseScheduleConfig.flagOffsite] ? 'F' : ''}${r[courseScheduleConfig.flagPilot] ? 'P' : ''}${r[courseScheduleConfig.flagSpecial] ? 'S' : ''}${r[courseScheduleConfig.flagUpload] ? 'U' : ''}`;

  const hidePartnerColumn: HiddenFormatter = (p: IProfileState) => !p.isPartner;
  const hideAdminColumn: HiddenFormatter = (p: IProfileState) => !p.isTrainingFacility;

  const [editState, setEditState] = React.useState<{ editId?: number }>({});

  const defaultColumns: ICustomColumnDefinition[] = [
    // {
    //   key: displayIECNumber
    // },
    {
      key: courseScheduleConfig.trainingDate
      // title: 'Date',
      // display: r => moment(r[courseScheduleConfig.trainingDate]).format(settings.dateFormatMoment)
    },
    {
      key: courseScheduleConfig.trainingTime
      // title: 'Time',
      // display: r => r[courseScheduleConfig.trainingTime] ? moment(`1900-01-01 ${r[courseScheduleConfig.trainingTime]}`).format(settings.timeFormatMoment) : ''
    },
    {
      key: courseScheduleConfig.seatMaxDisplay,
      display: formatSeatsOrTime,
      hidden: hideAdminColumn
    },
    {
      key: courseScheduleConfig.seatEnrolment,
      display: formatSeatsOrTime,
      hidden: hideAdminColumn
    },
    {
      key: courseScheduleConfig.seatsAvailable,
      display: formatSeatsOrTime
    },
    {
      key: courseScheduleConfig.seatsReserved,
      display: formatSeatsOrTime
    },
    {
      key: courseScheduleConfig.courseCode,
      display: (r, k) => displayField(r, k, undefined)
    },
    {
      key: courseScheduleConfig.courseTitle
    },
    {
      key: courseScheduleConfig.scheduleRoom,
      title: 'Location'
    },
    {
      key: courseScheduleConfig.displayPricePartner,
      hidden: hidePartnerColumn
    },
    {
      key: courseScheduleConfig.displayPriceNonPartner
    },
    {
      key: courseScheduleConfig.instructors,
      hidden: hideAdminColumn
    },
    {
      key: courseScheduleConfig.reservedCompany,
      hidden: hideAdminColumn,
      title: 'Reserved Company'
    }
  ];

  const extraColumns = [
    courseScheduleConfig.section,
    courseScheduleConfig.defaultPricePartner,
    courseScheduleConfig.defaultPriceNonPartner,
    courseScheduleConfig.timeslotData,
    courseScheduleConfig.flagOffsite,
    courseScheduleConfig.flagPilot,
    courseScheduleConfig.flagSpecial,
    courseScheduleConfig.flagUpload,
    courseScheduleConfig.seatMin,
    courseScheduleConfig.seatMax,
    courseScheduleConfig.scheduleRoomId,
    courseScheduleConfig.courseId,
    courseScheduleConfig.seatType
  ];

  const defaultGridParams: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps = {
    displayField,
    exportTitle: 'Scheduled Courses',
    baseTable: courseScheduleConfig._table,
    baseTableId: -1,
    displayColumns: [],
    additionalColumns: undefined,
    keyField: undefined,
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    selectedRows: undefined,
    loadedConfig: false,
    dateField: undefined,
    courseField: undefined
    // instructorFields: undefined
  };

  const [gridParams, setGridParams] = React.useState(defaultGridParams);

  // const [scheduleEdit, setScheduleEdit] = React.useState<Omit<ICourseScheduleEditButtonProps, 'onSave'>>({
  //   mode: 'button',
  //   open: false,
  //   buttonTitle: 'Schedule a Class'
  // });

  interface IViewReserves {
    type: 'viewReserves';
    title: string;
    courseSchedule: ICourseSchedule;
  }

  interface ICancelClass {
    type: 'cancelClass';
    title: string;
    courseScheduleId: number;
    rowData: AutoRow;
  }

  const [rowAction, setRowAction] = React.useState<IViewReserves | ICancelClass | undefined>(undefined);

  const loadData = (params: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps) => {
    const schedFilters: IFilterCondition[] = [
      {
        columnId: params.recordsUploadField!.columnId,
        lookupPath: params.recordsUploadField!.lookupPath ?? [],
        type: FilterConditionType.Equal,
        firstParameter: false
      }
    ];

    if (props.endDate != null) {
      schedFilters.push({
        columnId: params.dateField!.columnId,
        lookupPath: params.dateField!.lookupPath || [],
        type: FilterConditionType.Between,
        firstParameter: props.startDate,
        secondParameter: props.endDate,
        forcedType: 'Date'
      });
    } else {
      schedFilters.push({
        columnId: params.dateField!.columnId,
        lookupPath: params.dateField!.lookupPath || [],
        type: FilterConditionType.Equal,
        firstParameter: props.startDate,
        forcedType: 'Date'
      });
    }

    if (props.courseIds != null && props.courseIds.length > 0) {
      schedFilters.push({
        columnId: params.courseField!.columnId,
        lookupPath: params.courseField!.lookupPath || [],
        type: FilterConditionType.IsOneOf,
        firstParameter: props.courseIds
      });
    }

    const schedSearch: IFilterDefinition = {
      baseTableId: params.baseTableId,
      name: '',
      baseFilter: {
        type: FilterGroupType.And,
        children: schedFilters
      }
    };

    setGridParams((old) => ({
      ...old,
      rowDataLoading: true
    }));

    autoLoadMulti({
      fetchConfigRequest: props.fetchConfigRequest,
      createAlertBulk: props.createAlertBulk,
      multi: [
        {
          search: schedSearch,
          baseTableId: params.baseTableId,
          keyField: params.keyField!,
          displayField: params.displayField!,
          allFields: getAllFields(params.displayColumns, params.additionalColumns)
        }
      ]
    }).then((res) => {
      const scheduleData = res[courseScheduleConfig._table];
      setGridParams((old) => ({
        ...old,
        rowData: scheduleData,
        rowDataLastUpdate: moment().valueOf(),
        rowDataLoading: false
      }));
    });
  };

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'trainingCourseSchedule',
      action: (db) => {
        if (gridParams.baseTable == null) return;
        const baseTableId = typeof gridParams.baseTable === 'string' ? db.getTableByCombinedName(gridParams.baseTable.toString()).tableId : +gridParams.baseTable;
        const [keyField, courseField, dateField, recordsUploadField] = db.mapSelectColumnsByNamesWithId(baseTableId, [courseScheduleConfig._key, courseScheduleConfig.courseId, courseScheduleConfig.trainingDate, courseScheduleConfig.recordsUpload]);

        const displayColumns: (ICustomSelectColumnMap | ICustomDisplayDefinition)[] = db.mapDisplayColumnsByNamesWithId(baseTableId, defaultColumns);
        const additionalColumns = db.mapSelectColumnsByNamesWithId(baseTableId, extraColumns);

        setGridParams((old) => ({ ...old, baseTableId, keyField, courseField, dateField, displayColumns, recordsUploadField, additionalColumns, loadedConfig: true }));
        loadData({ ...gridParams, baseTableId, keyField, courseField, dateField, displayColumns, recordsUploadField, additionalColumns });
      }
    });

    return () => { };
  }, []);

  React.useEffect(() => {
    if (gridParams.loadedConfig) {
      loadData(gridParams);
    }

    return () => { };
  }, [props.courseIds, props.startDate, props.endDate]);

  const handleReservesChanged = () => loadData(gridParams);

  const generateDialogContent = () => {
    if (rowAction == null) return undefined;

    switch (rowAction.type) {
      case 'viewReserves':
        return <Reserves courseSchedule={rowAction.courseSchedule.courseScheduleId} onReservesChanged={handleReservesChanged} />;
    }

    return undefined;
  };

  const handleDialogClose = () => setRowAction(undefined);

  interface SecuredIActionButton extends IActionButton {
    isShown?: (p: IProfileState) => boolean;
  }

  const actions: SecuredIActionButton[] = [
    {
      icon: <Edit />,
      isShown: (p) => p.isTrainingFacility,
      title: 'Edit',
      onClick: (row) => setEditState((old) => ({ ...old, editId: row[courseScheduleConfig._key] }))
    },
    {
      icon: <SaveAlt />,
      title: 'Reserves',
      disabled: (r, k, p) => {
        return r == null || (r[courseScheduleConfig.courseCode] as string).substring(1, 3) === 'FIT' || (!p.isTrainingFacility && !p.companies.some((x) => (x.reserveLimit ?? 0) > 0));
      },
      onClick: (row) => setRowAction({
        type: 'viewReserves',
        courseSchedule: mapRawToCourseSchedule(row),
        title: `Reserves - ${row[courseScheduleConfig.courseTitle]}`
      })
    },
    {
      icon: <ListAlt />,
      isShown: (p) => p.isTrainingFacility,
      title: 'Roster',
      onClick: (r) => window.open(`/training/roster/${r[courseScheduleConfig._key]}`, '_blank')
    },
    {
      icon: <Cancel />,
      isShown: (p) => p.isTrainingFacility,
      title: 'Cancel Class',
      onClick: (row) => setRowAction({ type: 'cancelClass', title: `Cancel ${row[courseScheduleConfig.courseTitle]}`, courseScheduleId: row[courseScheduleConfig._key], rowData: row })
    }
  ];

  const handleEditorCancel = () => setEditState({});

  const handleEditorSave = (_newId: number, _result: AutoRow) => {
    handleEditorCancel();
    loadData(gridParams);
  };

  const handleScheduleDeleteCancel = () => setRowAction(undefined);

  const handleScheduleDeleteSuccess = (success: boolean) => {
    if (success && rowAction?.type === 'cancelClass') {
      const rowData = cloneDeep(gridParams.rowData);
      delete rowData[rowAction.courseScheduleId];

      setGridParams((old) => ({ ...old, rowData, rowDataLastUpdate: moment().valueOf() }));
    }

    handleScheduleDeleteCancel();
  };

  return (
    <div style={{ maxWidth: '100vw' }} hidden={props.hidden}>
      {!profile.isTrainingFacility ? undefined : (
        <Grid container spacing={3}>
          <Grid className={clsx(classes.root, classes.searchRoot)} item xs={12} sm={2}>
            <AutoEditor
              baseTable="training.courseSchedule"
              mode={editState.editId == null ? 'button' : 'direct'}
              open={editState.editId != null}
              editId={editState.editId}
              buttonTitle="Schedule a Class"
              onSave={handleEditorSave}
              onCancel={handleEditorCancel}
              stateExtension={{
                courseSearchFilters: {
                  courseTypeKeys: ['classroom', 'schedule-fittest', 'sma']
                }
              }}
            />
          </Grid>
          {/* <Grid className={clsx(classes.root, classes.searchRoot)} item xs={12} sm={2}>
            <BulkCreator
              baseTable="training.courseSchedule"
              buttonTitle="Schedule multiple Classes"
              mode="button"
              stateExtension={{
                courseSearchFilters: {
                  courseTypeKeys: ['classroom', 'sma']
                }
              }}/>
          </Grid> */}
        </Grid>
      )}
      <AutoGrid
        emptyMessage="No scheduled training to show"
        minHeight={props.minHeight}
        maxHeight={props.maxHeight}
        {...gridParams}
        postActions={[actions.filter((x) => !x.isShown || x.isShown(profile))]}
      />
      <Dialog open={rowAction != null} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <DialogTitle>{rowAction != null ? rowAction.title : ''}</DialogTitle>
        <DialogContent>{generateDialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" className={classes.root}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {rowAction?.type === 'cancelClass' ? (
        <AutoDeletePrompt
          baseTable="training.courseSchedule"
          deleteId={rowAction.courseScheduleId}
          open={rowAction.courseScheduleId != null}
          title={rowAction.title}
          rowData={rowAction.rowData}
          mode="direct"
          promptEmail
          changeContextDescription="Course Schedule > Cancel Class"
          displayField={gridParams.displayField}
          displayColumns={gridParams.displayColumns}
          onDelete={handleScheduleDeleteSuccess}
          onCancel={handleScheduleDeleteCancel}
        />
      ) : undefined}
    </div>
  );
};

export default compose(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(CourseSchedule);

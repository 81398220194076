import React from 'react';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { mapConfigFetchToProps } from '../auto/AutoGrid';
import { IProfileState } from '../../stores/profile/types';
import { fetchConfigRequest } from '../../stores/database/actions';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, TextField } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

interface IConnectedProps {
  db: IDatabaseState;
  profile: IProfileState;
  fetchConfigRequest: typeof fetchConfigRequest;
}

interface IUserAddProps extends IConnectedProps {
  classes: any;
  companyId: number;
  onSave: (newId: number) => void;
}

interface IUserAddState {
  open: boolean;
  saving: boolean;
  displayName: string;
  email: string;
}

const UserAdd = (props: IUserAddProps) => {
  const [state, setState] = React.useState<IUserAddState>({
    open: false,
    saving: false,
    displayName: '',
    email: ''
  });

  const handleOpen = () => setState((old) => ({
    ...old,
    open: true
  }));

  const handleClose = () => setState({
    open: false,
    saving: false,
    displayName: '',
    email: ''
  });

  const handleSave = () => {
    setState((old) => ({ ...old, saving: true }));
    axios.post(`${API_ENDPOINT}/account/create`, { companyId: props.companyId, displayName: state.displayName, email: state.email }).then((res) => {
      handleClose();
      props.onSave(res.data);
    });
  };

  const setValue = (field: string, value: string) => setState((old) => ({
    ...old,
    ...{ [field]: value }
  }));

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>
        Add Login
      </Button>
      <Dialog open={state.open} onClose={handleClose}>
        <DialogTitle>Add Login</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>Create Login Step 1 of 2 - Email</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField required fullWidth id="display-name" label="Full Name" value={state.displayName} onChange={(e) => setValue('displayName', e.target.value)} disabled={state.saving} />
            </Grid>
            <Grid item xs={12}>
              <TextField required fullWidth id="email" type="email" label="Email" value={state.email} onChange={(e) => setValue('email', e.target.value)} disabled={state.saving} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={state.saving}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!emailRegex.test(state.email) || state.saving}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default compose(withStyles(createStyles({})), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(UserAdd);

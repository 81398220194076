import moment from 'moment';
import * as React from 'react';
import { Dictionary } from 'lodash';
import AutoGrid from '../auto/AutoGrid';
import axios, { AxiosResponse } from 'axios';
import settings from '../../abstracts/settings';
import LoadingScreen from '../common/LoadingScreen';
import { createStyles, withStyles } from '@mui/styles';
import { Theme, Typography, Grid, Divider, Link } from '@mui/material';
import { objectEach, objectSortMap } from '../../abstracts/DataroweHelpers';
import { IRegistrationResultRow, IRegistrationConfirmationDetails } from '../../stores/training/booking/types';

const API_ENDPOINT = process.env.REACT_APP_REG_API_URL || '';

const styles = (theme: Theme) => createStyles({
  incompleteTraining: {
    backgroundColor: theme.palette.grey[100]
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  payDetail: {
    maxWidth: 400
  },
  payTotals: {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: theme.spacing(3)
  },
  payAmount: {
    textAlign: 'right',
    paddingRight: theme.spacing(3)
  },
  payDivider: {
    marginTop: 5,
    marginBottom: 5
  }
});

interface ICompletedProps {
  classes: any;
  booking?: number;
}

interface IConnectedProps {
}

interface ILineItem {
  date: string;
  worker: string;
  course: string;
  price: number;
}

interface ILineItemGroup {
  key: string;
  company: string;
  purchaseOrder: string;
  subtotal: number;
  items: ILineItem[];
}

interface IInvoiceDisplay {
  subtotal: number;
  tax: number;
  total: number;
  lineItemGroups: Dictionary<ILineItemGroup>;
}

interface IRegistrationConfirmationDetailsExtendedProps {
  lastUpdated?: number;
  invoiceDisplay: Dictionary<IInvoiceDisplay>;
}

const CompletedBooking = (props: ICompletedProps & IConnectedProps) => {
  const { classes } = props;

  const [regDetails, setRegDetails] = React.useState<IRegistrationConfirmationDetails & IRegistrationConfirmationDetailsExtendedProps | undefined>(undefined);

  const generateInvoiceDetails = (details: IRegistrationConfirmationDetails): Dictionary<IInvoiceDisplay> => {
    const result: Dictionary<IInvoiceDisplay> = {};

    objectEach(details.registrationRows, (_key, row) => {
      const resultKey = row.payment;
      const key = `${row.companyId}_${row.purchaseOrder ?? ''}`;
      const price = parseFloat(row.price.replace('$', ''));

      if (result[resultKey] == null){
        result[resultKey] = { subtotal: 0, tax: 0, total: 0, lineItemGroups: {} };
      }

      if (result[resultKey].lineItemGroups[key] == null) {
        result[resultKey].lineItemGroups[key] = { key, company: row.companyName, purchaseOrder: row.purchaseOrder, subtotal: 0, items: [] };
      }

      result[resultKey].lineItemGroups[key].items.push({ price, date: row.trainingDate, worker: row.workerName, course: row.course });
      result[resultKey].lineItemGroups[key].subtotal += price;
      result[resultKey].subtotal += price;
      result[resultKey].tax = result[resultKey].subtotal * 0.13;
      result[resultKey].total = result[resultKey].subtotal + result[resultKey].tax;

    });
    return result;
  };

  React.useEffect(() => {
    if (props.booking == null) {
      setRegDetails(undefined);
    } else {
      axios.get(`${API_ENDPOINT}/Training/Booking/${props.booking}`).then((res: AxiosResponse<IRegistrationConfirmationDetails>) => setRegDetails({ ...res.data, lastUpdated: moment().valueOf(), invoiceDisplay: generateInvoiceDetails(res.data) }));
    }
  }, [props.booking]);

  return props.booking == null ? <div>Please select a booking to load</div> : (
    <div className={classes.contentWrapper}>
      {regDetails == null ? <LoadingScreen /> : undefined}
      <Typography variant="h6">Booking Completed by {regDetails?.scheduleDisplay} on {regDetails == null ? '' : moment.utc(regDetails.scheduleDateTimeUTC).local().format(settings.dateWeekdayTimeFormatMoment)}</Typography>
      {/* <Typography variant="subtitle1">{props.booking.preheader}</Typography>
      <Typography variant="subtitle2">{`Scheduled by ${props.booking.scheduler} on ${props.booking.scheduleDate}`}</Typography> */}
      <AutoGrid
        exportTitle="Completed Booking"
        keyField="workerScheduleId"
        displayField={r => `${r.workerName} (${r.iecNumber}) for ${r.course} on ${r.trainingDate}`}
        emptyMessage="No scheduled training to show"
        rowData={regDetails?.registrationRows ?? {}}
        rowDataLastUpdate={regDetails?.lastUpdated ?? -1}
        displayColumns={[
          {
            columnAlias: 'workerName',
            columnTitle: 'Worker Name'
          },
          {
            columnAlias: 'iecNumber',
            columnTitle: 'IEC Number'
          },
          {
            columnAlias: 'trainingDate',
            columnTitle: 'Training Date'
          },
          {
            columnAlias: 'classTime',
            columnTitle: 'Class Time'
          },
          {
            columnAlias: 'course',
            columnTitle: 'Course'
          },
          {
            columnAlias: 'location',
            columnTitle: 'Location',
            display: (r: IRegistrationResultRow) => r.mapURL == null ? r.location : r.mapURL,
            displayControl: (r: IRegistrationResultRow) => r.mapURL == null ? r.location : <>{r.location} - <a style={{ color: '#3391FF' }} href={r.mapURL}>Link</a></>
          },
          {
            columnAlias: 'companyName',
            columnTitle: 'Company Name'
          },
          {
            columnAlias: 'purchaseOrder',
            columnTitle: 'Purchase Order'
          },
          {
            columnAlias: 'price',
            columnTitle: 'Price',
            display: ({ price }: IRegistrationResultRow) => `$${price}`
          },
          {
            columnAlias: 'payment',
            columnTitle: 'Payment Method'
          }
        ]}
      />
      {regDetails == null ? undefined :
        <Grid container spacing={0} className={classes.payDetail}>
          {objectSortMap(regDetails.invoiceDisplay, '', pType => <>
            <Typography variant="h6" component={Grid} item xs={12} key={pType.key}>{pType.key}</Typography>
            {objectSortMap(pType.item.lineItemGroups ?? {}, '', ({ item, key }) => <>
              <Grid item xs={8} key={`${key}_description`}>{item.company} - {item.purchaseOrder ?? 'No PO'}</Grid>
              <Grid item xs={4} key={`${key}_price`} className={classes.payAmount}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(item.subtotal)}</Grid>
            </>)}
            <Grid item xs={12} key="subtotalDivider" className={classes.payDivider}>
              <Divider key="subtotalDivider" />
            </Grid>
            <Grid item xs={8} key={`${pType.key}_subtotal-label`} className={classes.payTotals}>Subtotal</Grid>
            <Grid item xs={4} key={`${pType.key}_subtotal-amount`} className={classes.payTotals}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(pType.item.subtotal)}</Grid>
            <Grid item xs={8} key={`${pType.key}_subtotal-label`} className={classes.payTotals}>Taxes</Grid>
            <Grid item xs={4} key={`${pType.key}_subtotal-amount`} className={classes.payTotals}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(pType.item.tax)}</Grid>
            <Grid item xs={8} key={`${pType.key}_subtotal-label`} className={classes.payTotals}>Total</Grid>
            <Grid item xs={4} key={`${pType.key}_subtotal-amount`} className={classes.payTotals}>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(pType.item.total)}</Grid>
          </>)}
          {regDetails.payment?.receiptUrl == null ? undefined :
            <Grid item xs={12} key="receipt-url">
              <Link sx={{ color: '#3391FF' }} target="_blank" rel="noreferrer" href={regDetails.payment.receiptUrl}>Click here for Credit Card Receipt</Link>
            </Grid>
          }
        </Grid>
      }
    </div>
  );
};
export default withStyles(styles)(CompletedBooking);

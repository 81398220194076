import React from 'react';
import { TextField } from '@mui/material';

interface InlineTextboxProps {
  defaultValue?: string;
  uniqueId: string;
  onValueUpdated: (value?: string) => void;
}

const InlineTextbox = (props: InlineTextboxProps) => {
  const { uniqueId, defaultValue, onValueUpdated } = props;

  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => setValue(defaultValue), [defaultValue]);
  const handleTextChange = (text: string) => setValue(text);

  const handleFieldBlur = () => {
    if ((defaultValue ?? '').localeCompare(value ?? '') !== 0) onValueUpdated(value);
  };

  const text = React.useRef<HTMLElement | null>(null);

  return <TextField
    margin="dense"
    inputRef={text}
    fullWidth
    key={uniqueId}
    value={value || ''}
    onChange={event => handleTextChange(event.currentTarget.value)}
    onBlur={handleFieldBlur}
    // if user presses escape, cancel update to the value and reinstate the prop value
    onKeyDown={(event) => {
      event.stopPropagation();
      if (event.keyCode === 27) setValue(defaultValue);
      if (event.keyCode === 13) text!.current!.blur();
    }}
  />;
};

export default (InlineTextbox);

import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { createAlertBulk } from 'src/stores/ui/actions';
import { fetchConfigRequest } from '../../stores/database/actions';
import { IDateRange, dateRangeToFilter } from '../common/DateRangeEdit';
import { RowFormatter } from 'src/stores/database/gridExtension/interfaces';
import { mapProfileDbFromAppState, IDatabaseState } from '../../stores/database/types';
import { autoLoad, ICustomColumnDefinition, getAllFields, AutoRow } from '../auto/AutoLoad';
import AutoGrid, { IAutoGridProps, mapConfigFetchToProps, RowClasses } from '../auto/AutoGrid';
import { IFilterDefinition, FilterGroupType, IFilterCondition, IColumnMap, FilterConditionType, ISelectColumnMap, IFilterGroup } from '../../stores/database/interfaces';

const styles = () => createStyles({
  incompleteTraining: {
    // backgroundColor: theme.palette.grey[100]
  }
});

interface IHistoryProps {
  classes: any;
  hidden?: boolean;
  hideWorkerName?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
  personIds?: number | number[];
  companyIds?: number | number[];
  courseIds?: number[];
  trainingDate?: IDateRange;
  expiryDate?: IDateRange;
  hideReplacedTraining?: boolean;
}

interface IConnectedProps {
  db: IDatabaseState;
  fetchConfigRequest: typeof fetchConfigRequest;
  createAlertBulk: typeof createAlertBulk;
}

interface IInternalProps {
  baseTableId: number;
  loadedData: boolean;
  courseField?: IColumnMap;
  personField?: IColumnMap;
  companyField?: IColumnMap;
  trainingDateField?: IColumnMap;
  expiryDateField?: IColumnMap;
  hasRefreshField?: IColumnMap;
  extraColumns?: ISelectColumnMap[];
}

const WorkerHistory = (props: IHistoryProps & IConnectedProps) => {
  const { courseIds, trainingDate, expiryDate, companyIds, personIds, hideReplacedTraining } = props;

  const recordsUpload = 'workerSchedule.courseSchedule.recordsUpload';
  const historyCourseCode = 'course.code';
  const historyCourseTitle = 'course.title';
  const trainDate = 'workerSchedule.courseSchedule.trainingDate';
  const expiry = 'expiryDate';
  const company = 'workerSchedule.userCompany.company.name';
  const union = 'workerSchedule.person.worker.person.companyUnion.name';
  const statusName = 'workerSchedule.workerScheduleStatus.statusName';
  const statusKey = 'workerSchedule.workerScheduleStatus.statusKey';
  const personIdField = 'workerSchedule.person.worker.person.iecnumber';
  const companyIdField = 'workerSchedule.userCompany.company';
  const personDisplayField = 'workerSchedule.person.worker.displayName';
  const respiratorFactor = 'respiratorFactor';
  const respiratorSize = 'respiratorSize';
  const hasRefresh = 'replaceByWorkerHistory';

  const extraColumns = [statusKey];

  const defaultColumns: ICustomColumnDefinition[] = [
    {
      key: statusName
    },
    {
      key: recordsUpload
    },
    {
      key: personIdField
    },
    {
      key: personDisplayField
    },
    {
      key: historyCourseCode
    },
    {
      key: historyCourseTitle
    },
    {
      key: trainDate
    },
    {
      key: expiry
    },
    {
      key: company,
      title: 'Company Name'
    },
    {
      key: union,
      title: 'Worker Union'
    },
    {
      key: respiratorFactor
    },
    {
      key: respiratorSize
    }
  ];

  const displayField: RowFormatter<string> = (data): string => `${data[historyCourseCode]} - ${data[historyCourseTitle]}`;
  const rowClasses: RowClasses = (data) => data[historyCourseCode] == null ? [props.classes.incompleteTraining] : undefined;

  const defaultGridParams: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps = {
    displayField,
    rowClasses,
    exportTitle: 'Worker History',
    baseTable: 'Training.WorkerHistory',
    baseTableId: -1,
    displayColumns: [],
    defaultSortColumn: undefined,
    additionalColumns: undefined,
    keyField: undefined,
    rowData: {},
    rowDataLastUpdate: moment().valueOf(),
    rowDataLoading: false,
    selectedRows: undefined,
    loadedData: false,
    courseField: undefined,
    personField: undefined
  };

  const [gridParams, setGridParams] = React.useState(defaultGridParams);

  const loadData = (params: IAutoGridProps<AutoRow, ISelectColumnMap | undefined> & IInternalProps) => {
    const filters: (IFilterCondition | IFilterGroup)[] = [];

    if (personIds != null) {
      filters.push({
        columnId: params.personField!.columnId,
        lookupPath: params.personField!.lookupPath || [],
        type: Array.isArray(personIds) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: personIds
      });
    }

    if (trainingDate != null) {
      filters.push(dateRangeToFilter({ range: trainingDate, column: params.trainingDateField!, disallowNull: true }));
    }

    if (expiryDate != null) {
      filters.push(dateRangeToFilter({ range: expiryDate, column: params.expiryDateField! }));
    }

    if (courseIds != null) {
      filters.push({
        columnId: params.courseField!.columnId,
        lookupPath: params.courseField!.lookupPath || [],
        type: Array.isArray(courseIds) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: courseIds
      });
    }

    if (companyIds != null) {
      filters.push({
        columnId: params.companyField!.columnId,
        lookupPath: params.companyField!.lookupPath || [],
        type: Array.isArray(companyIds) ? FilterConditionType.IsOneOf : FilterConditionType.Equal,
        firstParameter: companyIds
      });
    }

    if (hideReplacedTraining) {
      filters.push({
        columnId: params.hasRefreshField!.columnId,
        lookupPath: params.hasRefreshField!.lookupPath || [],
        type: FilterConditionType.IsNull
      });
    }

    const search: IFilterDefinition | undefined = filters.length === 0 ? undefined : {
      baseTableId: params.baseTableId,
      name: '',
      baseFilter: {
        type: FilterGroupType.And,
        children: filters
      }
    };

    if (Array.isArray(personIds) && personIds.length === 0) {
      setGridParams((old) => ({
        ...old,
        rowData: {},
        rowDataLastUpdate: moment().valueOf(),
        loadedData: true,
        rowDataLoading: false
      }));
    } else {
      setGridParams((old) => ({
        ...old,
        rowDataLoading: true
      }));

      autoLoad({
        search,
        count: search == null ? 1000 : 10000,
        baseTableId: params.baseTableId,
        keyField: params.keyField!,
        displayField: params.displayField!,
        allFields: getAllFields(params.displayColumns, params.additionalColumns),
        fetchConfigRequest: props.fetchConfigRequest,
        createAlertBulk: props.createAlertBulk
      }).then((res) => {
        setGridParams((old) => ({
          ...old,
          rowData: res,
          rowDataLastUpdate: moment().valueOf(),
          loadedData: true,
          rowDataLoading: false
        }));
      });
    }
  };

  const genDefaultColumns = () => {
    if (props.hideWorkerName) return defaultColumns.filter((x) => x.key !== personIdField && x.key !== personDisplayField);

    return defaultColumns;
  };

  React.useEffect(() => {
    if (gridParams.loadedData) loadData(gridParams);
  }, [companyIds, courseIds, JSON.stringify(trainingDate ?? ''), JSON.stringify(expiryDate ?? ''), personIds, hideReplacedTraining ?? false]);

  React.useEffect(() => {
    props.fetchConfigRequest(undefined, {
      key: 'trainingWorkerHistory',
      action: (db) => {
        if (gridParams.baseTable == null) return;
        const baseTableId = typeof gridParams.baseTable === 'string' ? db.getTableByCombinedName(gridParams.baseTable.toString()).tableId : +gridParams.baseTable;
        const [keyField, courseField, personField, companyField, trainingDateField, expiryDateField, hasRefreshField] = db.mapSelectColumnsByNamesWithId(baseTableId, [
          'workerHistoryId',
          'course',
          personIdField,
          companyIdField,
          trainDate,
          expiry,
          hasRefresh
        ]);
        const displayColumns = db.mapDisplayColumnsByNamesWithId(baseTableId, genDefaultColumns());
        const additionalColumns = db.mapSelectColumnsByNamesWithId(baseTableId, extraColumns);

        setGridParams((old) => ({
          ...old,
          baseTableId,
          keyField,
          courseField,
          personField,
          companyField,
          trainingDateField,
          expiryDateField,
          hasRefreshField,
          displayColumns,
          additionalColumns,
          defaultSortColumn: { column: expiryDateField, sortOrder: 'Descending' }
        }));

        loadData({
          ...gridParams,
          baseTableId,
          keyField,
          courseField,
          personField,
          companyField,
          hasRefreshField,
          trainingDateField,
          expiryDateField,
          displayColumns,
          additionalColumns,
          defaultSortColumn: { column: expiryDateField, sortOrder: 'Descending' }
        });
      }
    });
    return () => {};
  }, []);

  return (
    <div style={{ maxWidth: '100vw' }} hidden={props.hidden}>
      <AutoGrid minHeight={props.minHeight} maxHeight={props.maxHeight} emptyMessage="No History for Selected Parameters" pagination {...gridParams} />
    </div>
  );
};

export default compose(withStyles(styles), connect(mapProfileDbFromAppState, mapConfigFetchToProps))(WorkerHistory);

import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Add, Delete } from '@mui/icons-material';
import FitTestTypeEditor from './FitTestTypeEditor';
import settings from '../../../../abstracts/settings';
import { createStyles, withStyles } from '@mui/styles';
import Validation from '../../../../abstracts/Validation';
import { Grid, Button, TextField, Theme } from '@mui/material';
import { IFitTestType, ITimeslotData } from '../../../../stores/database/training/courseSchedule';

const borderStyle: string = '1px solid rgba(0, 0, 0, 0.4)';
const styles = (theme: Theme) => createStyles({
  addType: {
    textAlign: 'right'
  },
  removeTimeslot: {
    textAlign: 'right'
  },
  odd: {
    backgroundColor: theme.palette.background.paper
  },
  even: {
    backgroundColor: theme.palette.background.default
  },
  left: {
    borderTop: borderStyle,
    borderBottom: borderStyle,
    borderLeft: borderStyle
  },
  right: {
    borderTop: borderStyle,
    borderBottom: borderStyle,
    borderRight: borderStyle,
    paddingRight: theme.spacing(3)
  },
  middle: {
    borderTop: borderStyle,
    borderBottom: borderStyle
  }
});

interface IFitTestTimeslotProps extends ITimeslotData {
  classes?: any;
  index: number;
  parityClass: string;
  onValueUpdated: (start: string, end: string, fitTestTypes: IFitTestType[]) => void;
  onRemoveTimeslot: () => void;
}

const FitTestTimeslotEditor = (props: IFitTestTimeslotProps) => {
  const { classes, start, end, fitTestTypes, onValueUpdated, onRemoveTimeslot } = props;

  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');

  React.useEffect(() => {
    if (props.start) {
      setStartTime(moment(props.start, settings.apiDateTimeFormatMoment).format(settings.inputs.time));
    }

    if (props.end) {
      setEndTime(moment(props.end, settings.apiDateTimeFormatMoment).format(settings.inputs.time));
    }
  }, []);

  const applyClasses = (additionalClasses: string[]) => {
    const classList = [classes[props.parityClass]];
    additionalClasses.forEach(element => {
      classList.push(classes[element]);
    });

    return clsx(...classList);
  };

  const handleAddFitType = () => {
    const newFitTestTypes = cloneDeep(fitTestTypes);

    newFitTestTypes.push({
      scheduleType: '',
      duration: 0,
      maxSeats: 0,
      errorMessage: Validation.messages.scheduleType.required
    });

    onValueUpdated(start, end, newFitTestTypes);
  };

  const handleRemoveFitTestType = (index: number) => () => {
    const newFitTestTypes = cloneDeep(fitTestTypes);
    newFitTestTypes.splice(index, 1);

    onValueUpdated(start, end, newFitTestTypes);
  };

  const validateScheduleType = (index: number, scheduleType: string, duration: number, maxSeats: number, message?: string, errorMessage?: string) => {
    const newFitTestTypes = cloneDeep(fitTestTypes);

    newFitTestTypes[index] = {
      scheduleType,
      duration,
      maxSeats,
      message,
      errorMessage
    };

    if (scheduleType !== fitTestTypes[index].scheduleType) {
      const fitTestIndex = fitTestTypes.findIndex((fitTestType, ftIndex) => {
        return fitTestType.scheduleType === scheduleType && ftIndex !== index;
      });

      if (fitTestIndex !== -1) {
        newFitTestTypes[index].scheduleType = fitTestTypes[fitTestIndex].scheduleType;
        newFitTestTypes[fitTestIndex].scheduleType = fitTestTypes[index].scheduleType;
      }
    }

    return {
      newFitTestTypes
    };
  };

  const handleValueUpdated = (index: number) => (scheduleType: string, duration: number, maxSeats: number, message?: string, errorMessage?: string): void => {
    const { newFitTestTypes } = validateScheduleType(index, scheduleType, duration, maxSeats, message, errorMessage);

    onValueUpdated(start, end, newFitTestTypes);
  };

  const handleTimeUpdate = (label: string, dateTime: string): void => {
    if (label === 'Start Time') {
      onValueUpdated(dateTime, end, fitTestTypes);
    } else if (label === 'End Time') {
      onValueUpdated(start, dateTime, fitTestTypes);
    }
  };

  const renderTimepicker = (label: string, value: string, callback: React.Dispatch<React.SetStateAction<string>>) => {
    return (
      <TextField
        fullWidth
        InputLabelProps={{ shrink: true }}
        label={label}
        onChange={event => {
          callback(event.currentTarget.value);
        }}
        onBlur={event => {
          handleTimeUpdate(label, event.currentTarget.value ?? '');
        }}
        required={true}
        type="time"
        value={value}
      />
    );
  };

  return (
    <>
      <Grid item xs={9} md={5} className={applyClasses(['left'])}>
        {renderTimepicker('Start Time', startTime, setStartTime)}
      </Grid>
      <Grid item xs={9} md={5} className={applyClasses(['middle'])}>
        {renderTimepicker('End Time', endTime, setEndTime)}
      </Grid>
      <Grid item xs={1} className={applyClasses(['middle', 'addType'])}>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleAddFitType} disabled={fitTestTypes.length === 4}>
          <Add />
        </Button>
      </Grid>
      <Grid item xs={1} className={applyClasses(['right', 'removeTimeslot'])}>
        <Button variant="contained" color="primary" className={classes.button} onClick={onRemoveTimeslot}>
          <Delete />
        </Button>
      </Grid>
      {fitTestTypes.map((fitTestType, index) => (
        <FitTestTypeEditor {...fitTestType} count={fitTestTypes.length} key={index} index={index} maxIndex={fitTestTypes.length - 1} parityClass={props.parityClass} onValueUpdated={handleValueUpdated(index)} onRemoveType={handleRemoveFitTestType(index)} />
      ))}
    </>
  );
};

export default withStyles(styles)(FitTestTimeslotEditor);

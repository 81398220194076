import { action } from 'typesafe-actions';
import { ICardDisplay } from 'src/components/identity/WorkerCardDisplay';
import { IAlertDetails, IQRDetails, UIActions } from './types';

export const closeAlert = () => ({ type: UIActions.ALERT_NEXT });
export const validateApi = (isValid: boolean) => action(UIActions.SET_API_CONNECTED, isValid);
export const setTitle = (title: string, helpGuid: string) => action(UIActions.SET_TITLE, { title, helpGuid });
export const setSubtitle = (subtitle: string) => action(UIActions.SET_SUBTITLE, subtitle);
export const setTitleSubtitle = (title: string, helpGuid: string, subtitle: string) => action(UIActions.SET_TITLE_SUBTITLE, { title, helpGuid, subtitle });

export const createAlert = (alert: IAlertDetails) => action(UIActions.ALERT_CREATE, alert);
export const createAlertBulk = (alerts: IAlertDetails[]) => action(UIActions.ALERT_CREATE_BULK, alerts.filter(a => a != null));
// export const closeAlert = () => action(UIActions.ALERT_NEXT);
export const removeAlert = (alertId: number) => action(UIActions.ALERT_REMOVE, alertId);
export const clearAlerts = () => action(UIActions.ALERT_REMOVE_ALL);
export const markAlertsRead = () => action(UIActions.ALERT_MARK_ALL_READ);

export const showQRLink = (link: IQRDetails) => action(UIActions.QR_SHOW_LINK, link);
export const clearQRLink = () => action(UIActions.QR_CLEAR_LINK);
export const queueLookup = (personId: number) => action(UIActions.QUEUE_WORKER_LOOKUP, personId);
export const completeLookup = (workerLookup?: ICardDisplay) => action(UIActions.COMPLETE_WORKER_LOOKUP, workerLookup);
export const failLookup = (personId: number, message: string) => action(UIActions.QUEUE_WORKER_ERROR, { personId, message });
